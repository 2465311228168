<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Tabs</span></div>

      <h2>Default</h2>
      <div class="jh-card">
        <div class="jh-card-header has-tab">
          <v-tabs v-model="tab1" slider-size="4">
            <v-tab key="tab1_1">탭1</v-tab>
            <v-tab key="tab1_2">탭2</v-tab>
          </v-tabs>
        </div>
        <div class="jh-card-body">
          <v-tabs-items v-model="tab1">
              <v-tab-item key="tab1_1">
                  <div class="tab-contnets">탭1 컨텐츠</div>
              </v-tab-item>
              <v-tab-item key="tab1_2">
                  <div class="tab-contnets">탭2 컨텐츠</div>
              </v-tab-item>
          </v-tabs-items>          
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Tab Centerd</h2>
      <div class="jh-card">
        <div class="jh-card-header has-tab">
          <v-tabs v-model="tab2" centered slider-size="4">
            <v-tab>탭1</v-tab>
            <v-tab>탭2</v-tab>
          </v-tabs>
        </div>
        <div class="jh-card-body">
          <v-tabs-items v-model="tab2">
              <v-tab-item>
                  <div class="tab-contnets">탭1 컨텐츠</div>
              </v-tab-item>
              <v-tab-item>
                  <div class="tab-contnets">탭2 컨텐츠</div>
              </v-tab-item>
          </v-tabs-items>          
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source02">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source02">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Tab Right</h2>
      <div class="jh-card">
        <div class="jh-card-header has-tab">
          <v-tabs v-model="tab3" right slider-size="4">
            <v-tab>탭1</v-tab>
            <v-tab>탭2</v-tab>
          </v-tabs>
        </div>
        <div class="jh-card-body">
          <v-tabs-items v-model="tab3">
              <v-tab-item>
                  <div class="tab-contnets">탭1 컨텐츠</div>
              </v-tab-item>
              <v-tab-item>
                  <div class="tab-contnets">탭2 컨텐츠</div>
              </v-tab-item>
          </v-tabs-items>          
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source03">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source03">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Tab Vertical</h2>
      <div
        class="guide-card"             
        outlined>
        <v-tabs v-model="tab4" vertical slider-size="3">
            <v-tab>탭1</v-tab>
            <v-tab>탭2</v-tab>
            <v-tabs-items v-model="tab4">
                <v-tab-item>
                    <div class="tab-contnets">탭1 컨텐츠</div>
                </v-tab-item>
                <v-tab-item>
                    <div class="tab-contnets">탭2 컨텐츠</div>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source04">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source04">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Tab Button</h2>
      <div class="jh-card">
        <div class="jh-card-header has-tab">
          <v-tabs slider-size="4">
            <v-tab>탭1</v-tab>
            <v-tab>탭2</v-tab>
          </v-tabs>
          <p class="jh-tab-button is-right">
              <button type="button" class="jh-btn is-icon" title="고객정보 새로고침"><i class="jh-icon-refresh"></i></button>
              <button type="button" class="jh-btn is-icon" title="확장"><i class="jh-icon-transfer-left"></i></button>
          </p>
        </div>
      </div>
      <div class="jh-card is-mt-5">
        <div class="jh-card-header has-tab">
          <v-tabs centered slider-size="4">
            <v-tab>탭1</v-tab>
            <v-tab>탭2</v-tab>
          </v-tabs>
          <p class="jh-tab-button is-left">
              <button type="button" class="jh-btn is-icon" title="고객정보 새로고침"><i class="jh-icon-refresh"></i></button>
              <button type="button" class="jh-btn is-icon" title="확장"><i class="jh-icon-transfer-left"></i></button>
          </p>
          <p class="jh-tab-button is-right">
              <button type="button" class="jh-btn is-icon" title="고객정보 새로고침"><i class="jh-icon-refresh"></i></button>
              <button type="button" class="jh-btn is-icon" title="확장"><i class="jh-icon-transfer-left"></i></button>
          </p>
        </div>
      </div>
      <div class="jh-card is-mt-5">
        <div class="jh-card-header has-tab">
          <v-tabs right slider-size="4">
            <v-tab>탭1</v-tab>
            <v-tab>탭2</v-tab>
          </v-tabs>
          <p class="jh-tab-button is-left">
              <button type="button" class="jh-btn is-icon" title="고객정보 새로고침"><i class="jh-icon-refresh"></i></button>
              <button type="button" class="jh-btn is-icon" title="확장"><i class="jh-icon-transfer-left"></i></button>
          </p>
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source05">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source05">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "VueTab",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {    
        tab1: 'tab1_1',
        tab2: 'null',
        tab3: 'null',
        tab4: 'null',
        tab5: 'null',
        source01: 'HTML',
        source02: 'HTML',
        source03: 'HTML',
        source04: 'HTML',
        source05: 'HTML',
        content:{

source01_tab01: `<div class="jh-card">
  <div class="jh-card-header has-tab">
    <v-tabs v-model="tab1" slider-size="4">
      <v-tab key="tab1_1">탭1</v-tab>
      <v-tab key="tab1_2">탭2</v-tab>
    </v-tabs>
  </div>
  <div class="jh-card-body">
    <v-tabs-items v-model="tab1">
        <v-tab-item key="tab1_1">
            <div class="tab-contnets">탭1 컨텐츠</div>
        </v-tab-item>
        <v-tab-item key="tab1_2">
            <div class="tab-contnets">탭2 컨텐츠</div>
        </v-tab-item>
    </v-tabs-items>          
  </div>
</div>`
,source01_tab02: `export default {
  data () {
    return {
      tab1: 'tab1_1',
    }
  },
}`
,source02_tab01: `<div class="jh-card">
  <div class="jh-card-header has-tab">
    <v-tabs v-model="tab2" centered slider-size="4">
      <v-tab>탭1</v-tab>
      <v-tab>탭2</v-tab>
    </v-tabs>
  </div>
  <div class="jh-card-body">
    <v-tabs-items v-model="tab2">
        <v-tab-item>
            <div class="tab-contnets">탭1 컨텐츠</div>
        </v-tab-item>
        <v-tab-item>
            <div class="tab-contnets">탭2 컨텐츠</div>
        </v-tab-item>
    </v-tabs-items>          
  </div>
</div>`
,source02_tab02: `export default {
  data () {
    return {
        tab1: 'tab1_1',
    }
  },
}`
,source03_tab01: `<div class="jh-card-header has-tab">
  <v-tabs v-model="tab3" right slider-size="4">
    <v-tab>탭1</v-tab>
    <v-tab>탭2</v-tab>
  </v-tabs>
</div>
<div class="jh-card-body">
  <v-tabs-items v-model="tab3">
      <v-tab-item>
          <div class="tab-contnets">탭1 컨텐츠</div>
      </v-tab-item>
      <v-tab-item>
          <div class="tab-contnets">탭2 컨텐츠</div>
      </v-tab-item>
  </v-tabs-items>          
</div>`
,source03_tab02: `export default {
  data () {
    return {
        tab1: 'tab1_1',
    }
  },
}`
,source04_tab01: `<v-tabs v-model="tab1" vertical slider-size="3">
    <v-tab>탭1</v-tab>
    <v-tab>탭2</v-tab>
    
    <!-- v-tabs 안에 넣어주세요 -->
    <v-tabs-items v-model="tab1">
        <v-tab-item>
            <div class="tab-contnets">탭1 컨텐츠</div>
        </v-tab-item>
        <v-tab-item>
            <div class="tab-contnets">탭2 컨텐츠</div>
        </v-tab-item>
    </v-tabs-items>
</v-tabs>`
,source04_tab02: `export default {
  data () {
    return {
        tab1: 'tab1_1',
    }
  },
}`
,source05_tab01: `<!-- 오른쪽에 버튼 -->
<div class="jh-card">
  <div class="jh-card-header has-tab">
    <v-tabs slider-size="4">
      <v-tab>탭1</v-tab>
      <v-tab>탭2</v-tab>
    </v-tabs>
    <p class="jh-tab-button is-right">
        <button type="button" class="jh-btn is-icon" title="고객정보 새로고침"><i class="jh-icon-refresh"></i></button>
        <button type="button" class="jh-btn is-icon" title="확장"><i class="jh-icon-transfer-left"></i></button>
    </p>
  </div>
</div>

<!-- 양쪽에 버튼 -->
<div class="jh-card is-mt-5">
  <div class="jh-card-header has-tab">
    <v-tabs centered slider-size="4">
      <v-tab>탭1</v-tab>
      <v-tab>탭2</v-tab>
    </v-tabs>
    <p class="jh-tab-button is-left">
        <button type="button" class="jh-btn is-icon" title="고객정보 새로고침"><i class="jh-icon-refresh"></i></button>
        <button type="button" class="jh-btn is-icon" title="확장"><i class="jh-icon-transfer-left"></i></button>
    </p>
    <p class="jh-tab-button is-right">
        <button type="button" class="jh-btn is-icon" title="고객정보 새로고침"><i class="jh-icon-refresh"></i></button>
        <button type="button" class="jh-btn is-icon" title="확장"><i class="jh-icon-transfer-left"></i></button>
    </p>
  </div>
</div>

<!-- 왼쪽에 버튼 -->
<div class="jh-card is-mt-5">
  <div class="jh-card-header has-tab">
    <v-tabs right slider-size="4">
      <v-tab>탭1</v-tab>
      <v-tab>탭2</v-tab>
    </v-tabs>
    <p class="jh-tab-button is-left">
        <button type="button" class="jh-btn is-icon" title="고객정보 새로고침"><i class="jh-icon-refresh"></i></button>
        <button type="button" class="jh-btn is-icon" title="확장"><i class="jh-icon-transfer-left"></i></button>
    </p>
  </div>
</div>
<v-tabs slider-size="3">
    <v-tab>탭1</v-tab>
    <v-tab>탭2</v-tab>
    <p class="tab-button"><!-- 버튼은 P tag로 감싸주세요 -->
        <button type="button" class="jh-btn is-icon" title="고객정보 새로고침"><i class="jh-icon-refresh"></i></button>
        <button type="button" class="jh-btn is-icon" title="확장"><i class="jh-icon-expand"></i></button>
    </p>
</v-tabs>`
      }
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>