<template>
  <v-app class="frame-layout">
    <LayoutTop @drawer="drawer = !drawer"></LayoutTop>
    <v-main>
      <LayoutLeft></LayoutLeft>
      <div class="is-layout-center">
        <LayoutTbas></LayoutTbas>
        <v-fade-transition mode="out-in">
          <LayoutContent></LayoutContent>
        </v-fade-transition>
        <LayoutFooter></LayoutFooter>
      </div>
      <!-- <LayoutRight></LayoutRight> -->
      <LayoutMenu v-model="drawer"></LayoutMenu>
    </v-main>
  </v-app>
</template>

<script>
import LayoutTop from "./TopArea";
// import LayoutMdi from "./MdiArea";
import LayoutTbas from "./TabsArea";
import LayoutLeft from "./LeftArea";
import LayoutRight from "./RightArea";
import LayoutFooter from "./FooterArea";
import LayoutContent from "./ContentsArea";
import LayoutMenu from "./MenuArea";

export default {
  name: "MainLayout",
  components: {
    LayoutTop,
    // LayoutMdi,
    LayoutTbas,
    LayoutLeft,
    LayoutRight,
    LayoutFooter,
    LayoutContent,
    LayoutMenu,
  },
  data: () => ({
    drawer: false,
  }),
  methods: {

  }
};
</script>

<style></style>
