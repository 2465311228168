<template>
  <v-navigation-drawer
    absolute
    permanent
    width="60px"
    color="transparent"
    class="jh-left-nav"
  >
    <ul class="jh-left-nav-list">
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" to="/E999999" @click="activeTab('E999999','HOME')">
              <i class="jh-icon-nav-home"></i>
            </span>
          </template>
          <span>HOME</span>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" to="/E999992" @click="activeTab('E999992','계약관리')">
              <i class="jh-icon-nav-monitor"></i>
            </span>
          </template>
          <span>계약관리</span>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" to="/E999992" @click="activeTab('E999994','갱신리스트관리')">
              <i class="jh-icon-nav-evaluate"></i>
            </span>
          </template>
          <span>갱신리스트관리</span>
        </v-tooltip>
      </li>      
      <li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" to="/E050201" @click="activeTab('E050201','공지사항')">
              <i class="jh-icon-nav-board"></i>
            </span>
          </template>
          <span>공지사항</span>
        </v-tooltip>
      </li> 
      <!-- </li> -->
             
        <!-- <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
              <i class="jh-icon-nav-sms"></i>
            </span>
          </template>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openE030201P01">
              <i class="jh-icon-nav-mail"></i>
            </span>
          </template>
          <span>이메일발송</span>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openMOPopup">
              <i class="jh-icon-nav-fax"></i>
            </span>
          </template>
          <span>FAX/MO</span>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openSTTAdminPopup">
              <i class="jh-icon-nav-stt"></i>
            </span>
          </template>
          <span>STT관리자</span>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openE050201P03">
              <i class="jh-icon-nav-notice"></i>
            </span>
          </template>
          <span>공지사항</span>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openE050203P03">
              <i class="jh-icon-nav-board"></i>
            </span>
          </template>
          <span>자유게시판</span>
        </v-tooltip>
      </li> -->
      <!-- <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openEvaluationSystemPopup">
              <i class="jh-icon-nav-evaluate"></i>
            </span>
          </template>
          <span>상담평가시스템</span>
        </v-tooltip>
      </li> -->
      <!-- <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openE010501P01">
              <i class="jh-icon-nav-calendar"></i>
            </span>
          </template>
          <span>캘린더/상장책자신청</span>
        </v-tooltip>
      </li> -->
      <!-- <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openStatisticsPopup">
              <i class="jh-icon-nav-state"></i>
            </span>
          </template>
          <span>통계시스템</span>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openPT100101">
              <i class="jh-icon-nav-map"></i>
            </span>
          </template>
          <span>지점정보</span>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openPT100901">
              <i class="jh-icon-nav-kms"></i>
            </span>
          </template>
          <span>지식함</span>
        </v-tooltip>
      </li>
      <li>
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="openPT101001">
              <i class="jh-icon-nav-memo"></i>
            </span>
          </template>
          <span>메모</span>
        </v-tooltip>
      </li> -->
      <!-- <li v-show="true">
        <v-tooltip content-class="jh-tooltip" bottom nudge-right="30" nudge-top="15">
          <template v-slot:activator="{ on, attrs }">
            <span class="jh-nav-btn" v-bind="attrs" v-on="on" @click="testLink = !testLink">
            </span>
          </template>
          <span>테스트링크</span>
        </v-tooltip>
      </li> -->
    </ul>
    <!-- <transition name="slide-down">
      <div class="jh-drop-layer is-p-10" style="bottom: 10px; left: 50px" v-show="testLink">
        <button type="button" class="jh-btn is-md is-main" @click="downloadLog();testLink=false">다운로드</button>
      </div>
    </transition> -->
    <div>
      <P100101Pop ref="p100101Pop" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtMain="val=>onRecvRsvPop(val)"></P100101Pop>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mixin } from "@/mixin/mixin";
import WinPop from "@/views/popup/WinPop.vue";
import { mapGetters } from "vuex";

const RDWP_SERVER_URL = process.env.RDWP_SERVER_URL;
const RDWP_SERVER_PORT = process.env.RDWP_SERVER_PORT;

export default {
  mixins: [mixin],
  components: {  
    'P100101Pop' : WinPop,
  },
  data() {
    return {
      right: null,
      smsWinPopup: null,
      isInitSmsWinPopup: true,
      emailWinPopup: null,
      isInitEmailWinPopup: true,

      testLink: false
    };
  },
  computed: {
    ...mapGetters({
      geCallStt: 'callStore/GE_CALL_STATE',
      geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
    }),
  },
  methods: {
    activeTab(NODE_NO, NODE_TITLE){
      this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
        id: NODE_NO,
        title: NODE_TITLE,
        default: "N",
        link: "/" + NODE_NO,
      });
    },
    openP100101(){
      //this.$openWindow('P100101','P100101','1780','855');
      this.$refs.p100101Pop.openWinPop2('/P100101','1780','890');
    },
    evtCloseWinPopup(val) {
      console.log("=====>clsPop" + val);
    },
    onRecvRsvPop(recvObj) {
      console.log("onRecvRsvPop(recvObj) ", recvObj)
      if (this.geCallStt == 'TALKING') {
        this.common_alert(`통화중입니다.`, 'info');
      } else if (this.geCallStt == 'A_SETACW' && this.geCnslSaveYn == 'N') {
        this.common_alert(`상담이력을 저장해주세요.`, 'info');
      } else {
        let param = recvObj.Val1
        if(recvObj.evt != null) {
          console.log('E010101 >>>> onRecvRsvPop:' + JSON.stringify(param))
        }
            console.log('E010101 >>>> param:' + param)

        let custParam = {
          SRCH_ACCOUNT : param.SRCH_ACCOUNT,
          CLNT_NO : param.CUST_NO
        }
  
        if(param.CUST_NO == '00000000') custParam.SRCH_CL_CID = "9999999999999";
        
        this.$store.dispatch("callStore/AC_CUST_INFO", custParam);
        this.setActiveTab(custParam);
      }
    },
    openE030101P01(){
      // window.open("E030101P01?agentId="+Math.random() * 40, 'WebPlay', 'width=780,height=360');
      window.open('E030101P01?agentId='+Math.random() * 40,'_blank', 'width=가로폭, height=세로폭, toolbar=no, menubar=no, scrollbars=yes, resizable=yes');

      // if (!this.smsWinPopup) {
      //   this.smsWinPopup = this.$openWindow('E030101P01?agentId='+Math.random() * 40,'_blank','1100','908');

      //   // this.$store.commit("popStore/MU_POP", this.smsWinPopup);


      //   this.smsWinPopup.addEventListener("unload", e => {
      //     if (!this.isInitSmsWinPopup && this.smsWinPopup){
      //       this.smsWinPopup.close();
      //       this.smsWinPopup = null;
      //       this.isInitSmsWinPopup = true;
      //     }
      //     else this.isInitSmsWinPopup = false;
      //   });
      // }
    },
    openE030201P01(){
      if (!this.emailWinPopup) {
        this.emailWinPopup = this.$openWindow('E030201P01','_blank','1600','900');
        this.emailWinPopup.addEventListener("unload", e => {
          if (!this.isInitEmailWinPopup && this.emailWinPopup){
            this.emailWinPopup.close();
            this.emailWinPopup = null;
            this.isInitEmailWinPopup = true;
          }
          else this.isInitEmailWinPopup = false;
        });
      }
    },
    openWindow() {
        let left = (screen.width - 620) / 2,
            top = (screen.height - 700) / 4
        window.open("http://localhost:3000/#/ttalk_main/ebest", 'name', "width="+620 +",height="+700+",left="+left+",top="+top);
    },
    openE010501P01(){
      this.$openWindow('E010501P01','E010501P01','1390','890');
    },
    openE050201P03(){
      this.$openWindow('E050201P03','E050201P03','1300','890');
    },
    openE050203P03(){
      this.$openWindow('E050203P03','E050203P03','1300','890');
    },
    openPT100101(){
      this.$openWindow('PT100101','PT100101','850','880');
    },
    openPT100901(){
      this.$openWindow('PT100901','PT100901','1200','900');
    },
    openPT101001(){
      this.$openWindow('PT101001','PT101001','500','900');
    },
    // 외부 시스템
    openMOPopup: function() {
      // this.$openWindow("https://172.17.10.167", "MO");
      // https 기본 포트인 443을 http로 사 용 중.... 차후 바뀔지도
      this.$openWindow("https://172.17.10.167:10443", "MO");
    },
    openSTTAdminPopup: function() {
      var VUE_APP_STT_SERVER_URL2 = process.env.VUE_APP_STT_SERVER_URL2
      var VUE_APP_STT_SERVER_PORT2 = process.env.VUE_APP_STT_SERVER_PORT2
      // this.$openWindow("https://172.17.10.168:28081", "STT");
      this.$openWindow("https://" + VUE_APP_STT_SERVER_URL2 + ":" + VUE_APP_STT_SERVER_PORT2, "STT");
    },
    openEvaluationSystemPopup: function() {
      var VUE_APP_RDWP_SERVER_URL = process.env.VUE_APP_RDWP_SERVER_URL
      var VUE_APP_RDWP_SERVER_PORT = process.env.VUE_APP_RDWP_SERVER_PORT      
      // this.$openWindow("https://voicerec.ebestsec.co.kr:18088", "상담평가시스템");
      this.$openWindow(VUE_APP_RDWP_SERVER_URL + ":" + VUE_APP_RDWP_SERVER_PORT, "상담평가시스템");
    },
    openStatisticsPopup: function() {
      this.$openWindow("https://172.17.10.176:8443/stat/login.action", "통계시스템");
    },
    // async downloadLog(){
    //   let date = new Date();

    //   let Y = date.getFullYear();
    //   let M = date.getMonth();
    //   let D = date.getDate();
    //   let H = date.getHours();
    //   let Min = date.getMinutes();
    //   let S = date.getSeconds();

    //   H = H < 10 ? ('0' + H) : H;
    //   Min = Min < 10 ? ('0' + Min) : Min;
    //   S = S < 10 ? ('0' + S) : S;

    //   let filename = "로그파일_" + Y + M + D + "_" + H + ":" + Min + ":" + ":" + S + ".txt"

    //   const a = document.createElement('a');
    //   const type = filename.split(".").pop();
    //   a.href = URL.createObjectURL(new Blob([this.$store.getters["logStore/GE_LOG"]], {type:`test/${type === "txt" ? "plain" : type}` }));
    //   a.download = filename;
    //   a.click();

    // },
    openPopup(url,name,width,height) {
        let left = (screen.width - width) / 2,
            top = (screen.height - height) / 4
        window.open(url, name, "width="+width +",height="+height+",left="+left+",top="+top);

        window.addEventListener('message', this.recvEvtFromChild, false);
    },
    recvEvtFromChild(evt) {
        if (evt.data == null) {
            return;
        }
        console.log(">>>>>>>>>>>>>" + JSON.stringify(evt.data));
        let recvObj = JSON.parse(evt.data);

        this.$store.dispatch("callStore/AC_CUST_INFO", recvObj);
        this.setActiveTab(recvObj);
    },
    setActiveTab(item) {
        let acTabs = this.$store.getters["commonStore/GE_COMMON_TAB"];
        let aleady = false; //기존에 있는 탭인지 여부
        for(let i = 0; i < acTabs.length; i++) {
          if(acTabs[i].id === "E010101") {
            console.log("tab[" + acTabs[i].id + "]");
            aleady = true;
            break;
          }
        }
        if(aleady) {
          //this.$store.dispatch('callStore/AC_CUST_SRCH_MODE', "init");

          this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
            id: "E010101",
            title: "콜상담 메인",
            link: "/E010101"
          });
          if(!this.mixin_isEmpty(item.SRCH_ACCOUNT)) {
            this.$store.dispatch('callStore/AC_CUST_SRCH_MODE', "search");
          } else if (item.SRCH_CL_CID == '9999999999999') {
            this.$store.dispatch('callStore/AC_CUST_SRCH_MODE', "search2");
          } else if (!this.mixin_isEmpty(item.CLNT_NO)) {
            this.$store.dispatch('callStore/AC_CUST_SRCH_MODE', "search3");
          }
          return;
        }

        // 콜상담 메인 탭 오픈
        this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
                id: "E010101",
                title: "콜상담 메인",
                default: "N",
                tabIcon: "",
                link: "/E010101"
        });
    },
    setActiveTab2() {
      // 콜상담 메인 탭 닫기
      this.$store.dispatch("commonStore/AC_COMMON_DEACTIVE_TAB", {
        id: "E010101",
        title: "콜상담 메인",
        default: "N",
        tabIcon: "",
        link: "/E010101"
      });
      // 콜상담 메인 탭 오픈
      this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
        id: "E010101",
        title: "콜상담 메인",
        default: "N",
        tabIcon: "",
        link: "/E010101"
      });
    },
  },
};
</script>

<style></style>
