<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Styles<v-icon class="mdi mdi-chevron-right"></v-icon><span>Cols Group</span></div>

      <p class="jh-info-txt is-mt-30"><em>.jh-cols</em>의 첫번째 자식들은 <em>1/n</em>로 균등한 <strong>width</strong> 값을 갖는다.</p>
      <div
        class="guide-card"             
        outlined>
        <div class="jh-cols">
            <input type="text" class="jh-form" value="1/2">
            <input type="text" class="jh-form" value="1/2">
        </div>
        <div class="jh-cols">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
        </div>
        <div class="jh-cols">
            <input type="text" class="jh-form" value="1/4">
            <input type="text" class="jh-form" value="1/4">
            <input type="text" class="jh-form" value="1/4">
            <input type="text" class="jh-form" value="1/4">
        </div>
        <div class="jh-cols">
            <input type="text" class="jh-form" value="1/5">
            <input type="text" class="jh-form" value="1/5">
            <input type="text" class="jh-form" value="1/5">
            <input type="text" class="jh-form" value="1/5">
            <input type="text" class="jh-form" value="1/5">
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <p class="jh-info-txt is-mt-40"><em>.jh-unit, .jh-split</em>과 <em>button</em>은 자신의 고유한 <strong>width</strong> 값을 갖는다.</p>
      <div
        class="guide-card"             
        outlined>
        <div class="jh-cols">
            <input type="text" class="jh-form">
            <span class="jh-unit">%</span>
            <input type="text" class="jh-form">
            <span class="jh-split"></span>
            <input type="text" class="jh-form">
            <button type="button" class="jh-btn is-search">조회</button>
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <p class="jh-info-txt is-mt-40">자식 요소에 <em>.is-col-fix</em>를 추가하고, <em>style="width:200px"</em>와 같이 스타일 정의를 하여 고유값을 설정할 수 있다.(%도 가능)</p>
      <div
        class="guide-card"             
        outlined>
        <div class="jh-cols">
            <input type="text" class="jh-form is-col-fix" style="width:200px" value="200px">
            <input type="text" class="jh-form" value="100% - 200px - 30%">
            <input type="text" class="jh-form is-col-fix" style="width:30%" value="30%">
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div class="jh-info-txt is-mt-40">요소 사이의 간격은 기본 <em>10px</em>이다.</div>
      <div class="jh-info-txt">요소 사이의 간격은  <em>.jh-cols</em>에 <em>.is-space-0, .is-space-5, .is-space-10, .is-space-15, .is-space-20</em> 등을 추가하여 조정할 수 있다.</div>
      <div
        class="guide-card"             
        outlined>
        <div class="jh-cols is-space-0">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
        </div>
        <div class="jh-cols is-space-5">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
        </div>
        <div class="jh-cols is-space-10">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
        </div>
        <div class="jh-cols is-space-15">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
        </div>
        <div class="jh-cols is-space-20">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
            <input type="text" class="jh-form" value="1/3">
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div class="jh-info-txt is-mt-40">&lt;th&gt;, &lt;td&gt; 내 요소 사이의 간격은 기본 <em>2px</em>이다.</div>
      <div
        class="guide-card"             
        outlined>
        <table>
            <tr>
                <th>
                    <div class="jh-cols">
                        <input type="text" class="jh-form">
                        <span class="jh-unit">%</span>
                        <input type="text" class="jh-form">
                        <span class="jh-split"></span>
                        <input type="text" class="jh-form">
                        <v-btn class="jh-btn is-fill">선택</v-btn>
                        <v-btn type="button" class="jh-btn">초기화</v-btn>
                    </div>
                </th>
            </tr>
        </table>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Cols",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {        
          source01: 'HTML',
          content:{

source01_tab01: `<div class="jh-cols">
    <input type="text" class="jh-form" value="1/2">
    <input type="text" class="jh-form" value="1/2">
</div>
<div class="jh-cols">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
</div>
<div class="jh-cols">
    <input type="text" class="jh-form" value="1/4">
    <input type="text" class="jh-form" value="1/4">
    <input type="text" class="jh-form" value="1/4">
    <input type="text" class="jh-form" value="1/4">
</div>
<div class="jh-cols">
    <input type="text" class="jh-form" value="1/5">
    <input type="text" class="jh-form" value="1/5">
    <input type="text" class="jh-form" value="1/5">
    <input type="text" class="jh-form" value="1/5">
    <input type="text" class="jh-form" value="1/5">
</div>`
,source02_tab01: `<div class="jh-cols">
    <input type="text" class="jh-form">
    <span class="jh-unit">%</span>
    <input type="text" class="jh-form">
    <span class="jh-split"></span>
    <input type="text" class="jh-form">
    <button type="button" class="jh-btn is-search">조회</button>
</div>`
,source03_tab01: `<div class="jh-cols">
    <input type="text" class="jh-form is-col-fix" style="width:200px" value="200px">
    <input type="text" class="jh-form" value="100% - 200px - 30%">
    <input type="text" class="jh-form is-col-fix" style="width:30%" value="30%">
</div>`
,source04_tab01: `<div class="jh-cols is-space-0">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
</div>
<div class="jh-cols is-space-5">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
</div>
<div class="jh-cols is-space-10">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
</div>
<div class="jh-cols is-space-15">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
</div>
<div class="jh-cols is-space-20">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
    <input type="text" class="jh-form" value="1/3">
</div>`
,source05_tab01: `<table>
    <tr>
        <th>
            <div class="jh-cols">
                <input type="text" class="jh-form">
                <span class="jh-unit">%</span>
                <input type="text" class="jh-form">
                <span class="jh-split"></span>
                <input type="text" class="jh-form">
                <button type="button" class="jh-btn is-fill">선택</button>
                <button type="button" class="jh-btn">초기화</button>
            </div>
        </th>
    </tr>
</table>`
          }
      }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>