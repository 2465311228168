<template>
  <div class="jh-grid-wrapper">
    <v-data-table
      ref="dataTable"
      class="jh-grid"
      :class="dataTableClass"
      :calculate-widths="dataTableOptions.calculateWidths"
      :caption="dataTableOptions.caption"
      :checkbox-color="dataTableOptions.checkboxColor"
      :custom-filter="dataTableOptions.customFilter"
      :custom-group="dataTableOptions.customGroup"
      :custom-sort="dataTableOptions.customSort"
      :dark="dataTableOptions.dark"
      :dense="dataTableOptions.dense"
      :disable-filtering="dataTableOptions.disableFiltering"
      :disable-pagination="dataTableOptions.disablePagination"
      :disable-sort="dataTableOptions.disableSort"
      :expand-icon="dataTableOptions.expandIcon"
      :expanded.sync="dataTableOptions.expanded"
      :fixed-header="dataTableOptions.fixedHeader"
      :footer-props="dataTableOptions.footerProps"
      :group-by.sync="dataTableOptions.groupBy"
      :group-desc.sync="dataTableOptions.groupDesc"
      :header-props="dataTableOptions.headerProps"
      :headers="dataTableOptions.headers"
      :headers-length="dataTableOptions.headersLength"
      :height="dataTableOptions.height"
      :hide-default-footer="dataTableOptions.hideDefaultFooter"
      :hide-default-header="dataTableOptions.hideDefaultHeader"
      :item-class="dataTableOptions.itemClass"
      :item-key="dataTableOptions.itemKey"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :light="dataTableOptions.light"
      :loader-height="dataTableOptions.loaderHeight"
      :loading="dataTableOptions.loading"
      :loading-text="dataTableOptions.loadingText"
      :locale="locale"
      :mobile-breakpoint="dataTableOptions.mobileBreakpoint"
      :multi-sort="dataTableOptions.multiSort"
      :must-sort="dataTableOptions.mustSort"
      :no-data-text="dataTableOptions.noDataText"
      :no-results-text="dataTableOptions.noResultsText"
      :options.sync="options"
      :page.sync="page"
      :search="dataTableOptions.search"
      :selectable-key="dataTableOptions.selectableKey"
      :server-items-length="serverItemsLength"
      :show-expand="dataTableOptions.showExpand"
      :show-group-by="dataTableOptions.showGroupBy"
      :show-select="dataTableOptions.showSelect"
      :single-expand="dataTableOptions.singleExpand"
      :single-select="dataTableOptions.singleSelect"
      :sort-by.sync="dataTableOptions.sortBy"
      :sort-desc.sync="dataTableOptions.sortDesc"
      :value="value"
      v-on="dataTablesListeners"
    >
      <template
        v-if="$scopedSlots.body || $slots.body"
        v-slot:body="{
          items,
          originalItemsLength,
          pagination,
          options,
          groupedItems,
          updateOptions,
          sort,
          sortArray,
          group,
          headers,
          isMobile,
          isSelected,
          select,
          isExpanded,
          expand
        }"
      >
        <slot name="body"
          :items="items"
          :originalItemsLength="originalItemsLength"
          :pagination="pagination"
          :options="options"
          :groupedItems="groupedItems"
          :updateOptions="updateOptions"
          :sort="sort"
          :sortArray="sortArray"
          :group="group"
          :headers="headers"
          :isMobile="isMobile"
          :isSelected="isSelected"
          :select="select"
          :isExpanded="isExpanded"
          :expand="expand"
        />
      </template>
      <template
        v-if="$scopedSlots['body.append'] || $slots['body.append']"
        v-slot:body.append="{
          items,
          originalItemsLength,
          pagination,
          options,
          groupedItems,
          updateOptions,
          sort,
          sortArray,
          group,
          headers,
          isMobile,
          isSelected,
          select,
          isExpanded,
          expand
        }"
      >
        <slot name="body.append"
          :items="items"
          :originalItemsLength="originalItemsLength"
          :pagination="pagination"
          :options="options"
          :groupedItems="groupedItems"
          :updateOptions="updateOptions"
          :sort="sort"
          :sortArray="sortArray"
          :group="group"
          :headers="headers"
          :isMobile="isMobile"
          :isSelected="isSelected"
          :select="select"
          :isExpanded="isExpanded"
          :expand="expand"
        />
      </template>
      <template
        v-if="$scopedSlots['body.prepend'] || $slots['body.prepend']"
        v-slot:body.prepend="{
          items,
          originalItemsLength,
          pagination,
          options,
          groupedItems,
          updateOptions,
          sort,
          sortArray,
          group,
          headers,
          isMobile,
          isSelected,
          select,
          isExpanded,
          expand
        }"
      >
        <slot name="body.prepend"
          :items="items"
          :originalItemsLength="originalItemsLength"
          :pagination="pagination"
          :options="options"
          :groupedItems="groupedItems"
          :updateOptions="updateOptions"
          :sort="sort"
          :sortArray="sortArray"
          :group="group"
          :headers="headers"
          :isMobile="isMobile"
          :isSelected="isSelected"
          :select="select"
          :isExpanded="isExpanded"
          :expand="expand"
        />
      </template>
      <template
        v-if="$scopedSlots['expanded-item'] || $slots['expanded-item']"
        v-slot:expanded-item="{ isMobile, item, headers }">
        <slot name="expanded-item"
          :isMobile="isMobile"
          :item="item"
          :headers="headers"
        />
      </template>
      <template
        v-if="$scopedSlots.foot || $slots.foot"
        v-slot:foot="{
          items,
          originalItemsLength,
          pagination,
          options,
          groupedItems,
          updateOptions,
          sort,
          sortArray,
          group
        }">
        <slot name="foot"
          :items="items"
          :originalItemsLength="originalItemsLength"
          :pagination="pagination"
          :options="options"
          :groupedItems="groupedItems"
          :updateOptions="updateOptions"
          :sort="sort"
          :sortArray="sortArray"
          :group="group"
        />
      </template>
      <template
        v-if="$scopedSlots.footer || $slots.footer"
        v-slot:footer="{
          props,
          on,
          headers,
          widths
        }">
        <slot name="footer"
          :props="props"
          :on="on"
          :headers="headers"
          :widths="widths"
        />
      </template>
      <template
        v-if="$scopedSlots['footer.page-text'] || $slots['footer.page-text']"
        v-slot:footer.page-text="{
          pageStart,
          pageStop,
          itemsLength
        }">
        <slot name="footer.page-text"
          :pageStart="pageStart"
          :pageStop="pageStop"
          :itemsLength="itemsLength"
        />
      </template>
      <template
        v-if="$scopedSlots['footer.prepend'] || $slots['footer.prepend']"
        v-slot:footer.prepend>
        <slot name="footer.prepend" />
      </template>
      <template
        v-if="$scopedSlots['group.header'] || $slots['group.header']"
        v-slot:group.header="{
          group,
          groupBy,
          isMobile,
          items,
          headers,
          isOpen,
          toggle,
          remove
        }"
      >
        <slot name="group.header"
          :group="group"
          :groupBy="groupBy"
          :isMobile="isMobile"
          :items="items"
          :headers="headers"
          :isOpen="isOpen"
          :toggle="toggle"
          :remove="remove"
        />
      </template>
      <template
        v-if="$scopedSlots['group.summary'] || $slots['group.summary']"
        v-slot:group.summary="{
          group,
          groupBy,
          isMobile,
          items,
          headers,
          isOpen,
          toggle
        }"
      >
        <slot name="group.summary"
          :group="group"
          :groupBy="groupBy"
          :isMobile="isMobile"
          :items="items"
          :headers="headers"
          :isOpen="isOpen"
          :toggle="toggle"
        />
      </template>
      <template
        v-if="$scopedSlots.header || $slots.header"
        v-slot:header="{
          isMobile,
          props,
          on
        }"
      >
        <slot name="header"
          :isMobile="isMobile"
          :props="props"
          :on="on"
        />
      </template>
      <template
        v-for="headerColumnName in headerColumnNameSlots"
        v-slot:[headerColumnName]="{
          header
        }"
      >
        <slot :name="headerColumnName"
          :header="header"
        />
      </template>
      <template
        v-if="$scopedSlots['header.data-table-select'] || $slots['header.data-table-select']"
        v-slot:header.data-table-select="{
          isMobile,
          props,
          on
        }"
      >
        <slot name="header.data-table-select"
          :isMobile="isMobile"
          :props="props"
          :on="on"
        />
      </template>
      <template
        v-if="$scopedSlots.item || $slots.item"
        v-slot:item="{
          expand,
          index,
          item,
          isExpanded,
          isMobile,
          isSelected,
          select,
          headers
        }"
      >
        <slot name="item"
          :expand="expand"
          :index="index"
          :item="item"
          :isExpanded="isExpanded"
          :isMobile="isMobile"
          :isSelected="isSelected"
          :select="select"
          :headers="headers"
        />
      </template>
      <template
        v-for="itemColumnName in itemColumnNameSlots"
        v-slot:[itemColumnName]="{
          isMobile,
          item,
          header,
          value,
          index
        }"
      >
        <slot :name="itemColumnName"
          :isMobile="isMobile"
          :item="item"
          :header="header"
          :value="value"
          :index="index"
        />
      </template>
      <template
        v-if="$scopedSlots['item.data-table-expand'] || $slots['item.data-table-expand']"
        v-slot:item.data-table-expand="{
          expand,
          index,
          item,
          isExpanded,
          isMobile,
          isSelected,
          select,
          headers
        }"
      >
        <slot name="item.data-table-expand"
          :expand="expand"
          :index="index"
          :item="item"
          :isExpanded="isExpanded"
          :isMobile="isMobile"
          :isSelected="isSelected"
          :select="select"
          :headers="headers"
        />
      </template>
      <template
        v-if="$scopedSlots['item.data-table-select'] || $slots['item.data-table-select']"
        v-slot:item.data-table-select="{
          expand,
          index,
          item,
          isExpanded,
          isMobile,
          isSelected,
          select,
          headers
        }"
      >
        <slot name="item.data-table-select"
          :expand="expand"
          :index="index"
          :item="item"
          :isExpanded="isExpanded"
          :isMobile="isMobile"
          :isSelected="isSelected"
          :select="select"
          :headers="headers"
        />
      </template>
      <template
        v-if="$scopedSlots.loading || $slots.loading"
        v-slot:loading>
        <slot name="loading" />
      </template>
      <template
        v-if="$scopedSlots['no-data'] || $slots['no-data']"
        v-slot:no-data>
        <slot name="no-data" />
      </template>
      <template
        v-if="$scopedSlots['no-results'] || $slots['no-results']"
        v-slot:no-results>
        <slot name="no-results" />
      </template>
      <template
        v-if="$scopedSlots.progress || $slots.progress"
        v-slot:progress>
        <slot name="progress" />
      </template>
      <template
        v-if="$scopedSlots.top || $slots.top"
        v-slot:top="{
          items,
          originalItemsLength,
          pagination,
          options,
          groupedItems,
          updateOptions,
          sort,
          sortArray,
          group,
          isMobile
        }"
      >
        <slot name="top"
          :items="items"
          :originalItemsLength="originalItemsLength"
          :pagination="pagination"
          :options="options"
          :groupedItems="groupedItems"
          :updateOptions="updateOptions"
          :sort="sort"
          :sortArray="sortArray"
          :group="group"
          :isMobile="isMobile"
        />
      </template>
    </v-data-table>
    <div class="jh-pager" v-if="!dataTableOptions.invisiblePagination">          
      <v-pagination
        ref="pagination"
        v-if="!dataTableOptions.disablePagination"
        :class="paginationClass"
        :circle="paginationOptions.circle"
        :color="paginationOptions.color"
        :current-page-aria-label="paginationOptions.currentPageAriaLabel"
        :dark="paginationOptions.dark"
        :disabled="paginationOptions.disabled"
        :length="pageCount"
        :light="paginationOptions.light"
        :next-aria-label="paginationOptions.nextAriaLabel"
        :next-icon="paginationOptions.nextIcon"
        :page-aria-label="paginationOptions.pageAriaLabel"
        :prev-icon="paginationOptions.prevIcon"
        :previous-aria-label="paginationOptions.previousAriaLabel"
        :total-visible="paginationOptions.totalVisible"
        :value="paginationOptions.value"
        :wrapper-aria-label="paginationOptions.wrapperAriaLabel"
        v-model="page"
        v-on="paginationListeners"
      />
      <v-select
        v-if="!dataTableOptions.disablePagination"
        class="jh-form is-round is-right"
        :items="perPages"
        v-model="options.itemsPerPage"
        label="10"
        style="width: 70px"
      />
      <span class="jh-counter is-left">총 <em>{{ serverItemsLength || totalCount }}</em> 건</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    dataTableClass: {
      type: [ String, Object, Array ],
      default: ""
    },
    dataTableOptions: {
      type: Object,
      default: () => {}
    },
    paginationClass: {
      type: [ String, Object, Array ],
      default: ""
    },
    paginationOptions: {
      type: Object,
      default: () => {}
    },
    value: undefined
  },
  data() {
    return {
      isInitComplete: false,

      locale: "ko-KR",
      itemsPerPage: 50,
      page: 1,
      pageCount: undefined,

      perPages: [
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '150', value: 150 },
        { text: '200', value: 200 }
        // { text: 'All', value: -1 }
      ],

      options: {},

      items: [],
      totalCount: 0,

      serverItemsLength: undefined
    };
  },
  computed: {
    dataTableInstance: function() {
      return this.$refs.dataTable;
    },
    paginationInstance: function() {
      return this.$refs.pagination;
    },
    dataTablesListeners: function() {
      let that = this;

      return Object.assign(
        {},
        this.$listeners,
        {
          "page-count": function(event) {
            that.$emit("page-count", event);
            that.pageCount = event;
          }
        }
      );
    },
    paginationListeners: function() {
      let that = this;
      let paginationListeners = _.reduce(this.paginationOptions, function(result, value, key) {
        if (_.eq(key, "input") || _.eq(key, "next") || _.eq(key, "previous")) {
          result[key] = value;
        }
        return result;
      }, {});

      return Object.assign(
        {},
        paginationListeners,
        {}
      );
    },
    headerColumnNameSlots: function() {
      let that = this;
      return _.reduce(
        this.dataTableOptions.headers,
        function(result, header, index) {
          let columnName = `header.${header.value}`;
          if (that.$scopedSlots[columnName] || that.$slots[columnName]) {
            result.push(columnName);
          }
          return result;
        },
        []
      );
    },
    itemColumnNameSlots: function() {
      let that = this;
      return _.reduce(
        this.dataTableOptions.headers,
        function(result, header, index) {
          let columnName = `item.${header.value}`;
          if (that.$scopedSlots[columnName] || that.$slots[columnName]) {
            result.push(columnName);
          }
          return result;
        },
        []
      );
    }
  },
  methods: {
    loadData: async function() {
      if (this.dataTableOptions.load) {
        this.dataTableOptions.loading = true;

        let rtn = this.dataTableOptions.load(this.options);

        if (rtn instanceof Promise) {
          const res = await rtn;

          this.items = res.data;
          this.totalCount = res.totalCount;
          this.serverItemsLength = res.totalCount;

          this.dataTableOptions.loading = false;
        }
        else {
          this.items = rtn.data;
          this.totalCount = rtn.totalCount;

          this.dataTableOptions.loading = false;
        }
      }
    },

    getData: function() {
      return this.dataTableInstance.internalCurrentItems;
    },
    getTotalCount: function() {
      return this.serverItemsLength || this.totalCount;
    },
    getSelectedData: function() {
      return this.dataTableInstance.selection;
    },
    getExpandedData: function() {
      return this.dataTableInstance.expanded;
    },
    
    addRow: function(row) {
      this.items.push(row);
    },
    updateRow: function(index, fieldName, newValue) {
      let row = this.items[index];
      row[fieldName] = newValue;
    },
    deleteRow: function(index) {
      this.items.splice(index, 1);
    }
  },
  created() {
    if (this.dataTableOptions.locale) this.locale = this.dataTableOptions.locale;

    if (this.dataTableOptions.options) this.options = this.dataTableOptions.options;

    this.page = this.dataTableOptions.page;
    if (this.paginationOptions && this.paginationOptions.perPages) this.perPages = this.paginationOptions.perPages;
    if (this.perPages.length > 0 && this.perPages[0]) {
      if (!this.dataTableOptions.itemsPerPage) {
        if (typeof this.perPages[0] == "object") this.itemsPerPage = this.perPages[0].value;
        else if (typeof this.perPages[0] == "string") this.itemsPerPage = parseInt(this.perPages[0]);
        else if (typeof this.perPages[0] == "number") this.itemsPerPage = this.perPages[0];
      }
      else {
        if (typeof this.perPages[0] == "object" && !_.find(this.perPages, { value: this.itemsPerPage })) this.itemsPerPage = this.perPages[0].value;
        else if (typeof this.perPages[0] == "string" && !_.find(this.perPages, new String(this.itemsPerPage))) this.itemsPerPage = parseInt(this.perPages[0]);
        else if (typeof this.perPages[0] == "number" && !_.find(this.perPages, parseInt(this.itemsPerPage))) this.itemsPerPage = this.perPages[0];
        else this.itemsPerPage = this.dataTableOptions.itemsPerPage;
      }
    }

    if (this.dataTableOptions.items) {
      this.items = this.dataTableOptions.items;
      this.totalCount = this.dataTableOptions.items.length;
    }

    this.isInitComplete = true;
  },
  mounted() {
    // console.log(this.dataTableInstance);
    // console.log(this.paginationInstance);
  },
  watch: {
    items: {
      handler: function(newValue) {
        this.dataTableOptions.items = newValue;
      },
      deep: true
    },
    options: {
      handler: function(newValue, oldValue) {
        if (this.isInitComplete) {
          this.dataTableOptions.options = newValue;
          if (this.serverItemsLength > 0) {
            let newPageCount = newValue.itemsPerPage == -1 ? 1 : Math.ceil(this.serverItemsLength / newValue.itemsPerPage );
            if (newPageCount < this.dataTableOptions.page) {
              this.dataTableOptions.page = newPageCount;
            }
            else this.loadData();
          }
          else this.loadData();
        }
      }
    },
    itemsPerPage: function(newValue) {
      this.dataTableOptions.itemsPerPage = newValue;
    },
    page: function(newValue) {
      this.dataTableOptions.page = newValue;
    },

    "dataTableOptions.items": {
      handler: function(newValue) {
        this.items = newValue;
        this.totalCount = newValue.length;
      },
      deep: true
    },
    "dataTableOptions.itemsPerPage": function(newValue) {
      if (this.perPages.length > 0 && this.perPages[0]) {
        if (!newValue) {
          if (typeof this.perPages[0] == "object") this.itemsPerPage = this.perPages[0].value;
          else if (typeof this.perPages[0] == "string") this.itemsPerPage = parseInt(this.perPages[0]);
          else if (typeof this.perPages[0] == "number") this.itemsPerPage = this.perPages[0];
        }
        else {
          if (typeof this.perPages[0] == "object" && !_.find(this.perPages, { value: this.itemsPerPage })) this.itemsPerPage = this.perPages[0].value;
          else if (typeof this.perPages[0] == "string" && !_.find(this.perPages, new String(this.itemsPerPage))) this.itemsPerPage = parseInt(this.perPages[0]);
          else if (typeof this.perPages[0] == "number" && !_.find(this.perPages, parseInt(this.itemsPerPage))) this.itemsPerPage = this.perPages[0];
          else this.itemsPerPage = newValue;
        }
      }
      else this.dataTableOptions.itemsPerPage = 50;
    },
    "dataTableOptions.load": function(newValue) {
      this.loadData();
    },
    "dataTableOptions.options": {
      handler: function(newValue) {
        this.options = newValue;
      },
      deep: true
    },
    "dataTableOptions.page": function(newValue) {
      this.page = newValue;
    },
    "dataTableOptions.perpages": function(newValue) {
      if (newValue) this.perPages = newValue;
    }
  }
}
</script>

<style scoped>

</style>