<template>
    <div>
        <div class="jh-card-header is-jh-10">
            <!--div class="is-right">
                <v-btn class="jh-btn is-del is-border-pink" @click="fnCompAcctNo">계좌번호(TEST)</v-btn>
                <v-btn class="jh-btn is-del is-border-pink" @click="fnCompRrno">주민번호(TEST)</v-btn>
            </div-->
        </div>		
        <div class="jh-call-alert-body">
            <i class="jh-icon-call-signal"></i>
            <ul class="jh-call-alert-list">
                <li>
                    <label>고객대기시간</label>
                    <div class="is-bg-light-red is-txt-red">{{ STD_TIME }}</div>
                </li>
                <li>
                    <label>인입경로</label>
                    <div class="is-multi">{{ IVR_IN_PATH }}</div>
                </li>
                <li>
                    <label>고객명</label>
                    <div>{{ CUST_NM }}</div>
                </li>
                <li>
                    <label>고객정보(MEMO)</label>
                    <div class="is-multi"><span>{{ CUST_MEMO }}</span></div>
                    <!--div >
                        <textarea class="jh-form" style="height: 60px;" v-model="CUST_MEMO" placeholder="고객정보를 입력하세요"></textarea>     
                    </div-->
                </li>
                <li>
                    <label>발신번호</label>
                    <div>{{ mixin_newPhoneFormat(inboundNum) }}</div>
                </li>
            </ul>
        </div>
        <div class="jh-call-alert-footer">
            <button type="button" class="jh-btn is-xl is-fill" @click="[$emit('hide')]">전화받기</button>
        </div>
    </div>
</template>

<script>

// import { eventBus } from "@/store/eventBus.js";           //Event Bus 
import $ from "@/cti/jquery-3.2.1.js";
import $1 from "@/cti/jquery-3.2.1_min.js";
import $2 from "@/cti/jquery.cookie.js";
import api from "../../store/apiUtil.js";
// import * as finesseCore from "@/cti/finesse_core_v2.0.js";
// import * as finesseParser from "@/cti/finesse_parser_v2.0.js";
// import * as finesseHandler from "@/cti/finesse_handler_v2.0.js";
import Vue from "vue";


// Vue.use(finesseCore);
// Vue.use(finesseHandler);
// Vue.use(finesseParser);

import { mixin } from "@/mixin/mixin.js";
// import { finesseGadGe }  from "@/utils/finessenongadget.js";
// import { jabberwerx  }  from "@/utils/jabberwerx.js"


export default {
    name: "MENU_P100201", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
        footerSubmit: {
            type: Boolean,
            default: true,
        },
        headerTitle: {
            type: String,
            default: '제목',
        },
        footerSubmitTitle: {
            type: String,
            default: '저장',
        },
        rcvPopMode: {
            type: String,
            default: '',
        },
        receive: {
          type: Object,
          value: {},
        },
    },

    data: () => ({
        finesseHandler1 : finesseHandler,

        footerHideTitle: '저장',
        defaultBodyContent: 'body slot 영역을 작성해주세요.',

        STD_TIME: '',
		IVR_IN_PATH: '',
		CUST_NM: '',
		CUST_MEMO: '',
		inboundNum: ''
    }),
    mounted() {
        this.initData();
    },

    methods: {
        // closePassowrdDialog() {
        //     this.finesseHandler1.finesseHandler.answerCall;
        //     this.$emit('hide');
        // },
        async initData() {
            console.log("----------------------------------------------initRcv");
            this.STD_TIME    = this.receive.STD_TIME;
            this.CUST_NM     = this.receive.CUST_NM;
            this.inboundNum  = this.receive.inboundNum;

            let ivrInCd = this.receive.IVR_IN_CD;
            let ivrInPath = "";

            //this.CUST_MEMO   = "당사직원 \n 화가 나있음 당사직원 <BR> 화가 나있음";

            if(!this.mixin_isEmpty(ivrInCd)) {
                if(ivrInCd.length < 5) {
                    ivrInPath = ivrInCd;
                    this.IVR_IN_PATH = ivrInCd;
                    this.receive.IVR_IN_PATH = ivrInCd;
                }
                else ivrInPath   = this.getCodeNm("IVR04", this.receive.IVR_IN_CD);
            } else {
                this.IVR_IN_PATH = ivrInCd;
                this.receive.IVR_IN_PATH = ivrInCd;
            }

            let ivrInNo = this.receive.IVR_IN_NO;

            if(!this.mixin_isEmpty(ivrInNo)) {
                this.receive.IVR_IN_NO  = await this.getCodeNm("IVR05", ivrInNo);
            } 

            let errNm   = "";

            if(this.receive.ERR_CD == "0000" || this.receive.ERR_CD == "0001" ) {
                errNm = "";
            } else {
                errNm = await this.getIvrErrNm(this.receive.ERR_CD);
            }

            this.fnCustDtl();
        },
        /* 코드명 가져오기 */
        async getCodeNm(sCd, pVal) {
            let cdNm = "";
            api
                .post(
                "/api/code/common/code-book/selectCodeNm", //api url입력
                {
                    GROUP_CD: sCd,
                    CODE: pVal,
                    USE_YN: "Y"
                },
                {
                    head: {
                    SERVICE: "api.palette-main.auth",
                    METHOD: "inqire",
                    TYPE: "BIZ_SERVICE",
                    },
                }
                )
                .then((response) => {
                    if (response.data.HEADER.COUNT == 0) {
                        let errMsg = response.data.HEADER.ERROR_MSG;
                        errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"";
                        console.log("getCodeNm:[" + errMsg +"]");
                        return false;
                    }
                    cdNm = response.data.DATA[0].CD_NM;
                    if(sCd == "IVR04") {
                        if(!this.mixin_isEmpty(cdNm)){
                            this.IVR_IN_PATH = cdNm;
                            this.receive.IVR_IN_PATH = cdNm;
                        } else {
                            this.IVR_IN_PATH = pVal;
                            this.receive.IVR_IN_PATH = pVal;
                        }
                    } else if(sCd == "IVR05") {
                        this.receive.IVR_IN_NO = cdNm;
                    }
                })
                .catch((err) => {
                    alert(err);
                });
            return cdNm;
        },
        /* IVR오류메시지 조회 */
        async getIvrErrNm(pVal) {
            if(pVal == "") {
                // this.receive.IVR_MENT = "내용없음";
                return;
            } 
            let ivrErrNm = "";
            api
            .post("/api/phone/main/ivrErrMsg/inqire", //api url입력
            {
                MSG_TP_CODE: '01'
                ,MSG_CODE: pVal
            },
            {
                head: {
                SERVICE: "phone.main.ivrMsg",
                METHOD: "inqire",
                TYPE: "BIZ_SERVICE",
                },
            }
            )
            .then((response) => {
                if (response.data.HEADER.COUNT == 0) {
                    let errMsg = response.data.HEADER.ERROR_MSG;
                    errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"";
                    console.log("getIvrErrNm:[" + errMsg +"]");
                    return false;
                }
                ivrErrNm = response.data.DATA[0].MSG_HANGL_NM;
                //this.CUST_MEMO = ivrErrNm;
                this.receive.IVR_MENT = ivrErrNm;
            })
            .catch((err) => {
                alert(err);
            });
            return ivrErrNm;
        },
        async fnCustDtl() {
            let ivrErrNm = "";

            let custNo  = "";
            let acctNo  = "";
            let acntPwd = "";

            if(!this.mixin_isEmpty(this.receive.ACCT_NO))  acctNo  = this.receive.ACCT_NO;
            if(!this.mixin_isEmpty(this.receive.ACCT_PWD)) acntPwd = this.receive.ACCT_PWD;
            if(!this.mixin_isEmpty(this.receive.RRNO))     custNo  = this.receive.RRNO;
           

            api.post("/api/phone/main/custDtl-info/inqire", //api url입력
                {
                    CUST_NO: custNo,
                    ACCT_NO: acctNo,
                    ACNT_PWD: acntPwd
                },
                {
                    head: {
                        SERVICE: "phone.main.custDtl",
                        METHOD: "inqire",
                        TYPE: "BIZ_SERVICE",
                    },
                }
            )
            .then((response) => {
                if (response.data.HEADER.COUNT == 0) {
                    let errMsg = response.data.HEADER.ERROR_MSG;
                        errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"";
                    console.log("getIvrErrNm:[" + errMsg +"]");
                    return false;
                }
                let objCustDtl = response.data;
                //console.log(">>>>>objCustDtl>>>>>>" + JSON.stringify(objCustDtl));
                let srchType = objCustDtl.DATA[0].SRCH_TYPE;

                if(srchType == "CL_CID") {
                    this.CUST_NM = objCustDtl.DATA[0].CNM;
                    // this.receive.COMP_TYPE = "2";   //인증유형(1:계좌번호, 2:주민번호)
                    // this.receive.COMP_RST  = "Y";   //인증결과(N:미인증  , Y:인증성공)
                    // this.receive.ACCT_NO   = "";
                    // this.receive.ACNT_PWD  = "";
                    //this.receive.RRNO      = "";
                    // this.receive.MBL_NO    = "";
                    // this.receive.PHNNUM_YN = "";
                    //this.receive.CUST_NO   = objCustDtl.DATA[0].CL_CID;
                    // this.CUST_MEMO         = objCustDtl.DATA[0].CUSTOMER_MEMO;
                    this.CUST_MEMO   = objCustDtl.DATA[0].CUSTOMER_MEMO ? this.restoreXSS(objCustDtl.DATA[0].CUSTOMER_MEMO).replaceAll("<BR>", "\r") : '';
                } else if(srchType == "ACCT_NO") {
                    this.CUST_NM = objCustDtl.DATA[0].CNM;
                    // this.receive.COMP_TYPE = "1";   //인증유형(1:계좌번호, 2:주민번호)
                    // this.receive.COMP_RST  = "Y";   //인증결과(N:미인증  , Y:인증성공)
                    //this.receive.ACCT_NO   = objCustDtl.DATA[0].ACCOUNT;
                    //this.receive.ACNT_PWD  = objCustDtl.DATA[0].ACNT_PWD;
                    //this.receive.RRNO      = "";
                    // this.receive.MBL_NO    = "";
                    // this.receive.PHNNUM_YN = "";
                    //this.receive.CUST_NO   = objCustDtl.DATA[0].CUST_NO;
                    //this.CUST_MEMO         = objCustDtl.DATA[0].CUSTOMER_MEMO;
                    this.CUST_MEMO   = objCustDtl.DATA[0].CUSTOMER_MEMO ? this.restoreXSS(objCustDtl.DATA[0].CUSTOMER_MEMO).replaceAll("<BR>", "\r") : '';
                    console.log(">>>>." + this.CUST_MEMO );
                } else {
                    this.CUST_NM = "미인증고객";
                    this.receive.COMP_TYPE = "";
                    this.receive.COMP_RST  = "";
                    this.receive.ACCT_NO   = "";
                    this.receive.ACNT_PWD  = "";
                    this.receive.RRNO      = "";
                    this.receive.CUST_NO   = "";
                }

            })
            .catch((err) => {
                alert(err);
            });
            return ivrErrNm;
        },
        /* 계좌번호 인증 */
        fnCompAcctNo(){
            this.receive.COMP_TYPE = "1";   //인증유형(1:계좌번호, 2:주민번호)
            this.receive.COMP_RST  = "Y";   //인증결과(N:미인증  , Y:인증성공)
            this.receive.ACCT_NO   = "20172112302";
            this.receive.ACCT_NM   = "RP형CMA테스트";
            this.receive.ACCT_PWD  = "0709";
            this.receive.ERR_CD    = "00000";
            this.receive.RRNO      = "";
            this.receive.MBL_NO    = "";
            this.receive.PHNNUM_YN = "N";
            this.CUST_NM           = "임면양";
            this.inboundNum        = "010-5323-9999";

            let errNm = this.getIvrErrNm(this.receive.ERR_CD);

            //this.common_toast("계좌번호 인증 성공!!!");
        },
        fnCompAcctNo2(){
            this.receive.COMP_TYPE = "X";   //인증유형(1:계좌번호, 2:주민번호)
            this.receive.COMP_RST  = "N";   //인증결과(N:미인증  , Y:인증성공)
            this.receive.ACCT_NO   = "20068627301";
            this.receive.ACCT_NM   = "탕유원";
            this.receive.ACCT_PWD  = "";
            this.receive.ERR_CD    = "00000";
            this.receive.RRNO      = "";
            this.receive.MBL_NO    = "";
            this.receive.PHNNUM_YN = "N";
            this.CUST_NM           = "탕유원";
            this.inboundNum        = "010-5323-9999";

            let errNm = this.getIvrErrNm(this.receive.ERR_CD);

            //this.common_toast("계좌번호 인증 성공!!!");
        },
        fnCompAcctNo3(){
            this.receive.COMP_TYPE = "2";   //인증유형(1:계좌번호, 2:주민번호)
            this.receive.COMP_RST  = "Y";   //인증결과(N:미인증  , Y:인증성공)
            this.receive.ACCT_NO   = "20160658403";
            this.receive.ACCT_NM   = "임면양";
            this.receive.ACCT_PWD  = "";
            this.receive.ERR_CD    = "00000";
            this.receive.RRNO      = "00384568";
            this.receive.MBL_NO    = "";
            this.receive.PHNNUM_YN = "N";
            this.CUST_NM           = "임면양";
            this.inboundNum        = "01053238723";

            let errNm = this.getIvrErrNm(this.receive.ERR_CD);

            //this.common_toast("계좌번호 인증 성공!!!");
        },
        /* 주민번호 인증 */
        fnCompRrno(){
            // this.receive.COMP_TYPE = "2";   //인증유형(1:계좌번호, 2:주민번호)
            // this.receive.COMP_RST  = "Y";   //인증결과(N:미인증  , Y:인증성공)
            this.receive.ACCT_NO   = "20160658401";
            this.receive.ACCT_NM   = "";
            this.receive.ACCT_PWD  = "";
            this.receive.RRNO      = "00384568";
            this.receive.ERR_CD    = "00000";
            this.CUST_NM           = "임면양";
            this.inboundNum        = "01053238723";
            this.receive.MBL_NO    = "";
            this.receive.PHNNUM_YN = "N";

            let errNm = this.getIvrErrNm(this.receive.ERR_CD);
            //this.common_toast("주민번호 인증 성공!!!");
            this.fnCustDtl();
        },
    },
    watch: {
        "rcvPopMode": function(nVal) {
            console.log("** rcvPopMode>>>>>" + nVal);
            this.initData();
        }
    },
    // created() {
    //     /**
    //      * 전화 받기 이벤트버스
    //      */
    //     eventBus.$on('receiveData', (callingYN) => {
    //         this.initData();
    //     });    
    // },
}
</script>

<style>  
  </style>