<template>
  <div class="jh-layout-footer">
    <div class="jh-info-txt">성공적으로 작업을 수행하였습니다.</div>
    <!-- <span
      class="btn-footer-tog svg-footer-btn"
      @click="toggleFooter = !toggleFooter"
      :class="{active : toggleFooter == true}"
    >
    </span> -->
    <!-- 토글 책갈피 -->
    <!-- <div class="layout-cs-list" :class="{active: toggleFooter == true}">
      <v-tabs
        next-icon="svg-arrow-round-next"
        prev-icon="svg-arrow-round"
        show-arrows
        hide-slider
        background-color="#EFEFEF"
      >
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab
          v-for="(items,index) in bookmark"
          :key="index"
          :class=[items.class]
          class="layout-cs-list--bookmark"
        >
          <span :class="'icn-'+[ items.icon ]"></span>
          <span class="layout-cs-list--bookmark-ci"><img :src="[ items.logoSrc ]" :alt="[ items.logo ]"></span>
          <span class="layout-cs-list--bookmark-time">{{ items.time }}분</span>
          <div class="layout-cs-list--bookmark-text">
            {{ items.text }}
          </div>
        </v-tab>
      </v-tabs>
    </div> -->

    <!-- <v-footer
      absolute
      padless
      class="layout-footer"
      width="calc(100% - 120px)"
      >

        <div>
          <v-icon
            size="20px"
            class="svg-footer-noti"
          ></v-icon>팔레트 공지사항 전달드립니다.

        </div>
        <div class="layout-footer--msg">
          <v-icon
            class="svg-footer-alert"
          ></v-icon>

          성공적으로 작업을 수행하였습니다.

        </div>
      </v-footer> -->

  </div>
</template>

<script>
export default {
  data () {
    return{
      // toggleFooter : false,
      // bookmark: [
      //   {
      //     class: 'talk',
      //     icon: 'talk',
      //     logo: 'ci ss',
      //     logoSrc: require('@/assets/logo.png'),
      //     time: '00',
      //     text: 'text text text text text text ',
      //   },
      //   {
      //     class: 'phone',
      //     icon: 'talk',
      //     logo: 'ci ss',
      //     logoSrc: require('@/assets/logo.png'),
      //     time: '00',
      //     text: 'text text text text text text ',
      //   },
      //   {
      //     class: 'talk',
      //     icon: 'talk',
      //     logo: 'ci ss',
      //     logoSrc: require('@/assets/logo.png'),
      //     time: '00',
      //     text: 'text text text text text text ',
      //   },
      //   {
      //     class: 'phone',
      //     icon: 'talk',
      //     logo: 'ci ss',
      //     logoSrc: require('@/assets/logo.png'),
      //     time: '00',
      //     text: 'text text text text text text ',
      //   },
      //   {
      //     class: 'talk',
      //     icon: 'talk',
      //     logo: 'ci ss',
      //     logoSrc: require('@/assets/logo.png'),
      //     time: '00',
      //     text: 'text text text text text text ',
      //   },
      //   {
      //     class: 'phone',
      //     icon: 'talk',
      //     logo: 'ci ss',
      //     logoSrc: require('@/assets/logo.png'),
      //     time: '00',
      //     text: 'text text text text text text ',
      //   },
      //   {
      //     class: 'talk',
      //     icon: 'talk',
      //     logo: 'ci ss',
      //     logoSrc: require('@/assets/logo.png'),
      //     time: '00',
      //     text: 'text text text text text text ',
      //   },
      //   {
      //     class: 'talk',
      //     icon: 'talk',
      //     logo: 'ci ss',
      //     logoSrc: require('@/assets/logo.png'),
      //     time: '00',
      //     text: 'text text text text text text ',
      //   },
      //   {
      //     class: 'talk',
      //     icon: 'talk',
      //     logo: 'ci ss',
      //     logoSrc: require('@/assets/logo.png'),
      //     time: '00',
      //     text: 'text text text text text text ',
      //   },

      // ],

    }
  },
  methods: {
  }

}
</script>

<style>

</style>