<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Selection</span></div>

      <h2>Select Box</h2>
      <div class="guide-card jh-cols">
        <select class="jh-form is-sm"><option>Small</option></select>
        <select class="jh-form"><option>Default</option></select>
        <select class="jh-form is-md"><option>Medium</option></select>
        <select class="jh-form is-lg"><option>Large</option></select>
        <select class="jh-form is-xl"><option>X-Large</option></select>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Round Select Box</h2>
      <div class="guide-card jh-cols">
        <select class="jh-form is-round is-sm"><option>Small</option></select>
        <select class="jh-form is-round"><option>Default</option></select>
        <select class="jh-form is-round is-md"><option>Medium</option></select>
        <select class="jh-form is-round is-lg"><option>Large</option></select>
        <select class="jh-form is-round is-xl"><option>X-Large</option></select>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source08_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Select Box Multiple</h2>
      <div class="guide-card">
        <select class="jh-form" name="" id="" multiple="multiple" style="height:180px;">
			<option value="1">1월</option>
			<option value="2">2월</option>
			<option value="3">3월</option>
			<option value="4">4월</option>
			<option value="5">5월</option>
			<option value="6">6월</option>
			<option value="7">7월</option>
			<option value="8">8월</option>
			<option value="9">9월</option>
			<option value="10">10월</option>
			<option value="11">11월</option>
			<option value="12">12월</option>
		</select>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Check Box</h2>
      <div class="guide-card">
        <input type="checkbox" class="jh-form" name="chk" id="chk"><label for="chk">선택</label>
        <input type="checkbox" class="jh-form" name="chk" id="chk2" disabled><label for="chk2">Unchecked Disabled</label>
        <input type="checkbox" class="jh-form" name="chk" id="chk3" checked disabled><label for="chk3">Checked Disabled</label>
      </div>
      <div class="guide-card is-mt-5">
        <input type="checkbox" class="jh-form is-circle" name="chk2" id="chk4"><label for="chk4">선택</label>
        <input type="checkbox" class="jh-form is-circle" name="chk2" id="chk5" disabled><label for="chk5">Unchecked Disabled</label>
        <input type="checkbox" class="jh-form is-circle" name="chk2" id="chk6" checked disabled><label for="chk6">Checked Disabled</label>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Radio Button</h2>
      <div class="guide-card jh-cols">
        <input type="radio" class="jh-form" name="rd" id="rd"><label for="rd">선택</label>
        <input type="radio" class="jh-form" name="rd" id="rd2"><label for="rd2">선택</label>
        <input type="radio" class="jh-form" name="rd2" id="rd3" disabled><label for="rd3">Unchecked Disabled</label>
        <input type="radio" class="jh-form" name="rd2" id="rd4" checked disabled><label for="rd4">Checked Disabled</label>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Radio Button Group</h2>
      <div class="guide-card">
        <div class="jh-radio-group">
            <input type="radio" name="rd" id="rd_1" title="선택"><label for="rd_1">선택</label>
            <input type="radio" name="rd" id="rd_2" title="선택"><label for="rd_2">선택</label>
            <input type="radio" name="rd" id="rd_3" title="선택"><label for="rd_3">선택</label>
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Switch Checkbox</h2>
      <div class="guide-card">
        <span class="jh-switch-label">사용</span>
        <input type="checkbox" class="jh-switch" name="switch" id="switch" checked="checked"><label for="switch"></label>
        <span class="jh-switch-label">사용</span>
        <input type="checkbox" class="jh-switch" name="switch" id="switch2"><label for="switch2"></label>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source06_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Switch Radio</h2>
      <div class="guide-card">
        <span class="jh-switch-label">사용</span>
        <input type="radio" class="jh-switch" name="switch2" id="switch3" checked="checked"><label for="switch3"></label>
        <span class="jh-switch-label">사용안함</span>
        <input type="radio" class="jh-switch" name="switch2" id="switch4"><label for="switch4"></label>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source07_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Buttons",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {        
          source01: 'HTML',
          content:{

source01_tab01: `<select class="jh-form is-sm"><option>Small</option></select>
<select class="jh-form"><option>Default</option></select>
<select class="jh-form is-md"><option>Medium</option></select>
<select class="jh-form is-lg"><option>Large</option></select>
<select class="jh-form is-xl"><option>X-Large</option></select>`
,source02_tab01: `<select class="jh-form" name="" id="" multiple="multiple" style="height:180px;">
    ...
</select>`
,source03_tab01: `<input type="checkbox" class="jh-form" name="chk" id="chk"><label for="chk">선택</label>
<input type="checkbox" class="jh-form" name="chk" id="chk2" disabled><label for="chk2">Unchecked Disabled</label>
<input type="checkbox" class="jh-form" name="chk" id="chk3" checked disabled><label for="chk3">Checked Disabled</label>
<input type="checkbox" class="jh-form is-circle" name="chk2" id="chk4"><label for="chk4">선택</label>
<input type="checkbox" class="jh-form is-circle" name="chk2" id="chk5" disabled><label for="chk5">Unchecked Disabled</label>
<input type="checkbox" class="jh-form is-circle" name="chk2" id="chk6" checked disabled><label for="chk6">Checked Disabled</label>`
,source04_tab01: `<input type="radio" class="jh-form" name="rd" id="rd"><label for="rd">선택</label>
<input type="radio" class="jh-form" name="rd" id="rd2"><label for="rd2">선택</label>
<input type="radio" class="jh-form" name="rd2" id="rd3" disabled><label for="rd3">Unchecked Disabled</label>
<input type="radio" class="jh-form" name="rd2" id="rd4" checked disabled><label for="rd4">Checked Disabled</label>`
,source05_tab01: `<div class="jh-radio-group">
    <input type="radio" name="rd" id="rd_1" title="선택"><label for="rd_1">선택</label>
    <input type="radio" name="rd" id="rd_2" title="선택"><label for="rd_2">선택</label>
    <input type="radio" name="rd" id="rd_3" title="선택"><label for="rd_3">선택</label>
</div>`
,source06_tab01: `<span class="jh-switch-label">사용</span>
<input type="checkbox" class="jh-switch" name="switch" id="switch" checked="checked"><label for="switch"></label>
<span class="jh-switch-label">사용</span>
<input type="checkbox" class="jh-switch" name="switch" id="switch2"><label for="switch2"></label>`
,source07_tab01: `<span class="jh-switch-label">사용</span>
<input type="radio" class="jh-switch" name="switch2" id="switch3" checked="checked"><label for="switch3"></label>
<span class="jh-switch-label">사용안함</span>
<input type="radio" class="jh-switch" name="switch2" id="switch4"><label for="switch4"></label>`
,source08_tab01: `<select class="jh-form is-round is-sm"><option>Small</option></select>
<select class="jh-form is-round"><option>Default</option></select>
<select class="jh-form is-round is-md"><option>Medium</option></select>
<select class="jh-form is-round is-lg"><option>Large</option></select>
<select class="jh-form is-round is-xl"><option>X-Large</option></select>`
          }
      }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>