const commonStore = {
  namespaced: true,
  state: () => ({
    popup:"",
    tab: [], //전체 탭
    no_cash_tab: [], //전체 탭
    active_tab: {
      num: 0, //
      id: "",
      title: "",
      link: "",
    },
    help_dialog: false,
    help_dialog_id: "",
    progress_bar: false,
    app_loading: false,
    error_code: "",
  }),
  getters: {
    GE_COMMON_POP: (state) => state.popup,
    GE_COMMON_TAB: (state) => state.tab,
    GE_COMMON_NO_CASH_TAB: (state) => state.no_cash_tab,
    GE_COMMON_ACTIVE_TAB: (state) => state.active_tab,
    GE_COMMON_ACTIVE_TAB_NUM: (state) => state.active_tab.num,
    GE_COMMON_ACTIVE_TAB_ID: (state) => state.active_tab.id,
    GE_COMMON_ACTIVE_TAB_TITLE: (state) => state.active_tab.title,
    GE_COMMON_HELP_DIALOG: (state) => state.help_dialog,
    GE_COMMON_HELP_DIALOG_ID: (state) => state.help_dialog_id,
    GE_COMMON_PROGRESS_BAR: (state) => state.progress_bar,
    // GE_COMMON_ACTIVE_TAB_NUM: state => state.active_tab_num,
    // GE_COMMON_ACTIVE_TAB_ID: state => state.active_tab_id,
    // GE_COMMON_ACTIVE_TAB_TITLE: state => state.active_tab_title,
    GE_COMMON_APP_LOADING: (state) => state.app_loading,
    GE_COMMON_APP_ERROR_CODE: (state) => state.error_code,
  },
  mutations: {
    MU_COMMON_INIT: (state) => {
      state.tab = [];
      state.no_cash_tab = [];
      state.active_tab = {
        num: 0, //
        id: "",
        title: "",
        link: "",
      };
      state.progress_bar = false;
      state.app_loading = false;
    },
    MU_COMMON_ACTIVE_POP: (state, data) => {
      state.popup = data.id;
    },
    MU_COMMON_ACTIVE_TAB: (state, data) => {
      /*
                여기서는 payload를 객체로 받습니다.
                payload를 객체로 받으면, mutation를 조금더 유연하게 사용할 수 있기는 합니다.
            */
      let aleady = false; //기존에 있는 탭인지 여부
      setTimeout(function () {
        //기존에 탭에 있는 탭인지 확인
        for (let i = 0; i < state.tab.length; i++) {
          if (state.tab[i].id === data.id) {
            aleady = true;
            state.active_tab.num = i; //기존 열려 있는 탭을 활성화 시킨다
          }
        }

        //기존에 열려 있는 탭이 아닌 신규탭이면 탭을 추가하고 활성화 시킨다
        if (aleady === false) {
          state.tab.push({
            id: data.id,
            title: data.title,
            icon: data.tabIcon,
            link: data.link,
          });
          state.active_tab.num = state.tab.length - 1;
        }

        state.active_tab.id = data.id;
        state.active_tab.title = data.title;

        //탭삭제
        for (let a = 0; a < state.no_cash_tab.length; a++) {
          if (state.no_cash_tab[a] === data.id) {
            state.no_cash_tab.splice(a, 1);
          }
        }
      }, 100);
    },
    MU_COMMON_DEACTIVE_TAB: (state, data) => {
      /*
                여기서는 payload를 객체로 받습니다.
                payload를 객체로 받으면, mutation를 조금더 유연하게 사용할 수 있기는 합니다.
            */

      setTimeout(function () {
        //탭삭제
        for (let i = 0; i < state.tab.length; i++) {
          if (state.tab[i].id === data.id) {
            state.tab.splice(i, 1);
          }
        }

        //삭제한 탭이 마지막 탭이거나 삭제한탭이 활성화 중인 탭이면
        //삭제한 후 남아있는 마지막 탭으로 이동
        if (
          state.active_tab.num > state.tab.length - 1 ||
          state.active_tab.id === data.id
        ) {
          state.active_tab.num = state.tab.length - 1;
          state.active_tab.id = state.tab[state.tab.length - 1].id;
          state.active_tab.title = state.tab[state.tab.length - 1].title;
          state.active_tab.link = state.tab[state.tab.length - 1].link;
        }

        //cache 하지 않아야 하는 탭리스트에 추가
        state.no_cash_tab.push(data.id);
      }, 100);
    },
    MU_COMMON_INIT_TAB: (state, data) => {
      state.tab = data;
      if (state.tab.length > 0) {
        state.active_tab.num = 0;
        state.active_tab.id = state.tab[0].id;
        state.active_tab.title = state.tab[0].title;
        state.active_tab.link = state.tab[0].link;
      }
    },
    MU_COMMON_DELETE_ALL_TAB: (state, data) => {
      let change_action_tab = true;

      //기본(default가 'Y')탭을 제외하고 모든탭을 삭제한다
      for (let i = 0; i < state.tab.length; i++) {
        if (state.tab[i].default !== "Y") {
          state.tab.splice(i, 1);
          i--;
        } else {
          //현재 열려있는 탭이 기본탭이면 탭변경을 하지 않는다
          if (state.active_tab.id === state.tab[i].id) {
            change_action_tab = false;
          }
        }
      }

      //탭을 변경한다
      if (change_action_tab === true && state.tab.length > 0) {
        state.active_tab.num = 0;
        state.active_tab.id = state.tab[0].id;
        state.active_tab.title = state.tab[0].title;
        state.active_tab.link = state.tab[0].link;
      }
    },
    MU_COMMON_HELP_DIALOG: (state, data) => {
      state.help_dialog = data.type;
    },
    MU_COMMON_HELP_DIALOG_ID: (state, data) => {
      state.help_dialog_id = data.id;
    },
    MU_COMMON_PROGRESS_BAR: (state, data) => {
      state.progress_bar = data;
    },
    MU_COMMON_APP_LOADING: (state, data) => {
      state.app_loading = data;
    },
    MU_COMMON_APP_ERROR: (state, error) => {
      // api 응답 httpcode가 401이면 인증 오류로 처리
      if (state.error_code != 401 && error.response.status == 401) {
        state.error_code = error.response.status;
        if (!opener) // 메인창인 경우
          alert("로그인 정보가 만료되었습니다. 다시 로그인 해주세요.");
        else // 윈도우 팝업창인 경우
          alert("로그인 정보가 만료되었습니다. 새창을 열어 다시 시도해주세요.");
      }

      // state.app_error.error = error.response.data.error;
      // state.app_error.message = error.response.message || error.message;
    },
    MU_COMMON_APP_ERROR_CODE: (state, data) => {
      state.error_code = data;
    }
  },
  actions: {
    //신규팝업
    async AC_COMMON_ACTIVE_POP({ commit }, data) {
      commit("MU_COMMON_ACTIVE_POP", data);
    },    
    //신규탭 생성
    async AC_COMMON_ACTIVE_TAB({ commit }, data) {
      commit("MU_COMMON_ACTIVE_TAB", data);
    },
    //탭 삭제
    async AC_COMMON_DEACTIVE_TAB({ commit }, data) {
      commit("MU_COMMON_DEACTIVE_TAB", data);
    },
    //탭 초기화
    async AC_COMMON_INIT_TAB({ commit }, data) {
      commit("MU_COMMON_INIT_TAB", data);
    },
    //탭 전체삭제
    async AC_COMMON_DELETE_ALL_TAB({ commit }, data) {
      commit("MU_COMMON_DELETE_ALL_TAB", data);
    },
    //HELP DIALOG 상태 변경
    async AC_COMMON_HELP_DIALOG({ commit }, data) {
      commit("MU_COMMON_HELP_DIALOG", data);
    },
    //HELP DIALOG ID 셋팅
    async AC_COMMON_HELP_DIALOG_ID({ commit }, data) {
      commit("MU_COMMON_HELP_DIALOG_ID", data);
    },
    //HELP DIALOG ID 셋팅
    async AC_COMMON_PROGRESS_BAR({ commit }, data) {
      commit("MU_COMMON_PROGRESS_BAR", data);
    },
    AC_COMMON_APP_LOADING({ commit }, data) {
      commit("MU_COMMON_APP_LOADING", data);
    }
  },
};

export default commonStore;
