import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VuetifyDialog from "vuetify-dialog";
import store from "./store";
import CKEditor from "ckeditor4-vue";
import common from "./assets/js/common";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import api from "@/store/apiUtil";
import { eventBus } from "@/store/eventBus.js";           //Event Bus 

moment.locale("ko");
window.moment = moment;
// window.addEventListener("keydown", this.setAvailableRefresh);
// window.addEventListener('beforeunload', function(event) {
//   let routerPath = window.location.pathname
//   if(!routerPath.includes("P") && window.sessionStorage.getItem("jsessionid") && window.sessionStorage.getItem("token")){
//     axios.post('/api/phone/ars/list');
//   }
// })
// window.addEventListener('beforeunload', function(event) {

//   eventBus.busCloseWindowCtiLogout();  
  
//   let routerPath = window.location.pathname
//   // let saqrfwqr = this.$store.getters["commonStore/GE_COMMON_APP_LOADING"];
//   // console.log("!saqrfwqr   ", saqrfwqr)

//   // let a = this.$store.getters["commonStore/GE_COMMON_TAB"]
//   // console.log("!routerPath   ", routerPath)
//   // console.log("!routerPath.includes()   ", !routerPath.includes("P"))
//   // console.log("this.$store.getters[commonStore/GE_COMMON_POP]   ", a)
//   // console.log("result   ", this.$store.getters["commonStore/GE_COMMON_POP"] == routerPath)
//   // let popUp = this.getPop()
//   // console.log("popUp", popUp)

//   if(!routerPath.includes("P") && window.sessionStorage.getItem("jsessionid") && window.sessionStorage.getItem("token")){
//   // if(!routerPath.includes("P")){
//       // if(this.popUp == routerPath && window.sessionStorage.getItem("jsessionid") && window.sessionStorage.getItem("token")){
    
//     api
//     .post("api/logout", {
//       ACCESS_TOKEN: window.sessionStorage.getItem("token"),
//       JSESSIONID: window.sessionStorage.getItem("jsessionid")
//     })
//     .then((response) => {
//       //로그아웃 처리
//       commit("MU_AUTH_LOGOUT_RESULT", set_data);
//       window.sessionStorage.clear();
//       axios.post('/api/sagjksabkjgbsakgjbsat');

//       response_data = response;
//       console.log("logout.success", response_data);
//       console.log("logout.success", success);
//       // eventBus.unloadLogout();

//     })
//     .catch((error) => {
//       // 에러인 경우 처리
//       console.error("logout.error", error);

//       set_data.code = -1;
//       //로그인에러 처리
//       commit("MU_AUTH_LOGIN_RESULT", set_data);
//     });
//   }
// })

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.mixin({

  methods: {
    getPop(){
      console.log("inininininb")
      return this.$store.getters["commonStore/GE_COMMON_POP"];
    },
    setCookie: function(name, value, exdays) { // (쿠키이름, 값, 만료기간) 만료기간 0 으로 할 경우 삭제, 만료기간 입력안하면 무기한
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    },
    getCookie(name) {
      name += "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$options.name == "v-progress-circular" && this.$el.id == "appLoading" && this.$store) {
        let loginResult = this.$store.getters["authStore/GE_AUTH_LOGIN_RESULT"];
        let appLoading = this.$store.getters["commonStore/GE_COMMON_APP_LOADING"];
        if (loginResult > 0 && appLoading) {
          this.$store.dispatch("commonStore/AC_COMMON_APP_LOADING", false);
        }
      }
    }, 500);
  }
});

Vue.use(CKEditor);
Vue.use(common);
new Vue({
  router,
  vuetify,
  store,
  // Toast,
  VuetifyDialog,
  common,
  render: (h) => h(App),
}).$mount("#app");
