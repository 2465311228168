<template>
  <div class="jh-dialog-wrap">
    <div class="jh-dialog-header">
      <h1>일정관리</h1>
      <div class="is-right">
        <v-btn class="jh-btn is-icon" title="닫기" @click="[$emit('hide'), init()]"><i class="jh-icon-close-lg is-white"></i></v-btn>
      </div>
    </div>
    <div class="jh-dialog-body">
      <div class="jh-cols" style="height:600px">
        <!--오른쪽 스케줄-->
        <div class="jh-schedule-form is-col-fix" style="width: 300px;" v-if="initFlag">
          <v-date-picker
            class="jh-schedule is-light"
            v-model="calendar"
            :events="arrayEventsCalendar"
            color="#f26d78"
            full-width
            no-title
            event-color="#F06969"
            @change="calendarLog"
            @click:date="checkDate"
            :first-day-of-week="0"
            locale="ko-KR"
            :day-format="getDay"
            @update:picker-date="updatePickedMonth"
          ></v-date-picker>
          <div class="is-timeline">
            <p class="jh-no-data" v-if="this.arrayEvents.length == 0"><span>등록된 일정이 없습니다.</span></p>
            <v-timeline align-top dense v-if="this.arrayEvents.length > 0">
              <v-timeline-item
                small
                v-for="item in arrayEvents"
                :key="item.id"
                :color="item.color">
                <div class="is-row">
                  <div class="is-date">
                    <strong>{{ daySplit(item.day) }}</strong><span>일</span>
                  </div>
                  <div class="is-text">                  
                    <strong>{{ item.titleLS }}</strong>
                    <p><pre>{{ item.desc }}</pre></p>
                  </div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </div>
        </div>
        <!--//오른쪽 스케줄-->
        <div>
          <div class="jh-ui-header">
            <h2 class="has-bullet">일정상세정보</h2>
            <span class="is-left">({{sub_content.length}}/3000)</span>
          </div>
          <table class="jh-tbl-detail">
            <colgroup>
              <col width="90px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th><label>일자</label></th>
                <td> 
                    <v-text-field class="jh-form is-md is-trns" v-model="date" readonly placeholder="캘린더 일자를 클릭하세요"></v-text-field>                            
                </td>
              </tr>
              <tr>
                <th><label class="is-required">일정유형</label></th>
                <td> 
                  <v-select class="jh-form is-md" style="width: 120px;" :items="dropItems" item-text="title" name="daysl" v-model="daysl" placeholder="선택하세요">
                    <template slot="selection" slot-scope="data">
                    <label :class="'jh-label-circle '+ data.item.color"></label>
                    <div> {{ data.item.title }} </div>
                    </template>
                    <template slot="item" slot-scope="data">
                      <div :class="'jh-label-circle '+ data.item.color"></div>
                      <div> {{ data.item.title }} </div>
                    </template>
                  </v-select>                         
                </td>
              </tr>
              <tr>
                <th><label class="is-required">일정제목</label></th>
                <td> 
                  <v-text-field class="jh-form is-md" v-model="sub_title" maxLength="48" placeholder="제목을 입력하세요."></v-text-field>
                </td>
              </tr>
              <tr>
                <th><label>일정내용</label></th>
                <td> 
                  <v-textarea class="jh-form" style="height: 455px;" no-resize v-model="sub_content" :rules="textareaRules" placeholder="내용을 입력하세요."></v-textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="jh-dialog-footer">
      <v-btn class="jh-btn is-md is-del" @click="scheduleDel">삭제</v-btn>
      <v-btn class="jh-btn is-md is-main" @click="scheduleReg">저장</v-btn>
    </div>
  </div>
</template>

<script>
  import api from "@/store/apiUtil";
  import { mixin } from "@/mixin/mixin.js";
  import gibberish from "@/mixin/gibberish-aes_mixin";
  import { eventBus } from "@/store/eventBus.js";

  export default {
    name: "MENU_E050101P02", //name은 'MENU_' + 파일명 조합
    mixins: [mixin, gibberish],
    props: {
      userParam: {
        type: Object,
      },
      agentInfo: {
        type: Object,
      },
    },
    data() {
      return {
        initFlag: true,
        dropItems: [
          { title: "기념일", color: "amber",},
          { title: "스케줄", color: "cyan", },
        ],
        textareaRules: [(v) => v.length <= 100 || "최대 100자 제한"],
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        timeStep: "10:10",
        calendar: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        arrayEvents: [],
        //arrayEventsCalendar: null,
        arrayEventDetail: null,
        arrayEventColor: null,

        USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        month: "",
        nowDate: "",
        sub_title: "",
        sub_content: "",
        daysl: "",
        daysl2: "",
        responseCheck: "",
        titleSE: "",
        pickerDate:null,
        initCNT:0,
      };
    },
    methods: {
      initCalendar() {
        this.initFlag = false;
        this.$nextTick(() => {
          this.initFlag = true;
        });
      },
      calendarLog(e) {
          const index = this.arrayEvents.findIndex(function (item) {
            return item.day === e;
          });

          if (index < 0) return;

          // console.log('index = ' + index);
          this.arrayEventDetail =
            this.arrayEvents[index] ?? this.arrayEvents[index];
      },
      
      checkDate() {
        //this.arrayEvents = [];
        this.date = this.calendar;
        //this.calendarClick();
        this.calendarCheck();
      },

      //test 스케줄 목록조회
      calendarClick() {
        console.log("click");
        const USER_ID = this.USER_ID;
        const USER_DATE = this.nowDate.replace(/\-/g, "");
        //alert(USER_DATE);
        //alert(USER_DATE.substr(6,7));
        const FIRST_USER_DATE = USER_DATE.substr(0, 6) + "00";
        //alert(FIRST_USER_DATE);
        const LAST_USER_DATE = USER_DATE.substr(0, 6) + "99";
        //alert(LAST_USER_DATE);
        this.arrayEvents = [];
        api
          .post(
            "api/schedule/list",
            {
              USER_ID: USER_ID,
              FIRST_USER_DATE: FIRST_USER_DATE,
              LAST_USER_DATE: LAST_USER_DATE,
            },
            {
              head: {
                SERVICE: "api/schedule",
                METHOD: "/list",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            console.log("<<<",response.data);
            console.log(">>>",response.data.DATA.length);
            this.initCNT = 1;
            for (let index = 0; index < response.data.DATA.length; index++) {
              var dataList = {};
              var arrCal = [];
              var arrColor = [];
              dataList.id = index;
              //dataList.day = response.data.DATA[index]['USER_DATE'].substr(0,3)+"-"+response.data.DATA[index]['USER_DATE'].substr(4,5)+"-"+response.data.DATA[index]['USER_DATE'].substr(6,7)
              dataList.day = response.data.DATA[index]["USER_DATE"].replace(
                /([0-9]{4})([0-9]+)([0-9]{2})/,
                "$1-$2-$3"
              );
              dataList.titleLS = this.restoreXSS(response.data.DATA[index]["TITLE"]).replace(/\r\n/g, "\n");
              dataList.desc = this.restoreXSS(response.data.DATA[index]["CONTENT"]).replace(/\r\n/g, "\n");
              if (response.data.DATA[index]["SELECT_DAY"] == "기념일") {
                dataList.type = "evt";
                dataList.title = response.data.DATA[index]["SELECT_DAY"];
                dataList.color = "amber";
              } else {
                dataList.type = "schedule";
                dataList.title = response.data.DATA[index]["SELECT_DAY"];
                dataList.color = "cyan";
              }

              this.arrayEvents.push(dataList);
              this.arrayEventsCalendar(this.arrayEvents[index].day);
            }
          });
      },

      //test 스케줄 목록조회
      FirstCalendarClick() {
        const USER_ID = this.USER_ID;
        const USER_DATE = this.nowDate.replace(/\-/g, "");
        const FIRST_USER_DATE = USER_DATE.substr(0, 6) + "00";
        const LAST_USER_DATE = USER_DATE.substr(0, 6) + "99";
        this.arrayEvents = [];
        api
          .post(
            "api/schedule/list",
            {
              USER_ID: USER_ID,
              FIRST_USER_DATE: FIRST_USER_DATE,
              LAST_USER_DATE: LAST_USER_DATE,
            },
            {
              head: {
                SERVICE: "api/schedule",
                METHOD: "/list",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            console.log(response.data.DATA.length);
            this.initCNT = 1;
            for (let index = 0; index < response.data.DATA.length; index++) {
              var dataList = {};
              var arrCal = [];
              var arrColor = [];
              dataList.id = index;
              //dataList.day = response.data.DATA[index]['USER_DATE'].substr(0,3)+"-"+response.data.DATA[index]['USER_DATE'].substr(4,5)+"-"+response.data.DATA[index]['USER_DATE'].substr(6,7)
              dataList.day = response.data.DATA[index]["USER_DATE"].replace(
                /([0-9]{4})([0-9]+)([0-9]{2})/,
                "$1-$2-$3"
              );
              dataList.titleLS = this.restoreXSS(response.data.DATA[index]["TITLE"]).replace(/\r\n/g, "\n");
              dataList.desc = this.restoreXSS(response.data.DATA[index]["CONTENT"]).replace(/\r\n/g, "\n");
              if (response.data.DATA[index]["SELECT_DAY"] == "기념일") {
                dataList.type = "evt";
                dataList.title = response.data.DATA[index]["SELECT_DAY"];
                dataList.color = "amber";
              } else {
                dataList.type = "schedule";
                dataList.title = response.data.DATA[index]["SELECT_DAY"];
                dataList.color = "cyan";
              }

              this.arrayEvents.push(dataList);
              this.arrayEventsCalendar(this.arrayEvents[index].day);
            }
          });
      },

      searchData() {
        //alert(this.USER_ID);
        //alert(this.month); 현재 날짜 달
        //alert(this.nowDate); 현재 날짜
        INLNE_NEW_NO.value = this.INLNE_NO;
        USER_NEW_NAME.valText = this.USER_NAME;

        const USER_ID = this.USER_ID;
        const USER_DATE = "20220325";

        api
          .post("/api/schedule/list", {
            USER_ID: USER_ID,
            USER_DATE: USER_DATE,
          })
          .then((response) => {
            console.log(response.data);
            console.log(response.data.DATA);
          });
      },

      scheduleReg() {
        if(this.sub_content.length > 3000){
          this.common_alert("일정내용은 3000자 이상 입력할수 없습니다.", "error");
          return;
        }
        if (this.date == "" || this.date == undefined) {
          this.common_alert("날짜를 선택해주세요.", "error");
          return;
        }

        if (this.daysl == "" || this.daysl == undefined) {
          this.common_alert("기념일 또는 스케즐을 선택해주세요.", "error");
          return;
        }
        //alert(this.calendar);//달력에 표시
        //alert(this.date);//밑에 날짜.
        var responseCheck = "";
        this.calendarCheck();
        console.log(this.responseCheck);
        if (this.responseCheck == "false") {
          //등록 api
          const USER_ID = this.USER_ID;
          const USER_DATE = this.date.replace(/\-/g, "");
          const SELECT_DAY = this.daysl;
          const TITLE = this.sub_title;
          const CONTENT = this.sub_content;

          api
            .post(
              "api/schedule/insert",
              {
                USER_ID: USER_ID,
                USER_DATE: USER_DATE,
                SELECT_DAY: SELECT_DAY,
                TITLE: this.restoreXSS_Dec(TITLE),
                CONTENT: this.restoreXSS_Dec(CONTENT),
              },
              {
                head: {
                  SERVICE: "api/schedule",
                  METHOD: "/insert",
                  TYPE: "BIZ_SERVICE",
                },
              }
            )
            .then((response) => {
              this.$store.getters['userStore/GE_USER_ROLE'].SCHEDULE = 1;
              console.log(response.data);
              this.common_alert("일정이 등록되었습니다.");
              this.calendarCheck();
              this.calendarClick();
            });
        } else {
          // 수정 api
          const USER_ID = this.USER_ID;
          const USER_DATE = this.date.replace(/\-/g, "");
          const SELECT_DAY = this.daysl;
          const TITLE = this.sub_title;
          const CONTENT = this.sub_content;

          api
            .post(
              "api/schedule/update",
              {
                USER_ID: USER_ID,
                USER_DATE: USER_DATE,
                SELECT_DAY: SELECT_DAY,
                TITLE: this.restoreXSS_Dec(TITLE),
                CONTENT: this.restoreXSS_Dec(CONTENT),
              },
              {
                head: {
                  SERVICE: "api/schedule",
                  METHOD: "/update",
                  TYPE: "BIZ_SERVICE",
                },
              }
            )
            .then((response) => {
              console.log(response.data);
              this.$store.getters['userStore/GE_USER_ROLE'].SCHEDULE = 1;
              this.common_alert("일정이 수정되었습니다.");
              this.calendarCheck();
              this.calendarClick();
            });
        }
      },
      calendarCheck() {
        const USER_ID = this.USER_ID;
        const USER_DATE = this.date.replace(/\-/g, "");
        var responseCheck = "";
        api
          .post(
            "api/schedule/select",
            {
              USER_ID: USER_ID,
              USER_DATE: USER_DATE,
            },
            {
              head: {
                SERVICE: "api/schedule",
                METHOD: "/select",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            if (response.data.DATA == "") {
              this.daysl = "";
              this.sub_title = "";
              this.sub_content = "";
              this.responseCheck = "false";
            } else {
              this.daysl = response.data.DATA[0]["SELECT_DAY"];
              this.sub_title = this.restoreXSS(response.data.DATA[0]["TITLE"]).replace(/\r\n/g, "\n");
              this.sub_content = this.restoreXSS(response.data.DATA[0]["CONTENT"]).replace(/\r\n/g, "\n");
              this.responseCheck = "true";
            }
            //console.log(responseCheck);
          });
      },

      scheduleDel() {
        var responseCheck = "";
        this.calendarCheck();
        console.log(this.responseCheck);
        if (this.responseCheck == "false") {
          this.common_alert("삭제할 일정이 없습니다.");
        } else {
          // 삭제 api
          const USER_ID = this.USER_ID;
          const USER_DATE = this.date.replace(/\-/g, "");
          //const SELECT_DAY = this.daysl;
          //const TITLE = this.sub_title;
          //const CONTENT = this.sub_content;

          api
            .post(
              "api/schedule/delete",
              {
                USER_ID: USER_ID,
                USER_DATE: USER_DATE,
                //SELECT_DAY: SELECT_DAY,
                //TITLE: TITLE,
                //CONTENT: CONTENT,
              },
              {
                head: {
                  SERVICE: "api/schedule",
                  METHOD: "/delete",
                  TYPE: "BIZ_SERVICE",
                },
              }
            )
            .then((response) => {
              console.log(response.data);
              this.$store.getters['userStore/GE_USER_ROLE'].SCHEDULE = 1;
              this.common_alert("일정이 삭제되었습니다.");
              this.calendarCheck();
              this.calendarClick();
            });
        }
      },

      FnArrayEventColor(e) {
        const index = this.arrayEvents.findIndex(function (item) {
          return item.day === e;
        });

        if (index < 0) return;

        return this.arrayEventColor[index];
      },

      setColor() {
        var allDates = document.querySelectorAll(
          ".csm-modal-wrap .v-date-picker-table .v-btn .v-date-picker-table__events"
        );
        var dates = this.arrayEvents;
        dates.forEach((e, index) => {
          //dates[index].type == 'schedule' ? allDates[index].classList.add('schedule') : allDates[index].classList.add('evt');
          console.log(index, allDates[index], dates[index].type);
        });
      },

      evtCreate() {
        var arrCal = [];
        var arrColor = [];
        for (let index = 0; index < this.arrayEvents.length; index++) {
          var element = this.arrayEvents[index].day;
          var element2 =
            this.arrayEvents[index].type === "schedule" ? "#FFF" : "#F7AFAE";

          arrCal[arrCal.length] = element;
          arrColor[arrColor.length] = element2;
        }
        this.arrayEventsCalendar = arrCal;
        this.arrayEventColor = arrColor;
        console.log(arrCal);
      },

      daySplit(e) {
        const day = e.split("-")[2];
        return day;
      },

      //스케줄 달력에 포인트 찍기
      arrayEventsCalendar(date) {
        let colorArray = [];

        for (let i = 0; i < this.arrayEvents.length; i++) {
          if (this.arrayEvents[i].day === date) {
            if (this.arrayEvents[i].type === "schedule") {
              colorArray.push("#6DC4B1");
            } else if (this.arrayEvents[i].type === "evt") {
              colorArray.push("#F7B611");
            }
          }
        }
        // const [,, day] = date.split('-')
        // if ([12, 17, 28].includes(parseInt(day, 10))) return true
        // if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f']
        return colorArray;
      },

      getDay(date) {
        const day = date.split("-")[2];
        return Number(day);
      },

      time() {
        var dateLS = new Date();

        var year = dateLS.getFullYear();
        var month = dateLS.getMonth() + 1;
        var day = dateLS.getDate();

        if (month < 10) {
          month = "0" + month;
        }

        if (day < 10) {
          day = "0" + day;
        }
        var nowDate = year + "-" + month + "-" + day;
        this.nowDate = nowDate;
        this.month = month;
        
        //return year + month + day
      },

      init() {
        this.arrayEvents = [];
        this.sub_title = "";
        (this.sub_content = ""), (this.date = "");
        this.profileIcon =
          "svg-profile " + this.$store.getters["userStore/GE_USER_ICON"];
        this.daysl = "";
        this.FirstCalendarClick();
      },

      updatePickedMonth(value){
        this.nowDate = value + "-01";

        if (this.initCNT != 0){
          this.calendarClick();
        }
      },
    },
    mounted() {
      //time 초기화 등록
      eventBus.$on('E050101P02TimeInit', () => {this.initCalendar()});
      this.time();
      //setTimeout(
      //  () => {
      //    // this.setColor();
      //  },
      //  0,
      //  this.evtCreate()
      //);
      //USER_NAME = this.USER_NAME;
      //this.searchData()
      //this.calendarClick();
      //this.FirstCalendarClick();
      this.init();
    },

    watch: {
    },
  };
</script>

<style></style>