<template>
    <div class="guide-content"> 
        <div class="guide-content-header">Styles<v-icon class="mdi mdi-chevron-right"></v-icon><span>Spacing</span></div>
  
        <h2>Margin <span class="tt-info-txt">요소와 요소 사이의 간격</span></h2>
        <table class="tbl-border">
            <colgroup>
                <col width="75px">
                <col>
                <col width="75px">
                <col>
                <col width="75px">
                <col>
                <col width="75px">
                <col>
                <col width="75px">
                <col>
            </colgroup>
            <thead>
                <tr>
                    <th>Class명</th>
                    <th>CSS</th>
                    <th>Class명</th>
                    <th>CSS</th>
                    <th>Class명</th>
                    <th>CSS</th>
                    <th>Class명</th>
                    <th>CSS</th>
                    <th>Class명</th>
                    <th>CSS</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th class="">is-m-0</th>
                    <td><span class="value">margin: 0</span></td>
                    <th class="">is-mt-0</th>
                    <td><span class="value">margin-top: 0</span></td>
                    <th class="">is-mr-0</th>
                    <td><span class="value">margin-right: 0</span></td>
                    <th class="">is-mb-0</th>
                    <td><span class="value">margin-bottom: 0</span></td>
                    <th class="">is-ml-0</th>
                    <td><span class="value">margin-left: 0</span></td>
                </tr>
                <tr>
                    <th class="">is-m-5</th>
                    <td><span class="value">margin: 5px</span></td>
                    <th class="">is-mt-5</th>
                    <td><span class="value">margin-top: 5px</span></td>
                    <th class="">is-mr-5</th>
                    <td><span class="value">margin-right: 5px</span></td>
                    <th class="">is-mb-5</th>
                    <td><span class="value">margin-bottom: 5px</span></td>
                    <th class="">is-ml-5</th>
                    <td><span class="value">margin-left: 5px</span></td>
                </tr>
                <tr>
                    <th class="">is-m-10</th>
                    <td><span class="value">margin: 10px</span></td>
                    <th class="">is-mt-10</th>
                    <td><span class="value">margin-top: 10px</span></td>
                    <th class="">is-mr-10</th>
                    <td><span class="value">margin-right: 10px</span></td>
                    <th class="">is-mb-10</th>
                    <td><span class="value">margin-bottom: 10px</span></td>
                    <th class="">is-ml-10</th>
                    <td><span class="value">margin-left: 10px</span></td>
                </tr>
                <tr>
                    <th class="">is-m-15</th>
                    <td><span class="value">margin: 15px</span></td>
                    <th class="">is-mt-15</th>
                    <td><span class="value">margin-top: 15px</span></td>
                    <th class="">is-mr-15</th>
                    <td><span class="value">margin-right: 15px</span></td>
                    <th class="">is-mb-15</th>
                    <td><span class="value">margin-bottom: 15px</span></td>
                    <th class="">is-ml-15</th>
                    <td><span class="value">margin-left: 15px</span></td>
                </tr>
                <tr>
                    <th class="">is-m-20</th>
                    <td><span class="value">margin: 20px</span></td>
                    <th class="">is-mt-20</th>
                    <td><span class="value">margin-top: 20px</span></td>
                    <th class="">is-mr-20</th>
                    <td><span class="value">margin-right: 20px</span></td>
                    <th class="">is-mb-20</th>
                    <td><span class="value">margin-bottom: 20px</span></td>
                    <th class="">is-ml-20</th>
                    <td><span class="value">margin-left: 20px</span></td>
                </tr>
                <tr>
                    <th class="">is-m-25</th>
                    <td><span class="value">margin: 25px</span></td>
                    <th class="">is-mt-25</th>
                    <td><span class="value">margin-top: 25px</span></td>
                    <th class="">is-mr-25</th>
                    <td><span class="value">margin-right: 25px</span></td>
                    <th class="">is-mb-25</th>
                    <td><span class="value">margin-bottom: 25px</span></td>
                    <th class="">is-ml-25</th>
                    <td><span class="value">margin-left: 25px</span></td>
                </tr>
                <tr>
                    <th class="">is-m-30</th>
                    <td><span class="value">margin: 30px</span></td>
                    <th class="">is-mt-30</th>
                    <td><span class="value">margin-top: 30px</span></td>
                    <th class="">is-mr-30</th>
                    <td><span class="value">margin-right: 30px</span></td>
                    <th class="">is-mb-30</th>
                    <td><span class="value">margin-bottom: 30px</span></td>
                    <th class="">is-ml-30</th>
                    <td><span class="value">margin-left: 30px</span></td>
                </tr>
            </tbody>
        </table>
  
        <h2>Padding <span class="tt-info-txt">요소 내부의 여백</span></h2>
		<table class="tbl-border">
			<colgroup>
				<col width="75px">
				<col>
				<col width="75px">
				<col>
				<col width="75px">
				<col>
				<col width="75px">
				<col>
				<col width="75px">
				<col>
			</colgroup>
			<thead>
				<tr>
					<th>Class명</th>
					<th>CSS</th>
					<th>Class명</th>
					<th>CSS</th>
					<th>Class명</th>
					<th>CSS</th>
					<th>Class명</th>
					<th>CSS</th>
					<th>Class명</th>
					<th>CSS</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th class="">is-p-0</th>
					<td><span class="value">padding: 0</span></td>
					<th class="">is-pt-0</th>
					<td><span class="value">padding-top: 0</span></td>
					<th class="">is-pr-0</th>
					<td><span class="value">padding-right: 0</span></td>
					<th class="">is-pb-0</th>
					<td><span class="value">padding-bottom: 0</span></td>
					<th class="">is-jh-0</th>
					<td><span class="value">padding-left: 0</span></td>
				</tr>
				<tr>
					<th class="">is-p-5</th>
					<td><span class="value">padding: 5px</span></td>
					<th class="">is-pt-5</th>
					<td><span class="value">padding-top: 5px</span></td>
					<th class="">is-pr-5</th>
					<td><span class="value">padding-right: 5px</span></td>
					<th class="">is-pb-5</th>
					<td><span class="value">padding-bottom: 5px</span></td>
					<th class="">is-jh-5</th>
					<td><span class="value">padding-left: 5px</span></td>
				</tr>
				<tr>
					<th class="">is-p-10</th>
					<td><span class="value">padding: 10px</span></td>
					<th class="">is-pt-10</th>
					<td><span class="value">padding-top: 10px</span></td>
					<th class="">is-pr-10</th>
					<td><span class="value">padding-right: 10px</span></td>
					<th class="">is-pb-10</th>
					<td><span class="value">padding-bottom: 10px</span></td>
					<th class="">is-jh-10</th>
					<td><span class="value">padding-left: 10px</span></td>
				</tr>
				<tr>
					<th class="">is-p-15</th>
					<td><span class="value">padding: 15px</span></td>
					<th class="">is-pt-15</th>
					<td><span class="value">padding-top: 15px</span></td>
					<th class="">is-pr-15</th>
					<td><span class="value">padding-right: 15px</span></td>
					<th class="">is-pb-15</th>
					<td><span class="value">padding-bottom: 15px</span></td>
					<th class="">is-jh-15</th>
					<td><span class="value">padding-left: 15px</span></td>
				</tr>
				<tr>
					<th class="">is-p-20</th>
					<td><span class="value">padding: 20px</span></td>
					<th class="">is-pt-20</th>
					<td><span class="value">padding-top: 20px</span></td>
					<th class="">is-pr-20</th>
					<td><span class="value">padding-right: 20px</span></td>
					<th class="">is-pb-20</th>
					<td><span class="value">padding-bottom: 20px</span></td>
					<th class="">is-jh-20</th>
					<td><span class="value">padding-left: 20px</span></td>
				</tr>
				<tr>
					<th class="">is-p-25</th>
					<td><span class="value">padding: 25px</span></td>
					<th class="">is-pt-25</th>
					<td><span class="value">padding-top: 25px</span></td>
					<th class="">is-pr-25</th>
					<td><span class="value">padding-right: 25px</span></td>
					<th class="">is-pb-25</th>
					<td><span class="value">padding-bottom: 25px</span></td>
					<th class="">is-jh-25</th>
					<td><span class="value">padding-left: 25px</span></td>
				</tr>
				<tr>
					<th class="">is-p-30</th>
					<td><span class="value">padding: 30px</span></td>
					<th class="">is-pt-30</th>
					<td><span class="value">padding-top: 30px</span></td>
					<th class="">is-pr-30</th>
					<td><span class="value">padding-right: 30px</span></td>
					<th class="">is-pb-30</th>
					<td><span class="value">padding-bottom: 30px</span></td>
					<th class="">is-jh-30</th>
					<td><span class="value">padding-left: 30px</span></td>
				</tr>
			</tbody>
		</table>
  
    </div>
  </template>
  
  <script>
  export default {
    name: "Align",
    components: { 
    },
    data() {
        return {     
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {  
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>