<template>
    <div class="guide-content"> 
        <div class="guide-content-header">Styles<v-icon class="mdi mdi-chevron-right"></v-icon><span>Align</span></div>
  
        <h2>Vertical Align</h2>
        <div class="jh-cols" style="height: 200px;">
            <div
                class="guide-card jh-align-top"            
                outlined>
                <span class="jh-label">위로 정렬</span>
                <button type="button" class="jh-btn is-md is-main" id="" name="">위로 정렬</button>
            </div>
            <div
                class="guide-card jh-align-middle"            
                outlined>
                <span class="jh-label">중간 정렬</span>
                <button type="button" class="jh-btn is-md is-main" id="" name="">중간 정렬</button>
            </div>
            <div
                class="guide-card jh-align-bottom"            
                outlined>
                <span class="jh-label">아래로 정렬</span>
                <button type="button" class="jh-btn is-md is-main" id="" name="">아래로 정렬</button>
            </div>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source01">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>
  
        <h2>Horizontal Align</h2>
        <div class="jh-cols" style="height: 200px;">
            <div
                class="guide-card jh-align-middle jh-align-left"            
                outlined>
                <span class="jh-label">왼쪽 정렬</span>
                <button type="button" class="jh-btn is-md is-main" id="" name="">왼쪽 정렬</button>
            </div>
            <div
                class="guide-card jh-align-middle jh-align-center"            
                outlined>
                <span class="jh-label">가운데 정렬</span>
                <button type="button" class="jh-btn is-md is-main" id="" name="">가운데 정렬</button>
            </div>
            <div
                class="guide-card jh-align-middle jh-align-right"            
                outlined>
                <span class="jh-label">오른쪽 정렬</span>
                <button type="button" class="jh-btn is-md is-main" id="" name="">가운데 정렬</button>
            </div>
            <div
                class="guide-card jh-align-middle jh-align-space"            
                outlined>
                <span class="jh-label">양쪽 정렬</span>
                <button type="button" class="jh-btn is-md is-main" id="" name="">양쪽 정렬</button>
            </div>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source01">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>
    
    </div>
  </template>
  
  <script>
  /*
  https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
  npm install --save highlight.js
  */
  import hljs from 'highlight.js';
  export default {
    name: "Align",
    directives: {
        'highlightjs': {
            bind: function (el, binding) {
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                    if (binding.value) {
                        target.innerHTML = binding.value
                    }
                    hljs.highlightBlock(target)
                })
            },
            componentUpdated: function(el, binding) {
                // after an update, re-fill the content and then highlight
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                if (binding.value) {
                    target.textContent = binding.value
                    hljs.highlightBlock(target)
                }
                })
            }
        }
    },
    components: { 
    },
    data() {
        return {        
            source01: 'HTML',
            content:{
  
source01_tab01: `<div class="jh-align-top">
    <span class="jh-label">위로 정렬</span>
    <button type="button" class="jh-btn is-md is-main" id="" name="">위로 정렬</button>
</div>
<div class="jh-align-middle">
    <span class="jh-label">중간 정렬</span>
    <button type="button" class="jh-btn is-md is-main" id="" name="">중간 정렬</button>
</div>
<div class="jh-align-bottom">
    <span class="jh-label">아래로 정렬</span>
    <button type="button" class="jh-btn is-md is-main" id="" name="">아래로 정렬</button>
</div>`
,source02_tab01: `<div class="jh-align-middle jh-align-left">
    <span class="jh-label">왼쪽 정렬</span>
    <button type="button" class="jh-btn is-md is-main" id="" name="">왼쪽 정렬</button>
</div>
<div class="jh-align-middle jh-align-center">
    <span class="jh-label">가운데 정렬</span>
    <button type="button" class="jh-btn is-md is-main" id="" name="">가운데 정렬</button>
</div>
<div class="jh-align-middle jh-align-right">
    <span class="jh-label">오른쪽 정렬</span>
    <button type="button" class="jh-btn is-md is-main" id="" name="">오른쪽 정렬</button>
</div>
<div class="jh-align-middle jh-align-space">
    <span class="jh-label">양쪽 정렬</span>
    <button type="button" class="jh-btn is-md is-main" id="" name="">양쪽 정렬</button>
</div>`
            }
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {  
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>