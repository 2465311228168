<template>
  <div>
  </div>
</template>

<script>
export default {
  name: "WinPop",
  computed: {
  },
  data() {
    return {
      // 6. 윈도우 팝업 변수
      windowRef : null,
    };
  },

  created() {
  },

  mounted() {
  },

  watch: {
  },

  methods: {
    openWinPop: function(uri, width, height) {
      if(this.windowRef != null) {
        this.closeWinPop();
      }

      let left = (screen.width) ? (screen.width - width)  / 2 : 0;
      let top  = (screen.height)? (screen.height - height)/ 2 : 0;

      let attr = 'top=' + top + ', left=' + left + ', width=' + width + ', height=' + height + ', resizeble=no,status=no';

      // 윈도우 팝업 띄우기
      this.windowRef = window.open(uri, "", attr);
      if( this.windowRef != null ) {
        this.windowRef.addEventListener('beforeunload', this.evtClose);
      } else {
        alert( "window.open Fail!!!" );
      }


      // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
      window.addEventListener("message", this.recvEvtFromChild, false);
    },
    openRsvPop: function(uri, width, height) {
      if(this.windowRef != null) {
        this.closeWinPop();
      }

      let left = (screen.width) ? (screen.width - width)  / 2 : 0;
      let top  = (screen.height)? (screen.height - height)/ 2 : 0;

      let attr = 'top=' + top + ', left=' + left + ', width=' + width + ', height=' + height + ', resizeble=no,status=no';
      console.log("openRsvPop >>>>>>>>>>>uri:" +uri);

      // 윈도우 팝업 띄우기
      this.windowRef = window.open(uri, "", attr);
      if( this.windowRef != null ) {
        this.windowRef.addEventListener('beforeunload', this.evtClose);
      } else {
        alert( "window.open Fail!!!" );
      }

      if(uri != null && uri != ""){
          
        if(uri.indexOf("T100201") > 0){          
          // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
          window.addEventListener("message", this.recvPopEvtFromChild_T100201, false);

        }else if(uri.indexOf("T100301") > 0){
          // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
          window.addEventListener("message", this.recvPopEvtFromChild_T100301, false);

        }else if(uri.indexOf("T100401") > 0){
          // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
          window.addEventListener("message", this.recvPopEvtFromChild_T100401, false);
          
        }else if(uri.indexOf("T100501") > 0){
           // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
          window.addEventListener("message", this.recvPopEvtFromChild_T100501, false);

        }else if(uri.indexOf("T100601") > 0){
          // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
          window.addEventListener("message", this.recvPopEvtFromChild_T100601, false);

        }else if(uri.indexOf("T100701") > 0){
          // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
          window.addEventListener("message", this.recvPopEvtFromChild_T100701, false);

        }else if(uri.indexOf("T100801") > 0){
          // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
          window.addEventListener("message", this.recvPopEvtFromChild_T100801, false);

        }
        
      }else{
      
        // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
        window.addEventListener("message", this.recvPopEvtFromChild, false);
      }
     
    },

    openWinPop2: function(uri, width, height) {

      console.log("============openWinPop2=================");

      if(this.windowRef != null) {
        this.closeWinPop();
      }

      let left = (screen.width) ? (screen.width - width)  / 2 : 0;
      let top  = (screen.height)? (screen.height - height)/ 2 : 0;

      let attr = 'top=' + top + ', left=' + left + ', width=' + width + ', height=' + height + ', resizeble=no,status=no';

      // 윈도우 팝업 띄우기
      this.windowRef = window.open(uri, "", attr);
      if( this.windowRef != null ) {
        this.windowRef.addEventListener('beforeunload', this.evtClose);
      } else {
        alert( "window.open Fail!!!" );
      }

        console.log("============openWinPop2=================");
        
      // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
      window.addEventListener("message", this.recvEvtMain, false);
    },

    openVMSPop: function(uri, width, height) {
      if(this.windowRef != null) {
        this.closeWinPop();
      }

      let left = (screen.width) ? (screen.width - width)  / 2 : 0;
      let top  = (screen.height)? (screen.height - height)/ 2 : 0;

      let attr = 'top=' + top + ', left=' + left + ', width=' + width + ', height=' + height + ', resizeble=no,status=no';

      // 윈도우 팝업 띄우기
      this.windowRef = window.open(uri, "", attr);
      if( this.windowRef != null ) {
        this.windowRef.addEventListener('beforeunload', this.evtClose);
      } else {
        alert( "window.open Fail!!!" );
      }

      // 새로 띄운 윈도우 팝업창으로 부터 수신 메세지 이벤트 처리
      window.addEventListener("message", this.recvVMSEvtFromChild, false);
    },

    // 윈도우 팝업 닫기
    closeWinPop: function() {
      if(this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
      }
    },

    evtClose: function() {
      if(this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
        this.$emit('onClose');
      }
    },

    // 부모창에서 팝업창에 메세지 보내기
    sendEvtToChild: function(evt) {
      if(this.windowRef) {
        //return;
      }
      if(g_winPopup == null) {
        return;
      }
      // 윈도팝업창()에 함수 실행
      g_winPopup.calledFromOpener(evt);
    },

    recvEvtFromChild: function(evt) {
      if( evt.data == null ){
        return;
      }
      let recvObj = JSON.parse(evt.data);

      if (recvObj.message == 'P100401') {
        console.log("recvEvtFromChild>>>>>>>");
        // WinPop.vue를 콤포넌트로 사용하는 부모 측에 이벤트 전달
        this.$emit('onRecvEvtFromWinPop',  recvObj);
      }
    },
    recvEvtMain: function(evt) {
      if( evt.data == null ){
        return;
      }
      let recvObj = JSON.stringify(evt.data);
      const message = evt.data.message;

      if (evt.data.message == 'E010201') {
        console.log("recvEvtMain>>>>>>>");
        // WinPop.vue를 콤포넌트로 사용하는 부모 측에 이벤트 전달
        this.$emit('onRecvEvtMain',  evt.data);
      }
    },
    recvPopEvtFromChild(evt) {
      const message = evt.data.message;

      if (message == 'T100201' || message == 'T100301' || message == 'T100401' || message == 'T100501' || 
        message == 'T100601' || message == 'T100701' || message == 'T100801') {
        console.log("recvPopEvtFromChild>>>>>>>");
        // 콜상담 메인 탭 열기
        this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
          id: "E010101",
          title: "콜상담 메인",
          default: "N",
          tabIcon: "",
          link: "/E010101"
        });
  
        this.$emit('onRecvEvtFromRsvPop', evt.data);
      }
    },
    recvPopEvtFromChild_T100201(evt) {
      const message = evt.data.message;

      if (message == 'T100201' ) {
        console.log("T100201 recvPopEvtFromChild>>>>>>>");
        // 콜상담 메인 탭 열기
        this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
          id: "E010101",
          title: "콜상담 메인",
          default: "N",
          tabIcon: "",
          link: "/E010101"
        });
  
        this.$emit('onRecvEvtFromRsvPop', evt.data);
      }
    },
    recvPopEvtFromChild_T100301(evt) {
      const message = evt.data.message;

      if (message == 'T100301') {
        console.log("T100301 recvPopEvtFromChild>>>>>>>");
        // 콜상담 메인 탭 열기
        this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
          id: "E010101",
          title: "콜상담 메인",
          default: "N",
          tabIcon: "",
          link: "/E010101"
        });
  
        this.$emit('onRecvEvtFromRsvPop', evt.data);
      }
    },
    recvPopEvtFromChild_T100401(evt) {
      const message = evt.data.message;

      if (message == 'T100401') {
        console.log("T100401 recvPopEvtFromChild>>>>>>>");
        // 콜상담 메인 탭 열기
        this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
          id: "E010101",
          title: "콜상담 메인",
          default: "N",
          tabIcon: "",
          link: "/E010101"
        });
  
        this.$emit('onRecvEvtFromRsvPop', evt.data);
      }
    },
    recvPopEvtFromChild_T100501(evt) {
      const message = evt.data.message;

      if (message == 'T100501') {
          console.log("T100501 recvPopEvtFromChild>>>>>>>");
          // 콜상담 메인 탭 열기
          this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
            id: "E010101",
            title: "콜상담 메인",
            default: "N",
            tabIcon: "",
            link: "/E010101"
          });
    
          this.$emit('onRecvEvtFromRsvPop', evt.data);
        }
    },
    recvPopEvtFromChild_T100601(evt) {
      const message = evt.data.message;

     
      if (message == 'T100601') {
          console.log("T100601 recvPopEvtFromChild>>>>>>>");
          // 콜상담 메인 탭 열기
          this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
            id: "E010101",
            title: "콜상담 메인",
            default: "N",
            tabIcon: "",
            link: "/E010101"
          });
    
          this.$emit('onRecvEvtFromRsvPop', evt.data);
        }
    },
    recvPopEvtFromChild_T100701(evt) {
      const message = evt.data.message;

     
      if (message == 'T100701') {
          console.log("T100701 recvPopEvtFromChild>>>>>>>");
          // 콜상담 메인 탭 열기
          this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
            id: "E010101",
            title: "콜상담 메인",
            default: "N",
            tabIcon: "",
            link: "/E010101"
          });
  
        this.$emit('onRecvEvtFromRsvPop', evt.data);
      }
    },
    recvPopEvtFromChild_T100801(evt) {
      const message = evt.data.message;
     
      if (message == 'T100801') {
          console.log("T100801 recvPopEvtFromChild>>>>>>>");
          // 콜상담 메인 탭 열기
          this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
            id: "E010101",
            title: "콜상담 메인",
            default: "N",
            tabIcon: "",
            link: "/E010101"
          });
    
          this.$emit('onRecvEvtFromRsvPop', evt.data);
        }
    },

    recvVMSEvtFromChild: function(evt) {
      if( evt.data == null ){
        return;
      }
      let recvObj = JSON.parse(evt.data);

      if (recvObj.message == 'P100701' || recvObj.message == 'P100801') {
        console.log("recvVMSEvtFromChild>>>>>>>");
        // WinPop.vue를 콤포넌트로 사용하는 부모 측에 이벤트 전달
        this.$emit('onRecvEvtVMSFromWinPop',  recvObj);
      }
    },
  },

  
};
</script>

<style scoped></style>
