<template>
    <div class="guide-content"> 
        <div class="guide-content-header">Styles<v-icon class="mdi mdi-chevron-right"></v-icon><span>Text</span></div>

        <h2>Title</h2>
        <div class="guide-card">
            <h1>H1</h1>
            <h2>H2</h2>
            <h3>H3</h3>
            <h4>H4</h4>
            <h5>H5</h5>
            <h6>H6</h6>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source01">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>Title 강조</h2>
        <div class="guide-card">
            <h1>H1 <strong>강조</strong> <em>컬러강조</em></h1>
            <h2>H2 <strong>강조</strong> <em>컬러강조</em></h2>
            <h3>H3 <strong>강조</strong> <em>컬러강조</em></h3>
            <h4>H4 <strong>강조</strong> <em>컬러강조</em></h4>
            <h5>H5 <strong>강조</strong> <em>컬러강조</em></h5>
            <h6>H6 <strong>강조</strong> <em>컬러강조</em></h6>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source02">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source02">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>Info Text</h2>
        <div class="guide-card">
            <div class="jh-info-txt">안내문 및 도움말입니다.</div>
            <div class="jh-info-txt is-fill">안내문 및 도움말입니다.</div>
            <div class="jh-info-txt is-blue">안내문 및 도움말입니다.</div>
            <div class="jh-info-txt is-fill is-blue">안내문 및 도움말입니다.</div>
            <div class="jh-info-txt is-red">안내문 및 도움말입니다.</div>
            <div class="jh-info-txt is-fill is-red">안내문 및 도움말입니다.</div>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source03">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source03">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>지시문</h2>
        <div class="guide-card">
            <p class="jh-desc">기능 설명을 하는 지시문입니다.</p>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source04">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source04">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>Info Box</h2>
        <div class="guide-card">
            <div class="jh-info-box">
                <h1>안내문 제목입니다.</h1>
                <p class="jh-desc">기능 설명을 하는 지시문입니다.</p>
                <div class="jh-info-txt">안내문 및 도움말입니다.</div>
            </div>
            <div class="jh-info-box">
                <h1>안내문 제목입니다.</h1>
                <p class="jh-desc">기능 설명을 하는 지시문입니다.</p>
                <div class="jh-info-txt">안내문 및 도움말입니다.</div>
                <ul class="jh-list-dot">
                    <li>안내문 및 도움말입니다.</li>
                    <li>안내문 및 도움말입니다.</li>
                    <li>안내문 및 도움말입니다.</li>
                </ul>
            </div>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source05">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source05">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>Dot List</h2>
        <div class="guide-card">
            <ul class="jh-list-dot">
                <li><em>강조</em> 리스트 내용</li>
                <li><em>강조</em> 리스트 내용</li>
                <li><em>강조</em> 리스트 내용</li>
                <li><em>강조</em> 리스트 내용</li>
                <li><em>강조</em> 리스트 내용</li>
            </ul>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source06">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source06">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source06_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>라벨 텍스트</h2>
        <div
            class="guide-card"            
            outlined>
            <span class="jh-label">라벨</span>
            <span class="jh-label-value">20</span>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source07">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source07">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source07_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>배지 텍스트</h2>
        <div class="guide-card">
            <span class="jh-badge">배지</span>
            <span class="jh-badge is-red">배지</span>
            <span class="jh-badge is-org">배지</span>
            <span class="jh-badge is-yellow">배지</span>
            <span class="jh-badge is-blue">배지</span>
            <span class="jh-badge is-cyan">배지</span>
            <span class="jh-badge is-indigo">배지</span>
            <span class="jh-badge is-blueGray">배지</span>
            <span class="jh-badge is-green">배지</span>
            <span class="jh-badge is-purple">배지</span>
            <span class="jh-badge is-main">배지</span>
            <span class="jh-badge is-black">배지</span>
            <span class="jh-badge is-white">배지</span>
        </div>
        <div class="guide-card is-mt-5">
            <span class="jh-badge is-number">0</span>
            <span class="jh-badge is-number is-red">0</span>
            <span class="jh-badge is-number is-org">0</span>
            <span class="jh-badge is-number is-yellow">0</span>
            <span class="jh-badge is-number is-blue">0</span>
            <span class="jh-badge is-number is-cyan">0</span>
            <span class="jh-badge is-number is-indigo">0</span>
            <span class="jh-badge is-number is-blueGray">0</span>
            <span class="jh-badge is-number is-green">0</span>
            <span class="jh-badge is-number is-purple">0</span>
            <span class="jh-badge is-number is-main">0</span>
            <span class="jh-badge is-number is-black">0</span>
            <span class="jh-badge is-number is-white">0</span>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source14">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source14">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source14_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>필수 표시</h2>
        <div class="guide-card">
            <label class="jh-label is-required">필수요소 표시입니다.</label>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source08">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source08">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source08_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>유닛 텍스트</h2>
        <div class="guide-card">
            <span class="jh-unit">~</span>
            <span class="jh-unit">/</span>
            <span class="jh-unit">%</span>
            <span class="jh-unit">원</span>
            <span class="jh-unit">일</span>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source09">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source09">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source09_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>링크 텍스트</h2>
        <div class="guide-card">
            <span class="jh-link-txt">여기를 클릭해 주세요.</span>
            <a href="#" class="jh-link-txt">여기를 클릭해 주세요.</a>
            <a href="#">여기를 클릭해 주세요.</a>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source10">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source10">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source10_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>NO 데이터 텍스트</h2>
        <div class="guide-card">
            <p class="jh-no-data"><span>현재 데이터가 없습니다.</span></p>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source11">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source11">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source11_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>텍스트 영역</h2>
        <div class="guide-card">
            <div class="jh-txt-area" style="height:200px;">textarea 태그와 같은 역할을 하는 div입니다.</div>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source12">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source12">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source12_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>텍스트 스타일</h2>
        <div class="guide-card">
            <div class="jh-cols">
                <input type="text" class="jh-form is-lg is-txt-red" value="is-txt-red">
                <input type="text" class="jh-form is-lg is-txt-org" value="is-txt-org">
                <input type="text" class="jh-form is-lg is-txt-yellow" value="is-txt-yellow">
                <input type="text" class="jh-form is-lg is-txt-blue" value="is-txt-blue">
                <input type="text" class="jh-form is-lg is-txt-cyan" value="is-txt-cyan">
                <input type="text" class="jh-form is-lg is-txt-indigo" value="is-txt-indigo">
            </div>
            <div class="jh-cols">
                <input type="text" class="jh-form is-lg is-txt-green" value="is-txt-green">
                <input type="text" class="jh-form is-lg is-txt-purple" value="is-txt-purple">
                <input type="text" class="jh-form is-lg is-txt-blueGray" value="is-txt-blueGray">
                <input type="text" class="jh-form is-lg is-txt-black" value="is-txt-black">
                <input type="text" class="jh-form is-lg is-bg-black is-txt-white" value="is-txt-white">
                <input type="text" class="jh-form is-lg is-txt-bold" value="is-txt-bold">
            </div>
            <div class="jh-cols">
                <input type="text" class="jh-form is-lg is-txt-left" value="is-txt-left">
                <input type="text" class="jh-form is-lg is-txt-center" value="is-txt-center">
                <input type="text" class="jh-form is-lg is-txt-right" value="is-txt-right">
            </div>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source13">
            <v-tab>Html</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source13">
            <v-tab-item>
                <pre class="guide-code" v-highlightjs><code class="html">{{content.source13_tab01}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

    </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
    name: "Tex2t",
    directives: {
        'highlightjs': {
            bind: function (el, binding) {
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                    if (binding.value) {
                        target.innerHTML = binding.value
                    }
                    hljs.highlightBlock(target)
                })
            },
            componentUpdated: function(el, binding) {
                // after an update, re-fill the content and then highlight
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                if (binding.value) {
                    target.textContent = binding.value
                    hljs.highlightBlock(target)
                }
                })
            }
        }
    },
    components: { 
    },
    data() {
        return {        
            source01: 'HTML',
            source02: 'HTML',
            source03: 'HTML',
            source04: 'HTML',
            source05: 'HTML',
            source06: 'HTML',
            source07: 'HTML',
            source08: 'HTML',
            source09: 'HTML',
            source10: 'HTML',
            source11: 'HTML',
            source12: 'HTML',
            source13: 'HTML',
            source14: 'HTML',
            content:{

source01_tab01: `<h1>H1</h1>
<h2>H2</h2>
<h3>H3</h3>
<h4>H4</h4>
<h5>H5</h5>
<h6>H6</h6>`
,source02_tab01: `<h1>H1 <strong>강조</strong> <em>컬러강조</em></h1>
<h2>H2 <strong>강조</strong> <em>컬러강조</em></h2>
<h3>H3 <strong>강조</strong> <em>컬러강조</em></h3>
<h4>H4 <strong>강조</strong> <em>컬러강조</em></h4>
<h5>H5 <strong>강조</strong> <em>컬러강조</em></h5>
<h6>H6 <strong>강조</strong> <em>컬러강조</em></h6>`
,source03_tab01: `<!-- div, p, span 태그 모두 가능합니다. -->
<div class="jh-info-txt">안내문 및 도움말입니다.</div>
<div class="jh-info-txt is-fill">안내문 및 도움말입니다.</div>
<div class="jh-info-txt is-blue">안내문 및 도움말입니다.</div>
<div class="jh-info-txt is-fill is-blue">안내문 및 도움말입니다.</div>
<div class="jh-info-txt is-red">안내문 및 도움말입니다.</div>
<div class="jh-info-txt is-fill is-red">안내문 및 도움말입니다.</div>`
,source04_tab01: `<p class="jh-desc">기능 설명을 하는 지시문입니다.</p>`
,source05_tab01: `<div class="jh-info-box">
    <h1>안내문 제목입니다.</h1>
    <p class="jh-desc">기능 설명을 하는 지시문입니다.</p>
    <div class="jh-info-txt">안내문 및 도움말입니다.</div>
</div>
<div class="jh-info-box">
    <h1>안내문 제목입니다.</h1>
    <p class="jh-desc">기능 설명을 하는 지시문입니다.</p>
    <ul class="jh-list-dot">
        <li>안내문 및 도움말입니다.</li>
        <li>안내문 및 도움말입니다.</li>
        <li>안내문 및 도움말입니다.</li>
    </ul>
</div>`
,source06_tab01: `<ul class="jh-list-dot">
    <li><em>강조</em> 리스트 내용</li>
    <li><em>강조</em> 리스트 내용</li>
    <li><em>강조</em> 리스트 내용</li>
    <li><em>강조</em> 리스트 내용</li>
    <li><em>강조</em> 리스트 내용</li>
</ul>`
,source07_tab01: `<span class="jh-label">라벨</span>
<span class="jh-label-value">20</span>`
,source08_tab01: `<label class="jh-label is-required">필수요소 표시입니다.</label>`
,source09_tab01: `<span class="jh-unit">~</span>
<span class="jh-unit">/</span>
<span class="jh-unit">%</span>
<span class="jh-unit">원</span>
<span class="jh-unit">일</span>`
,source10_tab01: `<span class="jh-link-txt">여기를 클릭해 주세요.</span>
<a href="#" class="jh-link-txt">여기를 클릭해 주세요.</a>
<a href="#">여기를 클릭해 주세요.</a>`
,source11_tab01: `<p class="jh-no-data"><span>현재 데이터가 없습니다.</span></p>`
,source12_tab01: `<div class="jh-txt-area" style="height:200px;">textarea 태그와 같은 역할을 하는 div입니다.</div>`
,source13_tab01: `<input type="text" class="jh-form is-txt-red" value="is-txt-red">
<input type="text" class="jh-form is-txt-org" value="is-txt-org">
<input type="text" class="jh-form is-txt-yellow" value="is-txt-yellow">
<input type="text" class="jh-form is-txt-blue" value="is-txt-blue">
<input type="text" class="jh-form is-txt-cyan" value="is-txt-cyan">
<input type="text" class="jh-form is-txt-indigo" value="is-txt-indigo">
<input type="text" class="jh-form is-txt-green" value="is-txt-green">
<input type="text" class="jh-form is-txt-purple" value="is-txt-purple">
<input type="text" class="jh-form is-txt-blueGray" value="is-txt-blueGray">
<input type="text" class="jh-form is-txt-black" value="is-txt-black">
<input type="text" class="jh-form is-bg-black is-txt-white" value="is-txt-white">
<input type="text" class="jh-form is-txt-bold" value="is-txt-bold">
<input type="text" class="jh-form is-txt-left" value="is-txt-left">
<input type="text" class="jh-form is-txt-center" value="is-txt-center">
<input type="text" class="jh-form is-txt-right" value="is-txt-right">`
,source14_tab01: `<span class="jh-badge">배지</span>
<span class="jh-badge is-red">배지</span>
<span class="jh-badge is-org">배지</span>
<span class="jh-badge is-yellow">배지</span>
<span class="jh-badge is-blue">배지</span>
<span class="jh-badge is-cyan">배지</span>
<span class="jh-badge is-indigo">배지</span>
<span class="jh-badge is-blueGray">배지</span>
<span class="jh-badge is-green">배지</span>
<span class="jh-badge is-purple">배지</span>
<span class="jh-badge is-main">배지</span>
<span class="jh-badge is-black">배지</span>
<span class="jh-badge is-white">배지</span>


<span class="jh-badge is-number">0</span>
<span class="jh-badge is-number is-red">0</span>
<span class="jh-badge is-number is-org">0</span>
<span class="jh-badge is-number is-yellow">0</span>
<span class="jh-badge is-number is-blue">0</span>
<span class="jh-badge is-number is-cyan">0</span>
<span class="jh-badge is-number is-indigo">0</span>
<span class="jh-badge is-number is-blueGray">0</span>
<span class="jh-badge is-number is-green">0</span>
<span class="jh-badge is-number is-purple">0</span>
<span class="jh-badge is-number is-main">0</span>
<span class="jh-badge is-number is-black">0</span>
<span class="jh-badge is-number is-white">0</span>`
            }
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {  
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>