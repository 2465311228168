<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Styles<v-icon class="mdi mdi-chevron-right"></v-icon><span>Color</span></div>

      <h2>Text Color</h2>
      <div class="guide-card">
          <div class="jh-cols">
            <input type="text" class="jh-form is-lg is-txt-red" value="is-txt-red">
            <input type="text" class="jh-form is-lg is-txt-org" value="is-txt-org">
            <input type="text" class="jh-form is-lg is-txt-yellow" value="is-txt-yellow">
            <input type="text" class="jh-form is-lg is-txt-blue" value="is-txt-blue">
            <input type="text" class="jh-form is-lg is-txt-cyan" value="is-txt-cyan">
            <input type="text" class="jh-form is-lg is-txt-indigo" value="is-txt-indigo">
          </div>
          <div class="jh-cols">
            <input type="text" class="jh-form is-lg is-txt-green" value="is-txt-green">
            <input type="text" class="jh-form is-lg is-txt-purple" value="is-txt-purple">
            <input type="text" class="jh-form is-lg is-txt-blueGray" value="is-txt-blueGray">
            <input type="text" class="jh-form is-lg is-txt-black" value="is-txt-black">
            <input type="text" class="jh-form is-lg is-bg-black is-txt-white" value="is-txt-white">
          </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Background Color</h2>
      <div class="guide-card">
        <div class="jh-cols">
          <input type="text" class="jh-form is-bg-red is-lg is-txt-white" value="is-bg-red">
          <input type="text" class="jh-form is-bg-org is-lg is-txt-white" value="is-bg-org">
          <input type="text" class="jh-form is-bg-yellow is-lg is-txt-white" value="is-bg-yellow">
          <input type="text" class="jh-form is-bg-blue is-lg is-txt-white" value="is-bg-blue">
          <input type="text" class="jh-form is-bg-cyan is-lg is-txt-white" value="is-bg-cyan">
          <input type="text" class="jh-form is-bg-indigo is-lg is-txt-white" value="is-bg-indigo">
        </div>
        <div class="jh-cols">
          <input type="text" class="jh-form is-bg-green is-lg is-txt-white" value="is-bg-green">
          <input type="text" class="jh-form is-bg-purple is-lg is-txt-white" value="is-bg-purple">
          <input type="text" class="jh-form is-bg-blueGray is-lg is-txt-white" value="is-bg-blueGray">
          <input type="text" class="jh-form is-bg-black is-lg is-txt-white" value="is-bg-black">
          <input type="text" class="jh-form is-bg-white is-lg" value="is-bg-white">
          <input type="text" class="jh-form is-bg-light is-lg" value="is-bg-light">
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Background Light Color</h2>
      <div class="guide-card">
        <div class="jh-cols">
          <input type="text" class="jh-form is-bg-light-red is-lg" value="is-bg-light-red">
          <input type="text" class="jh-form is-bg-light-org is-lg" value="is-bg-light-org">
          <input type="text" class="jh-form is-bg-light-yellow is-lg" value="is-bg-light-yellow">
          <input type="text" class="jh-form is-bg-light-blue is-lg" value="is-bg-light-blue">
          <input type="text" class="jh-form is-bg-light-cyan is-lg" value="is-bg-light-cyan">
          <input type="text" class="jh-form is-bg-light-indigo is-lg" value="is-bg-light-indigo">
          <input type="text" class="jh-form is-bg-light-green is-lg" value="is-bg-light-green">
          <input type="text" class="jh-form is-bg-light-purple is-lg" value="is-bg-light-purple">
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Border Color</h2>
      <div class="guide-card">
        <div class="jh-cols">
          <input type="text" class="jh-form is-border-red is-lg" value="is-border-red">
          <input type="text" class="jh-form is-border-org is-lg" value="is-border-org">
          <input type="text" class="jh-form is-border-yellow is-lg" value="is-border-yellow">
          <input type="text" class="jh-form is-border-blue is-lg" value="is-border-blue">
          <input type="text" class="jh-form is-border-cyan is-lg" value="is-border-cyan">
          <input type="text" class="jh-form is-border-indigo is-lg" value="is-border-indigo">
        </div>
        <div class="jh-cols">
          <input type="text" class="jh-form is-border-green is-lg" value="is-border-green">
          <input type="text" class="jh-form is-border-purple is-lg" value="is-border-purple">
          <input type="text" class="jh-form is-border-blueGray is-lg" value="is-border-blueGray">
          <input type="text" class="jh-form is-border-black is-lg" value="is-border-black">
          <input type="text" class="jh-form is-border-white is-lg" value="is-border-white">
          <input type="text" class="jh-form is-border-light is-lg" value="is-border-light">
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Color",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {        
          source01: 'HTML',
          content:{

source01_tab01: `<input type="text" class="jh-form is-txt-red" value="is-txt-red">
<input type="text" class="jh-form is-txt-org" value="is-txt-org">
<input type="text" class="jh-form is-txt-yellow" value="is-txt-yellow">
<input type="text" class="jh-form is-txt-blue" value="is-txt-blue">
<input type="text" class="jh-form is-txt-cyan" value="is-txt-cyan">
<input type="text" class="jh-form is-txt-indigo" value="is-txt-indigo">
<input type="text" class="jh-form is-txt-green" value="is-txt-green">
<input type="text" class="jh-form is-txt-purple" value="is-txt-purple">
<input type="text" class="jh-form is-txt-blueGray" value="is-txt-blueGray">
<input type="text" class="jh-form is-txt-gray" value="is-txt-gray">
<input type="text" class="jh-form is-txt-black" value="is-txt-black">
<input type="text" class="jh-form is-bg-black is-txt-white" value="is-txt-white">`
,source02_tab01: `<input type="text" class="jh-form is-bg-red is-txt-white" value="is-bg-red">
<input type="text" class="jh-form is-bg-org is-txt-white" value="is-bg-org">
<input type="text" class="jh-form is-bg-yellow is-txt-white" value="is-bg-yellow">
<input type="text" class="jh-form is-bg-blue is-txt-white" value="is-bg-blue">
<input type="text" class="jh-form is-bg-cyan is-txt-white" value="is-bg-cyan">
<input type="text" class="jh-form is-bg-indigo is-txt-white" value="is-bg-indigo">
<input type="text" class="jh-form is-bg-green is-txt-white" value="is-bg-green">
<input type="text" class="jh-form is-bg-purple is-txt-white" value="is-bg-purple">
<input type="text" class="jh-form is-bg-blueGray is-txt-white" value="is-bg-blueGray">
<input type="text" class="jh-form is-bg-black is-txt-white" value="is-bg-black">
<input type="text" class="jh-form is-bg-white" value="is-bg-white">
<input type="text" class="jh-form is-bg-light is-lg" value="is-bg-light">`
,source03_tab01: `<input type="text" class="jh-form is-bg-light-red is-lg" value="is-bg-light-red">
<input type="text" class="jh-form is-bg-light-org is-lg" value="is-bg-light-org">
<input type="text" class="jh-form is-bg-light-yellow is-lg" value="is-bg-light-yellow">
<input type="text" class="jh-form is-bg-light-blue is-lg" value="is-bg-light-blue">
<input type="text" class="jh-form is-bg-light-cyan is-lg" value="is-bg-light-cyan">
<input type="text" class="jh-form is-bg-light-indigo is-lg" value="is-bg-light-indigo">
<input type="text" class="jh-form is-bg-light-green is-lg" value="is-bg-light-green">
<input type="text" class="jh-form is-bg-light-purple is-lg" value="is-bg-light-purple">`
,source04_tab01: `<input type="text" class="jh-form is-border-red is-lg" value="is-border-red">
<input type="text" class="jh-form is-border-org is-lg" value="is-border-org">
<input type="text" class="jh-form is-border-yellow is-lg" value="is-border-yellow">
<input type="text" class="jh-form is-border-blue is-lg" value="is-border-blue">
<input type="text" class="jh-form is-border-cyan is-lg" value="is-border-cyan">
<input type="text" class="jh-form is-border-indigo is-lg" value="is-border-indigo">
<input type="text" class="jh-form is-border-green is-lg" value="is-border-green">
<input type="text" class="jh-form is-border-purple is-lg" value="is-border-purple">
<input type="text" class="jh-form is-border-blueGray is-lg" value="is-border-blueGray">
<input type="text" class="jh-form is-border-black is-lg" value="is-border-black">
<input type="text" class="jh-form is-border-white is-lg" value="is-border-white">
<input type="text" class="jh-form is-border-light is-lg" value="is-border-light">`
          }
      }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>