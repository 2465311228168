<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Patterns<v-icon class="mdi mdi-chevron-right"></v-icon><span>상담이미지목록</span></div>

      <!-- <h2>Default</h2> -->
      <div class="jh-form-wrap is-mt-10">
        <div class="jh-ui-header">
          <h2>상담이미지목록</h2>
          <div class="is-right">
            <label class="jh-label">보기정렬</label>
            <v-radio-group class="jh-radio-group" v-model="CONST_BTNCOUNT_GROUP">
              <v-radio v-for="i in CONST_BTNCOUNT.TO" :key="i"  v-if="i >= CONST_BTNCOUNT.FROM" :label=" `${i}열`" :value="i" @click="changeRow(i)"></v-radio>
            </v-radio-group>
            <div class="jh-split"></div>
            <v-btn class="jh-btn is-round is-main">이미지 등록</v-btn>
          </div>
        </div>
        
        <div class="jh-list-image" style="height: 602px;">
          <p class="jh-no-data" :class="historyList.length > 0 ? 'is-hidden': ''"><span>현재 데이터가 없습니다.</span></p>
          <ul :class="SelectAlign">
            <!-- <li v-for="i in 10" :key="i" @click="vcardClick(i)">
                <div class="is-image-title" v-html="`trans${i}.gif`"></div>
                <div class="is-image-date" v-html="`2022-10-${i}`"></div>
                <img :src="`/emoticon/trans${i}.gif`">
            </li> -->
            <li v-for="i in historyList" :key="i" :title="i.BRD_TIT">
                <div class="is-image-title" v-html="i.BRD_TIT"></div>
                <div class="is-image-date" v-html="i.FST_BRD_DT_D"></div>
                <img :src="i.src">
            </li>
            <!-- <li v-for="i in 10" :key="i">
                <div class="tt-image-title" v-html="`https://randomuser.me/api/portraits/women/${i + 5}.jpg`"></div>
                <div class="tt-image-area"><img :src="`https://randomuser.me/api/portraits/women/${i + 5}.jpg`"></div>
            </li> -->
          </ul>
        </div>
        <!-- <div class="jh-pager">
          <v-pagination v-model="page" :length="pages" :total-visible="10" @input="updatePage"></v-pagination>
          <span class="jh-counter is-left">총 <em>{{ historyList.length }}</em> 건</span>
        </div> -->
    </div>
    <div class="guide-source">
    <v-tabs v-model="source01">
        <v-tab>Html</v-tab>
        <v-tab>Script</v-tab>
    </v-tabs>
    <v-tabs-items v-model="source01">
        <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
        </v-tab-item>
        <v-tab-item>
          <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
        </v-tab-item>
    </v-tabs-items>
    </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Table",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {  
        source01: 'HTML',
        SelectAlign: 'is-col-7', //CONST_BTNCOUNT_GROUP의 숫자를 is-col- 다음에 표기
        CONST_BTNCOUNT_GROUP: 7,
        CONST_BTNCOUNT  : {"FROM": 2, "TO": 12},  
        historyList: [
            { BRD_TIT: "white1.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white1.gif'},
            { BRD_TIT: "white2.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white2.gif'},
            { BRD_TIT: "white3.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white3.gif'},
            { BRD_TIT: "white4.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white4.gif'},
            { BRD_TIT: "white5.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white5.gif'},
            { BRD_TIT: "white6.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white6.gif'},
            { BRD_TIT: "white7.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white7.gif'},
            { BRD_TIT: "white8.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white8.gif'},
            { BRD_TIT: "white9.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white9.gif'},
            { BRD_TIT: "white10.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white10.gif'},
            { BRD_TIT: "trans1.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans1.gif'},
            { BRD_TIT: "trans2.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans2.gif'},
            { BRD_TIT: "trans3.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans3.gif'},
            { BRD_TIT: "trans4.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans4.gif'},
            { BRD_TIT: "trans5.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans5.gif'},
            { BRD_TIT: "trans6.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans6.gif'},
            { BRD_TIT: "trans7.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans7.gif'},
            { BRD_TIT: "trans8.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans8.gif'},
            { BRD_TIT: "trans9.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans9.gif'},
            { BRD_TIT: "trans10.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans10.gif'},
        ],
        source01: 'HTML',
        content:{

source01_tab01: `<div class="jh-form-wrap">
    <div class="jh-ui-header">
        <h2>상담이미지목록</h2>
        <div class="is-right">
        <label class="jh-label">보기정렬</label>
        <v-radio-group class="jh-radio-group" v-model="CONST_BTNCOUNT_GROUP">
            <v-radio v-for="i in CONST_BTNCOUNT.TO" :key="i"  v-if="i >= CONST_BTNCOUNT.FROM" :label="\`\${i}열\`\" :value="i" @click="changeRow(i)"></v-radio>
        </v-radio-group>
        <div class="jh-split"></div>
        <v-btn class="jh-btn is-round is-main">이미지 등록</v-btn>
        </div>
    </div>    
    <div class="jh-list-image" style="height: 602px;">
        <p class="jh-no-data" :class="historyList.length > 0 ? 'is-hidden': ''"><span>현재 데이터가 없습니다.</span></p>
        <ul :class="SelectAlign">
            <li v-for="i in historyList" :key="i" :title="i.BRD_TIT">
                <div class="is-image-title" v-html="i.BRD_TIT"></div>
                <div class="is-image-date" v-html="i.FST_BRD_DT_D"></div>
                <img :src="i.src">
            </li>
        </ul>
    </div>
</div>`
,source01_tab02: `export default {
  data () {
    return {  
        SelectAlign: 'is-col-7', //CONST_BTNCOUNT_GROUP의 숫자를 is-col- 다음에 표기
        CONST_BTNCOUNT_GROUP: 7,
        CONST_BTNCOUNT  : {"FROM": 2, "TO": 12},  
        historyList: [
            { BRD_TIT: "white1.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white1.gif'},
            { BRD_TIT: "white2.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white2.gif'},
            { BRD_TIT: "white3.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white3.gif'},
            { BRD_TIT: "white4.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white4.gif'},
            { BRD_TIT: "white5.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white5.gif'},
            { BRD_TIT: "white6.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white6.gif'},
            { BRD_TIT: "white7.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white7.gif'},
            { BRD_TIT: "white8.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white8.gif'},
            { BRD_TIT: "white9.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white9.gif'},
            { BRD_TIT: "white10.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/white10.gif'},
            { BRD_TIT: "trans1.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans1.gif'},
            { BRD_TIT: "trans2.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans2.gif'},
            { BRD_TIT: "trans3.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans3.gif'},
            { BRD_TIT: "trans4.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans4.gif'},
            { BRD_TIT: "trans5.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans5.gif'},
            { BRD_TIT: "trans6.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans6.gif'},
            { BRD_TIT: "trans7.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans7.gif'},
            { BRD_TIT: "trans8.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans8.gif'},
            { BRD_TIT: "trans9.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans9.gif'},
            { BRD_TIT: "trans10.gif", FST_BRD_DT_D: "2022-10-10", src: '/emoticon/trans10.gif'},
        ],
    }
  },
  methods: {
    changeRow(cols) {
      //보기정렬 세팅
      this.SelectAlign = 'is-col-'+cols;
    },
  },
}`
      }
    }
  },
  methods: {
    changeRow(cols) {
      //보기정렬 세팅
      this.SelectAlign = 'is-col-'+cols;
    },
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>