<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Text Fields</span></div>

      <h2>Input Text</h2>
      <div class="guide-card jh-cols">
        <input type="text" class="jh-form" placeholder="Default">
        <input type="text" class="jh-form" value="Readonly" readonly="readonly">
        <input type="text" class="jh-form" value="Disabled" disabled="disabled">
        <input type="text" class="jh-form is-trns" value="Disabled" disabled="disabled">
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Input Text Size</h2>
      <div class="guide-card jh-cols">
        <input type="text" class="jh-form is-sm" placeholder="Small">
        <input type="text" class="jh-form" placeholder="Default">
        <input type="text" class="jh-form is-md" placeholder="Medium">
        <input type="text" class="jh-form is-lg" placeholder="Large">
        <input type="text" class="jh-form is-xl" placeholder="X-Large">
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Round Input Text</h2>
      <div class="guide-card jh-cols">
        <input type="text" class="jh-form is-round is-sm" placeholder="Small">
        <input type="text" class="jh-form is-round" placeholder="Default">
        <input type="text" class="jh-form is-round is-md" placeholder="Medium">
        <input type="text" class="jh-form is-round is-lg" placeholder="Large">
        <input type="text" class="jh-form is-round is-xl" placeholder="X-Large">
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source06_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Textarea</h2>
      <div class="guide-card jh-cols">
        <textarea class="jh-form">Default</textarea>
        <textarea class="jh-form" readonly="readonly">Readonly</textarea>
        <textarea class="jh-form" disabled="disabled">Disabled</textarea>
        <textarea class="jh-form is-trns" disabled="disabled">Disabled</textarea>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Textarea Count</h2>
      <div class="guide-card jh-cols">
        <div>
            <textarea class="jh-form" style="height:80px;">Default</textarea>
            <span class="jh-txt-counter"><em>0</em> / 1000 Byte</span>
        </div>
        <div>
            <textarea class="jh-form" style="height:80px;">Default</textarea>
            <span class="jh-txt-counter is-right"><em>0</em> / 1000 Byte</span>
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>File Input</h2>
      <div class="guide-card">
        <input type="file">
      </div>
      <div class="guide-source">
        <v-tabs v-model="source06">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source06">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source06_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Textfields",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {        
          source01: 'HTML',     
          source06: 'HTML',
          content:{

source01_tab01: `<input type="text" class="jh-form" placeholder="Default">
<input type="text" class="jh-form" value="Readonly" readonly="readonly">
<input type="text" class="jh-form" value="Disabled" disabled="disabled">
<input type="text" class="jh-form is-trns" value="Disabled" disabled="disabled">`
,source02_tab01: `<input type="text" class="jh-form is-sm" placeholder="Small">
<input type="text" class="jh-form" placeholder="Default">
<input type="text" class="jh-form is-md" placeholder="Medium">
<input type="text" class="jh-form is-lg" placeholder="Large">
<input type="text" class="jh-form is-xl" placeholder="X-Large">`
,source04_tab01: `<textarea class="jh-form">Default</textarea>
<textarea class="jh-form" readonly="readonly">Readonly</textarea>
<textarea class="jh-form" disabled="disabled">Disabled</textarea>
<textarea class="jh-form is-trns" disabled="disabled">Disabled</textarea>`
,source05_tab01: `<textarea class="jh-form" style="height:80px;">Default</textarea>
<span class="jh-txt-counter"><em>0</em> / 1000 Byte</span>

<textarea class="jh-form" style="height:80px;">Default</textarea>
<span class="jh-txt-counter is-right"><em>0</em> / 1000 Byte</span>`
,source06_tab01: `<input type="file">`
          }
      }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>