<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Dialog</span></div>

      <h2>내부 소스</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-md is-main" @click="dialog=true">Open Dialog</v-btn>
        <v-dialog
            v-model="dialog"
            content-class="jh-dialog-wrap"
            max-width="500">
            <div class="jh-dialog-header">
                <h1>전달</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="dialog=false"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </div>
            <div class="jh-dialog-search jh-cols">
                <label class="is-required">상담사 선택</label>
                <v-autocomplete
                    class="jh-form is-md is-round"
                    v-model="select_member"
                    :items="member_items"
                    no-data-text="일치하는 데이터가 없습니다."
                    item-text="name"
                    item-value="name"
                    label=""
                   >
                    <template v-slot:selection="data">
                        <span class="auto-value">{{ data.item.id }}</span>
                        <span class="auto-value">{{ data.item.name }}</span>
                        <span class="auto-group">{{ data.item.group }}</span>
                    </template>
                    <template v-slot:item="data">
                        <template>
                            <span class="auto-value" v-html="data.item.id"></span>
                            <span class="auto-value" v-html="data.item.name"></span>
                            <span class="auto-group" v-html="data.item.group"></span>
                        </template>
                    </template>
                </v-autocomplete>
            </div>
            <div class="jh-dialog-body">
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>상담유형</label></th>
                            <td>   
                                <div class="jh-cols">                           
                                    <v-combobox
                                        class="jh-form"
                                        v-model="select_cnsl_type1"
                                        :items="cnsl_type_items1"
                                        label="상담유형1"
                                       >
                                    </v-combobox>
                                    <v-combobox
                                        class="jh-form"
                                        v-model="select_cnsl_type1"
                                        :items="cnsl_type_items1"
                                        label="상담유형2"
                                       >
                                    </v-combobox>
                                </div>  
                                <div class="jh-cols">                           
                                    <v-combobox
                                        class="jh-form"
                                        v-model="select_cnsl_type3"
                                        :items="cnsl_type_items3"
                                        label="상담유형3"
                                       >
                                    </v-combobox>                          
                                    <v-combobox
                                        class="jh-form"
                                        v-model="select_cnsl_type4"
                                        :items="cnsl_type_items4"
                                        label="상담유형4"
                                       >
                                    </v-combobox>
                                </div>  
                            </td>
                        </tr>
                        <tr>
                            <th><label>상담내용</label></th>
                            <td>                                
                                <v-textarea
                                    class="jh-form"
                                   
                                    noResize
                                    placeholder="상담내용을 입력하세요."
                                    style="height:120px">
                                </v-textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="jh-dialog-footer">
                <v-btn class="jh-btn is-md" @click="dialog=false">취소</v-btn>
                <v-btn class="jh-btn is-md is-main" @click="dialog=false">전달</v-btn>
            </div>
        </v-dialog>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>외부 소스</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-md is-main" @click="showDialog('Sample')">Open Dialog</v-btn>
        <v-dialog max-width="655" v-model="dialogSample">
            <dialog-default
              header-title="타이틀"
              @hide="hideDialog('Sample')"
              @submit="submitDialog('Sample')"
            >
              <template v-slot:body> </template>
            </dialog-default>
        </v-dialog>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source02">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source02">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source02_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
import DialogDefault from "@/components/dialogSample.vue";
export default {
  name: "VueDialog",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
    DialogDefault,
  },
  data() {
      return {    
        dialog: false,
        select_cnsl_type1: '',
        cnsl_type_items1: ['상담유형1-1','상담유형1-2','상담유형1-3','상담유형1-4','상담유형1-5'],
        
        select_cnsl_type2: '상담유형2',
        cnsl_type_items2: ['상담유형2'],
        select_cnsl_type3: '상담유형3',
        cnsl_type_items3: ['상담유형3'],
        select_cnsl_type4: '상담유형4',
        cnsl_type_items4: ['상담유형4'],
        select_member: '',
        member_items: [
            {id: 'teletalk01', name: '홍길동', group: '상담1팀'},
            {id: 'teletalk02', name: '유재순', group: '상담2팀'},
            {id: 'teletalk03', name: '김상담', group: '상담3팀'},
            {id: 'teletalk04', name: '유재석', group: '상담4팀'},
            {id: 'teletalk05', name: '강호동', group: '상담5팀'},
        ], 
        source01: 'HTML',
        source02: 'HTML',
        dialogSample: false,
        content:{

source01_tab01: `<v-btn class="jh-btn is-md is-main" @click="dialog=true">Open Dialog</v-btn>
<v-dialog
    v-model="dialog"
    content-class="jh-dialog-wrap"
    max-width="500">
    <div class="jh-dialog-header">
        <h1>전달</h1>
        <div class="is-right">
            <v-btn class="jh-btn is-icon" title="닫기" @click="dialog=false"><i class="jh-icon-close-lg is-white"></i></v-btn>
        </div>
    </div>
    <div class="jh-dialog-search">
        ...
    </div>
    <div class="jh-dialog-body">
        ...
    </div>
    <div class="jh-dialog-footer">
        <v-btn class="jh-btn is-md" @click="dialog=false">취소</v-btn>
        <v-btn class="jh-btn is-md is-main" @click="dialog=false">전달</v-btn>
    </div>
</v-dialog>`
,source01_tab02: `export default {
  data () {
    return {
        dialog: false,
    }
  },
}`
,source02_tab01: `<v-btn class="jh-btn is-md is-main" @click="showDialog('Sample')">Open Dialog</v-btn>

<v-dialog max-width="655" v-model="dialogSample">
    <dialog-default
        header-title="타이틀"
        @hide="hideDialog('Sample')"
        @submit="submitDialog('Sample')"
    >
        <template v-slot:body> </template>
    </dialog-default>
</v-dialog>`
,source02_tab02: `import DialogDefault from "@/components/dialogSample.vue";
export default {
  data () {
    return {
        dialogSample: false, //v-modle명
    }
  },
  components: { 
    DialogDefault,
  },
  methods: {
    showDialog(type){
        this[\`dialog\${type}\`] = true
    },
    hideDialog(type){
        this[\`dialog\${type}\`] = false
    },
    submitDialog(type){
        console.log('완료');
        this[\`dialog\${type}\`] = false
    },
  },
}`
      }
    }
  },
  methods: {
    showDialog(type){
        this[`dialog${type}`] = true
    },
    hideDialog(type){
        this[`dialog${type}`] = false
    },
    submitDialog(type){
        console.log('완료');
        this[`dialog${type}`] = false
    },
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>