<template>
  
</template>

<script>
// import api from "@/store/apiUtil";
import { mixin } from "@/mixin/mixin.js";
// import { mapState, mapGetters, mapActions } from "vuex";


export default {
  name: "MENU_Init",
  components: {

  },
  data: () => ({

  }),

  mixins: [mixin],

  computed: {

  },
  mounted() {
      // this.$openWindow('HOME','HOME','1000','1000');
    // window.open('HOME', 'HOME', "width="screen.width ",height="screen.height",left="(screen.width - width) / 2",top="(screen.width - width) / 4);
    this.openWindow();
      // this.$router.push("/Login");

  },
  watch: {
  },
  methods: {
    openWindow(){

      let width = screen.width,
         height = screen.height;
      
      let left = (screen.width - width) / 2,
          top = (screen.height - height) / 4
          
      // window.open('Login', 'Login', "width=" + width + ",height=" +height +",left=" +left +",top=" + top );
      window.open('Login', 'Login', "width=" + width + ",height=1005,left=" +left +",top=" + top );

      window.open('','_parent').parent.close();
      //window.open('about:black','_parent').parent.close();
    
    }
  },
};

</script>

<style scoped></style>