<template>
  <div class="jh-layout-tabs-area">
    <v-tabs background-color="transparent" slider-size="0" class="jh-layout-tabs" v-model="active_tab_num" height="29px">
      <v-tab v-for="(tab, index) in tabs" :key="tab.id" @click="moveTab(tab)">
        {{ tab.title }}
        <v-btn @click.prevent.stop="removeTab(tab, index)" v-show="tab.default !== 'Y'" class="jh-btn is-icon">
          <i class="jh-icon-close-xs"></i>
        </v-btn>
        <!-- <v-btn  v-show="tab.default == 'Y' && tab.id == 'E010101'" class="jh-btn is-icon" style="margin-left: -10px;">
        </v-btn> -->
      </v-tab>
    </v-tabs>
    <div class="is-right">
      <button class="jh-btn is-sm is-trns" @click="allRemoveTab" v-if="this.tabs.length > 3">
        <i class="jh-icon-close-xs is-red"></i>
        <span>전체</span>
      </button>

      <!-- <v-btn class="jh-btn" :disabled="disbtnClear" @click="mixin_sintongReset()">新통 초기화</v-btn>
      <v-btn class="jh-btn" :disabled="disbtnSearch" :loading="isLoading" @click="mixin_clickSintong()">新통 조회</v-btn>

      <v-btn class="jh-btn" :class="[btnActive ? 'is-del' : '']" :disabled="disbtnActive" @click="sintongConnect1">
        <i class="jh-icon-check-sm" :class="[btnActive ? 'is-red' : '']"></i>
        <span>新통 8 0400</span>
      </v-btn>

      <v-btn class="jh-btn" :class="[btnActive2 ? 'is-del' : '']" @click="sintongConnect2">
        <i class="jh-icon-check-sm" :class="[btnActive2 ? 'is-red' : '']"></i>
        <span>新통 연동안함</span>
      </v-btn> -->
    </div>  
  </div>
</template>
  
<script>
import { mixin } from "@/mixin/mixin.js";
import { mapState, mapGetters, mapActions } from "vuex";
import { eventBus } from "@/store/eventBus.js";           //Event Bus 

export default {
  mixins: [mixin],

  data() {
    return {
      isLoading      : false,

      allCloseTog    : false,
      btnActive      : false,
      btnActive2     : false,

      disbtnClear    : false,
      disbtnSearch   : false,
      disbtnActive   : false,

      dissangdamClear: false,
    };
  },

  mounted() {
    // mixin에 있는 default_tab 기준으로 탭을 초기화 한다
    this.$store.dispatch("commonStore/AC_COMMON_INIT_TAB", this.default_tab);

    this.$store.dispatch("callStore/AC_SINTONG_80400", this.btnActive);

    // 컴포넌트 활성화시 home 메뉴로 이동한다
    // 새로고침시에도 home으로 이동한다
    if (this.$route.fullPath !== "/E999999") {
      this.$router.push("/E999999");
    }
  },

  computed: {
    ...mapGetters({
      tabs: "commonStore/GE_COMMON_TAB",
      active_tab_title: "commonStore/GE_COMMON_ACTIVE_TAB_TITLE",
      geCustInfo: 'callStore/GE_CUST_INFO', // 상담메인 고객정보
      user_id   : "userStore/GE_USER_ID",
    }),

    active_tab_num: {
      get() {
        return this.$store.getters["commonStore/GE_COMMON_ACTIVE_TAB_NUM"];
      },
      set() {},
    },

    active_tab_id: {
      get() {
        return this.$store.getters["commonStore/GE_COMMON_ACTIVE_TAB_ID"];
      },
      set() {},
    },
  },

  watch: {
    // 활성화탭 이름이 변경되면 router를 변경한다
    active_tab_title() {

      // 변경된 탭이 활성화되어 있는 탭과 다르다면 router를 변경한다
      if (this.tabs[this.active_tab_num].link !== this.$route.path) {
        var linkto = this.tabs[this.active_tab_num].link.replace("/", "");
        this.$router.push(linkto);
      }
    },
  },

  methods: {
    // 신통 80400 연결
    sintongConnect1() {
      console.log("sintongConnect1 TEST #####");
      this.btnActive = !this.btnActive;

      if(this.btnActive){
        // header 세팅
        let requestData = {
          headers: {
            ASYNC  : false,
            METHOD : "sintongConnect",
            SERVICE: "api/sintong",
            TYPE   : "BIZ_SERVICE",
            URL    : "/api/sintong/sintongConnect"
          },
          sendData: {},
        };

        // 테스트 데이터
        // sendData 세팅
        // requestData.sendData.USER_ID = "31285";          // 사번
        // requestData.sendData.MdaTp   = "0";              // 매체구분(0:CTI(상담앱) 1:폰패드)
        // requestData.sendData.TrxTp   = "3";              // 처리구분(CTI : 0 데이터 삭제 및 화면닫기, 1 계좌연동, 3 화면오픈 / 폰패드 : 1 ARS폰패드 시작, 2 비밀번호 연동)
        // requestData.sendData.ScrNo   = "80400";          // 화면번호(CTI연동 화면오픈시 입력)
        // requestData.sendData.AcntNo  = "";               // 계좌번호(CTI 계좌연동시 사용)
        // requestData.sendData.Pwd     = "";               // 비밀번호(폰패드, CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
        // requestData.sendData.BnkPwd  = "";               // 은행비밀번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
        // requestData.sendData.ClntId  = "";               // 고객ID(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
        // requestData.sendData.Rmnno   = "";               // 실명확인번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6) 

        // 상담메인에서 고객정보 받아온 데이터
        // sendData 세팅
        requestData.sendData.USER_ID = this.user_id;                 // 사번
        requestData.sendData.MdaTp   = "0";                          // 매체구분(0:CTI(상담앱) 1:폰패드)
        requestData.sendData.TrxTp   = "3";                          // 처리구분(CTI : 0 데이터 삭제 및 화면닫기, 1 계좌연동, 3 화면오픈 / 폰패드 : 1 ARS폰패드 시작, 2 비밀번호 연동)
        requestData.sendData.ScrNo   = "80400";                      // 화면번호(CTI연동 화면오픈시 입력)
        requestData.sendData.AcntNo  = "";                           // 계좌번호(CTI 계좌연동시 사용)
        requestData.sendData.Pwd     = "";                           // 비밀번호(폰패드, CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
        requestData.sendData.BnkPwd  = "";                           // 은행비밀번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
        requestData.sendData.ClntId  = "";                           // 고객ID(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
        requestData.sendData.Rmnno   = "";                           // 실명확인번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6) 
        
        this.common_postCall(requestData).then(response => {
          if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG || (response.DATA && response.DATA.length > 0 && response.DATA[0].TrxReslt != "0000")) {
            let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\n") : null;
            let errorMsg = _.find(errorMessages, msg => {
            return _.includes(msg, "에러 메시지: ");
            });
            let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
            this.common_alert(msg, "error");
            return false;
          }
        })
      }

      this.$store.dispatch("callStore/AC_SINTONG_80400", this.btnActive);
    },

    // 신통연동안함
    sintongConnect2() {
      this.btnActive2   = !this.btnActive2;
      this.disbtnActive = !this.disbtnActive;
      this.disbtnClear  = !this.disbtnClear;
      this.disbtnSearch = !this.disbtnSearch;

      if(this.btnActive2) this.btnActive = false;

      // 상담메인 Clear 버튼 disabled
      this.dissangdamClear = !this.dissangdamClear;

      this.$store.dispatch("callStore/AC_SINTONG_CLEAR", this.dissangdamClear);
      this.$store.dispatch("callStore/AC_SINTONG_80400", this.btnActive);
    },

    // 탭 변경시 이벤트
    moveTab(tab) {
      this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
        id: tab.id,
        title: tab.title,
      });
    },

    changeState(type) {
      this.$store.commit("userStore/MU_USER_STATE", { ustate: type });
    },

    // 탭 삭제시 이벤트
    removeTab(tab, index) {
      this.tabs.splice(index, 1);
      this.$store.dispatch("commonStore/AC_COMMON_DEACTIVE_TAB", {
        id: tab.id,
        title: tab.title,
      });

      if(tab.id == 'E010102'){
        this.$store.commit("authStore/MU_CHAT_MODE", false);
        eventBus.chatMainClose();
      }      

    },

    // 전체 탭 삭제시 이벤트
    allRemoveTab() {
      // this.allCloseTog = !this.allCloseTog;
      // mixin에 있는 default_tab 기준으로 탭을 초기화 한다
      this.$store.dispatch("commonStore/AC_COMMON_DELETE_ALL_TAB", "");
    },
  },
};
</script>

<style>
</style>
  