<template>
  <!-- <transition name="slide">
    <div class="jh-quick-layer slide-right">
      <div class="jh-quick-layer-header" @click="closeModal()">
        <h1>2차상담</h1>
        <div class="is-right">
          <v-btn class="jh-btn is-icon">
          <i class="jh-icon-close-md"></i></v-btn>
        </div>
      </div> -->
      <!-- <div class="jh-quick-layer-body"> -->
      <div>
        <!--조회-->
        <div class="jh-search-form is-mb-10">
          <table>
            <colgroup>
              <col width="55px">
              <col width="110px">
              <col width="70px">
              <col width="110px">
              <col width="80px">
              <col width="120px">
              <col width="80px">
              <col width="120px">
              <col width="80px">
              <col width="100px">
              <col>
            </colgroup>
            <tr>
              <th><label>접수일</label></th>
              <td colspan="3">
                <div class="jh-cols">                        
                  <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                      maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                    </template>
                    <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                  </v-menu>
                  <span class="jh-unit">~</span>                       
                  <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                      maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                    </template>
                    <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                  </v-menu>
                </div>
              </td>
              <th><label>배당직원</label></th>
              <td colspan="5">
                <!-- <input type="text" class="jh-form" v-model="CSLT_MAN"> -->
                <div class="jh-cols">
                  <v-select class="jh-form" :items="ATTR_A_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="ATTR_A"></v-select>
                  <v-select class="jh-form" :items="ATTR_B_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="ATTR_B"></v-select>
                  <v-select class="jh-form" :items="USER_COMBO" item-text="USER_NM" item-value="USER_ID" placeholder="선택" v-model="CSLT_MAN"></v-select>
                </div>
              </td>
              <td class="is-jh-20">
                <v-checkbox class="jh-check" v-model="checkbox1" label="미인증"></v-checkbox>
              </td>
            </tr>
            <tr>
              <th><label>접수직원</label></th>
              <td><input type="text" class="jh-form" v-model="RCPT_MAN" @keydown.enter="selectBtn"></td>
              <th><label>업무유형</label></th>
              <td><v-select class="jh-form" :items="SND01_COMBO" item-text="CD_NM" item-value="CD" v-model="CSLT_TY_MDCLS"></v-select></td>
              <th><label>고객번호</label></th>
              <td><input type="text" class="jh-form" v-model="CUST_NO" :disabled="checkbox1" @keydown.enter="selectBtn"></td>
              <!-- <th><label>고객명</label></th>
              <td><input type="text" class="jh-form" v-model="CUST_NM"></td> -->
              <th><label>계좌번호</label></th>
              <td><input type="text" class="jh-form" v-model="ACCOUNT" @keydown.enter="selectBtn" oninput="this.value = this.value.replace(/[^0-9]/g, '');"></td>
              <th><label>처리상태</label></th>
              <td><v-select class="jh-form" :items="SCDSTS_COMBO" item-text="CD_NM" item-value="CD" v-model="PROC_STAT_CD"></v-select></td>
              <td class="has-search"><v-btn class="jh-btn is-search" @click="selectBtn">조회</v-btn></td>
            </tr>
          </table>
        </div>
        <!--//조회-->

        <div class="jh-form-wrap">
          <div class="jh-ui-header">
            <div class="is-left">
              <h2>2차상담 리스트</h2>
            </div>
            <div class="is-right">              
              <p class="jh-info-txt is-red is-mr-10">
                <strong class="is-txt-red">[주의]</strong> 2차 접수고객 선택 후 콜메인에서 다른 고객 조회 시 ▶ <strong class="is-txt-red">먼저 CLEAR 후 진행</strong>
              </p>
              <v-btn class="jh-btn is-fill" @click="saveBtn">저장</v-btn>
            </div>
          </div>
          <data-tables
            ref="dataTable"
            dataTableClass="has-control"
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            @dblclick:row="onClickRow2"
            :paginationOptions="paginationOptions"
          >
            <template v-slot:item.CSLT_MAN_ID="{ item }">
              <v-select v-if="item.CSLT_MAN_ID == '' || item.CSLT_MAN_ID == USER_ID" class="jh-form" 
                :items="CSLT_MAN_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="item.CSLT_MAN_ID"></v-select>
              <span v-else>{{item.CSLT_MAN}}</span>
            </template>
          </data-tables>
        </div>

        <!--상세-->
        <div class="jh-form-wrap jh-cols is-mt-10">
          <!--접수정보-->
          <div>
            <div class="jh-ui-header">
              <h3>접수정보</h3>
              <div class="is-right">
              </div>
            </div>
            <table class="jh-tbl-detail">
              <colgroup>
                  <col width="100px">
                  <col>
              </colgroup>
              <tbody>
                  <tr>
                      <th><label>접수유형</label></th>
                      <td> 
                        <span class="is-value">
                          {{detailObj.CSLT_TY_LCLS}} {{detailObj.CSLT_TY_MDCLS ? '> ' + detailObj.CSLT_TY_MDCLS : ''}} {{detailObj.CSLT_TY_SCLS ? '> ' + detailObj.CSLT_TY_SCLS : ''}}
                        </span>
                      </td>
                  </tr>
                  <tr>
                    <th><label>예약일시</label></th>
                    <td> 
                      <span class="is-value">{{detailObj.RESV_DT_TIME}}</span>
                    </td>
                  </tr>
                  <tr>
                    <th><label>예약수신번호</label></th>
                    <td> 
                      <span class="is-value">{{detailObj.RESV_TEL_NO}}</span>
                    </td>
                  </tr>
                  <tr>
                    <th><label>예약메모</label></th>
                    <td class="is-p-0"> 
                      <textarea class="jh-form is-trns" style="height: 140px;" readonly v-model="detailObj.RESV_MEMO"></textarea>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <!--//접수정보-->

          <!--처리정보-->
          <div>
            <div class="jh-ui-header">
              <h3>처리정보</h3>
              <div class="is-right">
              </div>
            </div>
            <table class="jh-tbl-detail">
              <colgroup>
                  <col width="100px">
                  <col>
                  <col width="100px">
                  <col>
              </colgroup>
              <tbody>
                  <tr>
                      <th><label>처리일시</label></th>
                      <td> 
                        <span class="is-value">{{detailObj.PROC_DT_TIME}}</span>
                      </td>
                      <th><label>처리직원</label></th>
                      <td> 
                        <span class="is-value">{{detailObj.PROC_MAN}}</span>
                      </td>
                  </tr>
                  <tr>
                    <th><label>처리내용</label></th>
                    <td colspan="3" class="is-p-0"> 
                      <textarea class="jh-form is-trns" style="height: 85px;" readonly v-model="detailObj.PROC_CNTN"></textarea>
                    </td>
                  </tr>
                  <tr>
                      <th><label>수정일시</label></th>
                      <td> 
                        <span class="is-value">{{detailObj.MOD_DTTM}}</span>
                      </td>
                      <th><label>수정직원</label></th>
                      <td> 
                        <span class="is-value">{{detailObj.MOD_MAN}}</span>
                      </td>
                  </tr>
                  <tr>
                    <th><label>수정내용</label></th>
                    <td colspan="3" class="is-p-0"> 
                      <textarea class="jh-form is-trns" style="height: 84px;" readonly v-model="detailObj.MOD_MSG"></textarea>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <!--//처리정보-->

        </div>
        <!--상세-->
      </div>
    <!-- </div>
  </transition> -->
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import { mapGetters } from "vuex";

export default {
  name: "MENU_T100601", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],

  components: {
    dataTables
  },

  props: [ "evt" ],

  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      dates: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],
      
      headers: [
        { text: '접수일시', value: 'RECP_DT_TIME', align: 'center', width: '130px',},
        { text: '계좌번호', value: 'ACCOUNT', align: 'center', width: '100px', },
        { text: '고객명', value: 'CUST_NM', align: 'center', width: '90px', },
        { text: '고객번호', value: 'CUST_NO', align: 'center', width: '90px', },
        { text: '업무유형', value: 'CSLT_TY_MDCLS', align: 'center', },
        { text: '접수직원', value: 'RCPT_MAN', align: 'center', width: '120px', },
        { text: '배당직원', value: 'CSLT_MAN_ID', align: 'center', width: '120px', },
        { text: '배분직원', value: 'DSTR_MAN', align: 'center', width: '120px', },
        { text: '처리상태', value: 'PROC_STAT_CD_NM', align: 'center', width: '80px', },
        { text: '처리직원', value: 'PROC_MAN', align: 'center', width: '120px', },
        { text: '처리일시', value: 'PROC_DT_TIME', align: 'center', width: '130px', },
      ],
      items: [],
      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        // height: '358px',
        height: '325px',
        itemKey: 'ROWNUM',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        itemClass: "[item.selected && 'selected']",
        load: undefined,
      },
      paginationOptions: {
        totalVisible: 10
      },

      histNos: '',

      // 조회조건
      ACCOUNT: '',        // 계좌번호
      RCPT_MAN: '',       // 접수직원
      CUST_NM: '',        // 고객명
      CSLT_TY_MDCLS: '',  // 업무유형
      CUST_NO: '',        // 고객번호
      ATTR_A: '',         // 부서
      ATTR_B: '',         // 파트
      CSLT_MAN: '',       // 배당직원
      PROC_MAN: '',       // 처리직원
      PROC_STAT_CD: '',   // 처리상태

      // 콤보박스
      SCDSTS_COMBO: [{ CD: '', CD_NM: '전체' }],
      SND01_COMBO: [{ CD: '', CD_NM: '전체' }],
      CSLT_MAN_COMBO: [],
      ATTR_A_COMBO: [],
      ATTR_B_COMBO: [],
      USER_COMBO: [],

      // 접수정보, 처리정보
      detailObj: {
        CSLT_TY_LCLS: '',         // 접수유형(대)
        CSLT_TY_MDCLS: '',        // 접수유형(중)
        CSLT_TY_SCLS: '',         // 접수유형(소)
        RESV_DT_TIME: '',         // 예약일시
        RESV_TEL_NO: '',          // 예약수신번호
        RESV_MEMO: '',            // 예약메모
        PROC_DT_TIME: '',         // 처리일시
        PROC_MAN: '',             // 처리직원
        CNSL_CNTN: '',            // 처리내용
        MOD_MAN: '',              // 수정직원
        MOD_MSG: '',              // 수정내용
      },

      selectedRow: [],

      USER_ID : this.$store.getters['userStore/GE_USER_ROLE'].userId,

      checkbox1: false,

    }
  },
  created() { 
    this.dataTableOptions.headers = this.headers;
    this.dataTableOptions.items = this.items;
  },
  mounted() {
    this.init();
    this.setUserCombo();
    this.getSelBoxList();
    this.getAttrList();
  },
  computed: {
    ...mapGetters({
      geCallStt: 'callStore/GE_CALL_STATE',
      geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
    }),
    rowNum() {
      return this.items?.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
    dataTableInstance() {
      return this.$refs.dataTable;
    },
    initHeaders(){
      return {
        SERVICE: "phone.secondary",
        METHOD: "",
        TYPE: 'BIZ_SERVICE',
      };
    },
  },
  watch: {
    "ATTR_A": async function (val) {
      this.CSLT_MAN = '';
      this.ATTR_B = '';

      if (val == '') {
        this.ATTR_B_COMBO = [];
        this.USER_COMBO = [];
      } else {
        this.ATTR_B_COMBO = await this.mixin_get_attr_by_attr('', val);
      }
    },
    "ATTR_B": async function (val) {
      this.CSLT_MAN = '';

      if (val == '') {
        this.USER_COMBO = [];
      } else {
        this.USER_COMBO = await this.mixin_get_user_by_attr(this.ATTR_A, val);
      }
    },
    "checkbox1": async function (val) {
      val ? this.CUST_NO = '00000000' : this.CUST_NO = '';
    }
  },
  methods: {
    init() {
    },
    
    closeModal() {
        this.$parent.closeModal('slide1');
    },

    fromOnInput() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    changeDate(type) {
      if(type == 'start') {
        // if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(90, 'days').format('YYYY-MM-DD'))) {
        //     this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
        //     const date = moment().format("YYYY-MM-DD");
        //     this.dates[0] = date;
        //     this.from = date;
        //     return;
        // } else {
            this.from = this.dates[0];
        // }
      } else if (type == 'end') {
        // if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(90, 'days').format('YYYY-MM-DD'))) {
        //     this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
        //     const date = moment().format("YYYY-MM-DD");
        //     this.dates[1] = date;
        //     this.to = date;
        //     return;
        // } else {
            this.to = this.dates[1];
        // }
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }
        
        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
    onClickRow(item, row) {
      if (item != null) {
        // row.select(true);
        // row.isSelected = true;
        this.detailObj = item;
        this.selectedRow.push(row);
      }
    },
    onClickRow2(e, { item }) {
      if (item.PROC_STAT_CD == '03') {
        this.common_alert('통화성공건은 진행할 수 없습니다.', 'noti');
        return;
      } else if (item.CSLT_MAN == '') {
        this.common_alert('배당직원을 선택해주세요.', 'noti');
        return;
      } else {
        let custInfo = {};
        custInfo.SRCH_ACCOUNT = item.ACCOUNT.replace(/\-/g, '');
        custInfo.SRCH_CL_CID = item.CUST_NO;
        custInfo.SCDL_ID = ''; // 스케쥴ID
        custInfo.ID = '';
        custInfo.IVR_IN_NO = '';
        custInfo.IVR_IN_PATH = '';
        custInfo.IVR_MENT = '';
        custInfo.IVR_STD_TIME = '';
        custInfo.RSV_CALL_ID = item.RSV_CALL_ID;
        custInfo.MY_CALL_YN = 'N';
        custInfo.POP_YN = 'Y';
        custInfo.message = 'T100601';
        
        if(custInfo.SRCH_CL_CID == '00000000') custInfo.SRCH_CL_CID = '9999999999999';
        window.opener.postMessage(custInfo, '*');
      }
    },
    saveBtn() {
      const obj = JSON.parse(JSON.stringify(this.dataTableInstance.getSelectedData()));

      if (Object.keys(obj).length == 0) {
        this.common_alert("선택된 항목이 없습니다.", "noti");
      } else {
        if(this.validDist()) {
          this.updateSecondaryWork();
        } else {
          this.common_alert('처리상태가 통화 또는 완료인 상담은 배당직원을 변경 할 수 없습니다.');
        }
      }
    },
    selectBtn() {
      this.detailObj = {};
      this.dataTableOptions.load = this.selectSecondaryWork;

      if (this.dataTableOptions.page > 1) {
        this.dataTableOptions.page = 1;
      } else {
        this.dataTableInstance.loadData();
      }
    },
    async selectSecondaryWork(loadOptions) {

      // if (this.selectedRow.length > 0) {
      //   this.selectedRow.forEach(item => {
      //     item.select(false);
      //   });
      // }

      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {}
      };

      const table = this.$el.children[1].children[1].children[0].children[0];
      table.scrollTop = 0;

      const sortBy = loadOptions?.sortBy?.[0];
      const sortDesc = loadOptions?.sortDesc?.[0] === undefined ? 'desc' : loadOptions?.sortDesc?.[0] ? 'desc' : 'asc';

      requestData.headers["URL"] = "/api/phone/secondary/list";
      requestData.headers["METHOD"] = "select";
      requestData.headers["ASYNC"] = false;
      requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
      requestData.headers["PAGES_CNT"] = loadOptions.page; 

      requestData.sendData["STR_RECP_DT"] = this.dates[0].replace(/\-/g, "");
      requestData.sendData["END_RECP_DT"] = this.dates[1].replace(/\-/g, "");
      requestData.sendData["ACCOUNT"] = this.ACCOUNT;
      requestData.sendData["RCPT_MAN"] = this.RCPT_MAN;
      requestData.sendData["CUST_NM"] = this.CUST_NM;
      requestData.sendData["CUST_NO"] = this.CUST_NO;
      requestData.sendData["ATTR_A"] = this.ATTR_A;
      requestData.sendData["ATTR_B"] = this.ATTR_B;
      requestData.sendData["CSLT_MAN"] = this.CSLT_MAN;
      requestData.sendData["CSLT_TY_MDCLS"] = this.CSLT_TY_MDCLS;
      requestData.sendData["PROC_STAT_CD"] = this.PROC_STAT_CD;
      requestData.sendData["SORT_ORDR"] = sortBy ? sortBy : 'ROWNUM';
      requestData.sendData["SORT_DRCT"] = sortDesc;

      const response = await this.common_postCall(requestData);
      this.detailObj = {};

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
        let header = response.HEADER;
        let data = response.DATA;
        this.items = data;

        data.forEach(item => {
          item.ACCOUNT = item.ACCOUNT.indexOf('-') < 0 ? 
              this.mixin_accNoFormat(item.ACCOUNT) : this.mixin_accNoFormat(item.ACCOUNT.replace(/\-/g, ''));
          item.RESV_TEL_NO = item.RESV_TEL_NO.indexOf('-') < 0 ?
              this.mixin_newPhoneFormat(item.RESV_TEL_NO) : this.mixin_newPhoneFormat(item.RESV_TEL_NO.replace(/\-/g, ''));
        })

        return {
          data: data,
          totalCount: header.TOT_COUNT
        };
      }
    },
    setUserCombo() {
      this.CSLT_MAN_COMBO.push(
        { CD: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          CD_NM: this.$store.getters['userStore/GE_USER_ROLE'].userName }
      )
    },
    async getSelBoxList() {

      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {}
      };

      requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
      requestData.headers["METHOD"] = "selectCode";
      requestData.headers["ASYNC"] = false;

      let sendList = [];
      sendList.push("SND01");     //2차상담업무유형코드
      sendList.push('2NDSTS');    //처리상태

      requestData.sendData["GROUP_CD"] = sendList

      const response = await this.common_postCall(requestData);

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

        for (var i = 0; i < response.DATA.length ; i++){
          if (response.DATA[i].GROUP_CD === "SND01"){
            if(response.DATA[i].CD.length === 4) {
              this.SND01_COMBO.push(response.DATA[i]);
            }
          } else if (response.DATA[i].GROUP_CD === '2NDSTS') {
            this.SCDSTS_COMBO.push(response.DATA[i]);
          }
        }
      }
    },
    validDist() {
      let result = true;

      let obj = JSON.stringify(this.dataTableInstance.getSelectedData());
      let obj2 = JSON.parse(obj);

      const arr = [];

      if (Object.keys(obj2).length > 0) {
        Object.keys(obj2).map(item => {
          if (obj2[item].PROC_STAT_CD === '02' || obj[item].PROC_STAT_CD === '03') {
            result = false;
            return;
          } else {
            arr.push(obj2[item].CNSL_HIST_NO);
          }
        })

        for(let i in arr) {
          this.histNos += arr[i] + "/";
        }

        return result;
      }

    },
    async updateSecondaryWork() {
      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {}
      };

      requestData.headers["URL"] = '/api/phone/secondary/up-dist';
      requestData.headers["METHOD"] = "update";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["CNSL_HIST_NO"] = this.histNos;
      requestData.sendData["CSLT_MAN"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
      requestData.sendData["DSTR_MAN"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
      requestData.sendData["MOD_MAN"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
      requestData.sendData["UPD_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;

      const response = await this.common_postCall(requestData);

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
        this.common_alert(`정상적으로 배당되었습니다.`, 'done')
        this.dataTableInstance.loadData();
      }
    },

    async getAttrList() {
        this.ATTR_A_COMBO = await this.mixin_get_attr_by_attr('****', '');
    },
  },

}
</script>

<style scoped>
</style>