const callStore = {
  namespaced: true,
  state: () => ({
    // 화면연동시 cust_info 고객정보 
    // CUST_GRD       고객 등급
    // CUST_ACTNO     고객 계좌번호
    // CUST_NO        고객 번호
    // CUST_NM        고객 명
    cust_info: {}, //전화상담메인 고객상세정보
    cti_info: {
      CTI_MODE: false,  // CTI모드(on, off)
      USER_ID:  '',
      USER_NM:  '',
      PASSWORD: '',
      CALL_ID:  '',  
      CALL_TYPE: '',
      IPCC_NO:  '',
      FROM_TNO: '',
      IVR_IN_PATH: '',
      IVR_STD_TIME: '',
      IVR_MENT:  '',
      LOGINFLAG: 0,
      REASON_CD: '',
      EXTENSION: '',
      CHANNER:   '',
      IVR_IN_NO: '',
      SKILL_NAME: '',
      SKILL_NO: '',
      END_DT: '',
      END_TM: '',
    },  //전화상담메인 CTI 정보
    rdwt_info: {}, //녹취관련정보
    established: false, // 수신여부
    custInfMode: '',  //고객정보모드
    callState: '', //콜상태값
    pinTypeNo: '0', //핀패드타입번호
    cnslSaveYn: 'Y', //상담저장여부
    cust_data: {},
    sintongClear: false, // 신통 클리어
    sintong80400: false, // 신통 80400
    playYn: 'N',  // VMS 플레이 여부
    to_tno: '',
    callBttm: '',
    lastCustId: '',
    cnslSaveCnt: '0', //상담저장건수



    cnslSaveFlag: false, 
    cnslSavePrev: "", 
    cnslInOut: "", 



  }),
  getters: {
    GE_CUST_INFO : (state) => state.cust_info,
    GE_CTI_INFO : (state)  => state.cti_info,
    GE_RDWT_INFO : (state)  => state.rdwt_info,
    GE_ESTABLISHED : (state) => state.established,
    GE_CUST_SRCH_MODE : (state) => state.custInfMode,
    GE_CALL_STATE : (state) => state.callState,
    GE_PIN_TYPE_NO : (state) => state.pinTypeNo,
    GE_CUST_DATA : (state) => state.cust_data,
    GE_SINTONG_CLEAR: (state) => state.sintongClear,
    GE_SINTONG_80400: (state) => state.sintong80400,
    GE_CNSL_SAVE_YN: (state) => state.cnslSaveYn,
    GE_PLAY_YN: (state) => state.playYn,
    GE_TO_TNO: (state) => state.to_tno,
    GE_CALL_BDTTM: (state) => state.callBttm,
    GE_LAST_CUST_ID: (state) => state.lastCustId,
    GE_CNSL_SAVE_CNT: (state) => state.cnslSaveCnt,
    GE_CNSL_SAVE_FLAG: (state) => state.cnslSaveFlag,
    GE_CNSL_CUST_PREV: (state) => state.cnslSavePrev,
    GE_CNSL_CUST_INOUT: (state) => state.cnslInOut,
  },
  mutations: {
    MU_CUST_INOUT: (state, data) =>{
      if(data){
        state.cnslInOut = data;
      }
    },    
    MU_CUST_INFO: (state, data) =>{
      if(data){
        state.cust_info = data;
      }
    },
    MU_CTI_INFO: (state, data) =>{
      if(data){
        state.cti_info = data;
      }
    },
    MU_RDWT_INFO: (state, data) =>{
      if(data){
        state.rdwt_info = data;
      }
    },
    MU_ESTABLISH: (state, data) =>{
      if(data){
        state.established = data;
      }
    },
    MU_CUST_SRCH_MODE: (state, data) =>{
      if(data){
        state.custInfMode = data;
      }
    },
    MU_CALL_STATE: (state, data) =>{
      if(data){
        state.callState = data;
      }
    },
    MU_PIN_TYPE_NO: (state, data) =>{
      if(data){
        state.pinTypeNo = data;
      }
    },
    MU_CUST_DATA: (state, data) => {
      if(data) {
        state.cust_data = data;
      }
    },
    MU_SINTONG_CLEAR: (state, data) => {
      state.sintongClear = data;
    },
    MU_SINTONG_80400: (state, data) => {
      state.sintong80400 = data;
    },
    MU_CNSL_SAVE_YN: (state, data) => {
      state.cnslSaveYn = data;
    },
    MU_PLAY_YN: (state, data) => {
      if(data){
        state.playYn = data;
      }
    },
    MU_TO_TNO: (state, data) => {
      if(data){
        state.to_tno = data;
      }
    },
    MU_CALL_BDTTM: (state, data) => {
      if(data){
        state.callBttm = data;
      }
    },
    MU_LAST_CUST_ID: (state, data) => {
      if(data){
        state.lastCustId = data;
      }
    },
    MU_CNSL_SAVE_CNT: (state, data) => {
        state.cnslSaveCnt = data;
    },
    MU_CNSL_SAVE_FLAG: (state, data) => {
      // if(data){
        console.log("MU_CNSL_SAVE_FLAG", data)
        state.cnslSaveFlag = data;
      // }
    },    
    MU_CNSL_CUST_PREV: (state, data) => {
      state.cnslSavePrev = data;
    },     
  },
  actions: {
    AC_CUST_INOUT({ commit }, data) {
      commit("MU_CUST_INOUT", data);
    },
    AC_CUST_INFO({ commit }, data) {
      commit("MU_CUST_INFO", data);
    },
    AC_CTI_INFO({ commit }, data) {
      commit("MU_CTI_INFO", data);
    },
    AC_RDWT_INFO({ commit }, data) {
      commit("MU_RDWT_INFO", data);
    },
    AC_CUST_SRCH_MODE({ commit }, data) {
      commit("MU_CUST_SRCH_MODE", data);
    },
    AC_CALL_STATE({ commit }, data) {
      commit("MU_CALL_STATE", data);
    },
    AC_PIN_TYPE_NO({ commit }, data) {
      commit("MU_PIN_TYPE_NO", data);
    },
    AC_SINTONG_CLEAR({ commit }, data) {
      commit("MU_SINTONG_CLEAR", data);
    },
    AC_SINTONG_80400({ commit }, data) {
      commit("MU_SINTONG_80400", data);
    },
    AC_CNSL_SAVE_YN({ commit }, data) {
      commit("MU_CNSL_SAVE_YN", data);
    },
    AC_PLAY_YN({ commit }, data) {
      commit("MU_PLAY_YN", data);
    },
    AC_TO_TNO({ commit }, data) {
      commit("MU_TO_TNO", data);
    },
    AC_CALL_BDTTM({ commit }, data) {
      commit("MU_CALL_BDTTM", data);
    },
    AC_LAST_CUST_ID({ commit }, data) {
      commit("MU_LAST_CUST_ID", data);
    },
    AC_CNSL_SAVE_CNT({ commit }, data) {
      commit("MU_CNSL_SAVE_CNT", data);
    },
    AC_CNSL_SAVE_FLAG({ commit }, data) {
      commit("MU_CNSL_SAVE_FLAG", data);
    },
    AC_CNSL_CUST_PREV({ commit }, data) {
      commit("MU_CNSL_CUST_PREV", data);
    }            
  },
};

export default callStore;
