<template>
    <div class="guide-content"> 
        <div class="guide-content-header">Patterns<v-icon class="mdi mdi-chevron-right"></v-icon><span>윈도우팝업</span></div>

        <!-- <h2>Default</h2> -->
        <div class="guide-card is-mt-10">
            <div class="frame-popup">
                <header>
                    <h1>주소 조회</h1>
                    <div class="is-right">
                        <v-btn class="jh-btn is-icon" title="닫기"><i class="jh-icon-close-lg is-white"></i></v-btn>
                    </div>
                </header>
                <main style="background:#f0f5f9">
                    <!--조회-->
                    <div class="jh-search-form">
                        <table>
                            <colgroup>
                                <col width="30px">
                                <col width="350px">
                                <col>
                            </colgroup>
                            <tr>
                                <th><label>주소</label></th>
                                <td><input type="text" class="jh-form"></td>
                                <td class="has-search"><v-btn class="jh-btn is-search">조회</v-btn></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td colspan="2">
                                    <p class="jh-info-txt is-fill is-blue">도로명, 지번에 대한 통합 검색이 가능합니다. ( 예: 반포대로 58, 여의도동 28, 여의도동 KT 등 )</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!--//조회-->

                    <!--리스트-->
                    <v-card class="jh-form-wrap is-p-5">
                        <p class="jh-no-data" v-if="this.items.length === 0"><span>조회된 주소가 없습니다.</span></p>
                        <!-- <v-virtual-scroll class="jh-list-address" :items="items" item-height="65" height="460" v-if="this.items.length > 0">
                            <template v-slot="{ item }">
                                <div tabindex="-1" class="jh-list-address-item" :key="item.index" :class="{'is-active' : selectedItem === item }" @click="listClick(item)">
                                    <div>
                                        <label class="is-new">도로명</label>
                                        <p>{{ item.address1 }}{{ item.index }}</p>
                                    </div>
                                    <div>
                                        <label class="is-old">지번</label>
                                        <p>{{ item.address2 }}{{ item.index }}</p>
                                    </div>
                                </div>
                            </template>
                        </v-virtual-scroll> -->
                        <v-virtual-scroll class="jh-list-address" :items="items" item-height="65" height="460" v-if="this.items.length > 0">
                            <template v-slot="{ item }">
                                <div tabindex="-1" class="jh-list-address-item" :key="item" :class="{'is-active' : selectedItem === item }" @click="listClick(item)">
                                    <div>
                                        <label class="is-new">도로명</label>
                                        <p>서울특별시 영등포구 여의서로 120 (여의도동) {{ item }}</p>
                                    </div>
                                    <div>
                                        <label class="is-old">지번</label>
                                        <p>서울특별시 영등포구 여의도동 82-1 인천해양경찰서 한강파출소 {{ item }}</p>
                                    </div>
                                </div>
                            </template>
                        </v-virtual-scroll>
                    </v-card>
                    <!--//리스트-->
                </main>
                <footer><v-btn class="jh-btn is-md is-main">선택</v-btn></footer>
            </div>
        </div>
        <div class="guide-source">
            <v-tabs v-model="source01">
                <v-tab>Html</v-tab>
                <v-tab>Script</v-tab>
            </v-tabs>
            <v-tabs-items v-model="source01">
                <v-tab-item>
                    <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
                </v-tab-item>
                <v-tab-item>
                    <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
                </v-tab-item>
            </v-tabs-items>
        </div>

    </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Table",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {  
        selectedItem: null,
        // items: [
        //     { address1: '서울특별시 영등포구 여의서로 120 (여의도동)', address2: '서울특별시 영등포구 여의도동 82-1 인천해양경찰서 한강파출소'},
        //     { address1: '서울특별시 영등포구 여의서로 160 (여의도동)', address2: '서울특별시 영등포구 여의도동 81-8'},
        //     { address1: '서울특별시 영등포구 여의서로 82 (여의도동)', address2: '서울특별시 영등포구 여의도동 83 여의도 관공선 사무실'},
        //     { address1: '서울특별시 영등포구 여의서로 120 (여의도동)', address2: '서울특별시 영등포구 여의도동 82-1 인천해양경찰서 한강파출소'},
        //     { address1: '서울특별시 영등포구 여의서로 160 (여의도동)', address2: '서울특별시 영등포구 여의도동 81-8'},
        //     { address1: '서울특별시 영등포구 여의서로 82 (여의도동)', address2: '서울특별시 영등포구 여의도동 83 여의도 관공선 사무실'},
        //     { address1: '서울특별시 영등포구 여의서로 120 (여의도동)', address2: '서울특별시 영등포구 여의도동 82-1 인천해양경찰서 한강파출소'},
        //     { address1: '서울특별시 영등포구 여의서로 160 (여의도동)', address2: '서울특별시 영등포구 여의도동 81-8'},
        //     { address1: '서울특별시 영등포구 여의서로 82 (여의도동)', address2: '서울특별시 영등포구 여의도동 83 여의도 관공선 사무실'},
        // ],
        
        // items: Array.from({length: 100}).map((v, k) => ({
        //     index: `${k+1}`,
        //     address1: '서울특별시 영등포구 여의서로 120 (여의도동)',
        //     address2: '서울특별시 영등포구 여의도동 82-1 인천해양경찰서 한강파출소'
        // })),
        source01: 'HTML',
        content:{

source01_tab01: `<template>
    <v-app><!-- 반드시 입력해야 하는 요소 -->
        <div class="frame-popup">
            <header>
                <h1>주소 조회</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main>
                <!-- main 태그 사이에 컨텐츠를 넣어주세요 -->
                <!--조회-->
                <div class="jh-search-form">
                    <table>
                        <colgroup>
                            <col width="30px">
                            <col width="350px">
                            <col>
                        </colgroup>
                        <tr>
                            <th><label>주소</label></th>
                            <td><input type="text" class="jh-form"></td>
                            <td class="has-search"><v-btn class="jh-btn is-search">조회</v-btn></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colspan="2">
                                <p class="jh-info-txt is-fill is-blue">도로명, 지번에 대한 통합 검색이 가능합니다. ( 예: 반포대로 58, 여의도동 28, 여의도동 KT 등 )</p>
                            </td>
                        </tr>
                    </table>
                </div>
                <!--//조회-->

                <!--리스트-->
                <div class="jh-form-wrap is-p-5">
                    <p class="jh-no-data" v-if="this.items.length === 0"><span>조회된 주소가 없습니다.</span></p>
                    <v-virtual-scroll class="jh-list-address" :items="items" item-height="60" height="460" v-if="this.items.length > 0">
                        <template v-slot="{ item }">
                            <div class="jh-list-address-item" :key="item.index" :class="{'is-active' : selectedItem === item }" @click="listClick(item)">
                                <div>
                                    <label class="is-new">도로명</label>
                                    <p>{{ item.address1 }}</p>
                                </div>
                                <div>
                                    <label class="is-old">지번</label>
                                    <p>{{ item.address2 }}</p>
                                </div>
                            </div>
                        </template>
                    </v-virtual-scroll>
                </div>
                <!--//리스트-->
            </main>
            <footer><v-btn class="jh-btn is-md is-main" @click="close">선택</v-btn></footer>
        </div>
    </v-app>
</template>`
,source01_tab02: `export default {
  data () {
    return {  
    }
  },
  methods: {
    //팝업 닫기
    close(){
        self.close();
    },
  },
}`
      }
    }
  },
  methods: {
    listClick: function(item){
        if(this.selectedItem === item){
            this.selectedItem = null;
        }else{
            this.selectedItem = item;
        }
    } 
  },
  computed: {
      items () {
        return Array.from({length: this.length}, (k, v) => v + 1)
      },
      length (){
        return 100
      }
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>