<template>
  <div>
    <v-progress-circular id="appLoading" v-if="appLoading" indeterminate color="cyan" />
    <v-fade-transition mode="out-in">
      <router-view v-if="!appLoading" />
    </v-fade-transition>
    <Toast />
    <Alert />
  </div>
</template>

<script>
import Toast from "@/components/ToastDefault";
import Alert from "@/components/AlertDefault";

export default {
  name: "App",
  components: {
    Toast,
    Alert,
  },
  // beforeRouteLeave(to, from, next){
  //   if(confirm('이 사이트에서 나가시겠습니까?')){
  //     next('/login')
  //   }
  // },
  computed: {
    appLoading: function() {
      return this.$store.getters["commonStore/GE_COMMON_APP_LOADING"];
    }
  },
  mounted() {
    // window.addEventListener("keydown", this.setAvailableRefresh);

    // window.addEventListener("beforeunload", this.unLoadEvent);
  },
  beforeMount() {
    // window.removeEventListener("beforeunload", this.unLoadEvent);

    // api 응답의 httpcode가 401이면 로그아웃 처리
    this.$watch("$store.state.commonStore.error_code", function(newVal) {
      if (newVal === 401) {
        // 메인창인 경우
        if (!opener) {
          // this.$store.commit("alertStore/INIT");
          // this.$store.commit("toastStore/INIT");

          // this.$store.commit("authStore/MU_AUTH_LOGOUT_RESULT", { code: null, data: null });
          // this.$store.commit("commonStore/MU_COMMON_INIT");
          // this.$store.commit("userStore/MU_USER_INIT");

          // window.sessionStorage.clear();
          // // location.href = "/";
          // this.$router.push("/login");
          // window.location.reload(true);

          this.$store
            .dispatch("authStore/AC_AUTH_LOGOUT", {  })
            .then((response) => {
              if (this.mixin_check_repsonse(response) === true) {
                // console.log("######### 로그아웃 성공");
              }

              this.$store.commit("alertStore/INIT");
              this.$store.commit("toastStore/INIT");

              //API가 정상처리 되지 않아도 세션 삭제 후 로그인 페이지로 이동 시킨다
              window.sessionStorage.clear();
              this.$router.push("/login");
              window.location.reload(true);
            })
            .catch((err) => {
              window.sessionStorage.clear();
              this.$router.push("/login");
              window.location.reload(true);
              // console.log("######### 로그아웃 에러");

              // this.errorMessage = err.response.data.errormessage;
            });
        }
        // 윈도우 팝업창인 경우
        else {
          this.$store.commit("commonStore/MU_COMMON_APP_ERROR_CODE", "");
          self.close();
        }
      }
    })
  },
  methods: {
    unLoadEvent(event) {
      //로그인페이지가 아니라면 새로고침 클릭시 컴폼을 받지 않는다
      if (this.$route.fullPath !== "/login") {
        console.log("unloadEvent")
        event.preventDefault();
        event.returnValue = '';
      }
    },
  },
    // 새로고침 방지 
    // setAvailableRefresh : function(e) {
    //   console.loca("console이다!!!!! ", e)
         
    //      if(e.key === 116){
    //         if(typeof(event) == "object"){
    //           event.key = 0;
    //         }
    //         return false;
    //       } else if(e.key === 82 && e.ctrl) {
    //           return false;
    //       } else if(e.key === 8) {
    //           if(e.target.nodeName === "INPUT" || e.target.nodeName === "TEXTAREA"){
    //             if(typeof($(e.target).attr("readonly")) !== "undefined" || $(e.target).hasClass("twbDisabled") || $(e.target).is(":disabled")){
    //               return false;
    //             }else{
    //               return true;
    //             }
    //           }else{
    //             return false;
    //           }
    //       }
    // },   
  beforeDestroy() {
    //세션에 담나놓는거라 서비스가 종료되면 자연스럽게 삭제되지만
    //혹시몰라서 추가
    window.sessionStorage.clear();
  },
};
</script>

<style scoped>
.v-progress-circular {
  position: fixed;
  top: calc(50vh - 16px);
  left: calc(50vw - 16px);
  z-index: 11111;
}
</style>
