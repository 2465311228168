<template>
    <div class="guide-content"> 
        <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Tooltip</span></div>

        <h2>Tooltip Direction</h2>
        <div class="guide-card">
            <v-tooltip content-class="jh-tooltip" top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="jh-btn" v-bind="attrs" v-on="on">TOP</v-btn>
                </template>
                <span>위로 나오는 툴팁</span>
            </v-tooltip>
            <v-tooltip content-class="jh-tooltip" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="jh-btn is-ml-5" v-bind="attrs" v-on="on">BOTTOM</v-btn>
                </template>
                <span>아래로 나오는 툴팁</span>
            </v-tooltip>
            <v-tooltip content-class="jh-tooltip" left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="jh-btn is-ml-5" v-bind="attrs" v-on="on">LEFT</v-btn>
                </template>
                <span>왼쪽에 나오는 툴팁</span>
            </v-tooltip>
            <v-tooltip content-class="jh-tooltip" right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="jh-btn is-ml-5" v-bind="attrs" v-on="on">RIGHT</v-btn>
                </template>
                <span>오른쪽에 나오는 툴팁</span>
            </v-tooltip>
        </div>
        <div class="guide-source">
            <v-tabs v-model="source01">
                <v-tab>Html</v-tab>
            </v-tabs>
            <v-tabs-items v-model="source01">
                <v-tab-item>
                    <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
                </v-tab-item>
            </v-tabs-items>
        </div>

        <h2>Max Width</h2>
        <div class="guide-card">
            <v-tooltip content-class="jh-tooltip" bottom max-width="200px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="jh-btn" v-bind="attrs" v-on="on">BOTTOM</v-btn>
                </template>
                <span>아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁</span>
            </v-tooltip>
        </div>
        <div class="guide-source">
            <v-tabs v-model="source02">
                <v-tab>Html</v-tab>
            </v-tabs>
            <v-tabs-items v-model="source02">
                <v-tab-item>
                    <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
                </v-tab-item>
            </v-tabs-items>
        </div>

        <h2>Data</h2>
        <div class="guide-card">
            <v-tooltip content-class="jh-tooltip" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="jh-btn" v-bind="attrs" v-on="on">BOTTOM</v-btn>
                </template>
                <span>{{ tooltipMsg }}</span>
            </v-tooltip>
        </div>
        <div class="guide-source">
            <v-tabs v-model="source03">
                <v-tab>Html</v-tab>
                <v-tab>Script</v-tab>
            </v-tabs>
            <v-tabs-items v-model="source03">
                <v-tab-item>
                    <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
                </v-tab-item>
                <v-tab-item>
                  <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source03_tab02}}</code></pre>
                </v-tab-item>
            </v-tabs-items>
        </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Table",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {    
        source01: 'HTML',
        source02: 'HTML',
        source03: 'HTML',
        tooltipMsg: '툴팁메시지입니다.',
        content:{

source01_tab01: `<v-tooltip content-class="jh-tooltip" top>
    <template v-slot:activator="{ on, attrs }">
        <v-btn class="jh-btn" v-bind="attrs" v-on="on">TOP</v-btn>
    </template>
    <span>위로 나오는 툴팁</span>
</v-tooltip>
<v-tooltip content-class="jh-tooltip" bottom>
    <template v-slot:activator="{ on, attrs }">
        <v-btn class="jh-btn" v-bind="attrs" v-on="on">BOTTOM</v-btn>
    </template>
    <span>아래로 나오는 툴팁</span>
</v-tooltip>
<v-tooltip content-class="jh-tooltip" left>
    <template v-slot:activator="{ on, attrs }">
        <v-btn class="jh-btn" v-bind="attrs" v-on="on">LEFT</v-btn>
    </template>
    <span>왼쪽에 나오는 툴팁</span>
</v-tooltip>
<v-tooltip content-class="jh-tooltip" right>
    <template v-slot:activator="{ on, attrs }">
        <v-btn class="jh-btn" v-bind="attrs" v-on="on">RIGHT</v-btn>
    </template>
    <span>오른쪽에 나오는 툴팁</span>
</v-tooltip>`
,source02_tab01: `<v-tooltip content-class="jh-tooltip" bottom max-width="200px">
    <template v-slot:activator="{ on, attrs }">
        <v-btn class="jh-btn is-ml-5" v-bind="attrs" v-on="on">BOTTOM</v-btn>
    </template>
    <span>아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁</span>
</v-tooltip>`
,source03_tab01: `<v-tooltip content-class="jh-tooltip" bottom>
    <template v-slot:activator="{ on, attrs }">
        <v-btn class="jh-btn" v-bind="attrs" v-on="on">BOTTOM</v-btn>
    </template>
    <span>{{ tooltipMsg }}</span>
</v-tooltip>`
,source03_tab02: `data() {
    return {    
        tooltipMsg: '툴팁메시지입니다.',
    }
},`
      }
    }
  },
  methods: {
    showAlertInfo(){
        this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: '안내 메시지입니다.',
            iconClass: 'jh-alert-icon is-info',
            type: 'default'
        })
    },
    showAlertWarning(){
        this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
            iconClass: 'jh-alert-icon is-error',
            type: 'default'
        })
    },
    showAlertSuccess(){
        this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: '정상처리 되었습니다.',
            iconClass: 'jh-alert-icon is-success',
            type: 'default'
        })
    },
    showAlertError(){
        this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: '에러 메시지입니다.',
            iconClass: 'jh-alert-icon is-error',
            type: 'default'
        })
    },
    showConfirm(){
        this.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: true,
            msg: '로그아웃 하시겠습니까?',
            iconClass: 'jh-alert-icon is-error',
            type: 'confirm',
            callYes: this.callYes,
            callNo: this.callNo,
        })
    },
    callYes() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    callNo() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    showAlertCallback() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        type: "callback",
        count: 20,
      });
    },
    showAlertReserve() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        type: "reserveCall",
        count: 10,
        reserveCallTime: 40,
      });
    },

    showToast() {
        this.$store.commit("toastStore/POP", {
            act: true,
            msg: '기본 메시지입니다.',
            class: '',
            hasToastIcon: false,
            icon: '',
        })
    },
    showToastSuccess() {
        this.$store.commit("toastStore/POP", {
            act: true,
            msg: '성공 메시지입니다.',
            class: 'jh-toast-success',
            hasToastIcon: true,
            icon: 'jh-icon-toast-success',
        })
    },
    showToastInfo() {
        this.$store.commit("toastStore/POP", {
            act: true,
            msg: '안내 메시지입니다.',
            class: 'jh-toast-info',
            hasToastIcon: true,
            icon: 'jh-icon-toast-info',
        })
    },
    showToastWarning() {
        this.$store.commit("toastStore/POP", {
            act: true,
            msg: '경고 메시지입니다.',
            class: 'jh-toast-warning',
            hasToastIcon: true,
            icon: 'jh-icon-toast-warning',
        })
    },
    showToastError() {
        this.$store.commit("toastStore/POP", {
            act: true,
            msg: '에러 메시지입니다.',
            class: 'jh-toast-error',
            hasToastIcon: true,
            icon: 'jh-icon-toast-error',
        })
    },
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>