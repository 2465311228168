<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Text Fields</span></div>

      <h2>Input Text</h2>
      <div class="guide-card jh-cols">
        <v-text-field class="jh-form" placeholder="default"></v-text-field>
        <v-text-field class="jh-form" placeholder="disabled" disabled></v-text-field>
        <v-text-field class="jh-form is-trns" placeholder="투명" disabled></v-text-field>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Input Text Size</h2>
      <div class="guide-card jh-cols">  
        <v-text-field class="jh-form is-sm" placeholder="jh-form is-sm"></v-text-field>
        <v-text-field class="jh-form" placeholder="jh-form"></v-text-field> 
        <v-text-field class="jh-form is-md" placeholder="jh-form is-md"></v-text-field>
        <v-text-field class="jh-form is-lg" placeholder="jh-form is-lg"></v-text-field>
        <v-text-field class="jh-form is-xl" placeholder="jh-form is-xl"></v-text-field>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Round Input Text</h2>
      <div class="guide-card jh-cols">  
        <v-text-field class="jh-form is-round is-sm" placeholder="jh-form is-round is-sm"></v-text-field>
        <v-text-field class="jh-form is-round" placeholder="jh-form is-round"></v-text-field> 
        <v-text-field class="jh-form is-round is-md" placeholder="jh-form is-round is-md"></v-text-field>
        <v-text-field class="jh-form is-round is-lg" placeholder="jh-form is-round is-lg"></v-text-field>
        <v-text-field class="jh-form is-round is-xl" placeholder="jh-form is-round is-xl"></v-text-field> 
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Append/Prepend v-btn</h2>
      <div class="guide-card jh-cols">   
        <v-text-field clearable class="jh-form is-round is-md" placeholder="검색어를 입력하세요.">          
          <template v-slot:append>
            <v-btn class="jh-btn is-icon"><i class="jh-icon-search"></i></v-btn>
          </template>
        </v-text-field>
        <v-text-field clearable class="jh-form is-round is-md" placeholder="검색어를 입력하세요.">          
          <template v-slot:prepend-inner>
            <v-btn class="jh-btn is-icon"><i class="jh-icon-search"></i></v-btn>
          </template>
        </v-text-field>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Append/Prepend Icon</h2>
      <div class="guide-card jh-cols">
        <v-text-field class="jh-form is-md" append-icon="jh-icon-map" placeholder="위치"></v-text-field> 
        <v-text-field class="jh-form is-md" prepend-inner-icon="jh-icon-map" placeholder="위치"></v-text-field>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Clearable</h2>
      <div class="guide-card jh-cols">
        <v-text-field clearable class="jh-form" placeholder="오른쪽에 X버튼 나옴"></v-text-field> 
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source06_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Number</h2>
      <div class="guide-card jh-cols">
        <v-text-field type="number" class="jh-form" placeholder="숫자만 입력"></v-text-field>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source07_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Suffix / Prefix</h2>
      <div class="guide-card jh-cols">
        <v-text-field class="jh-form is-txt-right" suffix="건"></v-text-field>
        <v-text-field class="jh-form" prefix="건수 : "></v-text-field>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source08_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Textarea</h2>
      <div class="guide-card jh-cols">
        <v-textarea class="jh-form" placeholder="상담내용을 입력하세요." style="height:120px"></v-textarea>
        <v-textarea class="jh-form is-trns" placeholder="상담내용을 입력하세요.(투명)" style="height:120px"></v-textarea>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source09_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>      

      <h2>File Input</h2>
      <div class="guide-card jh-cols">
        <v-file-input class="jh-form" placeholder="파일을 등록하세요"></v-file-input>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source10_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "VueTextfield",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {     
        source01: 'HTML',
        content:{

source01_tab01: `<v-text-field class="jh-form" placeholder="default"></v-text-field>
<v-text-field class="jh-form" placeholder="disabled" disabled></v-text-field>
<v-text-field class="jh-form is-trns" placeholder="투명" disabled></v-text-field>`
,source02_tab01: `<v-text-field class="jh-form is-sm" placeholder="jh-form is-sm"></v-text-field>
<v-text-field class="jh-form" placeholder="jh-form"></v-text-field> 
<v-text-field class="jh-form is-md" placeholder="jh-form is-md"></v-text-field>
<v-text-field class="jh-form is-lg" placeholder="jh-form is-lg"></v-text-field>
<v-text-field class="jh-form is-xl" placeholder="jh-form is-xl"></v-text-field>`
,source03_tab01: `<v-text-field class="jh-form is-round is-sm" placeholder="jh-form is-round is-sm"></v-text-field>
<v-text-field class="jh-form is-round" placeholder="jh-form is-round"></v-text-field> 
<v-text-field class="jh-form is-round is-md" placeholder="jh-form is-round is-md"></v-text-field>
<v-text-field class="jh-form is-round is-lg" placeholder="jh-form is-round is-lg"></v-text-field>
<v-text-field class="jh-form is-round is-xl" placeholder="jh-form is-round is-xl"></v-text-field>`
,source04_tab01: `<v-text-field clearable class="jh-form is-round is-md" placeholder="검색어를 입력하세요.">          
  <template v-slot:append>
    <v-btn class="jh-btn is-icon"><i class="jh-icon-search"></i></v-btn>
  </template>
</v-text-field>
<v-text-field clearable class="jh-form is-round is-md" placeholder="검색어를 입력하세요.">          
  <template v-slot:prepend-inner>
    <v-btn class="jh-btn is-icon"><i class="jh-icon-search"></i></v-btn>
  </template>
</v-text-field>`
,source05_tab01: `<v-text-field class="jh-form is-md" append-icon="jh-icon-map" placeholder="위치"></v-text-field> 
<v-text-field class="jh-form is-md" prepend-inner-icon="jh-icon-map" placeholder="위치"></v-text-field>`
,source06_tab01: `<v-text-field clearable class="jh-form is-md" placeholder="오른쪽에 X버튼 나옴"></v-text-field>`
,source07_tab01: `<v-text-field type="number" class="jh-form" placeholder="숫자만 입력"></v-text-field>`
,source08_tab01: `<v-text-field class="jh-form is-txt-right" suffix="건"></v-text-field>
<v-text-field class="jh-form" prefix="건수 : "></v-text-field>`
,source09_tab01: `<v-textarea class="jh-form" placeholder="상담내용을 입력하세요." style="height:120px"></v-textarea>
<v-textarea class="jh-form is-trns" placeholder="상담내용을 입력하세요.(투명)" style="height:120px"></v-textarea>`
,source10_tab01: `<v-file-input class="jh-form" placeholder="파일을 등록하세요"></v-file-input>`
      }
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>