<template>
  <div class="guide-content"> 
    <div class="guide-content-header">Patterns<v-icon class="mdi mdi-chevron-right"></v-icon><span>Header</span></div>

    <div class="guide-card is-mt-30">
        <div class="jh-ui-header">
            <h1>화면제목</h1>
            <div class="is-right">
            </div>
        </div>
    </div>
    <div class="guide-source">
        <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
            <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
            </v-tab-item>
        </v-tabs-items>
    </div>

    <div class="guide-card is-mt-40">
        <div class="jh-ui-header is-underline">
            <h2>그룹제목</h2>
            <div class="is-right">
                <button type="button" class="jh-btn is-search">조회</button>
            </div>
        </div>
    </div>
    <div class="guide-source">
        <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
            <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
            </v-tab-item>
        </v-tabs-items>
    </div>

    <div class="guide-card is-mt-40">
        <div class="jh-ui-header">
            <h2>제목</h2>
            <div class="is-right">
                <button type="button" class="jh-btn">신규</button>
                <button type="button" class="jh-btn is-light">상세</button>
                <button type="button" class="jh-btn is-del">삭제</button>
                <button type="button" class="jh-btn is-main">저장</button>
            </div>
        </div>
    </div>
    <div class="guide-source">
        <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
            <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
            </v-tab-item>
        </v-tabs-items>
    </div>

    <div class="guide-card is-mt-40">
        <div class="jh-ui-header">
            <h2 class="has-bullet">제목</h2>
            <div class="is-right">
                <v-text-field
                    placeholder="검색어를 입력하세요."
                   
                    class="jh-form">
                    <!--Append Button-->
                    <template v-slot:append>
                        <button class="jh-btn is-icon"><i class="jh-icon-search-sm is-blue"></i></button>
                    </template>
                </v-text-field>
            </div>
        </div>
    </div>
    <div class="guide-source">
        <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
            <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
            </v-tab-item>
        </v-tabs-items>
    </div>

    <div class="guide-card is-mt-40">
        <div class="jh-ui-header">
            <h3>제목</h3>
            <div class="is-right">
                <label class="jh-label has-dot">조회조건</label>
                <em class="is-value">0</em>
                <span class="jh-unit">건</span>
                <label class="jh-label has-dot">등록완료</label>
                <em class="is-value">0</em>
                <span class="jh-unit is-mr-10">건</span>
                <button type="button" class="jh-btn">등록초기화</button>
                <button type="button" class="jh-btn is-main">등록</button>
                <button type="button" class="jh-btn is-fill">등록제거</button>
                <button type="button" class="jh-btn is-fill">등록마감</button>
            </div>
        </div>
    </div>
    <div class="guide-source">
        <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
            <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
            </v-tab-item>
        </v-tabs-items>
    </div>

    <div class="guide-card is-mt-40">
        <div class="jh-ui-header">
            <h3 class="has-bullet">제목</h3>
            <div class="is-right jh-cols is-space-5">
                <label class="jh-label has-dot">조회조건</label>
                <v-select
                    class="jh-form"
                    :items="items"
                    label="선택"
                   
                    style="width:100px"
                ></v-select>
                <v-text-field
                    class="jh-form"
                    single-line
                   
                    placeholder="검색어"
                ></v-text-field>
                <button type="button" class="jh-btn is-search">조회</button>
            </div>
        </div>
    </div>
    <div class="guide-source">
        <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
            <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source06_tab01}}</code></pre>
            </v-tab-item>
        </v-tabs-items>
    </div>

    <div class="guide-card is-mt-40">
        <div class="jh-ui-header">
            <h2>제목</h2>
            <div class="is-right">
                <label class="jh-label has-dot">보기정렬</label>
                <v-btn-toggle
                    v-model="align"
                    class="jh-btn-group">
                    <v-btn class="jh-btn"><span>3열</span></v-btn>
                    <v-btn class="jh-btn"><span>4열</span></v-btn>
                    <v-btn class="jh-btn"><span>5열</span></v-btn>
                    <v-btn class="jh-btn"><span>6열</span></v-btn>
                    <v-btn class="jh-btn"><span>7열</span></v-btn>
                </v-btn-toggle>
            </div>
        </div>
    </div>
    <div class="guide-source">
        <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
            <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source07_tab01}}</code></pre>
            </v-tab-item>
        </v-tabs-items>
    </div>

    <div class="guide-card is-mt-40">
        <div class="jh-ui-header">
            <div class="is-left">
                <v-text-field
                    class="jh-form"
                    palaceholder="검색어를 입력하세요."
                   >
                    <!--Append Button-->
                    <template v-slot:append>
                        <button class="jh-btn is-icon"><i class="jh-icon-search-sm is-blue"></i></button>
                    </template>
                </v-text-field>
            </div>
            <div class="is-right">
                <span class="jh-info-txt">도움말입니다.</span>
            </div>
        </div>
    </div>
    <div class="guide-source">
        <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
            <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source08_tab01}}</code></pre>
            </v-tab-item>
        </v-tabs-items>
    </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Header",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {    
        align: 2,
        items: [],
        
        source01: 'HTML',
        content:{

source01_tab01: `<div class="jh-ui-header">
    <h1>화면제목</h1>
    <div class="is-right">
    </div>
</div>`
,source02_tab01: `<div class="jh-ui-header">
    <h2>그룹제목</h2>
    <div class="is-right">
        <button type="button" class="jh-btn is-search">조회</button>
    </div>
</div>`
,source03_tab01: `<div class="jh-ui-header">
    <h2>제목</h2>
    <div class="is-right">
        <button type="button" class="jh-btn">신규</button>
        <button type="button" class="jh-btn is-light">상세</button>
        <button type="button" class="jh-btn is-del">삭제</button>
        <button type="button" class="jh-btn is-main">저장</button>
    </div>
</div>`
,source04_tab01: `<div class="jh-ui-header">
    <h2 class="has-bullet">제목</h2>
    <div class="is-right">
        <v-text-field class="jh-form" placeholder="검색어를 입력하세요.">
            <!--Append Button-->
            <template v-slot:append>
                <button class="jh-btn is-icon"><i class="jh-icon-search-sm is-blue"></i></button>
            </template>
        </v-text-field>
    </div>
</div>`
,source05_tab01: `<div class="jh-ui-header">
    <h3>제목</h3>
    <div class="is-right">
        <label class="jh-label has-dot">조회조건</label>
        <em class="is-value">0</em>
        <span class="jh-unit">건</span>
        <label class="jh-label has-dot">등록완료</label>
        <em class="is-value">0</em>
        <span class="jh-unit is-mr-10">건</span>
        <button type="button" class="jh-btn">등록초기화</button>
        <button type="button" class="jh-btn is-main">등록</button>
        <button type="button" class="jh-btn is-fill">등록제거</button>
        <button type="button" class="jh-btn is-fill">등록마감</button>
    </div>
</div>`
,source06_tab01: `<div class="jh-ui-header">
    <h3 class="has-bullet">제목</h3>
    <div class="is-right jh-cols is-space-5">
        <label class="jh-label has-dot">조회조건</label>
        <v-select class="jh-form" :items="items" label="선택" style="width:100px"></v-select>
        <v-text-field class="jh-form" placeholder="검색어"></v-text-field>
        <button type="button" class="jh-btn is-search">조회</button>
    </div>
</div>`
,source07_tab01: `<div class="jh-ui-header">
    <h1>제목</h1>
    <div class="is-right">
        <label class="jh-label has-dot">보기정렬</label>
        <v-btn-toggle v-model="align" class="jh-btn-group">
            <v-btn class="jh-btn"><sapn>3열</sapn></v-btn>
            <v-btn class="jh-btn"><sapn>4열</sapn></v-btn>
            <v-btn class="jh-btn"><sapn>5열</sapn></v-btn>
            <v-btn class="jh-btn"><sapn>6열</sapn></v-btn>
            <v-btn class="jh-btn"><sapn>7열</sapn></v-btn>
        </v-btn-toggle>
    </div>
</div>`
,source08_tab01: `<div class="jh-ui-header">
    <div class="is-left">
        <v-text-field class="jh-form" palaceholder="검색어를 입력하세요.">
            <!--Append Button-->
            <template v-slot:append>
                <button class="jh-btn is-icon"><i class="jh-icon-search-sm is-blue"></i></button>
            </template>
        </v-text-field>
    </div>
    <div class="is-right">
        <span class="jh-info-txt">도움말입니다.</span>
    </div>
</div>`
      }
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>