var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"guide-content"},[_c('div',{staticClass:"guide-content-header"},[_vm._v("Vue Components"),_c('v-icon',{staticClass:"mdi mdi-chevron-right"}),_c('span',[_vm._v("Tooltip")])],1),_c('h2',[_vm._v("Tooltip Direction")]),_c('div',{staticClass:"guide-card"},[_c('v-tooltip',{attrs:{"content-class":"jh-tooltip","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"jh-btn"},'v-btn',attrs,false),on),[_vm._v("TOP")])]}}])},[_c('span',[_vm._v("위로 나오는 툴팁")])]),_c('v-tooltip',{attrs:{"content-class":"jh-tooltip","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"jh-btn is-ml-5"},'v-btn',attrs,false),on),[_vm._v("BOTTOM")])]}}])},[_c('span',[_vm._v("아래로 나오는 툴팁")])]),_c('v-tooltip',{attrs:{"content-class":"jh-tooltip","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"jh-btn is-ml-5"},'v-btn',attrs,false),on),[_vm._v("LEFT")])]}}])},[_c('span',[_vm._v("왼쪽에 나오는 툴팁")])]),_c('v-tooltip',{attrs:{"content-class":"jh-tooltip","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"jh-btn is-ml-5"},'v-btn',attrs,false),on),[_vm._v("RIGHT")])]}}])},[_c('span',[_vm._v("오른쪽에 나오는 툴팁")])])],1),_c('div',{staticClass:"guide-source"},[_c('v-tabs',{model:{value:(_vm.source01),callback:function ($$v) {_vm.source01=$$v},expression:"source01"}},[_c('v-tab',[_vm._v("Html")])],1),_c('v-tabs-items',{model:{value:(_vm.source01),callback:function ($$v) {_vm.source01=$$v},expression:"source01"}},[_c('v-tab-item',[_c('pre',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"guide-code"},[_c('code',{staticClass:"html"},[_vm._v(_vm._s(_vm.content.source01_tab01))])])])],1)],1),_c('h2',[_vm._v("Max Width")]),_c('div',{staticClass:"guide-card"},[_c('v-tooltip',{attrs:{"content-class":"jh-tooltip","bottom":"","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"jh-btn"},'v-btn',attrs,false),on),[_vm._v("BOTTOM")])]}}])},[_c('span',[_vm._v("아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁 / 아래로 나오는 툴팁")])])],1),_c('div',{staticClass:"guide-source"},[_c('v-tabs',{model:{value:(_vm.source02),callback:function ($$v) {_vm.source02=$$v},expression:"source02"}},[_c('v-tab',[_vm._v("Html")])],1),_c('v-tabs-items',{model:{value:(_vm.source02),callback:function ($$v) {_vm.source02=$$v},expression:"source02"}},[_c('v-tab-item',[_c('pre',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"guide-code"},[_c('code',{staticClass:"html"},[_vm._v(_vm._s(_vm.content.source02_tab01))])])])],1)],1),_c('h2',[_vm._v("Data")]),_c('div',{staticClass:"guide-card"},[_c('v-tooltip',{attrs:{"content-class":"jh-tooltip","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"jh-btn"},'v-btn',attrs,false),on),[_vm._v("BOTTOM")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipMsg))])])],1),_c('div',{staticClass:"guide-source"},[_c('v-tabs',{model:{value:(_vm.source03),callback:function ($$v) {_vm.source03=$$v},expression:"source03"}},[_c('v-tab',[_vm._v("Html")]),_c('v-tab',[_vm._v("Script")])],1),_c('v-tabs-items',{model:{value:(_vm.source03),callback:function ($$v) {_vm.source03=$$v},expression:"source03"}},[_c('v-tab-item',[_c('pre',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"guide-code"},[_c('code',{staticClass:"html"},[_vm._v(_vm._s(_vm.content.source03_tab01))])])]),_c('v-tab-item',[_c('pre',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"guide-code"},[_c('code',{staticClass:"javascript"},[_vm._v(_vm._s(_vm.content.source03_tab02))])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }