import axios from "axios";

import Vue      from 'vue';

export const apiCall = new Vue({
    methods: {
        /** CALL이벤트 
         * 
         * param 1 : url데이터
         * 
        */

        ipcc(url){
            let data = {
                "url": url,
                "method": "GET",
                "timeout": 0,
            };
            $.ajax(data).done(function (err, response) {
                if (response){
                    console.log(response);
                    return response;
                } else {
                    return err;
                }
            });
        },
        callApi(url, authKey){
            let data = {
                "url": url,
                "method": "GET",
                "timeout": 0,
                "headers": {
                    "Authorization": authKey
                },
            };
            $.ajax(data).done(function (err, response) {
                if (response){
                    console.log(response);
                    return response;
                } else {
                    return err;
                }
            });
        },
        callPost(url, params){
            console.log("############################################");
            console.log("*url:"+url);
            let data = {
                "url": url,
                "method": "POST",
                "timeout": 0,
                "contentType": "application/json",
                "Accept": "application/json",
                "data": JSON.stringify(params),
            };
            $.ajax(data).done(function (err, response) {
                if (response){
                    console.log(response);
                    return response;
                } else {
                    return err;
                }
            });
        }
    }

})