<template>
  <v-dialog v-if="alert.alertDialogToggle" v-model="alert.alertDialogToggle" max-width="420"
    :persistent="alert.type === 'confirmPwd' || alert.type === 'confirmAplyWork'" 
    @keydown.enter="enter" @keydown.space="space">
    <!--persistent 옵션: 바탕 또는 esc키 눌러도 안닫히게 하는 옵션-->
    <div class="jh-alert">
      <template>
        <v-btn
          v-if="
            alert.type == 'default' ||
            alert.type == 'callback' ||
            alert.type == 'reserveCall'
          "
          class="jh-btn is-icon is-close"
          @click="alert.alertDialogToggle = false"
        >
          <i class="jh-icon-close-lg"></i>
        </v-btn>
      </template>
      <div class="jh-alert-body" v-if="alert.type == 'default' || alert.type == 'confirm' || alert.type == 'confirmPwd' || alert.type == 'confirmAplyWork' || alert.type == 'confirmHistRegist'">
        <i :class="alert.iconClass"> </i>
        <h1 v-html="newLine(alert.msg)"></h1>
      </div>
      <div class="jh-alert-body" v-if="alert.type == 'callback'">
        <div class="jh-alert-badge">콜백 {{ alert.count }} 건</div>
        <h1>콜백이 할당되었습니다.</h1>
        <div class="jh-alert-copy is-txt-red">콜백을 처리해주세요.</div>
      </div>
      <div class="jh-alert-body" v-if="alert.type == 'reserveCall'">
        <div class="jh-alert-badge">예약콜 {{ alert.count }} 건</div>
        <h1>예약콜 <em>{{ alert.reserveCallTime }}분</em> 전입니다.</h1>
      </div>
      <div class="jh-alert-btn" v-if="alert.type === 'confirm'">
        <v-btn class="jh-btn is-lg" @click="alert.callNo">아니오</v-btn>
        <v-btn class="jh-btn is-lg is-fill" @click="alert.callYes">예</v-btn>
      </div>

      <!-- 비밀번호 만료  confirm -->
      <div class="jh-alert-btn" v-if="alert.type === 'confirmPwd'">
        <v-btn class="jh-btn is-lg is-fill" @click="alert.callYes">비밀번호 변경</v-btn>
      </div>
      <!-- 캘린더/상장책자 미처리 건  confirm -->
      <div class="jh-alert-btn" v-if="alert.type === 'confirmAplyWork'">
        <v-btn class="jh-btn is-lg is-fill" @click="alert.callYes">확인</v-btn>
      </div>
      <div class="jh-alert-btn" v-if="alert.type === 'confirmHistRegist'">
        <v-btn class="jh-btn is-lg" @click="alert.callNo">닫기</v-btn>
        <v-btn class="jh-btn is-lg is-fill" @click="alert.callYes">저장</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "alertDefault",
  data: () => ({}),
  computed: {
    ...mapGetters({
      alert: "alertStore/GE_ALERT",
    }),
  },
  methods: {
    newLine(title) {
      if (!title) return;
      return title.split("\n").join("<br>");
    },
    enter() {
      if (this.alert.type == 'confirm' || this.alert.type == 'confirmHistRegist') {
        this.alert.callYes();
      } else {
        this.alert.alertDialogToggle = false;
      }
    },
    space() {
      if (this.alert.type == 'confirm' || this.alert.type == 'confirmHistRegist') {
        this.alert.callYes();
      } else {
        this.alert.alertDialogToggle = false;
      }
    }
  },
};
</script>

<style></style>
