<!-- /********************************************************************************
 * @classDescription 텔레톡 5.0 메인 화면 스타일 가이드 
 * @ATRT HANKOOK Corp.
 * @version 1.0
 * -------------------------------------------------------------------------------
 * Modification Information
 * Date              Developer           Content
 * ----------        -------------       -------------------------
 * 2019/07/23        서주희               신규생성 
 * -------------------------------------------------------------------------------
 * Copyright (C) 2017 by HANKOOK Corp. All rights reserved.
 *********************************************************************************/

/*******************************************************************************
 * 텔레톡 5.0 메인 화면 스타일 가이드 
 ******************************************************************************/ -->

<template>
    <v-app>
        <v-fab-transition>
            <v-btn
                v-show="fab"
                fab
                small
                dark
                fixed
                bottom
                :right="true"
                color="deep-orange"
                @click="toTop">
                <i class="jh-icon-arrow-up-md-bold is-white"></i>
            </v-btn>
        </v-fab-transition>
        <v-navigation-drawer
            v-model="drawer"
            fixed
            width="280"
            permanent
            class="guide-nav-frame"
            >
            <v-list-item class="guide-header">
                <v-list-item-content>
                  <v-list-item-title class="title">
                    Publishing Guide
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Palette
                  </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        
            <v-divider></v-divider>       
            <v-list class="guide-nav" rounded>
                <v-list-group
                    v-for="item in nav"
                    :key="item.title"
                    v-model="item.active"  
                    color="indigo"                    
                    no-action>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                    </template>
            
                    <v-list-item
                        v-for="subItem in item.children"
                        :key="subItem.title"
                        :class="{'v-list-item--active':subItem.title == selected}"
                        @click.stop="selected=subItem.title;menuClick(subItem.path)">
                        <v-list-item-content>
                            <v-list-item-subtitle v-text="subItem.title"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-navigation-drawer>
        <div class="guide-main-frame">
            <transition name="component-fade" mode="out-in"><component :is="component"></component></transition>            
        </div>
    </v-app>
</template>

<script>
import Text2 from "@/pbGuide/Text.vue";
import Color from "@/pbGuide/color.vue";
import Icons from "@/pbGuide/icons.vue";
import Spacing from "@/pbGuide/spacing.vue";
import Align from "@/pbGuide/align.vue";
import Textfield from "@/pbGuide/textfield.vue";
import Buttons from "@/pbGuide/buttons.vue";
import Selection from "@/pbGuide/selection.vue";
import Cols from "@/pbGuide/cols.vue";
import Table from "@/pbGuide/table.vue";
import VueAlert from "@/pbGuide/vueAlert.vue";
import VueTextfield from "@/pbGuide/vueTextfield.vue";
import VueSelects from "@/pbGuide/vueSelects.vue";
import VueControls from "@/pbGuide/vueControls.vue";
import VueChips from "@/pbGuide/vueChips.vue";
import VueDataTables from "@/pbGuide/vueDataTables.vue";
import VueDialog from "@/pbGuide/vueDialog.vue";
import VuePopup from "@/pbGuide/vuePopup.vue";
import VueTab from "@/pbGuide/vueTab.vue";
import VueButtons from "@/pbGuide/vueButtons.vue";
import VueTooltip from "@/pbGuide/vueTooltip.vue";
import VuePickers from "@/pbGuide/vuePickers.vue";
import VueTree from "@/pbGuide/vueTree.vue";
import Header from "@/pbGuide/header.vue";
import ListForm from "@/pbGuide/listForm.vue";
import ListImage from "@/pbGuide/listImage.vue";
import Card from "@/pbGuide/card.vue";
import Popup from "@/pbGuide/popup.vue";
import Block from "@/pbGuide/block.vue";
import api from "@/store/apiUtil";
import { mixin } from "@/mixin/mixin.js";

export default {
    name: "pbGuide",
    mixins: [mixin],
    components: {
        Text2, Color, Icons, Spacing, Align, Textfield, Buttons, Selection, Cols, Table, VueAlert, VueTooltip, VuePopup,
        VueTextfield, VueSelects, VueControls, VueChips, VueDataTables, VueDialog, VueTab, VueButtons, VuePickers, VueTree,
        Header, ListForm, ListImage,  Card, Popup, Block
    },
    data() {
        return {
            drawer: true,
            component: "Text2",
            selected: 'Text',
            nav: [
                {
                    title: 'Styles',
                    active: true,
                    children: [
                        { title: 'Text', path:'Text2'},
                        { title: 'Color', path:'Color' },
                        { title: 'Icons', path:'Icons' },
                        { title: 'Spacing', path:'Spacing' },
                        { title: 'Align', path:'Align' },
                        { title: 'Cols Group', path:'Cols' },
                    ],
                },
                {
                    title: 'Components',
                    children: [
                        { title: 'Text Fields', path:'Textfield' },
                        { title: 'Buttons', path:'Buttons' },
                        { title: 'Selection', path:'Selection' },
                        { title: 'Table', path:'Table' },
                        { title: 'Card', path:'Card' },
                    ],
                },
                {
                    title: 'Vuetify Components',
                    children: [
                        { title: 'Textfield', path:'VueTextfield' },
                        { title: 'Selects', path:'VueSelects' },
                        { title: 'Selection Controls', path:'VueControls' },
                        { title: 'Buttons', path:'VueButtons' },
                        { title: 'Pickers', path:'VuePickers' },
                        { title: 'Chips', path:'VueChips' },
                        { title: 'Data Tables', path:'VueDataTables' },
                        { title: 'Dialog', path:'VueDialog' },
                        { title: 'Tabs', path:'VueTab' },
                        { title: 'Window Popup', path:'VuePopup' },
                        { title: 'Alert / Toast', path:'VueAlert' },
                        { title: 'Tooltip', path:'VueTooltip' },
                        { title: 'Tree Menu', path:'VueTree' },
                    ],
                },
                {
                    title: 'Patterns',
                    children: [
                        { title: 'Header', path:'Header' },
                        { title: 'List Form', path:'ListForm' },
                        { title: '상담이미지목록', path:'ListImage' },
                        { title: '윈도우팝업', path:'Popup' },
                        { title: 'Block', path:'Block' },
                    ],
                },
            ],
            
            fab: false,
            // scrollTop: 0,
            // target: null,
        };
    },

    computed: {
    },
    methods: {
        toTop () {
            document.querySelector('body').scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },        
        handleScroll (e) {
                this.scrollTop = e.target.scrollTop;
                if(this.scrollTop > 50){
                    this.fab = true;
                }else{
                    this.fab = false;
                }
        },
        menuClick: function(path){
            this.component = path;
            this.toTop();
        }
    },
    created: function () {
        document.querySelector('body').addEventListener('scroll', this.handleScroll);
    },
    destroyed: function () {
        document.querySelector('body').removeEventListener('scroll', this.handleScroll);
    },
    beforeDestroy () {
    },
    mounted() { 
        // this.handleScroll();
    }

};
</script>

<style>
@import "../assets/css/guide.css";
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to{
  opacity: 0;
}
</style>