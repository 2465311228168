export default{
  install(Vue){
    Vue.prototype.$openWindow = function(url,name,width,height){
      if (!width) width = screen.width;
      if (!height) height = screen.height;
      
      let left = (screen.width - width) / 2,
          top = (screen.height - height) / 4
          

      var popUp = window.open(url, name, "width="+width +",height="+height+",left="+left+",top="+top);    
      this.$store.commit("popStore/MU_POP", popUp);

      return popUp
    }
  }
}
