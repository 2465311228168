<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Selects</span></div>

      <h2>Default Select</h2>
      <div class="guide-card jh-cols">
        <v-select
          class="jh-form"
          :items="items"
          placeholder="선택"         
        ></v-select>
        <v-select
          class="jh-form"
          :items="items"
          placeholder="선택"         
          disabled
        ></v-select>
        <v-select
          class="jh-form is-trns"
          :items="items"
          placeholder="선택"
        ></v-select>
        <v-select
          class="jh-form is-trns"
          :items="items"
          placeholder="선택"         
          disabled
          v-model="select04"
        ></v-select>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Select Size</h2>
      <div class="guide-card jh-cols">
        <v-select
          class="jh-form is-sm"
          :items="items"
          placeholder="jh-form is-sm"         
        ></v-select>
        <v-select
          class="jh-form"
          :items="items"
          placeholder="jh-form"         
        ></v-select>
        <v-select
          class="jh-form is-md"
          :items="items"
          placeholder="jh-form is-md"         
        ></v-select>
        <v-select
          class="jh-form is-lg"
          :items="items"
          placeholder="jh-form is-lg"         
        ></v-select>
        <v-select
          class="jh-form is-xl"
          :items="items"
          placeholder="jh-form is-xl"         
        ></v-select>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source02">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source02">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source02_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Round Select</h2>
      <div class="guide-card jh-cols"> 
        <v-select
          class="jh-form is-round is-sm"
          :items="items"
          placeholder="jh-form is-round is-sm"         
        ></v-select>
        <v-select
          class="jh-form is-round"
          :items="items"
          placeholder="jh-form is-round"         
        ></v-select>
        <v-select
          class="jh-form is-round is-md"
          :items="items"
          placeholder="jh-form is-round is-md"         
        ></v-select>
        <v-select
          class="jh-form is-round is-lg"
          :items="items"
          placeholder="jh-form is-round is-lg"         
        ></v-select>
        <v-select
          class="jh-form is-round is-xl"
          :items="items"
          placeholder="jh-form is-round is-xl"         
        ></v-select>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source03">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source03">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source03_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Autocomplete</h2>
      <div class="guide-card jh-cols">
        <v-autocomplete
          class="jh-form"
          :items="auto_items"         
          placeholder="상담유형1"
        ></v-autocomplete>
        <v-autocomplete
          class="jh-form is-round"
          :items="auto_items"         
          placeholder="상담유형1"
        ></v-autocomplete>
        <v-autocomplete
          class="jh-form is-round"
          v-model="select_member"
          :items="member_items"
          no-data-text="일치하는 데이터가 없습니다."
          item-text="name"
          item-value="name"
          placeholder=""         
        >
          <template v-slot:selection="data">
            <span class="auto-value">{{ data.item.id }}</span>
            <span class="auto-value">{{ data.item.name }}</span>
            <span class="auto-group">{{ data.item.group }}</span>
          </template>
          <template v-slot:item="data">
            <template>
              <span class="auto-value" v-html="data.item.id"></span>
              <span class="auto-value" v-html="data.item.name"></span>
              <span class="auto-group" v-html="data.item.group"></span>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source04">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source04">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source04_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Combobox</h2>
      <div class="guide-card jh-cols">    
        <v-combobox
          class="jh-form"
          :items="items"
          placeholder="선택"         
        ></v-combobox>        
        <v-combobox
          class="jh-form"
          :items="items"
          placeholder="선택"         
          multiple
        ></v-combobox>        
        <v-combobox
          class="jh-form is-md"
          :items="items"
          placeholder="선택"         
          multiple
          small-chips
        ></v-combobox>       
        <v-combobox
          class="jh-form is-lg"
          :items="items"
          placeholder="선택"         
          multiple
          chips
        ></v-combobox>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source05">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source05">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source05_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>multiple Select</h2>
      <div class="guide-card jh-cols">    
        <v-select
          class="jh-form is-round has-badge"
          single-line
          multiple
          v-model="CHK_NEWDAY"
          :items="CHK_DAY"
          item-value="value"
          item-text="text"
          placeholder="선택"
          @change="[chgChkDay(),chkNoDay()]"
        >
          <template v-slot:selection="{ item, index }">
            <span class="jh-badge is-light">
              {{ item.text }}
              <template>
                <v-btn class="jh-btn is-sm is-icon" v-show="dropItemRemove === true" @click.stop="removeDropDay(item)"><i class="jh-icon-close-xs"></i></v-btn>
              </template>
            </span>
          </template>
        </v-select>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source06">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source06">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source06_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source06_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "VueSelects",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {    
        select04: '상담유형4',
        items: ['상담유형1', '상담유형2', '상담유형3', '상담유형4'], 
        auto_items: ['상담유형1-1','상담유형1-2','상담유형1-3','상담유형1-4','상담유형1-5'],
        select_member: '',
        member_items: [
            {id: 'teletalk01', name: '홍길동', group: '상담1팀'},
            {id: 'teletalk02', name: '유재순', group: '상담2팀'},
            {id: 'teletalk03', name: '김상담', group: '상담3팀'},
            {id: 'teletalk04', name: '유재석', group: '상담4팀'},
            {id: 'teletalk05', name: '강호동', group: '상담5팀'},
        ], 
        dropItemRemove: true,
        CHK_NEWDAY: ["1", "2", "3", "4", "5", "6", "7"],
        CHK_DAY: [
          { text: "일", value: "1", },
          { text: "월", value: "2", },
          { text: "화", value: "3", },
          { text: "수", value: "4", },
          { text: "목", value: "5", },
          { text: "금", value: "6", },
          { text: "토", value: "7", },
        ],
        source01: 'HTML',
        source02: 'HTML',
        source03: 'HTML',
        source04: 'HTML',
        source05: 'HTML',
        source06: 'HTML',
        content:{

source01_tab01: `<v-select class="jh-form" :items="items"></v-select>
<v-select class="jh-form" :items="items" disabled></v-select>
<v-select class="jh-form is-trns" :items="items"></v-select>
<v-select class="jh-form is-trns" :items="items" disabled></v-select>`
,source01_tab02: `export default {
  data () {
    return {
      items: ['상담유형1', '상담유형2', '상담유형3', '상담유형4'],
    }
  },
}`
,source02_tab01: `<v-select class="jh-form is-sm" :items="items"></v-select>
<v-select class="jh-form" :items="items"></v-select>
<v-select class="jh-form is-md" :items="items"></v-select>
<v-select class="jh-form is-lg" :items="items"></v-select>
<v-select class="jh-form is-xl" :items="items"></v-select>`
,source02_tab02: `export default {
  data () {
    return {
      items: ['상담유형1', '상담유형2', '상담유형3', '상담유형4'],
    }
  },
}`
,source03_tab01: `<v-select class="jh-form is-sm" :items="items"></v-select>
<v-select class="jh-form is-round" :items="items"></v-select>
<v-select class="jh-form is-round is-md" :items="items"></v-select>
<v-select class="jh-form is-round is-lg" :items="items"></v-select>
<v-select class="jh-form is-round is-xl" :items="items"></v-select>`
,source03_tab02: `export default {
  data () {
    return {
      items: ['상담유형1', '상담유형2', '상담유형3', '상담유형4'],
    }
  },
}`
,source04_tab01: `<v-autocomplete class="jh-form" :items="auto_items"></v-autocomplete>
<v-autocomplete class="jh-form is-round" :items="auto_items" ></v-autocomplete>
<v-autocomplete class="jh-form is-round" v-model="select_member" :items="member_items"
  no-data-text="일치하는 데이터가 없습니다."
  item-text="name"
  item-value="name"  
>
  <template v-slot:selection="data">
    <span class="auto-value">{{ data.item.id }}</span>
    <span class="auto-value">{{ data.item.name }}</span>
    <span class="auto-group">{{ data.item.group }}</span>
  </template>
  <template v-slot:item="data">
    <template>
      <span class="auto-value" v-html="data.item.id"></span>
      <span class="auto-value" v-html="data.item.name"></span>
      <span class="auto-group" v-html="data.item.group"></span>
    </template>
  </template>
</v-autocomplete>`
,source04_tab02: `export default {
  data () {
    return {
        auto_items: ['상담유형1-1','상담유형1-2','상담유형1-3','상담유형1-4','상담유형1-5'],
        select_member: '',
        member_items: [
            {id: 'teletalk01', name: '홍길동', group: '상담1팀'},
            {id: 'teletalk02', name: '유재순', group: '상담2팀'},
            {id: 'teletalk03', name: '김상담', group: '상담3팀'},
            {id: 'teletalk04', name: '유재석', group: '상담4팀'},
            {id: 'teletalk05', name: '강호동', group: '상담5팀'},
        ], 
    }
  },
}`
,source05_tab01: `<v-combobox class="jh-form" :items="items"></v-combobox>
<v-combobox class="jh-form" :items="items" multiple></v-combobox>
<v-combobox class="jh-form is-md" :items="items" multiple small-chips></v-combobox>
<v-combobox class="jh-form is-lg" :items="items" multiple small-chips></v-combobox> `
,source05_tab02: `export default {
  data () {
    return {
      items: ['상담유형1', '상담유형2', '상담유형3', '상담유형4'],
    }
  },
}`
,source06_tab01: `<v-select
  class="jh-form is-round has-badge"
  single-line
  multiple
  v-model="CHK_NEWDAY"
  :items="CHK_DAY"
  item-value="value"
  item-text="text"
  placeholder="선택"
  @change="[chgChkDay(),chkNoDay()]"
>
  <template v-slot:selection="{ item, index }">
    <span class="jh-badge is-light">
      {{ item.text }}
      <template>
        <v-btn class="jh-btn is-sm is-icon" v-show="dropItemRemove === true" @click.stop="removeDropDay(item)"><i class="jh-icon-close-xs"></i></v-btn>
      </template>
    </span>
  </template>
</v-select>`
,source06_tab02: `export default {
  data () {
    return {
      dropItemRemove: true,
      CHK_NEWDAY: ["1", "2", "3", "4", "5", "6", "7"],
      CHK_DAY: [
        { text: "일", value: "1", },
        { text: "월", value: "2", },
        { text: "화", value: "3", },
        { text: "수", value: "4", },
        { text: "목", value: "5", },
        { text: "금", value: "6", },
        { text: "토", value: "7", },
      ],
    }
  },
  methods: {
    removeDropDay(item) {
      this.CHK_NEWDAY.splice((item.value-1), 1);

      let DayArray = this.CHK_NEWDAY;
      this.CHK_NEWDAY = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < DayArray.length; i++){
        if(DayArray[i] != "" || DayArray[i] != null){
          let m = parseInt(DayArray[i]);
          this.CHK_NEWDAY[m-1] = DayArray[i];
        }
      }

      this.chkNoDay();
    },
  },
}`
      }
    }
  },
  methods: {
    removeDropDay(item) {
      this.CHK_NEWDAY.splice((item.value-1), 1);

      let DayArray = this.CHK_NEWDAY;
      this.CHK_NEWDAY = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]
      for(let i = 0; i < DayArray.length; i++){
        if(DayArray[i] != "" || DayArray[i] != null){
          let m = parseInt(DayArray[i]);
          this.CHK_NEWDAY[m-1] = DayArray[i];
        }
      }

      this.chkNoDay();
    },
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>