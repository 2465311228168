<template>
  <v-list-group
    :prepend-icon="item.ICON_CLASS_NM"
    :key="item.C_NODE_NO"
    color="none"
    no-action
    v-model="active"
  >
    <!-- {{ item }} -->
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ item.NODE_TITLE }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-for="(child, index) in item.child">
      <template v-if="child.child">
        <v-list-group
          :prepend-icon="child.ICON_CLASS_NM"
          :key="`group-${index}`"
          v-if="child.child && (child.C_NODE_NO == 'E0101' || child.C_NODE_NO == 'E0102')"
          color="none"
          sub-group
          class="two-depth"
          active-class="active-icon"
          no-action
          v-model="active2"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ child.NODE_TITLE }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(child2, index2) in child.child">
            <menu-list
              class="three-depth"
              :key="`child2-${index2}`"
              :item="child2"
              :user_menu="user_menu"
            />
          </template>
        </v-list-group>
        <v-list-group
          :prepend-icon="child.ICON_CLASS_NM"
          :key="`group-${index}`"
          v-if="child.child && child.C_NODE_NO != 'E0101' && child.C_NODE_NO != 'E0102'"
          color="none"
          sub-group
          class="two-depth"
          active-class="active-icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ child.NODE_TITLE }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(child2, index2) in child.child">
            <menu-list
              class="three-depth"
              :key="`child2-${index2}`"
              :item="child2"
              :user_menu="user_menu"
            />
          </template>
        </v-list-group>
      </template>
      <menu-list
        v-else
        class="two-depth"
        :key="`child-${index}`"
        :item="child"
        :user_menu="user_menu"
      />
    </template>
  </v-list-group>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import MenuList from "./MenuList";
export default {
  components: { MenuList },
  name: "MenuGroupList",
  props: {
    item: {
      type: Object,
      default: () => ({}),
      // type: Array,
      // default: () => [],
    },
    user_menu: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    // getUserMenu: [],
    active: false,
    active2: true,
  }),
  computed: {
    ...mapGetters({
      getUserRole: "userStore/GE_USER_ROLE",
      // getUserMenu: "userStore/GE_USER_MENU",
    }),
  },
  created() {
    if (this.item.C_NODE_NO == 'E01' || this.item.C_NODE_NO == 'E02') {
      this.active = true;
    }
  },
  methods: {
    checkUserRole: (id, user_menu) => {
      var return_val = false;
      if (id) {
        if (user_menu) {
          for (var i = 0; i < user_menu.length; i++) {
            if (id === user_menu[i].C_NODE_NO) return_val = true;
          }
        }
      }
      return return_val;
    },
  },
};
</script>

<style>
</style>
