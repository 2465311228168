<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Patterns<v-icon class="mdi mdi-chevron-right"></v-icon><span>List Form</span></div>

      <div
        class="guide-card is-mt-30"             
        outlined>
        <dl class="jh-list-form">
            <dt><label class="is-required">아이템명</label></dt>
            <dd><v-text-field class="jh-form" placeholder="아이템명"></v-text-field></dd>
            <dt><label class="is-required">아이템명</label></dt>
            <dd><v-text-field class="jh-form" placeholder="아이템명"></v-text-field></dd>
        </dl>
        <div class="jh-cols is-space-15">
            <dl class="jh-list-form">
                <dt><label class="is-required">아이템명</label></dt>
                <dd><v-text-field class="jh-form" placeholder="아이템명"></v-text-field></dd>
            </dl>
            <dl class="jh-list-form">
                <dt><label class="is-required">아이템명</label></dt>
                <dd><v-select class="jh-form" :items="items"></v-select></dd>
            </dl>
        </div>
        <dl class="jh-list-form">
            <dt><label class="is-required">아이템명</label></dt>
            <dd><v-textarea class="jh-form" noResize placeholder="상담내용을 입력하세요." style="height:120px" ></v-textarea></dd>
        </dl>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div
      class="guide-card is-mt-40"             
        outlined>
        <dl class="jh-list-form">
            <dt><label class="is-required">아이템명</label></dt>
            <dd>
                <v-text-field class="jh-form" placeholder="아이템명"></v-text-field>
                <p class="jh-info-txt">도움말입니다.</p>
            </dd>
            <dt><label class="is-required">아이템명</label></dt>
            <dd>
                <v-select class="jh-form" :items="items"></v-select>
                <p class="jh-info-txt">도움말입니다.</p>
            </dd>
        </dl>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div
      class="guide-card is-mt-40"             
        outlined>
        <dl class="jh-list-form">
            <dt><label class="is-required">아이템명</label></dt>
            <dd class="is-block">
                <v-text-field class="jh-form" placeholder="아이템명"></v-text-field>
                <p class="jh-info-txt">도움말입니다.</p>
            </dd>
            <dt><label class="is-required">아이템명</label></dt>
            <dd class="is-block">
                <v-select class="jh-form" :items="items"></v-select>
                <p class="jh-info-txt">도움말입니다.</p>
            </dd>
        </dl>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>
      
      <div
      class="guide-card is-mt-40"             
        outlined>
        <dl class="jh-list-form">
            <dt><label class="is-required">아이템명</label></dt>
            <dd class="is-block">
                <v-text-field class="jh-form" placeholder="아이템명"></v-text-field>                
            </dd>
            <dd><p class="jh-info-txt">도움말입니다.</p></dd>
            <dt><label class="is-required">아이템명</label></dt>
            <dd class="is-block">
                <v-select class="jh-form" :items="items"></v-select>
            </dd>
            <dd><p class="jh-info-txt">도움말입니다.</p></dd>
        </dl>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>
      
      <div
      class="guide-card is-mt-40"             
        outlined>
        <dl class="jh-list-form">
            <dt><label class="is-required">아이템명</label></dt>
            <dd>
                <v-text-field class="jh-form" placeholder="아이템명"></v-text-field>                
            </dd>
            <dt><label class="is-required">아이템명</label></dt>
            <dd>
                <v-select class="jh-form" :items="items"></v-select>
            </dd>
        </dl>
        <div class="jh-info-txt is-mt-10">도움말입니다.</div>
        <div class="jh-btn-area">
            <button type="button" class="jh-btn is-md">취소</button>
            <button type="button" class="jh-btn is-md is-main">저장</button>
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "ListForm",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {    
        dialog: false,
        items: [],
        
        source01: 'HTML',
        content:{

source01_tab01: `<dl class="jh-list-form">
    <dt><label class="is-required">아이템명</label></dt>
    <dd><v-text-field class="jh-form" placeholder="아이템명"></v-text-field></dd>
    <dt><label class="is-required">아이템명</label></dt>
    <dd><v-text-field class="jh-form" placeholder="아이템명"></v-text-field></dd>
</dl>
<div class="jh-cols is-space-15">
    <dl class="jh-list-form">
        <dt><label class="is-required">아이템명</label></dt>
        <dd><v-text-field class="jh-form" placeholder="아이템명"></v-text-field></dd>
    </dl>
    <dl class="jh-list-form">
        <dt><label class="is-required">아이템명</label></dt>
        <dd><v-select class="jh-form" :items="items"></v-select></dd>
    </dl>
</div>
<dl class="jh-list-form">
    <dt><label class="is-required">아이템명</label></dt>
    <dd><v-textarea class="jh-form" noResize label="상담내용을 입력하세요." style="height:120px" ></v-textarea></dd>
</dl>`
,source02_tab01: `<dl class="jh-list-form">
    <dt><label class="is-required">아이템명</label></dt>
    <dd>
        <v-text-field class="jh-form" placeholder="아이템명"></v-text-field>
        <p class="jh-info-txt">도움말입니다.</p>
    </dd>
    <dt><label class="is-required">아이템명</label></dt>
    <dd>
        <v-select class="jh-form" :items="items"></v-select>
        <p class="jh-info-txt">도움말입니다.</p>
    </dd>
</dl>`
,source03_tab01: `<dl class="jh-list-form">
    <dt><label class="is-required">아이템명</label></dt>
    <dd class="is-block">
        <v-text-field class="jh-form" placeholder="아이템명"></v-text-field>
        <p class="jh-info-txt">도움말입니다.</p>
    </dd>
    <dt><label class="is-required">아이템명</label></dt>
    <dd class="is-block">
        <v-select :items="items"></v-select>
        <p class="jh-info-txt">도움말입니다.</p>
    </dd>
</dl>`
,source04_tab01: `<dl class="jh-list-form">
    <dt><label class="is-required">아이템명</label></dt>
    <dd class="is-block">
        <v-text-field class="jh-form" placeholder="아이템명"></v-text-field>                
    </dd>
    <dd><p class="jh-info-txt">도움말입니다.</p></dd>
    <dt><label class="is-required">아이템명</label></dt>
    <dd class="is-block">
        <v-select class="jh-form" :items="items"></v-select>
    </dd>
    <dd><p class="jh-info-txt">도움말입니다.</p></dd>
</dl>`
,source05_tab01: `<dl class="jh-list-form">
    <dt><label class="is-required">아이템명</label></dt>
    <dd>
        <v-text-field class="jh-form" placeholder="아이템명"></v-text-field>                
    </dd>
    <dt><label class="is-required">아이템명</label></dt>
    <dd>
        <v-select class="jh-form" :items="items"></v-select>
    </dd>
</dl>
<div class="jh-info-txt is-mt-10">도움말입니다.</div>
<div class="jh-btn-area">
    <button type="button" class="jh-btn is-md">취소</button>
    <button type="button" class="jh-btn is-md is-main">저장</button>
</div>`
      }
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>