<template>
  
  <div class="jh-cols">
    <!--목록-->
    <div class="jh-form-wrap" style="max-width: 40%;">
      <div class="jh-ui-header">
          <h2 class="has-bullet">지식정보 리스트</h2>
      </div>
      <div class="jh-search-form is-mb-5">
        <table>
          <colgroup>
            <col width="110px">
            <col>
          </colgroup>
          <tr>
            <th>
              <v-select class="jh-form" :items="clasItem" item-value="CD" item-text="NM" placeholder="선택" v-model="classValue"></v-select>
            </th>
            <td>
              <template v-if="classValue=='02'">
              <v-text-field clearable class="jh-form" v-model="searchTitle" @keypress.enter="search">          
                <template v-slot:append>
                  <v-btn class="jh-btn is-icon"><i class="jh-icon-search-sm is-blue" @click="search"></i></v-btn>
                </template>
              </v-text-field>
              </template>
              <template v-else>
                <v-select class="jh-form" :items="classItem" item-value="text" item-text="text" placeholder="선택" v-model="searchClass" @change="search"></v-select>
              </template>
            </td>
            
          </tr>
        </table>
      </div>
      <data-tables
        ref="dataTable"
        :data-table-options="dataTableOptions"
        @click:row="onClickRow"
        :paginationOptions="paginationOptions"
        v-model="selectedRows"
      />
    </div>
    <!--상세-->
    <div class="jh-form-wrap">
      <div class="jh-ui-header">
          <h2 class="has-bullet">지식 상세정보</h2>
          <div class="is-right">
          </div>
      </div>
      <table class="jh-tbl-detail">
        <colgroup>
          <col width="20%">
          <col width="30%">
          <col width="20%">
          <col>
        </colgroup>
        <tbody>
          <!--
          <tr>
              <th><label>지식정보ID</label></th>
              <td>
                <span class="is-value">{{ !knowledgeDetail.ID ? "" : knowledgeDetail.ID }}</span>
              </td>
              <th><label>대분류</label></th>
              <td>
                <span class="is-value">{{ knowledgeDetail.MAIN_CTGR_NM }}</span>
              </td>
          </tr>
          -->
          <tr>
              <th><label>등록자</label></th>
              <td>
                <span class="is-value">{{ knowledgeDetail.REG_NM }}</span>
              </td>
              <th><label>분류</label></th>
              <td>
                <span class="is-value">{{ knowledgeDetail.MAIN_CTGR_NM }}</span>
              </td>
          </tr>
          <tr>
              <th><label>수정자</label></th>
              <td>
                <span class="is-value">{{ knowledgeDetail.UPD_NM }}</span>
              </td>
              <th><label>수정일시</label></th>
              <td>
                <span class="is-value">{{ knowledgeDetail.UPD_DTTM }}</span>
              </td>
          </tr>
          <tr>
              <th><label>지식정보명</label></th>
              <td colspan="3">
                <span class="is-value">{{ knowledgeDetail.TITLE }}</span>
              </td>
          </tr>
        </tbody>
      </table>
      <div class="jh-editor is-mt-5"><ckeditor v-model="knowledgeDetail.CONTENT" :config="editorConfig" :read-only="true"></ckeditor></div>
    </div>
  </div>
</template>

<script>
  import { mixin } from "@/mixin/mixin.js";
  import dataTables from "@/components/DataTables";

  export default {
    name: "MENU_T100901", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dataTables        
    },
    props: [ "evt" ],
    data() {
      return {
        searchTitle: null,
        searchClass: '',
        clasItem:[
          {
            'NM' : '지식정보명',
            'CD': '02'
          },
          {
            'NM' : '분류',
            'CD': '01'
          },
        ],
        classItem:[
          {
            'NM' : '전체',
            'CD': null
          },
        ],
        widthFlag:0, //0 : 1040px, 1 : 1800px
        widthStyle:'width:1040px;',
        textStyle: 'width:550px;',

        classValue:'02',
        items: [
        ],
        dataTableOptions: {
          fixedHeader: true,
          headers: [
            { text: "분류", value: "MAIN_CTGR_NM", align: "center", width: "120px" },
            { text: "지식정보명", value: "TITLE" },
          ],
          height: "calc(100vh - 240px)",
          hideDefaultFooter: true,
          itemKey: "ID",
          itemsPerPage: 50,
          noDataText: "조회된 결과가 없습니다.",
          page: 1,
          singleSelect: true,

          load: this.getList
        },
        paginationOptions: {
          totalVisible: 7
        },

        editorConfig: {
          extraPlugins: "font, colorbutton, colordialog, openlink, spacingsliders",
          height: "calc(100vh - 310px)",
          openlink_enableReadOnly: true,
          openlink_modifier: 0,
          toolbar: [
            // { name: "clipboard", items: [ "Cut", "Copy", "Paste", "-", "Undo", "Redo" ] },//, "PasteText", "PasteFromWord"
            // { name: "editing" }, //, items: [ "Scayt" ]
            { name: "links", items: [ "Link", "Unlink" ] },//, "Anchor"
            { name: "insert", items: [ "Image", "Table", "HorizontalRule", "SpecialChar" ] },
            { name: "forms" },
            { name: "styles", items: [ "Font", "FontSize" ] },
            { name: "styles", items: [ "Styles", "Format" ] },
            { name: "others", items: [ "-" ] },
            "/",
            { name: "colors", items: [ "TextColor", "BGColor", "spacingsliders" ] },
            { name: "basicstyles", items: [ "Bold", "Italic", "Strike" ] }, //, "-", "RemoveFormat"
            { name: "paragraph", items: [ "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote" ] },
            { name: "document", items: [ "Source" ] },
            { name: "tools", items: [ "Maximize" ] },
            { name: "about", items: [] }
          ],
          toolbarCanCollapse:  true,
          toolbarStartupExpanded: false,
        },

        requestData: {
          headers: {
              SERVICE: "knowledge",
              TYPE: "BIZ_SERVICE",
          },
          sendData: {},
        },

        selectedKey: null,
        selectedRows: [],

        knowledgeDetail: {
          ID: null,
          MAIN_CTGR_CD: null,
          MAIN_CTGR_NM: null,
          SUB_CTGR_CD: null,
          SUB_CTGR_NM: null,
          TITLE: null,
          CONTENT: null,
          REG_ID: null,
          REG_NM: null,
          UPD_ID: null,
          UPD_NM: null,
          UPD_DTTM: null
        }
      }
    },
    computed: {     
      dataTableInstance: function() {
        return this.$refs.dataTable;
      }
    },
    methods: {
      init: async function() {
        // 헤더 초기화 및 세팅
        this.setDataTableParams();

        let that = this;
        /*
        this.evt.$on("open-slide9", () => {
          // 슬라이드 열 때 실행
          that.search();
        });
        this.evt.$on("close-slide9", () => {
          // 슬라이드 닫을 때 실행
          that.reset();
        });
        */
        const resCategoryList = await this.getCategoryList();
        if (
            !this.mixin_isEmpty(resCategoryList) &&
            !resCategoryList.HEADER.ERROR_FLAG
        ) {
            this.classItem = [
                { text: "전체", value: null },
                ...resCategoryList.DATA
            ];
        }
      },
      getCategoryList: function(level, parentCode) {
          if (!level) level = "MAIN";
          
          // header 세팅
          let requestData = {
              headers: {
                  ASYNC: false,
                  METHOD: "list",
                  SERVICE: "knowledge",
                  TYPE: "BIZ_SERVICE",
                  URL: "/api/knowledge/category/list"
              },
              sendData: {},
          };

          // sendData 세팅
          requestData.sendData["LEVEL"] = level;
          requestData.sendData["PRNT_CD"] = parentCode;

          return this.common_postCall(requestData);
      },
      setDataTableParams: function() {
        // header 세팅
        this.requestData.headers.URL = "/api/knowledge/list";
        this.requestData.headers.METHOD = "list";
        this.requestData.headers.ASYNC = false;

        // sendData 세팅
        this.requestData.sendData.TITLE = (this.classValue=='02')? this.searchTitle : ((this.searchClass == '전체')? null:this.searchClass);
        this.requestData.sendData.CLASS = this.classValue;
      },
      textSizeControl: function(){
        if(this.widthFlag == 0){
          this.widthStyle = 'width:1640px;'
          this.textStyle = 'width:1150px;'
          this.widthFlag = 1
        } else {
          this.widthStyle = 'width:1040px;'
          this.textStyle = 'width:550px;'
          this.widthFlag = 0
        }
      },
      getList: async function(loadOptions) {
        // 페이징 정보 세팅
        this.requestData.headers.ROW_CNT = loadOptions.itemsPerPage;
        this.requestData.headers.PAGES_CNT = loadOptions.page;
        if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
          this.requestData.sendData.SORT_ORDR = loadOptions.sortBy[0];
        else this.requestData.sendData.SORT_ORDR = undefined;
        if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
          this.requestData.sendData.SORT_DRCT = loadOptions.sortDesc[0] ? "desc" : "asc";
        else this.requestData.sendData.SORT_DRCT = undefined;

        return this.common_postCall(this.requestData).then((response) => {
          if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
            // this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
            return false;
          }

          let header = response.HEADER;
          let data = response.DATA;
          _.each(data, (item) => {
            item.TITLE = this.restoreXSS(item.TITLE);
            item.CONTENT = this.restoreXSS_CKeditor(item.CONTENT);
            // 이행 데이터 중 tag의 attribute의 쌍따옴표가 escape 문자로 변경된 경우가 존재하여 변환해줘야 정상 처리됨
            item.CONTENT = _.replace(item.CONTENT, /&quot;/gi, '"');
            item.UPD_DTTM = item.UPD_DTTM.substr(0, 19);
          });

          return {
            data: data,
            totalCount: header.TOT_COUNT,
          };
        });
      },
      search: function() {
          this.setDataTableParams();

          if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
          else this.dataTableInstance.loadData();
      },
      reset: function() {
        // 검색 값 초기화
        this.searchTitle = null;
        // 선택된 열 초기화
        this.selectedKey = null;
        this.selectedRows = [];
        // 상세 정보 초기화
        this.knowledgeDetail = {
          ID: null,
          MAIN_CTGR_CD: null,
          MAIN_CTGR_NM: null,
          SUB_CTGR_CD: null,
          SUB_CTGR_NM: null,
          TITLE: null,
          CONTENT: null,
          REG_ID: null,
          REG_NM: null,
          UPD_ID: null,
          UPD_NM: null,
          UPD_DTTM: null
        };
      },

      closeModal: function() {
        this.$parent.closeModal('slide9');
      },
      onClickRow: function(item, row) {
        if (this.selectedKey === item.ID) {
          row.select(false);
          this.selectedKey = null;
          this.knowledgeDetail = {
            ID: null,
            MAIN_CTGR_CD: null,
            MAIN_CTGR_NM: null,
            SUB_CTGR_CD: null,
            SUB_CTGR_NM: null,
            TITLE: null,
            CONTENT: null,
            REG_ID: null,
            REG_NM: null,
            UPD_ID: null,
            UPD_NM: null,
            UPD_DTTM: null
          };
        }
        else {
          row.select(true);
          this.selectedKey = item.ID;
          this.knowledgeDetail = Object.assign(this.knowledgeDetail, item);
        }
      },
    },
    created() {},
    mounted() {
      this.init();
    },
    watch: {}
  }
</script>

<style scoped>
</style>