const alertStore = {
  namespaced: true,
  state() {
    return{
      alert:{
        alertDialogToggle: false,
        alertDialogInfo: null,
        iconClass: null,
        msg: '',
        type: 'default',
        count: null,
        reserveCallTime: null,
        callYes: '',
        callNo: '',
      }

    }
  },
  getters: {
    GE_ALERT: (state) => state.alert,
  },
  mutations: {
    openAlertDialog(state,payload){
      state.alert = payload;
    },
  },
  actions: {

  }
};

export default alertStore;
