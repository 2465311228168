<template>
  <div class="jh-dialog-wrap">
    <div class="jh-dialog-header">
      <h1>{{ headerTitle }}</h1>
      <div class="is-right">
          <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
      </div>
    </div>
    <div class="jh-dialog-body">컨텐츠는 이곳에</div>
    <template v-if="footerSubmit">
      <div class="jh-dialog-footer">
        <v-btn class="jh-btn id-md is-main" @click="$emit('submit')" >{{ footerHideTitle }}</v-btn>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DialogDefault',
  props: {
    footerSubmit: {
      type: Boolean,
      default: true,
    },
    headerTitle: {
      type: String,
      default: '제목',
    },
    footerSubmitTitle: {
      type: String,
      default: '저장',
    },
  },
  data: () => ({
      footerHideTitle: '저장',
      defaultBodyContent: 'body slot 영역을 작성해주세요.',
  }),
}
</script>

<style>

</style>