<template>
  <div class="jh-login-frame">
    <div class="jh-login-form">
    <div ><h3><br></h3></div>    
      <div class="is-logo" title="EASYDIRECT"></div>
        <!-- <div ><img :src="src/views/imgSample/NH_logo.jpg"></div> -->

      <v-text-field
        name="input-10-1"
        placeholder="아이디를 입력하세요"
        class="jh-form is-login is-mb-10"       
        v-model="uid"
      ></v-text-field>
      <v-text-field
        :type="'password'"
        name="input-10-1"
        placeholder="비밀번호를 입력하세요"
        class="jh-form is-login"       
        v-model="upwd"
        @keyup.enter="getKey"
      ></v-text-field>
      <div class="is-check">
        <!-- <v-checkbox class="jh-check" label="CTI 사용" v-model="chk1"></v-checkbox>
        <v-checkbox class="jh-check" label="ID 저장"  v-model="chk2"></v-checkbox> -->
      </div>
      <v-btn class="jh-btn is-xl is-fill" @click="getKey">로그인</v-btn>
      <!-- <div class="jh-download-btn">
        <span class="jh-link-txt" @click="guideFileDownload">매뉴얼 다운로드</span>
        <span class="jh-split"></span>
        <span class="jh-link-txt" @click="sslFileDownload">인증서 다운로드</span>
      </div> -->
    </div>

    <!-- 비밀번호 변경 모달 -->
    <v-dialog
      max-width="400"
      persistent
      content-class="jh-dialog-wrap"
      v-model="dialogFindPass"
    >
      <div class="jh-dialog-header">
        <h1>비밀번호 변경</h1>
        <div class="is-right">
        </div>
      </div>
      <div class="jh-dialog-body">
        <dl class="jh-list-form">
          <dt><label class="">아이디</label></dt>
          <dd>
            <v-text-field class="jh-form is-lg" placeholder="아이템명" v-model="USER_ID" maxLength="18" disabled></v-text-field>                
          </dd>
          <dt><label class="is-required">기존 비밀번호</label></dt>
          <dd>
            <v-text-field type="password" class="jh-form is-lg" placeholder="기존 비밀번호" v-model="PASSWORD_OLD" maxLength="18"></v-text-field>  
          </dd>
          <dt><label class="is-required">새 비밀번호</label></dt>
          <dd>
            <v-text-field type="password" class="jh-form is-lg" placeholder="새 비밀번호" v-model="PASSWORD_NEW" maxLength="18"></v-text-field>  
          </dd>
          <dt><label class="is-required">새 비밀번호 재확인</label></dt>
          <dd>
            <v-text-field type="password" class="jh-form is-lg" placeholder="새 비밀번호 재확인" v-model="PASSWORD_NEW2" maxLength="18"></v-text-field>  
          </dd>
        </dl>
        <div class="jh-info-txt is-blue is-mt-10">영문, 숫자, 특수문자 혼합 8자리 이상</div>
        <div class="jh-info-txt is-blue is-mt-5" >특수문자는 ‘ !, @, #, $, & ’만 사용</div>
      </div>
      <div class="jh-dialog-footer">
        <v-btn class="jh-btn is-lg is-main" @click="checkPassword">비밀번호 변경</v-btn>
      </div>
    </v-dialog>
    <!--// 비밀번호 변경 모달 -->
  </div>
</template>

<script>
import api from "@/store/apiUtil";
import { mixin } from "@/mixin/mixin.js";
import { mapState, mapGetters, mapActions } from "vuex";
import { eventBus }  from "@/store/eventBus.js";

const sha256 = require("sha256");
const crypto = require("crypto");
// const GibberishAES = require("crypto");

export default {
  name: "MENU_Login",
  components: {

  },
  data: () => ({
    uid           : "",
    upwd          : "",
    B_DAY         : "",
    HP_NO         : "",    
    USER_ID       : "",
    ENCRYPT_KEY   : "",
    PASSWORD_OLD  : "",
    PASSWORD_NEW  : "",
    PASSWORD_NEW2 : "",

    AplyWorkList : "",

    dialogFindPass: false, // 비밀번호 변경 모달
    chk1: false,           // 체크박스: CTI 사용 초기값은 false
    chk2: true,            // 체크박스: ID 저장  초기값은 true
  }),

  mixins: [mixin],

  computed: {
    ...mapState({
      stateToken: "authStore/token",
    }),
    ...mapGetters({
      getterNumber: "authStore/GE_AUTH_TOKEN",
      loginResult: "authStore/GE_AUTH_LOGIN_RESULT",
    }),
    getName() {
      return this.userId + "님";
    },
  },
  mounted() {
    // 에러 코드 초기화
    if (this.$store.getters["commonStore/GE_COMMON_APP_ERROR_CODE"]) {
      this.$store.commit("commonStore/MU_COMMON_APP_ERROR_CODE", "");
    }

      // const w = new Worker('/worker2.js');

      // console.log("before worker data", w)

      // w.onmessage = function(event){
      //   console.log("worker result : ", event.data)
      // }
      // w.postMessage({"name" : "callVariable1"})

    
    // if (window.sessionStorage.getItem("token")) {
    //   this.$router.push("/E050101");
    // }
    this.init();
  },
  watch: {
    async loginResult(value) {


      // 로그인 성공
      if (value !== null) {
        if (value > 0) {
          this.common_toast("로그인 되었습니다.", "success");
          
          // CTI 사용 체크시 true, false 값 저장
          if (this.chk1) {
            this.setCookie("CTI_CHK", true, 3650);
          } else if (!this.chk1) {
            this.setCookie("CTI_CHK", false, 3650);
          }

          // ID 저장 체크시 ID, 체크박스 체크(true, false) 쿠키 저장, 만료기간-> 3650: 10년, 0: 저장안함 
          if (this.chk2) {
            this.setCookie("USER_ID", this.uid, 3650);
            this.setCookie("ID_CHK", this.chk2, 3650);
          } else if (!this.chk2) {
            this.setCookie("USER_ID", this.uid, 3650);
            this.setCookie("ID_CHK", this.chk2, 3650);
          }

          // 사용자 회사정보 조회
          await this.getCompany();

          // 사용자 메뉴권한 조회
          await this.getMenu();

          // 사용자 권한 조회
          let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;

          if(chkGroup.indexOf("노노!") != -1/*chkGroup == "이베스트관리자" || chkGroup == "시스템관리자"*/){
            // 캘린더/상장책자 미처리 건 조회
            this.selectAplyWork().then((response) => {
              if (response.data.HEADER.ERROR_FLAG == true){
                this.common_alert(this.alertMsg.responseError, "error");
                return false;
              }
              /*
              if(response.data.DATA.length > 0){
                this.common_confirmAplyWork("캘린더/책자 공통업무 미처리 건이 있습니다.<br>확인 부탁드립니다.", this.callLogin, null, "noti");
              } else {
                this.$store.dispatch("commonStore/AC_COMMON_APP_LOADING", true);
                this.$router.push("/E050101");
              }
              */
              //TST-CAL_000033 캘린더 신청_미처리건 있을 시_ 알럿 : 로그인화면이 아닌 HOME에서 띄우기
              if(response.data.DATA.length > 0){
                localStorage['CALENDER_UNTREATED'] = 'true';
              } else {
                localStorage['CALENDER_UNTREATED'] = 'false';
              }
              this.$store.dispatch("commonStore/AC_COMMON_APP_LOADING", true);


              // this.$router.push("/E050101");
              this.$router.push("/E999999");
            });
          } else {
            this.$store.dispatch("commonStore/AC_COMMON_APP_LOADING", true);
            // this.$router.push("/E050101");
            this.$router.push("/E999999");
          }
        } else if (value === 0) {
          // 로그인 실패
          this.common_toast("로그인을 실패하였습니다.", "error");
        } else {
          // 로그아웃 실패
          this.common_toast("로그아웃을 실패하였습니다.", "success");
        }
      } else {
        // 로그아웃 성공
        this.common_toast("로그아웃 되었습니다.", "success");
      }
    },
  },
  methods: {
    
      async guideFileDownload(){
        var item = {ASP_CUST_KEY: "001",
                    DNLOD_CNT: 1,
                    FILE_EXTS: "docx",
                    FILE_GROUP_KEY: "1682747119562-253797f86d154f5f8ac4ae3319b8fe91",
                    FILE_KEY: "1682747123744-df1c50f583ad4bba8dd2ad4d5cf37c1d",
                    FILE_SIZE: "689Kb",
                    ORIGINAL_FILENAME: "상담App사설 인증서 SSL 적용 가이드.docx",
                    SAVE_FILENAME: "1682747123726_ee719f02d3894ae7a238e8013ceeb63d",
                    }
        console.log("chs22:::::::::::", item);

        let busiType = "bbs";   //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage
        await this.mixin_loginFileDownload(item, busiType, pathType)

        var item = {ASP_CUST_KEY: "001",
                    DNLOD_CNT: 1,
                    FILE_EXTS: "docx",
                    FILE_GROUP_KEY: "1682747119562-253797f86d154f5f8ac4ae3319b8fe91",
                    FILE_KEY: "1682747128785-f6cdad83b95d460195becd56c17679f1",
                    FILE_SIZE: "536Kb",
                    ORIGINAL_FILENAME: "상담App캐시 및 쿠키삭제 가이드.docx",
                    SAVE_FILENAME: "1682747128745_b7558334f25a4a4a82ddd18cdb78adc7",
                    }
        console.log("chs22:::::::::::", item);

        await this.mixin_loginFileDownload(item, busiType, pathType)
        
        var item = {ASP_CUST_KEY: "001",
                    DNLOD_CNT: 1,
                    FILE_EXTS: "pptx",
                    FILE_GROUP_KEY: "1682747656196-e70ad55a27b14b7daf0f9d6be0dea3c3",
                    FILE_KEY: "1682747659060-84e534fd46294bc6894ee56f4a2318f9",
                    FILE_SIZE: "1652Kb",
                    ORIGINAL_FILENAME: "상담APP 사용자매뉴얼_V1.0.pptx",
                    SAVE_FILENAME: "1682747659046_37c1d7da23c14ecd8241a3c753255b88",
                    }
        console.log("chs22:::::::::::", item);

        await this.mixin_loginFileDownload(item, busiType, pathType)        
      },


      async sslFileDownload(){


        var item = {ASP_CUST_KEY: "001",
                    DNLOD_CNT: 1,
                    FILE_EXTS: "crt",
                    FILE_GROUP_KEY: "1680253287778-ecc926f6f9a9426cbe74e607d2c175c3",
                    FILE_KEY: "1680253296216-a1653bd63b364ef8b1a575df7d47c654",
                    FILE_SIZE: "1Kb",
                    ORIGINAL_FILENAME: "palette2.crt",
                    SAVE_FILENAME: "1680253296209_7bd825f9a8284fc481615a7a4d03d453",                 
                    }

        console.log("chs22:::::::::::", item);
        let busiType = "bbs";   //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage
        await this.mixin_loginFileDownload(item, busiType, pathType)

        // var item = {ASP_CUST_KEY: "001",
        //             DNLOD_CNT: 1,
        //             FILE_EXTS: "crt",
        //             FILE_GROUP_KEY: "1681209906149-6fda9fa6a2704b3cb153a8707b1da184",
        //             FILE_KEY: "1681209909731-567e51a15a734cd7b1602a4d63d89e8a",
        //             FILE_SIZE: "1Kb",
        //             ORIGINAL_FILENAME: "EB-FINPUB.ebipcc.com.cer",
        //             SAVE_FILENAME: "1681209909668_92e946b3bd5144eea5ec23953aeb4730",                 
        //             }

        // console.log("chs22:::::::::::", item);

        // await this.mixin_loginFileDownload(item, busiType, pathType)

        // var item = {ASP_CUST_KEY: "001",
        //             DNLOD_CNT: 1,
        //             FILE_EXTS: "crt",
        //             FILE_GROUP_KEY: "1681209906149-6fda9fa6a2704b3cb153a8707b1da184",
        //             FILE_KEY: "1681209915872-6e4481c9b27b4da49e3bbfb8d0a7c527",
        //             FILE_SIZE: "1Kb",
        //             ORIGINAL_FILENAME: "EB-FINSUB.ebipcc.com.cer",
        //             SAVE_FILENAME: "1681209915869_727fb248ada940258336bb36e902f7fc",                 
        //             }

        // console.log("chs22:::::::::::", item);

        // await this.mixin_loginFileDownload(item, busiType, pathType)


        var item = {ASP_CUST_KEY: "001",
                    DNLOD_CNT: 1,
                    FILE_EXTS: "cer",
                    FILE_GROUP_KEY: "1682144099525-99ba414213f5402a99472a9c9185d15a",
                    FILE_KEY: "1682144399690-7726d3286eba4f33b1adf22fabdc80df",
                    FILE_SIZE: "1Kb",
                    ORIGINAL_FILENAME: "CCEAdmin_CTI(Finesse).cer",
                    SAVE_FILENAME: "1682144399656_35b584d7e471498f85fe037767bb3c19",                 
                    }

        console.log("chs22:::::::::::", item);

        await this.mixin_loginFileDownload(item, busiType, pathType)        
      },
      
          // 비밀번호 변경 모달 아이디 초기 세팅
    init() {
      this.USER_ID       = this.uid;
      this.PASSWORD_OLD  = "";
      this.PASSWORD_NEW  = "";
      this.PASSWORD_NEW2 = "";

      // CTI 사용 체크시 true 값 저장
      // let cookie_CTIChk = this.getCookie("CTI_CHK");

      // if (cookie_CTIChk == "true") {
      //   this.chk1 = true;
      // } else if (cookie_CTIChk == "false") {
      //   this.chk1 = false;
      // } 

      // // 쿠키에 저장된 ID, 체크박스 체크 여부 불러온다.
      // let cookie_userid = this.getCookie("USER_ID");
      // let cookie_idchk  = this.getCookie("ID_CHK");

      // // 쿠키에 저장된 체크박스(true, false)에 따라 세팅
      // if (cookie_idchk == "false"){
      //   this.chk2 = false;
      //   this.uid = "";
      // } else if (cookie_idchk == "true") {
      //   this.chk2 = true;
      //   this.uid = cookie_userid;
      // } 
    },

    // 비밀번호 변경 모달 닫고 다시 열때 아이디 비번 초기 세팅
    checkdialogFindPass() {
      if (this.dialogFindPass == true) {
        this.dialogFindPass = false;
        this.init();
      } else {
        this.dialogFindPass = true;
        this.init();
      }
    },

    // 비밀번호 입력 체크
    checkPassword() {
      var pwNew = this.PASSWORD_NEW;

      var num = pwNew.search(/[0-9]/g);
      var eng = pwNew.search(/[a-z]/gi);
      // var spe = pwNew.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
      var spe = pwNew.search(/[`!@#$&|₩₩₩'₩";:₩/?]/gi);
      
      var HPNO = this.HP_NO.split("-");     // 핸드폰번호 ex) 010-1234-5678 -> HPNO[0]: 010 / HPNO[1]: 1234 / HPNO[2]: 5678
      var BDAY = "";                        // 생년월일   ex)        900101 -> 0101
      if(this.B_DAY.length != 6){
        BDAY = this.B_DAY;
      } else {
        BDAY = this.B_DAY.substr(2, 4);
      }

      if (this.PASSWORD_OLD == "" || this.PASSWORD_OLD == "undefined") {
        this.common_toast("비밀번호를 입력해주세요.", "error");
        return;
      } else if (this.PASSWORD_NEW != this.PASSWORD_NEW2) {
        this.common_toast("새 비밀번호와 비밀번호 확인이 일치하지 않습니다.", "error");
        return;
      } else if (pwNew.length < 8) {
        this.common_toast("8자리 이상 입력해주세요.", "error");
        return;
      } else if (pwNew.length > 20) {
        this.common_toast("20자리 이하로 입력해주세요.", "error");
        return;
      } else if (num < 0 || eng < 0 || spe < 0) {
        this.common_toast("영문, 숫자, 특수문자를 혼합하여 입력해주세요.", "error");
        return;
      } else if (pwNew.indexOf(this.USER_ID) > -1) {        
        this.common_toast("비밀번호는 사번을 포함할 수 없습니다.", "error");
        return;
      } else if ((this.HP_NO != null && this.HP_NO != "") && pwNew.indexOf(HPNO[1]) > -1 || pwNew.indexOf(HPNO[2]) > -1) {
        this.common_toast("비밀번호는 핸드폰번호를 포함할 수 없습니다.", "error");
        return;
      } else if ((this.B_DAY != null && this.B_DAY != "") && pwNew.indexOf(BDAY) > -1) {
        this.common_toast("비밀번호는 생년월일을 포함할 수 없습니다.", "error");
        return;
      } else if (pwNew.search(/\s/) != -1) {
        this.common_toast("비밀번호는 공백 없이 입력해주세요.", "error");
        return;
      } else {
        this.pwGetKey();
      }
    },

    // 비밀번호 암호화 키 호출
    pwGetKey() {
      api
        .post("api/login/password-encpt/process", {
          username: this.USER_ID,
        })
        .then((response) => {
          if (this.mixin_check_repsonse(response) === true) {
            if (response.data.DATA) {
              this.ENCRYPT_KEY = response.data.DATA[0].ENCRYPT_KEY;
              this.changePassword();
            } else {
              this.common_toast("비밀번호 변경을 실패하였습니다(암호화키 에러)", "error");
            }
          }
        })
        .catch((error) => {
          this.common_toast("비밀번호 변경을 실패하였습니다(암호화키 에러)", "error");
        });
    },

    // 비밀번호 변경 처리
    changePassword() {
      // 원본
      // const strPassWD_OLD = sha256(this.PASSWORD_OLD);
      // console.log("strPassWD_OLD : ", strPassWD_OLD);
      // let encPassWD_OLD = GibberishAES.aesEncrypt(
      //   strPassWD_OLD,
      //   this.ENCRYPT_KEY
      // ); //gibberish-aes.js
      // console.log("encPassWD_OLD : ", encPassWD_OLD);
      // //const password_NEW = this.PASSWORD_NEW;
      // const strPassWD_NEW = sha256(this.PASSWORD_NEW);
      // let encPassWD_NEW = GibberishAES.aesEncrypt(
      //   strPassWD_NEW,
      //   this.ENCRYPT_KEY
      // ); //gibberish-aes.js

      // 암호화 수정
      const strPassWD_OLD = sha256(this.PASSWORD_OLD);     
      let encPassWD_OLD = this.encryptAES_ECB(strPassWD_OLD, this.ENCRYPT_KEY); 
      
      const strPassWD_NEW = sha256(this.PASSWORD_NEW);
      let encPassWD_NEW = this.encryptAES_ECB(strPassWD_NEW, this.ENCRYPT_KEY); 

      const CHK_OLD_FLAG = "Y";
      const PWD_FAIL_FREQ = "0";
      const BIZ_DIV = "CHANGEPWD";
      const PWD_STATUS = "NORMAL";
      
      api
        .post("api/login/password/modify", {
          CHK_OLD_FLAG: CHK_OLD_FLAG,
          USER_ID: this.USER_ID,
          PWD: this.PASSWORD_OLD,
          PASSWORD_OLD: encPassWD_OLD,
          PASSWORD_NEW: encPassWD_NEW,
          PWD_FAIL_FREQ: PWD_FAIL_FREQ,
          BIZ_DIV: BIZ_DIV,
          PROC_ID: this.USER_ID,
          PWD_STATUS: PWD_STATUS,
          ENCRYPT_KEY: this.ENCRYPT_KEY,
        })
        .then((response) => {
          if (response.data.HEADER.ERROR_FLAG == true) {
            this.common_toast(
              "아이디 또는 비밀번호가 일치하지 않습니다.",
              "error"
            );
            return;
          } else {
            this.common_toast("정상적으로 수정되었습니다.", "success");
            this.dialogFindPass = false;
            // window.location.reload(true);  // 비밀번호 변경 후 새로고침
          }
          console.log(response.data);
          console.log(response.data.DATA);
        })
        .catch((error) => {
          // 에러인 경우 처리
          this.common_toast("비밀번호 변경 에러.", "error");
          console.error("비밀번호 변경 에러", error);
        });
    },

    // 로그인 시 암호화 키 호출
    getKey() {
      if(!this.uid) {
        this.common_toast("아이디를 입력해 주세요.", "error");
        return;
      }
      if(!this.upwd) {
        this.common_toast("비밀번호를 입력해 주세요.", "error");
        return;
      }
      api
        .post("api/login/password-encpt/process", {
          username: this.uid,
        })
        .then((response) => {
          if (this.mixin_check_repsonse(response) === true) {
            if (response.data.DATA) {
              this.ENCRYPT_KEY = response.data.DATA[0].ENCRYPT_KEY;

              // 호출된 암호화 키로 로그인 처리
              this.login();
            } else {
              this.common_toast(
                "로그인을 실패하였습니다(암호화키 에러)",
                "error"
              );
            }
          }
          // commit("MU_USER_MENU", response.data);
        })
        .catch((error) => {
          // 에러인 경우 처리
          // console.error("menu 조회 에러", error);
          this.common_toast("로그인을 실패하였습니다(암호화키 에러)", "error");
        });
    },

    // 비밀번호 변경전 임시 로그인 처리
    pwLogin() {
      //alert(this.PASSWORD_OLD);
      //alert(this.USER_ID);
      const password = this.PASSWORD_OLD;
      const strPassWD = sha256(password);
      console.log("strPassWD1111  :", strPassWD);
      
      // 원본 
      //let encPassWD = GibberishAES.aesEncrypt(strPassWD, this.ENCRYPT_KEY); //gibberish-aes.js

      // 암호화 수정
      let encPassWD = this.encryptAES_ECB(strPassWD, this.ENCRYPT_KEY);

      // 임시 테스트 데이터
      let set_data = {
        code: 0,
        data: data,
      };

      let response_data;

      api
        .post("api/login", {
          username: this.USER_ID,
          userpassword: encPassWD,
        })
        .then((response) => {
          set_data.code = 1;
          set_data.data = response.data.DATA[0];
          this.changePassword();
          response_data = response;
        })
        .catch((error) => {
          // 에러인 경우 처리
          set_data.code = 0;
          console.log("에러에러에러");
        });
    },

    // 로그인 처리
    login(relogin) {
      const password = this.upwd;
      const strPassWD = sha256(password);
      // 원본
      //let encPassWD = GibberishAES.aesEncrypt(strPassWD, this.ENCRYPT_KEY); //gibberish-aes.js

      // 암호화 수정
      let encPassWD = this.encryptAES_ECB(strPassWD, this.ENCRYPT_KEY);

      this.$store
        .dispatch("authStore/AC_AUTH_LOGIN", {
          username: this.uid,
          userpassword: encPassWD,
          relogin: true // 기존 로그인을 끊고 다시 로그인하는지 여부
        })
        .then((response) => {
          let result = this.mixin_check_repsonse(response);

          // 비밀번호 만료시 error.msg: Expired 리턴받아 비밀번호 변경 처리한다.
          if (result === "Expired"){
            this.B_DAY = response.data.DATA[0].BDAY;
            this.HP_NO = response.data.DATA[0].HPNO;          
            this.showConfirm();
          }
          // 이미 로그인되어 있거나 현재 클라이언트의 IP에 로그인된 아이디가 존재하면 기존 로그인을 끊고 로그인을 할지 선택하는 컨펌창 오픈
          else if ([ "LOGGED_IN_ID", "LOGGED_IN_IP" ].includes(result)) {
            this.openReLoginConfirm(result);
          }
          else if (result === true) {
            //사용자 정보 셋팅
            this.setUserDedail();


          }
          // 원본
          // if (this.mixin_check_repsonse(response) === true) {
          //   // setTimeout(() => {
          //     //사용자 정보 셋팅
          //     this.setUserDedail();
          //   // }, 200);
          // }
        });
    },
    // 기존 로그인을 끊고 로그인을 할지 선택하는 컨펌창 오픈
    openReLoginConfirm(result) {
      console.log("result", result )
      let msg = result == "LOGGED_IN_ID" ? "이미 로그인된 아이디입니다.<br>다시 로그인하시겠습니까?" : "동일한 IP에 로그인된 아이디가 존재합니다.<br>기존 연결을 끊고 로그인하시겠습니까?";
      
      this.common_confirm(msg, this.confirmReLogin, null, null, null, "noti");
    },
    confirmReLogin: function() {
      this.login(true);
    },
    // 비밀번호 만료 알림 메시지
    showConfirm() {
      this.common_confirmPwd("분기 비밀번호가 만료되었습니다.<br>비밀번호를 변경해 주세요.", this.callYes, null, "noti");
    },
    // 비밀번호 변경 클릭시 비밀번호 변경 팝업 띄워준다.
    callYes(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      this.dialogFindPass = true;
      this.init();
    },

    // 캘린더/상장책자 미처리 건 확인
    callLogin(){
      this.$store.dispatch("commonStore/AC_COMMON_APP_LOADING", true);
      // this.$router.push("/E050101");
      this.$router.push("/E999999");
      // window.location.reload(true);  // 화면 진입시 새로고침
    },

    // 사용자 정보 셋팅
    setUserDedail() {
      let getData = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"];
      this.$store
        .dispatch("userStore/AC_USER_DETAIL", {
          data: getData,
        })
        .then((response) => {
          // this.getCallIdCnt()
          // 버튼 권한 조회
          // this.mixin_common_button_auth("20210405154851920KC00302");
        });
    },

    async getCallIdCnt(){
      let requestData = { headers: {}, sendData: {} };

      requestData.headers["SERVICE"] = "phone.main.callId.count";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["ASYNC"] = "true";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["URL"] = "/api/phone/main/callId/count/inqire";
      requestData.sendData["USER_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;

      let cnslSaveCnt = await this.common_postCall(requestData);

      this.$store.dispatch('callStore/AC_CNSL_SAVE_CNT', cnslSaveCnt.DATA[0].cnt); //콜건수
    },

    // 사용자 회사 정보 조회
    async getCompany() {
      this.$store
        .dispatch("userStore/AC_USER_COMPANY", {
          user_id: this.uid,
        })
        .then((response) => {
          //사용자 상세정보 조회
          //조회시 asp_cust_key가 필요하기 때문에 company 조회 후 호출
          // this.getUserDedail();
        });
    },

    // 회사 채널 정보 조회
    getChannel() {
      let get_user_role = this.$store.getters["userStore/GE_USER_ROLE"];
      for (var i = 0; i < get_user_role.length; i++) {
        get_user_role.company[i];
      }
      // this.$store
      //   .dispatch("userStore/AC_USER_CHANNEL", {
      //     companyid: "3test3",
      //   })
      //   .then((response) => {
      //   });
    },

    // 사용자 메뉴 정보 조회
    async getMenu() {
      this.$store
        .dispatch("userStore/AC_USER_MENU", {
          user_id: this.uid,
        })
        .then((response) => {
          //사용자 회사정보 조회
          // this.getChannel();
        });
    },

    // 캘린더/상장책자 미처리 건 조회
    selectAplyWork(){
      return api
        .post(
          "/api/phone/cmpgn/aply-work/Loginlist",
          {                 
            STR_APLY_DT: [moment().format("YYYYMMDD")],       
            END_APLY_DT: [moment().format("YYYYMMDD")],
            PROC_RSLT_CD: "0",                          // 처리결과코드(0:미처리)
          },
          {
            head: {
              SERVICE: "phone.cmpgn.aply-work",
              METHOD : "select",
              TYPE   : "BIZ_SERVICE",
            },
          }
        )
    },

    // 매뉴얼 다운로드
    async manualDownload(){
      let item = {
        FILE_GROUP_KEY: "1663735641529-69312293c76f427abd55ff804be878c7",
        FILE_KEY      : "1663735646727-da2e92273ce54853a9389b50c78a751b",
        ASP_CUST_KEY  : "001"
      };

      // 로그인전이기 때문에 procId는 SYSTEM 으로 넘겨준다.
      await this.mixin_fileDownload(item, "bbs", "files", "SYSTEM");
    },
  
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },
  },
};

// 암호화 모듈
let GibberishAES = (function () {
  var Nr = 14,
    /* Default to 256 Bit Encryption */
    Nk = 8,
    Decrypt = false,
    enc_utf8 = function (s) {
      try {
        return unescape(encodeURIComponent(s));
      } catch (e) {
        throw "Error on UTF-8 encode";
      }
    },
    dec_utf8 = function (s) {
      try {
        return decodeURIComponent(escape(s));
      } catch (e) {
        throw "Bad Key";
      }
    },
    padBlock = function (byteArr) {
      var array = [],
        cpad,
        i;
      if (byteArr.length < 16) {
        cpad = 16 - byteArr.length;
        array = [
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
        ];
      }
      for (i = 0; i < byteArr.length; i++) {
        array[i] = byteArr[i];
      }
      return array;
    },
    block2s = function (block, lastBlock) {
      var string = "",
        padding,
        i;
      if (lastBlock) {
        padding = block[15];
        if (padding > 16) {
          throw "Decryption error: Maybe bad key";
        }
        if (padding == 16) {
          return "";
        }
        for (i = 0; i < 16 - padding; i++) {
          string += String.fromCharCode(block[i]);
        }
      } else {
        for (i = 0; i < 16; i++) {
          string += String.fromCharCode(block[i]);
        }
      }
      return string;
    },
    a2h = function (numArr) {
      var string = "",
        i;
      for (i = 0; i < numArr.length; i++) {
        string += (numArr[i] < 16 ? "0" : "") + numArr[i].toString(16);
      }
      return string;
    },
    h2a = function (s) {
      var ret = [];
      s.replace(/(..)/g, function (s) {
        ret.push(parseInt(s, 16));
      });
      return ret;
    },
    s2a = function (string, binary) {
      var array = [],
        i;

      if (!binary) {
        string = enc_utf8(string);
      }

      for (i = 0; i < string.length; i++) {
        array[i] = string.charCodeAt(i);
      }

      return array;
    },
    size = function (newsize) {
      switch (newsize) {
        case 128:
          Nr = 10;
          Nk = 4;
          break;
        case 192:
          Nr = 12;
          Nk = 6;
          break;
        case 256:
          Nr = 14;
          Nk = 8;
          break;
        default:
          throw "Invalid Key Size Specified:" + newsize;
      }
    },
    randArr = function (num) {
      var result = [],
        i;
      for (i = 0; i < num; i++) {
        result = result.concat(Math.floor(Math.random() * 256));
      }
      return result;
    },
    openSSLKey = function (passwordArr, saltArr) {
      // Number of rounds depends on the size of the AES in use
      // 3 rounds for 256
      //        2 rounds for the key, 1 for the IV
      // 2 rounds for 128
      //        1 round for the key, 1 round for the IV
      // 3 rounds for 192 since it's not evenly divided by 128 bits
      var rounds = Nr >= 12 ? 3 : 2,
        key = [],
        iv = [],
        md5_hash = [],
        result = [],
        data00 = passwordArr.concat(saltArr),
        i;
      md5_hash[0] = GibberishAES.Hash.MD5(data00);
      result = md5_hash[0];
      for (i = 1; i < rounds; i++) {
        md5_hash[i] = GibberishAES.Hash.MD5(md5_hash[i - 1].concat(data00));
        result = result.concat(md5_hash[i]);
      }
      key = result.slice(0, 4 * Nk);
      iv = result.slice(4 * Nk, 4 * Nk + 16);
      return {
        key: key,
        iv: iv,
      };
    },
    rawEncrypt = function (plaintext, key) {
      plaintext = GibberishAES.s2a(plaintext);
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // plaintext, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = Math.ceil(plaintext.length / 16),
        blocks = [],
        i,
        cipherBlocks = [];
      for (i = 0; i < numBlocks; i++) {
        blocks[i] = padBlock(plaintext.slice(i * 16, i * 16 + 16));
      }
      if (plaintext.length % 16 === 0) {
        blocks.push([
          16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16,
        ]);
        // CBC OpenSSL padding scheme
        numBlocks++;
      }
      for (i = 0; i < blocks.length; i++) {
        blocks[i] =
          i === 0
            ? xorBlocks(blocks[i], iv)
            : xorBlocks(blocks[i], cipherBlocks[i - 1]);
        cipherBlocks[i] = encryptBlock(blocks[i], key);
      }
      return GibberishAES.Base64.encode(cipherBlocks);
    },
    rawDecrypt = function (cryptArr, key) {
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      //  cryptArr, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = cryptArr.length / 16,
        cipherBlocks = [],
        i,
        plainBlocks = [],
        string = "";
      for (i = 0; i < numBlocks; i++) {
        cipherBlocks.push(cryptArr.slice(i * 16, (i + 1) * 16));
      }
      for (i = cipherBlocks.length - 1; i >= 0; i--) {
        plainBlocks[i] = decryptBlock(cipherBlocks[i], key);
        plainBlocks[i] =
          i === 0
            ? xorBlocks(plainBlocks[i], iv)
            : xorBlocks(plainBlocks[i], cipherBlocks[i - 1]);
      }
      for (i = 0; i < numBlocks - 1; i++) {
        string += block2s(plainBlocks[i]);
      }
      string += block2s(plainBlocks[i], true);

      return dec_utf8(string);
      //return binary ? string : dec_utf8(string);
    },
    aesEncrypt = function (plaintext, key) {
      var rtnVal = "";
      plaintext = GibberishAES.s2a(plaintext);
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // plaintext, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = Math.ceil(plaintext.length / 16),
        blocks = [],
        i,
        cipherBlocks = [];
      for (i = 0; i < numBlocks; i++) {
        blocks[i] = padBlock(plaintext.slice(i * 16, i * 16 + 16));
      }
      if (plaintext.length % 16 === 0) {
        blocks.push([
          16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16,
        ]);
        // CBC OpenSSL padding scheme
        numBlocks++;
      }
      for (i = 0; i < blocks.length; i++) {
        blocks[i] =
          i === 0
            ? xorBlocks(blocks[i], iv)
            : xorBlocks(blocks[i], cipherBlocks[i - 1]);
        cipherBlocks[i] = encryptBlock(blocks[i], key);
      }
      rtnVal = GibberishAES.Base64.encode(cipherBlocks);

      return rtnVal.toString().replace("\n", "");
    },
    aesDecrypt = function (base64Text, key) {
      var cryptArr = GibberishAES.Base64.decode(base64Text);
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      //  cryptArr, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = cryptArr.length / 16,
        cipherBlocks = [],
        i,
        plainBlocks = [],
        string = "";
      for (i = 0; i < numBlocks; i++) {
        cipherBlocks.push(cryptArr.slice(i * 16, (i + 1) * 16));
      }
      for (i = cipherBlocks.length - 1; i >= 0; i--) {
        plainBlocks[i] = decryptBlock(cipherBlocks[i], key);
        plainBlocks[i] =
          i === 0
            ? xorBlocks(plainBlocks[i], iv)
            : xorBlocks(plainBlocks[i], cipherBlocks[i - 1]);
      }
      for (i = 0; i < numBlocks - 1; i++) {
        string += block2s(plainBlocks[i]);
      }
      string += block2s(plainBlocks[i], true);

      return dec_utf8(string);
      //return binary ? string : dec_utf8(string);
    },
    encryptBlock = function (block, words) {
      Decrypt = false;
      var state = addRoundKey(block, words, 0),
        round;
      for (round = 1; round < Nr + 1; round++) {
        state = subBytes(state);
        state = shiftRows(state);
        if (round < Nr) {
          state = mixColumns(state);
        }
        //last round? don't mixColumns
        state = addRoundKey(state, words, round);
      }

      return state;
    },
    decryptBlock = function (block, words) {
      Decrypt = true;
      var state = addRoundKey(block, words, Nr),
        round;
      for (round = Nr - 1; round > -1; round--) {
        state = shiftRows(state);
        state = subBytes(state);
        state = addRoundKey(state, words, round);
        if (round > 0) {
          state = mixColumns(state);
        }
        //last round? don't mixColumns
      }

      return state;
    },
    subBytes = function (state) {
      var S = Decrypt ? SBoxInv : SBox,
        temp = [],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = S[state[i]];
      }
      return temp;
    },
    shiftRows = function (state) {
      var temp = [],
        shiftBy = Decrypt
          ? [0, 13, 10, 7, 4, 1, 14, 11, 8, 5, 2, 15, 12, 9, 6, 3]
          : [0, 5, 10, 15, 4, 9, 14, 3, 8, 13, 2, 7, 12, 1, 6, 11],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = state[shiftBy[i]];
      }
      return temp;
    },
    mixColumns = function (state) {
      var t = [],
        c;
      if (!Decrypt) {
        for (c = 0; c < 4; c++) {
          t[c * 4] =
            G2X[state[c * 4]] ^
            G3X[state[1 + c * 4]] ^
            state[2 + c * 4] ^
            state[3 + c * 4];
          t[1 + c * 4] =
            state[c * 4] ^
            G2X[state[1 + c * 4]] ^
            G3X[state[2 + c * 4]] ^
            state[3 + c * 4];
          t[2 + c * 4] =
            state[c * 4] ^
            state[1 + c * 4] ^
            G2X[state[2 + c * 4]] ^
            G3X[state[3 + c * 4]];
          t[3 + c * 4] =
            G3X[state[c * 4]] ^
            state[1 + c * 4] ^
            state[2 + c * 4] ^
            G2X[state[3 + c * 4]];
        }
      } else {
        for (c = 0; c < 4; c++) {
          t[c * 4] =
            GEX[state[c * 4]] ^
            GBX[state[1 + c * 4]] ^
            GDX[state[2 + c * 4]] ^
            G9X[state[3 + c * 4]];
          t[1 + c * 4] =
            G9X[state[c * 4]] ^
            GEX[state[1 + c * 4]] ^
            GBX[state[2 + c * 4]] ^
            GDX[state[3 + c * 4]];
          t[2 + c * 4] =
            GDX[state[c * 4]] ^
            G9X[state[1 + c * 4]] ^
            GEX[state[2 + c * 4]] ^
            GBX[state[3 + c * 4]];
          t[3 + c * 4] =
            GBX[state[c * 4]] ^
            GDX[state[1 + c * 4]] ^
            G9X[state[2 + c * 4]] ^
            GEX[state[3 + c * 4]];
        }
      }

      return t;
    },
    addRoundKey = function (state, words, round) {
      var temp = [],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = state[i] ^ words[round][i];
      }
      return temp;
    },
    xorBlocks = function (block1, block2) {
      var temp = [],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = block1[i] ^ block2[i];
      }
      return temp;
    },
    expandKey = function (key) {
      // Expects a 1d number array
      var w = [],
        temp = [],
        i,
        r,
        t,
        flat = [],
        j;

      for (i = 0; i < Nk; i++) {
        r = [key[4 * i], key[4 * i + 1], key[4 * i + 2], key[4 * i + 3]];
        w[i] = r;
      }

      for (i = Nk; i < 4 * (Nr + 1); i++) {
        w[i] = [];
        for (t = 0; t < 4; t++) {
          temp[t] = w[i - 1][t];
        }
        if (i % Nk === 0) {
          temp = subWord(rotWord(temp));
          temp[0] ^= Rcon[i / Nk - 1];
        } else if (Nk > 6 && i % Nk == 4) {
          temp = subWord(temp);
        }
        for (t = 0; t < 4; t++) {
          w[i][t] = w[i - Nk][t] ^ temp[t];
        }
      }
      for (i = 0; i < Nr + 1; i++) {
        flat[i] = [];
        for (j = 0; j < 4; j++) {
          flat[i].push(
            w[i * 4 + j][0],
            w[i * 4 + j][1],
            w[i * 4 + j][2],
            w[i * 4 + j][3]
          );
        }
      }
      return flat;
    },
    subWord = function (w) {
      // apply SBox to 4-byte word w
      for (var i = 0; i < 4; i++) {
        w[i] = SBox[w[i]];
      }
      return w;
    },
    rotWord = function (w) {
      // rotate 4-byte word w left by one byte
      var tmp = w[0],
        i;
      for (i = 0; i < 4; i++) {
        w[i] = w[i + 1];
      }
      w[3] = tmp;
      return w;
    },
    // S-box
    SBox = [
      99, 124, 119, 123, 242, 107, 111, 197, 48, 1, 103, 43, 254, 215, 171, 118,
      202, 130, 201, 125, 250, 89, 71, 240, 173, 212, 162, 175, 156, 164, 114,
      192, 183, 253, 147, 38, 54, 63, 247, 204, 52, 165, 229, 241, 113, 216, 49,
      21, 4, 199, 35, 195, 24, 150, 5, 154, 7, 18, 128, 226, 235, 39, 178, 117,
      9, 131, 44, 26, 27, 110, 90, 160, 82, 59, 214, 179, 41, 227, 47, 132, 83,
      209, 0, 237, 32, 252, 177, 91, 106, 203, 190, 57, 74, 76, 88, 207, 208,
      239, 170, 251, 67, 77, 51, 133, 69, 249, 2, 127, 80, 60, 159, 168, 81,
      163, 64, 143, 146, 157, 56, 245, 188, 182, 218, 33, 16, 255, 243, 210,
      205, 12, 19, 236, 95, 151, 68, 23, 196, 167, 126, 61, 100, 93, 25, 115,
      96, 129, 79, 220, 34, 42, 144, 136, 70, 238, 184, 20, 222, 94, 11, 219,
      224, 50, 58, 10, 73, 6, 36, 92, 194, 211, 172, 98, 145, 149, 228, 121,
      231, 200, 55, 109, 141, 213, 78, 169, 108, 86, 244, 234, 101, 122, 174, 8,
      186, 120, 37, 46, 28, 166, 180, 198, 232, 221, 116, 31, 75, 189, 139, 138,
      112, 62, 181, 102, 72, 3, 246, 14, 97, 53, 87, 185, 134, 193, 29, 158,
      225, 248, 152, 17, 105, 217, 142, 148, 155, 30, 135, 233, 206, 85, 40,
      223, 140, 161, 137, 13, 191, 230, 66, 104, 65, 153, 45, 15, 176, 84, 187,
      22,
    ],
    // Precomputed lookup table for the inverse SBox
    SBoxInv = [
      82, 9, 106, 213, 48, 54, 165, 56, 191, 64, 163, 158, 129, 243, 215, 251,
      124, 227, 57, 130, 155, 47, 255, 135, 52, 142, 67, 68, 196, 222, 233, 203,
      84, 123, 148, 50, 166, 194, 35, 61, 238, 76, 149, 11, 66, 250, 195, 78, 8,
      46, 161, 102, 40, 217, 36, 178, 118, 91, 162, 73, 109, 139, 209, 37, 114,
      248, 246, 100, 134, 104, 152, 22, 212, 164, 92, 204, 93, 101, 182, 146,
      108, 112, 72, 80, 253, 237, 185, 218, 94, 21, 70, 87, 167, 141, 157, 132,
      144, 216, 171, 0, 140, 188, 211, 10, 247, 228, 88, 5, 184, 179, 69, 6,
      208, 44, 30, 143, 202, 63, 15, 2, 193, 175, 189, 3, 1, 19, 138, 107, 58,
      145, 17, 65, 79, 103, 220, 234, 151, 242, 207, 206, 240, 180, 230, 115,
      150, 172, 116, 34, 231, 173, 53, 133, 226, 249, 55, 232, 28, 117, 223,
      110, 71, 241, 26, 113, 29, 41, 197, 137, 111, 183, 98, 14, 170, 24, 190,
      27, 252, 86, 62, 75, 198, 210, 121, 32, 154, 219, 192, 254, 120, 205, 90,
      244, 31, 221, 168, 51, 136, 7, 199, 49, 177, 18, 16, 89, 39, 128, 236, 95,
      96, 81, 127, 169, 25, 181, 74, 13, 45, 229, 122, 159, 147, 201, 156, 239,
      160, 224, 59, 77, 174, 42, 245, 176, 200, 235, 187, 60, 131, 83, 153, 97,
      23, 43, 4, 126, 186, 119, 214, 38, 225, 105, 20, 99, 85, 33, 12, 125,
    ],
    // Rijndael Rcon
    Rcon = [
      1, 2, 4, 8, 16, 32, 64, 128, 27, 54, 108, 216, 171, 77, 154, 47, 94, 188,
      99, 198, 151, 53, 106, 212, 179, 125, 250, 239, 197, 145,
    ],
    G2X = [
      0x00, 0x02, 0x04, 0x06, 0x08, 0x0a, 0x0c, 0x0e, 0x10, 0x12, 0x14, 0x16,
      0x18, 0x1a, 0x1c, 0x1e, 0x20, 0x22, 0x24, 0x26, 0x28, 0x2a, 0x2c, 0x2e,
      0x30, 0x32, 0x34, 0x36, 0x38, 0x3a, 0x3c, 0x3e, 0x40, 0x42, 0x44, 0x46,
      0x48, 0x4a, 0x4c, 0x4e, 0x50, 0x52, 0x54, 0x56, 0x58, 0x5a, 0x5c, 0x5e,
      0x60, 0x62, 0x64, 0x66, 0x68, 0x6a, 0x6c, 0x6e, 0x70, 0x72, 0x74, 0x76,
      0x78, 0x7a, 0x7c, 0x7e, 0x80, 0x82, 0x84, 0x86, 0x88, 0x8a, 0x8c, 0x8e,
      0x90, 0x92, 0x94, 0x96, 0x98, 0x9a, 0x9c, 0x9e, 0xa0, 0xa2, 0xa4, 0xa6,
      0xa8, 0xaa, 0xac, 0xae, 0xb0, 0xb2, 0xb4, 0xb6, 0xb8, 0xba, 0xbc, 0xbe,
      0xc0, 0xc2, 0xc4, 0xc6, 0xc8, 0xca, 0xcc, 0xce, 0xd0, 0xd2, 0xd4, 0xd6,
      0xd8, 0xda, 0xdc, 0xde, 0xe0, 0xe2, 0xe4, 0xe6, 0xe8, 0xea, 0xec, 0xee,
      0xf0, 0xf2, 0xf4, 0xf6, 0xf8, 0xfa, 0xfc, 0xfe, 0x1b, 0x19, 0x1f, 0x1d,
      0x13, 0x11, 0x17, 0x15, 0x0b, 0x09, 0x0f, 0x0d, 0x03, 0x01, 0x07, 0x05,
      0x3b, 0x39, 0x3f, 0x3d, 0x33, 0x31, 0x37, 0x35, 0x2b, 0x29, 0x2f, 0x2d,
      0x23, 0x21, 0x27, 0x25, 0x5b, 0x59, 0x5f, 0x5d, 0x53, 0x51, 0x57, 0x55,
      0x4b, 0x49, 0x4f, 0x4d, 0x43, 0x41, 0x47, 0x45, 0x7b, 0x79, 0x7f, 0x7d,
      0x73, 0x71, 0x77, 0x75, 0x6b, 0x69, 0x6f, 0x6d, 0x63, 0x61, 0x67, 0x65,
      0x9b, 0x99, 0x9f, 0x9d, 0x93, 0x91, 0x97, 0x95, 0x8b, 0x89, 0x8f, 0x8d,
      0x83, 0x81, 0x87, 0x85, 0xbb, 0xb9, 0xbf, 0xbd, 0xb3, 0xb1, 0xb7, 0xb5,
      0xab, 0xa9, 0xaf, 0xad, 0xa3, 0xa1, 0xa7, 0xa5, 0xdb, 0xd9, 0xdf, 0xdd,
      0xd3, 0xd1, 0xd7, 0xd5, 0xcb, 0xc9, 0xcf, 0xcd, 0xc3, 0xc1, 0xc7, 0xc5,
      0xfb, 0xf9, 0xff, 0xfd, 0xf3, 0xf1, 0xf7, 0xf5, 0xeb, 0xe9, 0xef, 0xed,
      0xe3, 0xe1, 0xe7, 0xe5,
    ],
    G3X = [
      0x00, 0x03, 0x06, 0x05, 0x0c, 0x0f, 0x0a, 0x09, 0x18, 0x1b, 0x1e, 0x1d,
      0x14, 0x17, 0x12, 0x11, 0x30, 0x33, 0x36, 0x35, 0x3c, 0x3f, 0x3a, 0x39,
      0x28, 0x2b, 0x2e, 0x2d, 0x24, 0x27, 0x22, 0x21, 0x60, 0x63, 0x66, 0x65,
      0x6c, 0x6f, 0x6a, 0x69, 0x78, 0x7b, 0x7e, 0x7d, 0x74, 0x77, 0x72, 0x71,
      0x50, 0x53, 0x56, 0x55, 0x5c, 0x5f, 0x5a, 0x59, 0x48, 0x4b, 0x4e, 0x4d,
      0x44, 0x47, 0x42, 0x41, 0xc0, 0xc3, 0xc6, 0xc5, 0xcc, 0xcf, 0xca, 0xc9,
      0xd8, 0xdb, 0xde, 0xdd, 0xd4, 0xd7, 0xd2, 0xd1, 0xf0, 0xf3, 0xf6, 0xf5,
      0xfc, 0xff, 0xfa, 0xf9, 0xe8, 0xeb, 0xee, 0xed, 0xe4, 0xe7, 0xe2, 0xe1,
      0xa0, 0xa3, 0xa6, 0xa5, 0xac, 0xaf, 0xaa, 0xa9, 0xb8, 0xbb, 0xbe, 0xbd,
      0xb4, 0xb7, 0xb2, 0xb1, 0x90, 0x93, 0x96, 0x95, 0x9c, 0x9f, 0x9a, 0x99,
      0x88, 0x8b, 0x8e, 0x8d, 0x84, 0x87, 0x82, 0x81, 0x9b, 0x98, 0x9d, 0x9e,
      0x97, 0x94, 0x91, 0x92, 0x83, 0x80, 0x85, 0x86, 0x8f, 0x8c, 0x89, 0x8a,
      0xab, 0xa8, 0xad, 0xae, 0xa7, 0xa4, 0xa1, 0xa2, 0xb3, 0xb0, 0xb5, 0xb6,
      0xbf, 0xbc, 0xb9, 0xba, 0xfb, 0xf8, 0xfd, 0xfe, 0xf7, 0xf4, 0xf1, 0xf2,
      0xe3, 0xe0, 0xe5, 0xe6, 0xef, 0xec, 0xe9, 0xea, 0xcb, 0xc8, 0xcd, 0xce,
      0xc7, 0xc4, 0xc1, 0xc2, 0xd3, 0xd0, 0xd5, 0xd6, 0xdf, 0xdc, 0xd9, 0xda,
      0x5b, 0x58, 0x5d, 0x5e, 0x57, 0x54, 0x51, 0x52, 0x43, 0x40, 0x45, 0x46,
      0x4f, 0x4c, 0x49, 0x4a, 0x6b, 0x68, 0x6d, 0x6e, 0x67, 0x64, 0x61, 0x62,
      0x73, 0x70, 0x75, 0x76, 0x7f, 0x7c, 0x79, 0x7a, 0x3b, 0x38, 0x3d, 0x3e,
      0x37, 0x34, 0x31, 0x32, 0x23, 0x20, 0x25, 0x26, 0x2f, 0x2c, 0x29, 0x2a,
      0x0b, 0x08, 0x0d, 0x0e, 0x07, 0x04, 0x01, 0x02, 0x13, 0x10, 0x15, 0x16,
      0x1f, 0x1c, 0x19, 0x1a,
    ],
    G9X = [
      0x00, 0x09, 0x12, 0x1b, 0x24, 0x2d, 0x36, 0x3f, 0x48, 0x41, 0x5a, 0x53,
      0x6c, 0x65, 0x7e, 0x77, 0x90, 0x99, 0x82, 0x8b, 0xb4, 0xbd, 0xa6, 0xaf,
      0xd8, 0xd1, 0xca, 0xc3, 0xfc, 0xf5, 0xee, 0xe7, 0x3b, 0x32, 0x29, 0x20,
      0x1f, 0x16, 0x0d, 0x04, 0x73, 0x7a, 0x61, 0x68, 0x57, 0x5e, 0x45, 0x4c,
      0xab, 0xa2, 0xb9, 0xb0, 0x8f, 0x86, 0x9d, 0x94, 0xe3, 0xea, 0xf1, 0xf8,
      0xc7, 0xce, 0xd5, 0xdc, 0x76, 0x7f, 0x64, 0x6d, 0x52, 0x5b, 0x40, 0x49,
      0x3e, 0x37, 0x2c, 0x25, 0x1a, 0x13, 0x08, 0x01, 0xe6, 0xef, 0xf4, 0xfd,
      0xc2, 0xcb, 0xd0, 0xd9, 0xae, 0xa7, 0xbc, 0xb5, 0x8a, 0x83, 0x98, 0x91,
      0x4d, 0x44, 0x5f, 0x56, 0x69, 0x60, 0x7b, 0x72, 0x05, 0x0c, 0x17, 0x1e,
      0x21, 0x28, 0x33, 0x3a, 0xdd, 0xd4, 0xcf, 0xc6, 0xf9, 0xf0, 0xeb, 0xe2,
      0x95, 0x9c, 0x87, 0x8e, 0xb1, 0xb8, 0xa3, 0xaa, 0xec, 0xe5, 0xfe, 0xf7,
      0xc8, 0xc1, 0xda, 0xd3, 0xa4, 0xad, 0xb6, 0xbf, 0x80, 0x89, 0x92, 0x9b,
      0x7c, 0x75, 0x6e, 0x67, 0x58, 0x51, 0x4a, 0x43, 0x34, 0x3d, 0x26, 0x2f,
      0x10, 0x19, 0x02, 0x0b, 0xd7, 0xde, 0xc5, 0xcc, 0xf3, 0xfa, 0xe1, 0xe8,
      0x9f, 0x96, 0x8d, 0x84, 0xbb, 0xb2, 0xa9, 0xa0, 0x47, 0x4e, 0x55, 0x5c,
      0x63, 0x6a, 0x71, 0x78, 0x0f, 0x06, 0x1d, 0x14, 0x2b, 0x22, 0x39, 0x30,
      0x9a, 0x93, 0x88, 0x81, 0xbe, 0xb7, 0xac, 0xa5, 0xd2, 0xdb, 0xc0, 0xc9,
      0xf6, 0xff, 0xe4, 0xed, 0x0a, 0x03, 0x18, 0x11, 0x2e, 0x27, 0x3c, 0x35,
      0x42, 0x4b, 0x50, 0x59, 0x66, 0x6f, 0x74, 0x7d, 0xa1, 0xa8, 0xb3, 0xba,
      0x85, 0x8c, 0x97, 0x9e, 0xe9, 0xe0, 0xfb, 0xf2, 0xcd, 0xc4, 0xdf, 0xd6,
      0x31, 0x38, 0x23, 0x2a, 0x15, 0x1c, 0x07, 0x0e, 0x79, 0x70, 0x6b, 0x62,
      0x5d, 0x54, 0x4f, 0x46,
    ],
    GBX = [
      0x00, 0x0b, 0x16, 0x1d, 0x2c, 0x27, 0x3a, 0x31, 0x58, 0x53, 0x4e, 0x45,
      0x74, 0x7f, 0x62, 0x69, 0xb0, 0xbb, 0xa6, 0xad, 0x9c, 0x97, 0x8a, 0x81,
      0xe8, 0xe3, 0xfe, 0xf5, 0xc4, 0xcf, 0xd2, 0xd9, 0x7b, 0x70, 0x6d, 0x66,
      0x57, 0x5c, 0x41, 0x4a, 0x23, 0x28, 0x35, 0x3e, 0x0f, 0x04, 0x19, 0x12,
      0xcb, 0xc0, 0xdd, 0xd6, 0xe7, 0xec, 0xf1, 0xfa, 0x93, 0x98, 0x85, 0x8e,
      0xbf, 0xb4, 0xa9, 0xa2, 0xf6, 0xfd, 0xe0, 0xeb, 0xda, 0xd1, 0xcc, 0xc7,
      0xae, 0xa5, 0xb8, 0xb3, 0x82, 0x89, 0x94, 0x9f, 0x46, 0x4d, 0x50, 0x5b,
      0x6a, 0x61, 0x7c, 0x77, 0x1e, 0x15, 0x08, 0x03, 0x32, 0x39, 0x24, 0x2f,
      0x8d, 0x86, 0x9b, 0x90, 0xa1, 0xaa, 0xb7, 0xbc, 0xd5, 0xde, 0xc3, 0xc8,
      0xf9, 0xf2, 0xef, 0xe4, 0x3d, 0x36, 0x2b, 0x20, 0x11, 0x1a, 0x07, 0x0c,
      0x65, 0x6e, 0x73, 0x78, 0x49, 0x42, 0x5f, 0x54, 0xf7, 0xfc, 0xe1, 0xea,
      0xdb, 0xd0, 0xcd, 0xc6, 0xaf, 0xa4, 0xb9, 0xb2, 0x83, 0x88, 0x95, 0x9e,
      0x47, 0x4c, 0x51, 0x5a, 0x6b, 0x60, 0x7d, 0x76, 0x1f, 0x14, 0x09, 0x02,
      0x33, 0x38, 0x25, 0x2e, 0x8c, 0x87, 0x9a, 0x91, 0xa0, 0xab, 0xb6, 0xbd,
      0xd4, 0xdf, 0xc2, 0xc9, 0xf8, 0xf3, 0xee, 0xe5, 0x3c, 0x37, 0x2a, 0x21,
      0x10, 0x1b, 0x06, 0x0d, 0x64, 0x6f, 0x72, 0x79, 0x48, 0x43, 0x5e, 0x55,
      0x01, 0x0a, 0x17, 0x1c, 0x2d, 0x26, 0x3b, 0x30, 0x59, 0x52, 0x4f, 0x44,
      0x75, 0x7e, 0x63, 0x68, 0xb1, 0xba, 0xa7, 0xac, 0x9d, 0x96, 0x8b, 0x80,
      0xe9, 0xe2, 0xff, 0xf4, 0xc5, 0xce, 0xd3, 0xd8, 0x7a, 0x71, 0x6c, 0x67,
      0x56, 0x5d, 0x40, 0x4b, 0x22, 0x29, 0x34, 0x3f, 0x0e, 0x05, 0x18, 0x13,
      0xca, 0xc1, 0xdc, 0xd7, 0xe6, 0xed, 0xf0, 0xfb, 0x92, 0x99, 0x84, 0x8f,
      0xbe, 0xb5, 0xa8, 0xa3,
    ],
    GDX = [
      0x00, 0x0d, 0x1a, 0x17, 0x34, 0x39, 0x2e, 0x23, 0x68, 0x65, 0x72, 0x7f,
      0x5c, 0x51, 0x46, 0x4b, 0xd0, 0xdd, 0xca, 0xc7, 0xe4, 0xe9, 0xfe, 0xf3,
      0xb8, 0xb5, 0xa2, 0xaf, 0x8c, 0x81, 0x96, 0x9b, 0xbb, 0xb6, 0xa1, 0xac,
      0x8f, 0x82, 0x95, 0x98, 0xd3, 0xde, 0xc9, 0xc4, 0xe7, 0xea, 0xfd, 0xf0,
      0x6b, 0x66, 0x71, 0x7c, 0x5f, 0x52, 0x45, 0x48, 0x03, 0x0e, 0x19, 0x14,
      0x37, 0x3a, 0x2d, 0x20, 0x6d, 0x60, 0x77, 0x7a, 0x59, 0x54, 0x43, 0x4e,
      0x05, 0x08, 0x1f, 0x12, 0x31, 0x3c, 0x2b, 0x26, 0xbd, 0xb0, 0xa7, 0xaa,
      0x89, 0x84, 0x93, 0x9e, 0xd5, 0xd8, 0xcf, 0xc2, 0xe1, 0xec, 0xfb, 0xf6,
      0xd6, 0xdb, 0xcc, 0xc1, 0xe2, 0xef, 0xf8, 0xf5, 0xbe, 0xb3, 0xa4, 0xa9,
      0x8a, 0x87, 0x90, 0x9d, 0x06, 0x0b, 0x1c, 0x11, 0x32, 0x3f, 0x28, 0x25,
      0x6e, 0x63, 0x74, 0x79, 0x5a, 0x57, 0x40, 0x4d, 0xda, 0xd7, 0xc0, 0xcd,
      0xee, 0xe3, 0xf4, 0xf9, 0xb2, 0xbf, 0xa8, 0xa5, 0x86, 0x8b, 0x9c, 0x91,
      0x0a, 0x07, 0x10, 0x1d, 0x3e, 0x33, 0x24, 0x29, 0x62, 0x6f, 0x78, 0x75,
      0x56, 0x5b, 0x4c, 0x41, 0x61, 0x6c, 0x7b, 0x76, 0x55, 0x58, 0x4f, 0x42,
      0x09, 0x04, 0x13, 0x1e, 0x3d, 0x30, 0x27, 0x2a, 0xb1, 0xbc, 0xab, 0xa6,
      0x85, 0x88, 0x9f, 0x92, 0xd9, 0xd4, 0xc3, 0xce, 0xed, 0xe0, 0xf7, 0xfa,
      0xb7, 0xba, 0xad, 0xa0, 0x83, 0x8e, 0x99, 0x94, 0xdf, 0xd2, 0xc5, 0xc8,
      0xeb, 0xe6, 0xf1, 0xfc, 0x67, 0x6a, 0x7d, 0x70, 0x53, 0x5e, 0x49, 0x44,
      0x0f, 0x02, 0x15, 0x18, 0x3b, 0x36, 0x21, 0x2c, 0x0c, 0x01, 0x16, 0x1b,
      0x38, 0x35, 0x22, 0x2f, 0x64, 0x69, 0x7e, 0x73, 0x50, 0x5d, 0x4a, 0x47,
      0xdc, 0xd1, 0xc6, 0xcb, 0xe8, 0xe5, 0xf2, 0xff, 0xb4, 0xb9, 0xae, 0xa3,
      0x80, 0x8d, 0x9a, 0x97,
    ],
    GEX = [
      0x00, 0x0e, 0x1c, 0x12, 0x38, 0x36, 0x24, 0x2a, 0x70, 0x7e, 0x6c, 0x62,
      0x48, 0x46, 0x54, 0x5a, 0xe0, 0xee, 0xfc, 0xf2, 0xd8, 0xd6, 0xc4, 0xca,
      0x90, 0x9e, 0x8c, 0x82, 0xa8, 0xa6, 0xb4, 0xba, 0xdb, 0xd5, 0xc7, 0xc9,
      0xe3, 0xed, 0xff, 0xf1, 0xab, 0xa5, 0xb7, 0xb9, 0x93, 0x9d, 0x8f, 0x81,
      0x3b, 0x35, 0x27, 0x29, 0x03, 0x0d, 0x1f, 0x11, 0x4b, 0x45, 0x57, 0x59,
      0x73, 0x7d, 0x6f, 0x61, 0xad, 0xa3, 0xb1, 0xbf, 0x95, 0x9b, 0x89, 0x87,
      0xdd, 0xd3, 0xc1, 0xcf, 0xe5, 0xeb, 0xf9, 0xf7, 0x4d, 0x43, 0x51, 0x5f,
      0x75, 0x7b, 0x69, 0x67, 0x3d, 0x33, 0x21, 0x2f, 0x05, 0x0b, 0x19, 0x17,
      0x76, 0x78, 0x6a, 0x64, 0x4e, 0x40, 0x52, 0x5c, 0x06, 0x08, 0x1a, 0x14,
      0x3e, 0x30, 0x22, 0x2c, 0x96, 0x98, 0x8a, 0x84, 0xae, 0xa0, 0xb2, 0xbc,
      0xe6, 0xe8, 0xfa, 0xf4, 0xde, 0xd0, 0xc2, 0xcc, 0x41, 0x4f, 0x5d, 0x53,
      0x79, 0x77, 0x65, 0x6b, 0x31, 0x3f, 0x2d, 0x23, 0x09, 0x07, 0x15, 0x1b,
      0xa1, 0xaf, 0xbd, 0xb3, 0x99, 0x97, 0x85, 0x8b, 0xd1, 0xdf, 0xcd, 0xc3,
      0xe9, 0xe7, 0xf5, 0xfb, 0x9a, 0x94, 0x86, 0x88, 0xa2, 0xac, 0xbe, 0xb0,
      0xea, 0xe4, 0xf6, 0xf8, 0xd2, 0xdc, 0xce, 0xc0, 0x7a, 0x74, 0x66, 0x68,
      0x42, 0x4c, 0x5e, 0x50, 0x0a, 0x04, 0x16, 0x18, 0x32, 0x3c, 0x2e, 0x20,
      0xec, 0xe2, 0xf0, 0xfe, 0xd4, 0xda, 0xc8, 0xc6, 0x9c, 0x92, 0x80, 0x8e,
      0xa4, 0xaa, 0xb8, 0xb6, 0x0c, 0x02, 0x10, 0x1e, 0x34, 0x3a, 0x28, 0x26,
      0x7c, 0x72, 0x60, 0x6e, 0x44, 0x4a, 0x58, 0x56, 0x37, 0x39, 0x2b, 0x25,
      0x0f, 0x01, 0x13, 0x1d, 0x47, 0x49, 0x5b, 0x55, 0x7f, 0x71, 0x63, 0x6d,
      0xd7, 0xd9, 0xcb, 0xc5, 0xef, 0xe1, 0xf3, 0xfd, 0xa7, 0xa9, 0xbb, 0xb5,
      0x9f, 0x91, 0x83, 0x8d,
    ],
    enc = function (string, pass, binary) {
      // string, password in plaintext
      var salt = randArr(8),
        pbe = openSSLKey(s2a(pass, binary), salt),
        key = pbe.key,
        iv = pbe.iv,
        cipherBlocks,
        saltBlock = [[83, 97, 108, 116, 101, 100, 95, 95].concat(salt)];
      string = s2a(string, binary);
      cipherBlocks = rawEncrypt(string, key, iv);
      // Spells out 'Salted__'
      cipherBlocks = saltBlock.concat(cipherBlocks);
      return Base64.encode(cipherBlocks);
    },
    dec = function (string, pass, binary) {
      // string, password in plaintext
      var cryptArr = Base64.decode(string),
        salt = cryptArr.slice(8, 16),
        pbe = openSSLKey(s2a(pass, binary), salt),
        key = pbe.key,
        iv = pbe.iv;
      cryptArr = cryptArr.slice(16, cryptArr.length);
      // Take off the Salted__ffeeddcc
      string = rawDecrypt(cryptArr, key, iv, binary);
      return string;
    },
    MD5 = function (numArr) {
      function rotateLeft(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
      }

      function addUnsigned(lX, lY) {
        var lX4, lY4, lX8, lY8, lResult;
        lX8 = lX & 0x80000000;
        lY8 = lY & 0x80000000;
        lX4 = lX & 0x40000000;
        lY4 = lY & 0x40000000;
        lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);
        if (lX4 & lY4) {
          return lResult ^ 0x80000000 ^ lX8 ^ lY8;
        }
        if (lX4 | lY4) {
          if (lResult & 0x40000000) {
            return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
          } else {
            return lResult ^ 0x40000000 ^ lX8 ^ lY8;
          }
        } else {
          return lResult ^ lX8 ^ lY8;
        }
      }

      function f(x, y, z) {
        return (x & y) | (~x & z);
      }
      function g(x, y, z) {
        return (x & z) | (y & ~z);
      }
      function h(x, y, z) {
        return x ^ y ^ z;
      }
      function funcI(x, y, z) {
        return y ^ (x | ~z);
      }

      function ff(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(f(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function gg(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(g(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function hh(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(h(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function ii(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(funcI(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function convertToWordArray(numArr) {
        var lWordCount,
          lMessageLength = numArr.length,
          lNumberOfWords_temp1 = lMessageLength + 8,
          lNumberOfWords_temp2 =
            (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64,
          lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16,
          lWordArray = [],
          lBytePosition = 0,
          lByteCount = 0;
        while (lByteCount < lMessageLength) {
          lWordCount = (lByteCount - (lByteCount % 4)) / 4;
          lBytePosition = (lByteCount % 4) * 8;
          lWordArray[lWordCount] =
            lWordArray[lWordCount] | (numArr[lByteCount] << lBytePosition);
          lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] =
          lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
      }

      function wordToHex(lValue) {
        var lByte,
          lCount,
          wordToHexArr = [];
        for (lCount = 0; lCount <= 3; lCount++) {
          lByte = (lValue >>> (lCount * 8)) & 255;
          wordToHexArr = wordToHexArr.concat(lByte);
        }
        return wordToHexArr;
      }

      /*function utf8Encode(string) {
            string = string.replace(/\r\n/g, "\n");
            var utftext = "",
            n,
            c;

            for (n = 0; n < string.length; n++) {

                c = string.charCodeAt(n);

                if (c < 128) {
                    utftext += String.fromCharCode(c);
                }
                else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }

            }

            return utftext;
        }*/

      var x = [],
        k,
        AA,
        BB,
        CC,
        DD,
        a,
        b,
        c,
        d,
        S11 = 7,
        S12 = 12,
        S13 = 17,
        S14 = 22,
        S21 = 5,
        S22 = 9,
        S23 = 14,
        S24 = 20,
        S31 = 4,
        S32 = 11,
        S33 = 16,
        S34 = 23,
        S41 = 6,
        S42 = 10,
        S43 = 15,
        S44 = 21;

      x = convertToWordArray(numArr);

      a = 0x67452301;
      b = 0xefcdab89;
      c = 0x98badcfe;
      d = 0x10325476;

      for (k = 0; k < x.length; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = ff(a, b, c, d, x[k + 0], S11, 0xd76aa478);
        d = ff(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
        c = ff(c, d, a, b, x[k + 2], S13, 0x242070db);
        b = ff(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
        a = ff(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
        d = ff(d, a, b, c, x[k + 5], S12, 0x4787c62a);
        c = ff(c, d, a, b, x[k + 6], S13, 0xa8304613);
        b = ff(b, c, d, a, x[k + 7], S14, 0xfd469501);
        a = ff(a, b, c, d, x[k + 8], S11, 0x698098d8);
        d = ff(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
        c = ff(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
        b = ff(b, c, d, a, x[k + 11], S14, 0x895cd7be);
        a = ff(a, b, c, d, x[k + 12], S11, 0x6b901122);
        d = ff(d, a, b, c, x[k + 13], S12, 0xfd987193);
        c = ff(c, d, a, b, x[k + 14], S13, 0xa679438e);
        b = ff(b, c, d, a, x[k + 15], S14, 0x49b40821);
        a = gg(a, b, c, d, x[k + 1], S21, 0xf61e2562);
        d = gg(d, a, b, c, x[k + 6], S22, 0xc040b340);
        c = gg(c, d, a, b, x[k + 11], S23, 0x265e5a51);
        b = gg(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
        a = gg(a, b, c, d, x[k + 5], S21, 0xd62f105d);
        d = gg(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = gg(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
        b = gg(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
        a = gg(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
        d = gg(d, a, b, c, x[k + 14], S22, 0xc33707d6);
        c = gg(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
        b = gg(b, c, d, a, x[k + 8], S24, 0x455a14ed);
        a = gg(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
        d = gg(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
        c = gg(c, d, a, b, x[k + 7], S23, 0x676f02d9);
        b = gg(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
        a = hh(a, b, c, d, x[k + 5], S31, 0xfffa3942);
        d = hh(d, a, b, c, x[k + 8], S32, 0x8771f681);
        c = hh(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
        b = hh(b, c, d, a, x[k + 14], S34, 0xfde5380c);
        a = hh(a, b, c, d, x[k + 1], S31, 0xa4beea44);
        d = hh(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
        c = hh(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
        b = hh(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
        a = hh(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
        d = hh(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
        c = hh(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
        b = hh(b, c, d, a, x[k + 6], S34, 0x4881d05);
        a = hh(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
        d = hh(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
        c = hh(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
        b = hh(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
        a = ii(a, b, c, d, x[k + 0], S41, 0xf4292244);
        d = ii(d, a, b, c, x[k + 7], S42, 0x432aff97);
        c = ii(c, d, a, b, x[k + 14], S43, 0xab9423a7);
        b = ii(b, c, d, a, x[k + 5], S44, 0xfc93a039);
        a = ii(a, b, c, d, x[k + 12], S41, 0x655b59c3);
        d = ii(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
        c = ii(c, d, a, b, x[k + 10], S43, 0xffeff47d);
        b = ii(b, c, d, a, x[k + 1], S44, 0x85845dd1);
        a = ii(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
        d = ii(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
        c = ii(c, d, a, b, x[k + 6], S43, 0xa3014314);
        b = ii(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
        a = ii(a, b, c, d, x[k + 4], S41, 0xf7537e82);
        d = ii(d, a, b, c, x[k + 11], S42, 0xbd3af235);
        c = ii(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
        b = ii(b, c, d, a, x[k + 9], S44, 0xeb86d391);
        a = addUnsigned(a, AA);
        b = addUnsigned(b, BB);
        c = addUnsigned(c, CC);
        d = addUnsigned(d, DD);
      }

      return wordToHex(a).concat(wordToHex(b), wordToHex(c), wordToHex(d));
    },
    Base64 = (function () {
      var totalChunks;
      // Takes a Nx16x1 byte array and converts it to Base64
      var _chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
        chars = _chars.split(""),
        encode = function (b, withBreaks) {
          var flatArr = [],
            b64 = "",
            i,
            broken_b64;
          totalChunks = Math.floor((b.length * 16) / 3);
          for (i = 0; i < b.length * 16; i++) {
            flatArr.push(b[Math.floor(i / 16)][i % 16]);
          }
          for (i = 0; i < flatArr.length; i = i + 3) {
            b64 += chars[flatArr[i] >> 2];
            b64 += chars[((flatArr[i] & 3) << 4) | (flatArr[i + 1] >> 4)];
            if (!(flatArr[i + 1] === undefined)) {
              b64 +=
                chars[((flatArr[i + 1] & 15) << 2) | (flatArr[i + 2] >> 6)];
            } else {
              b64 += "=";
            }
            if (!(flatArr[i + 2] === undefined)) {
              b64 += chars[flatArr[i + 2] & 63];
            } else {
              b64 += "=";
            }
          }
          // OpenSSL is super particular about line breaks
          broken_b64 = b64.slice(0, 64) + "\n";
          for (i = 1; i < Math.ceil(b64.length / 64); i++) {
            broken_b64 +=
              b64.slice(i * 64, i * 64 + 64) +
              (Math.ceil(b64.length / 64) == i + 1 ? "" : "\n");
          }
          return broken_b64;
        },
        decode = function (string) {
          string = string.replace(/\n/g, "");
          var flatArr = [],
            c = [],
            b = [],
            i;
          for (i = 0; i < string.length; i = i + 4) {
            c[0] = _chars.indexOf(string.charAt(i));
            c[1] = _chars.indexOf(string.charAt(i + 1));
            c[2] = _chars.indexOf(string.charAt(i + 2));
            c[3] = _chars.indexOf(string.charAt(i + 3));

            b[0] = (c[0] << 2) | (c[1] >> 4);
            b[1] = ((c[1] & 15) << 4) | (c[2] >> 2);
            b[2] = ((c[2] & 3) << 6) | c[3];
            flatArr.push(b[0], b[1], b[2]);
          }
          flatArr = flatArr.slice(0, flatArr.length - (flatArr.length % 16));
          return flatArr;
        };

      //internet explorer
      if (typeof Array.indexOf === "function") {
        _chars = chars;
      }

      /*
        //other way to solve internet explorer problem
        if(!Array.indexOf){
            Array.prototype.indexOf = function(obj){
                for(var i=0; i<this.length; i++){
                    if(this[i]===obj){
                        return i;
                    }
                }
                return -1;
            }
        }
        */

      return {
        encode: encode,
        decode: decode,
      };
    })();

  return {
    size: size,
    h2a: h2a,
    expandKey: expandKey,
    encryptBlock: encryptBlock,
    decryptBlock: decryptBlock,
    Decrypt: Decrypt,
    s2a: s2a,
    rawEncrypt: rawEncrypt,
    aesEncrypt: aesEncrypt,
    aesDecrypt: aesDecrypt,
    dec: dec,
    openSSLKey: openSSLKey,
    a2h: a2h,
    enc: enc,
    Hash: { MD5: MD5 },
    Base64: Base64,
  };
})();
</script>

<style scoped></style>
