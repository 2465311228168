<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Chips</span></div>

      <h2>Size</h2>
      <div class="guide-card jh-cols jh-align-middle">    
        <v-chip x-small>x-small</v-chip>
        <v-chip small>small</v-chip>
        <v-chip>Default</v-chip>
        <v-chip large>large</v-chip>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source05">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source05">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Color</h2>
      <div class="guide-card">
        <div class="jh-cols is-space-5">
            <v-chip>Default</v-chip>
            <v-chip color="primary">primary</v-chip>
            <v-chip color="red" dark>red</v-chip>
            <v-chip color="pink" dark>pink</v-chip>
            <v-chip color="purple" dark>purple</v-chip>
            <v-chip color="deep-purple" dark>deep-purple</v-chip>
            <v-chip color="indigo" dark>indigo</v-chip>
            <v-chip color="blue" dark>blue</v-chip>
            <v-chip color="light-blue" dark>light-blue</v-chip>
            <v-chip color="cyan" dark>cyan</v-chip>
            <v-chip color="teal" dark>teal</v-chip>
        </div>
        <div class="jh-cols is-space-5">
            <v-chip color="green" dark>green</v-chip>
            <v-chip color="light-green" dark>light-green</v-chip>
            <v-chip color="lime" dark>lime</v-chip>
            <v-chip color="yellow" dark>yellow</v-chip>
            <v-chip color="amber" dark>amber</v-chip>
            <v-chip color="orange" dark>orange</v-chip>
            <v-chip color="deep-orange" dark>deep-orange</v-chip>
            <v-chip color="brown" dark>brown</v-chip>
            <v-chip color="blue-grey" dark>blue-grey</v-chip>
            <v-chip color="grey" dark>grey</v-chip>
            <v-chip color="shades" dark>shades</v-chip>
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Icon</h2>
      <div class="guide-card jh-cols">
        <v-chip color="primary">
            <v-icon left>mdi-account-circle</v-icon>
            홍길동
        </v-chip>
        <v-chip color="light-blue" dark>
            별점
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
        </v-chip>
        <v-chip color="pink" dark>
            <v-icon left>mdi-cake-variant</v-icon>
            기념일
        </v-chip>
        <v-chip color="yellow">
            <v-avatar left class="yellow darken-3 is-txt-white">1</v-avatar>
            시간
        </v-chip>
        <v-chip color="indigo" dark close>
            <v-avatar left class="indigo darken-4">1</v-avatar>
            주년
        </v-chip>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source02">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source02">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Outlined</h2>
      <div class="guide-card jh-cols"> 
        <v-chip color="primary" outlined>
            <v-icon left>mdi-account-circle</v-icon>
            홍길동
        </v-chip>
        <v-chip color="light-blue" dark outlined>
            별점
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
        </v-chip>
        <v-chip color="pink" dark outlined>
            <v-icon left>mdi-cake-variant</v-icon>
            기념일
        </v-chip>
        <v-chip color="orange" outlined>
            <v-avatar left class="orange is-txt-white">1</v-avatar>
            시간
        </v-chip>
        <v-chip color="indigo" close outlined>
            <v-avatar left class="indigo is-txt-white">1</v-avatar>
            주년
        </v-chip>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source03">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source03">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Label</h2>
      <div class="guide-card jh-cols">
        <v-chip color="primary" label>
            <v-icon left>mdi-account-circle</v-icon>
            홍길동
        </v-chip>
        <v-chip color="light-blue" dark label>
            별점
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
            <v-icon right>mdi-star</v-icon>
        </v-chip>
        <v-chip color="pink" dark label>
            <v-icon left>mdi-cake-variant</v-icon>
            기념일
        </v-chip>
        <v-chip color="orange" dark label>
            <v-avatar left class="orange darken-3">1</v-avatar>
            시간
        </v-chip>
        <v-chip color="indigo" dark close label>
            <v-avatar left class="indigo darken-4">1</v-avatar>
            주년
        </v-chip>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source04">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source04">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "VueChips",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {    
        source01: 'HTML',
        source02: 'HTML',
        source03: 'HTML',
        source04: 'HTML',
        source05: 'HTML',
        content:{

source01_tab01: `<v-chip>Default</v-chip>
<v-chip color="primary">primary</v-chip>
<v-chip color="red" dark>red</v-chip>
<v-chip color="pink" dark>pink</v-chip>
<v-chip color="purple" dark>purple</v-chip>
<v-chip color="deep-purple" dark>deep-purple</v-chip>
<v-chip color="indigo" dark>indigo</v-chip>
<v-chip color="blue" dark>blue</v-chip>
<v-chip color="light-blue" dark>light-blue</v-chip>
<v-chip color="cyan" dark>cyan</v-chip>
<v-chip color="teal" dark>teal</v-chip>
<v-chip color="green" dark>green</v-chip>
<v-chip color="light-green" dark>light-green</v-chip>
<v-chip color="lime" dark>lime</v-chip>
<v-chip color="yellow" dark>yellow</v-chip>
<v-chip color="amber" dark>amber</v-chip>
<v-chip color="orange" dark>orange</v-chip>
<v-chip color="deep-orange" dark>deep-orange</v-chip>
<v-chip color="brown" dark>brown</v-chip>
<v-chip color="blue-grey" dark>blue-grey</v-chip>
<v-chip color="grey" dark>grey</v-chip>
<v-chip color="shades" dark>shades</v-chip>`
,source02_tab01: `<v-chip color="primary">
    <v-icon left>mdi-account-circle</v-icon>
    홍길동
</v-chip>
<v-chip color="light-green" dark>
    별점
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
</v-chip>
<v-chip color="pink" dark>
    <v-icon left>mdi-cake-variant</v-icon>
    기념일
</v-chip>
<v-chip color="yellow">
    <v-avatar left class="yellow darken-3 is-txt-white">1</v-avatar>
    시간
</v-chip>
<v-chip color="indigo" dark close>
    <v-avatar left class="indigo darken-4">1</v-avatar>
    주년
</v-chip>`
,source03_tab01: `<v-chip color="primary" outlined>
    <v-icon left>mdi-account-circle</v-icon>
    홍길동
</v-chip>
<v-chip color="light-green" dark outlined>
    별점
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
</v-chip>
<v-chip color="pink" dark outlined>
    <v-icon left>mdi-cake-variant</v-icon>
    기념일
</v-chip>
<v-chip color="orange" outlined>
    <v-avatar left class="orange is-txt-white">1</v-avatar>
    시간
</v-chip>
<v-chip color="indigo" close outlined>
    <v-avatar left class="indigo is-txt-white">1</v-avatar>
    주년
</v-chip>`
,source04_tab01: `<v-chip color="primary" label>
    <v-icon left>mdi-account-circle</v-icon>
    홍길동
</v-chip>
<v-chip color="light-green" dark label>
    별점
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
    <v-icon right>mdi-star</v-icon>
</v-chip>
<v-chip color="pink" dark label>
    <v-icon left>mdi-cake-variant</v-icon>
    기념일
</v-chip>
<v-chip color="orange" dark label>
    <v-avatar left class="orange darken-3">1</v-avatar>
    시간
</v-chip>
<v-chip color="indigo" dark close label>
    <v-avatar left class="indigo darken-4">1</v-avatar>
    주년
</v-chip>`
,source05_tab01: `<v-chip x-small>x-small</v-chip>
<v-chip small>small</v-chip>
<v-chip>Default</v-chip>
<v-chip large>large</v-chip>`
      }
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>