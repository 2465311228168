<template>
  <div class="main-scss">
    <header>
      <div class="logo_bx"><img src="/img/logo.png" alt="logo" /></div>
      <!-- <a href="#" class="menu"></a> -->
    </header>

    <section class="sec_top">
      <div class="main_img_bx"><img src="/img/main_img01.jpg" alt="img" /></div>
      <div class="info_bx">
        <span class="info_txt">
          <span class="info_icon"></span>
          본 플랫폼은 보험영업(자동차) 회원 전용 서비스입니다.</span
        >
      </div>

      <div class="imgTxt_wrapper">
        <div class="img_bx">
          <img src="/img/main_num_img.png" alt="img_num" />
        </div>
        <div class="txt_bx">
          <h1>저희는 다이렉트 자동차 보험의 비교견적 서비스를 제공합니다.</h1>

          <h3>
            저희 이지 다이렉트는 금융 소비자의 합리적인 자동차 보험 선택을 위해<br />
            간편하고 저렴한 다이렉트 자동차 보험 비교 견적 서비스 제공으로<br />
            고객의 성원에 보답하기 위해 모든 역량을 집중하고 있습니다.
          </h3>

          <div class="txt_etc_bx">
            <span>▷ <span class="txt_red">설계사 광고 수수료 5% 익일 지급</span></span>
            <span>▷ 간편한 견적 의뢰(카톡 또는 1688-5392)</span>
            <span>▷ 전문 상담사 업무지원</span>
            <span>▷ 회원용 계약관리 전산 시스템 제공</span>
          </div>

          <a href="https://open.kakao.com/o/grKvMFlf" target="_blank" class="kakao_bx" style="width: 400px">
            <div class="kakao_wrapper">
              <!-- 오픈채팅방 -->
              <div class="kakao_img">
                <img src="/img/kakao_logo.png" alt="kakao_logo" />
              </div>
              <span>
                보험영업 및 보상 정보를 공유하는<br />이지다이렉트 설계사 전용<br />오픈채칭방으로 초대합니다.
              </span>
            </div>
          </a>
        </div>
      </div>
    </section>

    <section class="sec_bottom">
      <div class="grid_wrapper">
        <h1>1. 손해보험</h1>
        <!-- grid -->
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="20"
          hide-default-footer
          mobile-breakpoint="0"
          no-data
          class="grid"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td align="center">
                <span class="logo_img"><img :src="item.name" alt="logo" /></span>
              </td>
              <td>
                <a :href="item.type01URL" target="_blank"><img src="/img/icon_file.png" /></a>
              </td>
              <td>{{ item.type02 }}</td>
              <td>{{ item.type03 }}</td>
              <td>
                <a :href="item.type04URL" target="_blank"><img src="/img/icon_file.png" /></a>
              </td>
              <td>
                <a :href="item.type05URL" target="_blank"><img src="/img/icon_file.png" /></a>
              </td>
              <td>{{ item.type06 }}</td>
              <td>{{ item.type07 }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>

      <div class="grid_wrapper">
        <h1>2. 생명보험</h1>
        <!-- grid -->
        <v-data-table
          :headers="headers2"
          :items="items2"
          :items-per-page="20"
          hide-default-footer
          mobile-breakpoint="0"
          no-data
          class="grid"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td align="center">
                <span class="logo_img"><img :src="item.name" alt="logo" /></span>
              </td>
              <td>
                <a :href="item.type01URL" target="_blank"><img src="/img/icon_file.png" /></a>
              </td>
              <td>{{ item.type02 }}</td>
              <td>{{ item.type03 }}</td>
              <td>
                <a :href="item.type04URL" target="_blank"><img src="/img/icon_file.png" /></a>
              </td>
              <td>
                <a :href="item.type05URL" target="_blank"><img src="/img/icon_file.png" /></a>
              </td>
              <td>{{ item.type06 }}</td>
              <td>{{ item.type07 }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>

      <div class="grid_wrapper">
        <h1>3. 공제회사</h1>
        <!-- grid -->
        <v-data-table
          :headers="headers3"
          :items="items3"
          :items-per-page="20"
          hide-default-footer
          mobile-breakpoint="0"
          no-data
          class="grid"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td align="center">
                <span class="logo_img"><img :src="item.name" alt="logo" /></span>
              </td>
              <td>
                <a :href="item.type01URL" target="_blank"><img src="/img/icon_file.png" /></a>
              </td>
              <td>{{ item.type02 }}</td>
              <td>{{ item.type03 }}</td>
              <td>
                <a :href="item.type04URL" target="_blank"><img src="/img/icon_file.png" /></a>
              </td>
              <td>
                <a :href="item.type05URL" target="_blank"><img src="/img/icon_file.png" /></a>
              </td>
              <td>{{ item.type06 }}</td>
              <td>{{ item.type07 }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </section>

    <footer>
      <div class="footer_wrapper">
        <h2>이지다이렉트</h2>

        <!-- <div><span>이용약관</span><span>개인정보취급방침</span><span>이메일주소무단수집거부</span></div> -->
        <div>
          <span>플랫폼:이지다이렉트</span><span>대표:조규영</span><span>Email : </span
          ><span>Tel : 010-2799-9090</span>
        </div>
        <div><span>Copyright &copy; 이지다이렉트 All rights reserved. Designed & Programmed by WHOIS</span></div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'MainPage',

  data() {
    return {
      headers: [
        {
          text: '보험회사',
          align: 'center',
          sortable: false,
          value: 'name',
          width: '130px',
        },
        {
          text: 'GA 사용자 전산',
          align: 'center',
          sortable: false,
          value: 'type01',
          width: '120px',
        },
        {
          text: '대표전화 (콜센터)',
          align: 'center',
          sortable: false,
          value: 'type02',
          width: '130px',
        },
        {
          text: '보험금 청구 팩스',
          align: 'center',
          sortable: false,
          value: 'type03',
          width: '130px',
        },
        {
          text: '보험금 청구서 양식',
          align: 'center',
          sortable: false,
          value: 'type04',
          width: '140px',
        },
        {
          text: '약관',
          align: 'center',
          sortable: false,
          value: 'type05',
          width: '80px',
        },
        {
          text: '모니터링 (인콜)',
          align: 'center',
          sortable: false,
          value: 'type06',
          width: '120px',
        },
        {
          text: '전산 헬프 데스크',
          align: 'center',
          sortable: false,
          value: 'type07',
          width: '130px',
        },
      ],
      items: [
        {
          name: '/img/NH_logo.jpg',
          type01: '신보험시스템설치 | NH농협손해보험 (nhfire.co.kr)',
          type01URL: 'https://www.nhfire.co.kr/fc/fd.nhfire',
          type02: '1644-9000',
          type03: '1644-9600',
          type04: '청구서류안내 | NH농협손해보험 (nhfire.co.kr)',
          type04URL: 'https://www.nhfire.co.kr/customer/bilgdcm/retrieveBilgDcmList.nhfire',
          type05: '보험상품공시-판매상품 | NH농협손해보험 (nhfire.co.kr)',
          type05URL: 'https://www.nhfire.co.kr/announce/productAnnounce/retrieveInsuranceProductsAnnounce.nhfire',
          type06: '1644-9600',
          type07: '1644-0090',
        },
        {
          name: '/img/LOTTE_logo.jpg',
          type01: 'NGS - 영업지원시스템 (lotteins.co.kr)',
          type01URL: 'https://lottero.lotteins.co.kr/ncrmwebroot/webfw/html/nawlogon.jsp',
          type02: '1588-3344',
          type03: '1600-5182',
          type04: '필요서류 접수처안내 - 롯데손해보험 (lotteins.co.kr)',
          type04URL: 'https://lotteins.co.kr/web/C/D/C/cdc_claim_0502.jsp',
          type05: '상품목록 - 롯데손해보험 (lotteins.co.kr)',
          type05URL: 'https://lotteins.co.kr/web/C/D/H/cdh190.jsp',
          type06: '1600-5182',
          type07: '02-3455-3984',
        },
        {
          name: '/img/MERITZ_logo.jpg',
          type01: '메리츠화재 영업포탈 | 로그인 (meritzfire.com)',
          type01URL:
            'https://sso.meritzfire.com:9443/3rdParty/loginFormPageMulti.jsp?NONCE=gvFXB2PNyecMWtErtSRSN18zdG85IWxfOutTinXU%2BDwbUgY6lKP32ENMemGQQ5bM%0AiZUZh8vuF8gpiSXU1XH6SQ%3D%3D%0A&UURL=http%3A%2F%2Fsso.meritzfire.com%2Fnls3%2Ffcs',
          type02: '1566-7711',
          type03: '0505-021-3400',
          type04: '필요서류 안내 | 메리츠화재 (meritzfire.com)',
          type04URL: 'https://www.meritzfire.com/compensation/longterm-insurance/request-document.do#!/',
          type05: '상품목록 | 메리츠화재 공식 사이트 (meritzfire.com)',
          type05URL: 'https://www.meritzfire.com/disclosure/product-announcement/product-list.do#!/',
          type06: '1577-7711',
          type07: '02-3786-2777',
        },
        {
          name: '/img/SAMSUNG_logo.jpg',
          type01: 'Dream Portal Login (samsungfire.com)1588-5114',
          type01URL: 'https://erp.samsungfire.com/irj/servlet/prt/portal/prtroot/logon.LogonPage',
          type02: '1588-5114',
          type03: '0505-162-0872',
          type04: '필요서류 안내 | 보상 - 삼성화재 (samsungfire.com)',
          type04URL: 'https://www.samsungfire.com/claim/P_P03_01_02_009.html',
          type05: '보험상품공시 | 공시실 - 삼성화재 (samsungfire.com)',
          type05URL: 'https://www.samsungfire.com/page/VH.REIF0011.do',
          type06: '1566-0553',
          type07: '1899-5005',
        },
        {
          name: '/img/AXA_logo.jpg',
          type01: 'AXA손해보험',
          type01URL: 'https://www.axa.co.kr/ActionControler.action?screenID=SHCM0000&actionID=I01',
          type02: '1566-2266',
          type03: '가상팩스 부여',
          type04: 'AXA 손해보험',
          type04URL: 'https://www.axa.co.kr/AsianPlatformInternet/html/axacms/shcl/pa/process/index.html',
          type05: 'axa.co.kr/AsianPlatformInternet/html/axacms/common/intro/disclosure/insurance/index.html',
          type05URL: 'https://www.axa.co.kr/AsianPlatformInternet/html/axacms/common/intro/disclosure/insurance/index.html',
          type06: '',
          type07: '',
        },
        {
          name: '/img/CHUBB_logo.jpg',
          type01: '에이스손해보험 (chubb.com)',
          type01URL: 'https://www.chubb.com/kr-kr/',
          type02: '1566-5800',
          type03: '02-2127-2308 (치아 02-6913-8482)',
          type04: '구비서류안내 (chubb.com)',
          type04URL: 'https://www.chubb.com/kr-kr/claims/report-a-claim.html',
          type05: '상품목록 (chubb.com)',
          type05URL: 'https://www.chubb.com/kr-kr/disclosure/product.html',
          type06: '',
          type07: '',
        },
        {
          name: '/img/HANA_logo.jpg',
          type01: 'https://sfa.saleshana.com/wq/login',
          type01URL: 'https://sfa.saleshana.com/wq/login',
          type02: '1566-0553',
          type03: '0505-152-0698',
          type04: '보상서류안내 | 하나손해보험 (hanainsure.co.kr)',
          type04URL: 'https://www.hanainsure.co.kr/w/claim/carReward/rewardDocumentGuide',
          type05: '상품목록 | 하나손해보험 (hanainsure.co.kr)',
          type05URL: 'https://www.hanainsure.co.kr/w/disclosure/product/saleProduct',
          type06: '1566-3000',
          type07: '02-6670-8110',
        },
        {
          name: '/img/HANHWA_logo.jpg',
          type01: 'HSP - 로그인 (hwgeneralins.com)',
          type01URL: 'https://portal.hwgeneralins.com/',
          type02: '1566-8000',
          type03: '0502-779-1004',
          type04: '한화손해보험 - 질병보험금 | 필요서류안내 (hwgeneralins.com)',
          type04URL: 'https://www.hwgeneralins.com/fplaza/compensation/receipt01.do',
          type05: '한화손해보험 - 현재판매상품 | 보험상품공시 (hwgeneralins.com)',
          type05URL: 'https://www.hwgeneralins.com/notice/ir/product-ing01.do',
          type06: '1670-1882',
          type07: '02-316-0111',
        },
        {
          name: '/img/HYUNDAI_logo.jpg',
          type01: '현대해상 영업포탈 시스템 (hi.co.kr)',
          type01URL: 'https://sp.hi.co.kr/websquare/websquare.html?w2xPath=/common/xml/Login.xml',
          type02: '1588-5656',
          type03: '0507-774-6060',
          type04: '질병/일반상해 구비서류 (hi.co.kr)',
          type04URL: 'https://www.hi.co.kr/serviceAction.do?menuId=100631',
          type05: '공시이용안내 (hi.co.kr)',
          type05URL: 'https://www.hi.co.kr/serviceAction.do?view=bin/PA/03/HHPA03010M#none',
          type06: '1577-3223',
          type07: '02-2628-4567',
        },
        {
          name: '/img/HEUNGKUK_logo.jpg',
          type01: 'Welcome! - 흥국화재 포탈에 오신걸 환영합니다. [124.111.187.147] (heungkukfire.co.kr)',
          type01URL: 'http://upride.heungkukfire.co.kr/portal/raon/login.jsp',
          type02: '1688-1688',
          type03: '0504-800-0700',
          type04: '구비서류안내 | 흥국화재 (heungkukfire.co.kr)',
          type04URL: 'https://www.heungkukfire.co.kr/FRW/compensation/accidentDocInfo.do',
          type05: '보험상품공시 | 흥국화재 (heungkukfire.co.kr)',
          type05URL: 'https://www.heungkukfire.co.kr/FRW/announce/insGoodsGongsiSale.do',
          type06: '1688-6997',
          type07: '031-786-8088',
        },
        {
          name: '/img/AIG_logo.jpg',
          type01: 'AIG GA LOGIN',
          type01URL: 'https://sso.aig.co.kr/gaLogin/gaLogin.jsp',
          type02: '1544-2792',
          type03: '02-2011-4607',
          type04: '상해질병보험(T)|보험금청구서류안내|보험금청구서류|보상서비스 | 개인보험 (aig.co.kr)',
          type04URL: 'https://www.aig.co.kr/wm/content.html?contentId=DPWMS406&menuId=MS406',
          type05: '상품목록 판매중인 상품- AIG',
          type05URL: 'https://www.aig.co.kr/wo/dpwot001.html?menuId=MS702',
          type06: '1544-2792',
          type07: '',
        },
        {
          name: '/img/DB_logo.jpg',
          type01: 'DB손해보험::통합로그인 (mdbins.com)',
          type01URL: 'https://www.mdbins.com/edge.html',
          type02: '1588-0100',
          type03: '0505-181-4862',
          type04: '상해/운행관련사고 | 구비서류안내 | 장기/일반보상 | DB손해보험 다이렉트 (directdb.co.kr)',
          type04URL: 'https://www.directdb.co.kr/clam/ltmclam/gid/injOprtAccdRqdDcuGid.do',
          type05: '상품목록 및 기초서류(보험약관) | DB손해보험 (idbins.com)',
          type05URL: 'https://www.idbins.com/FWMAIV1534.do',
          type06: '1566-0757',
          type07: '02-2262-1241',
        },
        {
          name: '/img/MG_logo.jpg',
          type01: 'GA포탈시스템 (mggeneralins.com)',
          type01URL: 'https://mganet.mggeneralins.com/jsp/browserGuide.jsp',
          type02: '1588-5959',
          type03: '0505-088-1646',
          type04: '구비서류안내 | 질병/상해/재물보험 | 보상서비스 | MG손해보험 (mggeneralins.com)',
          type04URL: 'https://www.mggeneralins.com/RW031010DM.scp?menuId=MN0501020',
          type05: '판매상품목록 | 보험상품목록 | 상품공시실 | 공시실 | MG손해보험 (mggeneralins.com)',
          type05URL: 'https://www.mggeneralins.com/PB031210DM.scp?menuId=MN0803006',
          type06: '1577-3777',
          type07: '02-3788-2261',
        },
        {
          name: '/img/KB_logo.jpg',
          type01: 'KB스마트비서 GA전용 (kbinsure.co.kr)',
          type01URL: 'http://sales.kbinsure.co.kr/eus/newindex.jsp',
          type02: '1544-0114',
          type03: '0505-136-6500',
          type04: '구비서류안내(일반상해) | KB손해보험 [A] (kbinsure.co.kr)',
          type04URL: 'https://www.kbinsure.co.kr/CG205020001.ec',
          type05: '상품목록(약관)(상품목록) | KB손해보험 [A] (kbinsure.co.kr)',
          type05URL: 'https://www.kbinsure.co.kr/CG802030001.ec',
          type06: '1544-0019',
          type07: '1544-8119',
        },
      ],
      headers2: [
        {
          text: '보험회사',
          align: 'center',
          sortable: false,
          value: 'name',
          width: '130px',
        },
        {
          text: 'GA 사용자 전산',
          align: 'center',
          sortable: false,
          value: 'type01',
          width: '120px',
        },
        {
          text: '대표전화',
          align: 'center',
          sortable: false,
          value: 'type02',
          width: '100px',
        },
        {
          text: '보험금 청구 팩스',
          align: 'center',
          sortable: false,
          value: 'type03',
          width: '130px',
        },
        {
          text: '보험금 청구서 양식',
          align: 'center',
          sortable: false,
          value: 'type04',
          width: '140px',
        },
        {
          text: '약관',
          align: 'center',
          sortable: false,
          value: 'type05',
          width: '80px',
        },
        {
          text: '모니터링 (인콜)',
          align: 'center',
          sortable: false,
          value: 'type06',
          width: '120px',
        },
        {
          text: '전산 헬프 데스크',
          align: 'center',
          sortable: false,
          value: 'type07',
          width: '130px',
        },
      ],
      items2: [
        {
          name: '/img/AIA_logo.jpg',
          type01: 'IMAP - 로그인 (aia.co.kr)',
          type01URL: 'https://imap.aia.co.kr/NBSE/aiaone/',
          type02: '1588-9898',
          type03: '02-2021-4540',
          type04: '서식자료실 - AIA생명',
          type04URL: 'https://www.aia.co.kr/ko/help-support/forms.html',
          type05: '개인 판매상품 - 공시실 | AIA생명',
          type05URL: 'https://mypage.aia.co.kr/AIAHomepage/disclosure/product.do',
          type06: '1588-2513',
          type07: '1588-2937',
        },
        {
          name: '/img/ABL_logo.jpg',
          type01: 'ABL생명 (abllife.co.kr)',
          type01URL: 'https://ga.abllife.co.kr/ui2/login/login.jsp',
          type02: '1588-6500',
          type03: '가상팩스 부여',
          type04: '사고보험금 구비서류 < ABL (abllife.co.kr)',
          type04URL: 'https://www.abllife.co.kr/st/custDesk/insSrvcGudn/acdtInsmClamGudn/acdtInsmClamGudn3?page=index',
          type05: '종신 < ABL (abllife.co.kr)',
          type05URL: 'https://www.abllife.co.kr/st/pban/prdtPban/whlPrdt/whlPrdt1/whlPrdt11?page=index',
          type06: '1566-1002',
          type07: '02-3787-8583',
        },
        {
          name: '/img/CARDIF_logo.jpg',
          type01: 'https://ga.cardif.co.kr/login/loginForm.do#none',
          type01URL: 'https://ga.cardif.co.kr/login/loginForm.do#none',
          type02: '1688-1118',
          type03: '가상팩스 부여',
          type04: 'https://www.cardif.co.kr/customer-center/hcwgi001.do',
          type04URL: 'https://www.cardif.co.kr/customer-center/hcwgi001.do',
          type05: 'https://www.cardif.co.kr/disclosure/papag101.do',
          type05URL: 'https://www.cardif.co.kr/disclosure/papag101.do',
          type06: '1688-1118',
          type07: '1577-3435',
        },
        {
          name: '/img/DB생명_logo.jpg',
          type01: 'DBLIFE (idblife.com)',
          type01URL: 'https://etopia.idblife.com/SSSCO99999M.mvc',
          type02: '1588-3131',
          type03: '0505-129-3134',
          type04: '청구 절차 및 방법 | DB생명 (idblife.com)',
          type04URL: 'https://www.idblife.com/support/guide/acbf_clm',
          type05: '상품판매공시 | 개인상품 | DB생명 (idblife.com)',
          type05URL: 'https://www.idblife.com/notice/product/sale',
          type06: '02-6470-7663',
          type07: '02-2119-5151',
        },
        {
          name: '/img/DGB_logo.jpg',
          type01: 'DGB생명 SMART. (dgbfnlife.com)',
          type01URL: 'https://fgs.dgbfnlife.com:8443/',
          type02: '1588-4770',
          type03: '콜센터 접수 후 0505-083-5420',
          type04: '보험금청구안내 > 공통사항 > 사고보험금청구 서류안내 | DGB생명 (dgbfnlife.com)',
          type04URL: 'https://www.dgbfnlife.com/BB/BB_D010.do',
          type05: '판매 중 상품 > 전체상품공시 | DGB생명 (dgbfnlife.com)',
          type05URL: 'https://www.dgbfnlife.com/BA/BA_A020.do',
          type06: '1588-4770',
          type07: '02-2087-9594',
        },
        {
          name: '/img/IBK_logo.jpg',
          type01: 'https://vpn.ibkinsu.co.kr/',
          type01URL: 'https://vpn.ibkinsu.co.kr/',
          type02: '1577-4117',
          type03: '02-2270-1577',
          type04: '구비서류 안내 - IBK연금보험 (ibki.co.kr)',
          type04URL: 'https://www.ibki.co.kr/process/HP_CSCETR_POSN_DOC_INS_LIST?bltb_cod=SC000014&sctn=in&TAB=in',
          type05: '판매상품_개인연금 - IBK연금보험 (ibki.co.kr)',
          type05URL: 'https://www.ibki.co.kr/process/HP_PBANO_PDT_SP_INDV',
          type06: '02-2270-1661',
          type07: '',
        },
        {
          name: '/img/KB라이프_logo.jpg',
          type01: 'KBLife Agent Support System',
          type01URL: 'https://sfa.kblife.co.kr/login/main',
          type02: '1588-3374',
          type03: '02-6220-9912',
          type04: '구비서류 안내 > 고객센터 | KB라이프생명 (kblife.co.kr)',
          type04URL: 'https://www.kblife.co.kr/customer-center/informRequiredDocument.do',
          type05: 'KB라이프생명 (kblife.co.kr)',
          type05URL: 'https://www.kblife.co.kr/customer-common/productList.do',
          type06: '1566-2730',
          type07: '',
        },
        {
          name: '/img/KDB생명_logo.jpg',
          type01: '산업은행계열 KDB 생명 | 스마트온 사용을 필수 프로그램 설치화면 (kdblife.co.kr)',
          type01URL: 'http://kss.kdblife.co.kr/Install/x_installAX.html',
          type02: '1588-4040',
          type03: '콜센터 접수 후 052-2669-7939',
          type04: '서식다운로드 | 산업은행계열 KDB 생명 (kdblife.co.kr)',
          type04URL: 'https://www.kdblife.co.kr/ajax.do?scrId=HCSCT006M01P',
          type05: '판매상품 | 산업은행계열 KDB 생명 (kdblife.co.kr)',
          type05URL: 'https://www.kdblife.co.kr/ajax.do?scrId=HDLMA002M02P',
          type06: '1588-4040',
          type07: '02-6303-2771',
        },
        {
          name: '/img/NH농협생명_logo.jpg',
          type01: '',
          type01URL: 'https://www.nhlife.co.kr/nhsmart.nhl',
          type02: '1544-4000',
          type03: '02-6971-6040',
          type04: '구비서류 안내-사고보험금 청구안내 [ 사고보험금 청구안내>보험가이드>고객센터>홈 ] (nhlife.co.kr)',
          type04URL: 'https://www.nhlife.co.kr/ho/cc/HOCC0010M00.nhl',
          type05: '판매 상품-전체상품공시 [ 전체상품공시>상품공시>공시실>홈 ] (nhlife.co.kr)',
          type05URL: 'https://www.nhlife.co.kr/ho/on/HOON0004M00.nhl',
          type06: '1544-4422',
          type07: '02-3786-8800',
        },
        {
          name: '/img/KYOBO_logo.jpg',
          type01: '로그인 (kyobo.com)',
          type01URL:
            'https://sso.kyobo.com:5443/3rdParty/TouchEn/install/install_nxkey.html?&url=https%3A%2F%2Fsso.kyobo.com%3A5443%2F3rdParty%2FcertLoginFormPage.jsp%3FNONCE%3DxZRcqAw0I4PoKkcacLmM39NTWh0BLsnAdYa%252FggGZa3%252FFKgXK3smtbcezj152wg2AbR%252FBTY7QmamuCX9FZeUyTw%253D%253D%26UURL%3Dhttps%253A%252F%252Fsso.kyobo.com%253A5443%252Fnls3%252Ffcs',
          type02: '1588-1001',
          type03: '가상팩스 부여',
          type04: '필요서류 안내-교보생명 (kyobo.com)',
          type04URL: 'https://www.kyobo.com/dgt/web/customer/support/need-papers/list',
          type05: '전체상품조회-교보생명 (kyobo.com)',
          type05URL: 'https://www.kyobo.com/dgt/web/product-official/all-product/search',
          type06: '1588-1636',
          type07: '02-721-3130',
        },
        {
          name: '/img/동양_logo.jpg',
          type01: 'https://1004.myangel.co.kr/colgnsf001m.wqv',
          type01URL: 'https://1004.myangel.co.kr/view/cowqvsf005m',
          type02: '1577-1004',
          type03: '02-3289-4517',
          type04: '홈 > 고객광장 >  사고보험금청구 >  보험금청구 구비서류 >  보험금청구서류안내 (myangel.co.kr)',
          type04URL: 'https://www.myangel.co.kr/process/main',
          type05: '홈 > 공시실 >  상품공시실 >  전체보험 상품목록 >  판매상품 (myangel.co.kr)',
          type05URL: 'https://www.myangel.co.kr/paging/WE_AC_WEPAAP020100L',
          type06: '080-899-1004',
          type07: '02-728-9900',
        },
        {
          name: '/img/라이나생명_logo.jpg',
          type01: 'https://ga.lina.co.kr/html/gap/GA/GAZ911M0.html',
          type01URL: 'https://ga.lina.co.kr/html/gap/GA/GAZ911M0.html',
          type02: '1588-0058',
          type03: '02-6944-1200',
          type04: '라이나생명 (lina.co.kr)',
          type04URL: 'https://www.lina.co.kr/cyber/accident-insurance/accident-insurance-info',
          type05: '판매중인상품 - 공시실 - 라이나생명 (lina.co.kr)',
          type05URL:
            'https://www.lina.co.kr/disclosure/product-public-announcement/product-on-sales?key=0%3FproductState%3D01&productKind=01',
          type06: '1588-2442',
          type07: '1588-2215',
        },
        {
          name: '/img/METLIFE_logo.jpg',
          type01: '메트라이프 영업포털 (metlife.co.kr)',
          type01URL: 'https://metplus.metlife.co.kr/WebCubeSetup.html',
          type02: '1588-9600',
          type03: '02-3469-9428',
          type04: '필요서류안내 - 메트라이프생명 (metlife.co.kr)',
          type04URL: 'https://cyber.metlife.co.kr/claim/requiredDocumentNotice',
          type05: '보험상품공시 - MetLife',
          type05URL: 'https://brand.metlife.co.kr/pn/paReal/insuProductDisclMain.do',
          type06: '1588-9609',
          type07: '1899-0751',
        },
        {
          name: '/img/MIRAE_logo.jpg',
          type01: '미래에셋생명 영업시스템 (loveageplan.com)',
          type01URL: 'https://www.loveageplan.com/websquare/websquare.jsp?w2xPath=/view/lap/ui/lg/lga/PLGA010M00.xml',
          type02: '1588-0220',
          type03: '가상팩스 부여',
          type04: '보험금 청구 방법 - 미래에셋생명 - 행복한 은퇴설계의 시작 (miraeasset.com)',
          type04URL: 'https://life.miraeasset.com/home/index.do#MO-HO-030501-010000',
          type05: '상품목록 - 미래에셋생명 홈페이지 (miraeasset.com)',
          type05URL: 'https://life.miraeasset.com/micro/disclosure/product/PC-HO-080301-000000.do',
          type06: '1588-0220',
          type07: '02-3271-5108',
        },
        {
          name: '/img/SAMSUNG생명_logo.jpg',
          type01: '삼성생명 - GA영업포탈 (samsunglife.com)',
          type01URL: 'https://ga.samsunglife.com/browserguide.html',
          type02: '1588-3114',
          type03: '가상팩스 부여',
          type04: '보험금청구 - 보험금청구신청 - 보험 - MY삼성생명 - 삼성생명 (samsunglife.com)',
          type04URL: 'https://www.samsunglife.com/individual/mysamsunglife/insurance/internet/MDP-MYINT020110M',
          type05: '보험상품목록 - 상품공시 - 공시실 - 삼성생명 (samsunglife.com)',
          type05URL: 'https://www.samsunglife.com/individual/products/disclosure/sales/PDO-PRPRI010110M',
          type06: '1588-3115',
          type07: '02-311-4500',
        },
        {
          name: '/img/SH라이프_logo.jpg',
          type01: '신한라이프 통합영업지원 (shinhanlife.co.kr)',
          type01URL: 'https://ga.shinhanlife.co.kr/cocomsm998m.msv?url=https://ga.shinhanlife.co.kr/colgism001m.msv',
          type02: '1588-5580',
          type03: '가상팩스 부여',
          type04:
            '구비서류 | 사고보험금 청구 | 구비서류 안내 | 헬프데스크 | 라이프에 놀라움을 더하다, 신한라이프 (shinhanlife.co.kr)',
          type04URL: 'https://www.shinhanlife.co.kr/hp/cdhf0020t02.do',
          type05: '공시실 | 라이프에 놀라움을 더하다, 신한라이프 (shinhanlife.co.kr)',
          type05URL: 'https://www.shinhanlife.co.kr/hp/cdhi0010.do',
          type06: '1522-2285',
          type07: '02-3455-4119',
        },
        {
          name: '/img/CHUBB라이프_logo.jpg',
          type01: '처브라이프 (chubblife.co.kr)',
          type01URL: 'https://esmart.chubblife.co.kr/index.do',
          type02: '1599-4600',
          type03: '02-3480-7801',
          type04: '보험금 청구 안내-구비서류 서류안내 처브라이프생명 (chubblife.co.kr)',
          type04URL: 'https://www.chubblife.co.kr/front/ctmcenter/insurance/listDocu.do',
          type05: '상품공시-공시안내 처브라이프생명 (chubblife.co.kr)',
          type05URL: 'https://www.chubblife.co.kr/front/official/sale/list.do',
          type06: '1599-4600',
          type07: '1599-4646',
        },
        {
          name: '/img/푸본현대_logo.jpg',
          type01: '푸본현대생명 - Login (fubonhyundai.com)',
          type01URL: 'https://wsfa.fubonhyundai.com/GA/index_etc.jsp',
          type02: '1577-3311',
          type03: '0505-106-0311',
          type04: '보험금청구안내 | 푸본현대생명 (fubonhyundai.com)',
          type04URL: 'https://www.fubonhyundai.com/#CUSI070100000000',
          type05: '상품목록 | 푸본현대생명 (fubonhyundai.com)',
          type05URL: 'https://www.fubonhyundai.com/#CUSI150102010101',
          type06: '',
          type07: '080-860-1212',
        },
        {
          name: '/img/HEUNGKUK생명_logo.jpg',
          type01: '흥국생명 e-라이프 (heungkuklife.co.kr)',
          type01URL: 'https://e-life.heungkuklife.co.kr/webfw/html/nawlogon.html',
          type02: '1588-2288',
          type03: '가상팩스 부여',
          type04: '사고보험금 구비서류 | 고객센터 | 흥국생명 (heungkuklife.co.kr)',
          type04URL: 'https://www.heungkuklife.co.kr/jsps/front/help/customer_require_tab.jsp',
          type05: '판매상품 | 상품공시 | 공시실 | 흥국생명 (heungkuklife.co.kr)',
          type05URL: 'https://www.heungkuklife.co.kr/front/public/saleProduct.do?searchFlgSale=Y',
          type06: '1877-7006',
          type07: '031-786-8088',
        },
        {
          name: '/img/HANHWA생명_logo.jpg',
          type01: '한화생명 GA WORLD (hanwhalife.com)',
          type01URL:
            'https://hmp.hanwhalife.com/online/solutions/websquare/websquare.html?w2xPath=/online/ui/uv/gmn/uvgmn010mvw.xml',
          type02: '1588-6363',
          type03: '가상팩스 부여',
          type04: '신청서류 < 사고보험금 신청/조회 < 보험 < 마이페이지 | 한화생명 (hanwhalife.com)',
          type04URL: 'https://www.hanwhalife.com/static/main/myPage/insurance/accident/document/MY_INAPADC_T10000.jsp',
          type05: '판매상품 < 상품목록 < 상품목록 < 상품공시실 < 공시실 | 한화생명 (hanwhalife.com)',
          type05URL:
            'https://www.hanwhalife.com/main/disclosure/goods/disclosurenotice/DF_GDDN000_P10000.do?MENU_ID1=DF_GDGL000',
          type06: '1800-6633',
          type07: '02-1522-6379',
        },
      ],
      headers3: [
        {
          text: '보험회사',
          align: 'center',
          sortable: false,
          value: 'name',
          width: '130px',
        },
        {
          text: 'GA 사용자 전산',
          align: 'center',
          sortable: false,
          value: 'type01',
          width: '120px',
        },
        {
          text: '대표전화',
          align: 'center',
          sortable: false,
          value: 'type02',
          width: '100px',
        },
        {
          text: '보험금 청구 팩스',
          align: 'center',
          sortable: false,
          value: 'type03',
          width: '130px',
        },
        {
          text: '보험금 청구서 양식',
          align: 'center',
          sortable: false,
          value: 'type04',
          width: '140px',
        },
        {
          text: '약관',
          align: 'center',
          sortable: false,
          value: 'type05',
          width: '80px',
        },
        {
          text: '모니터링 (인콜)',
          align: 'center',
          sortable: false,
          value: 'type06',
          width: '120px',
        },
        {
          text: '헬프 데스크',
          align: 'center',
          sortable: false,
          value: 'type07',
          width: '130px',
        },
      ],
      items3: [
        {
          name: '/img/MG새마을금고_logo.jpg',
          type01: 'MG 새마을금고 공제홈페이지 (kfcc.co.kr)',
          type01URL: 'https://insu.kfcc.co.kr/main.do',
          type02: '1599-9010',
          type03: '가상팩스 부여',
          type04: '공제금청구절차 및 필요서류안내 < 공제금청구조회 < 사이버창구 < 새마을금고공제 (kfcc.co.kr)',
          type04URL: 'https://insu.kfcc.co.kr/ics/cybIcsChargeProcessinfo.do',
          type05: '공시안내 < 상품공시실 < 공시실 < 새마을금고공제 (kfcc.co.kr)',
          type05URL: 'https://insu.kfcc.co.kr/ino/inoGuide.do',
          type06: '',
          type07: '',
        },
        {
          name: '/img/우체국보험_logo.jpg',
          type01: 'HOME | 인터넷보험 (epostlife.go.kr)',
          type01URL: 'https://epostlife.go.kr/MNDMDM0000.do',
          type02: '1599-0100',
          type03: '콜센터',
          type04: '서식 | 인터넷보험 (epostlife.go.kr)',
          type04URL: 'https://epostlife.go.kr/IPDSDC0001.do',
          type05: '키워드로 찾기 | 우체국 금융상품몰 (epostbank.go.kr)',
          type05URL: 'https://mall.epostbank.go.kr/IPPSKE0000.do',
          type06: '',
          type07: '',
        },
        {
          name: '/img/수협_logo.jpg',
          type01: '개인뱅킹 - 수협은행 개인뱅킹 [PB04] (suhyup-bank.com)',
          type01URL: 'https://www.suhyup-bank.com/',
          type02: '1588-4119',
          type03: '가상팩스 부여',
          type04: '구비서류 안내 - 수협은행 개인뱅킹 [PB04] (suhyup-bank.com)',
          type04URL: 'https://www.suhyup-bank.com/',
          type05: '공제보험 전체상품 - 수협은행 [PB04] (suhyup-bank.com)',
          type05URL: 'https://www.suhyup-bank.com/',
          type06: '',
          type07: '',
        },
        {
          name: '/img/신협_logo.jpg',
          type01: '공제 < 신협 개인뱅킹 (cu.co.kr)',
          type01URL: 'https://openbank.cu.co.kr/?sub=6000',
          type02: '1544-3030',
          type03: '가상팩스 부여',
          type04: '사고공제금 구비서류 안내 < 공제금청구절차 < 공제금청구안내 < 공제 < 신협 개인뱅킹 (cu.co.kr)',
          type04URL: 'https://openbank.cu.co.kr/?sub=6000',
          type05: '판매중 상품 < 공제약관 < 상품공시실 < 공제 < 신협 개인뱅킹 (cu.co.kr)',
          type05URL: 'https://openbank.cu.co.kr/?sub=6000',
          type06: '',
          type07: '',
        },
      ],
    };
  },
};
</script>
<style scoped>
/* @import "/sass/common/main.scss"; */
    .body {
      background-color: aqua;
    }
</style>