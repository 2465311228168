<template>
  <div class="jh-grid-box">
    <div v-if="item.USE_YN == 'Y'" v-for="(item, index) in brInfo" :key="index" class="jh-form-wrap">
      <div class="jh-ui-header">
        <h2 class="has-bullet">{{item.BR_NM}}</h2>
        <div class="is-right">
          <v-btn class="jh-btn has-icon is-light" @click="btnClick(item, index+1)"><span>약도</span><i class="jh-icon-map"></i></v-btn>
        </div>
      </div>
      <div v-html="item.BR_CONT" class="is-center-info"></div>
    </div>

    <v-dialog
        v-model="dialogMap"
        content-class="jh-dialog-wrap"
        max-width="750">
        <div class="jh-dialog-header">
            <h1>{{selectedText}}</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="dialogMap=false"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </div>
        <div class="jh-dialog-body">
          <p class="jh-no-data" v-if="noData"><span>등록된 약도가 없습니다.</span></p>
          <img :src="selectedUrl" style="max-width: 100%; max-height: 700px" v-if="!noData">
        </div>
    </v-dialog>
  </div>
</template>

<script>

  import { mixin } from "@/mixin/mixin.js";

  export default {
    name: "MENU_T100101", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],

    components: {
        
    },

    props: [ "evt" ],
    
    data() {
      return {
        noData: false,
        MAP: false,
        // btnMap: [],
        selectedBtn: null,
        selectedUrl: null,
        selectedText: null,
        dialogMap: false,

        ORIGINAL_FILENAME : '',
        brInfo: [],

      }
    },
    created() { 
        this.selectBrInfo();
    },
    mounted() {
      this.init();
      
    },
    computed: {
      initHeaders(){
        return {
          SERVICE: 'setting.system.cmmn-brInfo-manage',
          METHOD: "",
          TYPE: 'BIZ_SERVICE',
        };
      },
    },
    watch: {
        
    },
    methods: {
      init: function() {
      },
      
      closeModal: function() {
          this.$parent.closeModal('slide8');
      },
      btnClick(item, index){
        this.dialogMap = true;

        this.selectedText = item.BR_NM;

        if(item.ORIGINAL_FILENAME == '') {
          this.noData = true;
        } else {
          this.noData = false;
          this.getFileImage(item.FILE_GROUP_KEY, item.FILE_KEY);
        }

        if(this.selectedBtn === index){
          this.selectedBtn = null;
          this.MAP = false;
        }else{
          this.MAP = true;
          this.selectedBtn = index;
        }
      },

      async selectBrInfo(){

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
        };

        requestData.headers["URL"] = "/api/setting/agent/brInfo/selectBrInfoPop";
        requestData.headers["METHOD"] = "select";
        requestData.headers["ASYNC"] = false;

        const response = await this.common_postCall(requestData);

        if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
          this.brInfo = response.DATA;
        } else {
          if (response.HEADER.ERROR_FLAG == true) {
            this.common_alert('시스템 오류가 발생하였습니다.', "error");
            return false;
          }
        }

      },
      async getFileImage(fileGroupKey, fileKey) {
        let data = await this.mixin_imageView('brch', 'images', [{FILE_GROUP_KEY: fileGroupKey, FILE_KEY: fileKey}]);
        console.log("data");
        console.log(data);

        this.selectedUrl = data[0]?.src;
      },
    },
    
  }
</script>

<style scoped>
</style>