<template>
    <div class="guide-content"> 
        <div class="guide-content-header">Patterns<v-icon class="mdi mdi-chevron-right"></v-icon><span>Block</span></div>

        <!-- <h2>Default</h2> -->
        <div class="guide-card is-mt-10">
            <div class="jh-block jh-cols">
                <label class="jh-label">등록일자 :</label>
                <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" style="max-width:90px" readonly value="2022-00-00">
                <label class="jh-label">OB명 :</label>
                <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" readonly value="현대자동차">
                <v-btn class="jh-btn">엑셀다운로드</v-btn>
            </div>
        </div>
        <div class="guide-source">
            <v-tabs v-model="source01">
                <v-tab>Html</v-tab>
            </v-tabs>
            <v-tabs-items v-model="source01">
                <v-tab-item>
                    <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
                </v-tab-item>
            </v-tabs-items>
        </div>

        <div class="guide-card is-mt-20">
            <div class="jh-block is-bg-light-yellow jh-cols">
                <label class="jh-label">고객번호 :</label>
                <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" style="max-width:90px" readonly value="00384568">
                <label class="jh-label">고객명 :</label>
                <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" readonly value="임면양">
            </div>
        </div>
        <div class="guide-source">
            <v-tabs v-model="source02">
                <v-tab>Html</v-tab>
            </v-tabs>
            <v-tabs-items v-model="source02">
                <v-tab-item>
                    <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
                </v-tab-item>
            </v-tabs-items>
        </div>

    </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Table",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {  
        source01: 'HTML',
        source02: 'HTML',
        content:{

source01_tab01: `<div class="jh-block jh-cols">
    <label class="jh-label">등록일자 :</label>
    <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" style="max-width:90px" readonly value="2022-00-00">
    <label class="jh-label">OB명 :</label>
    <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" readonly value="현대자동차">
    <v-btn class="jh-btn">엑셀다운로드</v-btn>
</div>`
,source01_tab02: `export default {
  data () {
    return {  
    }
  },
  methods: {
    //팝업 닫기
    close(){
        self.close();
    },
  },
}`
,source02_tab01: `<div class="jh-block is-bg-light-yellow jh-cols">
    <label class="jh-label">고객번호 :</label>
    <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" style="max-width:90px" readonly value="00384568">
    <label class="jh-label">고객명 :</label>
    <input type="text" class="jh-form is-md is-trns is-bold is-txt-blue is-p-0" readonly value="임면양">
</div>`
,source02_tab02: `export default {
  data () {
    return {  
    }
  },
  methods: {
    //팝업 닫기
    close(){
        self.close();
    },
  },
}`
      }
    }
  },
  methods: {
    listClick: function(item){
        if(this.selectedItem === item){
            this.selectedItem = null;
        }else{
            this.selectedItem = item;
        }
    } 
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>