const toastStore = {
  namespaced: true,
  state: {
    toast: {
      act: false,
      msg: "",
      class: "",
      hasToastIcon: false,
      icon: "",
    },
  },
  getters: {
    GE_TOAST: (state) => state.toast,
  },
  mutations: {
    POP(state, toast) {
      state.toast = toast;
    },
  },
};

export default toastStore;
