<template>
  <!-- <transition name="slide">
    <div class="jh-quick-layer slide-right">
      <div class="jh-quick-layer-header" @click="closeModal()">
        <h1>매수청구</h1>
        <div class="is-right">
          <v-btn class="jh-btn is-icon">
          <i class="jh-icon-close-md"></i></v-btn>
        </div>
      </div> -->
      <!-- <div class="jh-quick-layer-body is-pb-0"> -->
      <div>
        <!--조회-->
        <div class="jh-search-form">
          <table>
            <colgroup>
              <col width="55px">
              <col width="100px">
              <col width="70px">
              <col width="100px">
              <col width="75px">
              <col width="115px">
              <col width="110px">
              <col width="100px">
              <col width="85px">
              <col width="100px">
              <col>
            </colgroup>
            <tr>
              <th><label>등록일자</label></th>
              <td colspan="3">
                <div class="jh-cols">
                  <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                      maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                    </template>
                    <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                  </v-menu>
                  <span class="jh-unit">~</span>                       
                  <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                      maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                    </template>
                    <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                  </v-menu>
                </div>
              </td>
              <th><label>담당자</label></th>
              <td><v-select class="jh-form" :items="ATTR_A_COMBO" item-text="CD_NM"   item-value="CD"      v-model="ATTR_A"></v-select></td>
              <td><v-select class="jh-form" :items="ATTR_B_COMBO" item-text="CD_NM"   item-value="CD"      v-model="ATTR_B"></v-select></td>
              <td><v-select class="jh-form" :items="USER_COMBO"   item-text="USER_NM" item-value="USER_ID" v-model="searchValue.MNGR_ID"></v-select></td>
              <td colspan="3"><p class="jh-info-txt is-fill is-red is-ml-10"><b>업로드일자</b>를 조회해주세요</p></td>
            </tr>
            <tr>
              <th><label>종목명</label></th>
              <td colspan="3"><input type="text" class="jh-form" v-model="searchValue.OB_EVNT_DETL" @keyup.enter="searchList"></td>
              <th><label class="is-required">업로드일자</label></th>
              <td>                
                <v-menu v-model="menuUploadDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="inputDate" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuUploadDate = false"
                    maxlength="10" @input="dateOnInput" @click:clear="date = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                  </template>
                  <v-date-picker class="jh-calendar" v-model="date" @input="menuUploadDate = false" no-title locale="ko-KR" :min="dates[0]" :max="dates[1]" :day-format="mixin_getDate" @change="dateOnChange"></v-date-picker>
                </v-menu>
              </td>
              <th><label>통보결과1차</label></th>
              <td>
                <v-select 
                  class="jh-form"
                  :items="CRS000_COMBO"
                  item-text="CD_NM"
                  item-value="CD"
                  v-model="searchValue.NTFCTN_1st" 
                  placeholder="선택"
                >
                </v-select>
              </td>
              <th><label>통보결과N차</label></th>
              <td>
                <v-select class="jh-form" 
                  :items="CRS000_COMBO"
                  item-text="CD_NM"
                  item-value="CD"
                  v-model="searchValue.NTFCTN_Nth"
                  placeholder="선택"
                >
                </v-select>
              </td>
            </tr>
            <tr>
              <th><label>계좌번호</label></th>
              <td><input type="text" class="jh-form" v-model="searchValue.CUST_ACTNO" @keyup.enter="searchList" oninput="this.value = this.value.replace(/[^0-9]/g, '');"></td>
              <th><label>고객번호</label></th>
              <td><input type="text" class="jh-form" v-model="searchValue.CUST_NO" @keyup.enter="searchList"></td>
              <th><label>고객명</label></th>
              <td><input type="text" class="jh-form" v-model="searchValue.CUST_NM" @keyup.enter="searchList"></td>
              <th><label>고객등급</label></th>
              <td>
                <v-select class="jh-form"
                  :items="DS38_COMBO"
                  item-text="CD_NM"
                  item-value="CD"
                  v-model="searchValue.CUST_GRD"
                  placeholder="선택"
                >
                </v-select>
              </td>
              <th><label>신청유무</label></th>
              <td>
                <v-select class="jh-form"
                  :items="OB02_COMBO"
                  item-text="CD_NM"
                  item-value="CD"
                  v-model="searchValue.APLY_TYPE"
                  placeholder="선택"
                >
                </v-select>
              </td>
              <td class="has-search is-jh-10"><v-btn class="jh-btn is-search" @click="searchList">조회</v-btn></td>
            </tr>
          </table>
        </div>
        <!--//조회-->

        <div class="jh-form-wrap">
          <div class="jh-ui-header">
            <h2>매수청구 OB 리스트</h2>
            <div class="is-right">
              <v-btn class="jh-btn is-fill" :loading="isLoading" @click="save">저장</v-btn>
              <v-btn class="jh-btn" @click="excelDownload('OBList03', '매수청구 OB 리스트', '매수청구 OB 리스트')">엑셀다운로드</v-btn>
            </div>
          </div>
          <data-tables
            ref="dataTable"
            id="OBList03"
            dataTableClass="has-control"
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            @dblclick:row="onClickRow2"
            v-model="selectedRows"
          >
            <!-- 신청유무 -->
            <template v-slot:item.APLY_TYPE="{ item }">
              <v-select class="jh-form" :items="OB02_COMBO2" item-text="CD_NM" item-value="CD" v-model="item.APLY_TYPE" @dblclick.stop></v-select>
            </template>
            <!-- 비고 -->
            <template v-slot:item.REM="{ item }">
              <input type="text" class="jh-form" v-model="item.REM" @dblclick.stop>
              <span style="display: none;">{{ item.REM }}</span>
            </template>
            <!-- 매수청구 수량 -->
            <template v-slot:item.BUYN_QNT="{ item }">
              <input type="text" class="jh-form is-txt-right" v-model="item.BUYN_QNT" @dblclick.stop oninput="this.value = this.value.replace(/^0+|\D+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');">
              <span style="display: none;">{{ item.BUYN_QNT }}</span>
            </template>
            <!-- 업로드일자 -->
            <template v-slot:item.UPLOAD_DTTM="{ item }">
              {{ mixin_dateFormat(item.UPLOAD_DTTM) }}
            </template>
          </data-tables>
        </div>
        
      </div>
    <!-- </div>
  </transition> -->
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import api from "@/store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

export default {
  name: "MENU_T100401", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],

  components: {
    dataTables
  },

  props: [ "evt" ],
  
  data() {
    return {
      isLoading       : false,

      menuStartDate   : false,
      menuEndDate     : false,
      menuUploadDate  : false,

      ID       : "",
      OB_List  : "",

      items           : [],
      commonCodeItems : [],
      selectedRows    : [],
      gridListDataText: [],

      ATTR_A_COMBO    : [{CD_NM  : "전체", CD     : ""}], 
      ATTR_B_COMBO    : [{CD_NM  : "전체", CD     : ""}], 
      USER_COMBO      : [{USER_NM: "전체", USER_ID: ""}],

      DS38_COMBO      : [{CD_NM  : "전체", CD     : ""}],
      CRS000_COMBO    : [{CD_NM  : "전체", CD     : ""}],
      OB02_COMBO      : [{CD_NM  : "전체", CD     : ""}],
      OB02_COMBO2     : [], 

      ATTR_A        : "",
      ATTR_B        : "",
    
      searchValue: {
        MNGR_ID     : "",
        OB_EVNT_DETL: "",
        NTFCTN_1st  : "",
        NTFCTN_Nth  : "",
        CUST_ACTNO  : "",
        CUST_NO     : "",
        CUST_NM     : "",
        CUST_GRD    : "",
        APLY_TYPE   : "",
      },

      userInfo: {
        USER_ID    : this.$store.getters["userStore/GE_USER_ROLE"].userId,
        USER_NM    : this.$store.getters["userStore/GE_USER_ROLE"].userName,
        USER_ATTR_A: this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A,
        USER_ATTR_B: this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_B,
        USER_ATTR_C: this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_C,
      },

      // 기간 조회 초기값: 시작일(오늘 기준 일주일전), 마지막일(오늘) - 요건에 따라 수정해준다.
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      dates: [
        //moment().subtract(1, "weeks").add(1, "days").format("YYYY-MM-DD"),
        // moment().subtract(6, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      // 업로드일자 초기값 (오늘)
      inputDate: moment().format("YYYY-MM-DD"),
      date: moment().format("YYYY-MM-DD"),
      
      headers: [
        { text: 'No', value: 'index', align: 'center', width: '30px', sortable:false},
        { text: 'OB명', value: 'OB_EVNT', width: '120px', },
        { text: '종목명', value: 'OB_EVNT_DETL', width: '120px', },
        { text: '고객등급', value: 'CUST_GRD_NM', align: 'center', width: '90px', },
        { text: '고객번호', value: 'CUST_NO', align: 'center', width: '70px', },
        { text: '계좌번호', value: 'CUST_ACTNO', align: 'center', width: '110px', },
        { text: '고객명', value: 'CUST_NM', align: 'center', width: '80px', },
        { text: '신청유무', value: 'APLY_TYPE', align: 'center', width: '110px', sortable:false},
        { text: '1차결과', value: 'NTFCTN_1st_NM', align: 'center', width: '80px', },
        { text: 'N차결과', value: 'NTFCTN_Nth_NM', align: 'center', width: '80px', },
        { text: '통보처리일시', value: 'NTFCTN_DTTM', align: 'center', width: '90px', },
        { text: '담당자 사번', value: 'MNGR_ID', align: 'center', width: '60px', },
        { text: '담당자', value: 'MNGR_NM', align: 'center', width: '80px', },
        { text: '통보직원', value: 'NTFCTN_NM', align: 'center', width: '80px', },
        { text: '비고', value: 'REM', align: 'center', width: '130px', sortable:false},
        { text: '업로드일자', value: 'UPLOAD_DTTM', align: 'center', width: '80px', },
        { text: '반대통보 신청수량', value: 'OPP_NTFCTN_QNT', align: 'right', width: '70px', },
        { text: '매수청구 가능수량', value: 'BUYN_POSL_QNT', align: 'right', width: '75px', },
        { text: '매수청구 수량', value: 'BUYN_QNT', align: 'right', width: '75px', },
      ],
      items: [],

      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '610px',
        height: '595px',
        items: undefined,
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        showSelect: true,
        disablePagination: true,
      },  
      
      dataDisabled: {
        APLY_TYPE : true,
      },

      alertMsg: {
        responseError: "시스템에 오류가 발생하였습니다.",
      },
    }
  },

  created() { 
    this.dataTableOptions.headers = this.headers;
  },
  mounted() {
    this.init();
    // this.initMain();
    this.getAttrList();
    this.getAgentYN();
    this.getSelBoxList();
  },
  watch: {
    "ATTR_A": async function (val) {
      this.searchValue.MNGR_ID = '';
      this.ATTR_B = '';
      
      if (val == '') {
        this.ATTR_B_COMBO = [{CD: '', CD_NM: '전체'}];
      } else {
        this.ATTR_B_COMBO = await this.mixin_get_attr_by_attr('', val);
      }
    },
    "ATTR_B": async function (val) {
      this.searchValue.MNGR_ID = '';
      
      if (val == '') {
        this.USER_COMBO = [{USER_ID: '', USER_NM: "전체"}];
      } else {
        this.USER_COMBO = await this.mixin_get_user_by_attr(this.ATTR_A, val);
      }
    },
  },
  computed: {
    dataTableInstance() {
      return this.$refs.dataTable;
    },
    ...mapGetters({
      atrtGroupId  : "userStore/GE_USER_AUTHOR_GROUP_ID",
      geCallStt: 'callStore/GE_CALL_STATE',
      geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
    }),
    initHeaders(){
      return {
        SERVICE: 'setting.system.cmmn-CmpgnAplyWork-manage',
        METHOD: "",
        TYPE: 'BIZ_SERVICE',
      };
    },
  },
  methods: {
    init() {
      // let that = this;
      // this.evt.$on("open-slide6", () => {
      //   // 슬라이드 열 때 실행
      //   this.dates[0]  = moment().subtract(6, "days").format("YYYY-MM-DD");
      //   this.dates[1]  = moment().format("YYYY-MM-DD");
      //   this.date      = moment().format("YYYY-MM-DD");
      //   this.from      = moment().subtract(6, "days").format("YYYY-MM-DD");
      //   this.to        = moment().format("YYYY-MM-DD");
      //   this.inputDate = moment().format("YYYY-MM-DD");
      // });
      // this.evt.$on("close-slide6", () => {
      //   // 슬라이드 닫을 때 실행
      //   // 입력필드, 그리드 초기화
      //   this.ATTR_A = '';
      //   this.ATTR_B = '';

      //   this.dataTableOptions.items   = [];
      //   this.searchValue.MNGR_ID      = "";
      //   this.searchValue.OB_EVNT_DETL = "";
      //   this.searchValue.CUST_ACTNO   = "";
      //   this.searchValue.CUST_NO      = "";
      //   this.searchValue.CUST_NM      = "";
      //   this.searchValue.CUST_GRD     = "";
      //   this.searchValue.NTFCTN_1st   = "";
      //   this.searchValue.NTFCTN_Nth   = "";
      //   this.searchValue.APLY_TYPE    = "";
      // });
    },

    // 셀렉트박스 공통코드 조회
    async initMain(){
      let pArr = ['OB00', 'CRS000', 'DS38', 'OB02']
      this.commonCodeItems = await this.mixin_common_code_get_all(pArr)
    },

    async getAttrList() {
        this.ATTR_A_COMBO = await this.mixin_get_attr_by_attr('****', '');
    },

    // 조회 버튼 
    searchList () 
    {
      this.selectedRows = [];
      
      this.setDataTableParams();
      this.getGridList();
    },

    setDataTableParams() {
      this.requestData = {
        headers: {},
        sendData: {},
      };

      // header 세팅
      this.requestData.headers["URL"]      = "/api/phone/ob/manage/typeOb";
      this.requestData.headers["SERVICE"]  = "phone.ob.manage";
      this.requestData.headers["METHOD"]   = "typeOb";
      this.requestData.headers["ASYNC"]    =  false;
      this.requestData.headers["TYPE"]     = "BIZ_SERVICE";

      // sendData 세팅
      this.requestData.sendData["MNGR_ID"]      = this.searchValue.MNGR_ID;        // 담당자ID
      this.requestData.sendData["OB_TYPE"]      = "03";                            // OB유형 03: 매수청구
      this.requestData.sendData["UPLOAD_DTTM"]  = this.date.replace(/\-/g, '')     // 업로드일자
      this.requestData.sendData["OB_EVNT_DETL"] = this.searchValue.OB_EVNT_DETL;   // 종목명
      this.requestData.sendData["CUST_ACTNO"]   = this.searchValue.CUST_ACTNO;     // 계좌번호
      this.requestData.sendData["CUST_NO"]      = this.searchValue.CUST_NO;        // 고객번호
      this.requestData.sendData["CUST_NM"]      = this.searchValue.CUST_NM;        // 고객명
      this.requestData.sendData["CUST_GRD"]     = this.searchValue.CUST_GRD;       // 고객등급
      this.requestData.sendData["NTFCTN_1st"]   = this.searchValue.NTFCTN_1st;     // 통보결과1차
      this.requestData.sendData["NTFCTN_Nth"]   = this.searchValue.NTFCTN_Nth;     // 통보결과N차
      this.requestData.sendData["APLY_TYPE"]    = this.searchValue.APLY_TYPE;      // 신청유무
      this.requestData.sendData['USER_ATTR_A']  = this.ATTR_A;
      this.requestData.sendData['USER_ATTR_B']  = this.ATTR_B;

      // 업로드일자가 없으면 등록일자로 조회
      if(this.date == "" || this.date == null){
        this.requestData.sendData.FROM          = this.dates[0];                   // 등록일자 - FROM: 시작일 
        this.requestData.sendData.TO            = this.dates[1];                   // 등록일자 - TO  : 종료일
      }
    },

    getGridList(loadOptions) {
      this.common_postCall(this.requestData).then(response => {
        let resHeader = response.HEADER;
        let resData = response.DATA;
        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          return false;
        }

        // 날짜 세팅 -> 시간까지
        _.each(resData, (item) => {
          //item.REG_DTTM = item.REG_DTTM.substr(0, 19);
          item.NTFCTN_DTTM = item.NTFCTN_DTTM.substr(0, 19); // 통보처리일시
          item.CUST_ACTNO = item.CUST_ACTNO.indexOf('-') < 0 ? this.mixin_accNoFormat(item.CUST_ACTNO) : item.CUST_ACTNO;
        });

        // 날짜 세팅 -> 날짜까지
        //  _.each(resData, (item) => {
        //   item.REG_DTTM = item.REG_DTTM.substr(0, 10);
        // });
        
        // 순번 세팅
        this.dataTableOptions.items = resData.map(
          (item, index) => ({
          ...item,
          index: index + 1
        }));
      });
    },

    // 저장버튼
    save(){
      if(this.selectedRows == ""){
        this.common_alert("선택된 항목이 없습니다.", "noti");
      }else{
        this.common_confirm('저장하시겠습니까?', this.saveOb, null, null, null, 'chk');
      }
    },

    // 저장
    saveOb(){
      // header 세팅
      let requestData = {
        headers: {
          ASYNC  : false,
          METHOD : "updatetypeOb",
          SERVICE: "phone.ob.manage",
          TYPE   : "BIZ_SERVICE",
          URL    : "/api/phone/ob/manage/updatetypeOb"
        },
        sendData: {},
      };

      // sendData 세팅
      requestData.sendData.MNGR_ID = this.userInfo.USER_ID;        // 담당자 ID
      requestData.sendData.OB_List = this.selectedRows;            // 선택된 ROW

      this.common_postCall(requestData).then(res => {
        if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
            this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
            return false;
        }
        this.common_alert("정상 처리되었습니다.", "done");
        this.searchList();
        this.selectedRows = [];

      }).finally(() => {
        this.isLoading = false;
      });
    },

    // 엑셀다운로드
    excelDownload(table_id, file_name, sheet_name) {
      //number,date,time,string - n,d,t,s
            var exceltypeArr = {};
            exceltypeArr[0] = "s";
            exceltypeArr[1] = "n";
            exceltypeArr[2] = "s";
            exceltypeArr[3] = "s";
            exceltypeArr[4] = "s";
            exceltypeArr[5] = "s";
            exceltypeArr[6] = "s";
            exceltypeArr[7] = "s";
            exceltypeArr[8] = "s";
            exceltypeArr[9] = "s";
            exceltypeArr[10] = "s";
            exceltypeArr[11] = "s";
            exceltypeArr[12] = "s";
            exceltypeArr[13] = "s";
            exceltypeArr[14] = "s";
            exceltypeArr[15] = "s";
            exceltypeArr[16] = "s";
            exceltypeArr[17] = "n";
            exceltypeArr[18] = "n";
            exceltypeArr[19] = "n";



      this.table_id = table_id;
      this.file_name = file_name;
      this.sheet_name = sheet_name;
      this.mixin_common_exportExcel(exceltypeArr);
    },

    closeModal() {
        this.$parent.closeModal('slide6');
    },
    fromOnInput() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    changeDate(type) {
      if(type == 'start') {
        if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(90, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[0] = date;
          this.from = date;
          return;
        } else {
          this.from = this.dates[0];
        }
      } else if (type == 'end') {
        if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(90, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[1] = date;
          this.to = date;
          return;
        } else {
          this.to = this.dates[1];
        }
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }
        
        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
    dateOnInput() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.inputDate) && moment(this.inputDate).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.inputDate).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.inputDate = this.dates[0];
          });
        }
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        else if (moment(this.inputDate).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.inputDate = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.date = this.inputDate;
          // datepick 다시 랜더링
          this.menuUploadDate = false;
          this.menuUploadDate = true;
        });
      }
    },
    dateOnChange(e) {
      this.inputDate = e;
    },
    onClickRow(item, row) {
    },

    // 로우 더블 클릭
    onClickRow2(e, { item }) {
      // 통화성공 판단 -> NTFCTN_1st, NTFCTN_Nth = 01: 통화성공
      if(item.NTFCTN_1st == "01" || item.NTFCTN_Nth == "01"){
        this.common_alert("통화성공건은 진행할 수 없습니다.", "noti");
        return;
      }
    
      // 콜상담 메인 고객정보 연동
      let custInfo = {};
      custInfo.SRCH_ACCOUNT = item.CUST_ACTNO.replace(/\-/g, '');  // 고객 계좌번호
      custInfo.SRCH_CL_CID  = item.CUST_NO;     // 고객 번호
      custInfo.ID           = item.ID           // OB ID
      custInfo.NTFCTN_1st   = item.NTFCTN_1st;  // 통화결과 1차
      custInfo.NTFCTN_Nth   = item.NTFCTN_Nth;  // 통화결과 N차
      custInfo.CNSL_HIST_NO = item.CNSL_HIST_NO // 상담이력번호
      custInfo.IVR_IN_NO = '';
      custInfo.IVR_IN_PATH = '';
      custInfo.IVR_STD_TIME = '';
      custInfo.IVR_MENT = '';
      custInfo.message = 'T100401';

      console.log(custInfo);
      window.opener.postMessage(custInfo, '*');
    },

    isdisabled(item, isSelected) {
      console.log(item, isSelected)

      return true;
    },

    async getAgentYN() {
      // 상담원일 경우 세팅
      if (this.atrtGroupId == '20220826113539330TWBaSY4N') {
        this.agentYN = true;
        this.ATTR_A = this.userInfo.USER_ATTR_A;
        if (this.ATTR_A != '011') {
          this.ATTR_B_COMBO = await this.mixin_get_attr_by_attr('', this.userInfo.USER_ATTR_A);
          this.ATTR_B = this.userInfo.USER_ATTR_B;
          this.USER_COMBO = await this.mixin_get_user_by_attr(this.ATTR_A, this.ATTR_B);
        } else {
          this.USER_COMBO = await this.mixin_get_user_by_attr(this.ATTR_A, '');
        }
        this.searchValue.MNGR_ID = this.userInfo.USER_ID;
      }
    },

    async getSelBoxList() {
      let requestData = {
          headers: {
              SERVICE: this.initHeaders.SERVICE,
              METHOD: this.initHeaders.METHOD,
              TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
      };

      requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
      requestData.headers["METHOD"] = "selectCode";
      requestData.headers["ASYNC"] = false;

      let sendList = [];
      sendList.push("DS38"); //고객 등급
      sendList.push('CRS000');
      sendList.push('OB02');

      requestData.sendData["GROUP_CD"] = sendList

      const response = await this.common_postCall(requestData);

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
        for (var i = 0; i < response.DATA.length ; i++){
          if (response.DATA[i].GROUP_CD == "DS38"){
            this.DS38_COMBO.push(response.DATA[i]);
          } else if (response.DATA[i].GROUP_CD == "CRS000"){
            this.CRS000_COMBO.push(response.DATA[i]);
          } else if (response.DATA[i].GROUP_CD == "OB02"){
            this.OB02_COMBO.push(response.DATA[i]);
            this.OB02_COMBO2.push(response.DATA[i]);
          }
        }
      }
    },

  },

}
</script>

<style scoped>
</style>