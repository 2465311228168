<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Card</span></div>

      <h2>Default</h2>
      <div class="jh-card">
		<div class="jh-card-header">
			<h1>제목</h1>
		</div>
		<div class="jh-card-body">
			<ul class="jh-list-dot">
			    <li><em>강조</em> <strong>리스트</strong> 내용</li>
			    <li><em>강조</em> <strong>리스트</strong> 내용</li>
			    <li><em>강조</em> <strong>리스트</strong> 내용</li>
			    <li><em>강조</em> <strong>리스트</strong> 내용</li>
			    <li><em>강조</em> <strong>리스트</strong> 내용</li>
			</ul>
		</div>
		<div class="jh-card-footer">
			<button type="button" class="jh-btn is-md" id="" name="">초기화</button>
		    <button type="button" class="jh-btn is-md is-del" id="" name="">삭제</button>
		    <button type="button" class="jh-btn is-md is-main" id="" name="">저장</button>
		</div>
	</div>
    <div class="guide-source">
    <v-tabs v-model="source01">
        <v-tab>Html</v-tab>
    </v-tabs>
    <v-tabs-items v-model="source01">
        <v-tab-item>
        <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
        </v-tab-item>
    </v-tabs-items>
    </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Table",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {    
        dialog: false,
        source01: 'HTML',
        content:{

source01_tab01: `<table class="jh-tbl-detail">
    <colgroup>
        <col width="90px">
        <col>
    </colgroup>
    <tbody>
        <tr>
            <th><label class="required">고객명</label></th>
            <td> 
                <span class="is-value">d7wv3tk5r</span>
            </td>
        </tr>
        <tr>
            <th><label>최근문의일시</label></th>
            <td> 
                <span class="is-value">2020년 4월 30일 12:30:22</span>
            </td>
        </tr>
        <tr>
            <th><label>주의고객여부</label></th>
            <td> 
                <v-checkbox
                    v-model="checkbox"
                    label="주의고객"
                    color="var(--color-theme)"
                ></v-checkbox>
            </td>
        </tr>
        <tr>
            <th><label>메모</label></th>
            <td>                               
                <v-textarea
                   
                    noResize
                    label="메모내용을 입력하세요."
                    style="height:100px"
                >
                </v-textarea>
            </td>
        </tr>
    </tbody>
</table>`
,source02_tab01: `<table class="jh-tbl-detail">
    <colgroup>
        <col width="20%">
        <col>
        <col>
        <col>
    </colgroup>
    <thead>
        <th><label>고객명</label></th>
        <th><label>최근문의일시</label></th>
        <th><label>상담결과</label></th>
        <th><label>상담유형</label></th>
    </thead>
    <tbody>
        <tr>
            <td> 
                <v-text-field
                    label="고객명"
                    single-line
                   
                ></v-text-field>
            </td>
            <td> 
                <v-text-field
                    label="최근문의일시"
                    single-line
                   
                ></v-text-field>
            </td>
            <td> 
                <v-select
                    :items="items"
                    label="선택"
                   
                ></v-select>
            </td>
            <td> 
                <v-select
                    :items="items"
                    label="선택"
                   
                ></v-select>
            </td>
        </tr>
    </tbody>
</table>`
      }
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>