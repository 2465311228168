<template>
  <!-- <transition name="slide">
    <div class="jh-quick-layer slide-right">
      <div class="jh-quick-layer-header" @click="closeModal()">
        <h1>MY예약</h1>
        <div class="is-right">
          <v-btn class="jh-btn is-icon">
          <i class="jh-icon-close-md"></i></v-btn>
        </div>
      </div> -->
      <!-- <div class="jh-quick-layer-body"> -->
      <div>
        <!--조회-->
        <div class="jh-search-form">
          <table>
            <colgroup>
              <col width="40px">
              <col width="125px">
              <col width="125px">
              <col width="110px">
              <col width="80px">
              <col width="110px">
              <col width="80px">
              <col width="110px">
              <col>
            </colgroup>
            <tr>
              <th><label>접수일</label></th>
              <td colspan="2">
                <div class="jh-cols">                       
                  <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                      maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                    </template>
                    <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                  </v-menu>
                  <span class="jh-unit">~</span>                       
                  <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                      maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                    </template>
                    <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                  </v-menu>
                </div>
              </td>
              <th colspan="2"><label>고객번호</label></th>
              <td><input type="text" class="jh-form" v-model="CUST_NO" :disabled="checkbox1" @keydown.enter="selectbutton"></td>
              <td class="is-jh-20">
                <v-checkbox class="jh-check" v-model="checkbox1" label="미인증"></v-checkbox>
              </td>
            </tr>
            <tr>
              <th><label>담당자</label></th>
              <td><v-select class="jh-form" :items="ATTR_A_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="ATTR_A" :disabled="atrtGroupId === '20220826113539330TWBaSY4N' ? true : false"></v-select></td>
              <td><v-select class="jh-form" :items="ATTR_B_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="ATTR_B" :disabled="atrtGroupId === '20220826113539330TWBaSY4N' ? true : false"></v-select></td>
              <td><v-select class="jh-form" :items="USER_COMBO" item-text="USER_NM" item-value="USER_ID" placeholder="선택" v-model="CSLT_MAN" :disabled="atrtGroupId === '20220826113539330TWBaSY4N' ? true : false"></v-select></td>
              <th><label>계좌번호</label></th>
              <td><input type="text" class="jh-form" v-model="ACCOUNT" @keydown.enter="selectbutton" oninput="this.value = this.value.replace(/[^0-9]/g, '');"></td>
              <th><label>처리상태</label></th>
              <td><v-select class="jh-form" :items="SCDSTS_COMBO" item-text="CD_NM" item-value="CD" v-model="PROC_STAT_CD"></v-select></td>
              <td class="has-search"><v-btn class="jh-btn is-search" @click="selectbutton">조회</v-btn></td>
            </tr>
          </table>
        </div>
        <!--//조회-->

        <div class="jh-form-wrap">
          <div class="jh-ui-header">
            <h2>MY예약 리스트</h2>
            <div class="is-right"><v-btn class="jh-btn is-fill" @click="saveBtn">저장</v-btn></div>
          </div>
          <data-tables
            ref="dataTable"
            dataTableClass=""
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            @dblclick:row="onClickRow2"
            :paginationOptions="paginationOptions"
          >
            <template v-slot:item.PROC_STAT_CD="{ item }">
              <v-select class="jh-form" :items="PROC_RSLT_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="item.PROC_STAT_CD" v-on:change="changeResult"></v-select>
            </template>
          </data-tables>
        </div>

        <!--상세-->
        <div class="jh-form-wrap is-mt-10">
          <div class="jh-ui-header">
            <h3>접수정보</h3>
            <div class="is-right">
            </div>
          </div>
          <table class="jh-tbl-detail">
            <colgroup>
                <col width="100px">
                <col>
                <col width="100px">
                <col>
            </colgroup>
            <tbody>
                <tr>
                    <th><label>접수유형</label></th>
                    <td> 
                      <span class="is-value">{{detailObj.CSLT_TY_CLS}}</span>
                    </td>
                    <th rowspan="3"><label>예약메모</label></th>
                    <td rowspan="3" class="is-p-0"> 
                      <textarea class="jh-form is-trns" style="height: 85px;" readonly v-model="detailObj.RSV_MEMO"></textarea>
                    </td>
                </tr>
                <tr>
                  <th><label>예약일시</label></th>
                  <td> 
                    <span class="is-value">{{detailObj.RSV_DT_TIME}}</span>
                  </td>
                </tr>
                <tr>
                  <th><label>예약수신번호</label></th>
                  <td> 
                    <span class="is-value">{{detailObj.RESV_TEL_NO}}</span>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
        <!--상세-->
      </div>
    <!-- </div>
  </transition> -->
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import api from "../../store/apiUtil.js";
import { mapGetters } from "vuex";

export default {
  name: "MENU_T100801", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],

  components: {
    dataTables      
  },

  props: [ "evt" ],
  
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      dates: [
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],
      
      headers: [
        { text: 'NO', value: 'ROWNUM', align: 'center', width: '40px', },
        { text: '접수일시', value: 'RECP_DT_TIME', align: 'center', width: '150px',},
        { text: '계좌번호', value: 'ACCOUNT', align: 'center', width: '120px', },
        { text: '고객명', value: 'CUST_NM', align: 'center', width: '120px', },
        { text: '고객번호', value: 'CUST_NO', align: 'center', width: '120px', },
        { text: '상담유형', value: 'CSLT_TY_LCLS', align: 'center', },
        { text: '처리상태', value: 'PROC_STAT_CD', align: 'center', width: '100px', },
        { text: '처리직원', value: 'PROC_MAN', align: 'center', width: '120px', },
        { text: '처리일시', value: 'PROC_DT_TIME', align: 'center', width: '150px', },
      ],
      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        // height: '495px',
        height: '465px',
        items: undefined,
        itemKey: 'ROWNUM',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,
        itemClass: "[item.selected && 'selected']",
        load: undefined
      },
      paginationOptions: {
          totalVisible: 10
      },

      // 접수정보
      detailObj: {},
      param: {},

      // 콤보박스
      ATTR_A_COMBO: [],
      ATTR_B_COMBO: [],
      USER_COMBO: [],
      SCDSTS_COMBO: [{ CD: '', CD_NM: '전체' }],
      PROC_RSLT_COMBO: [],

      // 콤보박스 선택값
      ATTR_A: '',
      ATTR_B: '',
      CSLT_MAN: '',

      // 조회
      CUST_NO: '',  // 고객번호
      ACCOUNT: '',  // 계좌번호
      PROC_STAT_CD: '', // 처리상태

      atrtGroupId: this.$store.getters["userStore/GE_USER_ROLE"].atrtGroupId,

      selectedRow: {},

      checkbox1: false,
    }
  },
  created() { 
    this.dataTableOptions.headers = this.headers;
    this.dataTableOptions.items = this.rowNum;
  },
  mounted() {
    this.init();
    this.getAttrList();
    this.getSelBoxList();
    this.userDataInit();
  },
  watch: {
    "ATTR_A": async function (val) {
      this.CSLT_MAN = '';
      this.ATTR_B = '';

      if (val == '') {
        this.ATTR_B_COMBO = [];
        this.USER_COMBO = [];
      } else {
        this.ATTR_B_COMBO = await this.mixin_get_attr_by_attr('', val);
      }
    },
    "ATTR_B": async function (val) {
      this.CSLT_MAN = '';
      
      if (val == '') {
        this.USER_COMBO = [];
      } else {
        this.USER_COMBO = await this.mixin_get_user_by_attr(this.ATTR_A, val);
      }
    },
    "checkbox1": async function (val) {
      val ? this.CUST_NO = '00000000' : this.CUST_NO = '';
    }
  },
  computed: {
    ...mapGetters({
      geCallStt: 'callStore/GE_CALL_STATE',
      geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
    }),
    rowNum() {
      return this.items?.map(
          (items, index) => ({
          ...items,
          index: index + 1
      }))
    },
    dataTableInstance: function() {
        return this.$refs.dataTable;
    },     
    initHeaders(){
        return {
            SERVICE: "phone.mycall",
            METHOD: "",
            TYPE: 'BIZ_SERVICE',
        };
    },
  },
  methods: {
    init() {
    },
    
    closeModal() {
        this.$parent.closeModal('slide3');
    },

    fromOnInput() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    changeDate(type) {
      if(type == 'start') {
        if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(90, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[0] = date;
          this.from = date;
          return;
        } else {
          this.from = this.dates[0];
        }
      } else if (type == 'end') {
        if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(90, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[1] = date;
          this.to = date;
          return;
        } else {
          this.to = this.dates[1];
        }
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }
        
        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
    onClickRow(item, row) {
      row.select(true);
      row.isSelected = true;
      this.selectedRow = row;

      this.detailObj = item;
      this.param.CNSL_HIST_NO = item.CNSL_HIST_NO;

      this.detailObj.CSLT_TY_CLS = item.CSLT_TY_LCLS + (item.CSLT_TY_MDCLS ? ' > ' + item.CSLT_TY_MDCLS : '') + (item.CSLT_TY_SCLS ? ' > ' + item.CSLT_TY_SCLS : '');
    },
    onClickRow2(e, { item }) {
      if(item.PROC_STAT_CD == '03') {
        this.common_alert('처리완료건은 진행할 수 없습니다.', 'noti');
        return;
      } else {
        let custInfo = {};
        custInfo.SRCH_ACCOUNT = item.ACCOUNT.replace(/\-/g, '');
        custInfo.SRCH_CL_CID = item.CUST_NO;
        custInfo.SCDL_ID = ''; // 스케쥴ID
        custInfo.ID = '';
        custInfo.IVR_IN_NO = '';
        custInfo.IVR_IN_PATH = '';
        custInfo.IVR_MENT = '';
        custInfo.IVR_STD_TIME = '';
        custInfo.RSV_CALL_ID = item.RSV_CALL_ID;
        custInfo.MY_CALL_YN = 'Y';
        custInfo.message = 'T100801';
        
        if(custInfo.SRCH_CL_CID == '00000000') custInfo.SRCH_CL_CID = '9999999999999';
        window.opener.postMessage(custInfo, '*');
      }
    },
    selectbutton() {
      this.dataTableOptions.load = this.selectMyCallWork;
      this.detailInit();

      if (this.dataTableOptions.page > 1) {
          this.dataTableOptions.page = 1;
      } else {
          this.dataTableInstance.loadData();
      }
    },
    async selectMyCallWork(loadOptions) {

      if (this.selectedRow.isSelected) {
        this.selectedRow.select(false);
      }

      let requestData = {
          headers: {
              SERVICE: this.initHeaders.SERVICE,
              METHOD: this.initHeaders.METHOD,
              TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
      };

      const sortBy = loadOptions?.sortBy?.[0];
      const sortDesc = loadOptions?.sortDesc?.[0] === undefined ? 'desc' : loadOptions?.sortDesc?.[0] ? 'desc' : 'asc';

      requestData.headers["URL"] = "/api/phone/mycall/list";
      requestData.headers["METHOD"] = "select";
      requestData.headers["ASYNC"] = false;
      requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
      requestData.headers["PAGES_CNT"] = loadOptions.page; 

      requestData.sendData["STR_RECP_DT"] = this.dates[0].replace(/\-/g, "");
      requestData.sendData["END_RECP_DT"] = this.dates[1].replace(/\-/g, "");
      requestData.sendData["CUST_NO"] = this.CUST_NO;
      requestData.sendData["ACCOUNT"] = this.ACCOUNT;
      requestData.sendData["CSLT_MAN"] = this.CSLT_MAN;
      requestData.sendData["PROC_STAT_CD"] = this.PROC_STAT_CD;
      requestData.sendData["USER_ATTR_A"] = this.ATTR_A;
      requestData.sendData["USER_ATTR_B"] = this.ATTR_B;
      requestData.sendData["SORT_ORDR"] = sortBy ? sortBy : 'ROWNUM';
      requestData.sendData["SORT_DRCT"] = sortDesc;

      const response = await this.common_postCall(requestData);
      this.detailObj = {};

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
          let header = response.HEADER;
          let data = response.DATA;

          data.forEach(item => {
            item.ACCOUNT = item.ACCOUNT.indexOf('-') < 0 ? 
                this.mixin_accNoFormat(item.ACCOUNT) : this.mixin_accNoFormat(item.ACCOUNT.replace(/\-/g, ''));
            item.RESV_TEL_NO = item.RESV_TEL_NO.indexOf('-') < 0 ?
                this.mixin_newPhoneFormat(item.RESV_TEL_NO) : this.mixin_newPhoneFormat(item.RESV_TEL_NO.replace(/\-/g, ''));
          });

          return {
              data: data,
              totalCount: header.TOT_COUNT
          };
      }
    },
    async getAttrList() {
        this.ATTR_A_COMBO = await this.mixin_get_attr_by_attr('****', '');
    },
    detailInit() {
      this.DT_CSLT_TY_CLS = '';
      this.DT_RSV_DT_TIME = '';
      this.DT_RECV_TNO = '';
      this.DT_RSV_MEMO = '';
    },
    async getSelBoxList() {
      let requestData = {
          headers: {
              SERVICE: this.initHeaders.SERVICE,
              METHOD: this.initHeaders.METHOD,
              TYPE: this.initHeaders.TYPE,
          },
          sendData: {}
      };

      requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
      requestData.headers["METHOD"] = "selectCode";
      requestData.headers["ASYNC"] = false;

      let sendList = [];
      sendList.push("2NDSTS"); //처리결과코드

      requestData.sendData["GROUP_CD"] = sendList

      const response = await this.common_postCall(requestData);

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

        for (var i = 0; i < response.DATA.length ; i++){
          if (response.DATA[i].GROUP_CD == "2NDSTS"){
            this.SCDSTS_COMBO.push(response.DATA[i]);
            this.PROC_RSLT_COMBO.push(response.DATA[i]);
          }
        }
      }
    },
    saveBtn() {
      if (this.selectedRow.isSelected != undefined) {
        this.common_confirm("처리상태를 수정 하시겠습니까?", async ()=>{
            let URLData = '/api/phone/mycall/up-status';
            let HEADER_SERVICE = 'phone.mycall';
            let HEADER_METHOD = 'update';
            let HEADER_TYPE = 'BIZ_SERVICE';
            
            let params = {
                PROC_STAT_CD: this.param.PROC_STAT_CD,
                PROC_MAN: this.$store.getters['userStore/GE_USER_ROLE'].userId,
                UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
                CNSL_HIST_NO: this.param.CNSL_HIST_NO,
            };
  
            await api
              .post(URLData, params, {
                head: {
                  SERVICE: HEADER_SERVICE,
                  METHOD: HEADER_METHOD,
                  TYPE: HEADER_TYPE,
                  ASYNC: false,
                },
              })
              .then((response) => {
                this.common_alert("저장하였습니다.");
                this.dataTableInstance.loadData();
              })
              .catch((err) => {
                //this.common_alert('시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.', 'error');
                console.log(err);
              });
        })
      } else {
        this.common_alert("선택된 항목이 없습니다.", "noti");
      }
    },
    changeResult(e) {
      this.param.PROC_STAT_CD = e;
    },
    async userDataInit() {
      if (this.atrtGroupId === '20220826113539330TWBaSY4N') {
        const obj = await this.mixin_get_blng('001');
        const obj2 = JSON.stringify(obj);
        const obj3 = JSON.parse(obj2);
  
        this.ATTR_A = obj3[0].USER_ATTR_A;
        this.ATTR_B_COMBO = await this.mixin_get_attr_by_attr('', this.ATTR_A);
        this.ATTR_B = obj3[0].USER_ATTR_B;
        this.USER_COMBO = await this.mixin_get_user_by_attr(this.ATTR_A, this.ATTR_B);
        this.CSLT_MAN = this.$store.getters["userStore/GE_USER_ROLE"].userId;
      }
    },
  },

}
</script>

<style scoped>
</style>