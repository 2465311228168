<template>
  <div class="jh-dialog-wrap">
    <div class="jh-dialog-header">
      <h1>{{ headerTitle }}</h1>
      <div class="is-right">
        <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
      </div>
    </div>
    <div class="jh-dialog-body">
      <table class="jh-tbl-detail">
        <colgroup>
          <col width="70px">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th><label>등록일시</label></th>
            <td> 
              <v-text-field class="jh-form is-trns" v-model="agentInfo.date"></v-text-field>
            </td>
          </tr>
          <tr>
            <th><label>제목</label></th>
            <td> 
              <v-text-field class="jh-form is-trns" v-model="agentInfo.title" maxLength="48"></v-text-field>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="jh-editor is-mt-5"><ckeditor v-model="agentInfo.BRD_RMK" :config="readEditorConfig" :read-only="true"></ckeditor></div>
      
      <div class="jh-ui-header is-mt-10" v-if="this.fileList.length > 0">
        <div class="is-left">
          <i class="jh-icon-clip is-blue is-mr-5"></i>
          <h3>첨부파일</h3>
          <span class="is-ml-5"><span class="is-bold is-txt-blue">{{ fileList.length }}</span>개</span>
        </div>        
        <div class="is-right">
          <v-btn class="jh-btn" @click="fileDownload()"><span>다운로드</span><i class="jh-icon-download"></i></v-btn>
        </div>
      </div>
      <div class="jh-card is-no-shadow" v-if="this.fileList.length > 0">
        <div class="jh-card-body" style="max-height: 135px">
          <template v-for="(item, index) in fileList">
            <v-checkbox class="jh-check" v-model="item.selected" :key="index" @click="click(index)">
              <template v-slot:label>
                <!-- <v-icon :class="item.icon"></v-icon> -->
                <span class="is-ml-5"> {{ item.title }} </span>
                <span class="is-ml-5 is-txt-blueGray"> {{ item.size }} </span>
              </template>
            </v-checkbox>
          </template>
        </div>
      </div>
    </div>
    <template v-if="footerSubmit">
      <div class="jh-dialog-footer">
        <v-btn class="jh-btn id-md is-main" @click="$emit('submit')">{{ footerHideTitle }}</v-btn>
      </div>
    </template>
  </div>
</template>
  
<script>
  import axios from "axios";
  import api from "@/store/apiUtil";
  import vue2Dropzone from "vue2-dropzone";
  import { mixin } from "../../mixin/mixin"; 
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import DialogVueDropzone from "@/components/VueDropzone";
  import DialogFileUploadPop from "@/components/FileUploadPop.vue";

  export default {
    name: "MENU_E050101P01", //name은 'MENU_' + 파일명 조합
    props: {
      headerTitle: {
        type: String,
        default: "제목",
      },
      agentInfo: {
        type: Object,
        value: {},
      },
      fileGroupKey: {
        type: String,
        value: "",
      },
      isinit: {
        type: Boolean,
        default: false,
      },
    },
  
    computed: {},
    checkboxValue: [],
    gridDataText: {},
    mixins: [mixin],
    item: {},
  
    data: () => ({
  
      readEditorConfig: {
        extraPlugins: "font, colorbutton, colordialog, openlink, spacingsliders",
        height: "300px",
        openlink_enableReadOnly: true,
        openlink_modifier: 0,
        toolbar: [
          // { name: "clipboard", items: [ "Cut", "Copy", "Paste", "-", "Undo", "Redo" ] },//, "PasteText", "PasteFromWord"
          // { name: "editing" }, //, items: [ "Scayt" ]
          { name: "links", items: [ "Link", "Unlink" ] },//, "Anchor"
          { name: "insert", items: [ "Image", "Table", "HorizontalRule", "SpecialChar" ] },
          { name: "forms" },
          { name: "styles", items: [ "Font", "FontSize" ] },
          { name: "styles", items: [ "Styles", "Format" ] },
          { name: "others", items: [ "-" ] },
          "/",
          { name: "colors", items: [ "TextColor", "BGColor", "spacingsliders" ] },
          { name: "basicstyles", items: [ "Bold", "Italic", "Strike" ] }, //, "-", "RemoveFormat"
          { name: "paragraph", items: [ "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote" ] },
          { name: "document", items: [ "Source" ] },
          { name: "tools", items: [ "Maximize" ] },
          { name: "about", items: [] }
        ],
        toolbarCanCollapse:  true,
        toolbarStartupExpanded: false,
      },
      dropItems: [
        {
          title: "option1",
        },
      ],
      fileList: [],
      gridListSelected: "",
      sub_title: "",
      uesrDate: "",
  
      FILE:'',
  
      DOWNLOAD_LIST:[],

      footerSubmit: "",
    }),
  
    created() {
    },
  
    mounted(){
      this.FILE = this.agentInfo.FILE_GROUP_KEY;
      this.initTest()
    },
  
    methods: {
      click(index) {
        if (this.fileList[index].selected == true) {
          this.DOWNLOAD_LIST[this.DOWNLOAD_LIST.length] = this.fileList[index];
        } else if (this.fileList[index].selected == false) {
          for (let i = 0; i<this.DOWNLOAD_LIST.length; i++){
            if (this.DOWNLOAD_LIST[i].FILE_KEY == this.fileList[index].FILE_KEY){
              this.DOWNLOAD_LIST.splice(i,1);
            }
          }
        }
        console.log("this.DOWNLOAD_LIST", this.DOWNLOAD_LIST);
      },
      onButtonClick(item) {
        console.log("click on " + item.index);
      },
      async initTest() {
        //console.log(this.checkSelected);
        
        let resList = await this.mixin_getFileList(this.FILE);
        this.setFileList(resList);
      },
      initTest2() {
        //console.log("222222222");
        //this.initTest();
      },
      setFileList(listData) {
        this.fileList = [];
        //console.log(listData[0]['ORIGINAL_FILE
        for (let i in listData) {
          let gridDataText = listData[i];
          gridDataText.icon = "svg-file-down";
          gridDataText.size = this.mixin_getFileSize(listData[i]["FILE_SIZE"]);
          gridDataText.title = listData[i]["ORIGINAL_FILENAME"];
          if (i == "0") {
            gridDataText.first = true;
          }
  
          this.fileList.push(gridDataText);
  
          //this.fileDownload(this.fileList[0])
        }
        //console.log(this.fileList);
      },
      //*********************************
      //        첨부파일 다운로드
      //*********************************
      async fileDownload() {
        let busiType = "bbs"; //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage
  
        for(let i = 0; i<this.DOWNLOAD_LIST.length; i++){
          await this.mixin_fileDownload(this.DOWNLOAD_LIST[i], busiType, pathType);
        }
      },
    },
    watch: {
      agentInfo(){
        this.FILE = this.agentInfo.FILE_GROUP_KEY;
        this.initTest()
      },
    },
    
    destroyed() {
    },
  };
</script>

<style></style>
  