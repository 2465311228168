import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/default/index";
import AuthLayout from "@/layouts/auth/index";
import Login from "@/views/auth/Login";
import MainPage from "@/views/auth/MainPage";
// import Home from "@/views/page/E050101";
import Home from "@/views/page/E999999";
import Init from "@/views/auth/Init";

// import Commend from "@/views/help/Commend";
// import Layout from "@/views/help/Layout";
// import Menual from "@/views/help/Menual";
// import Guide from "@/views/help/Guide";
// import Sample from "@/views/help/Sample";
// import IaList from "@/views/help/IaList";

// pbguide 
import pbGuide from '@/pbGuide/pbGuide.vue';

Vue.use(VueRouter);

import store from "../store";

//token 인증 영역 (일단 token이 있기만하면 인증완료)
const beforeAuth = (isAuth) => (from, to, next) => {
  /*
  let isAuthenticated = store.getters["authStore/GE_COMMON_TOKEN"];

  if (!isAuthenticated && isAuth) {
    if (sessionStorage.getItem("token")) {
      isAuthenticated = sessionStorage.getItem("token");
      store.commit("authStore/MU_AUTH_TOKEN", {
        access_token: isAuthenticated,
      });
    }
  }

  // if ((isAuthenticated && isAuth) || (!isAuthenticated && !isAuth)) {
  if ((isAuthenticated && isAuth) || !isAuth) {
    return next();
  } else {
    // 로그인 화면으로 이동
    next("/login");
  }
  */

  //token이 없으면 login 페이지를 이동
  if ((window.sessionStorage.getItem("token") && isAuth) || !isAuth) {
    return next();
  } else {
    // 로그인 화면으로 이동
    next("/login");
  }

  return next();
};

//AuthenticationLayout: header와 aside(메뉴)가 없는 백지 레이아웃 (로그인, 회원가입, 비밀번호 찾기등의 페이지에 사용)
//DefaultLayout: 기본적인 header와 aside(메뉴)가 있는 레이아웃 (거의 모든 메뉴에 적용)
const routes = [
  {
    path: "/",
    component: AuthLayout,
    children: [
      // {
      //   path: "/",
      //   name: "기본",
      //   component: Login,
      //   beforeEnter: beforeAuth(false),
      // },
      {
        path: "/",
        name: "메인",
        component: MainPage,
        beforeEnter: beforeAuth(false),
      },      
      // {
      //   path: "/Init",
      //   name: "이닛",
      //   component: Init,
      //   beforeEnter: beforeAuth(false),
      // },
      // {
      //   path: "/guide",
      //   name: "가이드",
      //   component: Guide,
      //   beforeEnter: beforeAuth(true),
      // },

      // pbguide url 
      {
        path: '/pbGuide',
        name: 'pbGuide',
        component : pbGuide,
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/login",
        name: "로그인",
        component: Login,
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/MainPage",
        name: "메인페이지",
        component: MainPage,
        beforeEnter: beforeAuth(false),
        meta: { bodyClass: 'main-scss-body' }
      },      
    ],
  },
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/E050101",
        name: "HOME",
        component: Home,
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/commend",
        name: "설치",
        // component: Commend,
        component: () => import("@/views/help/Commend"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/layout",
        name: "구조",
        // component: Layout,
        component: () => import("@/views/help/Layout"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/menual",
        name: "사용법",
        // component: Menual,
        component: () => import("@/views/help/Menual"),
        beforeEnter: beforeAuth(true),
      },

      {
        path: "/sample",
        name: "기능샘플",
        // component: Sample,
        component: () => import("@/views/help/Sample"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/iaList",
        name: "화면목록",
        // component: IaList,
        component: () => import("@/views/help/IaList"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/guide",
        name: "가이드",
        component: () => import("@/views/help/Guide"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E999999", //
        name: "E999999",
        component: () => import("@/views/page/E999999"),
        beforeEnter: beforeAuth(true),
      }, 
      {
        path: "/E999995", //
        name: "E999995",
        component: () => import("@/views/page/E999995"),
        beforeEnter: beforeAuth(true),
      }, 
      {
        path: "/E999998", //
        name: "E999998",
        component: () => import("@/views/page/E999998"),
        beforeEnter: beforeAuth(true),
      },              
      {
        path: "/E999991", //
        name: "E999991",
        component: () => import("@/views/page/E999991"),
        beforeEnter: beforeAuth(true),
      }, 
      {
        path: "/E999996", //
        name: "E999996",
        component: () => import("@/views/page/E999996"),
        beforeEnter: beforeAuth(true),
      },           
      {
        path: "/E999994", //
        name: "E999994",
        component: () => import("@/views/page/E999994"),
        beforeEnter: beforeAuth(true),
      },         
      {
        path: "/E999997", //
        name: "E999997",
        component: () => import("@/views/page/E999997"),
        beforeEnter: beforeAuth(true),
      },             
      {
        path: "/E999992", //
        name: "E999992",
        component: () => import("@/views/page/E999992"),
        beforeEnter: beforeAuth(true),
      },  
      {
        path: "/E999990", //
        name: "E999990",
        component: () => import("@/views/page/E999990"),
        beforeEnter: beforeAuth(true),
      },        
      {
        path: "/E999993", //
        name: "E999993",
        component: () => import("@/views/page/E999993"),
        beforeEnter: beforeAuth(true),
      },           
      {
        path: "/E999989", //
        name: "E999989",
        component: () => import("@/views/page/E999989"),
        beforeEnter: beforeAuth(true),
      },                              
      {
        path: "/E010101", //콜상담메인
        name: "E010101",
        component: () => import("@/views/page/E010101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010102", //채팅상담메인
        name: "E010102",
        component: () => import("@/views/page/E010102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010201", //상담내역조회
        name: "E010201",
        component: () => import("@/views/page/E010201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010301", //ARS예약관리
        name: "E010301",
        component: () => import("@/views/page/E010301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010302", //2차상담관리
        name: "E010302",
        component: () => import("@/views/page/E010302"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010303", //OB관리
        name: "E010303",
        component: () => import("@/views/page/E010303"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010304", //OB관리 통계
        name: "E010304",
        component: () => import("@/views/page/E010304"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010401", //문제행동 고객관리
        name: "E010401",
        component: () => import("@/views/page/E010401"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010501", //캘린더/상장책자 신청
        name: "E010501",
        component: () => import("@/views/page/E010501"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010502", //캘린더/상장책자 신청관리
        name: "E010502",
        component: () => import("@/views/page/E010502"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E010601", //상담유형별통계
        name: "E010601",
        component: () => import("@/views/page/E010601"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020101", //채팅대시보드
        name: "E020101",
        component: () => import("@/views/page/E020101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020102", //채팅 문의유형별 대기 현황
        name: "E020102",
        component: () => import("@/views/page/E020102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020201", //채팅상담사현황
        name: "E020201",
        component: () => import("@/views/page/E020201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020202", //상담사이석이력
        name: "E020202",
        component: () => import("@/views/page/E020202"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020203", //상담중 현황 및 3자 채팅
        name: "E020203",
        component: () => import("@/views/page/E020203"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E0203", //채팅상담메인
        name: "E0203",
        component: () => import("@/views/page/E010102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020401", //상담이력관리
        name: "E020401",
        component: () => import("@/views/page/E020401"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020402", //챗봇검색실패내역조회
        name: "E020402",
        component: () => import("@/views/page/E020402"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020601", //부서별 휴일관리
        name: "E020601",
        component: () => import("@/views/page/E020601"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020602", //상담이미지관리
        name: "E020602",
        component: () => import("@/views/page/E020602"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020603", //금칙어관리
        name: "E020603",
        component: () => import("@/views/page/E020603"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020604", //시스템메시지관리
        name: "E020604",
        component: () => import("@/views/page/E020604"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020605", //상담설정관리
        name: "E020605",
        component: () => import("@/views/page/E020605"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020606", //문의유형관리
        name: "E020606",
        component: () => import("@/views/page/E020606"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020607", //상담사별 스킬관리
        name: "E020607",
        component: () => import("@/views/page/E020607"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E040109", //상담유형관리
        name: "E040109",
        component: () => import("@/views/page/E040109"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020609", //스크립트조회
        name: "E020609",
        component: () => import("@/views/page/E020609"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020610", //스크립트관리
        name: "E020610",
        component: () => import("@/views/page/E020610"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020611", //스크립트 변경관리
        name: "E020611",
        component: () => import("@/views/page/E020611"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020612", //챗봇시나리오관리
        name: "E020612",
        component: () => import("@/views/page/E020612"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020614", //링크관리
        name: "E020614",
        component: () => import("@/views/page/E020614"),
        beforeEnter: beforeAuth(true),
      },      
      {
        path: "/E020501", //채팅 상담사별 상담통계
        name: "E020501",
        component: () => import("@/views/page/E020501"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020502", //채팅 문의유형별 상담통계
        name: "E020502",
        component: () => import("@/views/page/E020502"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020503", //채팅 상담유형별 상담통계
        name: "E020503",
        component: () => import("@/views/page/E020503"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020504", //채팅 인입채널별 상담통계
        name: "E020504",
        component: () => import("@/views/page/E020504"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020505", //채팅 일자별 상담통계
        name: "E020505",
        component: () => import("@/views/page/E020505"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E020506", //챗봇실패데이터검색
        name: "E020506",
        component: () => import("@/views/page/E020506"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E030101", //SMS발송
        name: "E030101",
        component: () => import("@/views/page/E030101"),
      },
      {
        path: "/E030102", //SMS발송내역
        name: "E030102",
        component: () => import("@/views/page/E030102"),
      },
      {
        path: "/E030201", //이메일발송
        name: "E030201",
        component: () => import("@/views/page/E030201"),
      },
      {
        path: "/E030202", //이메일발송내역
        name: "E030202",
        component: () => import("@/views/page/E030202"),
      },
      {
        path: "/E030301", //템플릿관리
        name: "E030301",
        component: () => import("@/views/page/E030301"),
      },
      {
        path: "/E040103", //코드관리
        name: "E040103",
        component: () => import("@/views/page/E040103"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E040102", //지식관리
        name: "E040102",
        component: () => import("@/views/page/E040102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E040104", //메뉴관리
        name: "E040104",
        component: () => import("@/views/page/E040104"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E040105", //메뉴권한관리
        name: "E040105",
        component: () => import("@/views/page/E040105"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E040106", //상담원관리
        name: "E040106",
        component: () => import("@/views/page/E040106"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E040107", //상담원 권한관리
        name: "E040107",
        component: () => import("@/views/page/E040107"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E040108", //상담원 조직관리
        name: "E040108",
        component: () => import("@/views/page/E040108"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E040110", //ARS관리
        name: "E040110",
        component: () => import("@/views/page/E040110"),
        beforeEnter: beforeAuth(true),
      },      
      {
        path: "/E040101", //지점관리
        name: "E040101",
        component: () => import("@/views/page/E040101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E050201", //공지사항관리
        name: "E050201",
        component: () => import("@/views/page/E050201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E050202", //공지사항관리
        name: "E050202",
        component: () => import("@/views/page/E050202"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/E050203", //자유게시판
        name: "E050203",
        component: () => import("@/views/page/E050203"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M010101",
        name: "M010101",
        component: () => import("@/views/page/old/M010101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M010102",
        name: "M010102",
        component: () => import("@/views/page/old/M010102"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M010201",
      //   name: "M010201",
      //   component: () => import("@/views/page/old/M010201"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M010202",
        name: "M010202",
        component: () => import("@/views/page/old/M010202"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M010203",
        name: "M010203",
        component: () => import("@/views/page/old/M010203"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M010204",
        name: "M010204",
        component: () => import("@/views/page/old/M010204"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110101",
        name: "M110101",
        component: () => import("@/views/page/old/M110101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110102",
        name: "M110102",
        component: () => import("@/views/page/old/M110102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110201",
        name: "M110201",
        component: () => import("@/views/page/old/M110201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110202",
        name: "M110202",
        component: () => import("@/views/page/old/M110202"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110203",
        name: "M110203",
        component: () => import("@/views/page/old/M110203"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110204",
        name: "M110204",
        component: () => import("@/views/page/old/M110204"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110301",
        name: "M110301",
        component: () => import("@/views/page/old/M110301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110302",
        name: "M110302",
        component: () => import("@/views/page/old/M110302"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110303",
        name: "M110303",
        component: () => import("@/views/page/old/M110303"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110401",
        name: "M110401",
        component: () => import("@/views/page/old/M110401"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110402",
        name: "M110402",
        component: () => import("@/views/page/old/M110402"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M110403",
      //   name: "M110403",
      //   component: () => import("@/views/page/old/M110403"),
      //   beforeEnter: beforeAuth(true),
      // },
      // {
      //   path: "/M110404",
      //   name: "M110404",
      //   component: () => import("@/views/page/old/M110404"),
      //   beforeEnter: beforeAuth(true),
      // },
      // {
      //   path: "/M110405",
      //   name: "M110405",
      //   component: () => import("@/views/page/old/M110405"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M110501",
        name: "M110501",
        component: () => import("@/views/page/old/M110501"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110601",
        name: "M110601",
        component: () => import("@/views/page/old/M110601"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110602",
        name: "M110602",
        component: () => import("@/views/page/old/M110602"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110603",
        name: "M110603",
        component: () => import("@/views/page/old/M110603"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110701",
        name: "M110701",
        // component: () => import("@/views/page/old/M110701"),
        component: () => import("@/views/html/M110102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110801",
        name: "M110801",
        component: () => import("@/views/page/old/M110801"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110802",
        name: "M110802",
        component: () => import("@/views/page/old/M110802"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M210101",
      //   name: "M210101",
      //   component: () => import("@/views/page/old/M210101"),
      //   beforeEnter: beforeAuth(true),
      // },
      // {
      //   path: "/M210102",
      //   name: "M210102",
      //   component: () => import("@/views/page/old/M210102"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M310101",
        name: "M310101",
        component: () => import("@/views/page/old/M310101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M310102",
        name: "M310102",
        component: () => import("@/views/page/old/M310102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M310201",
        name: "M310201",
        component: () => import("@/views/page/old/M310201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M310301",
        name: "M310301",
        component: () => import("@/views/page/old/M310301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410101",
        name: "M410101",
        component: () => import("@/views/page/old/M410101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410102",
        name: "M410102",
        component: () => import("@/views/page/old/M410102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410201",
        name: "M410201",
        component: () => import("@/views/page/old/M410201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410301",
        name: "M410301",
        component: () => import("@/views/page/old/M410301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410401",
        name: "M410401",
        component: () => import("@/views/page/old/M410401"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M510101",
        name: "M510101",
        component: () => import("@/views/page/old/M510101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M510201",
        name: "M510201",
        component: () => import("@/views/page/old/M510201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M510301",
        name: "M510301",
        component: () => import("@/views/page/old/M510301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M6101",
        name: "M6101",
        component: () => import("@/views/page/old/M6101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M6102",
        name: "M6102",
        component: () => import("@/views/page/old/M6102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710101",
        name: "M710101",
        component: () => import("@/views/page/old/M710101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710102",
        name: "M710102",
        component: () => import("@/views/page/old/M710102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710103",
        name: "M710103",
        component: () => import("@/views/page/old/M710103"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710104",
        name: "M710104",
        component: () => import("@/views/page/old/M710104"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710201",
        name: "M710201",
        component: () => import("@/views/page/old/M710201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710202",
        name: "M710202",
        component: () => import("@/views/page/old/M710202"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710203",
        name: "M710203",
        component: () => import("@/views/page/old/M710203"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710204",
        name: "M710204",
        component: () => import("@/views/page/old/M710204"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710205",
        name: "M710205",
        component: () => import("@/views/page/old/M710205"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710301",
        name: "M710301",
        component: () => import("@/views/page/old/M710301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710401",
        name: "M710401",
        component: () => import("@/views/page/old/M710401"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710402",
        name: "M710402",
        component: () => import("@/views/page/old/M710402"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810101",
        name: "M810101",
        component: () => import("@/views/page/old/M810101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810201",
        name: "M810201",
        component: () => import("@/views/page/old/M810201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810301",
        name: "M810301",
        component: () => import("@/views/page/old/M810301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810401",
        name: "M810401",
        component: () => import("@/views/page/old/M810401"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810402",
      //   name: "M810402",
      //   component: () => import("@/views/page/old/M810402"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810403",
        name: "M810403",
        component: () => import("@/views/page/old/M810403"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810404",
      //   name: "M810404",
      //   component: () => import("@/views/page/old/M810404"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810501",
        name: "M810501",
        component: () => import("@/views/page/old/M810501"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810601",
        name: "M810601",
        component: () => import("@/views/page/old/M810601"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810602",
        name: "M810602",
        component: () => import("@/views/page/old/M810602"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810603",
        name: "M810603",
        component: () => import("@/views/page/old/M810603"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810604",
        name: "M810604",
        component: () => import("@/views/page/old/M810604"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810605",
      //   name: "M810605",
      //   component: () => import("@/views/page/old/M810605"),
      //   beforeEnter: beforeAuth(true),
      // },
      // {
      //   path: "/M810606",
      //   name: "M810606",
      //   component: () => import("@/views/page/old/M810606"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810607",
        name: "M810607",
        component: () => import("@/views/page/old/M810607"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810608",
        name: "M810608",
        component: () => import("@/views/page/old/M810608"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810609",
      //   name: "M810609",
      //   component: () => import("@/views/page/old/M810609"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810610",
        name: "M810610",
        component: () => import("@/views/page/old/M810610"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810611",
        name: "M810611",
        component: () => import("@/views/page/old/M810611"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810701",
        name: "M810701",
        component: () => import("@/views/page/old/M810701"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810702",
        name: "M810702",
        component: () => import("@/views/page/old/M810702"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810703",
        name: "M810703",
        component: () => import("@/views/page/old/M810703"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810704",
      //   name: "M810704",
      //   component: () => import("@/views/page/old/M810704"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810705",
        name: "M810705",
        component: () => import("@/views/page/old/M810705"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810706",
        name: "M810706",
        component: () => import("@/views/page/old/M810706"),
        beforeEnter: beforeAuth(true),
      },
    ],
  },
  {
    path: "/popupSample",
    name: "popupSample",
        component: () => import("@/views/popup/popupSample")
  },
  {
    path: "/E999993P01", //SMS발송
    name: "E999993P01",
    component: () => import("@/views/page/E999993P01"),
  },  
  {
    path: "/E999989P01", //SMS발송
    name: "E999989P01",
    component: () => import("@/views/page/E999989P01"),
  },    
  {
    path: "/E999996P01", //SMS발송
    name: "E999996P01",
    component: () => import("@/views/page/E999996P01"),
  },   
  {
    path: "/P100401", //호전환
    name: "P100401",
    component: () => import("@/views/page/P100401"),
  },
  {
    path: "/P101801", //사용자조회
    name: "P101801",
    component: () => import("@/views/page/P101801"),
  },
  {
    path: "/P10180101", //사용자조회 분리
    name: "P10180101",
    component: () => import("@/views/page/P10180101"),
  },
  {
    path: "/P100901", //고객정보(MEMO) 이력
    name: "P100901",
    component: () => import("@/views/page/P100901"),
  },
  {
    path: "/P100601", //즐겨찾기설정
    name: "P100601",
    component: () => import("@/views/page/P100601"),
  },
  {
    path: "/P100701", //업무VMS
    name: "P100701",
    component: () => import("@/views/page/P100701"),
  },
  {
    path: "/P100801", //문제행동VMS
    name: "P100801",
    component: () => import("@/views/page/P100801"),
  },
  {
    path: "/WinPop", //문제행동VMS
    name: "WinPop",
    component: () => import("@/views/popup/WinPop"),
  },
  {
    path: "/P101102", //채팅내용팝업
    name: "P101102",
    component: () => import("@/views/page/P101102"),
  },
  {
    path: "/P101103", //3자채팅팝업
    name: "P101103",
    component: () => import("@/views/page/P101103"),
  },
  {
    path: "/E101103P01", //3자채팅팝업
    name: "E101103P01",
    component: () => import("@/views/page/E101103P01"),
  },
  {
    path: "/T101201", //비상접수 수동저장
    name: "T101201",
    component: () => import("@/views/page/T101201"),
  },
  {
    path: "/E030101P01", //SMS발송
    name: "E030101P01",
    component: () => import("@/views/page/E030101P01"),
  },
  {
    path: "/E030102P01", //SMS발송내역
    name: "E030102P01",
    component: () => import("@/views/page/E030102P01"),
  },
  {
    path: "/E030201P01", //이메일발송
    name: "E030201P01",
    component: () => import("@/views/page/E030201P01"),
  },
  {
    path: "/E030202P01", //이메일발송내역
    name: "E030202P01",
    component: () => import("@/views/page/E030202P01"),
  },
  {
    path: "/P100101", //상담내역조회
    name: "P100101",
    component: () => import("@/views/page/P100101"),
  },
  {
    path: "/E010501P01", //캘린더/상장책자신청
    name: "E010501P01",
    component: () => import("@/views/page/E010501P01"),
  },
  {
    path: "/E010501P02", //우편번호조회 팝업
    name: "E010501P02",
    component: () => import("@/views/page/E010501P02"),
  },
  {
    path: "/E050201P03", //공지사항
    name: "E050201P03",
    component: () => import("@/views/page/E050201P03"),
  },
  {
    path: "/E050203P03", //자유게시판
    name: "E050203P03",
    component: () => import("@/views/page/E050203P03"),
  },
  {
    path: "/PT100601", //2차상담
    name: "PT100601",
    component: () => import("@/views/page/PT100601"),
  },
  {
    path: "/PT100801", //MY예약
    name: "PT100801",
    component: () => import("@/views/page/PT100801"),
  },
  {
    path: "/PT100701", //ARS예약
    name: "PT100701",
    component: () => import("@/views/page/PT100701"),
  },
  {
    path: "/PT100201", //유상청약
    name: "PT100201",
    component: () => import("@/views/page/PT100201"),
  },
  {
    path: "/PT100301", //반대의사
    name: "PT100301",
    component: () => import("@/views/page/PT100301"),
  },
  {
    path: "/PT100401", //매수청구
    name: "PT100401",
    component: () => import("@/views/page/PT100401"),
  },
  {
    path: "/PT100501", //TM
    name: "PT100501",
    component: () => import("@/views/page/PT100501"),
  },
  {
    path: "/PT100101", //지점정보
    name: "PT100101",
    component: () => import("@/views/page/PT100101"),
  },
  {
    path: "/PT100901", //지식함
    name: "PT100901",
    component: () => import("@/views/page/PT100901"),
  },
  {
    path: "/PT101001", //메모
    name: "PT101001",
    component: () => import("@/views/page/PT101001"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;