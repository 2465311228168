<template>
    <div class="guide-content"> 
        <div class="guide-content-header">Styles<v-icon class="mdi mdi-chevron-right"></v-icon><span>Icons</span></div>
        
        <div class="tt-info-box tt-mt-30">
            <h1>Material Design Icons</h1>
            <div class="tt-info-txt"><em>프로젝트폴더/node_modules/@mdi/font/preview.html</em> 파일을 로컬에서 열어서 사용하세요.</div>
        </div>
        <h2>Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-refresh"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-refresh">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-rotate-right"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-rotate-right">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-rotate-left"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-rotate-left">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-history"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-history">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-clock"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-clock">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-clock-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-clock-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-clock-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-clock-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-search"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-search">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-search-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-search-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-popup"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-popup">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-edit"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-edit">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-copy"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-copy">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-paste"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-paste">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-cut"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-cut">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-disket"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-disket">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-trash"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-trash">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-preview"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-preview">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-image"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-image">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-clip"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-clip">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-link"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-link">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-filter"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-filter">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-setting"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-setting">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-align-block"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-align-block">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-align-list"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-align-list">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-share-right"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-share-right">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-share-right-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-share-right-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-share-left"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-share-left">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-share-left-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-share-left-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-folder"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-folder">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-folder-open"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-folder-open">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-file"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-file">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-print"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-print">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-download"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-download">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-upload"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-upload">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-login"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-login">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-logout"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-logout">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-calendar"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-calendar">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-spoid"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-spoid">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-building"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-building">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-map"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-map">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-bell"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-bell">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-siren"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-siren">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-call"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-call">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-lock"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-lock">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-unlock"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-unlock">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-info"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-info">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-info-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-info-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-help"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-help">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-help-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-help-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-to-bottom"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-to-bottom">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-to-top"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-to-top">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-transfer"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-transfer">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-transfer-left"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-transfer-left">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-transfer-right"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-transfer-right">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-transfer-up"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-transfer-up">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-transfer-down"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-transfer-down">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-reply"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-reply">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-more-vert"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-more-vert">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-more-horz"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-more-horz">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-star"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-star">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-star-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-star-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-heart"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-heart">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-heart-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-heart-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-thumb-up"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-thumb-up">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-thumb-up-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-thumb-up-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-thumb-down"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-thumb-down">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-thumb-down-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-thumb-down-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-comment"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-comment">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-envelope"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-envelope">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-envelope-open"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-envelope-open">&lt;/i></code></pre>
            </li>
        </ul>
  
        <h2>Circle Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-circle-play"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-play">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-play-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-play-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-pause"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-pause">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-pause-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-pause-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-stop"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-stop">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-stop-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-stop-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-plus"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-plus">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-plus-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-plus-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-minus"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-minus">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-minus-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-minus-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-check"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-check">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-check-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-check-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-close"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-close">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-circle-close-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-circle-close-fill">&lt;/i></code></pre>
            </li>
        </ul>
  
        <h2>Squre Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-squre-play"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-play">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-play-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-play-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-pause"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-pause">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-pause-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-pause-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-stop"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-stop">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-stop-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-stop-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-plus"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-plus">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-plus-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-plus-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-minus"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-minus">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-minus-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-minus-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-check"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-check">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-check-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-check-fill">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-close"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-close">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-squre-close-fill"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-squre-close-fill">&lt;/i></code></pre>
            </li>
        </ul>
  
        <h2>Close Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-close-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-close-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-close"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-close-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-close-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-close-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-close-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-close-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-close-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-close-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-close-lg-bold">&lt;/i></code></pre>
            </li>
        </ul>
  
        <h2>Plus Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-plus-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-plus-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-plus"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-plus-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-plus-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-plus-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-plus-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-plus-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-plus-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-plus-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-plus-lg-bold">&lt;/i></code></pre>
            </li>
        </ul>
  
        <h2>Minus Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-minus-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-minus-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-minus"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-minus-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-minus-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-minus-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-minus-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-minus-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-minus-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-minus-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-minus-lg-bold">&lt;/i></code></pre>
            </li>
        </ul>
  
        <h2>Angle Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-angle-right-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-right-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-right"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-right-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-right-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-right-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-right-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-right-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-right-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-right-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-right-lg-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-left-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-left-lg-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-up-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-up-lg-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-angle-down-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-angle-down-lg-bold">&lt;/i></code></pre>
            </li>
        </ul>
  
        <h2>Arrow Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-arrow-right-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-right-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-right"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-right-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-right-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-right-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-right-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-right-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-right-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-right-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-right-lg-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-left-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-left-lg-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-up-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-up-lg-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-arrow-down-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-arrow-down-lg-bold">&lt;/i></code></pre>
            </li>
        </ul>
  
        <h2>Check Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-check-xs"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check-xs">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-check-sm"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check-sm">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-check"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-check-md"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check-md">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-check-lg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check-lg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-check-xs-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check-xs-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-check-sm-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check-sm-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-check-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-check-md-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check-md-bold">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-check-lg-bold"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-check-lg-bold">&lt;/i></code></pre>
            </li>
        </ul>
        
        <h2>Chat Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-chat-image"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-image">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-chat-stop"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-stop">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-chat-play"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-play">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-chat-complete"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-complete">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-chat-message"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-message">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-chat-transfer"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-transfer">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-chat-callback"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-callback">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-chat-key"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-key">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-chat-push"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-push">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-chat-push-new"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-chat-push-new">&lt;/i></code></pre>
            </li>
        </ul>
  
        <!-- <h2>Main Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-main-menu"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-main-menu">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-main-search"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-main-search">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-main-user"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-main-user">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-main-send"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-main-send">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-main-notice"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-main-notice">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-main-info"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-main-info">&lt;/i></code></pre>
            </li>
        </ul> -->
  
        <!-- <h2>Nav Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-nav-home"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-nav-home">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-nav-monitoring"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-nav-monitoring">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-nav-call"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-nav-call">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-nav-chat"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-nav-chat">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-nav-kms"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-nav-kms">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-nav-board"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-nav-board">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-nav-stats"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-nav-stats">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-nav-setting"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-nav-setting">&lt;/i></code></pre>
            </li>
        </ul> -->
  
        <!-- <h2>Quick Icons</h2>
        <ul class="list-icon is-bg-yellow">
            <li>
                <i class="jh-icon-quick-talk"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-quick-talk">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-quick-chat"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-quick-chat">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-quick-sms"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-quick-sms">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-quick-mail"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-quick-mail">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-quick-customer"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-quick-customer">&lt;/i></code></pre>
            </li>
        </ul> -->
  
        <!-- <h2>Context Menu Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-context is-plus"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-plus">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-minus"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-minus">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-copy-cell"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-copy-cell">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-copy-row"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-copy-row">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-past"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-past">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-crop"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-crop">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-edit"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-edit">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-delete"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-delete">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-save"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-save">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-move"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-move">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-download"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-download">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-preview"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-preview">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-refresh"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-refresh">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-reply"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-reply">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-close"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-close">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-expand"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-expand">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-context is-collapse"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-context is-collapse">&lt;/i></code></pre>
            </li>
        </ul> -->
  
        <h2>Move Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-move-right"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-move-right">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-move-left"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-move-left">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-move-up"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-move-up">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-move-down"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-move-down">&lt;/i></code></pre>
            </li>
        </ul>
  
        <!-- <h2>Channel Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-channel is-outbound"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-outbound">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-inbound"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-inbound">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-mail"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-mail">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-sms"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-sms">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-ttalk"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-ttalk">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-kakao"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-kakao">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-wechat"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-wechat">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-line"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-line">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-naver"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-naver">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-facebook"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-facebook">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-twitter"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-twitter">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-talk"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-talk">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-outbound"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-outbound">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-inbound"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-inbound">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-mail"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-mail">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-sms"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-sms">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-ttalk"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-ttalk">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-kakao"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-kakao">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-wechat"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-wechat">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-line"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-line">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-naver"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-naver">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-facebook"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-facebook">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-twitter"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-twitter">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-channel is-circle is-talk"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-channel is-circle is-talk">&lt;/i></code></pre>
            </li>
        </ul> -->
  
        <h2>Service Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-service is-time"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-service is-time">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-service is-mssg"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-service is-mssg">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-service is-channel"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-service is-channel">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-service is-holiday"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-service is-holiday">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-service is-user"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-service is-user">&lt;/i></code></pre>
            </li>
        </ul>
  
        <h2>Dashboard Icons</h2>
        <ul class="list-icon">
            <li>
                <i class="jh-icon-dashboard is-accept"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-dashboard is-accept">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-dashboard is-acceptinqry"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-dashboard is-acceptinqry">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-dashboard is-ready"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-dashboard is-ready">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-dashboard is-counsel"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-dashboard is-counsel">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-dashboard is-after"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-dashboard is-after">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-dashboard is-all"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-dashboard is-all">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-dashboard is-login"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-dashboard is-login">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-dashboard is-logout"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-dashboard is-logout">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-dashboard is-away"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-dashboard is-away">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-state is-ready"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-state is-ready">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-state is-counsel"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-state is-counsel">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-state is-complete"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-state is-complete">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-state is-giveup"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-state is-giveup">&lt;/i></code></pre>
            </li>
            <li>
                <i class="jh-icon-state is-busy"></i>
                <pre class="guide-code-inline" v-highlightjs><code class="html">&lt;i class="jh-icon-state is-busy">&lt;/i></code></pre>
            </li>
        </ul>
  
    </div>
  </template>
  
  <script>
  /*
  https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
  npm install --save highlight.js
  */
  import hljs from 'highlight.js';
  export default {
    name: "Icons",
    directives: {
        'highlightjs': {
            bind: function (el, binding) {
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                    if (binding.value) {
                        target.innerHTML = binding.value
                    }
                    hljs.highlightBlock(target)
                })
            },
            componentUpdated: function(el, binding) {
                // after an update, re-fill the content and then highlight
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                if (binding.value) {
                    target.textContent = binding.value
                    hljs.highlightBlock(target)
                }
                })
            }
        }
    },
    components: { 
    },
    data() {
        return {   

        }  
    },
    methods: {
    },
    computed: {
    },
    mounted() {  
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>