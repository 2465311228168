<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Selection Controls</span></div>

      <h2>Checkbox <span class="jh-info-txt is-ml-10">기본 세로정렬입니다.</span></h2>
      <div class="guide-card">
        <h6>세로정렬</h6>
        <v-checkbox class="jh-check" v-model="checkbox1" label="선택"></v-checkbox>
        <v-checkbox class="jh-check is-md" v-model="checkbox2" label="선택"></v-checkbox>
        <v-checkbox class="jh-check is-circle" v-model="checkbox3" label="선택"></v-checkbox>
        <v-divider class="is-mt-20 is-mb-20"></v-divider>
        <h6>가로정렬</h6>
        <div class="jh-selection-row">            
            <v-checkbox class="jh-check" v-model="checkbox4" label="선택"></v-checkbox>
            <v-checkbox class="jh-check is-md" v-model="checkbox5" label="선택"></v-checkbox>
            <v-checkbox class="jh-check is-circle" v-model="checkbox6" label="선택"></v-checkbox>
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Radio <span class="jh-info-txt is-ml-10">기본 세로정렬입니다.</span></h2>
      <div class="guide-card">  
        <h6>세로정렬</h6>
        <v-radio-group class="jh-radio">
          <v-radio label="Radio 1" value="radio-1"></v-radio>
          <v-radio label="Radio 2" value="radio-2"></v-radio>
        </v-radio-group>
        <v-radio-group class="jh-radio is-md">
          <v-radio label="Radio 1" value="radio-3"></v-radio>
          <v-radio label="Radio 2" value="radio-4"></v-radio>
        </v-radio-group>
        <v-divider class="is-mt-20 is-mb-20"></v-divider>
        <h6>가로정렬</h6>   
        <v-radio-group class="jh-radio" row>
          <v-radio label="Radio 1" value="radio-5"></v-radio>
          <v-radio label="Radio 2" value="radio-6"></v-radio>
        </v-radio-group> 
        <v-radio-group class="jh-radio is-md" row>
          <v-radio label="Radio 1" value="radio-7"></v-radio>
          <v-radio label="Radio 2" value="radio-8"></v-radio>
        </v-radio-group>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source02">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source02">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Switches <span class="jh-info-txt is-ml-10">기본 세로정렬입니다.</span></h2>
      <div class="guide-card">
        <h6>세로정렬</h6>   
        <v-switch v-model="switch1" label="사용"></v-switch>
        <v-switch v-model="switch2" label="사용"></v-switch>
        <v-divider class="is-mt-20 is-mb-20"></v-divider>
        <h6>가로정렬</h6>
        <div class="jh-selection-row">      
            <v-switch v-model="switch3" label="사용"></v-switch>
            <v-switch v-model="switch4" label="사용"></v-switch>
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source03">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source03">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source03_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Switches - inset <span class="jh-info-txt is-ml-10">기본 세로정렬입니다.</span></h2>
      <div class="guide-card">      
        <h6>세로정렬</h6>
        <v-switch v-model="switch5" inset label="사용"></v-switch>
        <v-switch v-model="switch6" inset label="사용"></v-switch>
        <v-divider class="is-mt-20 is-mb-20"></v-divider>
        <h6>가로정렬</h6>
        <div class="jh-selection-row">      
            <v-switch v-model="switch7" inset label="사용"></v-switch>
            <v-switch v-model="switch8" inset label="사용"></v-switch>
        </div>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source04">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source04">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source04_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Radio Button Group</h2>
      <div class="guide-card">
        <v-radio-group class="jh-radio-group" v-model="radioGroup">
          <v-radio label="선택" value="radio_group_1"></v-radio>
          <v-radio label="선택" value="radio_group_2"></v-radio>
          <v-radio label="선택" value="radio_group_3"></v-radio>
          <v-radio label="선택" value="radio_group_4"></v-radio>
        </v-radio-group>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source05">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source05">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source05_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "VueControls",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {     
        checkbox1: true,
        checkbox2: false,
        checkbox3: true,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        switch1: true,
        switch2: false,
        switch3: true,
        switch4: false,
        switch5: true,
        switch6: false,
        switch7: true,
        switch8: false,
        radioGroup: 'radio_group_1',
        source01: 'HTML',
        source02: 'HTML',
        source03: 'HTML',
        source04: 'HTML',
        source05: 'HTML',
        content:{

source01_tab01: `<!--세로정렬-->
<v-checkbox class="jh-check" v-model="checkbox1" label="선택"></v-checkbox>
<v-checkbox class="jh-check is-md" v-model="checkbox2" label="선택"></v-checkbox>
<v-checkbox class="jh-check is-circle" v-model="checkbox3" label="선택"></v-checkbox>
<!--가로정렬-->
<div class="jh-selection-row">               
  <v-checkbox class="jh-check" v-model="checkbox4" label="선택"></v-checkbox>
  <v-checkbox class="jh-check is-md" v-model="checkbox5" label="선택"></v-checkbox>
  <v-checkbox class="jh-check is-circle" v-model="checkbox6" label="선택"></v-checkbox>
</div>`
,source01_tab02: `export default {
  data () {
    return {  
        checkbox1: true,
        checkbox2: false,
        checkbox3: true,
        checkbox4: false,
    }
  },
}`
,source02_tab01: `<!--세로정렬-->
<v-radio-group class="jh-radio">
  <v-radio label="Radio 1" value="radio-1"></v-radio>
  <v-radio label="Radio 2" value="radio-2"></v-radio>
</v-radio-group>
<v-radio-group class="jh-radio is-md">
  <v-radio label="Radio 1" value="radio-3"></v-radio>
  <v-radio label="Radio 2" value="radio-4"></v-radio>
</v-radio-group>
<!--가로정렬-->
<v-radio-group class="jh-radio" row>
  <v-radio label="Radio 1" value="radio-5"></v-radio>
  <v-radio label="Radio 2" value="radio-6"></v-radio>
</v-radio-group> 
<v-radio-group class="jh-radio is-md" row>
  <v-radio label="Radio 1" value="radio-7"></v-radio>
  <v-radio label="Radio 2" value="radio-8"></v-radio>
</v-radio-group>`
,source03_tab01: `<!--세로정렬-->
<v-switch v-model="switch1" label="사용"></v-switch>
<v-switch v-model="switch2" label="사용"></v-switch>
<!--가로정렬-->
<div class="jh-selection-row">      
    <v-switch v-model="switch3" label="사용"></v-switch>
    <v-switch v-model="switch4" label="사용"></v-switch>
</div>`
,source03_tab02: `export default {
  data () {
    return {  
        switch1: true,
        switch2: false,
        switch3: true,
        switch4: false,
    }
  },
}`
,source04_tab01: `<!--세로정렬-->
<v-switch v-model="switch5" inset label="사용"></v-switch>
<v-switch v-model="switch6" inset label="사용"></v-switch>
<!--가로정렬-->
<div class="jh-selection-row">      
  <v-switch v-model="switch7" inset label="사용"></v-switch>
  <v-switch v-model="switch8" inset label="사용"></v-switch>
</div>`
,source04_tab02: `export default {
  data () {
    return {  
        switch5: true,
        switch6: false,
        switch7: true,
        switch8: false,
    }
  },
}`
,source05_tab01: `<v-radio-group class="jh-radio-group" v-model="radioGroup">
  <v-radio label="선택" value="radio_group_1"></v-radio>
  <v-radio label="선택" value="radio_group_2"></v-radio>
  <v-radio label="선택" value="radio_group_3"></v-radio>
  <v-radio label="선택" value="radio_group_4"></v-radio>
</v-radio-group>`
,source05_tab02: `export default {
  data () {
    return {  
      radioGroup: 'radio_group_1',
    }
  },
}`
      }
    }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>