import api from "@/store/apiUtil";
const userStore = {
  namespaced: true,
  state: () => ({
    // user_name: "도로시",
    user_state    : "none",
    user_role: 
    {
      userId      : "",
      userName    : "",
      userNick    : "",
      hpNo        : "",
      bday        : "",
      atrtGroupId : "",
      atrtGroupNm : "",
      cnnctIp     : "",
      lastLoginDt : "",
      INLNE_NO    : "",
      IPCC_NO     : "",
      CNNCT_IP    : "",
      TOPMSG      : "",
      ICON        : "",
      SCHEDULE    : 0,
      CHK_CUST: 
      {
        CHK    : 0,
        vue    : "",
        CHK_CD : "",
        CHK_NM : "",
      },
      company: [
        // {
        //   CD: "1024",
        //   CD_NM: "테스트기업2",
        // },
        // {
        //   CD: "1001",
        //   CD_NM: "네이버",
        // },
        // {
        //   CD: "1040",
        //   CD_NM: "KPP",
        // },
        // {
        //   CD: "1041",
        //   CD_NM: "HKC",
        // },
        // {
        //   CD: "001",
        //   CD_NM: "한국클라우드",
        // },
      ],
      USER_ATTR_A   : "",
      ATTR_DIV_NM_A : "",
      USER_ATTR_B   : "",
      ATTR_DIV_NM_B : "",
      USER_ATTR_C   : "",
      ATTR_DIV_NM_C : "",
      REMOTE_ID     : "", // 원격 ID
      CEN_TYPE_CD     : "", // 원격 ID
      //채널 필요 없음
      // channel: [
      //   {
      //     aspCustKey: "hkcloud",
      //     aspSenderKey: "1041",
      //     talkSenderKey: "416dd5770fc1f9b0442f820f79c9082150482117",
      //   },
      //   {
      //     aspCustKey: "kpp",
      //     aspSenderKey: "1042",
      //     talkSenderKey: "4f8bb80ee62869721a03c409791c0e430164daa6",
      //   },
      // ],
      //사용자 소속 필요없음
      // attr: [
      //   {
      //     aspCustKey: "hkcloud",
      //     userAttrA: "A01",
      //     userAttrANm: "한국클라우드",
      //     userAttrB: "B04",
      //     userAttrBNm: "STAFF",
      //     userAttrC: "C01",
      //     userAttrCNm: "시스템관리",
      //     userAttrD: "D03",
      //     userAttrDNm: "팀장(S/V)",
      //   },
      //   {
      //     aspCustKey: "kpp",
      //     userAttrA: "A01",
      //     userAttrANm: "한국클라우드",
      //     userAttrB: "B01",
      //     userAttrBNm: "상담사",
      //     userAttrC: "C01",
      //     userAttrCNm: "상담사",
      //     userAttrD: "D01",
      //     userAttrDNm: "시스템관리자",
      //   },
      //],
    },
    user_menu     : [], //사용자 메뉴권한 (tree 구조)
    user_menu_list: [], //사용자 메뉴 및 버튼 권한
  }),
  getters: {
    GE_USER_NAME           : (state) => state.user_role.userName,
    GE_USER_STATE          : (state) => state.user_state,
    GE_USER_ROLE           : (state) => state.user_role,
    GE_USER_MENU           : (state) => state.user_menu,
    GE_USER_MENU_LIST      : (state) => state.user_menu_list,
    GE_USER_ID             : (state) => state.user_role.userId,
    GE_USER_COMPANY        : (state) => state.user_role.company,
    GE_USER_INLNE_NO       : (state) => state.user_role.INLNE_NO,
    GE_USER_CNNCT_IP       : (state) => state.user_role.CNNCT_IP,
    GE_USER_TOPMSG         : (state) => state.user_role.TOPMSG,
    GE_USER_ICON           : (state) => state.user_role.ICON,
    GE_USER_SCHEDULE       : (state) => state.user_role.SCHEDULE,
    GE_USER_CHK_CUST       : (state) => state.user_role.CHK_CUST,
    GE_USER_HP_NO          : (state) => state.user_role.hpNo,
    GE_USER_BDAY           : (state) => state.user_role.bday,
  
    GE_USER_LastLoginDt    : (state) => state.user_role.lastLoginDt,
    GE_USER_AUTHOR_GROUP_ID: (state) => state.user_role.atrtGroupId,
    GE_USER_AUTHOR_GROUP_NM: (state) => state.user_role.atrtGroupNm,

    GE_USER_ATTR_A         : (state) => state.user_role.USER_ATTR_A,
    GE_USER_ATTR_B         : (state) => state.user_role.USER_ATTR_B,
    GE_USER_ATTR_DIV_NM_A  : (state) => state.user_role.ATTR_DIV_NM_A,
    GE_USER_ATTR_DIV_NM_B  : (state) => state.user_role.ATTR_DIV_NM_B,

    GE_USER_REMOTE_ID      : (state) => state.user_role.REMOTE_ID,
    GE_USER_CEN_TYPE_CD      : (state) => state.user_role.CEN_TYPE_CD,
  },
  mutations: {
    MU_USER_INIT: (state) => {
      state.user_state = "none";
      state.user_role = {
        userId      : "",
        userName    : "",
        userNick    : "",
        hpNo        : "",
        bday        : "",
        atrtGroupId : "",
        atrtGroupNm : "",
        cnnctIp     : "",
        lastLoginDt : "",
        INLNE_NO    : "",
        IPCC_NO     : "",
        CNNCT_IP    : "",
        TOPMSG      : "",
        ICON        : "",
        SCHEDULE    : 0,
        CHK_CUST: {
          CHK    : 0,
          vue    : "",
          CHK_CD : "",
          CHK_NM : "",
        },
        company: [],
        USER_ATTR_A   : "",
        ATTR_DIV_NM_A : "",
        USER_ATTR_B   : "",
        ATTR_DIV_NM_B : "",
        USER_ATTR_C   : "",
        ATTR_DIV_NM_C : "",
        REMOTE_ID     : "", // 원격 ID
        CEN_TYPE_CD     : "", // 원격 ID
      };
      state.user_menu = []; //사용자 메뉴권한 (tree 구조)
      state.user_menu_list = []; //사용자 메뉴 및 버튼 권한
    },
    MU_USER_NAME: (state, data) => {
      /*
        여기서는 payload를 객체로 받습니다.
        payload를 객체로 받으면, mutation를 조금더 유연하게 사용할 수 있기는 합니다.
      */
      state.user_name = data.userName;
    },
    MU_USER_STATE: (state, data) => {
      state.user_state = data.ustate;
    },
    MU_USER_COMPANY: (state, data) => {
      if (data) {
        let companyArray = [];
        for (var i = 0; i < data.length; i++) {
          companyArray.push({
            CD   : data[i]["CD"],
            CD_NM: data[i]["CD_NM"],
          });
        }
        state.user_role.company = companyArray;
      }
    },
    MU_USER_MENU: (state, data) => {
      // state.ustate = date.ustate;
      if (data) {
        state.user_menu      = data.MENU_ITEMS;
        state.user_menu_list = data.DATA;
      }
    },
    MU_USER_DETAIL: (state, data) => {
      // state.ustate = date.ustate;
      if (data.data) {
        let getData = data.data;
        console.log("getData:::  ", getData);
        state.user_role.userId        = getData.USER_ID;
        state.user_role.userName      = getData.USER_NM;
        state.user_role.userNick      = getData.USER_NICK;
        state.user_role.hpNo          = getData.HP_NO;
        state.user_role.bday          = getData.BDAY;
        state.user_role.atrtGroupId   = getData.AUTHOR_GROUP_ID_LIST;
        state.user_role.atrtGroupNm   = getData.AUTHOR_GROUP_NM_LIST;
        state.user_role.cnnctIp       = getData.CNNCT_IP;
        state.user_role.lastLoginDt   = getData.LAST_LOGIN_DT;
        state.user_role.INLNE_NO      = getData.INLNE_NO;
        state.user_role.IPCC_NO       = getData.IPCC_NO
        state.user_role.CNNCT_IP      = getData.CNNCT_IP;
        state.user_role.TOPMSG        = getData.TOPMSG;
        state.user_role.ICON          = getData.ICON;
        state.user_role.USER_ATTR_A   = getData.USER_ATTR_A;
        state.user_role.ATTR_DIV_NM_A = getData.ATTR_DIV_NM_A;
        state.user_role.USER_ATTR_B   = getData.USER_ATTR_B;
        state.user_role.ATTR_DIV_NM_B = getData.ATTR_DIV_NM_B;
        state.user_role.USER_ATTR_C   = getData.USER_ATTR_C;
        state.user_role.ATTR_DIV_NM_C = getData.ATTR_DIV_NM_C;
        state.user_role.REMOTE_ID = getData.REMOTE_ID;
        state.user_role.CEN_TYPE_CD = getData.CEN_TYPE_CD;
      }
    },
  },
  actions: {
    AC_USER_NAME: ({ commit }, data) => {
      commit("MU_USER_NAME", data);
    },
    AC_USER_STATE: ({ commit }, data) => {
      commit("MU_USER_STATE", data);
    },
    //사용자 회사 조회
    async AC_USER_COMPANY({ commit }, data) {
      let set_headers = {
        SERVICE: "code.common.code-book.sender",
        METHOD : "aspinqry",
        TYPE   : "BIZ_SERVICE",
        ASYNC  : false,
      };

      api
        .post(
          "api/code/common/code-book/aspinqry",
          {
            ASP_USER_ID: data.user_id,
          },
          { head: set_headers }
        )
        .then((response) => {
          if (response.data[data.user_id]) {
            commit("MU_USER_COMPANY", response.data[data.user_id]);
          }
        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("company 조회 에러", error);
        });
    },
    //사용자 메뉴 조회
    async AC_USER_MENU({ commit }, data) {
      api
        .post("api/palette-main/menu/list", {
          USER_ID: data.user_id,
        })
        .then((response) => {
          commit("MU_USER_MENU", response.data);
        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("menu 조회 에러", error);
        });
    },
    //사용자 상세정보 조회
    async AC_USER_DETAIL({ commit }, data) {
      // api
      //   .post("api/setting/agent/manage/detail", {
      //     USER_ID: data.user_id,
      //     ASP_NEWCUST_KEY: data.asp_cust_key,
      //   })
      //   .then((response) => {
      commit("MU_USER_DETAIL", data);
      // })
      // .catch((error) => {
      //   // 에러인 경우 처리
      //   console.error("사용자 상세정보 조회 에러", error);
      // });
    },
  },
};

export default userStore;
