import api from "@/store/apiUtil";
const popStore = {
  namespaced: true,
  state: () => ({
    gArrOpenObjentWin : []
  }),
  getters: {
    GE_POP: (state) => state.gArrOpenObjentWin,

  },
  mutations: {
    MU_POP: (state, data) => {
      state.gArrOpenObjentWin.push(data);
    },
  },
  actions: {
 
  },
};

export default popStore;
