<template>
    <div class="guide-content"> 
        <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Window Popup</span></div>
  
        <h2>Window Popup</h2>
        <div class="guide-card">  
            <v-btn class="jh-btn" @click="openSample()">윈도우팝업</v-btn>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
            <v-tab>Script</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source01">
            <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
            </v-tab-item>
            <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>
  
    </div>
  </template>
  
<script>
  /*
  https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
  npm install --save highlight.js
  */
import hljs from 'highlight.js';
export default {
    name: "VuePopup",
    directives: {
        'highlightjs': {
            bind: function (el, binding) {
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                    if (binding.value) {
                        target.innerHTML = binding.value
                    }
                    hljs.highlightBlock(target)
                })
            },
            componentUpdated: function(el, binding) {
                // after an update, re-fill the content and then highlight
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                if (binding.value) {
                    target.textContent = binding.value
                    hljs.highlightBlock(target)
                }
                })
            }
        }
    },
    components: { 
    },
    data() {
        return { 
          source01: 'HTML',
          content:{
  
  source01_tab01: `<v-btn class="jh-btn" @click="openSample()">윈도우팝업</v-btn>`
  ,source01_tab02: `methods: {
    openSample(){
      this.$openWindow('popupSample','popupSample','1200','800');
    }
},`
        }
      }
    },
    methods: {
        openSample(){
          this.$openWindow('popupSample','popupSample','1200','800');
        }
    },
    computed: {
    },
    mounted() {  
    }
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>