<template>
  <!-- <transition name="slide">
    <div class="jh-quick-layer slide-right">
      <div class="jh-quick-layer-header" @click="closeModal()">
        <h1>ARS예약</h1>
        <div class="is-right">
          <v-btn class="jh-btn is-icon">
          <i class="jh-icon-close-md"></i></v-btn>
        </div>
      </div> -->
      <!-- <div class="jh-quick-layer-body"> -->
      <div>
        <!--조회-->
        <div class="jh-search-form">
          <table>
            <colgroup>
              <col width="40px">
              <col width="125px">
              <col width="125px">
              <col width="110px">
              <col width="80px">
              <col width="100px">
              <col>
            </colgroup>
            <tr>
              <th><label>접수일</label></th>
              <td colspan="2">
                <div class="jh-cols">                        
                  <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                      maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                    </template>
                    <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                  </v-menu>
                  <span class="jh-unit">~</span>                       
                  <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                      maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                    </template>
                    <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                  </v-menu>
                </div>
              </td>
            </tr>
            <tr>
              <th><label>담당자</label></th>
              <td><v-select class="jh-form" :items="ATTR_A_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="ATTR_A" :disabled="atrtGroupId === '20220826113539330TWBaSY4N' ? true : false"></v-select></td>
              <td><v-select class="jh-form" :items="ATTR_B_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="ATTR_B" :disabled="atrtGroupId === '20220826113539330TWBaSY4N' ? true : false"></v-select></td>
              <td><v-select class="jh-form" :items="USER_COMBO" item-text="USER_NM" item-value="USER_ID" placeholder="선택" v-model="CSLT_MAN" :disabled="atrtGroupId === '20220826113539330TWBaSY4N' ? true : false"></v-select></td>
              <th><label>처리상태</label></th>
              <td><v-select class="jh-form" :items="RSV003_COMBO" item-text="CD_NM" item-value="CD" v-model="ARS_PROC_STAT_CD"></v-select></td>
              <td class="has-search"><v-btn class="jh-btn is-search" @click="selectbutton">조회</v-btn></td>
            </tr>
          </table>
        </div>
        <!--//조회-->

        <div class="jh-form-wrap">
          <div class="jh-ui-header">
            <div class="is-left">
              <h2>ARS예약 리스트</h2>
            </div>
            <div class="is-right">
              <p class="jh-info-txt is-red">
                <strong class="is-txt-red">[주의]</strong> ARS예약 접수고객 선택 후 콜메인에서 다른 고객 조회 시 ▶ <strong class="is-txt-red">먼저 CLEAR 후 진행</strong>
              </p>
            </div>
          </div>
          <data-tables
            ref="dataTable"
            dataTableClass=""
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            @dblclick:row="onClickRow2"
            :paginationOptions="paginationOptions"
          >
            <template v-slot:item.CUST_NM="{ item }">
              <span v-if="item.CUST_NO == '' && item.ACCOUNT == ''">미인증고객</span>
              <span v-else>{{ item.CUST_NM }}</span>
            </template>
          </data-tables>
        </div>

        <!--상세-->
        <div class="jh-form-wrap is-mt-10">
          <div class="jh-ui-header">
            <h3>처리정보</h3>
            <div class="is-right">
              <v-btn class="jh-btn is-fill" @click="saveDetail">상태저장</v-btn>
            </div>
          </div>
          <table class="jh-tbl-detail">
            <colgroup>
                <col width="100px">
                <col>
                <col width="100px">
                <col width="180px">
            </colgroup>
            <tbody>
                <tr>
                    <th rowspan="3"><label>상담내용</label></th>
                    <td rowspan="3"> 
                      <textarea class="jh-form is-trns" style="height: 85px;" v-model="detailObj.CNSL_CNTN"></textarea>
                    </td>
                    <th><label>처리상태</label></th>
                    <td> 
                      <v-select class="jh-form" :items="RSV003_COMBO" item-text="CD_NM" item-value="CD" v-model="detailObj.PROC_ST_CD"></v-select>
                    </td>
                </tr>
                <tr>
                  <th><label>처리자</label></th>
                  <td> 
                    <span class="is-value">{{detailObj.PROC_MAN}}</span>
                  </td>
                </tr>
                <tr>
                  <th><label>처리일시</label></th>
                  <td> 
                    <span class="is-value">{{detailObj.PROC_DT_TIME}}</span>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
        <!--상세-->
      </div>
    </div>
  </transition>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import { mapGetters } from "vuex";

export default {
  name: "MENU_T100701", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],

  components: {
    dataTables      
  },

  props: [ "evt" ],

  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      dates: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],
      
      headers: [
        { text: 'NO', value: 'ROWNUM', align: 'center', width: '40px', },
        { text: '접수일시', value: 'RCPT_DT_TIME', align: 'center', width: '140px',},
        { text: '계좌번호', value: 'ACCOUNT', align: 'center', width: '120px', },
        { text: '고객명', value: 'CUST_NM', align: 'center', width: '100px', },
        { text: '전화번호', value: 'TNO', align: 'center', width: '120px', },
        { text: '업무유형', value: 'CNSL_TYPE', align: 'center', },
        { text: '처리상태', value: 'PROC_ST', align: 'center', width: '80px', },
        { text: '담당자', value: 'CSLT_MAN', align: 'center', width: '120px', },
        { text: '통화일시', value: 'TEL_DT_TIME', align: 'center', width: '140px', },
        { text: '통화결과', value: 'TEL_RSLT_CD_NM', align: 'center', width: '100px',},
      ],
      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        // height: '522px',
        height: '460px',
        itemKey: 'ROWNUM',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,
        itemClass: "[item.selected && 'selected']"
      },
      paginationOptions: {
        totalVisible: 10
      },
      alertMsg: {
        saveDetail: '저장하시겠습니까?'
      },

      // 콤보박스
      RSV003_COMBO: [{ CD: '', CD_NM: '전체' }], // 처리상태
      ATTR_A_COMBO: [], // 대분류
      ATTR_B_COMBO: [], // 중분류
      USER_COMBO: [], // 담당자선택

      // 조회조건
      CSLT_MAN: '',   // 담당자
      ARS_PROC_STAT_CD: '',    // 처리상태

      // 상세내용
      detailObj: {},
      DT_ARS_PROC_STAT_CD: '',    // 처리상태
      DT_CSLT_MAN: '',    // 담당자
      DT_RCPT_DT: '', // 처리일자
      DT_RCPT_TIME: '',   // 처리시간
      DT_RCPT_CONTENTS: '',    // 처리내용 - CNSL_CNTN

      histNos: '',

      // 콤보박스 선택값
      ATTR_A: '',
      ATTR_B: '',

      blng_obj: {},
      selectedRow: {},

      atrtGroupId: this.$store.getters["userStore/GE_USER_ROLE"].atrtGroupId,
    }
  },
  computed: {
    ...mapGetters({
      geCallStt: 'callStore/GE_CALL_STATE',
      geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
    }),
    rowNum() {
      return this?.items?.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
    dataTableInstance() {
      return this.$refs.dataTable;
    },     
    initHeaders(){
      return {
        SERVICE: "phone.ars",
        METHOD: "",
        TYPE: 'BIZ_SERVICE',
      };
    },
  },
  created() { 
    this.dataTableOptions.headers = this.headers;
    this.dataTableOptions.items = this.rowNum;
  },
  mounted() {
    this.init();
    this.getSelBoxList();
    this.getAttrList();
    this.userDataInit();
  },
  watch: {
    "ATTR_A": async function (val) {
      this.CSLT_MAN = '';
      this.ATTR_B = '';

      if (val == '') {
        this.ATTR_B_COMBO = [];
        this.USER_COMBO = [];
      } else {
        this.ATTR_B_COMBO = await this.mixin_get_attr_by_attr('', val);
      }
    },
    "ATTR_B": async function (val) {
      this.CSLT_MAN = '';

      if (val == '') {
        this.USER_COMBO = [];
      } else {
        this.USER_COMBO = await this.mixin_get_user_by_attr(this.ATTR_A, val);
      }
    },
  },
  methods: {
    init() {
    },
    
    closeModal() {
      this.$parent.closeModal('slide2');
    },

    fromOnInput() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    changeDate(type) {
      if(type == 'start') {
        if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(90, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[0] = date;
          this.from = date;
          return;
        } else {
          this.from = this.dates[0];
        }
      } else if (type == 'end') {
        if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(90, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[1] = date;
          this.to = date;
          return;
        } else {
          this.to = this.dates[1];
        }
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }
        
        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
    onClickRow(item, row) { 
      row.select(true);
      row.isSelected = true;
      this.selectedRow = row;
      this.detailObj = item;
    },
    onClickRow2(e, { item }) {
      if(item.TEL_RSLT_CD == '01') {
        this.common_alert('통화성공건은 진행할 수 없습니다.', 'noti');
        return;
      } else {
        let custInfo = {};
  
        custInfo.SRCH_ACCOUNT = item.ACCOUNT.replace(/\-/g, '');
        custInfo.SRCH_CL_CID = item.CUST_NO;
        custInfo.SCDL_ID = item.SCDL_ID; // 스케쥴ID
        custInfo.ID = '';
        custInfo.IVR_IN_NO = item.IVR_IN_NO_NM;
        custInfo.IVR_IN_PATH = item.IVR_IN_PATH_NM;
        custInfo.IVR_STD_TIME = this.calStdTime(item);
        custInfo.IVR_MENT = item.IVR_MENT;
        custInfo.message = 'T100701';

        if (custInfo.SRCH_CL_CID == '') {
          custInfo.SRCH_CL_CID = '9999999999999';
        }
  
        console.log(custInfo);
        window.opener.postMessage(custInfo, '*');
      }
    },
    async getSelBoxList() {
      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {}
      };

      requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
      requestData.headers["METHOD"] = "selectCode";
      requestData.headers["ASYNC"] = false;

      let sendList = [];
      sendList.push("RSV003"); //처리결과코드

      requestData.sendData["GROUP_CD"] = sendList

      const response = await this.common_postCall(requestData);

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

        for (var i = 0; i < response.DATA.length ; i++){
          if (response.DATA[i].GROUP_CD == "RSV003"){
            this.RSV003_COMBO.push(response.DATA[i]);
          }
        }
      }
    },
    async getAttrList() {
      this.ATTR_A_COMBO = await this.mixin_get_attr_by_attr('****', '');
    },
    selectbutton() {
      this.dataTableOptions.load = this.selectArsWork;
      this.detailInit();

      if (this.dataTableOptions.page > 1) {
        this.dataTableOptions.page = 1;
      } else {
        this.dataTableInstance.loadData();
      }
    },
    async selectArsWork(loadOptions) {

      if (this.selectedRow.isSelected) {
        this.selectedRow.select(false);
      }
      
      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {}
      };
      
      const sortBy = loadOptions?.sortBy?.[0];
      const sortDesc = loadOptions?.sortDesc?.[0] === undefined ? 'desc' : loadOptions?.sortDesc?.[0] ? 'desc' : 'asc';

      requestData.headers["URL"] = "/api/phone/ars/list";
      requestData.headers["METHOD"] = "select";
      requestData.headers["ASYNC"] = false;
      requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
      requestData.headers["PAGES_CNT"] = loadOptions.page; 

      requestData.sendData["STR_RECP_DT"] = this.dates[0].replace(/\-/g, "");
      requestData.sendData["END_RECP_DT"] = this.dates[1].replace(/\-/g, "");
      requestData.sendData["CSLT_MAN"] = this.CSLT_MAN;
      requestData.sendData["PROC_ST_CD"] = this.ARS_PROC_STAT_CD;
      requestData.sendData["USER_ATTR_A"] = this.ATTR_A;
      requestData.sendData["USER_ATTR_B"] = this.ATTR_B;
      requestData.sendData["SORT_ORDR"] = sortBy ? sortBy : 'ROWNUM';
      requestData.sendData["SORT_DRCT"] = sortDesc;

      const response = await this.common_postCall(requestData);
      this.detailObj = {};

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
        let header = response.HEADER;
        let data = response.DATA;

        data.forEach(item => {
          item.ACCOUNT = item.ACCOUNT.indexOf('-') < 0 ? 
              this.mixin_accNoFormat(item.ACCOUNT) : this.mixin_accNoFormat(item.ACCOUNT.replace(/\-/g, ''));
          item.TNO = item.TNO.indexOf('-') < 0 ? this.mixin_newPhoneFormat(item.TNO) : this.mixin_newPhoneFormat(item.TNO.replace(/\-/g, ''));
        });

        return {
          data: data,
          totalCount: header.TOT_COUNT
        };
      }
    },
    detailInit() {
      this.DT_ARS_PROC_STAT_CD = '';
      this.DT_CSLT_MAN = '';
      this.DT_RCPT_DT = '';
      this.DT_RCPT_CONTENTS = '';
    },
    saveDetail() {
      const item = this.selectedRow.item;
      if (this.selectedRow.isSelected != undefined) {
        if (item.TEL_DATE == '' || item.TEL_RSLT_CD == '') {
          this.common_alert('통화결과 값이 없습니다.', 'noti');
          return;
        } else if (this.detailObj.PROC_ST_CD == '00') {
          this.common_alert('예약 상태 저장은 불가합니다.', 'noti');
          return;
        }else {
          this.common_confirm(this.alertMsg.saveDetail, this.saveConfirm, null, null, null, "error");
        }
      } else {
        this.common_alert("선택된 항목이 없습니다.", "noti");
        return;
      }
    }, 
    async saveConfirm() {
      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {}
      };

      requestData.headers["URL"] = "/api/phone/ars/up-detail";
      requestData.headers["METHOD"] = "update";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["PROC_ST_CD"] = this.detailObj.PROC_ST_CD;
      requestData.sendData["PROC_CNTN"] = this.detailObj.PROC_CNTN;
      requestData.sendData["SCDL_ID"] = this.detailObj.SCDL_ID;
      requestData.sendData["UPD_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
      requestData.sendData["PROC_MAN"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;

      const response = await this.common_postCall(requestData);

      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
        this.common_alert(`정상적으로 수정되었습니다.`, 'done')
        this.dataTableInstance.loadData();
      }
    },
    async userDataInit() {
      if (this.atrtGroupId === '20220826113539330TWBaSY4N') {
        const obj = await this.mixin_get_blng('001');
        const obj2 = JSON.stringify(obj);
        const obj3 = JSON.parse(obj2);
  
        this.ATTR_A = obj3[0].USER_ATTR_A;
        this.ATTR_B_COMBO = await this.mixin_get_attr_by_attr('', this.ATTR_A);
        this.ATTR_B = obj3[0].USER_ATTR_B;
        this.USER_COMBO = await this.mixin_get_user_by_attr(this.ATTR_A, this.ATTR_B);
        this.CSLT_MAN = this.$store.getters["userStore/GE_USER_ROLE"].userId;
      }
    },
    calStdTime(item) {
      const recpTime = item.RCPT_TIME;
      const ivrTime = item.IVR_STD_TIME.substr(8, 6);

      const hour = recpTime.substr(0, 2) - ivrTime.substr(0, 2);
      const minute = recpTime.substr(4, 2) > ivrTime.substr(4, 2) ? recpTime.substr(2, 2) - ivrTime.substr(2, 2) : recpTime.substr(2, 2) - 1 - ivrTime.substr(2, 2);
      const second = recpTime.substr(4, 2) > ivrTime.substr(4, 2) ? recpTime.substr(4, 2) - ivrTime.substr(4, 2) : recpTime.substr(4, 2) + 60 - ivrTime.substr(4, 2);

      const hour_c = ('00' + hour).slice(-2);
      const minute_c = ('00' + minute).slice(-2);
      const second_c = ('00' + second).slice(-2);

      return hour_c + ':' + minute_c + ':' + second_c;
    }
  },
}
</script>

<style scoped>
</style>