import api from "@/store/apiUtil";
const logStore = {
  namespaced: true,
  state: () => ({
    Log : ""
  }),
  getters: {
    GE_LOG: (state) => state.Log,

  },
  mutations: {
    MU_LOG: (state, data) => {
      state.Log = state.Log.concat(data);
    },
  },
  actions: {
 
  },
};

export default logStore;
