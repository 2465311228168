<template>
    <div class="guide-content"> 
        <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Tree Menu</span></div>
  
        <h2>Default</h2>
        <div class="guide-card">  
            <div class="jh-tree" style="height: 300px;">
                <div class="jh-tree-header">
                    <div class="is-left">
                        <label>기본 메뉴</label>
                    </div>
                    <div class="is-right">
                        <v-btn class="jh-btn is-sm" @click="expandAll('treeDefault')">전체 펼치기</v-btn>
                        <v-btn class="jh-btn is-sm" @click="collapseAll('treeDefault')">전체 접기</v-btn>
                    </div>
                </div>
                <div class="jh-tree-body">
                    <v-treeview ref="treeDefault" :items="treeItems" :open-all="expanded" activatable open-on-click color="secondary" class="jh-tree-view">
                    </v-treeview>
                </div>
            </div>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source01">
            <v-tab>Html</v-tab>
            <v-tab>Script</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source01">
            <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
            </v-tab-item>
            <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>Selectable</h2>
        <div class="guide-card">  
            <div class="jh-tree" style="height: 300px;">
                <div class="jh-tree-header">
                    <div class="is-left">
                        <label>체크 메뉴</label>
                    </div>
                    <div class="is-right">
                        <v-btn class="jh-btn is-sm" @click="expandAll('treeSelectable')">전체 펼치기</v-btn>
                        <v-btn class="jh-btn is-sm" @click="collapseAll('treeSelectable')">전체 접기</v-btn>
                    </div>
                </div>
                <div class="jh-tree-body">
                    <v-treeview ref="treeSelectable" :items="treeItems" selectable activatable open-on-click color="secondary" class="jh-tree-view">
                    </v-treeview>
                </div>
            </div>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source02">
            <v-tab>Html</v-tab>
            <v-tab>Script</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source02">
            <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
            </v-tab-item>
            <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source02_tab02}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <h2>Icons</h2>
        <div class="guide-card">  
            <div class="jh-tree" style="height: 300px;">
                <div class="jh-tree-header">
                    <div class="is-left">
                        <label>아이콘 메뉴</label>
                    </div>
                    <div class="is-right">
                        <v-btn class="jh-btn is-sm" @click="expandAll('treeIcon')">전체 펼치기</v-btn>
                        <v-btn class="jh-btn is-sm" @click="collapseAll('treeIcon')">전체 접기</v-btn>
                    </div>
                </div>
                <div class="jh-tree-body">
                    <v-treeview ref="treeIcon" :items="treeItems" item-key="name" activatable open-on-click color="secondary" class="jh-tree-view">
                        <template v-slot:prepend="{ item, open }">
                            <v-icon v-if="item.children">
                                {{open ? 'jh-icon-tree-folder-open' : 'jh-icon-tree-folder'}}
                            </v-icon>
                            <v-icon v-else>
                                {{ 'jh-icon-tree-file' }}
                            </v-icon>
                        </template>
                    </v-treeview>
                </div>
            </div>
        </div>
        <div class="guide-source">
          <v-tabs v-model="source03">
            <v-tab>Html</v-tab>
            <v-tab>Script</v-tab>
          </v-tabs>
          <v-tabs-items v-model="source03">
            <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
            </v-tab-item>
            <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source03_tab02}}</code></pre>
            </v-tab-item>
          </v-tabs-items>
        </div>
  
    </div>
  </template>
  
<script>
  /*
  <!-- https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
  npm install --save highlight.js -->
  */
import hljs from 'highlight.js';
export default {
    name: "VueTree",
    directives: {
        'highlightjs': {
            bind: function (el, binding) {
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                    if (binding.value) {
                        target.innerHTML = binding.value
                    }
                    hljs.highlightBlock(target)
                })
            },
            componentUpdated: function(el, binding) {
                // after an update, re-fill the content and then highlight
                let targets = el.querySelectorAll('code')
                targets.forEach((target) => {
                if (binding.value) {
                    target.textContent = binding.value
                    hljs.highlightBlock(target)
                }
                })
            }
        }
    },
    components: { 
    },
    data() {
        return { 
            treeItems: [
                {
                    id: 1,
                    name: 'menu1 :',
                    children: [
                    { id: 2, name: 'menu1-1' },
                    { id: 3, name: 'menu1-2' },
                    { id: 4, name: 'menu1-3' },
                    ],
                },
                {
                    id: 5,
                    name: 'menu2 :',
                    children: [
                    {
                        id: 6,
                        name: 'menu2-1 :',
                        children: [
                        {
                            id: 7,
                            name: 'menu2-1-1 :',
                            children: [
                            { id: 8, name: 'menu2-1-1-1' },
                            { id: 9, name: 'menu2-1-1-2' },
                            ],
                        },
                        ],
                    },
                    {
                        id: 10,
                        name: 'menu2-2 :',
                        children: [
                        {
                            id: 11,
                            name: 'menu2-2-1 :',
                            children: [
                            { id: 12, name: 'menu2-2-1-1' },
                            { id: 13, name: 'menu2-2-1-2' },
                            { id: 14, name: 'menu2-2-1-3' },
                            ],
                        },
                        ],
                    },
                    ],
                },
                {
                    id: 15,
                    name: 'menu3 :',
                    children: [
                    { id: 16, name: 'menu3-1' },
                    { id: 17, name: 'menu3-2' },
                    { id: 18, name: 'menu3-3' },
                    ],
                },
            ],
            expanded: false,
            
            source01: 'HTML',
            source02: 'HTML',
            source03: 'HTML',
            content:{  
source01_tab01: `<div class="jh-tree" style="height: 300px;">
    <div class="jh-tree-header">
        <div class="is-left">
            <label>기본 메뉴</label>
        </div>
        <div class="is-right">
            <v-btn class="jh-btn is-sm" @click="expandAll('treeDefault')">전체 펼치기</v-btn>
            <v-btn class="jh-btn is-sm" @click="collapseAll('treeDefault')">전체 접기</v-btn>
        </div>
    </div>
    <div class="jh-tree-body">
        <v-treeview ref="treeDefault" :items="treeItems" :open-all="expanded" activatable open-on-click color="secondary" class="jh-tree-view">
        </v-treeview>
    </div>
</div>`
,source01_tab02: `data() {
    return { 
        treeItems: [
            {
                id: 1,
                name: 'menu1 :',
                children: [
                { id: 2, name: 'menu1-1' },
                { id: 3, name: 'menu1-2' },
                { id: 4, name: 'menu1-3' },
                ],
            },
            {
                id: 5,
                name: 'menu2 :',
                children: [
                {
                    id: 6,
                    name: 'menu2-1 :',
                    children: [
                    {
                        id: 7,
                        name: 'menu2-1-1 :',
                        children: [
                        { id: 8, name: 'menu2-1-1-1' },
                        { id: 9, name: 'menu2-1-1-2' },
                        ],
                    },
                    ],
                },
                {
                    id: 10,
                    name: 'menu2-2 :',
                    children: [
                    {
                        id: 11,
                        name: 'menu2-2-1 :',
                        children: [
                        { id: 12, name: 'menu2-2-1-1' },
                        { id: 13, name: 'menu2-2-1-2' },
                        { id: 14, name: 'menu2-2-1-3' },
                        ],
                    },
                    ],
                },
                ],
            },
            {
                id: 15,
                name: 'menu3 :',
                children: [
                { id: 16, name: 'menu3-1' },
                { id: 17, name: 'menu3-2' },
                { id: 18, name: 'menu3-3' },
                ],
            },
        ],
    }
},
methods: {
    collapseAll(id){
        this.$refs[id].updateAll(this.expanded);
    },
    expandAll(id){
        this.$refs[id].updateAll(!this.expanded);
    }
},`
,source02_tab01: `<div class="jh-tree" style="height: 300px;">
    <div class="jh-tree-header">
        <div class="is-left">
            <label>체크 메뉴</label>
        </div>
        <div class="is-right">
            <v-btn class="jh-btn is-sm" @click="expandAll('treeSelectable')">전체 펼치기</v-btn>
            <v-btn class="jh-btn is-sm" @click="collapseAll('treeSelectable')">전체 접기</v-btn>
        </div>
    </div>
    <div class="jh-tree-body">
        <v-treeview ref="treeSelectable" :items="treeItems" selectable activatable open-on-click color="secondary" class="jh-tree-view">
        </v-treeview>
    </div>
</div>`
,source02_tab02: `data() {
    return { 
        treeItems: [
            {
                id: 1,
                name: 'menu1 :',
                children: [
                { id: 2, name: 'menu1-1' },
                { id: 3, name: 'menu1-2' },
                { id: 4, name: 'menu1-3' },
                ],
            },
            {
                id: 5,
                name: 'menu2 :',
                children: [
                {
                    id: 6,
                    name: 'menu2-1 :',
                    children: [
                    {
                        id: 7,
                        name: 'menu2-1-1 :',
                        children: [
                        { id: 8, name: 'menu2-1-1-1' },
                        { id: 9, name: 'menu2-1-1-2' },
                        ],
                    },
                    ],
                },
                {
                    id: 10,
                    name: 'menu2-2 :',
                    children: [
                    {
                        id: 11,
                        name: 'menu2-2-1 :',
                        children: [
                        { id: 12, name: 'menu2-2-1-1' },
                        { id: 13, name: 'menu2-2-1-2' },
                        { id: 14, name: 'menu2-2-1-3' },
                        ],
                    },
                    ],
                },
                ],
            },
            {
                id: 15,
                name: 'menu3 :',
                children: [
                { id: 16, name: 'menu3-1' },
                { id: 17, name: 'menu3-2' },
                { id: 18, name: 'menu3-3' },
                ],
            },
        ],
    }
},
methods: {
    collapseAll(id){
        this.$refs[id].updateAll(this.expanded);
    },
    expandAll(id){
        this.$refs[id].updateAll(!this.expanded);
    }
},`
,source03_tab01: `<div class="jh-tree" style="height: 300px;">
    <div class="jh-tree-header">
        <div class="is-left">
            <label>아이콘 메뉴</label>
        </div>
        <div class="is-right">
            <v-btn class="jh-btn is-sm" @click="expandAll('treeIcon')">전체 펼치기</v-btn>
            <v-btn class="jh-btn is-sm" @click="collapseAll('treeIcon')">전체 접기</v-btn>
        </div>
    </div>
    <div class="jh-tree-body">
        <v-treeview ref="treeIcon" :items="treeItems" item-key="name" activatable open-on-click color="secondary" class="jh-tree-view">
            <template v-slot:prepend="{ item, open }">
                <v-icon v-if="item.children">
                    {{open ? 'jh-icon-tree-folder-open' : 'jh-icon-tree-folder'}}
                </v-icon>
                <v-icon v-else>
                    {{ 'jh-icon-tree-file' }}
                </v-icon>
            </template>
        </v-treeview>
    </div>
</div>`
,source03_tab02: `data() {
    return { 
        treeItems: [
            {
                id: 1,
                name: 'menu1 :',
                children: [
                { id: 2, name: 'menu1-1' },
                { id: 3, name: 'menu1-2' },
                { id: 4, name: 'menu1-3' },
                ],
            },
            {
                id: 5,
                name: 'menu2 :',
                children: [
                {
                    id: 6,
                    name: 'menu2-1 :',
                    children: [
                    {
                        id: 7,
                        name: 'menu2-1-1 :',
                        children: [
                        { id: 8, name: 'menu2-1-1-1' },
                        { id: 9, name: 'menu2-1-1-2' },
                        ],
                    },
                    ],
                },
                {
                    id: 10,
                    name: 'menu2-2 :',
                    children: [
                    {
                        id: 11,
                        name: 'menu2-2-1 :',
                        children: [
                        { id: 12, name: 'menu2-2-1-1' },
                        { id: 13, name: 'menu2-2-1-2' },
                        { id: 14, name: 'menu2-2-1-3' },
                        ],
                    },
                    ],
                },
                ],
            },
            {
                id: 15,
                name: 'menu3 :',
                children: [
                { id: 16, name: 'menu3-1' },
                { id: 17, name: 'menu3-2' },
                { id: 18, name: 'menu3-3' },
                ],
            },
        ],
    }
},
methods: {
    collapseAll(id){
        this.$refs[id].updateAll(this.expanded);
    },
    expandAll(id){
        this.$refs[id].updateAll(!this.expanded);
    }
},`
        }
      }
    },
    methods: {
        collapseAll(id){
            this.$refs[id].updateAll(this.expanded);
        },
        expandAll(id){
            this.$refs[id].updateAll(!this.expanded);
        }
    },
    computed: {
    },
    mounted() {  
    },
  }
  </script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  </style>