<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Data Tables</span></div>

      <h2>Default</h2>
      <div class="guide-card">
        <data-tables
          ref="dataTable1"
          dataTableClass=""
          :data-table-options="dataTableOptions1"
          @click:row="onClickRow"
          @contextmenu:row="onContextmenuRow"
          @current-items="onCurrentItems"
          @dblclick:row="onDbclickRow"
          @input="dataTableOnInput"
          @item-expanded="onItemExpanded"
          @item-selected="onItemSelected"
          @page-count="onPageCount"
          @pagination="onPagination"
          @toggle-select-all="onToggleSelectAll"
          @update:expanded="onUpdateExpanded"
          @update:group-by="onUpdateGroupBy"
          @update:group-desc="onUpdateGroupDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:multi-sort="onUpdateMultiSort"
          @update:must-sort="onUpdateMustSort"
          @update:options="onUpdateOptions"
          @update:page="onUpdatePage"
          @update:sort-by="onUpdateSortBy"
          @update:sort-desc="onUpdateSortDesc"
          
          paginationClass=""
          :paginationOptions="paginationOptions"
        >
          <template v-slot:top="scopedProps">
            <button type="button" class="jh-btn" @click="addRow">추가</button>
          </template>
          <!-- <template v-slot:body="scopedProps">
            <tbody>
              <tr
                v-for="(item, index) in scopedProps.items"
                :key="index"
              >
                <td>{{ item.index }}</td>
                <td>{{ item.type }}</td>
                <td><div class="is-ellips" :title="item.subject">{{ item.contents }}</div></td>
                <td>{{ item.date }}</td>
              </tr>
            </tbody>
          </template> -->
        </data-tables>
        <!-- <v-data-table
          class="jh-grid"
          :headers="headers"
          :items="rowNum"
          fixed-header
          item-key="index"
          height="200px"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event">
        </v-data-table>
        <div class="jh-pager">          
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          <v-select
            class="jh-form is-round is-right"
            :items="perPage"           
            label="10"
            style="width: 70px"
          ></v-select>
          <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
        </div> -->
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Ellipsis</h2>
      <div class="guide-card">  
        <data-tables
          ref="dataTable2"
          dataTableClass=""
          :data-table-options="dataTableOptions1"
          @click:row="onClickRow"
          @contextmenu:row="onContextmenuRow"
          @current-items="onCurrentItems"
          @dblclick:row="onDbclickRow"
          @input="dataTableOnInput"
          @item-expanded="onItemExpanded"
          @item-selected="onItemSelected"
          @page-count="onPageCount"
          @pagination="onPagination"
          @toggle-select-all="onToggleSelectAll"
          @update:expanded="onUpdateExpanded"
          @update:group-by="onUpdateGroupBy"
          @update:group-desc="onUpdateGroupDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:multi-sort="onUpdateMultiSort"
          @update:must-sort="onUpdateMustSort"
          @update:options="onUpdateOptions"
          @update:page="onUpdatePage"
          @update:sort-by="onUpdateSortBy"
          @update:sort-desc="onUpdateSortDesc"
          
          paginationClass=""
          :paginationOptions="paginationOptions"
        >
          <template v-slot:item.contents="{ item }"><div class="is-ellips" :title="item.subject">{{ item.contents }}</div></template>
        </data-tables>
        <!-- <v-data-table
          class="jh-grid"
          :headers="headers"
          :items="rowNum"
          fixed-header
          item-key="index"
          height="200px"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event">
          <template v-slot:item.subject="{ item }"><div class="is-ellips" :title="item.subject">{{ item.subject }}</div></template>
        </v-data-table>
        <div class="jh-pager">          
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          <v-select
            class="jh-form is-round is-right"
            :items="perPage"           
            label="10"
            style="width: 70px"
          ></v-select>
          <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
        </div> -->
      </div>
      <div class="guide-source">
        <v-tabs v-model="source08">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source08">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source08_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source08_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Horizontal Scroll</h2>
      <div class="guide-card">  
        <data-tables
          ref="dataTable3"
          dataTableClass=""
          :data-table-options="dataTableOptions2"
          @click:row="onClickRow"
          @contextmenu:row="onContextmenuRow"
          @current-items="onCurrentItems"
          @dblclick:row="onDbclickRow"
          @input="dataTableOnInput"
          @item-expanded="onItemExpanded"
          @item-selected="onItemSelected"
          @page-count="onPageCount"
          @pagination="onPagination"
          @toggle-select-all="onToggleSelectAll"
          @update:expanded="onUpdateExpanded"
          @update:group-by="onUpdateGroupBy"
          @update:group-desc="onUpdateGroupDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:multi-sort="onUpdateMultiSort"
          @update:must-sort="onUpdateMustSort"
          @update:options="onUpdateOptions"
          @update:page="onUpdatePage"
          @update:sort-by="onUpdateSortBy"
          @update:sort-desc="onUpdateSortDesc"
          
          paginationClass=""
          :paginationOptions="paginationOptions"
        />
        <!-- <v-data-table
          class="jh-grid"
          :headers="headers4"
          :items="rowNum"
          fixed-header
          item-key="index"
          height="200px"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event">
        </v-data-table>
        <div class="jh-pager">          
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          <v-select
            class="jh-form is-round is-right"
            :items="perPage"           
            label="10"
            style="width: 70px"
          ></v-select>
          <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
        </div> -->
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source06_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source06_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Selected</h2>
      <div class="guide-card">  
        <data-tables
          ref="dataTable4"
          dataTableClass="has-control"
          :data-table-options="dataTableOptions3"
          v-model="selected"
          @click:row="onClickRow"
          @contextmenu:row="onContextmenuRow"
          @current-items="onCurrentItems"
          @dblclick:row="onDbclickRow"
          @input="dataTableOnInput"
          @item-expanded="onItemExpanded"
          @item-selected="onItemSelected"
          @page-count="onPageCount"
          @pagination="onPagination"
          @toggle-select-all="onToggleSelectAll"
          @update:expanded="onUpdateExpanded"
          @update:group-by="onUpdateGroupBy"
          @update:group-desc="onUpdateGroupDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:multi-sort="onUpdateMultiSort"
          @update:must-sort="onUpdateMustSort"
          @update:options="onUpdateOptions"
          @update:page="onUpdatePage"
          @update:sort-by="onUpdateSortBy"
          @update:sort-desc="onUpdateSortDesc"
          
          paginationClass=""
          :paginationOptions="paginationOptions"
        />
        <!-- <v-data-table
          class="jh-grid has-control"
          :headers="headers"
          :items="rowNum"
          item-key="index"
          fixed-header
          show-select
          height="200px"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event">
        </v-data-table>
        <div class="jh-pager">        
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          <v-select
            class="jh-form is-round is-right"
            :items="perPage"           
            label="10"
            style="width: 70px"
          ></v-select>
          <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
        </div> -->
      </div>
      <div class="guide-source">
        <v-tabs v-model="source02">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source02">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source02_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Custom</h2>
      <div class="guide-card">  
        <data-tables
          ref="dataTable5"
          dataTableClass=""
          :data-table-options="dataTableOptions4"
          @click:row="onClickRow"
          @contextmenu:row="onContextmenuRow"
          @current-items="onCurrentItems"
          @dblclick:row="onDbclickRow"
          @input="dataTableOnInput"
          @item-expanded="onItemExpanded"
          @item-selected="onItemSelected"
          @page-count="onPageCount"
          @pagination="onPagination"
          @toggle-select-all="onToggleSelectAll"
          @update:expanded="onUpdateExpanded"
          @update:group-by="onUpdateGroupBy"
          @update:group-desc="onUpdateGroupDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:multi-sort="onUpdateMultiSort"
          @update:must-sort="onUpdateMustSort"
          @update:options="onUpdateOptions"
          @update:page="onUpdatePage"
          @update:sort-by="onUpdateSortBy"
          @update:sort-desc="onUpdateSortDesc"
          
          paginationClass=""
          :paginationOptions="paginationOptions"
        >
          <template v-slot:item.subject="{ item }"><div class="is-ellips" :title="item.subject">{{ item.subject }}</div></template>
          <template v-slot:item.type="{ item }">
            <v-chip :color="getColor(item.type)" dark x-small>{{ item.type }}</v-chip>
          </template>
          <template v-slot:item.view>
            <button type="button" class="jh-btn is-icon"><i class="jh-icon-comment"></i></button>
          </template>
          <template v-slot:item.edit="{ item, index }">
            <button type="button" class="jh-btn is-icon" @click="updateRow(index, item)"><i class="jh-icon-edit"></i></button>
          </template>
          <template v-slot:item.delete="{ index }">
            <button type="button" class="jh-btn is-icon" @click="deleteRow(index)"><i class="jh-icon-trash"></i></button>
          </template>
        </data-tables>
        <!-- <v-data-table
          class="jh-grid"
          :headers="headers2"
          :items="rowNum"
          item-key="index"
          fixed-header
          height="200px"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event">
          <template v-slot:item.subject="{ item }"><div class="is-ellips" :title="item.subject">{{ item.subject }}</div></template>
          <template v-slot:item.type="{ item }">
            <v-chip :color="getColor(item.type)" dark x-small>{{ item.type }}</v-chip>
          </template>
          <template v-slot:item.view>
            <button type="button" class="jh-btn is-icon"><i class="jh-icon-comment"></i></button>
          </template>
          <template v-slot:item.edit>
            <button type="button" class="jh-btn is-icon"><i class="jh-icon-edit"></i></button>
          </template>
          <template v-slot:item.delete>
            <button type="button" class="jh-btn is-icon"><i class="jh-icon-trash"></i></button>
          </template>
        </v-data-table>
        <div class="jh-pager">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          <v-select
            class="jh-form is-round is-right"
            :items="perPage"
            
            label="10"
            style="width: 70px"
          ></v-select>
          <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
        </div> -->
      </div>
      <div class="guide-source">
        <v-tabs v-model="source03">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source03">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source03_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Expanded</h2>
      <div class="guide-card"> 
        <data-tables
          ref="dataTable6"
          dataTableClass="has-control"
          :data-table-options="dataTableOptions5"
          @click:row="onClickRow"
          @contextmenu:row="onContextmenuRow"
          @current-items="onCurrentItems"
          @dblclick:row="onDbclickRow"
          @input="dataTableOnInput"
          @item-expanded="onItemExpanded"
          @item-selected="onItemSelected"
          @page-count="onPageCount"
          @pagination="onPagination"
          @toggle-select-all="onToggleSelectAll"
          @update:expanded="onUpdateExpanded"
          @update:group-by="onUpdateGroupBy"
          @update:group-desc="onUpdateGroupDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:multi-sort="onUpdateMultiSort"
          @update:must-sort="onUpdateMustSort"
          @update:options="onUpdateOptions"
          @update:page="onUpdatePage"
          @update:sort-by="onUpdateSortBy"
          @update:sort-desc="onUpdateSortDesc"
          
          paginationClass=""
          :paginationOptions="paginationOptions"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="is-p-15">{{ item.contents }}</td>
          </template>
        </data-tables>
        <!-- <v-data-table        
          class="jh-grid has-control"
          :headers="headers3"
          :items="rowNum"is-p-10
          item-key="index"
          fixed-header
          show-expand
          singleExpand
          :expanded.sync="expanded"
          height="200px"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event">
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="is-p-10">{{ item.contents }}</td>
          </template>
        </v-data-table>
        <div class="jh-pager">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          <v-select
            class="jh-form is-round is-right"
            :items="perPage"           
            label="10"
            style="width: 70px"
          ></v-select>
          <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
        </div> -->
      </div>
      <div class="guide-source">
        <v-tabs v-model="source04">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source04">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source04_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Expanded - Row Click</h2>
      <div class="guide-card">  
        <data-tables
          ref="dataTable7"
          dataTableClass=""
          :data-table-options="dataTableOptions6"
          @click:row="rowClick"
          @contextmenu:row="onContextmenuRow"
          @current-items="onCurrentItems"
          @dblclick:row="onDbclickRow"
          @input="dataTableOnInput"
          @item-expanded="onItemExpanded"
          @item-selected="onItemSelected"
          @page-count="onPageCount"
          @pagination="onPagination"
          @toggle-select-all="onToggleSelectAll"
          @update:expanded="onUpdateExpanded"
          @update:group-by="onUpdateGroupBy"
          @update:group-desc="onUpdateGroupDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:multi-sort="onUpdateMultiSort"
          @update:must-sort="onUpdateMustSort"
          @update:options="onUpdateOptions"
          @update:page="onUpdatePage"
          @update:sort-by="onUpdateSortBy"
          @update:sort-desc="onUpdateSortDesc"
          
          paginationClass=""
          :paginationOptions="paginationOptions"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="is-p-15">{{ item.contents }}</td>
          </template>
        </data-tables>
        <!-- <v-data-table
          class="jh-grid"
          :headers="headers"
          :items="rowNum"
          item-key="index"
          singleSelect
          singleExpand
          :expanded.sync="expanded"
          fixed-header
          height="200px"
          @click:row="rowClick"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event">
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="is-p-10">{{ item.contents }}</td>
          </template>
        </v-data-table>
        <div class="jh-pager">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          <v-select
            class="jh-form is-round is-right"
            :items="perPage"
           
            label="10"
            style="width: 70px"
          ></v-select>
          <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
        </div> -->
      </div>
      <div class="guide-source">
        <v-tabs v-model="source05">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source05">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source05_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Multi Row Header</h2>
      <div class="guide-card">  
        <data-tables
          ref="dataTable8"
          dataTableClass=""
          :data-table-options="dataTableOptions7"
          @click:row="onClickRow"
          @contextmenu:row="onContextmenuRow"
          @current-items="onCurrentItems"
          @dblclick:row="onDbclickRow"
          @input="dataTableOnInput"
          @item-expanded="onItemExpanded"
          @item-selected="onItemSelected"
          @page-count="onPageCount"
          @pagination="onPagination"
          @toggle-select-all="onToggleSelectAll"
          @update:expanded="onUpdateExpanded"
          @update:group-by="onUpdateGroupBy"
          @update:group-desc="onUpdateGroupDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:multi-sort="onUpdateMultiSort"
          @update:must-sort="onUpdateMustSort"
          @update:options="onUpdateOptions"
          @update:page="onUpdatePage"
          @update:sort-by="onUpdateSortBy"
          @update:sort-desc="onUpdateSortDesc"
          
          paginationClass=""
          :paginationOptions="paginationOptions"
        >
          <template v-slot:header>
            <thead class="v-data-table-header">
              <tr>
                <th rowspan="2" width="60px"><span>NO</span></th>
                <th colspan="3" width="600px"><span>상담유형</span></th>
                <th rowspan="2"><span>스크립트명</span></th>
                <th rowspan="2" width="100px"><span>수정일</span></th>
              </tr>
              <tr>
                <th><span>대분류</span></th>
                <th><span>중분류</span></th>
                <th><span>소분류</span></th>
              </tr>
            </thead>
          </template>
        </data-tables>
        <!-- <v-data-table
          class="jh-grid"
          :headers="headers5"
          :items="rowNum"
          fixed-header
          item-key="index"
          height="200px"
          hide-default-header
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event">       
          <template v-slot:header="props">
            <thead class="v-data-table-header">
              <tr>
                <th rowspan="2" width="60px"><span>NO</span></th>
                <th colspan="3" width="600px"><span>상담유형</span></th>
                <th rowspan="2"><span>스크립트명</span></th>
                <th rowspan="2" width="100px"><span>수정일</span></th>
              </tr>
              <tr>
                <th><span>대분류</span></th>
                <th><span>중분류</span></th>
                <th><span>소분류</span></th>
              </tr>
            </thead>
          </template>
        </v-data-table> 
        <div class="jh-pager">          
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          <v-select
            class="jh-form is-round is-right"
            :items="perPage"           
            label="10"
            style="width: 70px"
          ></v-select>
          <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
        </div> -->
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source07_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source07_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';

import dataTables from "@/components/DataTables";

export default {
  name: "VueDataTables",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
    dataTables
  },
  data() {
      return {

        page: 1,
        pageCount: 0,
        perPage: ['10','50','100','200'],
        
        pagination  : {page : 2 , rowsPerPage : 5, sortBy : '', descending : ''},

        selected: [],

        selectedId: null,

        headers: [
          { text: '순번', value: 'index', align: 'center', width: '80px' },
          { text: '유형', value: 'type', align: 'center', width: '120px' },
          { text: '내용', value: 'contents'},
          { text: '수정일', value: 'date', align: 'center', width: '120px' },
        ],
        headers2: [
          { text: '순번', value: 'index', align: 'center', width: '50px' },
          { text: '유형', value: 'type', align: 'center', width: '60px', sortable: false },
          { text: '내용', value: 'view', align: 'center', width: '40px', sortable: false },
          { text: '스크립트명', value: 'subject'},
          { text: '수정', value: 'edit', align: 'center', width: '40px', sortable: false },
          { text: '삭제', value: 'delete', align: 'center', width: '40px', sortable: false },
        ],
        headers3: [
          { text: '', value: 'data-table-expand' },
          { text: '순번', value: 'index', align: 'center', width: '50px' },
          { text: '유형', value: 'type', align: 'center', width: '100px' },
          { text: '스크립트명', value: 'subject'},
          { text: '수정일', value: 'date', align: 'center', width: '100px' },
        ],
        headers4: [
          { text: '순번', value: 'index', align: 'center', width: '280px' },
          { text: '유형', value: 'type', align: 'center', width: '520px' },
          { text: '스크립트명', value: 'subject', width: '520px'},
          { text: '수정일', value: 'date', align: 'center', width: '220px' },
        ],
        headers5: [
          { text: '순번', value: 'index', align: 'center', width: '80px' },
          { text: '대분류', value: 'type01', align: 'center', width: '120px' },
          { text: '중분류', value: 'type02', align: 'center', width: '120px' },
          { text: '소분류', value: 'type03', align: 'center', width: '120px' },
          { text: '스크립트명', value: 'subject'},
          { text: '수정일', value: 'date', align: 'center', width: '120px' },
        ],
        items: [
          {
            type: '공통',
            subject: '취소수수료를 환불해 주세요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '호출중이었는데 앱이 꺼졌어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '개인',
            subject: '택시에 물건을 두고 내렸어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '호출을 기다리는데 기사님이 취소했어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '개인',
            subject: '카드 등록이 안되요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '취소수수료를 환불해 주세요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '호출중이었는데 앱이 꺼졌어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '개인',
            subject: '택시에 물건을 두고 내렸어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '호출을 기다리는데 기사님이 취소했어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '개인',
            subject: '카드 등록이 안되요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '취소수수료를 환불해 주세요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '호출중이었는데 앱이 꺼졌어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '개인',
            subject: '택시에 물건을 두고 내렸어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '호출을 기다리는데 기사님이 취소했어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '개인',
            subject: '카드 등록이 안되요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '취소수수료를 환불해 주세요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '호출중이었는데 앱이 꺼졌어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '개인',
            subject: '택시에 물건을 두고 내렸어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '공통',
            subject: '호출을 기다리는데 기사님이 취소했어요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
          {
            type: '개인',
            subject: '카드 등록이 안되요.',
            contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
            date: '2020-08-05'
          },
        ],
        
        dataTableOptions1: null,
        dataTableOptions2: null,
        dataTableOptions3: null,
        dataTableOptions4: null,
        dataTableOptions5: null,
        dataTableOptions6: null,
        dataTableOptions7: null,

        // v-data-table의 props로 전달될 option
        dataTableOptions: {
          calculateWidths: undefined, // type: boolean, default: false, 컬럼 넓이 계산 기능 활성화
          caption: undefined, // type: string, Grid 상단에 셋팅한 문자열로 caption을 표출
          checkboxColor: undefined, // type: string, showSelect가 true 일 때 체크박스의 색을 지정
          customFilter: undefined, // type: function, default: defaultFilter, 커스터마이징된 필터를 셋팅(function(value, search, item) { return boolean })
          customGroup: undefined, // type: function, default: groupItems, 커스터마이징된 그룹을 셋팅(function(items, groupBy, groupDesc) { return Record<string, any[]> })
          customSort: undefined, // type: function, default: sortItems, 커스터마이징된 정렬을 셋팅(function(items, sortBy, sortDesc, locale, customSorters) { return any[] })
          dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
          dense: undefined, // type: boolean, default: false, Row의 높이가 축소된 레이아웃 모드 활성화
          disableFiltering: undefined, // type: boolean, default: false, 필터링 기능 비활성화
          disablePagination: undefined, // type: boolean, default: false, 페이징 기능 비활성화, TotalCount는 표출
          disableSort: undefined, // type: boolean, default: false, 정렬 기능 비활성화
          expandIcon: undefined, // type: string, default: '$expand', 확장기능 버튼에 적용할 커스텀 아이콘 지정
          expanded: undefined, // type: array, default: [], 확장기능을 사용한 확장된 Rows, 초기값 지정으로만 사용
          fixedHeader: true, // type: boolean, default: false, 스크롤 시 Grid 상단에 헤더를 고정하는 기능 활성화
          footerProps: undefined, // type: object, Default footer 사용 시 v-data-footer의 props로 전달될 값 - 사용 안항
          groupBy: undefined, // type: string|array, default: [], Grouping을 위한 컬럼 지정, 배열 사용 시 여러 컬럼 지정 가능
          groupDesc: undefined, // type: boolean|array, default: [], Grouping한 컬럼의 정렬 순서 지정, 여러 컬럼을 Grouping한 경우, 컬럼 순서와 동일하게 배열로 지정, true일 경우 내림차순
          headerProps: undefined, // type: object, Default header 사용 시 v-data-header의 props로 전달될 값
          headers: undefined, // type: DataTableHeader[], default: [], 각각의 헤더 컬럼의 정보({ text: string, value: string, align?: 'start'|'center'|'end', sortable?: boolean, filterable?: boolean, groupable?: boolean, divider?: boolean, class?: string|string[], cellClass?: string|string[], width?: string|number, filter?: (value, search, item) => boolean, sort?: (a, b) => number })
          headersLength: undefined, // type: number, 헤더 컬럼의 수를 지정, hide-default-header와 함께
          height: '200px', // type: number|string, Grid의 높이 지정
          hideDefaultFooter: true, // type: boolean, default: false, Default footer 비활성화
          hideDefaultHeader: undefined, // type: boolean, default: false, Default header 비활성화
          invisiblePagination: undefined, // type: boolean, default: false, 페이징 기능 전체 표출 안함
          itemClass: undefined, // type: string|function, Row에 지정할 class
          itemKey: 'index', // type: string, default: 'id', Row 선택 시 사용하는 유니크한 Column 이름, 헤더정보의 value들 중 하나여야 함
          items: undefined, // type: array, default: [], Grid에 보여질 데이터 세팅, api를 통해 데이터 로드하기 위해 load를 사용할 경우 items는 undefined, 처리된 array data를 바로 보여줄 경우에 사용
          itemsPerPage: 5, // type: number, default: 10, Grid에 보여줄 Row의 수
          light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
          loaderHeight: undefined, // type: number|string, default: 4, loader의 높이 지정
          loading: undefined, // type: boolean|string, default: false, loader 표출 여부 지정
          loadingText: undefined, // type: string, default: '$vuetify.dataIterator.loadingText', loading이 true일 경우 보여질 텍스트 지정
          locale: undefined, // type: string, default: 'ko-KR', 정렬을 위한 국가 코드 셋팅
          mobileBreakpoint: undefined, // type: number|string, default: 600, Grid가 모바일 모드와 일반 모드로 전환되는 넓이 지정
          multiSort: undefined, // type: boolean, default: false, 멀티 정렬 사용 여부 지정
          mustSort: undefined, // type: boolean, default: false, 정렬을 항상 사용할지 여부 지정
          noDataText: undefined, // type: string, default: '$vuetify.noDataText', Row 데이터가 없을 경우 보여질 텍스트
          noResultsText: undefined, // type: string, default: '$vuetify.dataIterator.noResultsText', search 사용한 filtering 결과가 없을 때 보여질 텍스트
          options: undefined, // type: DataOptions, default: {}, Grid에 표출될 데이터와 연관된 페이징, 정렬, 그룹 등의 옵션 정보 ({ page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[], groupBy: string[], groupDesc: boolean[], muitlSort: boolean, mustSort: boolean })
          page: 1, // type: number, default: 1, 현재 표출될 페이지 수
          search: undefined, // type: string, Row 데이터 필터링하기 위한 텍스트 입력
          selectableKey: undefined, // type: string, default: 'isSelectable', 선택가능 여부를 위해 사용될 Row 데이터의 프로퍼티명
          showExpand: undefined, // type: boolean, default: false, 확장 기능을 위한 토글 버튼 활성화
          showGroupBy: undefined, // type: boolean, default: false, Grouping을 위한 토글 버튼 활성화
          showSelect: undefined, // type: boolean, default: false, Row 선택 기능 활성화
          singleExpand: undefined, // type: boolean, default: false, 확장 모드를 단일 확장 모드로 변경
          singleSelect: undefined, // type: boolean, default false, 선택 모드를 단일 선택 모드로 변경
          sortBy: undefined, // type: string|array, 정렬을 위해 사용되어질 프로퍼티명, array일 경우 복수 지정 가능
          sortDesc: undefined, // type: boolean|array, true이면 정렬을 내림차순으로 false이면 오름차순으로, sortBy를 복수로 지정했을 경우, 정렬 프로퍼티 array와 같은 인덱스 번호에 정렬 방향 지정
          
          load: this.demoAPI // grid data 요청 api 호출 , this.getGridData : 함수를 통해 데이터를 처리하여 로드할 경우
        },
        // data-table 이벤트
        // @click:row="onClickRow" Row를 클릭할 때 실행되는 콜백 함수
        // @contextmenu:row="onContextmenuRow" Row를 우클릭할 때 실행되는 콜백 함수
        // @current-items="onCurrentItems" items Props에 변경이 있을 때 실행되는 콜백 함수
        // @dblclick:row="onDbclickRow" Row를 더블클릭할 때 실행되는 콜백 함수
        // @input="dataTableOnInput" 선택된 Rows 변경이 있을 때 실행되는 콜백 함수
        // @item-expanded="onItemExpanded" Row 확장 기능이 토글될 때 실행되는 콜백 함수
        // @item-selected="onItemSelected" Row 선택 체크박스를 토글할 때 실행되는 콜백 함수
        // @page-count="onPageCount" 전체 페이지 수의 변경이 있을 때 실행되는 콜백 함수
        // @pagination="onPagination" 페이징 관련 옵션의 변경이 있을 때 실행되는 콜백 함수
        // @toggle-select-all="onToggleSelectAll" 전체 선택 체크박스를 토글할 때 실행되는 콜백 함수
        // @update:expanded="onUpdateExpanded" expanded prop의 변경이 있을 때 실행되는 콜백 함수
        // @update:group-by="onUpdateGroupBy" options의 groupBy의 변경이 있을 때 실행되는 콜백 함수
        // @update:group-desc="onUpdateGroupDesc" options의 groupDesc의 변경이 있을 때 실행되는 콜백 함수
        // @update:items-per-page="onUpdateItemsPerPage" options의 itemsPerPage의 변경이 있을 때 실행되는 콜백 함수
        // @update:multi-sort="onUpdateMultiSort" options의 multiSort의 변경이 있을 때 실행되는 콜백 함수
        // @update:must-sort="onUpdateMustSort" options의 mustSort의 변경이 있을 때 실행되는 콜백 함수
        // @update:options="onUpdateOptions" options의 변경이 있을 때 실행되는 콜백 함수
        // @update:page="onUpdatePage" options의 page의 변경이 있을 때 실행되는 콜백 함수
        // @update:sort-by="onUpdateSortBy" options의 sortBy의 변경이 있을 때 실행되는 콜백 함수
        // @update:sort-desc="onUpdateSortDesc" options의 sortDesc의 변경이 있을 때 실행되는 콜백 함수

        // v-pagination의 props로 전달될 option
        paginationOptions: {
          circle: undefined, // type: boolean, default: false, 페이징 엘레멘트를 원형으로 - 적용 안됨
          color: undefined,  // type: string, 페이징 엘레멘트 중 액티브 엘레멘트의 색상 지정 - 적용 안됨 
          currentPageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.currentPage', 페이지 수 버튼 중 현재 페이지의 aria-label
          dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
          disabled: undefined, // type: boolean, default: false, 페이징 비활성화
          light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
          nextAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.next', 다음 페이지 버튼의 aria-label
          nextIcon: undefined, // type: string, default: '$next', 다음 페이지 버튼의 아이콘 지정
          pageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.page', 페이지 수 버튼의 aria-label
          prevIcon: undefined, // type: string, default: '$next', 이전 페이지 버튼의 아이콘 지정
          previousAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.previous', 이전 페이지 버튼의 aria-label
          totalVisible: 7, // type: number, 보여질 페이지 수 버튼의 전체 갯수
          value: undefined, // type: number, default: 0, 현재 선택된 페이지 수
          wrapperAriaLabel: undefined, // type: string, default: '$vuetify,pagination.ariaLable.wrapper', wrapper의 aria-label

          perPages: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: 'All', value: -1 } ], // type: array, default: [ { text: '50', value: 50 }, { text: '100', value: 100 }, { text: '150', value: 150 } ],
                              //선택할 수 있는 페이지당 보여질 Row 수, number[]로도 가능 [ 5, 10, 15, -1 ], 단, 전체를 다 보여주는 -1은 그대로 표출됨, 페이징 처리 시 -1은 사용하지 마세요.

          // pagination 이벤트
          input: this.paginationOnInput, // 페이지 변경 이벤트 발생 시 실행되는 콜백 함수
          next: this.onNext, // 다음페이지 버튼 클릭 시 실행되는 콜백 함수
          previous: this.onPrevious // 이전페이지 버튼 클릭 시 실행되는 콜백 함수
        },

        source01: 'HTML',
        source02: 'HTML',
        source03: 'HTML',
        source04: 'HTML',
        source05: 'HTML',
        source06: 'HTML',
        source07: 'HTML',
        source08: 'HTML',
        content:{

source01_tab01: `<data-tables
  ref="dataTable1"
  dataTableClass="" // Grid에 추가할 class, string, array, object 으로 지정가능(:class 사용 방법과 동일)
  :data-table-options="dataTableOptions1"
  // data-table 이벤트
  @click:row="onClickRow" // Row를 클릭할 때 실행되는 콜백 함수
  @contextmenu:row="onContextmenuRow" // Row를 우클릭할 때 실행되는 콜백 함수
  @current-items="onCurrentItems" // items Props에 변경이 있을 때 실행되는 콜백 함수
  @dblclick:row="onDbclickRow" // Row를 더블클릭할 때 실행되는 콜백 함수
  @input="dataTableOnInput" // 선택된 Rows 변경이 있을 때 실행되는 콜백 함수
  @item-expanded="onItemExpanded" // Row 확장 기능이 토글될 때 실행되는 콜백 함수
  @item-selected="onItemSelected" // Row 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @page-count="onPageCount" // 전체 페이지 수의 변경이 있을 때 실행되는 콜백 함수
  @pagination="onPagination" // 페이징 관련 옵션의 변경이 있을 때 실행되는 콜백 함수
  @toggle-select-all="onToggleSelectAll" // 전체 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @update:expanded="onUpdateExpanded" // expanded prop의 변경이 있을 때 실행되는 콜백 함수
  @update:group-by="onUpdateGroupBy" // options의 groupBy의 변경이 있을 때 실행되는 콜백 함수
  @update:group-desc="onUpdateGroupDesc" // options의 groupDesc의 변경이 있을 때 실행되는 콜백 함수
  @update:items-per-page="onUpdateItemsPerPage" // options의 itemsPerPage의 변경이 있을 때 실행되는 콜백 함수
  @update:multi-sort="onUpdateMultiSort" // options의 multiSort의 변경이 있을 때 실행되는 콜백 함수
  @update:must-sort="onUpdateMustSort" // options의 mustSort의 변경이 있을 때 실행되는 콜백 함수
  @update:options="onUpdateOptions" // options의 변경이 있을 때 실행되는 콜백 함수
  @update:page="onUpdatePage" // options의 page의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-by="onUpdateSortBy" // options의 sortBy의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-desc="onUpdateSortDesc" // options의 sortDesc의 변경이 있을 때 실행되는 콜백 함수
  
  paginationClass="" // Pagination에 추가할 class, string, array, function 으로 지정가능(:class 사용 방법과 동일)
  :paginationOptions="paginationOptions"
>
  <template v-slot:top="scopedProps">
    <button type="button" class="jh-btn" @click="addRow">추가</button>
  </template>
</data-tables>`
// `<v-data-table
//   class="jh-grid"
//   :headers="headers"
//   :items="rowNum"
//   fixed-header
//   item-key="index"
//   height="200px"
//   hide-default-footer
//   :page.sync="page"
//   @page-count="pageCount = $event">
// </v-data-table>
// <div class="jh-pager">
//   <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
//   <v-select
//     class="jh-form is-round is-right"
//     :items="perPage"   
//     label="10"
//     style="width: 70px"
//   ></v-select>
//   <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
// </div>`
,source01_tab02: `import dataTables from "@/components/DataTables";

export default {
  components: { 
    dataTables
  },
  computed: {
    dataTable1Instance: function() {
      return this.$refs.dataTable1;
    },
    rowNum() {
      return this.items.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
  },
  data() {
    return {
      headers: [
        { text: '순번', value: 'index', align: 'center', width: '80px' },
        { text: '유형', value: 'type', align: 'center', width: '120px' },
        { text: '내용', value: 'contents'},
        { text: '수정일', value: 'date', align: 'center', width: '120px' },
      ],
      items: [
        {
          type: '공통',
          subject: '취소수수료를 환불해 주세요.',
          contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
          date: '2020-08-05'
        },
        ...
      ],
      dataTableOptions1: null,
      // v-data-table의 props로 전달될 option
      dataTableOptions: {
        calculateWidths: undefined, // type: boolean, default: false, 컬럼 넓이 계산 기능 활성화
        caption: undefined, // type: string, Grid 상단에 셋팅한 문자열로 caption을 표출
        checkboxColor: undefined, // type: string, showSelect가 true 일 때 체크박스의 색을 지정
        customFilter: undefined, // type: function, default: defaultFilter, 커스터마이징된 필터를 셋팅(function(value, search, item) { return boolean })
        customGroup: undefined, // type: function, default: groupItems, 커스터마이징된 그룹을 셋팅(function(items, groupBy, groupDesc) { return Record<string, any[]> })
        customSort: undefined, // type: function, default: sortItems, 커스터마이징된 정렬을 셋팅(function(items, sortBy, sortDesc, locale, customSorters) { return any[] })
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        dense: undefined, // type: boolean, default: false, Row의 높이가 축소된 레이아웃 모드 활성화
        disableFiltering: undefined, // type: boolean, default: false, 필터링 기능 비활성화
        disablePagination: undefined, // type: boolean, default: false, 페이징 기능 비활성화, TotalCount는 표출
        disableSort: undefined, // type: boolean, default: false, 정렬 기능 비활성화
        expandIcon: undefined, // type: string, default: '$expand', 확장기능 버튼에 적용할 커스텀 아이콘 지정
        expanded: undefined, // type: array, default: [], 확장기능을 사용한 확장된 Rows, 초기값 지정으로만 사용
        fixedHeader: true, // type: boolean, default: false, 스크롤 시 Grid 상단에 헤더를 고정하는 기능 활성화
        footerProps: undefined, // type: object, Default footer 사용 시 v-data-footer의 props로 전달될 값 - 사용 안항
        groupBy: undefined, // type: string|array, default: [], Grouping을 위한 컬럼 지정, 배열 사용 시 여러 컬럼 지정 가능
        groupDesc: undefined, // type: boolean|array, default: [], Grouping한 컬럼의 정렬 순서 지정, 여러 컬럼을 Grouping한 경우, 컬럼 순서와 동일하게 배열로 지정, true일 경우 내림차순
        headerProps: undefined, // type: object, Default header 사용 시 v-data-header의 props로 전달될 값
        headers: undefined, // type: DataTableHeader[], default: [], 각각의 헤더 컬럼의 정보({ text: string, value: string, align?: 'start'|'center'|'end', sortable?: boolean, filterable?: boolean, groupable?: boolean, divider?: boolean, class?: string|string[], cellClass?: string|string[], width?: string|number, filter?: (value, search, item) => boolean, sort?: (a, b) => number })
        headersLength: undefined, // type: number, 헤더 컬럼의 수를 지정, hide-default-header와 함께
        height: '200px', // type: number|string, Grid의 높이 지정
        hideDefaultFooter: true, // type: boolean, default: false, Default footer 비활성화
        hideDefaultHeader: undefined, // type: boolean, default: false, Default header 비활성화
        invisiblePagination: undefined, // type: boolean, default: false, 페이징 기능 전체 표출 안함
        itemClass: undefined, // type: string|function, Row에 지정할 class
        itemKey: 'index', // type: string, default: 'id', Row 선택 시 사용하는 유니크한 Column 이름, 헤더정보의 value들 중 하나여야 함
        items: undefined, // type: array, default: [], Grid에 보여질 데이터 세팅, api를 통해 데이터 로드하기 위해 load를 사용할 경우 items는 undefined, 처리된 array data를 바로 보여줄 경우에 사용
        itemsPerPage: 5, // type: number, default: 10, Grid에 보여줄 Row의 수
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        loaderHeight: undefined, // type: number|string, default: 4, loader의 높이 지정
        loading: undefined, // type: boolean|string, default: false, loader 표출 여부 지정
        loadingText: undefined, // type: string, default: '$vuetify.dataIterator.loadingText', loading이 true일 경우 보여질 텍스트 지정
        locale: undefined, // type: string, default: 'ko-KR', 정렬을 위한 국가 코드 셋팅
        mobileBreakpoint: undefined, // type: number|string, default: 600, Grid가 모바일 모드와 일반 모드로 전환되는 넓이 지정
        multiSort: undefined, // type: boolean, default: false, 멀티 정렬 사용 여부 지정
        mustSort: undefined, // type: boolean, default: false, 정렬을 항상 사용할지 여부 지정
        noDataText: undefined, // type: string, default: '$vuetify.noDataText', Row 데이터가 없을 경우 보여질 텍스트
        noResultsText: undefined, // type: string, default: '$vuetify.dataIterator.noResultsText', search 사용한 filtering 결과가 없을 때 보여질 텍스트
        options: undefined, // type: DataOptions, default: {}, Grid에 표출될 데이터와 연관된 페이징, 정렬, 그룹 등의 옵션 정보 ({ page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[], groupBy: string[], groupDesc: boolean[], muitlSort: boolean, mustSort: boolean })
        page: 1, // type: number, default: 1, 현재 표출될 페이지 수
        search: undefined, // type: string, Row 데이터 필터링하기 위한 텍스트 입력
        selectableKey: undefined, // type: string, default: 'isSelectable', 선택가능 여부를 위해 사용될 Row 데이터의 프로퍼티명
        showExpand: undefined, // type: boolean, default: false, 확장 기능을 위한 토글 버튼 활성화
        showGroupBy: undefined, // type: boolean, default: false, Grouping을 위한 토글 버튼 활성화
        showSelect: undefined, // type: boolean, default: false, Row 선택 기능 활성화
        singleExpand: undefined, // type: boolean, default: false, 확장 모드를 단일 확장 모드로 변경
        singleSelect: undefined, // type: boolean, default false, 선택 모드를 단일 선택 모드로 변경
        sortBy: undefined, // type: string|array, 정렬을 위해 사용되어질 프로퍼티명, array일 경우 복수 지정 가능
        sortDesc: undefined, // type: boolean|array, true이면 정렬을 내림차순으로 false이면 오름차순으로, sortBy를 복수로 지정했을 경우, 정렬 프로퍼티 array와 같은 인덱스 번호에 정렬 방향 지정
        
        load: this.demoAPI // grid data 요청 api 호출 , this.getGridData : 함수를 통해 데이터를 처리하여 로드할 경우
      },

      // v-pagination의 props로 전달될 option
      paginationOptions: {
        circle: undefined, // type: boolean, default: false, 페이징 엘레멘트를 원형으로 - 적용 안됨
        color: undefined,  // type: string, 페이징 엘레멘트 중 액티브 엘레멘트의 색상 지정 - 적용 안됨 
        currentPageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.currentPage', 페이지 수 버튼 중 현재 페이지의 aria-label
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        disabled: undefined, // type: boolean, default: false, 페이징 비활성화
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        nextAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.next', 다음 페이지 버튼의 aria-label
        nextIcon: undefined, // type: string, default: '$next', 다음 페이지 버튼의 아이콘 지정
        pageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.page', 페이지 수 버튼의 aria-label
        prevIcon: undefined, // type: string, default: '$next', 이전 페이지 버튼의 아이콘 지정
        previousAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.previous', 이전 페이지 버튼의 aria-label
        totalVisible: 7, // type: number, 보여질 페이지 수 버튼의 전체 갯수
        value: undefined, // type: number, default: 0, 현재 선택된 페이지 수
        wrapperAriaLabel: undefined, // type: string, default: '$vuetify,pagination.ariaLable.wrapper', wrapper의 aria-label

        perPages: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: 'All', value: -1 } ], // type: array, default: [ { text: '50', value: 50 }, { text: '100', value: 100 }, { text: '150', value: 150 } ],
                            //선택할 수 있는 페이지당 보여질 Row 수, number[]로도 가능 [ 5, 10, 15, -1 ], 단, 전체를 다 보여주는 -1은 그대로 표출됨, 페이징 처리 시 -1은 사용하지 마세요.

        // pagination 이벤트
        input: this.paginationOnInput, // 페이지 변경 이벤트 발생 시 실행되는 콜백 함수
        next: this.onNext, // 다음페이지 버튼 클릭 시 실행되는 콜백 함수
        previous: this.onPrevious // 이전페이지 버튼 클릭 시 실행되는 콜백 함수
      }
    }
  },
  methods: {
    onClickRow: function(row, data) {
      console.log("onClickRow", row, data);
    },
    onContextmenuRow: function(e, data) {
      console.log("onContextmenuRow", e, data);
    },
    onCurrentItems: function(items) {
      console.log("onCurrentItems", items);
    },
    onDbclickRow: function(e, row) {
      console.log("onDbclickRow", e, row);
    },
    dataTableOnInput: function(items) {
      console.log("dataTableOnInput", items);
    },
    onItemExpanded: function(data) {
      console.log("onItemExpanded", data);
    },
    onItemSelected: function(data) {
      console.log("onItemSelected", data);
    },
    onPageCount: function(pageCount) {
      console.log("onPageCount", pageCount);
    },
    onPagination: function(pagination) {
      console.log("onPagination", pagination);
    },
    onToggleSelectAll: function(data) {
      console.log("onToggleSelectAll", data);
    },
    onUpdateExpanded: function(expanded) {
      console.log("onUpdateExpanded", expanded);
    },
    onUpdateGroupBy: function(groupBy) {
      console.log("onUpdateGroupBy", groupBy);
    },
    onUpdateGroupDesc: function(groupDesc) {
      console.log("onUpdateGroupDesc", groupDesc);
    },
    onUpdateItemsPerPage: function(itemsPerPage) {
      console.log("onUpdateItemsPerPage", itemsPerPage);
    },
    onUpdateMultiSort: function(multiSort) {
      console.log("onUpdateMultiSort", multiSort);
    },
    onUpdateMustSort: function(mustSort) {
      console.log("onUpdateMustSort", mustSort);
    },
    onUpdateOptions: function(options) {
      console.log("onUpdateOptions", options);
    },
    onUpdatePage: function(page) {
      console.log("onUpdatePage", page);
    },
    onUpdateSortBy: function(sortBy) {
      console.log("onUpdateSortBy", sortBy);
    },
    onUpdateSortDesc: function(sortDesc) {
      console.log("onUpdateSortDesc", sortDesc);
    },
    
    paginationOnInput: function(page) {
      console.log("paginationOnInput", page);
    },
    onNext: function() {
      console.log("onNext");
    },
    onPrevious: function() {
      console.log("onPrevious");
    },
    // 예시로 API Call처럼 동작하도록 만든 함수, 실제 API Call과 다를 수 있음, 참고만
    demoAPI: function(loadOptions) {
      console.log(loadOptions);
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = loadOptions;

        let data = this.rowNum;
        const totalCount = data.length;

        if (sortBy.length == 1 && sortDesc.length == 1) {
          data = data.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              else 0;
            }
            else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              else 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          data = data.slice((page-1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            data,
            totalCount
          })
        }, 1000);
      });
    },
    getGridData: function() { return { data: this.rowNum, totalCount: this.rowNum.length } },

    addRow: function() {
      console.log(this.dataTable1Instance.getData()); // 현재 Grid의 데이터 가져오기
      let totalCount = this.dataTable1Instance.getTotalCount(); // 현재 Grid의 전체 데이터 수 가져오기
      let row = {
        index: ++totalCount,
        type: '개인',
        subject: '카드 등록이 안되요.',
        contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
        date: '2020-08-05'
      };
      this.dataTable1Instance.addRow(row); // row 추가
    }
  },
  created() {
    this.dataTableOptions1 = Object.assign({}, this.dataTableOptions, { headers: this.headers });
  }
}`
// `export default {
//   data () {
//     return {  
//       page: 1,
//       pageCount: 0,
//       itemsPerPage: 10,
//       headers: [
//         { text: '순번', value: 'index', align: 'center', width: '80px' },
//         { text: '유형', value: 'type', align: 'center', width: '120px' },
//         { text: '스크립트명', value: 'subject'},
//         { text: '수정일', value: 'date', align: 'center', width: '120px' },
//       ],
//       items: [
//           {
//             type: '공통',
//             subject: '취소수수료를 환불해 주세요.',
//             contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
//             date: '2020-08-05'
//           },
//           ...
//       ],
//     }
//   },  
//   computed: {
//     rowNum() {
//       return this.items.map(
//         (items, index) => ({
//         ...items,
//         index: index + 1
//       }))
//     },
//   },
// }`
,source02_tab01: `<!-- dataTableClass="has-control"로 바꿔줘야 함-->
<data-tables
  ref="dataTable4"
  dataTableClass="has-control" // Grid에 추가할 class, string, array, object 으로 지정가능(:class 사용 방법과 동일)
  :data-table-options="dataTableOptions3"
  v-model="selected" // type: array, default: [], 선택된 Rows 컨트롤하기 위해 사용, 선택된 Row 데이터가 필요할 경우
  // data-table 이벤트
  @click:row="onClickRow" // Row를 클릭할 때 실행되는 콜백 함수
  @contextmenu:row="onContextmenuRow" // Row를 우클릭할 때 실행되는 콜백 함수
  @current-items="onCurrentItems" // items Props에 변경이 있을 때 실행되는 콜백 함수
  @dblclick:row="onDbclickRow" // Row를 더블클릭할 때 실행되는 콜백 함수
  @input="dataTableOnInput" // 선택된 Rows 변경이 있을 때 실행되는 콜백 함수
  @item-expanded="onItemExpanded" // Row 확장 기능이 토글될 때 실행되는 콜백 함수
  @item-selected="onItemSelected" // Row 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @page-count="onPageCount" // 전체 페이지 수의 변경이 있을 때 실행되는 콜백 함수
  @pagination="onPagination" // 페이징 관련 옵션의 변경이 있을 때 실행되는 콜백 함수
  @toggle-select-all="onToggleSelectAll" // 전체 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @update:expanded="onUpdateExpanded" // expanded prop의 변경이 있을 때 실행되는 콜백 함수
  @update:group-by="onUpdateGroupBy" // options의 groupBy의 변경이 있을 때 실행되는 콜백 함수
  @update:group-desc="onUpdateGroupDesc" // options의 groupDesc의 변경이 있을 때 실행되는 콜백 함수
  @update:items-per-page="onUpdateItemsPerPage" // options의 itemsPerPage의 변경이 있을 때 실행되는 콜백 함수
  @update:multi-sort="onUpdateMultiSort" // options의 multiSort의 변경이 있을 때 실행되는 콜백 함수
  @update:must-sort="onUpdateMustSort" // options의 mustSort의 변경이 있을 때 실행되는 콜백 함수
  @update:options="onUpdateOptions" // options의 변경이 있을 때 실행되는 콜백 함수
  @update:page="onUpdatePage" // options의 page의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-by="onUpdateSortBy" // options의 sortBy의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-desc="onUpdateSortDesc" // options의 sortDesc의 변경이 있을 때 실행되는 콜백 함수
  
  paginationClass="" // Pagination에 추가할 class, string, array, function 으로 지정가능(:class 사용 방법과 동일)
  :paginationOptions="paginationOptions"
/>`
// `<!--class="jh-grid has-control"로 바꿔줘야 함-->
// <v-data-table
//   class="jh-grid has-control"
//   :headers="headers"
//   :items="rowNum"
//   fixed-header
//   item-key="index"
//   show-select
//   height="200px"
//   hide-default-footer
//   :page.sync="page"
//   @page-count="pageCount = $event">
// </v-data-table>
// <div class="jh-pager">
//   <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
//   <v-select
//     class="jh-form is-round is-right"
//     :items="perPage"   
//     label="10"
//     style="width: 70px"
//   ></v-select>
//   <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
// </div>`
,source02_tab02: `import dataTables from "@/components/DataTables";

export default {
  components: { 
    dataTables
  },
  computed: {
    dataTable4Instance: function() {
      return this.$refs.dataTable4;
    },
    rowNum() {
      return this.items.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
  },
  data() {
    return {
      headers: [
        { text: '순번', value: 'index', align: 'center', width: '80px' },
        { text: '유형', value: 'type', align: 'center', width: '120px' },
        { text: '내용', value: 'contents'},
        { text: '수정일', value: 'date', align: 'center', width: '120px' },
      ],
      items: [
        {
          type: '공통',
          subject: '취소수수료를 환불해 주세요.',
          contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
          date: '2020-08-05'
        },
        ...
      ],

      selected: [],

      dataTableOptions3: null,
      // v-data-table의 props로 전달될 option
      dataTableOptions: {
        calculateWidths: undefined, // type: boolean, default: false, 컬럼 넓이 계산 기능 활성화
        caption: undefined, // type: string, Grid 상단에 셋팅한 문자열로 caption을 표출
        checkboxColor: undefined, // type: string, showSelect가 true 일 때 체크박스의 색을 지정
        customFilter: undefined, // type: function, default: defaultFilter, 커스터마이징된 필터를 셋팅(function(value, search, item) { return boolean })
        customGroup: undefined, // type: function, default: groupItems, 커스터마이징된 그룹을 셋팅(function(items, groupBy, groupDesc) { return Record<string, any[]> })
        customSort: undefined, // type: function, default: sortItems, 커스터마이징된 정렬을 셋팅(function(items, sortBy, sortDesc, locale, customSorters) { return any[] })
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        dense: undefined, // type: boolean, default: false, Row의 높이가 축소된 레이아웃 모드 활성화
        disableFiltering: undefined, // type: boolean, default: false, 필터링 기능 비활성화
        disablePagination: undefined, // type: boolean, default: false, 페이징 기능 비활성화, TotalCount는 표출
        disableSort: undefined, // type: boolean, default: false, 정렬 기능 비활성화
        expandIcon: undefined, // type: string, default: '$expand', 확장기능 버튼에 적용할 커스텀 아이콘 지정
        expanded: undefined, // type: array, default: [], 확장기능을 사용한 확장된 Rows, 초기값 지정으로만 사용
        fixedHeader: true, // type: boolean, default: false, 스크롤 시 Grid 상단에 헤더를 고정하는 기능 활성화
        footerProps: undefined, // type: object, Default footer 사용 시 v-data-footer의 props로 전달될 값 - 사용 안항
        groupBy: undefined, // type: string|array, default: [], Grouping을 위한 컬럼 지정, 배열 사용 시 여러 컬럼 지정 가능
        groupDesc: undefined, // type: boolean|array, default: [], Grouping한 컬럼의 정렬 순서 지정, 여러 컬럼을 Grouping한 경우, 컬럼 순서와 동일하게 배열로 지정, true일 경우 내림차순
        headerProps: undefined, // type: object, Default header 사용 시 v-data-header의 props로 전달될 값
        headers: undefined, // type: DataTableHeader[], default: [], 각각의 헤더 컬럼의 정보({ text: string, value: string, align?: 'start'|'center'|'end', sortable?: boolean, filterable?: boolean, groupable?: boolean, divider?: boolean, class?: string|string[], cellClass?: string|string[], width?: string|number, filter?: (value, search, item) => boolean, sort?: (a, b) => number })
        headersLength: undefined, // type: number, 헤더 컬럼의 수를 지정, hide-default-header와 함께
        height: '200px', // type: number|string, Grid의 높이 지정
        hideDefaultFooter: true, // type: boolean, default: false, Default footer 비활성화
        hideDefaultHeader: undefined, // type: boolean, default: false, Default header 비활성화
        invisiblePagination: undefined, // type: boolean, default: false, 페이징 기능 전체 표출 안함
        itemClass: undefined, // type: string|function, Row에 지정할 class
        itemKey: 'index', // type: string, default: 'id', Row 선택 시 사용하는 유니크한 Column 이름, 헤더정보의 value들 중 하나여야 함
        items: undefined, // type: array, default: [], Grid에 보여질 데이터 세팅, api를 통해 데이터 로드하기 위해 load를 사용할 경우 items는 undefined, 처리된 array data를 바로 보여줄 경우에 사용
        itemsPerPage: 5, // type: number, default: 10, Grid에 보여줄 Row의 수
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        loaderHeight: undefined, // type: number|string, default: 4, loader의 높이 지정
        loading: undefined, // type: boolean|string, default: false, loader 표출 여부 지정
        loadingText: undefined, // type: string, default: '$vuetify.dataIterator.loadingText', loading이 true일 경우 보여질 텍스트 지정
        locale: undefined, // type: string, default: 'ko-KR', 정렬을 위한 국가 코드 셋팅
        mobileBreakpoint: undefined, // type: number|string, default: 600, Grid가 모바일 모드와 일반 모드로 전환되는 넓이 지정
        multiSort: undefined, // type: boolean, default: false, 멀티 정렬 사용 여부 지정
        mustSort: undefined, // type: boolean, default: false, 정렬을 항상 사용할지 여부 지정
        noDataText: undefined, // type: string, default: '$vuetify.noDataText', Row 데이터가 없을 경우 보여질 텍스트
        noResultsText: undefined, // type: string, default: '$vuetify.dataIterator.noResultsText', search 사용한 filtering 결과가 없을 때 보여질 텍스트
        options: undefined, // type: DataOptions, default: {}, Grid에 표출될 데이터와 연관된 페이징, 정렬, 그룹 등의 옵션 정보 ({ page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[], groupBy: string[], groupDesc: boolean[], muitlSort: boolean, mustSort: boolean })
        page: 1, // type: number, default: 1, 현재 표출될 페이지 수
        search: undefined, // type: string, Row 데이터 필터링하기 위한 텍스트 입력
        selectableKey: undefined, // type: string, default: 'isSelectable', 선택가능 여부를 위해 사용될 Row 데이터의 프로퍼티명
        showExpand: undefined, // type: boolean, default: false, 확장 기능을 위한 토글 버튼 활성화
        showGroupBy: undefined, // type: boolean, default: false, Grouping을 위한 토글 버튼 활성화
        showSelect: undefined, // type: boolean, default: false, Row 선택 기능 활성화
        singleExpand: undefined, // type: boolean, default: false, 확장 모드를 단일 확장 모드로 변경
        singleSelect: undefined, // type: boolean, default false, 선택 모드를 단일 선택 모드로 변경
        sortBy: undefined, // type: string|array, 정렬을 위해 사용되어질 프로퍼티명, array일 경우 복수 지정 가능
        sortDesc: undefined, // type: boolean|array, true이면 정렬을 내림차순으로 false이면 오름차순으로, sortBy를 복수로 지정했을 경우, 정렬 프로퍼티 array와 같은 인덱스 번호에 정렬 방향 지정
        
        load: this.demoAPI // grid data 요청 api 호출 , this.getGridData : 함수를 통해 데이터를 처리하여 로드할 경우
      },

      // v-pagination의 props로 전달될 option
      paginationOptions: {
        circle: undefined, // type: boolean, default: false, 페이징 엘레멘트를 원형으로 - 적용 안됨
        color: undefined,  // type: string, 페이징 엘레멘트 중 액티브 엘레멘트의 색상 지정 - 적용 안됨 
        currentPageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.currentPage', 페이지 수 버튼 중 현재 페이지의 aria-label
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        disabled: undefined, // type: boolean, default: false, 페이징 비활성화
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        nextAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.next', 다음 페이지 버튼의 aria-label
        nextIcon: undefined, // type: string, default: '$next', 다음 페이지 버튼의 아이콘 지정
        pageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.page', 페이지 수 버튼의 aria-label
        prevIcon: undefined, // type: string, default: '$next', 이전 페이지 버튼의 아이콘 지정
        previousAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.previous', 이전 페이지 버튼의 aria-label
        totalVisible: 7, // type: number, 보여질 페이지 수 버튼의 전체 갯수
        value: undefined, // type: number, default: 0, 현재 선택된 페이지 수
        wrapperAriaLabel: undefined, // type: string, default: '$vuetify,pagination.ariaLable.wrapper', wrapper의 aria-label

        perPages: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: 'All', value: -1 } ], // type: array, default: [ { text: '50', value: 50 }, { text: '100', value: 100 }, { text: '150', value: 150 } ],
                            //선택할 수 있는 페이지당 보여질 Row 수, number[]로도 가능 [ 5, 10, 15, -1 ], 단, 전체를 다 보여주는 -1은 그대로 표출됨, 페이징 처리 시 -1은 사용하지 마세요.

        // pagination 이벤트
        input: this.paginationOnInput, // 페이지 변경 이벤트 발생 시 실행되는 콜백 함수
        next: this.onNext, // 다음페이지 버튼 클릭 시 실행되는 콜백 함수
        previous: this.onPrevious // 이전페이지 버튼 클릭 시 실행되는 콜백 함수
      }
    }
  },
  methods: {
    onClickRow: function(row, data) {
      console.log("onClickRow", row, data);
    },
    onContextmenuRow: function(e, data) {
      console.log("onContextmenuRow", e, data);
    },
    onCurrentItems: function(items) {
      console.log("onCurrentItems", items);
    },
    onDbclickRow: function(e, row) {
      console.log("onDbclickRow", e, row);
    },
    dataTableOnInput: function(items) {
      console.log("dataTableOnInput", items);
      this.$nextTick(() => {
        console.log(this.dataTable4Instance.getSelectedData()); // 선택한 데이터 가져오기
      });
    },
    onItemExpanded: function(data) {
      console.log("onItemExpanded", data);
    },
    onItemSelected: function(data) {
      console.log("onItemSelected", data);
    },
    onPageCount: function(pageCount) {
      console.log("onPageCount", pageCount);
    },
    onPagination: function(pagination) {
      console.log("onPagination", pagination);
    },
    onToggleSelectAll: function(data) {
      console.log("onToggleSelectAll", data);
    },
    onUpdateExpanded: function(expanded) {
      console.log("onUpdateExpanded", expanded);
    },
    onUpdateGroupBy: function(groupBy) {
      console.log("onUpdateGroupBy", groupBy);
    },
    onUpdateGroupDesc: function(groupDesc) {
      console.log("onUpdateGroupDesc", groupDesc);
    },
    onUpdateItemsPerPage: function(itemsPerPage) {
      console.log("onUpdateItemsPerPage", itemsPerPage);
    },
    onUpdateMultiSort: function(multiSort) {
      console.log("onUpdateMultiSort", multiSort);
    },
    onUpdateMustSort: function(mustSort) {
      console.log("onUpdateMustSort", mustSort);
    },
    onUpdateOptions: function(options) {
      console.log("onUpdateOptions", options);
    },
    onUpdatePage: function(page) {
      console.log("onUpdatePage", page);
    },
    onUpdateSortBy: function(sortBy) {
      console.log("onUpdateSortBy", sortBy);
    },
    onUpdateSortDesc: function(sortDesc) {
      console.log("onUpdateSortDesc", sortDesc);
    },
    
    paginationOnInput: function(page) {
      console.log("paginationOnInput", page);
    },
    onNext: function() {
      console.log("onNext");
    },
    onPrevious: function() {
      console.log("onPrevious");
    },
    // 예시로 API Call처럼 동작하도록 만든 함수, 실제 API Call과 다를 수 있음, 참고만
    demoAPI: function(loadOptions) {
      console.log(loadOptions);
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = loadOptions;

        let data = this.rowNum;
        const totalCount = data.length;

        if (sortBy.length == 1 && sortDesc.length == 1) {
          data = data.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              else 0;
            }
            else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              else 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          data = data.slice((page-1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            data,
            totalCount
          })
        }, 1000);
      });
    },
    getGridData: function() { return { data: this.rowNum, totalCount: this.rowNum.length } }
  },
  created() {
    this.dataTableOptions3 = Object.assign({}, this.dataTableOptions, { headers: this.headers, showSelect: true });
  }
}`
// `export default {
//   data () {
//     return {  
//       page: 1,
//       pageCount: 0,
//       itemsPerPage: 10,
//       headers: [
//         { text: '순번', value: 'index', align: 'center', width: '50px' },
//         { text: '유형', value: 'type', align: 'center', width: '100px' },
//         { text: '스크립트명', value: 'subject'},
//         { text: '수정일', value: 'date', align: 'center', width: '100px' },
//       ],
//       items: [
//           {
//             type: '공통',
//             subject: '취소수수료를 환불해 주세요.',
//             contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
//             date: '2020-08-05'
//           },
//           ...
//       ],
//     }
//   },  
//   computed: {
//     rowNum() {
//       return this.items.map(
//         (items, index) => ({
//         ...items,
//         index: index + 1
//       }))
//     },
//   },
// }`
,source03_tab01: `<data-tables
  ref="dataTable5"
  dataTableClass="" // Grid에 추가할 class, string, array, object 으로 지정가능(:class 사용 방법과 동일)
  :data-table-options="dataTableOptions4"
  // data-table 이벤트
  @click:row="onClickRow" // Row를 클릭할 때 실행되는 콜백 함수
  @contextmenu:row="onContextmenuRow" // Row를 우클릭할 때 실행되는 콜백 함수
  @current-items="onCurrentItems" // items Props에 변경이 있을 때 실행되는 콜백 함수
  @dblclick:row="onDbclickRow" // Row를 더블클릭할 때 실행되는 콜백 함수
  @input="dataTableOnInput" // 선택된 Rows 변경이 있을 때 실행되는 콜백 함수
  @item-expanded="onItemExpanded" // Row 확장 기능이 토글될 때 실행되는 콜백 함수
  @item-selected="onItemSelected" // Row 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @page-count="onPageCount" // 전체 페이지 수의 변경이 있을 때 실행되는 콜백 함수
  @pagination="onPagination" // 페이징 관련 옵션의 변경이 있을 때 실행되는 콜백 함수
  @toggle-select-all="onToggleSelectAll" // 전체 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @update:expanded="onUpdateExpanded" // expanded prop의 변경이 있을 때 실행되는 콜백 함수
  @update:group-by="onUpdateGroupBy" // options의 groupBy의 변경이 있을 때 실행되는 콜백 함수
  @update:group-desc="onUpdateGroupDesc" // options의 groupDesc의 변경이 있을 때 실행되는 콜백 함수
  @update:items-per-page="onUpdateItemsPerPage" // options의 itemsPerPage의 변경이 있을 때 실행되는 콜백 함수
  @update:multi-sort="onUpdateMultiSort" // options의 multiSort의 변경이 있을 때 실행되는 콜백 함수
  @update:must-sort="onUpdateMustSort" // options의 mustSort의 변경이 있을 때 실행되는 콜백 함수
  @update:options="onUpdateOptions" // options의 변경이 있을 때 실행되는 콜백 함수
  @update:page="onUpdatePage" // options의 page의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-by="onUpdateSortBy" // options의 sortBy의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-desc="onUpdateSortDesc" // options의 sortDesc의 변경이 있을 때 실행되는 콜백 함수
  
  paginationClass="" // Pagination에 추가할 class, string, array, function 으로 지정가능(:class 사용 방법과 동일)
  :paginationOptions="paginationOptions"
>
  <template v-slot:item.subject="{ item }"><div class="is-ellips" :title="item.subject">{{ item.subject }}</div></template>
  <template v-slot:item.type="{ item }">
    <v-chip :color="getColor(item.type)" dark x-small>{{ item.type }}</v-chip>
  </template>
  <template v-slot:item.view>
    <button type="button" class="jh-btn is-icon"><i class="jh-icon-comment"></i></button>
  </template>
  <template v-slot:item.edit="{ item, index }">
    <button type="button" class="jh-btn is-icon" @click="updateRow(index, item)"><i class="jh-icon-edit"></i></button>
  </template>
  <template v-slot:item.delete="{ index }">
    <button type="button" class="jh-btn is-icon" @click="deleteRow(index)"><i class="jh-icon-trash"></i></button>
  </template>
</data-tables>`
// `<v-data-table
//   class="jh-grid"
//   :headers="headers"
//   :items="rowNum"
//   fixed-header
//   item-key="index"
//   height="200px"
//   hide-default-footer
//   :page.sync="page"
//   @page-count="pageCount = $event">
//   <template v-slot:item.subject="{ item }"><div class="is-ellips" :title="item.subject">{{ item.subject }}</div></template>
//   <template v-slot:item.type="{ item }">
//     <v-chip :color="getColor(item.type)" dark x-small>{{ item.type }}</v-chip>
//   </template>
//   <template v-slot:item.view>
//     <button type="button" class="jh-btn is-icon"><i class="jh-icon-comment"></i></button>
//   </template>
//   <template v-slot:item.edit>
//     <button type="button" class="jh-btn is-icon"><i class="jh-icon-edit"></i></button>
//   </template>
//   <template v-slot:item.delete>
//     <button type="button" class="jh-btn is-icon"><i class="jh-icon-trash"></i></button>
//   </template>
// </v-data-table>
// <div class="jh-pager">
//   <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
//   <v-select
//     class="jh-form is-round is-right"
//     :items="perPage"   
//     label="10"
//     style="width: 70px"
//   ></v-select>
//   <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
// </div>`
,source03_tab02: `import dataTables from "@/components/DataTables";

export default {
  components: { 
    dataTables
  },
  computed: {
    dataTable5Instance: function() {
      return this.$refs.dataTable5;
    },
    rowNum() {
      return this.items.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
  },
  data() {
    return {
      headers2: [
        { text: '순번', value: 'index', align: 'center', width: '50px' },
        { text: '유형', value: 'type', align: 'center', width: '60px', sortable: false },
        { text: '내용', value: 'view', align: 'center', width: '40px', sortable: false },
        { text: '스크립트명', value: 'subject'},
        { text: '수정', value: 'edit', align: 'center', width: '40px', sortable: false },
        { text: '삭제', value: 'delete', align: 'center', width: '40px', sortable: false },
      ],
      items: [
        {
          type: '공통',
          subject: '취소수수료를 환불해 주세요.',
          contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
          date: '2020-08-05'
        },
        ...
      ],
      dataTableOptions4: null,
      // v-data-table의 props로 전달될 option
      dataTableOptions: {
        calculateWidths: undefined, // type: boolean, default: false, 컬럼 넓이 계산 기능 활성화
        caption: undefined, // type: string, Grid 상단에 셋팅한 문자열로 caption을 표출
        checkboxColor: undefined, // type: string, showSelect가 true 일 때 체크박스의 색을 지정
        customFilter: undefined, // type: function, default: defaultFilter, 커스터마이징된 필터를 셋팅(function(value, search, item) { return boolean })
        customGroup: undefined, // type: function, default: groupItems, 커스터마이징된 그룹을 셋팅(function(items, groupBy, groupDesc) { return Record<string, any[]> })
        customSort: undefined, // type: function, default: sortItems, 커스터마이징된 정렬을 셋팅(function(items, sortBy, sortDesc, locale, customSorters) { return any[] })
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        dense: undefined, // type: boolean, default: false, Row의 높이가 축소된 레이아웃 모드 활성화
        disableFiltering: undefined, // type: boolean, default: false, 필터링 기능 비활성화
        disablePagination: undefined, // type: boolean, default: false, 페이징 기능 비활성화, TotalCount는 표출
        disableSort: undefined, // type: boolean, default: false, 정렬 기능 비활성화
        expandIcon: undefined, // type: string, default: '$expand', 확장기능 버튼에 적용할 커스텀 아이콘 지정
        expanded: undefined, // type: array, default: [], 확장기능을 사용한 확장된 Rows, 초기값 지정으로만 사용
        fixedHeader: true, // type: boolean, default: false, 스크롤 시 Grid 상단에 헤더를 고정하는 기능 활성화
        footerProps: undefined, // type: object, Default footer 사용 시 v-data-footer의 props로 전달될 값 - 사용 안항
        groupBy: undefined, // type: string|array, default: [], Grouping을 위한 컬럼 지정, 배열 사용 시 여러 컬럼 지정 가능
        groupDesc: undefined, // type: boolean|array, default: [], Grouping한 컬럼의 정렬 순서 지정, 여러 컬럼을 Grouping한 경우, 컬럼 순서와 동일하게 배열로 지정, true일 경우 내림차순
        headerProps: undefined, // type: object, Default header 사용 시 v-data-header의 props로 전달될 값
        headers: undefined, // type: DataTableHeader[], default: [], 각각의 헤더 컬럼의 정보({ text: string, value: string, align?: 'start'|'center'|'end', sortable?: boolean, filterable?: boolean, groupable?: boolean, divider?: boolean, class?: string|string[], cellClass?: string|string[], width?: string|number, filter?: (value, search, item) => boolean, sort?: (a, b) => number })
        headersLength: undefined, // type: number, 헤더 컬럼의 수를 지정, hide-default-header와 함께
        height: '200px', // type: number|string, Grid의 높이 지정
        hideDefaultFooter: true, // type: boolean, default: false, Default footer 비활성화
        hideDefaultHeader: undefined, // type: boolean, default: false, Default header 비활성화
        invisiblePagination: undefined, // type: boolean, default: false, 페이징 기능 전체 표출 안함
        itemClass: undefined, // type: string|function, Row에 지정할 class
        itemKey: 'index', // type: string, default: 'id', Row 선택 시 사용하는 유니크한 Column 이름, 헤더정보의 value들 중 하나여야 함
        items: undefined, // type: array, default: [], Grid에 보여질 데이터 세팅, api를 통해 데이터 로드하기 위해 load를 사용할 경우 items는 undefined, 처리된 array data를 바로 보여줄 경우에 사용
        itemsPerPage: 5, // type: number, default: 10, Grid에 보여줄 Row의 수
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        loaderHeight: undefined, // type: number|string, default: 4, loader의 높이 지정
        loading: undefined, // type: boolean|string, default: false, loader 표출 여부 지정
        loadingText: undefined, // type: string, default: '$vuetify.dataIterator.loadingText', loading이 true일 경우 보여질 텍스트 지정
        locale: undefined, // type: string, default: 'ko-KR', 정렬을 위한 국가 코드 셋팅
        mobileBreakpoint: undefined, // type: number|string, default: 600, Grid가 모바일 모드와 일반 모드로 전환되는 넓이 지정
        multiSort: undefined, // type: boolean, default: false, 멀티 정렬 사용 여부 지정
        mustSort: undefined, // type: boolean, default: false, 정렬을 항상 사용할지 여부 지정
        noDataText: undefined, // type: string, default: '$vuetify.noDataText', Row 데이터가 없을 경우 보여질 텍스트
        noResultsText: undefined, // type: string, default: '$vuetify.dataIterator.noResultsText', search 사용한 filtering 결과가 없을 때 보여질 텍스트
        options: undefined, // type: DataOptions, default: {}, Grid에 표출될 데이터와 연관된 페이징, 정렬, 그룹 등의 옵션 정보 ({ page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[], groupBy: string[], groupDesc: boolean[], muitlSort: boolean, mustSort: boolean })
        page: 1, // type: number, default: 1, 현재 표출될 페이지 수
        search: undefined, // type: string, Row 데이터 필터링하기 위한 텍스트 입력
        selectableKey: undefined, // type: string, default: 'isSelectable', 선택가능 여부를 위해 사용될 Row 데이터의 프로퍼티명
        showExpand: undefined, // type: boolean, default: false, 확장 기능을 위한 토글 버튼 활성화
        showGroupBy: undefined, // type: boolean, default: false, Grouping을 위한 토글 버튼 활성화
        showSelect: undefined, // type: boolean, default: false, Row 선택 기능 활성화
        singleExpand: undefined, // type: boolean, default: false, 확장 모드를 단일 확장 모드로 변경
        singleSelect: undefined, // type: boolean, default false, 선택 모드를 단일 선택 모드로 변경
        sortBy: undefined, // type: string|array, 정렬을 위해 사용되어질 프로퍼티명, array일 경우 복수 지정 가능
        sortDesc: undefined, // type: boolean|array, true이면 정렬을 내림차순으로 false이면 오름차순으로, sortBy를 복수로 지정했을 경우, 정렬 프로퍼티 array와 같은 인덱스 번호에 정렬 방향 지정
        
        load: this.demoAPI // grid data 요청 api 호출 , this.getGridData : 함수를 통해 데이터를 처리하여 로드할 경우
      },

      // v-pagination의 props로 전달될 option
      paginationOptions: {
        circle: undefined, // type: boolean, default: false, 페이징 엘레멘트를 원형으로 - 적용 안됨
        color: undefined,  // type: string, 페이징 엘레멘트 중 액티브 엘레멘트의 색상 지정 - 적용 안됨 
        currentPageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.currentPage', 페이지 수 버튼 중 현재 페이지의 aria-label
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        disabled: undefined, // type: boolean, default: false, 페이징 비활성화
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        nextAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.next', 다음 페이지 버튼의 aria-label
        nextIcon: undefined, // type: string, default: '$next', 다음 페이지 버튼의 아이콘 지정
        pageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.page', 페이지 수 버튼의 aria-label
        prevIcon: undefined, // type: string, default: '$next', 이전 페이지 버튼의 아이콘 지정
        previousAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.previous', 이전 페이지 버튼의 aria-label
        totalVisible: 7, // type: number, 보여질 페이지 수 버튼의 전체 갯수
        value: undefined, // type: number, default: 0, 현재 선택된 페이지 수
        wrapperAriaLabel: undefined, // type: string, default: '$vuetify,pagination.ariaLable.wrapper', wrapper의 aria-label

        perPages: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: 'All', value: -1 } ], // type: array, default: [ { text: '50', value: 50 }, { text: '100', value: 100 }, { text: '150', value: 150 } ],
                            //선택할 수 있는 페이지당 보여질 Row 수, number[]로도 가능 [ 5, 10, 15, -1 ], 단, 전체를 다 보여주는 -1은 그대로 표출됨, 페이징 처리 시 -1은 사용하지 마세요.

        // pagination 이벤트
        input: this.paginationOnInput, // 페이지 변경 이벤트 발생 시 실행되는 콜백 함수
        next: this.onNext, // 다음페이지 버튼 클릭 시 실행되는 콜백 함수
        previous: this.onPrevious // 이전페이지 버튼 클릭 시 실행되는 콜백 함수
      }
    }
  },
  methods: {
    onClickRow: function(row, data) {
      console.log("onClickRow", row, data);
    },
    onContextmenuRow: function(e, data) {
      console.log("onContextmenuRow", e, data);
    },
    onCurrentItems: function(items) {
      console.log("onCurrentItems", items);
    },
    onDbclickRow: function(e, row) {
      console.log("onDbclickRow", e, row);
    },
    dataTableOnInput: function(items) {
      console.log("dataTableOnInput", items);
    },
    onItemExpanded: function(data) {
      console.log("onItemExpanded", data);
    },
    onItemSelected: function(data) {
      console.log("onItemSelected", data);
    },
    onPageCount: function(pageCount) {
      console.log("onPageCount", pageCount);
    },
    onPagination: function(pagination) {
      console.log("onPagination", pagination);
    },
    onToggleSelectAll: function(data) {
      console.log("onToggleSelectAll", data);
    },
    onUpdateExpanded: function(expanded) {
      console.log("onUpdateExpanded", expanded);
    },
    onUpdateGroupBy: function(groupBy) {
      console.log("onUpdateGroupBy", groupBy);
    },
    onUpdateGroupDesc: function(groupDesc) {
      console.log("onUpdateGroupDesc", groupDesc);
    },
    onUpdateItemsPerPage: function(itemsPerPage) {
      console.log("onUpdateItemsPerPage", itemsPerPage);
    },
    onUpdateMultiSort: function(multiSort) {
      console.log("onUpdateMultiSort", multiSort);
    },
    onUpdateMustSort: function(mustSort) {
      console.log("onUpdateMustSort", mustSort);
    },
    onUpdateOptions: function(options) {
      console.log("onUpdateOptions", options);
    },
    onUpdatePage: function(page) {
      console.log("onUpdatePage", page);
    },
    onUpdateSortBy: function(sortBy) {
      console.log("onUpdateSortBy", sortBy);
    },
    onUpdateSortDesc: function(sortDesc) {
      console.log("onUpdateSortDesc", sortDesc);
    },
    
    paginationOnInput: function(page) {
      console.log("paginationOnInput", page);
    },
    onNext: function() {
      console.log("onNext");
    },
    onPrevious: function() {
      console.log("onPrevious");
    },
    // 예시로 API Call처럼 동작하도록 만든 함수, 실제 API Call과 다를 수 있음, 참고만
    demoAPI: function(loadOptions) {
      console.log(loadOptions);
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = loadOptions;

        let data = this.rowNum;
        const totalCount = data.length;

        if (sortBy.length == 1 && sortDesc.length == 1) {
          data = data.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              else 0;
            }
            else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              else 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          data = data.slice((page-1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            data,
            totalCount
          })
        }, 1000);
      });
    },
    getGridData: function() { return { data: this.rowNum, totalCount: this.rowNum.length } },

    getColor (type) {
      if (type === "공통") return 'light-blue'
      else return 'orange'
    },

    updateRow: function(idx, row) {
      this.dataTable5Instance.updateRow(idx, "subject", "[수정됨]" + row.subject); // row 업데이트
    },
    deleteRow: function(idx) {
      this.dataTable5Instance.deleteRow(idx); // row 삭제
    }
  },
  created() {
    this.dataTableOptions4 = Object.assign({}, this.dataTableOptions, { headers: this.headers2 });
  }
}`
// `export default {
//   data () {
//     return {  
//       page: 1,
//       pageCount: 0,
//       headers: [
//         { text: '순번', value: 'index', align: 'center', width: '50px' },
//         { text: '유형', value: 'type', align: 'center', width: '60px', sortable: false },
//         { text: '내용', value: 'view', align: 'center', width: '40px', sortable: false },
//         { text: '스크립트명', value: 'subject'},
//         { text: '수정', value: 'edit', align: 'center', width: '40px', sortable: false },
//         { text: '삭제', value: 'delete', align: 'center', width: '40px', sortable: false },
//       ],
//       items: [
//           {
//             type: '공통',
//             subject: '취소수수료를 환불해 주세요.',
//             contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
//             date: '2020-08-05'
//           },
//           ...
//       ],
//     }
//   },  
//   methods: {
//     getColor (type) {
//       if (type === "공통") return 'light-blue'
//       else return 'orange'
//     },
//   },
//   computed: {
//     rowNum() {
//       return this.items.map(
//         (items, index) => ({
//         ...items,
//         index: index + 1
//       }))
//     },
//   },
// }`
,source04_tab01: `<!-- dataTableClass="has-control"로 바꿔줘야 함-->
<data-tables
  ref="dataTable6"
  dataTableClass="has-control" // Grid에 추가할 class, string, array, object 으로 지정가능(:class 사용 방법과 동일)
  :data-table-options="dataTableOptions5"
  // data-table 이벤트
  @click:row="onClickRow" // Row를 클릭할 때 실행되는 콜백 함수
  @contextmenu:row="onContextmenuRow" // Row를 우클릭할 때 실행되는 콜백 함수
  @current-items="onCurrentItems" // items Props에 변경이 있을 때 실행되는 콜백 함수
  @dblclick:row="onDbclickRow" // Row를 더블클릭할 때 실행되는 콜백 함수
  @input="dataTableOnInput" // 선택된 Rows 변경이 있을 때 실행되는 콜백 함수
  @item-expanded="onItemExpanded" // Row 확장 기능이 토글될 때 실행되는 콜백 함수
  @item-selected="onItemSelected" // Row 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @page-count="onPageCount" // 전체 페이지 수의 변경이 있을 때 실행되는 콜백 함수
  @pagination="onPagination" // 페이징 관련 옵션의 변경이 있을 때 실행되는 콜백 함수
  @toggle-select-all="onToggleSelectAll" // 전체 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @update:expanded="onUpdateExpanded" // expanded prop의 변경이 있을 때 실행되는 콜백 함수
  @update:group-by="onUpdateGroupBy" // options의 groupBy의 변경이 있을 때 실행되는 콜백 함수
  @update:group-desc="onUpdateGroupDesc" // options의 groupDesc의 변경이 있을 때 실행되는 콜백 함수
  @update:items-per-page="onUpdateItemsPerPage" // options의 itemsPerPage의 변경이 있을 때 실행되는 콜백 함수
  @update:multi-sort="onUpdateMultiSort" // options의 multiSort의 변경이 있을 때 실행되는 콜백 함수
  @update:must-sort="onUpdateMustSort" // options의 mustSort의 변경이 있을 때 실행되는 콜백 함수
  @update:options="onUpdateOptions" // options의 변경이 있을 때 실행되는 콜백 함수
  @update:page="onUpdatePage" // options의 page의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-by="onUpdateSortBy" // options의 sortBy의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-desc="onUpdateSortDesc" // options의 sortDesc의 변경이 있을 때 실행되는 콜백 함수
  
  paginationClass="" // Pagination에 추가할 class, string, array, function 으로 지정가능(:class 사용 방법과 동일)
  :paginationOptions="paginationOptions"
>
  <template v-slot:expanded-item="{ headers, item }">
    <td :colspan="headers.length" class="is-p-15">{{ item.contents }}</td>
  </template>
</data-tables>`
// `<!--class="jh-grid has-control"로 바꿔줘야 함-->
// <v-data-table
//   class="jh-grid has-control"
//   :headers="headers"
//   :items="rowNum"
//   fixed-header
//   show-expand
//   singleExpand
//   :expanded.sync="expanded"
//   height="200px"
//   hide-default-footer
//   :page.sync="page"
//   @page-count="pageCount = $event">
//   <template v-slot:expanded-item="{ headers, item }">
//     <td :colspan="headers.length" class="is-p-10">{{ item.contents }}</td>
//   </template>
// </v-data-table>
// <div class="jh-pager">
//   <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
//   <v-select
//     class="jh-form is-round is-right"
//     :items="perPage"   
//     label="10"
//     style="width: 70px"
//   ></v-select>
//   <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
// </div>`
,source04_tab02: `import dataTables from "@/components/DataTables";

export default {
  components: { 
    dataTables
  },
  computed: {
    dataTable6Instance: function() {
      return this.$refs.dataTable6;
    },
    rowNum() {
      return this.items.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
  },
  data() {
    return {
      headers3: [
        { text: '', value: 'data-table-expand' },
        { text: '순번', value: 'index', align: 'center', width: '50px' },
        { text: '유형', value: 'type', align: 'center', width: '100px' },
        { text: '스크립트명', value: 'subject'},
        { text: '수정일', value: 'date', align: 'center', width: '100px' },
      ],
      items: [
        {
          type: '공통',
          subject: '취소수수료를 환불해 주세요.',
          contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
          date: '2020-08-05'
        },
        ...
      ],
      dataTableOptions5: null,
      // v-data-table의 props로 전달될 option
      dataTableOptions: {
        calculateWidths: undefined, // type: boolean, default: false, 컬럼 넓이 계산 기능 활성화
        caption: undefined, // type: string, Grid 상단에 셋팅한 문자열로 caption을 표출
        checkboxColor: undefined, // type: string, showSelect가 true 일 때 체크박스의 색을 지정
        customFilter: undefined, // type: function, default: defaultFilter, 커스터마이징된 필터를 셋팅(function(value, search, item) { return boolean })
        customGroup: undefined, // type: function, default: groupItems, 커스터마이징된 그룹을 셋팅(function(items, groupBy, groupDesc) { return Record<string, any[]> })
        customSort: undefined, // type: function, default: sortItems, 커스터마이징된 정렬을 셋팅(function(items, sortBy, sortDesc, locale, customSorters) { return any[] })
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        dense: undefined, // type: boolean, default: false, Row의 높이가 축소된 레이아웃 모드 활성화
        disableFiltering: undefined, // type: boolean, default: false, 필터링 기능 비활성화
        disablePagination: undefined, // type: boolean, default: false, 페이징 기능 비활성화, TotalCount는 표출
        disableSort: undefined, // type: boolean, default: false, 정렬 기능 비활성화
        expandIcon: undefined, // type: string, default: '$expand', 확장기능 버튼에 적용할 커스텀 아이콘 지정
        expanded: undefined, // type: array, default: [], 확장기능을 사용한 확장된 Rows, 초기값 지정으로만 사용
        fixedHeader: true, // type: boolean, default: false, 스크롤 시 Grid 상단에 헤더를 고정하는 기능 활성화
        footerProps: undefined, // type: object, Default footer 사용 시 v-data-footer의 props로 전달될 값 - 사용 안항
        groupBy: undefined, // type: string|array, default: [], Grouping을 위한 컬럼 지정, 배열 사용 시 여러 컬럼 지정 가능
        groupDesc: undefined, // type: boolean|array, default: [], Grouping한 컬럼의 정렬 순서 지정, 여러 컬럼을 Grouping한 경우, 컬럼 순서와 동일하게 배열로 지정, true일 경우 내림차순
        headerProps: undefined, // type: object, Default header 사용 시 v-data-header의 props로 전달될 값
        headers: undefined, // type: DataTableHeader[], default: [], 각각의 헤더 컬럼의 정보({ text: string, value: string, align?: 'start'|'center'|'end', sortable?: boolean, filterable?: boolean, groupable?: boolean, divider?: boolean, class?: string|string[], cellClass?: string|string[], width?: string|number, filter?: (value, search, item) => boolean, sort?: (a, b) => number })
        headersLength: undefined, // type: number, 헤더 컬럼의 수를 지정, hide-default-header와 함께
        height: '200px', // type: number|string, Grid의 높이 지정
        hideDefaultFooter: true, // type: boolean, default: false, Default footer 비활성화
        hideDefaultHeader: undefined, // type: boolean, default: false, Default header 비활성화
        invisiblePagination: undefined, // type: boolean, default: false, 페이징 기능 전체 표출 안함
        itemClass: undefined, // type: string|function, Row에 지정할 class
        itemKey: 'index', // type: string, default: 'id', Row 선택 시 사용하는 유니크한 Column 이름, 헤더정보의 value들 중 하나여야 함
        items: undefined, // type: array, default: [], Grid에 보여질 데이터 세팅, api를 통해 데이터 로드하기 위해 load를 사용할 경우 items는 undefined, 처리된 array data를 바로 보여줄 경우에 사용
        itemsPerPage: 5, // type: number, default: 10, Grid에 보여줄 Row의 수
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        loaderHeight: undefined, // type: number|string, default: 4, loader의 높이 지정
        loading: undefined, // type: boolean|string, default: false, loader 표출 여부 지정
        loadingText: undefined, // type: string, default: '$vuetify.dataIterator.loadingText', loading이 true일 경우 보여질 텍스트 지정
        locale: undefined, // type: string, default: 'ko-KR', 정렬을 위한 국가 코드 셋팅
        mobileBreakpoint: undefined, // type: number|string, default: 600, Grid가 모바일 모드와 일반 모드로 전환되는 넓이 지정
        multiSort: undefined, // type: boolean, default: false, 멀티 정렬 사용 여부 지정
        mustSort: undefined, // type: boolean, default: false, 정렬을 항상 사용할지 여부 지정
        noDataText: undefined, // type: string, default: '$vuetify.noDataText', Row 데이터가 없을 경우 보여질 텍스트
        noResultsText: undefined, // type: string, default: '$vuetify.dataIterator.noResultsText', search 사용한 filtering 결과가 없을 때 보여질 텍스트
        options: undefined, // type: DataOptions, default: {}, Grid에 표출될 데이터와 연관된 페이징, 정렬, 그룹 등의 옵션 정보 ({ page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[], groupBy: string[], groupDesc: boolean[], muitlSort: boolean, mustSort: boolean })
        page: 1, // type: number, default: 1, 현재 표출될 페이지 수
        search: undefined, // type: string, Row 데이터 필터링하기 위한 텍스트 입력
        selectableKey: undefined, // type: string, default: 'isSelectable', 선택가능 여부를 위해 사용될 Row 데이터의 프로퍼티명
        showExpand: undefined, // type: boolean, default: false, 확장 기능을 위한 토글 버튼 활성화
        showGroupBy: undefined, // type: boolean, default: false, Grouping을 위한 토글 버튼 활성화
        showSelect: undefined, // type: boolean, default: false, Row 선택 기능 활성화
        singleExpand: undefined, // type: boolean, default: false, 확장 모드를 단일 확장 모드로 변경
        singleSelect: undefined, // type: boolean, default false, 선택 모드를 단일 선택 모드로 변경
        sortBy: undefined, // type: string|array, 정렬을 위해 사용되어질 프로퍼티명, array일 경우 복수 지정 가능
        sortDesc: undefined, // type: boolean|array, true이면 정렬을 내림차순으로 false이면 오름차순으로, sortBy를 복수로 지정했을 경우, 정렬 프로퍼티 array와 같은 인덱스 번호에 정렬 방향 지정
        
        load: this.demoAPI // grid data 요청 api 호출 , this.getGridData : 함수를 통해 데이터를 처리하여 로드할 경우
      },

      // v-pagination의 props로 전달될 option
      paginationOptions: {
        circle: undefined, // type: boolean, default: false, 페이징 엘레멘트를 원형으로 - 적용 안됨
        color: undefined,  // type: string, 페이징 엘레멘트 중 액티브 엘레멘트의 색상 지정 - 적용 안됨 
        currentPageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.currentPage', 페이지 수 버튼 중 현재 페이지의 aria-label
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        disabled: undefined, // type: boolean, default: false, 페이징 비활성화
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        nextAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.next', 다음 페이지 버튼의 aria-label
        nextIcon: undefined, // type: string, default: '$next', 다음 페이지 버튼의 아이콘 지정
        pageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.page', 페이지 수 버튼의 aria-label
        prevIcon: undefined, // type: string, default: '$next', 이전 페이지 버튼의 아이콘 지정
        previousAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.previous', 이전 페이지 버튼의 aria-label
        totalVisible: 7, // type: number, 보여질 페이지 수 버튼의 전체 갯수
        value: undefined, // type: number, default: 0, 현재 선택된 페이지 수
        wrapperAriaLabel: undefined, // type: string, default: '$vuetify,pagination.ariaLable.wrapper', wrapper의 aria-label

        perPages: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: 'All', value: -1 } ], // type: array, default: [ { text: '50', value: 50 }, { text: '100', value: 100 }, { text: '150', value: 150 } ],
                            //선택할 수 있는 페이지당 보여질 Row 수, number[]로도 가능 [ 5, 10, 15, -1 ], 단, 전체를 다 보여주는 -1은 그대로 표출됨, 페이징 처리 시 -1은 사용하지 마세요.

        // pagination 이벤트
        input: this.paginationOnInput, // 페이지 변경 이벤트 발생 시 실행되는 콜백 함수
        next: this.onNext, // 다음페이지 버튼 클릭 시 실행되는 콜백 함수
        previous: this.onPrevious // 이전페이지 버튼 클릭 시 실행되는 콜백 함수
      }
    }
  },
  methods: {
    onClickRow: function(row, data) {
      console.log("onClickRow", row, data);
    },
    onContextmenuRow: function(e, data) {
      console.log("onContextmenuRow", e, data);
    },
    onCurrentItems: function(items) {
      console.log("onCurrentItems", items);
    },
    onDbclickRow: function(e, row) {
      console.log("onDbclickRow", e, row);
    },
    dataTableOnInput: function(items) {
      console.log("dataTableOnInput", items);
    },
    onItemExpanded: function(data) {
      console.log("onItemExpanded", data);
    },
    onItemSelected: function(data) {
      console.log("onItemSelected", data);
    },
    onPageCount: function(pageCount) {
      console.log("onPageCount", pageCount);
    },
    onPagination: function(pagination) {
      console.log("onPagination", pagination);
    },
    onToggleSelectAll: function(data) {
      console.log("onToggleSelectAll", data);
    },
    onUpdateExpanded: function(expanded) {
      console.log("onUpdateExpanded", expanded);
      this.$nextTick(() => {
        console.log(this.dataTable6Instance.getExpandedData()); // 확장한 데이터 가져오기
      });
    },
    onUpdateGroupBy: function(groupBy) {
      console.log("onUpdateGroupBy", groupBy);
    },
    onUpdateGroupDesc: function(groupDesc) {
      console.log("onUpdateGroupDesc", groupDesc);
    },
    onUpdateItemsPerPage: function(itemsPerPage) {
      console.log("onUpdateItemsPerPage", itemsPerPage);
    },
    onUpdateMultiSort: function(multiSort) {
      console.log("onUpdateMultiSort", multiSort);
    },
    onUpdateMustSort: function(mustSort) {
      console.log("onUpdateMustSort", mustSort);
    },
    onUpdateOptions: function(options) {
      console.log("onUpdateOptions", options);
    },
    onUpdatePage: function(page) {
      console.log("onUpdatePage", page);
    },
    onUpdateSortBy: function(sortBy) {
      console.log("onUpdateSortBy", sortBy);
    },
    onUpdateSortDesc: function(sortDesc) {
      console.log("onUpdateSortDesc", sortDesc);
    },
    
    paginationOnInput: function(page) {
      console.log("paginationOnInput", page);
    },
    onNext: function() {
      console.log("onNext");
    },
    onPrevious: function() {
      console.log("onPrevious");
    },
    // 예시로 API Call처럼 동작하도록 만든 함수, 실제 API Call과 다를 수 있음, 참고만
    demoAPI: function(loadOptions) {
      console.log(loadOptions);
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = loadOptions;

        let data = this.rowNum;
        const totalCount = data.length;

        if (sortBy.length == 1 && sortDesc.length == 1) {
          data = data.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              else 0;
            }
            else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              else 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          data = data.slice((page-1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            data,
            totalCount
          })
        }, 1000);
      });
    },
    getGridData: function() { return { data: this.rowNum, totalCount: this.rowNum.length } }
  },
  created() {
    this.dataTableOptions5 = Object.assign({}, this.dataTableOptions, { headers: this.headers3, showExpand: true, singleExpand: true });
  }
}`
// `export default {
//   data () {
//     return {  
//       page: 1,
//       pageCount: 0,
//       expanded: [],
//       headers: [
//         { text: '', value: 'data-table-expand' },
//         { text: '순번', value: 'index', align: 'center', width: '50px' },
//         { text: '유형', value: 'type', align: 'center', width: '100px' },
//         { text: '스크립트명', value: 'subject'},
//         { text: '수정일', value: 'date', align: 'center', width: '100px' },
//       ],
//       items: [
//         {
//           type: '공통',
//           subject: '취소수수료를 환불해 주세요.',
//           contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
//           date: '2020-08-05'
//         },
//         ...
//       ],
//     }
//   },  
//   computed: {
//     rowNum() {
//       return this.items.map(
//         (items, index) => ({
//         ...items,
//         index: index + 1
//       }))
//     },
//   },
// }`
,source05_tab01: `<data-tables
  ref="dataTable7"
  dataTableClass="" // Grid에 추가할 class, string, array, object 으로 지정가능(:class 사용 방법과 동일)
  :data-table-options="dataTableOptions6"
  // data-table 이벤트
  @click:row="rowClick" // Row를 클릭할 때 실행되는 콜백 함수
  @contextmenu:row="onContextmenuRow" // Row를 우클릭할 때 실행되는 콜백 함수
  @current-items="onCurrentItems" // items Props에 변경이 있을 때 실행되는 콜백 함수
  @dblclick:row="onDbclickRow" // Row를 더블클릭할 때 실행되는 콜백 함수
  @input="dataTableOnInput" // 선택된 Rows 변경이 있을 때 실행되는 콜백 함수
  @item-expanded="onItemExpanded" // Row 확장 기능이 토글될 때 실행되는 콜백 함수
  @item-selected="onItemSelected" // Row 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @page-count="onPageCount" // 전체 페이지 수의 변경이 있을 때 실행되는 콜백 함수
  @pagination="onPagination" // 페이징 관련 옵션의 변경이 있을 때 실행되는 콜백 함수
  @toggle-select-all="onToggleSelectAll" // 전체 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @update:expanded="onUpdateExpanded" // expanded prop의 변경이 있을 때 실행되는 콜백 함수
  @update:group-by="onUpdateGroupBy" // options의 groupBy의 변경이 있을 때 실행되는 콜백 함수
  @update:group-desc="onUpdateGroupDesc" // options의 groupDesc의 변경이 있을 때 실행되는 콜백 함수
  @update:items-per-page="onUpdateItemsPerPage" // options의 itemsPerPage의 변경이 있을 때 실행되는 콜백 함수
  @update:multi-sort="onUpdateMultiSort" // options의 multiSort의 변경이 있을 때 실행되는 콜백 함수
  @update:must-sort="onUpdateMustSort" // options의 mustSort의 변경이 있을 때 실행되는 콜백 함수
  @update:options="onUpdateOptions" // options의 변경이 있을 때 실행되는 콜백 함수
  @update:page="onUpdatePage" // options의 page의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-by="onUpdateSortBy" // options의 sortBy의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-desc="onUpdateSortDesc" // options의 sortDesc의 변경이 있을 때 실행되는 콜백 함수
  
  paginationClass="" // Pagination에 추가할 class, string, array, function 으로 지정가능(:class 사용 방법과 동일)
  :paginationOptions="paginationOptions"
>
  <template v-slot:expanded-item="{ headers, item }">
    <td :colspan="headers.length" class="is-p-15">{{ item.contents }}</td>
  </template>
</data-tables>`
// `<v-data-table
//   class="jh-grid"
//   :headers="headers"
//   :items="rowNum"
//   item-key="index"
//   singleSelect
//   singleExpand
//   :expanded.sync="expanded"
//   fixed-header
//   height="200px"
//   @click:row="rowClick"
//   hide-default-footer
//   :page.sync="page"
//   @page-count="pageCount = $event">
//   <template v-slot:expanded-item="{ headers, item }">
//     <td :colspan="headers.length" class="is-p-10">{{ item.contents }}</td>
//   </template>
// </v-data-table>
// <div class="jh-pager">
//   <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
//   <v-select
//     class="jh-form is-round is-right"
//     :items="perPage"   
//     label="10"
//     style="width: 70px"
//   ></v-select>
//   <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
// </div>`
,source05_tab02: `import dataTables from "@/components/DataTables";

export default {
  components: { 
    dataTables
  },
  computed: {
    dataTable7Instance: function() {
      return this.$refs.dataTable7;
    },
    rowNum() {
      return this.items.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
  },
  data() {
    return {
      headers: [
        { text: '순번', value: 'index', align: 'center', width: '80px' },
        { text: '유형', value: 'type', align: 'center', width: '120px' },
        { text: '내용', value: 'contents'},
        { text: '수정일', value: 'date', align: 'center', width: '120px' },
      ],
      items: [
        {
          type: '공통',
          subject: '취소수수료를 환불해 주세요.',
          contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
          date: '2020-08-05'
        },
        ...
      ],

      selectedId: null,

      dataTableOptions6: null,
      // v-data-table의 props로 전달될 option
      dataTableOptions: {
        calculateWidths: undefined, // type: boolean, default: false, 컬럼 넓이 계산 기능 활성화
        caption: undefined, // type: string, Grid 상단에 셋팅한 문자열로 caption을 표출
        checkboxColor: undefined, // type: string, showSelect가 true 일 때 체크박스의 색을 지정
        customFilter: undefined, // type: function, default: defaultFilter, 커스터마이징된 필터를 셋팅(function(value, search, item) { return boolean })
        customGroup: undefined, // type: function, default: groupItems, 커스터마이징된 그룹을 셋팅(function(items, groupBy, groupDesc) { return Record<string, any[]> })
        customSort: undefined, // type: function, default: sortItems, 커스터마이징된 정렬을 셋팅(function(items, sortBy, sortDesc, locale, customSorters) { return any[] })
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        dense: undefined, // type: boolean, default: false, Row의 높이가 축소된 레이아웃 모드 활성화
        disableFiltering: undefined, // type: boolean, default: false, 필터링 기능 비활성화
        disablePagination: undefined, // type: boolean, default: false, 페이징 기능 비활성화, TotalCount는 표출
        disableSort: undefined, // type: boolean, default: false, 정렬 기능 비활성화
        expandIcon: undefined, // type: string, default: '$expand', 확장기능 버튼에 적용할 커스텀 아이콘 지정
        expanded: undefined, // type: array, default: [], 확장기능을 사용한 확장된 Rows, 초기값 지정으로만 사용
        fixedHeader: true, // type: boolean, default: false, 스크롤 시 Grid 상단에 헤더를 고정하는 기능 활성화
        footerProps: undefined, // type: object, Default footer 사용 시 v-data-footer의 props로 전달될 값 - 사용 안항
        groupBy: undefined, // type: string|array, default: [], Grouping을 위한 컬럼 지정, 배열 사용 시 여러 컬럼 지정 가능
        groupDesc: undefined, // type: boolean|array, default: [], Grouping한 컬럼의 정렬 순서 지정, 여러 컬럼을 Grouping한 경우, 컬럼 순서와 동일하게 배열로 지정, true일 경우 내림차순
        headerProps: undefined, // type: object, Default header 사용 시 v-data-header의 props로 전달될 값
        headers: undefined, // type: DataTableHeader[], default: [], 각각의 헤더 컬럼의 정보({ text: string, value: string, align?: 'start'|'center'|'end', sortable?: boolean, filterable?: boolean, groupable?: boolean, divider?: boolean, class?: string|string[], cellClass?: string|string[], width?: string|number, filter?: (value, search, item) => boolean, sort?: (a, b) => number })
        headersLength: undefined, // type: number, 헤더 컬럼의 수를 지정, hide-default-header와 함께
        height: '200px', // type: number|string, Grid의 높이 지정
        hideDefaultFooter: true, // type: boolean, default: false, Default footer 비활성화
        hideDefaultHeader: undefined, // type: boolean, default: false, Default header 비활성화
        invisiblePagination: undefined, // type: boolean, default: false, 페이징 기능 전체 표출 안함
        itemClass: undefined, // type: string|function, Row에 지정할 class
        itemKey: 'index', // type: string, default: 'id', Row 선택 시 사용하는 유니크한 Column 이름, 헤더정보의 value들 중 하나여야 함
        items: undefined, // type: array, default: [], Grid에 보여질 데이터 세팅, api를 통해 데이터 로드하기 위해 load를 사용할 경우 items는 undefined, 처리된 array data를 바로 보여줄 경우에 사용
        itemsPerPage: 5, // type: number, default: 10, Grid에 보여줄 Row의 수
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        loaderHeight: undefined, // type: number|string, default: 4, loader의 높이 지정
        loading: undefined, // type: boolean|string, default: false, loader 표출 여부 지정
        loadingText: undefined, // type: string, default: '$vuetify.dataIterator.loadingText', loading이 true일 경우 보여질 텍스트 지정
        locale: undefined, // type: string, default: 'ko-KR', 정렬을 위한 국가 코드 셋팅
        mobileBreakpoint: undefined, // type: number|string, default: 600, Grid가 모바일 모드와 일반 모드로 전환되는 넓이 지정
        multiSort: undefined, // type: boolean, default: false, 멀티 정렬 사용 여부 지정
        mustSort: undefined, // type: boolean, default: false, 정렬을 항상 사용할지 여부 지정
        noDataText: undefined, // type: string, default: '$vuetify.noDataText', Row 데이터가 없을 경우 보여질 텍스트
        noResultsText: undefined, // type: string, default: '$vuetify.dataIterator.noResultsText', search 사용한 filtering 결과가 없을 때 보여질 텍스트
        options: undefined, // type: DataOptions, default: {}, Grid에 표출될 데이터와 연관된 페이징, 정렬, 그룹 등의 옵션 정보 ({ page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[], groupBy: string[], groupDesc: boolean[], muitlSort: boolean, mustSort: boolean })
        page: 1, // type: number, default: 1, 현재 표출될 페이지 수
        search: undefined, // type: string, Row 데이터 필터링하기 위한 텍스트 입력
        selectableKey: undefined, // type: string, default: 'isSelectable', 선택가능 여부를 위해 사용될 Row 데이터의 프로퍼티명
        showExpand: undefined, // type: boolean, default: false, 확장 기능을 위한 토글 버튼 활성화
        showGroupBy: undefined, // type: boolean, default: false, Grouping을 위한 토글 버튼 활성화
        showSelect: undefined, // type: boolean, default: false, Row 선택 기능 활성화
        singleExpand: undefined, // type: boolean, default: false, 확장 모드를 단일 확장 모드로 변경
        singleSelect: undefined, // type: boolean, default false, 선택 모드를 단일 선택 모드로 변경
        sortBy: undefined, // type: string|array, 정렬을 위해 사용되어질 프로퍼티명, array일 경우 복수 지정 가능
        sortDesc: undefined, // type: boolean|array, true이면 정렬을 내림차순으로 false이면 오름차순으로, sortBy를 복수로 지정했을 경우, 정렬 프로퍼티 array와 같은 인덱스 번호에 정렬 방향 지정
        
        load: this.demoAPI // grid data 요청 api 호출 , this.getGridData : 함수를 통해 데이터를 처리하여 로드할 경우
      },

      // v-pagination의 props로 전달될 option
      paginationOptions: {
        circle: undefined, // type: boolean, default: false, 페이징 엘레멘트를 원형으로 - 적용 안됨
        color: undefined,  // type: string, 페이징 엘레멘트 중 액티브 엘레멘트의 색상 지정 - 적용 안됨 
        currentPageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.currentPage', 페이지 수 버튼 중 현재 페이지의 aria-label
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        disabled: undefined, // type: boolean, default: false, 페이징 비활성화
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        nextAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.next', 다음 페이지 버튼의 aria-label
        nextIcon: undefined, // type: string, default: '$next', 다음 페이지 버튼의 아이콘 지정
        pageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.page', 페이지 수 버튼의 aria-label
        prevIcon: undefined, // type: string, default: '$next', 이전 페이지 버튼의 아이콘 지정
        previousAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.previous', 이전 페이지 버튼의 aria-label
        totalVisible: 7, // type: number, 보여질 페이지 수 버튼의 전체 갯수
        value: undefined, // type: number, default: 0, 현재 선택된 페이지 수
        wrapperAriaLabel: undefined, // type: string, default: '$vuetify,pagination.ariaLable.wrapper', wrapper의 aria-label

        perPages: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: 'All', value: -1 } ], // type: array, default: [ { text: '50', value: 50 }, { text: '100', value: 100 }, { text: '150', value: 150 } ],
                            //선택할 수 있는 페이지당 보여질 Row 수, number[]로도 가능 [ 5, 10, 15, -1 ], 단, 전체를 다 보여주는 -1은 그대로 표출됨, 페이징 처리 시 -1은 사용하지 마세요.

        // pagination 이벤트
        input: this.paginationOnInput, // 페이지 변경 이벤트 발생 시 실행되는 콜백 함수
        next: this.onNext, // 다음페이지 버튼 클릭 시 실행되는 콜백 함수
        previous: this.onPrevious // 이전페이지 버튼 클릭 시 실행되는 콜백 함수
      }
    }
  },
  methods: {
    onClickRow: function(row, data) {
      console.log("onClickRow", row, data);
    },
    onContextmenuRow: function(e, data) {
      console.log("onContextmenuRow", e, data);
    },
    onCurrentItems: function(items) {
      console.log("onCurrentItems", items);
    },
    onDbclickRow: function(e, row) {
      console.log("onDbclickRow", e, row);
    },
    dataTableOnInput: function(items) {
      console.log("dataTableOnInput", items);
      this.$nextTick(() => {
        console.log(this.dataTable7Instance.getSelectedData()); // 선택한 데이터 가져오기
      });
    },
    onItemExpanded: function(data) {
      console.log("onItemExpanded", data);
    },
    onItemSelected: function(data) {
      console.log("onItemSelected", data);
    },
    onPageCount: function(pageCount) {
      console.log("onPageCount", pageCount);
    },
    onPagination: function(pagination) {
      console.log("onPagination", pagination);
    },
    onToggleSelectAll: function(data) {
      console.log("onToggleSelectAll", data);
    },
    onUpdateExpanded: function(expanded) {
      console.log("onUpdateExpanded", expanded);
      this.$nextTick(() => {
        console.log(this.dataTable7Instance.getExpandedData()); // 확장한 데이터 가져오기
      });
    },
    onUpdateGroupBy: function(groupBy) {
      console.log("onUpdateGroupBy", groupBy);
    },
    onUpdateGroupDesc: function(groupDesc) {
      console.log("onUpdateGroupDesc", groupDesc);
    },
    onUpdateItemsPerPage: function(itemsPerPage) {
      console.log("onUpdateItemsPerPage", itemsPerPage);
    },
    onUpdateMultiSort: function(multiSort) {
      console.log("onUpdateMultiSort", multiSort);
    },
    onUpdateMustSort: function(mustSort) {
      console.log("onUpdateMustSort", mustSort);
    },
    onUpdateOptions: function(options) {
      console.log("onUpdateOptions", options);
    },
    onUpdatePage: function(page) {
      console.log("onUpdatePage", page);
    },
    onUpdateSortBy: function(sortBy) {
      console.log("onUpdateSortBy", sortBy);
    },
    onUpdateSortDesc: function(sortDesc) {
      console.log("onUpdateSortDesc", sortDesc);
    },
    
    paginationOnInput: function(page) {
      console.log("paginationOnInput", page);
    },
    onNext: function() {
      console.log("onNext");
    },
    onPrevious: function() {
      console.log("onPrevious");
    },
    // 예시로 API Call처럼 동작하도록 만든 함수, 실제 API Call과 다를 수 있음, 참고만
    demoAPI: function(loadOptions) {
      console.log(loadOptions);
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = loadOptions;

        let data = this.rowNum;
        const totalCount = data.length;

        if (sortBy.length == 1 && sortDesc.length == 1) {
          data = data.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              else 0;
            }
            else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              else 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          data = data.slice((page-1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            data,
            totalCount
          })
        }, 1000);
      });
    },
    getGridData: function() { return { data: this.rowNum, totalCount: this.rowNum.length } },

    rowClick: function (item, row) { 
      if(this.selectedId===item.index){
        row.select(false);
        row.expand(false);
        this.selectedId=item.name;
      }else{
        row.select(true);
        this.selectedId=item.index;
        row.expand(true);
      }
    }
  },
  created() {
    this.dataTableOptions6 = Object.assign({}, this.dataTableOptions, { headers: this.headers, singleSelect: true, singleExpand: true });
  }
}`
// `export default {
//   data () {
//     return {  
//       page: 1,
//       pageCount: 0,
//       expanded: [],
//       headers: [
//         { text: '순번', value: 'index', align: 'center', width: '50px' },
//         { text: '유형', value: 'type', align: 'center', width: '100px' },
//         { text: '스크립트명', value: 'subject'},
//         { text: '수정일', value: 'date', align: 'center', width: '100px' },
//       ],
//       items: [
//         {
//           type: '공통',
//           subject: '취소수수료를 환불해 주세요.',
//           contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
//           date: '2020-08-05'
//         },
//         ...
//       ],
//     }
//   },  
//   methods: {
//     rowClick: function (item, row) { 
//       if(this.selectedId===item.index){
//         row.select(false);
//         row.expand(false);
//         this.selectedId=item.name;
//       }else{
//         row.select(true);
//         this.selectedId=item.index;
//         row.expand(true);
//       }
//     },
//   },
//   computed: {
//     rowNum() {
//       return this.items.map(
//         (items, index) => ({
//         ...items,
//         index: index + 1
//       }))
//     },
//   },
// }`
,source06_tab01: `<data-tables
  ref="dataTable3"
  dataTableClass="" // Grid에 추가할 class, string, array, object 으로 지정가능(:class 사용 방법과 동일)
  :data-table-options="dataTableOptions2"
  // data-table 이벤트
  @click:row="onClickRow" // Row를 클릭할 때 실행되는 콜백 함수
  @contextmenu:row="onContextmenuRow" // Row를 우클릭할 때 실행되는 콜백 함수
  @current-items="onCurrentItems" // items Props에 변경이 있을 때 실행되는 콜백 함수
  @dblclick:row="onDbclickRow" // Row를 더블클릭할 때 실행되는 콜백 함수
  @input="dataTableOnInput" // 선택된 Rows 변경이 있을 때 실행되는 콜백 함수
  @item-expanded="onItemExpanded" // Row 확장 기능이 토글될 때 실행되는 콜백 함수
  @item-selected="onItemSelected" // Row 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @page-count="onPageCount" // 전체 페이지 수의 변경이 있을 때 실행되는 콜백 함수
  @pagination="onPagination" // 페이징 관련 옵션의 변경이 있을 때 실행되는 콜백 함수
  @toggle-select-all="onToggleSelectAll" // 전체 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @update:expanded="onUpdateExpanded" // expanded prop의 변경이 있을 때 실행되는 콜백 함수
  @update:group-by="onUpdateGroupBy" // options의 groupBy의 변경이 있을 때 실행되는 콜백 함수
  @update:group-desc="onUpdateGroupDesc" // options의 groupDesc의 변경이 있을 때 실행되는 콜백 함수
  @update:items-per-page="onUpdateItemsPerPage" // options의 itemsPerPage의 변경이 있을 때 실행되는 콜백 함수
  @update:multi-sort="onUpdateMultiSort" // options의 multiSort의 변경이 있을 때 실행되는 콜백 함수
  @update:must-sort="onUpdateMustSort" // options의 mustSort의 변경이 있을 때 실행되는 콜백 함수
  @update:options="onUpdateOptions" // options의 변경이 있을 때 실행되는 콜백 함수
  @update:page="onUpdatePage" // options의 page의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-by="onUpdateSortBy" // options의 sortBy의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-desc="onUpdateSortDesc" // options의 sortDesc의 변경이 있을 때 실행되는 콜백 함수
  
  paginationClass="" // Pagination에 추가할 class, string, array, function 으로 지정가능(:class 사용 방법과 동일)
  :paginationOptions="paginationOptions"
/>`
// `<v-data-table
//   class="jh-grid"
//   :headers="headers"
//   :items="rowNum"
//   fixed-header
//   item-key="index"
//   height="200px"
//   hide-default-footer
//   :page.sync="page"
//   @page-count="pageCount = $event">
// </v-data-table>
// <div class="jh-pager">
//   <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
//   <v-select
//     class="jh-form is-round is-right"
//     :items="perPage"   
//     label="10"
//     style="width: 70px"
//   ></v-select>
//   <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
// </div>`
,source06_tab02: `import dataTables from "@/components/DataTables";

export default {
  components: { 
    dataTables
  },
  computed: {
    dataTable3Instance: function() {
      return this.$refs.dataTable3;
    },
    rowNum() {
      return this.items.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
  },
  data() {
    return {
      headers4: [
        { text: '순번', value: 'index', align: 'center', width: '280px' },
        { text: '유형', value: 'type', align: 'center', width: '520px' },
        { text: '스크립트명', value: 'subject', width: '520px'},
        { text: '수정일', value: 'date', align: 'center', width: '220px' },
      ],
      items: [
        {
          type: '공통',
          subject: '취소수수료를 환불해 주세요.',
          contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
          date: '2020-08-05'
        },
        ...
      ],
      dataTableOptions2: null,
      // v-data-table의 props로 전달될 option
      dataTableOptions: {
        calculateWidths: undefined, // type: boolean, default: false, 컬럼 넓이 계산 기능 활성화
        caption: undefined, // type: string, Grid 상단에 셋팅한 문자열로 caption을 표출
        checkboxColor: undefined, // type: string, showSelect가 true 일 때 체크박스의 색을 지정
        customFilter: undefined, // type: function, default: defaultFilter, 커스터마이징된 필터를 셋팅(function(value, search, item) { return boolean })
        customGroup: undefined, // type: function, default: groupItems, 커스터마이징된 그룹을 셋팅(function(items, groupBy, groupDesc) { return Record<string, any[]> })
        customSort: undefined, // type: function, default: sortItems, 커스터마이징된 정렬을 셋팅(function(items, sortBy, sortDesc, locale, customSorters) { return any[] })
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        dense: undefined, // type: boolean, default: false, Row의 높이가 축소된 레이아웃 모드 활성화
        disableFiltering: undefined, // type: boolean, default: false, 필터링 기능 비활성화
        disablePagination: undefined, // type: boolean, default: false, 페이징 기능 비활성화, TotalCount는 표출
        disableSort: undefined, // type: boolean, default: false, 정렬 기능 비활성화
        expandIcon: undefined, // type: string, default: '$expand', 확장기능 버튼에 적용할 커스텀 아이콘 지정
        expanded: undefined, // type: array, default: [], 확장기능을 사용한 확장된 Rows, 초기값 지정으로만 사용
        fixedHeader: true, // type: boolean, default: false, 스크롤 시 Grid 상단에 헤더를 고정하는 기능 활성화
        footerProps: undefined, // type: object, Default footer 사용 시 v-data-footer의 props로 전달될 값 - 사용 안항
        groupBy: undefined, // type: string|array, default: [], Grouping을 위한 컬럼 지정, 배열 사용 시 여러 컬럼 지정 가능
        groupDesc: undefined, // type: boolean|array, default: [], Grouping한 컬럼의 정렬 순서 지정, 여러 컬럼을 Grouping한 경우, 컬럼 순서와 동일하게 배열로 지정, true일 경우 내림차순
        headerProps: undefined, // type: object, Default header 사용 시 v-data-header의 props로 전달될 값
        headers: undefined, // type: DataTableHeader[], default: [], 각각의 헤더 컬럼의 정보({ text: string, value: string, align?: 'start'|'center'|'end', sortable?: boolean, filterable?: boolean, groupable?: boolean, divider?: boolean, class?: string|string[], cellClass?: string|string[], width?: string|number, filter?: (value, search, item) => boolean, sort?: (a, b) => number })
        headersLength: undefined, // type: number, 헤더 컬럼의 수를 지정, hide-default-header와 함께
        height: '200px', // type: number|string, Grid의 높이 지정
        hideDefaultFooter: true, // type: boolean, default: false, Default footer 비활성화
        hideDefaultHeader: undefined, // type: boolean, default: false, Default header 비활성화
        invisiblePagination: undefined, // type: boolean, default: false, 페이징 기능 전체 표출 안함
        itemClass: undefined, // type: string|function, Row에 지정할 class
        itemKey: 'index', // type: string, default: 'id', Row 선택 시 사용하는 유니크한 Column 이름, 헤더정보의 value들 중 하나여야 함
        items: undefined, // type: array, default: [], Grid에 보여질 데이터 세팅, api를 통해 데이터 로드하기 위해 load를 사용할 경우 items는 undefined, 처리된 array data를 바로 보여줄 경우에 사용
        itemsPerPage: 5, // type: number, default: 10, Grid에 보여줄 Row의 수
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        loaderHeight: undefined, // type: number|string, default: 4, loader의 높이 지정
        loading: undefined, // type: boolean|string, default: false, loader 표출 여부 지정
        loadingText: undefined, // type: string, default: '$vuetify.dataIterator.loadingText', loading이 true일 경우 보여질 텍스트 지정
        locale: undefined, // type: string, default: 'ko-KR', 정렬을 위한 국가 코드 셋팅
        mobileBreakpoint: undefined, // type: number|string, default: 600, Grid가 모바일 모드와 일반 모드로 전환되는 넓이 지정
        multiSort: undefined, // type: boolean, default: false, 멀티 정렬 사용 여부 지정
        mustSort: undefined, // type: boolean, default: false, 정렬을 항상 사용할지 여부 지정
        noDataText: undefined, // type: string, default: '$vuetify.noDataText', Row 데이터가 없을 경우 보여질 텍스트
        noResultsText: undefined, // type: string, default: '$vuetify.dataIterator.noResultsText', search 사용한 filtering 결과가 없을 때 보여질 텍스트
        options: undefined, // type: DataOptions, default: {}, Grid에 표출될 데이터와 연관된 페이징, 정렬, 그룹 등의 옵션 정보 ({ page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[], groupBy: string[], groupDesc: boolean[], muitlSort: boolean, mustSort: boolean })
        page: 1, // type: number, default: 1, 현재 표출될 페이지 수
        search: undefined, // type: string, Row 데이터 필터링하기 위한 텍스트 입력
        selectableKey: undefined, // type: string, default: 'isSelectable', 선택가능 여부를 위해 사용될 Row 데이터의 프로퍼티명
        showExpand: undefined, // type: boolean, default: false, 확장 기능을 위한 토글 버튼 활성화
        showGroupBy: undefined, // type: boolean, default: false, Grouping을 위한 토글 버튼 활성화
        showSelect: undefined, // type: boolean, default: false, Row 선택 기능 활성화
        singleExpand: undefined, // type: boolean, default: false, 확장 모드를 단일 확장 모드로 변경
        singleSelect: undefined, // type: boolean, default false, 선택 모드를 단일 선택 모드로 변경
        sortBy: undefined, // type: string|array, 정렬을 위해 사용되어질 프로퍼티명, array일 경우 복수 지정 가능
        sortDesc: undefined, // type: boolean|array, true이면 정렬을 내림차순으로 false이면 오름차순으로, sortBy를 복수로 지정했을 경우, 정렬 프로퍼티 array와 같은 인덱스 번호에 정렬 방향 지정
        
        load: this.demoAPI // grid data 요청 api 호출 , this.getGridData : 함수를 통해 데이터를 처리하여 로드할 경우
      },

      // v-pagination의 props로 전달될 option
      paginationOptions: {
        circle: undefined, // type: boolean, default: false, 페이징 엘레멘트를 원형으로 - 적용 안됨
        color: undefined,  // type: string, 페이징 엘레멘트 중 액티브 엘레멘트의 색상 지정 - 적용 안됨 
        currentPageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.currentPage', 페이지 수 버튼 중 현재 페이지의 aria-label
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        disabled: undefined, // type: boolean, default: false, 페이징 비활성화
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        nextAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.next', 다음 페이지 버튼의 aria-label
        nextIcon: undefined, // type: string, default: '$next', 다음 페이지 버튼의 아이콘 지정
        pageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.page', 페이지 수 버튼의 aria-label
        prevIcon: undefined, // type: string, default: '$next', 이전 페이지 버튼의 아이콘 지정
        previousAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.previous', 이전 페이지 버튼의 aria-label
        totalVisible: 7, // type: number, 보여질 페이지 수 버튼의 전체 갯수
        value: undefined, // type: number, default: 0, 현재 선택된 페이지 수
        wrapperAriaLabel: undefined, // type: string, default: '$vuetify,pagination.ariaLable.wrapper', wrapper의 aria-label

        perPages: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: 'All', value: -1 } ], // type: array, default: [ { text: '50', value: 50 }, { text: '100', value: 100 }, { text: '150', value: 150 } ],
                            //선택할 수 있는 페이지당 보여질 Row 수, number[]로도 가능 [ 5, 10, 15, -1 ], 단, 전체를 다 보여주는 -1은 그대로 표출됨, 페이징 처리 시 -1은 사용하지 마세요.

        // pagination 이벤트
        input: this.paginationOnInput, // 페이지 변경 이벤트 발생 시 실행되는 콜백 함수
        next: this.onNext, // 다음페이지 버튼 클릭 시 실행되는 콜백 함수
        previous: this.onPrevious // 이전페이지 버튼 클릭 시 실행되는 콜백 함수
      }
    }
  },
  methods: {
    onClickRow: function(row, data) {
      console.log("onClickRow", row, data);
    },
    onContextmenuRow: function(e, data) {
      console.log("onContextmenuRow", e, data);
    },
    onCurrentItems: function(items) {
      console.log("onCurrentItems", items);
    },
    onDbclickRow: function(e, row) {
      console.log("onDbclickRow", e, row);
    },
    dataTableOnInput: function(items) {
      console.log("dataTableOnInput", items);
    },
    onItemExpanded: function(data) {
      console.log("onItemExpanded", data);
    },
    onItemSelected: function(data) {
      console.log("onItemSelected", data);
    },
    onPageCount: function(pageCount) {
      console.log("onPageCount", pageCount);
    },
    onPagination: function(pagination) {
      console.log("onPagination", pagination);
    },
    onToggleSelectAll: function(data) {
      console.log("onToggleSelectAll", data);
    },
    onUpdateExpanded: function(expanded) {
      console.log("onUpdateExpanded", expanded);
    },
    onUpdateGroupBy: function(groupBy) {
      console.log("onUpdateGroupBy", groupBy);
    },
    onUpdateGroupDesc: function(groupDesc) {
      console.log("onUpdateGroupDesc", groupDesc);
    },
    onUpdateItemsPerPage: function(itemsPerPage) {
      console.log("onUpdateItemsPerPage", itemsPerPage);
    },
    onUpdateMultiSort: function(multiSort) {
      console.log("onUpdateMultiSort", multiSort);
    },
    onUpdateMustSort: function(mustSort) {
      console.log("onUpdateMustSort", mustSort);
    },
    onUpdateOptions: function(options) {
      console.log("onUpdateOptions", options);
    },
    onUpdatePage: function(page) {
      console.log("onUpdatePage", page);
    },
    onUpdateSortBy: function(sortBy) {
      console.log("onUpdateSortBy", sortBy);
    },
    onUpdateSortDesc: function(sortDesc) {
      console.log("onUpdateSortDesc", sortDesc);
    },
    
    paginationOnInput: function(page) {
      console.log("paginationOnInput", page);
    },
    onNext: function() {
      console.log("onNext");
    },
    onPrevious: function() {
      console.log("onPrevious");
    },
    // 예시로 API Call처럼 동작하도록 만든 함수, 실제 API Call과 다를 수 있음, 참고만
    demoAPI: function(loadOptions) {
      console.log(loadOptions);
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = loadOptions;

        let data = this.rowNum;
        const totalCount = data.length;

        if (sortBy.length == 1 && sortDesc.length == 1) {
          data = data.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              else 0;
            }
            else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              else 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          data = data.slice((page-1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            data,
            totalCount
          })
        }, 1000);
      });
    },
    getGridData: function() { return { data: this.rowNum, totalCount: this.rowNum.length } }
  },
  created() {
    this.dataTableOptions2 = Object.assign({}, this.dataTableOptions, { headers: this.headers4 });
  }
}`
// `export default {
//   data () {
//     return {  
//       page: 1,
//       pageCount: 0,
//       itemsPerPage: 10,
//       headers: [
//           { text: '순번', value: 'index', align: 'center', width: '280px' },
//           { text: '유형', value: 'type', align: 'center', width: '520px' },
//           { text: '스크립트명', value: 'subject', width: '520px'},
//           { text: '수정일', value: 'date', align: 'center', width: '220px' },
//       ],
//       items: [
//           {
//             type: '공통',
//             subject: '취소수수료를 환불해 주세요.',
//             contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
//             date: '2020-08-05'
//           },
//           ...
//       ],
//     }
//   },  
//   computed: {
//     rowNum() {
//       return this.items.map(
//         (items, index) => ({
//         ...items,
//         index: index + 1
//       }))
//     },
//   },
// }`
,source07_tab01: `<data-tables
  ref="dataTable8"
  dataTableClass="" // Grid에 추가할 class, string, array, object 으로 지정가능(:class 사용 방법과 동일)
  :data-table-options="dataTableOptions7"
  // data-table 이벤트
  @click:row="onClickRow" // Row를 클릭할 때 실행되는 콜백 함수
  @contextmenu:row="onContextmenuRow" // Row를 우클릭할 때 실행되는 콜백 함수
  @current-items="onCurrentItems" // items Props에 변경이 있을 때 실행되는 콜백 함수
  @dblclick:row="onDbclickRow" // Row를 더블클릭할 때 실행되는 콜백 함수
  @input="dataTableOnInput" // 선택된 Rows 변경이 있을 때 실행되는 콜백 함수
  @item-expanded="onItemExpanded" // Row 확장 기능이 토글될 때 실행되는 콜백 함수
  @item-selected="onItemSelected" // Row 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @page-count="onPageCount" // 전체 페이지 수의 변경이 있을 때 실행되는 콜백 함수
  @pagination="onPagination" // 페이징 관련 옵션의 변경이 있을 때 실행되는 콜백 함수
  @toggle-select-all="onToggleSelectAll" // 전체 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @update:expanded="onUpdateExpanded" // expanded prop의 변경이 있을 때 실행되는 콜백 함수
  @update:group-by="onUpdateGroupBy" // options의 groupBy의 변경이 있을 때 실행되는 콜백 함수
  @update:group-desc="onUpdateGroupDesc" // options의 groupDesc의 변경이 있을 때 실행되는 콜백 함수
  @update:items-per-page="onUpdateItemsPerPage" // options의 itemsPerPage의 변경이 있을 때 실행되는 콜백 함수
  @update:multi-sort="onUpdateMultiSort" // options의 multiSort의 변경이 있을 때 실행되는 콜백 함수
  @update:must-sort="onUpdateMustSort" // options의 mustSort의 변경이 있을 때 실행되는 콜백 함수
  @update:options="onUpdateOptions" // options의 변경이 있을 때 실행되는 콜백 함수
  @update:page="onUpdatePage" // options의 page의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-by="onUpdateSortBy" // options의 sortBy의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-desc="onUpdateSortDesc" // options의 sortDesc의 변경이 있을 때 실행되는 콜백 함수
  
  paginationClass="" // Pagination에 추가할 class, string, array, function 으로 지정가능(:class 사용 방법과 동일)
  :paginationOptions="paginationOptions"
>
  <template v-slot:header>
    <thead class="v-data-table-header">
      <tr>
        <th rowspan="2" width="60px"><span>NO</span></th>
        <th colspan="3" width="600px"><span>상담유형</span></th>
        <th rowspan="2"><span>스크립트명</span></th>
        <th rowspan="2" width="100px"><span>수정일</span></th>
      </tr>
      <tr>
        <th><span>대분류</span></th>
        <th><span>중분류</span></th>
        <th><span>소분류</span></th>
      </tr>
    </thead>
  </template>
</data-tables>`
// `<v-data-table
//   class="jh-grid"
//   :headers="headers"
//   :items="rowNum"
//   fixed-header
//   item-key="index"
//   height="200px"
//   hide-default-header //추가
//   hide-default-footer
//   :page.sync="page"
//   @page-count="pageCount = $event">       
//   <template v-slot:header="props">
//     <thead class="v-data-table-header">
//       <tr>
//         <th rowspan="2" width="60px"><span>NO</span></th>
//         <th colspan="3" width="600px"><span>상담유형</span></th>
//         <th rowspan="2"><span>스크립트명</span></th>
//         <th rowspan="2" width="100px"><span>수정일</span></th>
//       </tr>
//       <tr>
//         <th><span>대분류</span></th>
//         <th><span>중분류</span></th>
//         <th><span>소분류</span></th>
//       </tr>
//     </thead>
//   </template>
// </v-data-table> 
// <div class="jh-pager">
//   <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
//   <v-select
//     class="jh-form is-round is-right"
//     :items="perPage"   
//     label="10"
//     style="width: 70px"
//   ></v-select>
//   <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
// </div>`
,source07_tab02: `import dataTables from "@/components/DataTables";

export default {
  components: { 
    dataTables
  },
  computed: {
    dataTable8Instance: function() {
      return this.$refs.dataTable8;
    },
    rowNum() {
      return this.items.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
  },
  data() {
    return {
      headers5: [
        { text: '순번', value: 'index', align: 'center', width: '80px' },
        { text: '대분류', value: 'type01', align: 'center', width: '120px' },
        { text: '중분류', value: 'type02', align: 'center', width: '120px' },
        { text: '소분류', value: 'type03', align: 'center', width: '120px' },
        { text: '스크립트명', value: 'subject'},
        { text: '수정일', value: 'date', align: 'center', width: '120px' },
      ],
      items: [
        {
          type: '공통',
          subject: '취소수수료를 환불해 주세요.',
          contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
          date: '2020-08-05'
        },
        ...
      ],
      dataTableOptions7: null,
      // v-data-table의 props로 전달될 option
      dataTableOptions: {
        calculateWidths: undefined, // type: boolean, default: false, 컬럼 넓이 계산 기능 활성화
        caption: undefined, // type: string, Grid 상단에 셋팅한 문자열로 caption을 표출
        checkboxColor: undefined, // type: string, showSelect가 true 일 때 체크박스의 색을 지정
        customFilter: undefined, // type: function, default: defaultFilter, 커스터마이징된 필터를 셋팅(function(value, search, item) { return boolean })
        customGroup: undefined, // type: function, default: groupItems, 커스터마이징된 그룹을 셋팅(function(items, groupBy, groupDesc) { return Record<string, any[]> })
        customSort: undefined, // type: function, default: sortItems, 커스터마이징된 정렬을 셋팅(function(items, sortBy, sortDesc, locale, customSorters) { return any[] })
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        dense: undefined, // type: boolean, default: false, Row의 높이가 축소된 레이아웃 모드 활성화
        disableFiltering: undefined, // type: boolean, default: false, 필터링 기능 비활성화
        disablePagination: undefined, // type: boolean, default: false, 페이징 기능 비활성화, TotalCount는 표출
        disableSort: undefined, // type: boolean, default: false, 정렬 기능 비활성화
        expandIcon: undefined, // type: string, default: '$expand', 확장기능 버튼에 적용할 커스텀 아이콘 지정
        expanded: undefined, // type: array, default: [], 확장기능을 사용한 확장된 Rows, 초기값 지정으로만 사용
        fixedHeader: true, // type: boolean, default: false, 스크롤 시 Grid 상단에 헤더를 고정하는 기능 활성화
        footerProps: undefined, // type: object, Default footer 사용 시 v-data-footer의 props로 전달될 값 - 사용 안항
        groupBy: undefined, // type: string|array, default: [], Grouping을 위한 컬럼 지정, 배열 사용 시 여러 컬럼 지정 가능
        groupDesc: undefined, // type: boolean|array, default: [], Grouping한 컬럼의 정렬 순서 지정, 여러 컬럼을 Grouping한 경우, 컬럼 순서와 동일하게 배열로 지정, true일 경우 내림차순
        headerProps: undefined, // type: object, Default header 사용 시 v-data-header의 props로 전달될 값
        headers: undefined, // type: DataTableHeader[], default: [], 각각의 헤더 컬럼의 정보({ text: string, value: string, align?: 'start'|'center'|'end', sortable?: boolean, filterable?: boolean, groupable?: boolean, divider?: boolean, class?: string|string[], cellClass?: string|string[], width?: string|number, filter?: (value, search, item) => boolean, sort?: (a, b) => number })
        headersLength: undefined, // type: number, 헤더 컬럼의 수를 지정, hide-default-header와 함께
        height: '200px', // type: number|string, Grid의 높이 지정
        hideDefaultFooter: true, // type: boolean, default: false, Default footer 비활성화
        hideDefaultHeader: undefined, // type: boolean, default: false, Default header 비활성화
        invisiblePagination: undefined, // type: boolean, default: false, 페이징 기능 전체 표출 안함
        itemClass: undefined, // type: string|function, Row에 지정할 class
        itemKey: 'index', // type: string, default: 'id', Row 선택 시 사용하는 유니크한 Column 이름, 헤더정보의 value들 중 하나여야 함
        items: undefined, // type: array, default: [], Grid에 보여질 데이터 세팅, api를 통해 데이터 로드하기 위해 load를 사용할 경우 items는 undefined, 처리된 array data를 바로 보여줄 경우에 사용
        itemsPerPage: 5, // type: number, default: 10, Grid에 보여줄 Row의 수
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        loaderHeight: undefined, // type: number|string, default: 4, loader의 높이 지정
        loading: undefined, // type: boolean|string, default: false, loader 표출 여부 지정
        loadingText: undefined, // type: string, default: '$vuetify.dataIterator.loadingText', loading이 true일 경우 보여질 텍스트 지정
        locale: undefined, // type: string, default: 'ko-KR', 정렬을 위한 국가 코드 셋팅
        mobileBreakpoint: undefined, // type: number|string, default: 600, Grid가 모바일 모드와 일반 모드로 전환되는 넓이 지정
        multiSort: undefined, // type: boolean, default: false, 멀티 정렬 사용 여부 지정
        mustSort: undefined, // type: boolean, default: false, 정렬을 항상 사용할지 여부 지정
        noDataText: undefined, // type: string, default: '$vuetify.noDataText', Row 데이터가 없을 경우 보여질 텍스트
        noResultsText: undefined, // type: string, default: '$vuetify.dataIterator.noResultsText', search 사용한 filtering 결과가 없을 때 보여질 텍스트
        options: undefined, // type: DataOptions, default: {}, Grid에 표출될 데이터와 연관된 페이징, 정렬, 그룹 등의 옵션 정보 ({ page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[], groupBy: string[], groupDesc: boolean[], muitlSort: boolean, mustSort: boolean })
        page: 1, // type: number, default: 1, 현재 표출될 페이지 수
        search: undefined, // type: string, Row 데이터 필터링하기 위한 텍스트 입력
        selectableKey: undefined, // type: string, default: 'isSelectable', 선택가능 여부를 위해 사용될 Row 데이터의 프로퍼티명
        showExpand: undefined, // type: boolean, default: false, 확장 기능을 위한 토글 버튼 활성화
        showGroupBy: undefined, // type: boolean, default: false, Grouping을 위한 토글 버튼 활성화
        showSelect: undefined, // type: boolean, default: false, Row 선택 기능 활성화
        singleExpand: undefined, // type: boolean, default: false, 확장 모드를 단일 확장 모드로 변경
        singleSelect: undefined, // type: boolean, default false, 선택 모드를 단일 선택 모드로 변경
        sortBy: undefined, // type: string|array, 정렬을 위해 사용되어질 프로퍼티명, array일 경우 복수 지정 가능
        sortDesc: undefined, // type: boolean|array, true이면 정렬을 내림차순으로 false이면 오름차순으로, sortBy를 복수로 지정했을 경우, 정렬 프로퍼티 array와 같은 인덱스 번호에 정렬 방향 지정
        
        load: this.demoAPI // grid data 요청 api 호출 , this.getGridData : 함수를 통해 데이터를 처리하여 로드할 경우
      },

      // v-pagination의 props로 전달될 option
      paginationOptions: {
        circle: undefined, // type: boolean, default: false, 페이징 엘레멘트를 원형으로 - 적용 안됨
        color: undefined,  // type: string, 페이징 엘레멘트 중 액티브 엘레멘트의 색상 지정 - 적용 안됨 
        currentPageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.currentPage', 페이지 수 버튼 중 현재 페이지의 aria-label
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        disabled: undefined, // type: boolean, default: false, 페이징 비활성화
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        nextAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.next', 다음 페이지 버튼의 aria-label
        nextIcon: undefined, // type: string, default: '$next', 다음 페이지 버튼의 아이콘 지정
        pageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.page', 페이지 수 버튼의 aria-label
        prevIcon: undefined, // type: string, default: '$next', 이전 페이지 버튼의 아이콘 지정
        previousAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.previous', 이전 페이지 버튼의 aria-label
        totalVisible: 7, // type: number, 보여질 페이지 수 버튼의 전체 갯수
        value: undefined, // type: number, default: 0, 현재 선택된 페이지 수
        wrapperAriaLabel: undefined, // type: string, default: '$vuetify,pagination.ariaLable.wrapper', wrapper의 aria-label

        perPages: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: 'All', value: -1 } ], // type: array, default: [ { text: '50', value: 50 }, { text: '100', value: 100 }, { text: '150', value: 150 } ],
                            //선택할 수 있는 페이지당 보여질 Row 수, number[]로도 가능 [ 5, 10, 15, -1 ], 단, 전체를 다 보여주는 -1은 그대로 표출됨, 페이징 처리 시 -1은 사용하지 마세요.

        // pagination 이벤트
        input: this.paginationOnInput, // 페이지 변경 이벤트 발생 시 실행되는 콜백 함수
        next: this.onNext, // 다음페이지 버튼 클릭 시 실행되는 콜백 함수
        previous: this.onPrevious // 이전페이지 버튼 클릭 시 실행되는 콜백 함수
      }
    }
  },
  methods: {
    onClickRow: function(row, data) {
      console.log("onClickRow", row, data);
    },
    onContextmenuRow: function(e, data) {
      console.log("onContextmenuRow", e, data);
    },
    onCurrentItems: function(items) {
      console.log("onCurrentItems", items);
    },
    onDbclickRow: function(e, row) {
      console.log("onDbclickRow", e, row);
    },
    dataTableOnInput: function(items) {
      console.log("dataTableOnInput", items);
    },
    onItemExpanded: function(data) {
      console.log("onItemExpanded", data);
    },
    onItemSelected: function(data) {
      console.log("onItemSelected", data);
    },
    onPageCount: function(pageCount) {
      console.log("onPageCount", pageCount);
    },
    onPagination: function(pagination) {
      console.log("onPagination", pagination);
    },
    onToggleSelectAll: function(data) {
      console.log("onToggleSelectAll", data);
    },
    onUpdateExpanded: function(expanded) {
      console.log("onUpdateExpanded", expanded);
    },
    onUpdateGroupBy: function(groupBy) {
      console.log("onUpdateGroupBy", groupBy);
    },
    onUpdateGroupDesc: function(groupDesc) {
      console.log("onUpdateGroupDesc", groupDesc);
    },
    onUpdateItemsPerPage: function(itemsPerPage) {
      console.log("onUpdateItemsPerPage", itemsPerPage);
    },
    onUpdateMultiSort: function(multiSort) {
      console.log("onUpdateMultiSort", multiSort);
    },
    onUpdateMustSort: function(mustSort) {
      console.log("onUpdateMustSort", mustSort);
    },
    onUpdateOptions: function(options) {
      console.log("onUpdateOptions", options);
    },
    onUpdatePage: function(page) {
      console.log("onUpdatePage", page);
    },
    onUpdateSortBy: function(sortBy) {
      console.log("onUpdateSortBy", sortBy);
    },
    onUpdateSortDesc: function(sortDesc) {
      console.log("onUpdateSortDesc", sortDesc);
    },
    
    paginationOnInput: function(page) {
      console.log("paginationOnInput", page);
    },
    onNext: function() {
      console.log("onNext");
    },
    onPrevious: function() {
      console.log("onPrevious");
    },
    // 예시로 API Call처럼 동작하도록 만든 함수, 실제 API Call과 다를 수 있음, 참고만
    demoAPI: function(loadOptions) {
      console.log(loadOptions);
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = loadOptions;

        let data = this.rowNum;
        const totalCount = data.length;

        if (sortBy.length == 1 && sortDesc.length == 1) {
          data = data.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              else 0;
            }
            else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              else 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          data = data.slice((page-1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            data,
            totalCount
          })
        }, 1000);
      });
    },
    getGridData: function() { return { data: this.rowNum, totalCount: this.rowNum.length } }
  },
  created() {
    this.dataTableOptions7 = Object.assign({}, this.dataTableOptions, { headers: this.headers5, hideDefaultHeader: true });
  }
}`
// `export default {
//   data () {
//     return {  
//       page: 1,
//       pageCount: 0,
//       itemsPerPage: 10,
//       headers: [
//         { text: '순번', value: 'index', align: 'center', width: '80px' },
//         { text: '대분류', value: 'type01', align: 'center', width: '120px' },
//         { text: '중분류', value: 'type02', align: 'center', width: '120px' },
//         { text: '소분류', value: 'type03', align: 'center', width: '120px' },
//         { text: '스크립트명', value: 'subject'},
//         { text: '수정일', value: 'date', align: 'center', width: '120px' },
//       ],
//       items: [
//           {
//             type: '공통',
//             subject: '취소수수료를 환불해 주세요.',
//             contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
//             date: '2020-08-05'
//           },
//           ...
//       ],
//     }
//   },  
//   computed: {
//     rowNum() {
//       return this.items.map(
//         (items, index) => ({
//         ...items,
//         index: index + 1
//       }))
//     },
//   },
// }`
,source08_tab01: `<data-tables
  ref="dataTable2"
  dataTableClass="" // Grid에 추가할 class, string, array, object 으로 지정가능(:class 사용 방법과 동일)
  :data-table-options="dataTableOptions1"
  data-table 이벤트
  @click:row="onClickRow" // Row를 클릭할 때 실행되는 콜백 함수
  @contextmenu:row="onContextmenuRow" // Row를 우클릭할 때 실행되는 콜백 함수
  @current-items="onCurrentItems" // items Props에 변경이 있을 때 실행되는 콜백 함수
  @dblclick:row="onDbclickRow" // Row를 더블클릭할 때 실행되는 콜백 함수
  @input="dataTableOnInput" // 선택된 Rows 변경이 있을 때 실행되는 콜백 함수
  @item-expanded="onItemExpanded" // Row 확장 기능이 토글될 때 실행되는 콜백 함수
  @item-selected="onItemSelected" // Row 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @page-count="onPageCount" // 전체 페이지 수의 변경이 있을 때 실행되는 콜백 함수
  @pagination="onPagination" // 페이징 관련 옵션의 변경이 있을 때 실행되는 콜백 함수
  @toggle-select-all="onToggleSelectAll" // 전체 선택 체크박스를 토글할 때 실행되는 콜백 함수
  @update:expanded="onUpdateExpanded" // expanded prop의 변경이 있을 때 실행되는 콜백 함수
  @update:group-by="onUpdateGroupBy" // options의 groupBy의 변경이 있을 때 실행되는 콜백 함수
  @update:group-desc="onUpdateGroupDesc" // options의 groupDesc의 변경이 있을 때 실행되는 콜백 함수
  @update:items-per-page="onUpdateItemsPerPage" // options의 itemsPerPage의 변경이 있을 때 실행되는 콜백 함수
  @update:multi-sort="onUpdateMultiSort" // options의 multiSort의 변경이 있을 때 실행되는 콜백 함수
  @update:must-sort="onUpdateMustSort" // options의 mustSort의 변경이 있을 때 실행되는 콜백 함수
  @update:options="onUpdateOptions" // options의 변경이 있을 때 실행되는 콜백 함수
  @update:page="onUpdatePage" // options의 page의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-by="onUpdateSortBy" // options의 sortBy의 변경이 있을 때 실행되는 콜백 함수
  @update:sort-desc="onUpdateSortDesc" // options의 sortDesc의 변경이 있을 때 실행되는 콜백 함수
  
  paginationClass="" // Pagination에 추가할 class, string, array, function 으로 지정가능(:class 사용 방법과 동일)
  :paginationOptions="paginationOptions"
>
  //말줄임표 생성시 사용
  <template v-slot:item.contents="{ item }"><div class="is-ellips" :title="item.subject">{{ item.contents }}</div></template>
</data-tables>
`
// `<v-data-table
//   class="jh-grid"
//   :headers="headers"
//   :items="rowNum"
//   fixed-header
//   item-key="index"
//   height="200px"
//   hide-default-footer
//   :page.sync="page"
//   @page-count="pageCount = $event">
//   //말줄임표 생성시 사용
//   <template v-slot:item.subject="{ item }"><div class="is-ellips" :title="item.subject">{{ item.subject }}</div></template>
// </v-data-table>
// <div class="jh-pager">
//   <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
//   <v-select
//     class="jh-form is-round is-right"
//     :items="perPage"   
//     label="10"
//     style="width: 70px"
//   ></v-select>
//   <span class="jh-counter is-left">총 <em>{{ rowNum.length }}</em> 건</span>
// </div>`
,source08_tab02: `import dataTables from "@/components/DataTables";

export default {
  components: { 
    dataTables
  },
  computed: {
    dataTable2Instance: function() {
      return this.$refs.dataTable2;
    },
    rowNum() {
      return this.items.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
  },
  data() {
    return {
      headers: [
        { text: '순번', value: 'index', align: 'center', width: '80px' },
        { text: '유형', value: 'type', align: 'center', width: '120px' },
        { text: '내용', value: 'contents'},
        { text: '수정일', value: 'date', align: 'center', width: '120px' },
      ],
      items: [
        {
          type: '공통',
          subject: '취소수수료를 환불해 주세요.',
          contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
          date: '2020-08-05'
        },
        ...
      ],
      dataTableOptions1: null,
      // v-data-table의 props로 전달될 option
      dataTableOptions: {
        calculateWidths: undefined, // type: boolean, default: false, 컬럼 넓이 계산 기능 활성화
        caption: undefined, // type: string, Grid 상단에 셋팅한 문자열로 caption을 표출
        checkboxColor: undefined, // type: string, showSelect가 true 일 때 체크박스의 색을 지정
        customFilter: undefined, // type: function, default: defaultFilter, 커스터마이징된 필터를 셋팅(function(value, search, item) { return boolean })
        customGroup: undefined, // type: function, default: groupItems, 커스터마이징된 그룹을 셋팅(function(items, groupBy, groupDesc) { return Record<string, any[]> })
        customSort: undefined, // type: function, default: sortItems, 커스터마이징된 정렬을 셋팅(function(items, sortBy, sortDesc, locale, customSorters) { return any[] })
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        dense: undefined, // type: boolean, default: false, Row의 높이가 축소된 레이아웃 모드 활성화
        disableFiltering: undefined, // type: boolean, default: false, 필터링 기능 비활성화
        disablePagination: undefined, // type: boolean, default: false, 페이징 기능 비활성화, TotalCount는 표출
        disableSort: undefined, // type: boolean, default: false, 정렬 기능 비활성화
        expandIcon: undefined, // type: string, default: '$expand', 확장기능 버튼에 적용할 커스텀 아이콘 지정
        expanded: undefined, // type: array, default: [], 확장기능을 사용한 확장된 Rows, 초기값 지정으로만 사용
        fixedHeader: true, // type: boolean, default: false, 스크롤 시 Grid 상단에 헤더를 고정하는 기능 활성화
        footerProps: undefined, // type: object, Default footer 사용 시 v-data-footer의 props로 전달될 값 - 사용 안항
        groupBy: undefined, // type: string|array, default: [], Grouping을 위한 컬럼 지정, 배열 사용 시 여러 컬럼 지정 가능
        groupDesc: undefined, // type: boolean|array, default: [], Grouping한 컬럼의 정렬 순서 지정, 여러 컬럼을 Grouping한 경우, 컬럼 순서와 동일하게 배열로 지정, true일 경우 내림차순
        headerProps: undefined, // type: object, Default header 사용 시 v-data-header의 props로 전달될 값
        headers: undefined, // type: DataTableHeader[], default: [], 각각의 헤더 컬럼의 정보({ text: string, value: string, align?: 'start'|'center'|'end', sortable?: boolean, filterable?: boolean, groupable?: boolean, divider?: boolean, class?: string|string[], cellClass?: string|string[], width?: string|number, filter?: (value, search, item) => boolean, sort?: (a, b) => number })
        headersLength: undefined, // type: number, 헤더 컬럼의 수를 지정, hide-default-header와 함께
        height: '200px', // type: number|string, Grid의 높이 지정
        hideDefaultFooter: true, // type: boolean, default: false, Default footer 비활성화
        hideDefaultHeader: undefined, // type: boolean, default: false, Default header 비활성화
        invisiblePagination: undefined, // type: boolean, default: false, 페이징 기능 전체 표출 안함
        itemClass: undefined, // type: string|function, Row에 지정할 class
        itemKey: 'index', // type: string, default: 'id', Row 선택 시 사용하는 유니크한 Column 이름, 헤더정보의 value들 중 하나여야 함
        items: undefined, // type: array, default: [], Grid에 보여질 데이터 세팅, api를 통해 데이터 로드하기 위해 load를 사용할 경우 items는 undefined, 처리된 array data를 바로 보여줄 경우에 사용
        itemsPerPage: 5, // type: number, default: 10, Grid에 보여줄 Row의 수
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        loaderHeight: undefined, // type: number|string, default: 4, loader의 높이 지정
        loading: undefined, // type: boolean|string, default: false, loader 표출 여부 지정
        loadingText: undefined, // type: string, default: '$vuetify.dataIterator.loadingText', loading이 true일 경우 보여질 텍스트 지정
        locale: undefined, // type: string, default: 'ko-KR', 정렬을 위한 국가 코드 셋팅
        mobileBreakpoint: undefined, // type: number|string, default: 600, Grid가 모바일 모드와 일반 모드로 전환되는 넓이 지정
        multiSort: undefined, // type: boolean, default: false, 멀티 정렬 사용 여부 지정
        mustSort: undefined, // type: boolean, default: false, 정렬을 항상 사용할지 여부 지정
        noDataText: undefined, // type: string, default: '$vuetify.noDataText', Row 데이터가 없을 경우 보여질 텍스트
        noResultsText: undefined, // type: string, default: '$vuetify.dataIterator.noResultsText', search 사용한 filtering 결과가 없을 때 보여질 텍스트
        options: undefined, // type: DataOptions, default: {}, Grid에 표출될 데이터와 연관된 페이징, 정렬, 그룹 등의 옵션 정보 ({ page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[], groupBy: string[], groupDesc: boolean[], muitlSort: boolean, mustSort: boolean })
        page: 1, // type: number, default: 1, 현재 표출될 페이지 수
        search: undefined, // type: string, Row 데이터 필터링하기 위한 텍스트 입력
        selectableKey: undefined, // type: string, default: 'isSelectable', 선택가능 여부를 위해 사용될 Row 데이터의 프로퍼티명
        showExpand: undefined, // type: boolean, default: false, 확장 기능을 위한 토글 버튼 활성화
        showGroupBy: undefined, // type: boolean, default: false, Grouping을 위한 토글 버튼 활성화
        showSelect: undefined, // type: boolean, default: false, Row 선택 기능 활성화
        singleExpand: undefined, // type: boolean, default: false, 확장 모드를 단일 확장 모드로 변경
        singleSelect: undefined, // type: boolean, default false, 선택 모드를 단일 선택 모드로 변경
        sortBy: undefined, // type: string|array, 정렬을 위해 사용되어질 프로퍼티명, array일 경우 복수 지정 가능
        sortDesc: undefined, // type: boolean|array, true이면 정렬을 내림차순으로 false이면 오름차순으로, sortBy를 복수로 지정했을 경우, 정렬 프로퍼티 array와 같은 인덱스 번호에 정렬 방향 지정
        
        load: this.demoAPI // grid data 요청 api 호출 , this.getGridData : 함수를 통해 데이터를 처리하여 로드할 경우
      },

      // v-pagination의 props로 전달될 option
      paginationOptions: {
        circle: undefined, // type: boolean, default: false, 페이징 엘레멘트를 원형으로 - 적용 안됨
        color: undefined,  // type: string, 페이징 엘레멘트 중 액티브 엘레멘트의 색상 지정 - 적용 안됨 
        currentPageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.currentPage', 페이지 수 버튼 중 현재 페이지의 aria-label
        dark: undefined, // type: boolean, default: false, 다크 테마 모드 활성화
        disabled: undefined, // type: boolean, default: false, 페이징 비활성화
        light: undefined, // type: boolean, default: false, 라이트 테마 모드 활성화
        nextAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.next', 다음 페이지 버튼의 aria-label
        nextIcon: undefined, // type: string, default: '$next', 다음 페이지 버튼의 아이콘 지정
        pageAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.page', 페이지 수 버튼의 aria-label
        prevIcon: undefined, // type: string, default: '$next', 이전 페이지 버튼의 아이콘 지정
        previousAriaLabel: undefined, // type: string, default: '$vuetify.pagination.ariaLabel.previous', 이전 페이지 버튼의 aria-label
        totalVisible: 7, // type: number, 보여질 페이지 수 버튼의 전체 갯수
        value: undefined, // type: number, default: 0, 현재 선택된 페이지 수
        wrapperAriaLabel: undefined, // type: string, default: '$vuetify,pagination.ariaLable.wrapper', wrapper의 aria-label

        perPages: [ { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '15', value: 15 }, { text: 'All', value: -1 } ], // type: array, default: [ { text: '50', value: 50 }, { text: '100', value: 100 }, { text: '150', value: 150 } ],
                            //선택할 수 있는 페이지당 보여질 Row 수, number[]로도 가능 [ 5, 10, 15, -1 ], 단, 전체를 다 보여주는 -1은 그대로 표출됨, 페이징 처리 시 -1은 사용하지 마세요.

        // pagination 이벤트
        input: this.paginationOnInput, // 페이지 변경 이벤트 발생 시 실행되는 콜백 함수
        next: this.onNext, // 다음페이지 버튼 클릭 시 실행되는 콜백 함수
        previous: this.onPrevious // 이전페이지 버튼 클릭 시 실행되는 콜백 함수
      }
    }
  },
  methods: {
    onClickRow: function(row, data) {
      console.log("onClickRow", row, data);
    },
    onContextmenuRow: function(e, data) {
      console.log("onContextmenuRow", e, data);
    },
    onCurrentItems: function(items) {
      console.log("onCurrentItems", items);
    },
    onDbclickRow: function(e, row) {
      console.log("onDbclickRow", e, row);
    },
    dataTableOnInput: function(items) {
      console.log("dataTableOnInput", items);
    },
    onItemExpanded: function(data) {
      console.log("onItemExpanded", data);
    },
    onItemSelected: function(data) {
      console.log("onItemSelected", data);
    },
    onPageCount: function(pageCount) {
      console.log("onPageCount", pageCount);
    },
    onPagination: function(pagination) {
      console.log("onPagination", pagination);
    },
    onToggleSelectAll: function(data) {
      console.log("onToggleSelectAll", data);
    },
    onUpdateExpanded: function(expanded) {
      console.log("onUpdateExpanded", expanded);
    },
    onUpdateGroupBy: function(groupBy) {
      console.log("onUpdateGroupBy", groupBy);
    },
    onUpdateGroupDesc: function(groupDesc) {
      console.log("onUpdateGroupDesc", groupDesc);
    },
    onUpdateItemsPerPage: function(itemsPerPage) {
      console.log("onUpdateItemsPerPage", itemsPerPage);
    },
    onUpdateMultiSort: function(multiSort) {
      console.log("onUpdateMultiSort", multiSort);
    },
    onUpdateMustSort: function(mustSort) {
      console.log("onUpdateMustSort", mustSort);
    },
    onUpdateOptions: function(options) {
      console.log("onUpdateOptions", options);
    },
    onUpdatePage: function(page) {
      console.log("onUpdatePage", page);
    },
    onUpdateSortBy: function(sortBy) {
      console.log("onUpdateSortBy", sortBy);
    },
    onUpdateSortDesc: function(sortDesc) {
      console.log("onUpdateSortDesc", sortDesc);
    },
    
    paginationOnInput: function(page) {
      console.log("paginationOnInput", page);
    },
    onNext: function() {
      console.log("onNext");
    },
    onPrevious: function() {
      console.log("onPrevious");
    },
    // 예시로 API Call처럼 동작하도록 만든 함수, 실제 API Call과 다를 수 있음, 참고만
    demoAPI: function(loadOptions) {
      console.log(loadOptions);
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = loadOptions;

        let data = this.rowNum;
        const totalCount = data.length;

        if (sortBy.length == 1 && sortDesc.length == 1) {
          data = data.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              else 0;
            }
            else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              else 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          data = data.slice((page-1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            data,
            totalCount
          })
        }, 1000);
      });
    },
    getGridData: function() { return { data: this.rowNum, totalCount: this.rowNum.length } }
  },
  created() {
    this.dataTableOptions1 = Object.assign({}, this.dataTableOptions, { headers: this.headers });
  }
}`
// `export default {
//   data () {
//     return {  
//       page: 1,
//       pageCount: 0,
//       itemsPerPage: 10,
//       headers: [
//         { text: '순번', value: 'index', align: 'center', width: '80px' },
//         { text: '유형', value: 'type', align: 'center', width: '120px' },
//         { text: '스크립트명', value: 'subject'},
//         { text: '수정일', value: 'date', align: 'center', width: '120px' },
//       ],
//       items: [
//           {
//             type: '공통',
//             subject: '취소수수료를 환불해 주세요.',
//             contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
//             date: '2020-08-05'
//           },
//           ...
//       ],
//     }
//   },  
//   computed: {
//     rowNum() {
//       return this.items.map(
//         (items, index) => ({
//         ...items,
//         index: index + 1
//       }))
//     },
//   },
// }`
      }
    }
  },
  methods: {
    onClickRow: function(row, data) {
      console.log("onClickRow", row, data);
    },
    onContextmenuRow: function(e, data) {
      console.log("onContextmenuRow", e, data);
    },
    onCurrentItems: function(items) {
      console.log("onCurrentItems", items);
    },
    onDbclickRow: function(e, row) {
      console.log("onDbclickRow", e, row);
    },
    dataTableOnInput: function(items) {
      console.log("dataTableOnInput", items);
      this.$nextTick(() => {
        console.log("dataTable4", this.selected); // 선택한 데이터 가져오기
        console.log("dataTable4", this.dataTable4Instance.getSelectedData()); // 선택한 데이터 가져오기
        console.log("dataTable7", this.dataTable7Instance.getSelectedData()); // 선택한 데이터 가져오기
      });
    },
    onItemExpanded: function(data) {
      console.log("onItemExpanded", data);
    },
    onItemSelected: function(data) {
      console.log("onItemSelected", data);
    },
    onPageCount: function(pageCount) {
      console.log("onPageCount", pageCount);
    },
    onPagination: function(pagination) {
      console.log("onPagination", pagination);
    },
    onToggleSelectAll: function(data) {
      console.log("onToggleSelectAll", data);
    },
    onUpdateExpanded: function(expanded) {
      console.log("onUpdateExpanded", expanded);
      this.$nextTick(() => {
        console.log("dataTable6", this.dataTable6Instance.getExpandedData()); // 확장한 데이터 가져오기
        console.log("dataTable7", this.dataTable7Instance.getExpandedData()); // 확장한 데이터 가져오기
      });
    },
    onUpdateGroupBy: function(groupBy) {
      console.log("onUpdateGroupBy", groupBy);
    },
    onUpdateGroupDesc: function(groupDesc) {
      console.log("onUpdateGroupDesc", groupDesc);
    },
    onUpdateItemsPerPage: function(itemsPerPage) {
      console.log("onUpdateItemsPerPage", itemsPerPage);
    },
    onUpdateMultiSort: function(multiSort) {
      console.log("onUpdateMultiSort", multiSort);
    },
    onUpdateMustSort: function(mustSort) {
      console.log("onUpdateMustSort", mustSort);
    },
    onUpdateOptions: function(options) {
      console.log("onUpdateOptions", options);
    },
    onUpdatePage: function(page) {
      console.log("onUpdatePage", page);
    },
    onUpdateSortBy: function(sortBy) {
      console.log("onUpdateSortBy", sortBy);
    },
    onUpdateSortDesc: function(sortDesc) {
      console.log("onUpdateSortDesc", sortDesc);
    },
    
    paginationOnInput: function(page) {
      console.log("paginationOnInput", page);
    },
    onNext: function() {
      console.log("onNext");
    },
    onPrevious: function() {
      console.log("onPrevious");
    },

    getColor (type) {
      if (type === "공통") return 'light-blue'
      else return 'orange'
    },
    rowClick: function (item, row) { 
      if(this.selectedId===item.index){
        row.select(false);
        row.expand(false);
        this.selectedId=item.name;
      }else{
        row.select(true);
        this.selectedId=item.index;
        row.expand(true);
      }
    },    

    // 예시로 API Call처럼 동작하도록 만든 함수, 실제 API Call과 다를 수 있음, 참고만
    demoAPI: function(loadOptions) {
      console.log(loadOptions);
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = loadOptions;

        let data = this.rowNum;
        const totalCount = data.length;

        if (sortBy.length == 1 && sortDesc.length == 1) {
          data = data.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              else 0;
            }
            else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              else 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          data = data.slice((page-1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            data,
            totalCount
          })
        }, 1000);
      });
    },
    getGridData: function() { return { data: this.rowNum, totalCount: this.rowNum.length } },

    // 페이지당건수 변경 시
    checkChangeRowPerPage: function() {
        var allCnt = this.pagination.rowsPerPage * this.pagination.page;            // 선택한 페이지의 표출 가능 전체 건수
        var maxPage = Math.ceil(this.totalCount / this.pagination.rowsPerPage);     // 조회된 전체 건수 대비 최대 페이지 수 계산
        if ( this.totalCount < allCnt ) {
            this.pagination.page = maxPage;                                         // 조회된 건체 건수보다 표출 가능 전체 건수가 많으면 조회된 전체 건수 대비 최대 페이지 수를 현재 페이지로 셋팅한다.
        }
    },

    addRow: function() {
      console.log(this.dataTable1Instance.getData()); // 현재 Grid의 데이터 가져오기
      let totalCount = this.dataTable1Instance.getTotalCount(); // 현재 Grid의 전체 데이터 수 가져오기
      let row = {
        index: ++totalCount,
        type: '개인',
        subject: '카드 등록이 안되요.',
        contents: '카카오T택시는 고객님과 기사분을 연결해드리는 중개버시스를 제공하고 있기에 현장에서 발생한 분쟁에 관하여 직접적인 개입이 어려운 부분이 있습니다. 다만, 해당 기사님이 승객과 배차되지 않도록 “이 기사 만나지 않기” 처리를 도와드릴 수 있습니다.',
        date: '2020-08-05'
      };
      this.dataTable1Instance.addRow(row); // row 추가
    },
    updateRow: function(idx, row) {
      this.dataTable5Instance.updateRow(idx, "subject", "[수정됨]" + row.subject); // row 업데이트
    },
    deleteRow: function(idx) {
      this.dataTable5Instance.deleteRow(idx); // row 삭제
    }
  },
  created() {
    this.dataTableOptions1 = Object.assign({}, this.dataTableOptions, { headers: this.headers, items: this.rowNum, load: undefined });
    this.dataTableOptions2 = Object.assign({}, this.dataTableOptions, { headers: this.headers4 });
    this.dataTableOptions3 = Object.assign({}, this.dataTableOptions, { headers: this.headers, showSelect: true });
    this.dataTableOptions4 = Object.assign({}, this.dataTableOptions, { headers: this.headers2 });
    this.dataTableOptions5 = Object.assign({}, this.dataTableOptions, { headers: this.headers3, showExpand: true, singleExpand: true });
    this.dataTableOptions6 = Object.assign({}, this.dataTableOptions, { headers: this.headers, singleSelect: true, singleExpand: true });
    this.dataTableOptions7 = Object.assign({}, this.dataTableOptions, { headers: this.headers5, hideDefaultHeader: true });
  },
  computed: {
    dataTable1Instance: function() {
      return this.$refs.dataTable1;
    },
    dataTable2Instance: function() {
      return this.$refs.dataTable2;
    },
    dataTable3Instance: function() {
      return this.$refs.dataTable3;
    },
    dataTable4Instance: function() {
      return this.$refs.dataTable4;
    },
    dataTable5Instance: function() {
      return this.$refs.dataTable5;
    },
    dataTable6Instance: function() {
      return this.$refs.dataTable6;
    },
    dataTable7Instance: function() {
      return this.$refs.dataTable7;
    },
    dataTable8Instance: function() {
      return this.$refs.dataTable8;
    },

    rowNum() {
      return this.items.map(
        (items, index) => ({
        ...items,
        index: index + 1
      }))
    },
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>