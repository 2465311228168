import { mapState, mapGetters, mapActions } from "vuex";
// import XLSX from "xlsx";
import XLSX from "xlsx-color";
import api from "../store/apiUtil.js";
import axios from "axios";
import CryptoJS from "crypto-js";

export const mixin = {
  data() {
    return {
      default_tab: [
        {
          id: "E999999",
          title: "HOME",
          default: "Y",
          icon: "svg-tab-home",
          link: "/E999999",
        },
        // {
        //   id: "E050101",
        //   title: "HOME",
        //   default: "Y",
        //   icon: "svg-tab-home",
        //   link: "/E050101",
        // },
        // {
        //   id: "E010101",
        //   title: "콜상담 메인",
        //   default: "Y",
        //   icon: "svg-tab-main",
        //   link: "/E010101",
        // },
      ],
      table_id: "",
      file_name: "",
      sheet_name: "",
      excel_template_headers: {},
      excel_template_bodys: {},
      excel_template_sheet_header: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "AA",
        "AB",
        "AC",
        "AD",
        "AE",
        "AF",
        "AG",
        "AH",
        "AI",
        "AJ",
        "AK",
        "AL",
        "AM",
        "AN",
        "AO",
        "AP",
        "AQ",
        "AR",
        "AS",
        "AT",
        "AU",
        "AV",
        "AW",
        "AX",
        "AY",
        "AZ",
      ],

      /**
       * 챗관련 추가 Start_Arthur.Kim
       */
      talkStatObjs : [  
          {TALK_STAT_CD:'11',TALK_STAT_NM:'대기중',TALK_STAT_NM_TITLE:'대기중',CALSS:'bg-green'}  
        , {TALK_STAT_CD:'13',TALK_STAT_NM:'호전환',TALK_STAT_NM_TITLE:'호전환',CALSS:'bg-cyan'}
        , {TALK_STAT_CD:'14',TALK_STAT_NM:'전달완료',TALK_STAT_NM_TITLE:'전달완료',CALSS:'bg-blueGrey'}
        , {TALK_STAT_CD:'12',TALK_STAT_NM:'상담중',TALK_STAT_NM_TITLE:'상담중',CALSS:'bg-blue'}
        , {TALK_STAT_CD:'20',TALK_STAT_NM:'후처리',TALK_STAT_NM_TITLE:'후처리',CALSS:'bg-blueGrey'}
        , {TALK_STAT_CD:'22',TALK_STAT_NM:'후처리',TALK_STAT_NM_TITLE:'후처리',CALSS:'bg-blueGrey'}
        , {TALK_STAT_CD:'12',TALK_STAT_NM:'',TALK_STAT_NM_TITLE:'중지',SYSMSG_SKIP_YN:'Y',CALSS:'tt-icon-pause'}
        , {TALK_STAT_CD:'21',TALK_STAT_NM:'상담완료',TALK_STAT_NM_TITLE:'상담완료'}
        , {TALK_STAT_CD:'12',TALK_STAT_NM:'콜백',TALK_STAT_NM_TITLE:'콜백',EXEC_RST_CD:'4'}   
        , {TALK_STAT_CD:'20',TALK_STAT_NM:'후처리',TALK_STAT_NM_TITLE:'후처리',EXEC_RST_CD:'4'}
      ],

      channels : [],  // 채널 ( 카카오/티톡 ) 

      /**
      * 챗관련 추가 End_Arthur.Kim
      */ 

    };
  },
  mounted() {},
  destroy() {},
  computed: {
    ...mapGetters({
      progress_bar: "commonStore/GE_COMMON_PROGRESS_BAR",
      user_id: "userStore/GE_USER_ID",
    }),
  },
  methods: {
    // 챗팅 상담상태명_Arthur.Kim 
    mixin_get_talk_statnm (talk_stat_cd, resultCd, sysMsgSkipYn)   {
      
      //console.log("talk_stat_cd ::: " + talk_stat_cd);
      //console.log("resultCd ::: " + resultCd);
      //console.log("sysMsgSkipYn ::: " + sysMsgSkipYn);
      
      let obj = _.find(this.talkStatObjs, {'TALK_STAT_CD': talk_stat_cd});
      
      if( talk_stat_cd == '12' && sysMsgSkipYn == 'Y' ) {
        obj = _.find(this.talkStatObjs, { 'TALK_STAT_CD': talk_stat_cd, 'SYSMSG_SKIP_YN':sysMsgSkipYn });
      } else if( resultCd != null && resultCd == '4' ) {
        obj = _.find(this.talkStatObjs, { 'TALK_STAT_CD': talk_stat_cd, 'EXEC_RST_CD': resultCd });
      }
      
      return obj != null ? obj.TALK_STAT_NM : '미등록상태';
    },

    async mixin_imageView(busiType, pathType, dataList){
      for (let indexKey in dataList) {
        // console.log("in for typeof dataList[indexKey] :" + typeof dataList[indexKey])
        if (typeof dataList[indexKey] === "object") {
          // console.log("in if dataList[indexKey] :" + typeof dataList[indexKey]);
          let blob = await this.requestImageView({
            url: "api/file/" + busiType + "/" + pathType + "/download", // required
             headers: { // required
               'Authorization': "",
             },
            data: { // optional
              'fileGroupKey': dataList[indexKey].FILE_GROUP_KEY,
              // 'aspNewCustKey': this.$store.getters['userStore/GE_USER_ASP_CUST_KEY'],
              // 'aspCustKey': this.$store.getters['userStore/GE_USER_ASP_CUST_KEY'],
              'aspNewCustKey': "001",
              'aspCustKey': "001",
              'procId': this.$store.getters['userStore/GE_USER_ROLE'].userId,
              'fileKey': dataList[indexKey].FILE_KEY,
              'uploadUri': "/api/file/" + busiType + "/" + pathType + "/uploads",
              'downloadUri': "/api/file/" + busiType + "/" + pathType + "/download",
              'fileUploadLib': "dropzone",
              'dir': "/EBEST/web/" + busiType + "/" + pathType,
              'tempDir': "/EBEST/web/" + busiType + "/" + pathType + "/temp",
              'targetType': "FILE",
              'acceptedFiles': "image/*,*.doc,*.docx,*.pdf,*.csv,*.xls,*.xlsx,*.ppt",
            }
          });
    
          console.log("set src start");
          dataList[indexKey].src = await this.getImageBase64(blob);
          console.log("set src end");
    
          console.log(dataList[indexKey]);
        } else {
          break;
        }
      }
    
      return dataList;
    },

    async requestImageView(requestObj) {
      // _.merge - Lodash 라이브러리
      let reqHeaders = requestObj.headers;
      const token = window.sessionStorage.getItem("token");
      // request.headers.token = token;
      //resuest header에 전달받은 accessToken을 Bearer 형식으로 전송
      reqHeaders.Authorization = "bearer " + token;
      let form = new FormData();
      console.log("fileName  " )
      console.log("fileName  ")

      for (var strKey in requestObj.data) {
        form.append(strKey, requestObj.data[strKey]);
      }
    
      return axios({
        url: process.env.VUE_APP_API_URL + requestObj.url,
        headers: reqHeaders,
        responseType: 'arraybuffer',
        method: 'post',
        data: form
      }).then(res => {
        try {

          console.log("fileName  res " + JSON.stringify(res) )
          console.log("fileName  res.headers['content-disposition'] " + res.headers['content-disposition'])

          let fileName = this.getFileName(res.headers['content-disposition'])
          
          fileName = decodeURI(fileName) // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)


          console.log("fileName  " + fileName)

          let fileExt = this.getFileExt(fileName);
          let blob;
          if (!this.mixin_isEmpty(fileExt)) {
            blob = new Blob([res.data], { type: "image/" + fileExt })
          }
    
          return blob;
    
        } catch (e) {
          console.error(e)
        }
      }).catch(res => { // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
        requestObj.callback(res.response.data)
      })
    },
    getFileExt(fileName){
      let rtn = "";
      if(fileName.length > 0){
        let index = fileName.indexOf(".");
        if(index > -1){
          rtn = fileName.substring(index + 1, fileName.length);
        }
      }
      return rtn;
    },
    async getImageBase64(blob){
      if (!this.mixin_isEmpty(blob)) {
        try {
          return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
              console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
              console.log(reader.result)
              resolve(reader.result);
            };
            reader.onerror = () => { reject("") };
            reader.readAsDataURL(blob);
    
          });
        } catch (e) {
          return "";
        }
    
      } else {
        return "";
      }
    
    },
    



    mixin_set_active_tab(item) {
      console.log("#### [mixin_set_active_tab] item=", item);
      if (item.C_NODE_NO == 'E020301') item.C_NODE_NO = 'E010102';
      if (item.C_NODE_NO) {
        // let setId = item.to.replace("/", "");
        this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
          id: item.C_NODE_NO,
          title: item.NODE_TITLE,
          default: "N",
          tabIcon: "",
          link: "/" + item.C_NODE_NO,
        });
      }
      // if (item.to) {
      //   let setId = item.to.replace("/", "");
      //   this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
      //     id: setId,
      //     title: item.title,
      //     default: "N",
      //     tabIcon: item.tabIcon,
      //     link: item.to,
      //   });
      // }
    },
    // 원본
    // mixin_common_exportExcel() {
    //   var excelData = XLSX.utils.table_to_sheet(
    //     document.getElementById(this.table_id) //table id를 셋팅
    //   ); // table id를 넣어주면된다
    //   var workBook = XLSX.utils.book_new(); // 새 시트 생성

    //   // 시트이름 설정
    //   // 시트이름을 변경하고 싶은 경우 sheet_name 변수를 변경 하세요
    //   XLSX.utils.book_append_sheet(workBook, excelData, "nameData");
      
    //   if (this.sheet_name !== "") {
    //     XLSX.utils.book_append_sheet(workBook, excelData, this.sheet_name);
    //   }

    //   XLSX.writeFile(workBook, this.file_name + ".xlsx"); // 엑셀파일 만듬

    //   //엑셀 내보내기 완료 후 사용된 시트명이 있다면 초기화 한다
    //   if (this.sheet_name !== "") {
    //     this.sheet_name = "";
    //   }
    // },

    // 수정 (화면에서 시트이름을 설정하면 해당 시트이름으로 설정되고, 시트이름없으면 sheet1)
    mixin_common_exportExcel_raw_true() {
      var excelData = XLSX.utils.table_to_sheet(
        document.getElementById(this.table_id), {raw: true} //table id 세팅, 문자열세팅 (raw: true)
      ); // table id를 넣어주면된다
      
      // for(var key in excelData){
 
      //   var ecVal = excelData[key]["v"] + "";
 
      //   if(ecVal.indexOf(",") >= 0 ){
      //     ecVal = ecVal.replaceAll(",", "");
      //     excelData[key]["v"] = ecVal;
      //   }
       
      // }

      var workBook = XLSX.utils.book_new(); // 새 시트 생성

      // 시트이름 설정
      // 시트이름을 변경하고 싶은 경우 sheet_name 변수를 변경 하세요    
      if (this.sheet_name !== "") {
        XLSX.utils.book_append_sheet(workBook, excelData, this.sheet_name);
      }
      else{
        // 시트이름 설정하지 않으면 sheet1로 세팅
        XLSX.utils.book_append_sheet(workBook, excelData, "sheet1");
      }
      XLSX.writeFile(workBook, this.file_name + ".xlsx"); // 엑셀파일 만듬

      //엑셀 내보내기 완료 후 사용된 시트명이 있다면 초기화 한다
      if (this.sheet_name !== "") {
        this.sheet_name = "";
      }
    },
    mixin_common_exportExcel(eArr) {
      var excelData = XLSX.utils.table_to_sheet(
        document.getElementById(this.table_id), {raw: false} //table id 세팅, 문자열세팅 안함(raw: false)
      ); // table id를 넣어주면된다
 
      var string_excelData = XLSX.utils.table_to_sheet(
        document.getElementById(this.table_id), {raw: true} //table id 세팅, 문자열세팅 안함(raw: false)
      ); // table id를 넣어주면된다
      
      if(eArr != null){

        for(var i=0; i<Object.keys(eArr).length; i++){
          var tp = eArr[i];
          if(this.mixin_isEmpty(tp)){
            tp = "s";
          }
          excelData = this.mixin_format_column(excelData, string_excelData, i, tp);
          
        }
      }

      var workBook = XLSX.utils.book_new(); // 새 시트 생성

      // 시트이름 설정
      // 시트이름을 변경하고 싶은 경우 sheet_name 변수를 변경 하세요    
      if (this.sheet_name !== "") {
        XLSX.utils.book_append_sheet(workBook, excelData, this.sheet_name);
      }
      else{
        // 시트이름 설정하지 않으면 sheet1로 세팅
        XLSX.utils.book_append_sheet(workBook, excelData, "sheet1");
      }
      XLSX.writeFile(workBook, this.file_name + ".xlsx"); // 엑셀파일 만듬

      //엑셀 내보내기 완료 후 사용된 시트명이 있다면 초기화 한다
      if (this.sheet_name !== "") {
        this.sheet_name = "";
      }
    },
    mixin_format_column(worksheet, worksheet_string, col, tp){

      var range = XLSX.utils.decode_range(worksheet['!ref']);

      //skip header
      for(var row = range.s.r+1; row <= range.e.r; ++row){
        var ref = XLSX.utils.encode_cell({ r:row, c: col });

        //check
        // if(worksheet[ref] && worksheet[ref].t == 's'){
        //   //worksheet[ref].z = fmt
        //   worksheet[ref].s = worksheet_string[ref].s;
        // }
        if( !worksheet[ref] ) {
          continue;
        }
       
        var ecVal =  worksheet[ref]["v"]+"";
        //console.log(worksheet[ref]);
        //console.log(ecVal);
        if(!this.mixin_isEmpty(ecVal)){

          if(tp == "s"){
            worksheet[ref]["t"] = "s";
            worksheet[ref]["v"] = worksheet_string[ref]["v"];
            worksheet[ref]["z"] = "@";

          }else if(tp == "n"){
            var nEcVal = ecVal;
              if(ecVal.indexOf(",") >= 0 ){
                nEcVal = ecVal.replaceAll(",", "");
              }

              if( !this.mixin_isEmpty(nEcVal) && !Number.isNaN(nEcVal) ){
                worksheet[ref]["t"] = "n";
                worksheet[ref]["v"] = Number(nEcVal);
              }else{
                worksheet[ref]["t"] = "s";
                worksheet[ref]["v"] = worksheet_string[ref]["v"];
                worksheet[ref]["z"] = "";
              }

          }else if(tp == "d"){
            
          }else if(tp == "t"){  
              var eSplit = ecVal.split(":");
              if( eSplit.length > 2
                 && !Number.isNaN(eSplit[0])
                 && !Number.isNaN(eSplit[1])
                 && !Number.isNaN(eSplit[2])
                ) {
                var strV = Number(0);
                strV += ( Number(eSplit[0]) * 0.0416666666666667);
                strV += (Number(eSplit[1]) * 0.000694444444444444);
                strV += (Number(eSplit[2]) * 0.0000115740740740741);

                worksheet[ref]["t"] = "n";
                worksheet[ref]["v"] = strV;
                worksheet[ref]["z"] = "h:mm:ss";
                                  
              }else{
                worksheet[ref]["t"] = "s";
                worksheet[ref]["v"] = worksheet_string[ref]["v"];
                worksheet[ref]["z"] = "";
              }

          } 

          if(  (worksheet[ref]["v"]+"") == "NaN"){
            worksheet[ref]["t"] = "s";
            worksheet[ref]["v"] = worksheet_string[ref]["v"];
            worksheet[ref]["z"] = "";
             
          }
  
  
 
        }


      }

      return worksheet;

    },
    mixin_chat_exportExcel(history, filename) {
      let deleteTarget = [
          'TALK_USER_KEY', 'CUSTOMER_SEQ','MEMBER_YN','CUSTOMER_PHONE_NO','LINKS','TALK_STAT_NM','CALL_TYP_CD','PATH_TYPE'
          ,'BUSI_TYPE','ASP_SENDER_KEY','SNDRCV_CD','TYPE','FILE_KEY','IMAGE_URL','IMAGE_TALK_PATH','IS_READ','CNSL_DIV'
          ,'TALK_STAT_CD','TALK_SERIAL_NUMBER','ORG_CONT_ID','FILE_PATH','CUSTOMER_NM2','CUSTOMER_NM3','TALK_CONTACT_ID'
          ,'IT_PROCESSING2','VIDEO_URL','FILE_GROUP_KEY','ORDER_NUMBER','VIDEO_TALK_PATH','ORG_FILE_ID','SAVE_FILENAME'
          ,'VIDEO_THUMNAIL_PATH','SEND_USER_SHORT_NM','IT_PROCESSING','TALK_SENDER_KEY_NM','USER_ID'
      ];
      for(var i=0; i<history.length; i++){
        deleteTarget.forEach(e => delete history[i][e]);
      }

      var excelData = XLSX.utils.json_to_sheet(history, {skipHeader:false});
      XLSX.utils.sheet_add_aoa(excelData,[["채팅시간","수/발신 여부","채팅내용","상담사","고객명"]], {origin:"A1"});
      var workBook = XLSX.utils.book_new(); // 새 시트 생성

      XLSX.utils.book_append_sheet(workBook, excelData, "chatContent");

      const max_width = history.reduce((w, r) => Math.max(w, r.CONTENT.length), 10);
      excelData["!cols"] = [ { wch: 15 },{ wch: 15 },{ wch: 60 },{ wch: 15 },{ wch: 15 } ];

      XLSX.writeFile(workBook, filename + ".xlsx"); // 엑셀파일 생성 및 다운로드
    },
    mixin_common_exportExcel_template(
      file_name,
      sheet_name,
      sheet_header,
      sheet_data
    ) {
      //옵션 사용법은 https://www.npmjs.com/package/xlsx-color 참고

      var spreadsheet = XLSX.utils.book_new();
      this.excel_template_headers = sheet_header;
      var keys = Object.keys(sheet_header); //키를 가져옵니다. 이때, keys 는 반복가능한 객체가 됩니다.
      let title = [];
      for (var a = 0; a < keys.length; a++) {
        title.push(this.excel_template_headers[keys[a]]);
      }

      sheet_data.unshift(title); //타이틀 추가
      this.excel_template_bodys[sheet_name] = sheet_data;

      for (var sheet of Object.keys(this.excel_template_bodys)) {
        XLSX.utils.book_append_sheet(
          spreadsheet,
          XLSX.utils.aoa_to_sheet(this.excel_template_bodys[sheet_name]),
          sheet
        );
      }

      for (
        let i = 1;
        i <= this.excel_template_bodys[sheet_name][0].length;
        i++
      ) {
        var sheet_title = this.excel_template_sheet_header[i - 1] + "1";

        //첫번째 가로줄를 배경색 처리
        spreadsheet.Sheets[sheet_name][sheet_title].s = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFF00" },
          },
        };

        // 첫번째 세로줄를 배경색 처리
        // spreadsheet.Sheets[sheet_name]["A" + i].s = {
        //   fill: {
        //     patternType: "solid",
        //     fgColor: { rgb: "FFFF00" },
        //   },
        // };
      }

      XLSX.writeFile(spreadsheet, file_name + ".xlsx");
    },
    async common_getAspDataS(userId) {
      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      requestData.headers["URL"] = "api/code/common/code-book/aspinqry";
      requestData.headers["SERVICE"] = "code.common.code-book.sender";
      requestData.headers["METHOD"] = "aspinqry";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["ASP_USER_ID"] = userId;
      let response = await this.common_postCall_noPB(requestData);
      return response;
    },
    //페이지에서 사용하는 코드셋을 API로 호출
    async mixin_common_code_get_all(group_cd, use_yn) {
      var responseData = [];
      await api
        .post(
          "api/code/common/code-book/inqry",
          {
            GROUP_CD: group_cd,
            USE_YN: 'Y',
          },
          {
            head: {
              SERVICE: "code.common.code-book.inqry",
              METHOD: "inqry",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            for (var i = 0; i < group_cd.length; i++) {
              if (response.data[group_cd[i]]) {
                // if (response.data[key] !== undefined) {
                var key = group_cd[i];
                var jasonData = {};
                jasonData["group_cd"] = key;
                jasonData["group_value"] = response.data[key];
                responseData.push(jasonData);
                // }
              }
            }
          }
        })
        .catch((error) => {});
      // console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;
    },
    //페이지에서 호출되어 있는 코드셋에서 하나의 그룹코드를 필터하여 가져온다
    mixin_common_code_get(all_code, group_cd, strHeadText) {
      var responseData = [];

      if (strHeadText != "" && strHeadText != undefined) {
        responseData.push({ text: strHeadText, value: "" });
      }

      if (all_code && typeof all_code === "object") {
        for (var i = 0; i < all_code.length; i++) {
          if (all_code[i].group_cd === group_cd) {
            for (var a = 0; a < all_code[i].group_value.length; a++) {
              responseData.push({
                text: all_code[i].group_value[a].CD_NM,
                value: all_code[i].group_value[a].CD,
                info1: all_code[i].group_value[a].ETC_INFO01,
                info2: all_code[i].group_value[a].ETC_INFO02,
                info3: all_code[i].group_value[a].ETC_INFO03,
                info4: all_code[i].group_value[a].ETC_INFO04,
              });
            }
          }
        }
      }
      return responseData;
    },
    getAspAuth() {
      api
        .post(
          "api/palette-main/auth/inqire", //api url입력
          {
            userId: this.loginId,
          },
          {
            head: {
              SERVICE: "api.palette-main.auth",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          // console.log(response.data.DATA);
          this.ATRT_USER_GP = response.data.DATA;
        })
        .catch((err) => {
          alert(err);
        });
    },
    //페이지별 버튼권한 조회
    async mixin_common_button_auth(menu_id) {
      var responseData = [];
      await api
        .post(
          "api/setting/system/menu-author-manage/menu-button-author/inqire",
          {
            USER_ID: this.$store.getters["userStore/GE_USER_ID"],
            MENU_ID: menu_id,
            GROUP_CD: "TWB007", //고정코드(공통코드)
          }
        )
        .then((response) => {
          console.log(
            "#### [mixins][mixin_common_button_auth] response=",
            response
          );
        })
        .catch((error) => {});
      // console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;
    },
    //문의유형 코드 조회
    async mixin_inqry_code_get(asp_newcust_key, spst_inqry_typ_cd) {
      var responseData = [];
      await api
        .post("api/chat/setting/inqry-ty-manage/list", {
          ASP_NEWCUST_KEY: asp_newcust_key,
          SPST_INQRY_TYP_CD: spst_inqry_typ_cd,
          USE_YN: "Y", //고정코드
        })
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
          }
        })
        .catch((error) => {});
      // console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;
    },
    //상담유형 코드 조회
    async mixin_counsel_type_code_get(
      asp_newcust_key,
      spst_cnsl_typ_cd,
      strHeadText
    ) {
      var responseData = [];
      await api
        .post("api/setting/consulttype/manage/inqire", {
          ASP_NEWCUST_KEY: asp_newcust_key,
          SPST_CNSL_TYP_CD: spst_cnsl_typ_cd,
          USE_YN: "Y", //고정코드
        })
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];

            if (strHeadText != "" && strHeadText != undefined) {
              responseData.unshift({ CD_NM: strHeadText, CD: "" });
            }
          }
        })
        .catch((error) => {});
      // console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;
    },
    //상담유형 코드 조회
    async mixin_counsel_type_code_div_get(
          main_YN,
          asp_newcust_key,
          cnsl_div_cd,
          spst_cnsl_typ_cd,
          strHeadText
    ) {
      var responseData = [];
          await api
            .post("api/setting/consulttype/manage-info/inqire", {
              SUB_USE_YN: main_YN,
              ASP_NEWCUST_KEY: asp_newcust_key,
              CNSL_DIV_CD: cnsl_div_cd,
              SPST_CNSL_TYP_CD: spst_cnsl_typ_cd,
              USE_YN: "Y", //고정코드
            })
            .then((response) => {
              if (response.data) {
                responseData = response.data["DATA"];
    
                if (strHeadText != "" && strHeadText != undefined) {
                  responseData.unshift({ CD_NM: strHeadText, CD: "" });
                }
              }
            })
            .catch((error) => {});
        // console.log("#### [mixins][common_code] responseData=", responseData);
        return responseData;
      },
    //사용자소속 코드 조회
    async mixin_attr_get(asp_newcust_key, attr_cd, default_text) {
      var responseData = [];
      await api
        .post("api/palette/common/get-attr-div/list", {
          ASP_NEWCUST_KEY: asp_newcust_key,
          ATTR_CD: attr_cd,
          ATTR_DIV_CD: "", //고정코드
        })
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
            if (
              default_text != null &&
              default_text != "" &&
              default_text != undefined
            ) {
              let default_obj = {
                CD_NM: default_text,
                CD: "",
              };
              responseData.splice(0, 0, default_obj);
            }
          }
        })
        .catch((error) => {});
      // console.log("#### [mixins][common_code] responseData=", responseData);
      return responseData;
    },
    //API 파라미터 에러 체크
    mixin_check_repsonse(response) {
      let return_data = true;
      let return_msg = "";
      // console.log("#### [mixin_check_repsonse] response=", response);
      // console.log(
      //   "#### [mixin_check_repsonse] response.error.flag=",
      //   response.error.flag
      // );

      // 원본
      // if (response.error.flag && response.error.flag === true) {
      //   return_data = false;
      //   this.common_toast(response.error.msg, "error");
      // }
      // return return_data;

      // 수정
      // 비밀번호 만료인 경우 toast 메시지 보여주지 않는다. (alert 처리)
      // Expired 리턴한다.
      if (response.error.flag === true && [ "Expired", "LOGGED_IN_ID", "LOGGED_IN_IP" ].includes(response.error.msg)) { //  response.error.msg == "Expired"
        // return_msg = "Expired";
        return response.error.msg;
      }
      else if (response.error.flag && response.error.flag === true) {
        return_data = false;
        this.common_toast(response.error.msg, "error");
      }
      return return_data;
    },

    //채팅채널 조회
    async mixin_channel_get(asp_cust_key) {
      // console.log("#### [mixin_channel_get] asp_cust_key=", asp_cust_key);
      var responseData = [];
      await api
        .post(
          "api/chat/main/sender-key/inqire", //api url입력
          {
            ASP_NEWCUST_KEY: asp_cust_key,
          },
          {
            head: {
              SERVICE: "code.common.code-book",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          // console.log(response.data.DATA);
          responseData = response.data["DATA"];
        })
        .catch((err) => {
          alert(err);
        });

      return responseData;
    },
    //모든 기업 계정관리의 ASP_CUST를 가져와 시스템 관리자의 콤보 박스로 사용
    async mixin_getAllAspData() {
      // console.log("#### [mixin_getAllAspData]");
      var responseData = [];
      await api
        .post(
          "/api/code/common/code-book/allaspinqry", //api url입력
          {},
          {
            head: {
              SERVICE: "code.common.code-book",
              METHOD: "allaspinqry",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          // console.log(response.data.DATA);
          responseData = response.data["DATA"];
        })
        .catch((err) => {
          alert(err);
        });

      return responseData;
    },
    //사용자 권한 콤보박스 생성
    async mixin_user_auth_get(default_text) {
      var responseData = [];
      await api
        .post(
          "api/palette-main/auth/inqire", //api url입력
          {
            userId: this.user_id,
          },
          {
            head: {
              SERVICE: "api.palette-main.auth",
              METHOD: "inqire",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          // console.log(response.data.DATA);
          responseData = response.data["DATA"];
          if (
            default_text != null &&
            default_text != "" &&
            default_text != undefined
          ) {
            let default_obj = {
              NM: default_text,
              CD: "",
            };
            responseData.splice(0, 0, default_obj);
          }
        })
        .catch((err) => {
          alert(err);
        });

      return responseData;
    },
    /**
     * 공통 toast
     * toast메시지와 타입을 전달하여 사용한다.
     * 예시) this.common_toast("정상적으로 처리되었습니다.","sucess");
     * @param message : toast메시지
     * @param iconType : success,indo,warn,error,''(default)
     */
    common_toast(message, type) {
      let set_class = "";
      let set_icon = "";
      switch (type) {
        case "success":
          set_class = "jh-toast-success";
          set_icon = "jh-icon-toast-success";
          break;
        case "info":
          set_class = "jh-toast-info";
          set_icon = "jh-icon-toast-info";
          break;
        case "warn":
          set_class = "jh-toast-warning";
          set_icon = "jh-icon-toast-warning";
          break;
        case "error":
          set_class = "jh-toast-error";
          set_icon = "jh-icon-toast-error";
          break;
        case "done":
          set_class = "jh-toast-done";
          set_icon = "jh-icon-toast-success";
          break;
        default:
          set_class = "";
          set_icon = "";
          break;
      }
      this.$store.commit("toastStore/POP", {
        act: true,
        msg: message,
        class: set_class,
        hasToastIcon: true,
        icon: set_icon,
      });
    },
    /**
     * 공통 alert
     * 출력메시지와 아이콘 타입을 전달하여 사용한다.
     * 예시) this.common_alert("확인?"); //iconType 생략시 done으로 사용
     * @param message : 출력메시지
     * @param iconType : done,noti,error,chk
     */
    common_alert(message, iconType) {
      let iconClass = "";
      switch (iconType) {
        case "done":
          iconClass = "jh-alert-icon is-success";
          break;
        case "noti":
          iconClass = "jh-alert-icon is-warning";
          break;
        case "error":
          iconClass = "jh-alert-icon is-error";
          break;
        case "chk":
          iconClass = "jh-alert-icon is-info";
          break;
        default:
          iconClass = "jh-alert-icon is-info";
          break;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: message,
        iconClass: iconClass,
        type: "default",
      });
    },
    /**
     * 공통 confirm
     * 출력메시지와 아이콘 타입을 전달하여 사용한다.
     * callYes, callNo 함수를 별도로 구현하여 callBack형태로 넘겨준다.
     * 예시) this.common_confirm(msg, this.saveCardYesCall, num, null, null, "error");
     * @param message : string
     * @param callYes : function
     * @param callYesArgs : any
     * @param callNo : function
     * @param callNoArgs : any
     * @param iconType : done,noti,error,chk
     */
    common_confirm(
      message,
      callYes,
      callYesArgs,
      callNo,
      callNoArgs,
      iconType
    ) {
      let iconClass = "";
      switch (iconType) {
        case "done":
          iconClass = "jh-alert-icon is-success";
          break;
        case "noti":
          iconClass = "jh-alert-icon is-warning";
          break;
        case "error":
          iconClass = "jh-alert-icon is-error";
          break;
        case "chk":
          iconClass = "jh-alert-icon is-info";
          break;
        default:
          iconClass = "jh-alert-icon is-info";
          break;
      }

      const parentVue = this;
      const parentCallYes = callYes;
      const parentCallYesArgs = callYesArgs;
      const parentCallNo = callNo;
      const parentCallNoArgs = callNoArgs;
      console.log("##### [common_confirm] parentCallYes=", parentCallYes);
      console.log(
        "##### [common_confirm] parentCallYesArgs=",
        parentCallYesArgs
      );
      console.log("##### [common_confirm] parentCallNo=", parentCallNo);
      console.log("##### [common_confirm] parentCallNoArgs=", parentCallNoArgs);
      console.log("##### [common_confirm] message=", message);
      console.log("##### [common_confirm] iconClass=", iconClass);

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: message,
        iconClass: iconClass,
        type: "confirm",
        callYes: function () {
          parentVue.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: false,
          });
          if (typeof parentCallYes == "function") {
            parentCallYes(parentCallYesArgs);
          }
        },
        callNo: function () {
          parentVue.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: false,
          });
          if (typeof parentCallNo == "function") {
            parentCallNo(parentCallNoArgs);
          }
        },
      });
    },

    // 비밀번호 만료  confirm
    common_confirmPwd(
      message,
      callYes,
      callYesArgs,
      iconType
    ) {
      let iconClass = "";
      switch (iconType) {
        case "done":
          iconClass = "jh-alert-icon is-success";
          break;
        case "noti":
          iconClass = "jh-alert-icon is-warning";
          break;
        case "error":
          iconClass = "jh-alert-icon is-error";
          break;
        case "chk":
          iconClass = "jh-alert-icon is-info";
          break;
        default:
          iconClass = "jh-alert-icon is-info";
          break;
      }

      const parentVue = this;
      const parentCallYes = callYes;
      const parentCallYesArgs = callYesArgs;

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: message,
        iconClass: iconClass,
        type: "confirmPwd",
        callYes: function () {
          parentVue.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: false,
          });
          if (typeof parentCallYes == "function") {
            parentCallYes(parentCallYesArgs);
          }
        },
      });
    },

    // 캘린더/상장책자 미처리 건  confirm
    common_confirmAplyWork(
      message,
      callYes,
      callYesArgs,
      iconType
    ) {
      let iconClass = "";
      switch (iconType) {
        case "done":
          iconClass = "jh-alert-icon is-success";
          break;
        case "noti":
          iconClass = "jh-alert-icon is-warning";
          break;
        case "error":
          iconClass = "jh-alert-icon is-error";
          break;
        case "chk":
          iconClass = "jh-alert-icon is-info";
          break;
        default:
          iconClass = "jh-alert-icon is-info";
          break;
      }

      const parentVue = this;
      const parentCallYes = callYes;
      const parentCallYesArgs = callYesArgs;

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: message,
        iconClass: iconClass,
        type: "confirmAplyWork",
        callYes: function () {
          parentVue.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: false,
          });
          if (typeof parentCallYes == "function") {
            parentCallYes(parentCallYesArgs);
          }
        },
      });
    },

    // 상담이력저장 confirm
    common_confirmHistRegist(
      message,
      callYes,
      callYesArgs,
      callNo,
      callNoArgs,
      iconType
    ) {
      let iconClass = "";
      switch (iconType) {
        case "done":
          iconClass = "jh-alert-icon is-success";
          break;
        case "noti":
          iconClass = "jh-alert-icon is-warning";
          break;
        case "error":
          iconClass = "jh-alert-icon is-error";
          break;
        case "chk":
          iconClass = "jh-alert-icon is-info";
          break;
        default:
          iconClass = "jh-alert-icon is-info";
          break;
      }

      const parentVue = this;
      const parentCallYes = callYes;
      const parentCallYesArgs = callYesArgs;
      const parentCallNo = callNo;
      const parentCallNoArgs = callNoArgs;

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: message,
        iconClass: iconClass,
        type: "confirmHistRegist",
        callYes: function () {
          parentVue.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: false,
          });
          if (typeof parentCallYes == "function") {
            parentCallYes(parentCallYesArgs);
          }
        },
        callNo: function () {
          parentVue.$store.commit("alertStore/openAlertDialog", {
            alertDialogToggle: false,
          });
          if (typeof parentCallNo == "function") {
            parentCallNo(parentCallNoArgs);
          }
        },
      });
    },


    /**
     * 공통 postCall
     * requestData를 선언하여 헤더와 sendData를 세팅 후 전달하면 결과를 리턴해준다.
     * 함수 선언시 async 사용.
     * ex)
     * async getList(){
     *     requestData = {
     *             headers: {},
     *             sendData:{},
     *           };
     *     // header 세팅
     *     requestData.headers["URL"] = "/api/chat/setting/message-manage/rcept-delay/inqire";
     *     requestData.headers["SERVICE"] = "chat.setting.message-manage.rcept-delay";
     *     requestData.headers["METHOD"] = "inqire";
     *     requestData.headers["TYPE"] = "BIZ_SERVICE";
     *     requestData.headers["ASYNC"] = false;
     *
     *     // sendData 세팅
     *     requestData.sendData["MSG_CL"] = this.aspNewcustKey;
     *     requestData.sendData["SYSTEM_MSG_ID"] = this.aspNewcustKey;
     *     requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
     *
     *     // 결과 리턴
     *     let response = await this.common_postCall_noPB(requestData, false);
     *     console.log(response);
     * }
     *
     * @param requestData
     * @returns {Promise<{}>}
     */

    async common_postCall(requestData, isLoadingbar) {
      if (
        typeof isLoadingbar === "undefined" ||
        typeof isLoadingbar !== "boolean"
      ) {
        isLoadingbar = true;
      }
      if (isLoadingbar) {
        await this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", true);
      }

      let headers = _.cloneDeep(requestData.headers);
      let responseData = {};
      let requestUrl = headers.URL;
      // HEADER[URL] 중복 제거
      delete headers.URL;

      await api
        .post(
          requestUrl, //api url입력
          requestData.sendData,
          { head: headers }
        ) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
        .then((response) => {
          if (isLoadingbar) {
            this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
          }
          // if(response.data.HEADER.TR_STATUS == "TR_ERROR"){
          //   this.common_alert("원장 데이터 수신 불가. [IT 담당자 문의요망]", "error");
          // }
          // let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\n") : null;
          // let errorMsg = _.find(errorMessages, msg => {
          //   console.log("errorMsg ", errorMsg)
          // // return _.includes(msg, "에러 메시지: ");
          // });

          responseData = response.data;

          if(responseData.HEADER.TR_STATUS == "TR_ERROR"){
            if (this.mixin_isEmpty(responseData) || responseData.HEADER.ERR_FLAG || (responseData.DATA && responseData.DATA.length > 0 && responseData.DATA[0].TrxReslt != "0000")) {

              let errorMessages = responseData.HEADER.ERR_MSG ? responseData.HEADER.ERR_MSG.split("\\n") : null;
              let errorMsg = _.find(errorMessages, msg => {
                return _.includes(msg, "에러 메시지: ");
              });

              let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
              if(msg == "ct error"){
                this.common_alert("원장 데이터 수신 불가. [IT 담당자 문의요망]", "error");
              }else{
                this.common_alert(msg, "error");
              }
              // return false;
            }
          }

          // string으로 넘어올 경우 에러임.
          /*
          if (typeof response.data === "string") {
            try{
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.common_alert(responseData.HEADER.ERROR_MSG, "error");
            }catch (e) {
              alert(response.data);
            }
          } else {
            // HEADER.ERROR_FLAG 가 true일 경우 에러.
            if (response.data.HEADER.ERROR_FLAG) {
              this.common_alert(response.data.HEADER.ERROR_MSG, "error");
            }
          }
           */
          // 공통 에러 체크로 변경
          // this.mixin_check_repsonse(response);
          // responseData = response.data;
        }) //api 호출 성공 이후 수행
        .catch((err) => {
          if (isLoadingbar) {
            this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
          }
          let msg = "시스템에 오류가 발생하였습니다.";
          if (err && err.response && err.response.data && err.response.data.error) msg = err.response.data.error;
          if (err?.response?.data?.status != 401) this.common_alert(msg, "error");
        }); //api호출 에러 작업 수행
      return responseData;
      //결과값 보여주기
    },

        /**
     * 공통 postCall - PROGRESS_BAR 제거 
     * 
     * 기본적으로 공통 postCall과 완전 동일하지만 
     * 한 페이지 내에서 postCall을 여러개 불러올 경우 
     * PROGRESS_BAR때문에 화면이 깜빡거리게 되는경우가 생김
     * 이를 막기위해 이 코드를 사용
     * 
     * @param requestData
     * @returns {Promise<{}>}
     */

         async common_getCall_noPB(requestData, isLoadingbar) {
          if (
            typeof isLoadingbar === "undefined" ||
            typeof isLoadingbar !== "boolean"
          ) {
            isLoadingbar = true;
          }
          if (isLoadingbar) {
            //await this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", true);
          }
          let responseData = {};
          let requestUrl = requestData.headers.URL;
          // HEADER[URL] 중복 제거
          delete requestData.headers.URL;
    
          await api
            .get(
              requestUrl, //api url입력
              requestData.sendData,
              { head: requestData.headers }
            ) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
            .then((response) => {
              if (isLoadingbar) {
                //this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
              }
              // string으로 넘어올 경우 에러임.
              /*
              if (typeof response.data === "string") {
                try{
                  let responseData = JSON.parse(response.data.replaceAll("\n", ""));
                  this.common_alert(responseData.HEADER.ERROR_MSG, "error");
                }catch (e) {
                  alert(response.data);
                }
              } else {
                // HEADER.ERROR_FLAG 가 true일 경우 에러.
                if (response.data.HEADER.ERROR_FLAG) {
                  this.common_alert(response.data.HEADER.ERROR_MSG, "error");
                }
              }
               */
              // 공통 에러 체크로 변경
              // this.mixin_check_repsonse(response);
              responseData = response.data;
            }) //api 호출 성공 이후 수행
            .catch((err) => {
              if (isLoadingbar) {
                this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
              }
              let msg = "시스템에 오류가 발생하였습니다.";
              if (err && err.response && err.response.data && err.response.data.error) msg = err.response.data.error;
              this.common_alert(msg, "error");
            }); //api호출 에러 작업 수행
          return responseData;
          //결과값 보여주기
        },
    /**
     * 공통 postCall - PROGRESS_BAR 제거 
     * 
     * 기본적으로 공통 postCall과 완전 동일하지만 
     * 한 페이지 내에서 postCall을 여러개 불러올 경우 
     * PROGRESS_BAR때문에 화면이 깜빡거리게 되는경우가 생김
     * 이를 막기위해 이 코드를 사용
     * 
     * @param requestData
     * @returns {Promise<{}>}
     */

     async common_postCall_noPB(requestData, isLoadingbar) {
      if (
        typeof isLoadingbar === "undefined" ||
        typeof isLoadingbar !== "boolean"
      ) {
        isLoadingbar = true;
      }
      if (isLoadingbar) {
        //await this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", true);
      }

      let headers = _.cloneDeep(requestData.headers);
      let responseData = {};
      let requestUrl = headers.URL;
      // HEADER[URL] 중복 제거
      delete headers.URL;

      await api
        .post(
          requestUrl, //api url입력
          requestData.sendData,
          { head: headers }
        ) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
        .then((response) => {
          if (isLoadingbar) {
            //this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
          }
          responseData = response.data;

          if(requestUrl == "/api/sintong/sintongSearch"){
            if (this.mixin_isEmpty(responseData) || responseData.HEADER.ERROR_FLAG || (responseData.DATA && responseData.DATA.length > 0 && responseData.DATA[0].TrxReslt != "0000")) {

              let errorMessages = responseData.HEADER.ERROR_MSG ? responseData.HEADER.ERROR_MSG.split("\n") : null;
              let errorMsg = _.find(errorMessages, msg => {
                return _.includes(msg, "에러 메시지: ");
              });

              let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
              if(msg == "ct error"){
                this.common_alert("원장 데이터 수신 불가. [IT 담당자 문의요망]", "error");
              }else{
                this.common_alert(msg, "error");
              }             
               // return false;
            }
          }






          // string으로 넘어올 경우 에러임.
          /*
          if (typeof response.data === "string") {
            try{
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.common_alert(responseData.HEADER.ERROR_MSG, "error");
            }catch (e) {
              alert(response.data);
            }
          } else {
            // HEADER.ERROR_FLAG 가 true일 경우 에러.
            if (response.data.HEADER.ERROR_FLAG) {
              this.common_alert(response.data.HEADER.ERROR_MSG, "error");
            }
          }
           */
          // 공통 에러 체크로 변경
          // this.mixin_check_repsonse(response);
        }) //api 호출 성공 이후 수행
        .catch((err) => {
          if (isLoadingbar) {
            this.$store.dispatch("commonStore/AC_COMMON_PROGRESS_BAR", false);
          }
          let msg = "시스템에 오류가 발생하였습니다.";
          if (err && err.response && err.response.data && err.response.data.error) msg = err.response.data.error;
          this.common_alert(msg, "error");
        }); //api호출 에러 작업 수행
      return responseData;
      //결과값 보여주기
    },

    // 업무에 따른 고유키정보를 가져온다.
    // DB 내부에서 채번된 시퀀스값(YYYYMMDDHHMISSMSC + 업무구분(대문자4자리) + 00000)
    async mixin_common_bizSeq_get(BIZ_CASE) {
      var responseData = "";
      await api
        .post("/api/innb/common/generate-unique-no/inqry", {
          BIZ_CASE: BIZ_CASE,
        })
        .then((response) => {
          //console.log(
          //    "#### [mixins][mixin_common_bizSeq_get] response=",
          //    response
          //);
          responseData = response.data.DATA[0]["RET_VAL"];
        })
        .catch((error) => {});
      return responseData;
    },
    //LMS 컨텐츠분류코드 조회
    async mixin_getDivCodeData(strCode) {
      let responseData = [];

      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/category/manage/div-cd/list";
      requestData.headers["SERVICE"] = "km.category.manage.div-cd";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["SPST_DIV_ID"] = strCode;

      // 결과 리턴
      let response = await this.common_postCall_noPB(requestData);

      if (!response.HEADER.ERROR_FLAG) {
        if (response[strCode]) {
          var key = strCode;
          var jasonData = {};
          jasonData["group_cd"] = key;
          jasonData["group_value"] = response[key];
          responseData.push(jasonData);
        }
      }

      return responseData;
    },

    restoreXSS2(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      if (strText.length <= 3) {
        return strText;
      }

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      // strText = strText.replaceAll("&amp;", "&");
      //      strText = strText.replaceAll("&lt;","&#60;");
      //      strText = strText.replaceAll("&gt;","&#62;");
      //      strText = strText.replaceAll("&lt;","<");
      //      strText = strText.replaceAll("&gt;",">");
      // strText = strText.replaceAll("&lt;", "&lt;");
      // strText = strText.replaceAll("&gt;", "&gt;");
      //트리에서 스크립트 사용시 문제되서 주석처리함
      // strText = strText.replaceAll("<","&lt;");
      // strText = strText.replaceAll(">","&gt;");
      // strText = strText.replaceAll("&#40;", "(");
      // strText = strText.replaceAll("&#41;", ")");
      // strText = strText.replaceAll("&apos;", "'");
      // strText = strText.replaceAll("&#91;", "[");
      // strText = strText.replaceAll("&#93;", "]");
      // strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("<BR>", "\r");
      strText = strText.replaceAll("</BR>", "\r");
      strText = strText.replaceAll("</br>", "\r");
      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");
      strText = strText.replaceAll("&#40;", "(");
      strText = strText.replaceAll("&#41;", ")");
      strText = strText.replaceAll("&#91;", "[");
      strText = strText.replaceAll("&#93;", "]");
      strText = strText.replaceAll("&amp;", "&");
      strText = strText.replaceAll("&quot;", '"');
      strText = strText.replaceAll("&apos;", "'");
      strText = strText.replaceAll("<br>", "\r");
      strText = strText.replaceAll("<p>", "\n");

      return strText;
    },
    restoreXSS_CKeditorDec(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");
      strText = strText.replaceAll(/\n/g, "");
      strText = strText.replaceAll(/\t/g, "");

      return strText;
    },
    restoreXSS_CKeditor(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = strText.replaceAll("&lt;", "<");
      strText = strText.replaceAll("&gt;", ">");

      return strText;
    },
    restoreXSS_Dec(strText) {
      //값존재유무 체크
      if (strText === "" || strText == null) {
        return strText;
      }
      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리
      // 이부분이 왜 걸려잇는지 모르겠음...테스트 후 길이체크는 막을 예정..
      // if(strText.length <= 3){return strText;}

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = strText.replaceAll("(", "&#40;");
      strText = strText.replaceAll(")", "&#41;");
      strText = strText.replaceAll("[", "&#91;");
      strText = strText.replaceAll("]", "&#93;");
      strText = strText.replaceAll("&", "&amp;");
      strText = strText.replaceAll('"', "&quot;");
      strText = strText.replaceAll("'", "&apos;");
      strText = strText.replaceAll("\r", "<br>");
      strText = strText.replaceAll("\n", "<p>");
      strText = strText.replaceAll("<", "&lt;");
      strText = strText.replaceAll(">", "&gt;");
      return strText;
    },
    //data-table 숫자 합계구하기
    //data_list : 데이터 리스트(JSON ARRAY)
    //key: 데이터 리스트의(sum을 구할 data_list에 포함된 key값명)
    mixin_sum_field(data_list, key) {
      //소수점 더하기일때 이상한 값을때를 위해 패치(toFixed)
      let sum = data_list.reduce(
        (a, b) => +(a + Number(b[key]) || 0).toFixed(12),
        0
      );
      return sum;
    },
    //data-table time 합계구하기
    //data_list : 데이터 리스트(JSON ARRAY)
    //key: 데이터 리스트의(sum을 구할 data_list에 포함된 key값명)
    //string(HH:MM:ss) 데이터를 초로 변환 합계 후 다시 string(HH:MM:ss) 값으로 변환
    mixin_date_sum_field(data_list, key) {
      let sum = 0;
      for (let i = 0; i < data_list.length; i++) {
        if (data_list[i][key] !== "0") {
          sum =
            sum +
            data_list[i][key]
              .split(":")
              .reverse()
              .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
        }
      }

      let return_data = "00:00:00";
      if (sum !== 0) {
        return_data = this.mixin_seconds_toHHMMSS(sum);
      }

      return return_data;
    },
    //평균 건수를 구한다(소수점 한자리)
    mixin_avg_field(data_list, total_key, base_key) {
      //소수점 더하기일때 이상한 값을때를 위해 패치(toFixed)
      let total_sum = data_list.reduce(
        (a, b) => +(a + Number(b[total_key]) || 0).toFixed(12),
        0
      );

      let base_sum = data_list.reduce(
        (a, b) => +(a + Number(b[base_key]) || 0).toFixed(12),
        0
      );

      let avg = 0;
      if (total_sum !== 0 && base_sum !== 0) {
        avg = ((total_sum / base_sum) * 100).toFixed(1);
      }

      return avg;
    },
    //시간 데이터를 가지고 평균 시간을 구한다
    //총시간 / 데이터 건수 (단, 0인건은 데이터 건수에 포함하지 않는다)
    mixin_date_avg_field(data_list, key) {
      let sum = 0;
      let avg = 0;
      let count = 0; //시간이 0인건은 포함시키지 않는다
      for (let i = 0; i < data_list.length; i++) {
        if (data_list[i][key] !== "0" && data_list[i][key] !== "00:00:00") {
          sum =
            sum +
            data_list[i][key]
              .split(":")
              .reverse()
              .reduce((prev, curr, i) => prev + curr * Math.pow(60, i), 0);
          count++;
        }
      }

      let return_data = "00:00:00";

      //평균시간을 구한다
      if (sum !== 0 && count !== 0) {
        avg = (sum / count).toFixed(0);
      }

      //HH:MM:SS 표기로 변환 한다
      if (sum !== 0 && count !== 0) {
        return_data = this.mixin_seconds_toHHMMSS(avg);
      }

      return return_data;
    },
    //seconds를 HH:MM:ss로 변환한다
    mixin_seconds_toHHMMSS(secs) {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return (
        [hours, minutes, seconds]
          .map((v) => (v < 10 ? "0" + v : v))
          // .filter((v, i) => v !== "00" || i > 0)
          .join(":")
      );
    },
    mixin_accNoFormat(accNo){
      if (!accNo) return accNo;

      //공백제거
      accNo = accNo.replace(/\s/gi, "");

      var fAccNo = accNo.substring(0,1);
      if(fAccNo < 2) {
        accNo = accNo.replace(/(\d{3})(\d{2})(\d{6})/, '$1-$2-$3');
        return accNo;
      } else if (fAccNo > 1) {
        accNo = accNo.replace(/(\d{9})(\d{2})/, '$1-$2');
        return accNo;
      } else {
        return accNo;
      }
    },
    mixin_juminNoFormat(csNum){
      if (!csNum) return csNum;

      //공백제거
      csNum = csNum.replace(/\s/gi, "");

      if (csNum.length == 13) {
        csNum = csNum.replace(/(\d{6})(\d{1})(\d{6})/, '$1-$2******');
      }else if (csNum.length == 10) {
        csNum = csNum.replace(/(\d{6})(\d{4})/, '$1-****');
      }

      return csNum;
    },
    mixin_phoneFormat(phoneNumber){
      if (!phoneNumber) return phoneNumber;

      //공백제거
      phoneNumber = phoneNumber.replace(/\s/gi, "");

      if (phoneNumber.length == 11) {
        phoneNumber = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        return phoneNumber;
      } 
      else if (phoneNumber.length == 10) {
        phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        return phoneNumber;
      } 
      else if (phoneNumber.length == 9) {
        phoneNumber = phoneNumber.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
        return phoneNumber;
      } 
      else {
        return phoneNumber;
      }
    },
    mixin_telNumFormat(phoneNumber){
      if (!phoneNumber) return phoneNumber;

      //공백제거
      phoneNumber = phoneNumber.replace(/\s/gi, "");

      let gbn = '';

      //서울
      if(phoneNumber.substring(0,2) == '02') gbn = '01';

      if(gbn == '01') {
        if (phoneNumber.length == 10) {
          phoneNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
          return phoneNumber;
        } 
        else if (phoneNumber.length == 9) {
          phoneNumber = phoneNumber.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
          return phoneNumber;
        } else {
          return phoneNumber;
        }
      }
      else if (phoneNumber.length == 11) {
        phoneNumber = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        return phoneNumber;
      } 
      else if (phoneNumber.length == 10) {
        phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        return phoneNumber;
      } 
      else {
        return phoneNumber;
      }
    },
    mixin_timeFormat(time) {
      var newTime = '';
      if (!time) return time;

      time = time.replaceAll(" ", "").replaceAll("/", "").replaceAll(":", "").replaceAll("-", "");

      if (time.length == 6) {
        newTime = time.substring(0, 2) + ":" + time.substring(2, 4) + ":" + time.substring(4, 6);
        return newTime;
      } else {
        //추후 6자리 외의 데이터가 있을 경우 수정
        return time;
      }
    },    
    mixin_dateFormat(date) {
      //날짜 포멧
      if (!date) return date;
      var newDate = "";
      //공백제거
      date = date.replaceAll(" ", "").replaceAll("/", "").replaceAll(":", "").replaceAll("-", "");

      if (date.length == 8) {
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        return newDate;
      } else if (date.length >= 14) {
        date = date.substring(0,14);
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5:$6');
        return newDate;
      } 
      else {
        return date;
      }
    },
    /**
     * 서버 시간을 포맷에 맞춰 반환한다.
     * ex)
     * let currentDate = await this.mixin_getSvrDate("YYYYMMDD");
     * console.log(currentDate);
     * @param strFormat
     * @returns {Promise<{}|*>}
     */
    async mixin_getSvrDate(strFormat) {
      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      requestData.headers["URL"] = "/api/date/common/current-time/inqry";
      requestData.headers["SERVICE"] = "date.common.current-time";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["FORMAT01"] = strFormat;
      let response = await this.common_postCall_noPB(requestData);
      if (!response.HEADER.ERROR_FLAG) {
        let resultData = response.DATA[0].RET_VAL;
        return resultData;
      } else {
        return response;
      }
    },
	  /**
     * 서버 시간을 포맷에 맞춰 반환한다.
     * ex)
     * let currentDate = await this.mixin_getSvrDateNa("YYYYMMDD");
     * console.log(currentDate);
     * @param strFormat
     * @returns {Promise<{}|*>}
     */
   async mixin_getSvrDateNa(strFormat) {
    let requestData = {
      headers: {},
      sendData: {},
    };
    // header 세팅
    requestData.headers["URL"] = "/api/date/common/current-time/inqryNa";
    requestData.headers["SERVICE"] = "date.common.current-time";
    requestData.headers["METHOD"] = "inqry";
    requestData.headers["TYPE"] = "BIZ_SERVICE";
    requestData.headers["ASYNC"] = false;

    // sendData 세팅
    requestData.sendData["FORMAT01"] = strFormat;
    let response = await this.common_postCall_noPB(requestData);
    if (!response.HEADER.ERROR_FLAG) {
      let resultData = response.DATA[0].RET_VAL;
      return resultData;
    } else {
      return response;
    }
  },
    /**
     * 서버 시간을 포맷에 맞춰 오늘날짜, 3일전, 7주일전, 1달전, 3달전 반환한다.
     * ex)
     * let currentDate = await this.mixin_getSvrDate("YYYYMMDD");
     * console.log(currentDate);
     * @param strFormat
     * @returns {Promise<{}|*>}
     */
    async mixin_getSvrPrevDate(strFormat) {
      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      requestData.headers["URL"] = "/api/date/common/prev-date/inqry";
      requestData.headers["SERVICE"] = "date.common.prev-date";
      requestData.headers["METHOD"] = "inqry";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["FORMAT01"] = strFormat;
      requestData.sendData["BASE_DATE"] = strFormat;
      let response = await this.common_postCall_noPB(requestData);
      if (!response.HEADER.ERROR_FLAG) {
        let resultData = response.DATA[0];
        return resultData;
      } else {
        return response;
      }
    },

    /**
     * 자리수 만큼 문자열 채워준다.
     * @param {String} strVal 베이스 문자열.
     * @param {int} intLen 리턴 문자열 자리수.
     * @param {String} strExtVal 문자열 자리수에 맞춰 채워넣을 문자열.
     * @param {String} strDirection 문자열 채울 방향 'L', 'R'
     * @return {String} strRetVal 반환되는 padding 문자열
     * @author MPC R&D Team
     */
    mixin_setTxtPad(strVal, intLen, strExtVal, strDirection) {
      if (typeof strVal === "undefined") {
        return "";
      }
      if (typeof strVal !== "string") {
        strVal = strVal.toString();
      }
      if (typeof intLen === "undefined") {
        intLen = 0;
      }
      if (typeof strExtVal === "undefined") {
        return strVal;
      }
      if (typeof strDirection === "undefined") {
        strDirection = "L";
      }
      if (strVal.length >= intLen) {
        return strVal;
      }

      var strRetVal = strVal;
      intLen = intLen - strVal.length;
      for (var i = 0; i < intLen; i++) {
        if (strDirection === "L") {
          strRetVal = strExtVal + strRetVal;
        } else {
          strRetVal = strRetVal + strExtVal;
        }
      }

      return strRetVal;
    },
    mixin_rpad: function (strVal, intLen, strExtVal) {
      return this.mixin_setTxtPad(strVal, intLen, strExtVal, "L");
    },
    mixin_lpad: function (str, _length, ch_str) {
      return this.mixin_setTxtPad(strVal, intLen, strExtVal, "R");
    },
    /**
     * 빈값 체크
     * @param {any} val
     * @returns {boolean}
     */
    mixin_isEmpty(val) {
      if (val == null) return true;
      if (typeof val === "undefined") return true;
      if (typeof val === "string" && val === "" && "" + val === "") return true;
      if (Array.isArray(val) && val.length < 1) return true;
      if (
        typeof val === "object" &&
        val.constructor.name === "Object" &&
        Object.keys(val).length < 1
      )
        return true;
      if (
        typeof val === "object" &&
        val.constructor.name === "String" &&
        Object.keys(val).length < 1
      )
        return true;
    },
    /**
     * isEmpty 체크 후 true일 경우 defaultVal 리턴
     * @param {any} val
     * @param {any} defaultVal
     * @returns {any}
     */
    mixin_nvl(val, defaultVal) {
      return this.mixin_isEmpty(val) ? defaultVal : val;
    },
    async requestDownloadApi(requestObj) {
      // _.merge - Lodash 라이브러리
      let reqHeaders = requestObj.headers;
      const token = window.sessionStorage.getItem("token");
      // request.headers.token = token;
      //resuest header에 전달받은 accessToken을 Bearer 형식으로 전송
      reqHeaders.Authorization = "bearer " + token;
      let form = new FormData();

      // console.log("reqHeaders ", reqHeaders)

      for (var strKey in requestObj.data) {
        form.append(strKey, requestObj.data[strKey]);
      }

      return axios({
        url: process.env.VUE_APP_API_URL + requestObj.url,
        headers: reqHeaders,
        responseType: "arraybuffer",
        method: "post",
        data: form,
      })
        .then((res) => {
          try {
            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            console.log("Res>>>>>");
            console.log(res);

            // 파일 존재 여부 판단 (파일이름이 없는 경우 파일이 존재하지 않는것으로 판단)
            let checkFileName = res.headers["content-disposition"];
            
            if(checkFileName == "" || checkFileName == null){
              let data = JSON.parse(new TextDecoder("utf-8").decode(res.data).replaceAll("\\", "\\\\"));
              if (data.HEADER && data.HEADER.ERROR_FLAG && data.HEADER.ERROR_MSG.indexOf("File doesn't exist") == "0") {
                this.common_alert("파일이 존재하지 않습니다.", "error");
                return;
              } 
            }

            let fileName = this.getFileName(checkFileName);
            fileName = decodeURI(fileName); // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)

            if (window.navigator.msSaveOrOpenBlob) {
              // IE 10+
              window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
              // not IE
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.target = "_self";
              if (fileName) link.download = fileName;
              link.click();
            }

            requestObj.callback(res.data);
          } catch (e) {
            console.error(e);
          }
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          requestObj.callback(res.response.data);
        });
    },




    async LoginRequestDownloadApi(requestObj) {
      // _.merge - Lodash 라이브러리
      let reqHeaders = requestObj.headers;
      const token = window.sessionStorage.getItem("token");
      // request.headers.token = token;
      //resuest header에 전달받은 accessToken을 Bearer 형식으로 전송
      // reqHeaders.Authorization = "bearer " + token;
      let form = new FormData();

      // console.log("reqHeaders ", reqHeaders)

      for (var strKey in requestObj.data) {
        form.append(strKey, requestObj.data[strKey]);
      }

      return axios({
        url: process.env.VUE_APP_API_URL + requestObj.url,
        headers: reqHeaders,
        responseType: "arraybuffer",
        method: "post",
        data: form,
      })
        .then((res) => {
          try {
            let blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });
            console.log("Res>>>>>");
            console.log(res);

            // 파일 존재 여부 판단 (파일이름이 없는 경우 파일이 존재하지 않는것으로 판단)
            let checkFileName = res.headers["content-disposition"];
            
            if(checkFileName == "" || checkFileName == null){
              let data = JSON.parse(new TextDecoder("utf-8").decode(res.data).replaceAll("\\", "\\\\"));
              if (data.HEADER && data.HEADER.ERROR_FLAG && data.HEADER.ERROR_MSG.indexOf("File doesn't exist") == "0") {
                this.common_alert("파일이 존재하지 않습니다.", "error");
                return;
              } 
            }

            let fileName = this.getFileName(checkFileName);
            fileName = decodeURI(fileName); // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)

            if (window.navigator.msSaveOrOpenBlob) {
              // IE 10+
              window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
              // not IE
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.target = "_self";
              if (fileName) link.download = fileName;
              link.click();
            }

            requestObj.callback(res.data);
          } catch (e) {
            console.error(e);
          }
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          requestObj.callback(res.response.data);
        });
    },




    getFileName(contentDisposition) {
      let fileName = contentDisposition
        .split(";")
        .filter((ele) => {
          return ele.indexOf("filename") > -1;
        })
        .map((ele) => {
          return ele.replace(/"/g, "").split("=")[1];
        });
      return fileName[0] ? fileName[0] : null;
    },
    async setFileDownCnt(FILE_GROUP_KEY, FILE_KEY, asp_cust_key) {
      let rtnBln = false;
      let requestObj = {
        headers: {},
        data: {},
      };

      const token = window.sessionStorage.getItem("token");
      // request.headers.token = token;
      //resuest header에 전달받은 accessToken을 Bearer 형식으로 전송
      requestObj.headers.Authorization = "bearer " + token;
      let form = new FormData();
      form.append("fileGroupKey", FILE_GROUP_KEY);
      form.append("fileKey", FILE_KEY);
      form.append("aspCustKey", asp_cust_key);
      form.append(
        "procId",
        this.$store.getters["userStore/GE_USER_ROLE"].userId
      );
      form.append(
        "amdrId",
        this.$store.getters["userStore/GE_USER_ROLE"].userId
      );

      axios({
        url: process.env.VUE_APP_API_URL + "api/file/update-dnlod-cnt",
        headers: requestObj.headers,

        method: "post",
        data: form,
      })
        .then((res) => {
          rtnBln = true;
          return rtnBln;
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          return rtnBln;
        });
    },

    async deleteFile(FILE_GROUP_KEY, FILE_KEY, asp_cust_key) {
      let rtnBln = false;
      let requestObj = {
        headers: {},
        data: {},
      };

      const token = window.sessionStorage.getItem("token");
      // request.headers.token = token;
      //resuest header에 전달받은 accessToken을 Bearer 형식으로 전송
      requestObj.headers.Authorization = "bearer " + token;

      await axios({
        url: process.env.VUE_APP_API_URL + "api/file/delete",
        headers: requestObj.headers,

        method: "post",
        data: {
          deleteFileKeys: [
            {
              fileGroupKey: FILE_GROUP_KEY,
              fileKey: FILE_KEY,
              aspCustKey: asp_cust_key,
              amdrId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
            },
          ],
        },
      })
        .then((res) => {
          rtnBln = true;
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          rtnBln = false;
        });
      return rtnBln;
    },

    /**
     * 첨부파일 다중 삭제.
     * @param {List} dataList checkBox 체크된 데이터 배열.
     * @return {boolean}
     * @author
     */
    async deleteFilesMulti(dataList) {
      let rtnBln = false;
      let requestObj = {
        headers: {},
        data: {},
      };

      let delData = [];
      for (let i in dataList) {
        delData.push({
          fileGroupKey: dataList[i].FILE_GROUP_KEY,
          fileKey: dataList[i].FILE_KEY,
          aspCustKey: dataList[i].ASP_CUST_KEY,
          amdrId: this.$store.getters["userStore/GE_USER_ID"],
        });
      }

      const token = window.sessionStorage.getItem("token");
      // request.headers.token = token;
      //resuest header에 전달받은 accessToken을 Bearer 형식으로 전송
      requestObj.headers.Authorization = "bearer " + token;

      await axios({
        url: process.env.VUE_APP_API_URL + "api/file/delete",
        headers: requestObj.headers,
        method: "post",
        data: { deleteFileKeys: delData },
      })
        .then((res) => {
          rtnBln = true;
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          rtnBln = false;
        });
      return rtnBln;
    },

    async mixin_getFileList(FILE_GROUP_KEY) {
      let rtn = [];
      let requestData = {
        headers: {},
        sendData: {},
      };
      // header 세팅
      requestData.headers["URL"] = "api/file/json/list";
      requestData.headers["SERVICE"] = "file.json";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["FILE_GROUP_KEY"] = FILE_GROUP_KEY;

      // 결과 리턴
      let response = await this.common_postCall_noPB(requestData);

      if (!response.HEADER.ERROR_FLAG) {
        rtn = response.DATA;
      }

      return rtn;
    },

    /*
      item (필수): 파일 목록 v-data-table item
      item 구조 : {
        FILE_KEY :파일키
        , SAVE_FILENAME : 저장된 파일명
        , ORIGINAL_FILENAME : 원본 파일명
        , FILE_SIZE : 파일 사이즈
        , FILE_EXTS : 파일 확장자
        , DNLOD_CNT : 다운로드 수
        , ASP_CUST_KEY : 회사구분
      }
      busiType (필수) : RepositoryBusiType.java 참조 (palette, phone, chat, km, bbs 해당 값만 허용됨)
      pathType (필수) : RepositoryPathType.java 참조 (publics, files, images, videos, inqrytypeimage 해당 값만 허용됨)
    */
    async mixin_fileDownload(item, busiType, pathType, user_id) {
      this.requestDownloadApi({
        url: "api/file/" + busiType + "/" + pathType + "/download", // required
        headers: {
          // required
          Authorization: "",
        },
        callback: (data) => {
          // required
          this.setFileDownCnt(
            item.FILE_GROUP_KEY,
            item.FILE_KEY,
            item.ASP_CUST_KEY
          );
          item.DNLOD_CNT = Number(item.DNLOD_CNT) + 1;
        },
        data: {
          // optional
          fileGroupKey: item.FILE_GROUP_KEY,
          aspNewCustKey: item.ASP_CUST_KEY,
          aspCustKey: item.ASP_CUST_KEY,
          // procId: user_id ? user_id : this.$store.getters["userStore/GE_USER_ROLE"].userId,
          procId: this.$store.getters["userStore/GE_USER_ROLE"].userId,
          fileKey: item.FILE_KEY,
          uploadUri: "/api/file/" + busiType + "/" + pathType + "/uploads",
          downloadUri: "/api/file/" + busiType + "/" + pathType + "/download",
          fileUploadLib: "dropzone",
          dir:
            "/EBEST/web/" + busiType + "/" + pathType, // local
          tempDir:
            "/EBEST/web/" + busiType + "/" + pathType + "/temp", // local
          targetType: "FILE",
          acceptedFiles: "image/*,*.doc,*.docx,*.pdf,*.csv,*.xls,*.xlsx,*.ppt",
        },
      });
    },
    async mixin_loginFileDownload(item, busiType, pathType, user_id) {
      this.LoginRequestDownloadApi({
        url: "api/file/" + busiType + "/" + pathType + "/download", // required
        headers: {
          // required
          // Authorization: "",
        },
        callback: (data) => {
          // required
          // this.setFileDownCnt(
          //   item.FILE_GROUP_KEY,
          //   item.FILE_KEY,
          //   item.ASP_CUST_KEY
          // );
          item.DNLOD_CNT = Number(item.DNLOD_CNT) + 1;
        },
        data: {
          // optional
          fileGroupKey: item.FILE_GROUP_KEY,
          aspNewCustKey: item.ASP_CUST_KEY,
          aspCustKey: item.ASP_CUST_KEY,
          // procId: user_id ? user_id : this.$store.getters["userStore/GE_USER_ROLE"].userId,
          procId: "90108",
          fileKey: item.FILE_KEY,
          uploadUri: "/api/file/" + busiType + "/" + pathType + "/uploads",
          downloadUri: "/api/file/" + busiType + "/" + pathType + "/download",
          fileUploadLib: "dropzone",
          dir:
            "/EBEST/web/" + busiType + "/" + pathType, // local
          tempDir:
            "/EBEST/web/" + busiType + "/" + pathType + "/temp", // local
          targetType: "FILE",
          acceptedFiles: "image/*,*.doc,*.docx,*.pdf,*.csv,*.xls,*.xlsx,*.ppt",
        },
      });
    },
    mixin_getFileSize(fileSize) {
      let rtnStr = "-";
      let divSize = 1024;
      let tempSize = fileSize;
      if (fileSize != undefined && fileSize != "" && fileSize > 0) {
        if (tempSize > divSize) {
          tempSize = (Number(tempSize) / 1024).toFixed(0);
          if (tempSize > divSize) {
            tempSize = (Number(tempSize) / 1024).toFixed(0);
            rtnStr = tempSize + "Mb";
          } else {
            rtnStr = tempSize + "Kb";
          }
        } else {
          rtnStr = "1Kb";
        }
      } else {
        return rtnStr;
      }

      return rtnStr;
    },

    // 신통 초기화
    async mixin_sintongReset() {
      // header 세팅
      let requestData = {
        headers: {
          ASYNC  : false,
          METHOD : "sintongReset",
          SERVICE: "api/sintong",
          TYPE   : "BIZ_SERVICE",
          URL    : "/api/sintong/sintongReset"
        },
        sendData: {},
      };

      // 테스트 데이터
      // sendData 세팅
      // requestData.sendData.USER_ID = "31285";          // 사번
      // requestData.sendData.MdaTp   = "0";              // 매체구분(0:CTI(상담앱) 1:폰패드)
      // requestData.sendData.TrxTp   = "0";              // 처리구분(CTI : 0 데이터 삭제 및 화면닫기, 1 계좌연동, 3 화면오픈 / 폰패드 : 1 ARS폰패드 시작, 2 비밀번호 연동)
      // requestData.sendData.ScrNo   = "";               // 화면번호(CTI연동 화면오픈시 입력)
      // requestData.sendData.AcntNo  = "";               // 계좌번호(CTI 계좌연동시 사용)
      // requestData.sendData.Pwd     = "";               // 비밀번호(폰패드, CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      // requestData.sendData.BnkPwd  = "";               // 은행비밀번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      // requestData.sendData.ClntId  = "";               // 고객ID(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      // requestData.sendData.Rmnno   = "";               // 실명확인번호

      // 상담메인에서 고객정보 받아온 데이터
      // sendData 세팅
      requestData.sendData.USER_ID = this.user_id;     // 사번
      requestData.sendData.MdaTp   = "0";              // 매체구분(0:CTI(상담앱) 1:폰패드)
      requestData.sendData.TrxTp   = "0";              // 처리구분(CTI : 0 데이터 삭제 및 화면닫기, 1 계좌연동, 3 화면오픈 / 폰패드 : 1 ARS폰패드 시작, 2 비밀번호 연동)
      requestData.sendData.ScrNo   = "";               // 화면번호(CTI연동 화면오픈시 입력)
      requestData.sendData.ENC_TYPE = "CALL";
      requestData.sendData.AcntNo  = "";               // 계좌번호(CTI 계좌연동시 사용)
      requestData.sendData.Pwd     = "";               // 비밀번호(폰패드, CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      requestData.sendData.BnkPwd  = "";               // 은행비밀번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      requestData.sendData.ClntId  = "";               // 고객ID(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      requestData.sendData.Rmnno   = "";               // 실명확인번호

      // I/F 처리 결과
      this.common_postCall(requestData).then(response => {
        if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG || (response.DATA && response.DATA.length > 0 && response.DATA[0].TrxReslt != "0000")) {
          let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\n") : null;
          let errorMsg = _.find(errorMessages, msg => {
          return _.includes(msg, "에러 메시지: ");
          });
          let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
          // this.common_alert(msg, "error");
          return false;
        }
      })
    },

    // 신통 조회 클릭 - TabsArea
    mixin_clickSintong(){
      // 상담메인에서 고객정보 받아온 데이터
      let item = {
        ACCOUNT   : this.geCustInfo.SRCH_ACCOUNT,
        ACCOUNT_PW: this.geCustInfo.ACCOUNT_PW,
        BANK_PW   : this.geCustInfo.BANK_PW,
        CLNT_NO   : this.geCustInfo.HTS_USER_ID,
        CL_CID    : this.geCustInfo.SRCH_CL_CID,
        ENC_TYPE  : 'CALL',
      }
      this.mixin_sintongSearch(item);
    },

    // 신통 조회
    async mixin_sintongSearch(item) {
      // console.log("------------------------------");
      // console.log(" *" + item.ACCOUNT);
      // console.log(" *" + item.ACCOUNT_PW);
      // console.log(" *" + item.BANK_PW);
      // console.log(" *" + item.CLNT_NO);
      // console.log(" *" + item.CL_CID);
      // console.log("------------------------------");
      // header 세팅
      let requestData = {
        headers: {
          ASYNC  : false,
          METHOD : "sintongSearch",
          SERVICE: "api/sintong",
          TYPE   : "BIZ_SERVICE",
          URL    : "/api/sintong/sintongSearch"
        },
        sendData: {},
      };

      // 테스트 데이터
      // sendData 세팅
      // requestData.sendData.USER_ID = "31285";          // 사번
      // requestData.sendData.MdaTp   = "0";              // 매체구분(0:CTI(상담앱) 1:폰패드)
      // requestData.sendData.TrxTp   = "1";              // 처리구분(CTI : 0 데이터 삭제 및 화면닫기, 1 계좌연동, 3 화면오픈 / 폰패드 : 1 ARS폰패드 시작, 2 비밀번호 연동)
      // requestData.sendData.ScrNo   = "";               // 화면번호(CTI연동 화면오픈시 입력)
      // requestData.sendData.AcntNo  = "20160658401";    // 계좌번호(CTI 계좌연동시 사용)
      // requestData.sendData.Pwd     = "0709";           // 비밀번호(폰패드, CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      // requestData.sendData.BnkPwd  = "1123";           // 은행비밀번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      // requestData.sendData.ClntId  = "00384568";       // 고객ID(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      // requestData.sendData.Rmnno   = "8702032526318";  // 실명확인번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)  

      // sendData 세팅
      requestData.sendData.USER_ID = this.user_id;         // 사번
      requestData.sendData.MdaTp   = "0";                  // 매체구분(0:CTI(상담앱) 1:폰패드)
      requestData.sendData.TrxTp   = "1";                  // 처리구분(CTI : 0 데이터 삭제 및 화면닫기, 1 계좌연동, 3 화면오픈 / 폰패드 : 1 ARS폰패드 시작, 2 비밀번호 연동)
      requestData.sendData.ScrNo   = "";                   // 화면번호(CTI연동 화면오픈시 입력)
      requestData.sendData.ENC_TYPE = "CALL";
      requestData.sendData.AcntNo  = item.ACCOUNT ? item.ACCOUNT.replaceAll(" ", "") : '';               // 계좌번호(CTI 계좌연동시 사용)
      requestData.sendData.Pwd     = item.ACCOUNT_PW ? item.ACCOUNT_PW.replaceAll(" ", "") : '';      // 비밀번호(폰패드, CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      requestData.sendData.BnkPwd  = item.BANK_PW ? item.BANK_PW.replaceAll(" ", "") : '';         // 은행비밀번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      requestData.sendData.ClntId  = item.CLNT_NO ? item.CLNT_NO.replaceAll(" ", "") : '';         // 고객ID(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6)
      requestData.sendData.Rmnno   = item.CL_CID ? item.CL_CID.replaceAll(" ", "") : '';          // 실명확인번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6) 
      requestData.sendData.ENC_TYPE= item.ENC_TYPE ? item.ENC_TYPE.replaceAll(" ", "") : '';          // 실명확인번호(CTI 계좌연동시 사용) AES256 암호화(키: adn1QW23aEsd32rfasDIdfcg9weV98C6) 

      // I/F 처리 결과
      this.common_postCall_noPB(requestData, false).then(response => {
        if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG || (response.DATA && response.DATA.length > 0 && response.DATA[0].TrxReslt != "0000")) {
          let errorMessages = response.HEADER.ERROR_MSG ? response.HEADER.ERROR_MSG.split("\n") : null;
          let errorMsg = _.find(errorMessages, msg => {
          return _.includes(msg, "에러 메시지: ");
          });
          let msg = errorMsg ? _.trim(errorMsg.split(":")[1]) : "시스템에 오류가 발생하였습니다."
          // this.common_alert(msg, "error");
          return false;
        }
      })
    },

    /**
     * 챗관련 추가 Start_Arthur.Kim
     */

    /**
     * 바이트 기준으로 문자열 길이를 계산하여 반환한다.
     * @param agStr : 문자열
     * @returns 문자열의 길이
     * @author MPC R&D Team  
     */
    getByte(agStr){

      let tmpStr;
      let temp=0;
      let onechar;
      let tcount;
      
      let pCnt = 1;
      let k = 0;

      tcount = 0;
      tmpStr = new String(agStr);
      
      temp = tmpStr.length;
      
      for (; k < temp; k++ ){
          onechar = tmpStr.charAt(k);
          pCnt =1;
          
          if ( escape(onechar).length > 4 ){
              pCnt = 2;
          }

          tcount = tcount+pCnt;
      }
      return tcount;
    },

    /**
     * 바이트 기준으로 문자열 자른다.
     * @param agStr : 문자열
     * @returns 문자열의 길이
     * @author MPC R&D Team  
     */
    cutByteLength(s, len) {

      let size = 0;
      let rIndex = 0;
      let i = 0;

      if (s == null || s.length == 0) {
          return 0;
      }

      rIndex = s.length;
      
      for (; i < s.length; i++) {

          size += this.charByteSize(s.charAt(i));

          if( size == len ) {
              rIndex = i + 1;
              break;
          } else if( size > len ) {
              rIndex = i;
              break;
          }
      }

      return s.substring(0, rIndex);
    },
  
    charByteSize(ch) {

      if (ch == null || ch.length == 0) {
          return 0;
      }

      let charCode = ch.charCodeAt(0);

      if (charCode <= 0x00007F) {
          return 1;
      } else if (charCode <= 0x0007FF) {
          return 2;
      } else if (charCode <= 0x00FFFF) {
          return 3;
      } else {
          return 4;
      }
    },

    /**
     * 문자열에서 특정문자열을 찾아서 특정문자로 변환한다.(정규식 사용하지 않고 직접처리함)
     * @param {String} strText 전체 문자열
     * @param {String} strFind 찾을 문자열
     * @param {String} strChange 변경할 문자열
     * @return {String} 치환된 문자열
     */
    replaceAll(strText, strFind, strChange){

      if(typeof(strText)		=== "undefined"){strText	= "";}
      if(typeof(strFind)		=== "undefined"){strFind	= "";}
      if(typeof(strChange)	=== "undefined"){strChange	= "";}
      if(typeof(strText)		!== "string"){strText	= strText.toString();}
      if(typeof(strFind)		!== "string"){strFind	= strFind.toString();}
      if(typeof(strChange)	!== "string"){strChange	= strChange.toString();}

      return strText.split(strFind).join(strChange);
    }, 

    /**
     * XSS이 적용된 문자를 원래문자로 변환한다.
     * @param {String} strText 전체 문자열
     */
    restoreXSS(strText) {

      //값존재유무 체크
      if(strText === "" || strText == null){
        return strText;
      }
      
      strText = strText.toString();
      // strText = JSON.parse(strText);

      //문자열 길이가 4이상일 경우만 처리
      //  if(strText.length <= 3){return strText;}

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = this.replaceAll(strText,"&amp;","&");
      strText = this.replaceAll(strText,"&amp;amp","&");
      strText = this.replaceAll(strText,"&lt;","<");
      strText = this.replaceAll(strText,"&gt;",">");
      strText = this.replaceAll(strText,"&#40;","(");
      strText = this.replaceAll(strText,"&#41;",")");
      strText = this.replaceAll(strText,"&apos;","'");
      strText = this.replaceAll(strText,"&#91;","[");
      strText = this.replaceAll(strText,"&#93;","]");
      strText = this.replaceAll(strText,"&quot;",'"');

      return strText;
   },
  
   /**
    * 
    * @param {*} strText 
    * @returns 
    */
    restoreXSS_Dec(strText) {

      //값존재유무 체크
      if(strText === "" || strText == null) { 
        return strText;
      }

      strText = strText.toString();

      //문자열 길이가 4이상일 경우만 처리 
      // 이부분이 왜 걸려잇는지 모르겠음...테스트 후 길이체크는 막을 예정..
      // if(strText.length <= 3){return strText;}

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = this.replaceAll(strText,"<","&lt;");
      strText = this.replaceAll(strText,">","&gt;");
      strText = this.replaceAll(strText,"(","&#40;");
      strText = this.replaceAll(strText,")","&#41;");
      strText = this.replaceAll(strText,"[","&#91;");
      strText = this.replaceAll(strText,"]","&#93;");
      //strText = replaceAll(strText,"'","&apos;");
      //strText = replaceAll(strText,"&","&amp;");

      return strText;
   },
   
    /**
     * 챗관련 추가 End_Arthur.Kim
     */
    //calendar 포맷 정의(일자)
    mixin_getDate(date) {
      const day = date.split("-")[2];
      const day_num = Number(day);
      return day_num;
    },
    //전화번호 포맷 변경이력일련번호
    //11자리 XXX-XXXX-XXXX
    //10자리 02-XXXX-XXXX 또는 XXX-XXX-XXXX
    mixin_setPhoneNo(no) {
      if (!no) return "";
      let setNum = "";
      if (no.length === 11) {
        setNum =
          no.substring(0, 3) +
          "-" +
          no.substring(3, 7) +
          "-" +
          no.substring(7, 11);
      } else if (no.length === 10) {
        if (no.substring(0, 2) === "02") {
          setNum =
            no.substring(0, 2) +
            "-" +
            no.substring(2, 6) +
            "-" +
            no.substring(6, 10);
        } else {
          setNum =
            no.substring(0, 3) +
            "-" +
            no.substring(3, 6) +
            "-" +
            no.substring(6, 10);
        }
      }else{
        return no;
      }

      return setNum;
    },

    //사용자 버튼 사용 여부 확인
    mixin_set_btn(menu_id, button_id) {
      //console.log("menu_id", menu_id);
      //console.log("button_id", button_id);
      if (!menu_id || !button_id) return false;
      let return_data = false;
      let set_id = menu_id.replace("MENU_", "");
      let menu_list = this.$store.getters["userStore/GE_USER_MENU_LIST"];

      for (let i = 0; i < menu_list.length; i++) {
        if (menu_list[i].C_NODE_NO === set_id) {
          if (menu_list[i].BTN_AUTH) {
            let btn_list = menu_list[i].BTN_AUTH.split(",");
            if (btn_list.indexOf(button_id) > -1) {
              return_data = true;
            }
          }
        }
      }

      return return_data;
    },
    
    // null 체크
    checkValueNull(num)
    {
        if(typeof num == undefined || num == null || num == "")
        {
            return true;
        }
        else
        {
            return false;
        }
    },

    //마스킹 처리
    mixin_mask_num(num) {
      
      let preMaskingData = num;
      let MaskingData = "";
      let len = "";

      let emailMatchValue = preMaskingData.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);

      if(this.checkValueNull(emailMatchValue) == true)
      {
          MaskingData = preMaskingData;
      }
      else
      {
          len = emailMatchValue.toString().split('@').length;
          MaskingData = preMaskingData.toString().replace(new RegExp('.(?=.{0,' + len + '}@)', 'gi'), '*');
      }

      let phoneMatchValue = MaskingData.match(/\d{2,3}-\d{3,4}-\d{4}/gi);

      if(/-[0-9]{3}-/.test(phoneMatchValue))
      {
          MaskingData= MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString()
                      .replace(/-[0-9]{3}-/g, "-***-"));
      
      }

      else if(/-[0-9]{4}-/.test(phoneMatchValue))
      {
          MaskingData= MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString()
                      .replace(/-[0-9]{4}-/g, "-****-"));
      
      }


      phoneMatchValue = MaskingData.length < 11 ? MaskingData.match(/\d{10}/gi) : MaskingData.match(/\d{11}/gi);
      if(this.checkValueNull(phoneMatchValue) == true)
      {
          MaskingData = MaskingData;
      }
      else
      {
          if(MaskingData.length < 11)
          {
              MaskingData = MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString()
                            .replace(/(\d{3})(\d{3})(\d{4})/gi,'$1***$3'));
          }
          else
          {
              MaskingData = MaskingData.toString().replace(phoneMatchValue, phoneMatchValue.toString()
                            .replace(/(\d{3})(\d{4})(\d{4})/gi,'$1****$3'));
          }
      }

      return MaskingData;
    },

    //사용자별 소속 조회
    async mixin_get_blng(asp_cust_key){
      var responseData = [];
      await api.post("/api/chat/dashboard/ATTR/inqire", {
        ASP_NEWCUST_KEY: asp_cust_key,
        ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
        ASP_CUST_KEY: asp_cust_key,
        USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      },
      { 
        head: {
          SERVICE: "chat.dashboard.ATTR",
          METHOD: "inqire",
          TYPE: "BIZ_SERVICE",
        } 
      })
      .then((response) => {
        if (response.data) {
          responseData = response.data["DATA"];
        }
      })
      .catch((error) => {});

    return responseData;
    },

    //소속별 사용자 조회
    async mixin_get_user_by_attr(attr_a, attr_b) {

      var responseData = [];
      await api
        .post("api/palette/common/get-attr-user/list", {
          USER_ATTR_A: attr_a,
          USER_ATTR_B: attr_b,
        })
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
            responseData.unshift({"USER_NICK": "", "USER_ATTR_A": "","USER_NM": "전체","USER_ID": "","USER_ATTR_D": "","USER_ATTR_C": "","USER_ATTR_B": ""});
          }
        })
        .catch((error) => {});

      return responseData;
    },

    async mixin_get_user(attr_a) {

      var responseData = [];
      await api
        .post("api/palette/common/get-attr-user", {
          ATRT_GROUP_ID: attr_a,
        })
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
            // responseData.unshift({"USER_NICK": "", "USER_ATTR_A": "","USER_NM": "전체","USER_ID": "","USER_ATTR_D": "","USER_ATTR_C": "","USER_ATTR_B": ""});
          }
        })
        .catch((error) => {});

      return responseData;
    },    

    //회사별 공통코드 조회
    mixin_common_code_byCust(all_code, group_cd, strHeadText, aspCustKey){
      var responseData = [];

      if (strHeadText != "" && strHeadText != undefined) {
        responseData.push({ text: strHeadText, value: "" });
      }

      if (all_code && typeof all_code === "object") {
        for (var i = 0; i < all_code.length; i++) {
          if (all_code[i].group_cd === group_cd) {
            for (var a = 0; a < all_code[i].group_value.length; a++) {
              if(all_code[i].group_value[a].ETC_INFO01 == '' || all_code[i].group_value[a].ETC_INFO01 == null){
                //기타코드1 비어있을때
                if(all_code[i].group_value[a].ETC_INFO02 == '' || all_code[i].group_value[a].ETC_INFO02 == null){
                  //기타코드1 비어있고 기타코드2 비어있을때
                  responseData.push({
                    text: all_code[i].group_value[a].CD_NM,
                    value: all_code[i].group_value[a].CD,
                    info1: all_code[i].group_value[a].ETC_INFO01,
                    info2: all_code[i].group_value[a].ETC_INFO02,
                    info3: all_code[i].group_value[a].ETC_INFO03,
                  });
                } else {
                  //기타코드1 비어있고 기타코드2 존재할때
                  let chkAspCus = all_code[i].group_value[a].ETC_INFO02.split(',');
                  let chkAspCusLeng = chkAspCus.length;
                  let n = 0;
                  let cnt = 0;
                  for(n;n<chkAspCusLeng;n++){
                    if(chkAspCus[n] == aspCustKey){
                      cnt++;
                    }
                  }
                  if(cnt == 0){
                    responseData.push({
                      text: all_code[i].group_value[a].CD_NM,
                      value: all_code[i].group_value[a].CD,
                      info1: all_code[i].group_value[a].ETC_INFO01,
                      info2: all_code[i].group_value[a].ETC_INFO02,
                      info3: all_code[i].group_value[a].ETC_INFO03,
                    });
                  }
                }
              } else {
                //기타코드1 존재할때 >> 기타코드2는 조회할 필요 없음
                let chkAspCus = all_code[i].group_value[a].ETC_INFO01.split(',');
                let chkAspCusLeng = chkAspCus.length;
                let n = 0;
                for(n;n<chkAspCusLeng;n++){
                  if(chkAspCus[n] == aspCustKey){
                    responseData.push({
                      text: all_code[i].group_value[a].CD_NM,
                      value: all_code[i].group_value[a].CD,
                      info1: all_code[i].group_value[a].ETC_INFO01,
                      info2: all_code[i].group_value[a].ETC_INFO02,
                      info3: all_code[i].group_value[a].ETC_INFO03,
                    });
                  }
                }
              }
              
            }
          }
        }
      }
      console.log("responseData", responseData);
      return responseData;
    },

    //소속 조회
    async mixin_get_attr_by_attr(cd, group_cd) {

      var responseData = [];
      await api
        .post("api/palette/common/get-attr-attr/list", {
          CD: cd,
          GROUP_CD: group_cd
        })
        .then((response) => {
          if (response.data) {
            responseData = response.data["DATA"];
            responseData.unshift({ CD: "", CD_NM: "전체" });
          }
        })
        .catch((error) => {});

      return responseData;
    },

    /**
     * AES256 ECB모드로 암호화하여 base64로 인코딩된 문자열 리턴
     * @param {String} text 암호화할 문자열
     * @param {String} key 암호화 키
     * @return {String} 암호화된 base64로 인코딩된 문자열
     */
    encryptAES_ECB: function(text, key) {
      // text = CryptoJS.enc.Utf8.parse(text);
      key = CryptoJS.enc.Utf8.parse(key);
      
      let encrypted = CryptoJS.AES.encrypt(text, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });

      return encrypted.toString();
    },
    /**
     * AES256 ECB모드로 암호화된 base64로 인코딩된 문자열을 복호화하여 리턴
     * @param {String} text 암호화된 base64로 인코딩된 문자열
     * @param {String} key 암호화 키
     * @return {String} 복호화된 문자열
     */
    decryptAES_ECB: function(text, key) {
      text = CryptoJS.enc.Base64.parse(text);
      key = CryptoJS.enc.Utf8.parse(key);
      
      let decrypted = CryptoJS.AES.decrypt({ ciphertext: text }, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });

      return decrypted.toString(CryptoJS.enc.Utf8);
    },

    mixin_newPhoneFormat(val) {
      if (!val) return val;
      let telNumber = (val.replace(/\-/g, "")).replace(/\s/g, "");  // 하이픈과 공백 모두 제거
      const telLength = telNumber.length;

      if(telLength > 12) return val;

      if (telLength > 0) {
        let telArr = new Array();
        let result = false;
  
        const telNo = new Array(
            "02"    // 서울
          , "031"   // 경기도
          , "032"   // 인천
          , "033"   // 강원
          , "041"   // 충남
          , "042"   // 대전
          , "043"   // 충북
          , "051"   // 부산
          , "052"   // 울산
          , "053"   // 대구
          , "054"   // 경북
          , "055"   // 경남
          , "061"   // 전남
          , "062"   // 광주
          , "063"   // 전북
          , "064"   // 제주
        
          , "010"   // 통합(이동)
          , "011"   // SKT
          , "016"   // KTF
          , "017"   // SKT
          , "018"   // KTF
          , "019"   // LGT
        
          , "0502"   // 평생번호(KT)
          , "0504"   // 모바일팩스번호
          , "0505"   // 평생번호(데이콤)
          , "060"   // 서비스/정보이용
          , "070"   // 인터넷전화
          , "080"   // 수신자부담(크로바서비스)
        );
  
        telNo.forEach(item => {
          if ((telNumber.substr(0, item.length)) == item) {
            telArr[0] = item;
            telArr[1] = telNumber.substring(item.length, telLength-4);
            telArr[2] = telNumber.substring(telLength-4, telLength);
  
            result = true;
          }
        });
  
        if (!result) {
          telArr[0] = telNumber.substring(telLength-12, telLength-8);
          telArr[1] = telNumber.substring(telLength-8, telLength-4);
          telArr[2] = telNumber.substring(telLength-4, telLength);
        }
        if(this.mixin_isEmpty(telArr[1])) {
          return telArr[2];
        } else if(this.mixin_isEmpty(telArr[0])) {
          return telArr[1] + '-' + telArr[2];
        }
        return telArr[0] + '-' + telArr[1] + '-' + telArr[2];
      } else {
        return '';
      }
    }
  },

  /**
   * 챗관련 추가 Start_Arthur.Kim
   */
  filters: {

    /**
     * XSS이 적용된 문자를 원래문자로 변환한다.(필터처리)
     * @param {String} strText 전체 문자열
     */
    restoreXSSFilter (strText) {
        
      //값존재유무 체크
      if(strText === "" || strText == null) {
        return strText;
      }

      strText = strText.toString();
      // strText = JSON.parse(strText);

      //문자열 길이가 4이상일 경우만 처리
      if(strText.length <= 3) {
        return strText;
      }

      //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
      strText = strText.split("&amp;").join("&");
      strText = strText.split("&lt;").join("<");
      strText = strText.split("&gt;").join(">");
      strText = strText.split("&#40;").join("(");
      strText = strText.split("&#41;").join(")");
      strText = strText.split("&apos;").join("'");
      strText = strText.split("&#91;").join("[");
      strText = strText.split("&#93;").join("]");
      strText = strText.split("&quot;").join('"');
        
      return strText;
    },

/**
 * 챗관련 추가 Start_Arthur.Kim
 */
  },
};
