import Vue from 'vue';

export const eventBus =  new Vue({
    
    data() {
      return {
      };
    },
    methods: {
        /**
         * 
         */
         busFnWorker() {
          this.$emit('busFnWorker');
        },      



        /**
         * 창 닫힘 시 CTI 로그아웃
         */
         busCloseWindowCtiLogout() {
          this.$emit('busCloseWindowCtiLogout');
        },
              

        /**
         * 콜메인 상담이력 select reload
         */
         busReRoadCnslTblRowClick() {
          this.$emit('busReRoadCnslTblRowClick');
        },
      /****************************************************************************************************************************************************
         * IPCC Socket 변수 / 함수 정보 START
       ***************************************************************************************************************************************************/

        /**
         * 로그인/아웃 CTI 배열 조정 
         */
        busInitCallCti(e) {
          this.$emit('busInitCallCti', e);
        },
        
      /****************************************************************************************************************************************************
         * IPCC Socket 변수 / 함수 정보 END 
       ****************************************************************************************************************************************************
       ***************************************************************************************************************************************************/

      /****************************************************************************************************************************************************
         * 챗 Stomp 변수 / 함수 정보 START

      ***************************************************************************************************************************************************/

      
      /**
       * 소켓 연결 닫기 
       */
       checkSocket(flag) {
        this.$emit('checkSocket', flag);
      }, 

      /**
       * 소켓 연결 닫기 
       */
      busStompDisconnect() {
        this.$emit('busStompDisconnect');
      }, 

      /**
       * 상담원 대기 구독 메시지 인입 
       * @param {*} message 
       */
      busReadyCallback(message) {
        this.$emit('busReadyCallback', message);
      },
      
      /**
       * 채팅 메시지 인입 
       * @param {*} message 
       */
       busInoutCallback(message) {
        this.$emit('busInoutCallback', message);
      } ,

      /**
       * 채팅 계좌번호 인증 결과 수신
       * @param {*} message 
       */
       busCertCallback(CUSTOMER_NM) {
        this.$emit('busCertCallback', CUSTOMER_NM);
      } ,      
      /**
       * 채팅 계좌번호 인증 오류
       * @param {*} message 
       */
       busCertErrCallback(CUSTOMER_NM, ERROR_CNT) {
        this.$emit('busCertErrCallback', CUSTOMER_NM, ERROR_CNT);
      } ,          
      /**
       * 채팅 로그인 인증 결과 수신
       * @param {*} message 
       */
       busLoginCallback(TALK_USER_KEY) {
        this.$emit('busLoginCallback', TALK_USER_KEY);
      } ,     
      /**
       * 채팅 메인 창 닫으면!!!
       * @param {*} message 
       */
       chatMainClose() {
        this.$emit('chatMainClose');
      } ,          

      /****************************************************************************************************************************************************
         * 챗 Stomp변수 / 함수 정보 END 
       ****************************************************************************************************************************************************
       ***************************************************************************************************************************************************/
      /**
       * 전화받기
       */
      receiveCall(  ) {
        this.$emit('receiveCall');
      },
      /**
       * 전화걸기
       * @param callNum
       */
      makeCall( callNum) {
          this.$emit('makeCall', callNum);
      },
     /**
      * FINESSE 데이타처리
      * @param data
      */
      sendFnssData( sMsg, data ) {
        this.$emit('sendFnssData', sMsg, data);
      } , 

     /**
      * FINESSE 데이타처리
      * @param data
      */
      sendFnssLog( sMsg, data ) {
        this.$emit('sendFnssLog', sMsg, data);
      } ,       
     /**
      * cti상태변경
      * @param sttCd
      */
      ctiStt( sttCd ) {
        this.$emit('ctiStt', sttCd);
      }, 
     /**
      * TOP처리
      * @param sttCd
      */
      reqTopPrc( data ) {
        this.$emit('reqTopPrc', data);
      },
     /**
      * cti 서비스요청
      * @param reqType
      * @param reqVal1
      */
      ctiSendReq( reqType,  reqVal1) {
        this.$emit('ctiSendReq', reqType, reqVal1);
      },

     /**
      * 상담메인 MODE
      * @param sttVal
      */
      setCnslMode( sttVal) {
        this.$emit('setCnslMode', sttVal);
      },

     /**
      * IVR입력값 조회
      * @param sttVal
      */
      getIvrRcv( sttVal) {
        this.$emit('getIvrRcv', sttVal);
      },

      /**
       * 채팅클릭
       * @param next  callback 함수 
       */
      changeChatInfo( next ) {
        this.$emit('changeChatInfo', next);
      } , 

      /**
       * 채팅프로세스 갱신
       */
      changeProcessInfo( ) {
        this.$emit('changeProcessInfo');
      } , 

      /**
       * 상담내용 다시 불러오기 
       * @param talkContactId  컨텐츠 아이디
       */
      refreshChatContent(talkContactId) {
        this.$emit('refreshChatContent', talkContactId);
      } , 

      /**
       * 상담이력 저장/종료
       * @param talkContactId  컨텐츠 아이디
       */
      saveTalkHistory(talkContactId) {
        this.$emit('saveTalkHistory', talkContactId);
      } , 

      /**
       * 전달 할 사용자 아이디 셋팅팅
       * @param transUserId  상담원 아이디
       */
      setTransUserId(transUserId) {
        this.$emit('setTransUserId', transUserId);
      } , 
      
      /**
       * 고객정보 조회
       * @param talkUserKey  사용자 키 
       * @param {*} msg 
       */
      getCustInfo(talkUserKey) {
        this.$emit('getCustInfo', talkUserKey);
      } , 
      
      /**
       * 상담이력 조회
       * @param userTelno  고객전화번호
       */
      getTalkHistory(userTelno) {
        
        this.$emit('getTalkHistory', userTelno);
      } , 
      
      /**
       * 스크립트 조회
       */
      getScripts() {
        this.$emit('getScripts');
      } , 
      
      /**
       * 스크립트 수정창 닫기
       */
      closeScriptForm() {
        this.$emit('closeScriptForm');
      } , 

      /**
       * 비대면링크 조회
       */
      getUntactUrls() {
        this.$emit('getUntactUrls');
      } , 
      
      /**
       * 상담내용보기 (팝업)
       */
      setMessageList(list) {
        this.$emit('setMessageList', list);
      } , 

      /**
       * 채팅창 입력 불가 처리 
       */
      disableChat() {
        this.$emit('disableChat');
      } , 
      
      /**
       * 상담이력 저장 창 닫기 이벤트 
       */
      controlCnslClick() {
        this.$emit('controlCnslClick');
      } ,
      
      /**
       * 상담입력 창 오픈
       */
      openMssgForm(){
        this.$emit('openMssgForm');
      },

      /**
       * 선택된 상담 초기화
       */
      initTalkContactInfo(){
        this.$emit('initTalkContactInfo');
      },

      /**
       * 전달 창 닫기 이벤트 
       */
      closeTransferDialog() {
        this.$emit('closeTransferDialog');
      } ,

      /**
       * 전달 창 열기 이벤트 
       */
      openTransferDialog() {
        this.$emit('openTransferDialog');
      } ,

      /**
       * 스크롤 아래 이벤트 
       */
      toBottomClick() {
        
        this.$emit('toBottomClick');
      } ,

      /**
       * 이미지 선택창 닫기
       * @param {req_type} req_type  message / expired_session
       * @param {*} msg 
       */
      closeImageDialog() {
        
        this.$emit('closeImageDialog');
      } ,

      /**
       * 전달 창 열기 이벤트 
       */
       openImageDialog() {
        this.$emit('openImageDialog');
      } ,
      
      /**
       * 스크립트 채팅창 이어붙이기 / 새로붙이기 
       */
      putScript(payload) {
        
        this.$emit('putScript', payload);
      } ,

      /**
       * 단축키로 스크립트 찾기
       * @param command  단축키 
       */
      searchScriptShortKey(command) {
        
        this.$emit('searchScriptShortKey', command);
      } ,

      /**
       * 채팅목록
       */
      getTalkers() {
        
        this.$emit('getTalkers');
      } ,

      /**
       * 상담내용 검색
       */
      searchMsg(keyword) {
        
        this.$emit('searchMsg' , keyword);
      } ,

      /**
       * 고객 계좌 상세정보 검색
       */
       acctDtlSrch(keyword) {
        
        this.$emit('acctDtlSrch' , keyword);
      } ,

      /*특이내역목록*/
      spclCttList(keyword){
        this.$emit('spclCttList', keyword);
      },
      
      /**
       * 상담통합이력 상담구분콤보 셋팅
       */
       setCnslHstTypeComboData() {
        
        this.$emit('setCnslHstTypeComboData');
      } ,

      /**
       * 채팅 전송
       * @param message  메시지 내용
       */
       userchatMessage(message) {
        
        this.$emit('userchatMessage', message);
      } , 

      /**
       * 상담메인 고객조회
       */
      cusSearch(srchType, sVal) {
        
        this.$emit('cusSearch', srchType, sVal);
      } ,

      /**
       * IVR정보 처리
       */
      setIvrInf(sVal) {
        
        this.$emit('setIvrInf' , sVal);
      } ,
      /**
       * 상담메인 클리어
       */
       clrCallInf(sVal) {
        
        this.$emit('clrCallInf' , sVal);
      } ,


      sendVmsData(sVal) {
        this.$emit('sendVmsData', sVal);
      },

      sendOnRecvRsvPop(sVal) {
        this.$emit('sendOnRecvRsvPop', sVal);
      },

      saveCnslHist() {
        this.$emit('saveCnslHist');
      }

    },

  }
)




