//index.js
import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

import VueSwal from 'vue-swal'
Vue.use(VueSwal);   // 메시자창 lib

import createPersistedState from "vuex-persistedstate";
import authStore from "./modules/authStore";
import callStore from "./modules/callStore";
import commonStore from "./modules/commonStore";
import userStore from "./modules/userStore";
import logStore from "./modules/logStore";
import popStore from "./modules/popStore";
import toastStore from "./modules/toastStore";
import alertStore from "./modules/alertStore";
import messages from "./modules/messages";                            //톡관련 추가_Arthur.Kim

Vue.prototype.store  = messages;                                       //톡관련 추가_Arthur.Kim

const store = new Vuex.Store({
  modules: {
    authStore,
    callStore,
    commonStore,
    userStore,
    toastStore,
    alertStore,
    logStore,
    popStore,
    messages,                                                          //톡관련 추가_Arthur.Kim
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["authStore", "callStore", "commonStore", "userStore"
      //, "messages"
      ],    //톡관련 추가_Arthur.Kim 
    }),
  ],
});

export default store;
