<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Pickers</span></div>

      <h2>Datepicker / Monthpicker</h2>
      <div class="guide-card jh-cols">  
        <v-menu
          v-model="menu_date"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          offset-y          
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="inputDate"
              v-bind="attrs"
              v-on="on"
              class="jh-form"
              clearable
              append-icon="jh-icon-calendar is-blue"
              maxlength="10"
              @input="dateOnInput"
              @keyup.enter="menu_date = false"
              @click:clear="date = ''"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"
            >
            </v-text-field>
          </template>
          <v-date-picker
            class="jh-calendar"
            v-model="date"
            @input="menu_date = false"
            no-title
            locale="ko-KR"
            :day-format="mixin_getDate"
            @change="dateOnChange"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menu_month"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          offset-y
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="inputMonth"
              v-bind="attrs"
              v-on="on"
              class="jh-form"
              clearable
              append-icon="jh-icon-calendar is-blue"
              maxlength="7"
              @input="monthOnInput"
              @keyup.enter="menu_month = false"
              @click:clear="month = ''"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})$/, '$1-$2');"
            >
            </v-text-field>
          </template>
          <v-date-picker
            class="jh-calendar"
            v-model="month"
            type="month"
            @input="menu_month = false"
            no-title
            locale="ko-KR"
            :day-format="mixin_getDate"
            @change="monthOnChange"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source01_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Range</h2>
      <div class="guide-card jh-cols">
        <div class="jh-cols">                        
          <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue"
              @keyup.enter="menuStartDate = false"
              maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"
              ></v-text-field>
            </template>
            <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
          </v-menu>
          <span class="jh-unit">~</span>                       
          <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue"
              @keyup.enter="menuEndDate = false"
              maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"
              ></v-text-field>
            </template>
            <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
          </v-menu>
        </div>
        <v-menu
          v-model="menu_range_month"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeMonth"
                v-bind="attrs"                       
                v-on="on"
                class="jh-form"
                append-icon="jh-icon-calendar is-blue"
                maxlength="17"
                @input="dateRangeMonthOnInput"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{4})(\d{2})$/, '$1-$2 ~ $3-$4');"
            >
            </v-text-field>
          </template>
          <v-date-picker
            class="jh-calendar"
            v-model="months"
            type="month"
            range
            no-title
            locale="ko-KR"
            @change="monthsOnChange"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source02">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source02">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source02_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Time Picker</h2>
      <div class="guide-card">
        <v-menu
          ref="menu_time"
          v-model="menu_time"
          :close-on-content-click="false"
          :return-value.sync="time"
          transition="scale-transition"
          min-width="auto"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="time"
                v-bind="attrs"                       
                v-on="on"
                class="jh-form"
                append-icon="jh-icon-clock is-blue"
                style="width: 100px;"
            >
            </v-text-field>
          </template>
          <v-time-picker
            class="jh-calendar"
            v-if="menu_time"
            v-model="time"
            :allowed-minutes="allowedStep"
            no-title
            @click:minute="$refs.menu_time.save(time)"
          ></v-time-picker>
        </v-menu>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source03">
          <v-tab>Html</v-tab>
          <v-tab>Script</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source03">
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
          <v-tab-item>
            <pre class="guide-code" v-highlightjs><code class="javascript">{{content.source03_tab02}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "VuePickers",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return { 
        menu_date: false,
        menu_month: false,
        menuStartDate: false,
        menuEndDate: false,
        menu_range_month: false,
        menu_time: false,
        inputDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        inputMonth: new Date().toISOString().substr(0, 7),
        month: new Date().toISOString().substr(0, 7),
        from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dates: [
            (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        ],
        dateRangeMonth: new Date().toISOString().substr(0, 7) + " ~ " + new Date().toISOString().substr(0, 7),
        months: [
          new Date().toISOString().substr(0, 7),
          new Date().toISOString().substr(0, 7)
        ],
        time: null,
        source01: 'HTML',
        source02: 'HTML',
        source03: 'HTML',
        content:{

source01_tab01: `<v-menu v-model="menu_date" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
  <template v-slot:activator="{ on, attrs }">
    <v-text-field v-model="inputDate" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menu_date = false"
      maxlength="10" @input="dateOnInput" @click:clear="date = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
  </template>
  <v-date-picker class="jh-calendar" v-model="date" @input="menu_date = false" no-title locale="ko-KR" :day-format="mixin_getDate" @change="dateOnChange"></v-date-picker>
</v-menu>

<!--Month-->
<v-menu v-model="menu_month" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
  <template v-slot:activator="{ on, attrs }">
    <v-text-field v-model="inputMonth" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menu_month = false"
      maxlength="7" @input="monthOnInput" @click:clear="month = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})$/, '$1-$2');"></v-text-field>
  </template>
  <v-date-picker class="jh-calendar" v-model="month" type="month" @input="menu_month = false" no-title locale="ko-KR" :day-format="mixin_getDate" @change="monthOnChange"></v-date-picker>
</v-menu>`
,source01_tab02: `export default {
  data () {
    return {
        menu_date: false,
        inputDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        menu_month: false,
        inputMonth: new Date().toISOString().substr(0, 7),
        month: new Date().toISOString().substr(0, 7),
    }
  },
  methods: {
    dateOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.inputDate) && moment(this.inputDate).isValid()) {
        this.date = this.inputDate;
        // datepick 다시 랜더링
        this.menu_date = false;
        this.menu_date = true;
      }
    },
    dateOnChange(e) {
      this.inputDate = e;
    },
    monthOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}$/.test(this.inputMonth) && moment(this.inputMonth).isValid()) {
        this.month = this.inputMonth;
        // datepick 다시 랜더링
        this.menu_month = false;
        this.menu_month = true;
      }
    },
    monthOnChange(e) {
      this.inputMonth = e;
    },
  }
}`
,source02_tab01: `<div class="jh-cols">                        
  <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
      maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
    </template>
    <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
  </v-menu>
  <span class="jh-unit">~</span>                       
  <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
      maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
    </template>
    <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
  </v-menu>
</div>

<!--Month-->
<v-menu v-model="menu_range_month" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
  <template v-slot:activator="{ on, attrs }">
    <v-text-field v-model="dateRangeMonth" v-bind="attrs" v-on="on" class="jh-form" append-icon="jh-icon-calendar is-blue"
      maxlength="17" @input="dateRangeMonthOnInput"
      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{4})(\d{2})$/, '$1-$2 ~ $3-$4');"></v-text-field>
  </template>
  <v-date-picker class="jh-calendar" v-model="months" type="month" no-title range locale="ko-KR" :day-format="mixin_getDate" @change="monthsOnChange"></v-date-picker>
</v-menu>`
,source02_tab02: `export default {
  data () {
    return {
        menuStartDate: false,
        menuEndDate: false,
        from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dates: [
            (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        ],

        menu_range_month: false,
        dateRangeMonth: new Date().toISOString().substr(0, 7) + " ~ " + new Date().toISOString().substr(0, 7),
        months: [
          new Date().toISOString().substr(0, 7),
          new Date().toISOString().substr(0, 7)
        ],
    }
  },
  methods: {    
    fromOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }

        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
    dateRangeMonthOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2} ~ \d{4}-\d{2}$/.test(this.dateRangeMonth)) {
        let months = this.dateRangeMonth.split(" ~ ");
        if (moment(months[0]).isAfter(months[1])) {
          months = months.sort();
          this.dateRangeMonth = months.join(" ~ ");
        }
        if (moment(months[0]).isValid() && moment(months[1]).isValid()) {
          this.months = months;
          // datepick 다시 랜더링
          this.menu_range_month = false;
          this.menu_range_month = true;
        }
      }
    },
    monthsOnChange: function(e) {
      this.dateRangeMonth = e.join(" ~ ");
    },
  },
}`
,source03_tab01: `<v-menu
  ref="menu_time"
  v-model="menu_time"
  :close-on-content-click="false"
  :return-value.sync="time"
  transition="scale-transition"
  min-width="auto"
  offset-y
>
  <template v-slot:activator="{ on, attrs }">
    <v-text-field
        v-model="time"
        v-bind="attrs"                       
        v-on="on"
        class="jh-form"
        append-icon="jh-icon-clock is-blue"
    >
    </v-text-field>
  </template>
  <v-time-picker
    class="jh-calendar"
    v-if="menu_time"
    v-model="time"
    :allowed-minutes="allowedStep"
    no-title
    @click:minute="$refs.menu_time.save(time)"
  ></v-time-picker>
</v-menu>`
,source03_tab02: `export default {
  data () {
    return {
        menu_time: false,
        time: null,
    }
  },
  methods: {
    allowedStep: (m) => m % 10 === 0,
  },
}`
      }
    }
  },
  methods: {
    allowedStep: (m) => m % 10 === 0,
    
    dateOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.inputDate) && moment(this.inputDate).isValid()) {
        this.date = this.inputDate;
        // datepick 다시 랜더링
        this.menu_date = false;
        this.menu_date = true;
      }
    },
    dateOnChange(e) {
      this.inputDate = e;
    },
    monthOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}$/.test(this.inputMonth) && moment(this.inputMonth).isValid()) {
        this.month = this.inputMonth;
        // datepick 다시 랜더링
        this.menu_month = false;
        this.menu_month = true;
      }
    },
    monthOnChange(e) {
      this.inputMonth = e;
    },
    fromOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }

        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
    dateRangeMonthOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2} ~ \d{4}-\d{2}$/.test(this.dateRangeMonth)) {
        let months = this.dateRangeMonth.split(" ~ ");
        if (moment(months[0]).isAfter(months[1])) {
          months = months.sort();
          this.dateRangeMonth = months.join(" ~ ");
        }
        if (moment(months[0]).isValid() && moment(months[1]).isValid()) {
          this.months = months;
          // datepick 다시 랜더링
          this.menu_range_month = false;
          this.menu_range_month = true;
        }
      }
    },
    monthsOnChange: function(e) {
      this.dateRangeMonth = e.join(" ~ ");
    },
    
    //calendar 포맷 정의(일자)
    mixin_getDate(date) {
      const day = date.split("-")[2];
      const day_num = Number(day);
      return day_num;
    },
  },
  computed: {
    dateRangeText () {
        return this.dates.join(' ~ ')
    },
    // dateRangeMonth(){
    //   return this.months.join(' ~ ')
    // }
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>