<template>
  <div class="guide-content"> 
      <div class="guide-content-header">Vue Components<v-icon class="mdi mdi-chevron-right"></v-icon><span>Buttons</span></div>

      <h2>Default Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn" id="" name="" disabled="disabled">버튼</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source01_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Light Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-light" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-light" id="" name="" disabled="disabled">버튼</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source02_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Fill Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-fill" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-fill" id="" name="" disabled="disabled">버튼</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source03_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Main Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-main" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-main" id="" name="" disabled="disabled">버튼</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source04_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Del Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-del" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-del" id="" name="" disabled="disabled">버튼</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source05_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Search Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-search" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-search" id="" name="" disabled="disabled">버튼</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source06_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>No Border Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-noborder" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-noborder" id="" name="" disabled="disabled">버튼</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source15_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Button Size</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-sm" id="" name="">Small</v-btn>
        <v-btn class="jh-btn" id="" name="">Default</v-btn>
        <v-btn class="jh-btn is-md" id="" name="">Medium</v-btn>
        <v-btn class="jh-btn is-lg" id="" name="">Large</v-btn>
        <v-btn class="jh-btn is-xl" id="" name="">X-Large</v-btn>
      </div>
      <div class="guide-card is-mt-5">
        <v-btn class="jh-btn is-sm is-fill" id="" name="">Small</v-btn>
        <v-btn class="jh-btn is-fill" id="" name="">Default</v-btn>
        <v-btn class="jh-btn is-md is-fill" id="" name="">Medium</v-btn>
        <v-btn class="jh-btn is-lg is-fill" id="" name="">Large</v-btn>
        <v-btn class="jh-btn is-xl is-fill" id="" name="">X-Large</v-btn>
      </div>
      <div class="guide-card is-mt-5">
        <v-btn class="jh-btn is-sm is-main" id="" name="">Small</v-btn>
        <v-btn class="jh-btn is-main" id="" name="">Default</v-btn>
        <v-btn class="jh-btn is-md is-main" id="" name="">Medium</v-btn>
        <v-btn class="jh-btn is-lg is-main" id="" name="">Large</v-btn>
        <v-btn class="jh-btn is-xl is-main" id="" name="">X-Large</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source07_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Round Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-sm is-round" id="" name="">Small</v-btn>
        <v-btn class="jh-btn is-round" id="" name="">Default</v-btn>
        <v-btn class="jh-btn is-md is-round" id="" name="">Medium</v-btn>
        <v-btn class="jh-btn is-lg is-round" id="" name="">Large</v-btn>
        <v-btn class="jh-btn is-xl is-round" id="" name="">X-Large</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source09_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Circle Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-sm is-circle" id="" name=""><i class="jh-icon-search-sm"></i></v-btn>
        <v-btn class="jh-btn is-circle" id="" name=""><i class="jh-icon-search"></i></v-btn>
        <v-btn class="jh-btn is-md is-circle" id="" name=""><i class="jh-icon-search"></i></v-btn>
        <v-btn class="jh-btn is-lg is-circle" id="" name=""><i class="jh-icon-search"></i></v-btn>
        <v-btn class="jh-btn is-xl is-circle" id="" name=""><i class="jh-icon-search"></i></v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source10_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>ETC Color Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-red" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-org" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-yellow" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-green" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-cyan" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-blue" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-indigo" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-purple" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-blueGray" id="" name="">버튼</v-btn>
        <v-btn class="jh-btn is-trns" id="" name="">버튼</v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source11_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Icon Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-icon" id="" name=""><i class="jh-icon-refresh"></i></v-btn>
        <v-btn class="jh-btn is-icon" id="" name=""><i class="jh-icon-search"></i></v-btn>
        <v-btn class="jh-btn is-icon" id="" name=""><i class="jh-icon-download"></i></v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source12_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Icon Only Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn has-icon-only" id="" name=""><i class="jh-icon-refresh"></i></v-btn>
        <v-btn class="jh-btn has-icon-only" id="" name=""><i class="jh-icon-search"></i></v-btn>
        <v-btn class="jh-btn has-icon-only" id="" name=""><i class="jh-icon-download"></i></v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source16_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Icon & Text Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn" id="" name=""><i class="jh-icon-refresh"></i><span>새로고침</span></v-btn>
        <v-btn class="jh-btn" id="" name=""><i class="jh-icon-search"></i><span>찾기</span></v-btn>
        <v-btn class="jh-btn" id="" name=""><i class="jh-icon-download"></i><span>다운로드</span></v-btn>
      </div>
      <div class="guide-card is-mt-5">
        <v-btn class="jh-btn" id="" name=""><span>새로고침</span><i class="jh-icon-refresh is-blue"></i></v-btn>
        <v-btn class="jh-btn" id="" name=""><span>찾기</span><i class="jh-icon-search is-blue"></i></v-btn>
        <v-btn class="jh-btn" id="" name=""><span>다운로드</span><i class="jh-icon-download is-blue"></i></v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source13_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <h2>Move Button</h2>
      <div class="guide-card">
        <v-btn class="jh-btn is-move" id="" name=""><i class="jh-icon-move-right"></i></v-btn>
        <v-btn class="jh-btn is-move" id="" name=""><i class="jh-icon-move-left"></i></v-btn>
        <v-btn class="jh-btn is-move" id="" name=""><i class="jh-icon-move-up"></i></v-btn>
        <v-btn class="jh-btn is-move" id="" name=""><i class="jh-icon-move-down"></i></v-btn>
        
        <v-btn class="jh-btn is-move is-md" id="" name=""><i class="jh-icon-move-right"></i></v-btn>
        <v-btn class="jh-btn is-move is-md" id="" name=""><i class="jh-icon-move-left"></i></v-btn>
        <v-btn class="jh-btn is-move is-md" id="" name=""><i class="jh-icon-move-up"></i></v-btn>
        <v-btn class="jh-btn is-move is-md" id="" name=""><i class="jh-icon-move-down"></i></v-btn>
      </div>
      <div class="guide-source">
        <v-tabs v-model="source01">
          <v-tab>Html</v-tab>
        </v-tabs>
        <v-tabs-items v-model="source01">
          <v-tab-item>
              <pre class="guide-code" v-highlightjs><code class="html">{{content.source14_tab01}}</code></pre>
          </v-tab-item>
        </v-tabs-items>
      </div>

  </div>
</template>

<script>
/*
https://vuejsfeed.com/blog/vue-js-syntax-highlighting-with-highlight-js
npm install --save highlight.js
*/
import hljs from 'highlight.js';
export default {
  name: "Buttons",
  directives: {
      'highlightjs': {
          bind: function (el, binding) {
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
                  if (binding.value) {
                      target.innerHTML = binding.value
                  }
                  hljs.highlightBlock(target)
              })
          },
          componentUpdated: function(el, binding) {
              // after an update, re-fill the content and then highlight
              let targets = el.querySelectorAll('code')
              targets.forEach((target) => {
              if (binding.value) {
                  target.textContent = binding.value
                  hljs.highlightBlock(target)
              }
              })
          }
      }
  },
  components: { 
  },
  data() {
      return {        
          source01: 'HTML',
          content:{

source01_tab01: `<v-btn class="jh-btn" id="" name="">버튼</v-btn>
<v-btn class="jh-btn" id="" name="" disabled="disabled">버튼</v-btn>`
,source02_tab01: `<v-btn class="jh-btn is-light" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-light" id="" name="" disabled="disabled">버튼</v-btn>`
,source03_tab01: `<v-btn class="jh-btn is-fill" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-fill" id="" name="" disabled="disabled">버튼</v-btn>`
,source04_tab01: `<v-btn class="jh-btn is-main" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-main" id="" name="" disabled="disabled">버튼</v-btn>`
,source05_tab01: `<v-btn class="jh-btn is-del" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-del" id="" name="" disabled="disabled">버튼</v-btn>`
,source06_tab01: `<v-btn class="jh-btn is-search" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-search" id="" name="" disabled="disabled">버튼</v-btn>`
,source07_tab01: `<v-btn class="jh-btn is-sm" id="" name="">Small</v-btn>
<v-btn class="jh-btn" id="" name="">Default</v-btn>
<v-btn class="jh-btn is-md" id="" name="">Medium</v-btn>
<v-btn class="jh-btn is-lg" id="" name="">Large</v-btn>
<v-btn class="jh-btn is-xl" id="" name="">Large</v-btn>
 
<v-btn class="jh-btn is-sm is-fill" id="" name="">Small</v-btn>
<v-btn class="jh-btn is-fill" id="" name="">Default</v-btn>
<v-btn class="jh-btn is-md is-fill" id="" name="">Medium</v-btn>
<v-btn class="jh-btn is-lg is-fill" id="" name="">Large</v-btn>
<v-btn class="jh-btn is-xl is-fill" id="" name="">Large</v-btn>
 
<v-btn class="jh-btn is-sm is-main" id="" name="">Small</v-btn>
<v-btn class="jh-btn is-main" id="" name="">Default</v-btn>
<v-btn class="jh-btn is-md is-main" id="" name="">Medium</v-btn>
<v-btn class="jh-btn is-lg is-main" id="" name="">Large</v-btn>
<v-btn class="jh-btn is-xl is-main" id="" name="">Large</v-btn>`
,source08_tab01: `<v-btn class="jh-btn is-sm is-noborder" id="" name="">Small</v-btn>
<v-btn class="jh-btn is-noborder" id="" name="">Default</v-btn>
<v-btn class="jh-btn is-md is-noborder" id="" name="">Medium</v-btn>
<v-btn class="jh-btn is-lg is-noborder" id="" name="">Large</v-btn>
<v-btn class="jh-btn is-xl is-noborder" id="" name="">X-Large</v-btn>`
,source09_tab01: `<v-btn class="jh-btn is-sm is-round" id="" name="">Small</v-btn>
<v-btn class="jh-btn is-round" id="" name="">Default</v-btn>
<v-btn class="jh-btn is-md is-round" id="" name="">Medium</v-btn>
<v-btn class="jh-btn is-lg is-round" id="" name="">Large</v-btn>
<v-btn class="jh-btn is-xl is-round" id="" name="">X-Large</v-btn>`
,source10_tab01: `<v-btn class="jh-btn is-sm is-circle" id="" name=""><i class="jh-icon-search"></i></v-btn>
<v-btn class="jh-btn is-circle" id="" name=""><i class="jh-icon-search-sm"></i></v-btn>
<v-btn class="jh-btn is-md is-circle" id="" name=""><i class="jh-icon-search"></i></v-btn>
<v-btn class="jh-btn is-lg is-circle" id="" name=""><i class="jh-icon-search"></i></v-btn>
<v-btn class="jh-btn is-xl is-circle" id="" name=""><i class="jh-icon-search"></i></v-btn>`
,source11_tab01: `<v-btn class="jh-btn is-red" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-org" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-yellow" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-green" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-cyan" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-blue" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-indigo" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-purple" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-blueGray" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-trns" id="" name="">버튼</v-btn>`
,source12_tab01: `<!-- 아이콘은 Styles > Icons에서 활용하세요-->
<v-btn class="jh-btn is-icon" id="" name=""><i class="jh-icon-refresh"></i></v-btn>
<v-btn class="jh-btn is-icon" id="" name=""><i class="jh-icon-search"></i></v-btn>
<v-btn class="jh-btn is-icon" id="" name=""><i class="jh-icon-download"></i></v-btn>`
,source13_tab01: `<!-- 아이콘은 Styles > Icons에서 활용하세요-->
<v-btn class="jh-btn" id="" name=""><i class="jh-icon-refresh"></i><span>새로고침</span></v-btn>
<v-btn class="jh-btn" id="" name=""><i class="jh-icon-search"></i><span>찾기</span></v-btn>
<v-btn class="jh-btn" id="" name=""><i class="jh-icon-download"></i><span>다운로드</span></v-btn>
<v-btn class="jh-btn" id="" name=""><span>새로고침</span><i class="jh-icon-refresh is-blue"></i></v-btn>
<v-btn class="jh-btn" id="" name=""><span>찾기</span><i class="jh-icon-search is-blue"></i></v-btn>
<v-btn class="jh-btn" id="" name=""><span>다운로드</span><i class="jh-icon-download is-blue"></i></v-btn>`
,source14_tab01: `<v-btn class="jh-btn is-move" id="" name=""><i class="jh-icon-move-right"></i></v-btn>
<v-btn class="jh-btn is-move" id="" name=""><i class="jh-icon-move-left"></i></v-btn>
<v-btn class="jh-btn is-move" id="" name=""><i class="jh-icon-move-up"></i></v-btn>
<v-btn class="jh-btn is-move" id="" name=""><i class="jh-icon-move-down"></i></v-btn>
 
<v-btn class="jh-btn is-move is-md" id="" name=""><i class="jh-icon-move-right"></i></v-btn>
<v-btn class="jh-btn is-move is-md" id="" name=""><i class="jh-icon-move-left"></i></v-btn>
<v-btn class="jh-btn is-move is-md" id="" name=""><i class="jh-icon-move-up"></i></v-btn>
<v-btn class="jh-btn is-move is-md" id="" name=""><i class="jh-icon-move-down"></i></v-btn>`
,source15_tab01: `<v-btn class="jh-btn is-noborder" id="" name="">버튼</v-btn>
<v-btn class="jh-btn is-noborder" id="" name="" disabled="disabled">버튼</v-btn>`
,source16_tab01: `<v-btn class="jh-btn has-icon-only" id="" name=""><i class="jh-icon-refresh"></i></v-btn>
<v-btn class="jh-btn has-icon-only" id="" name=""><i class="jh-icon-search"></i></v-btn>
<v-btn class="jh-btn has-icon-only" id="" name=""><i class="jh-icon-download"></i></v-btn>`
          }
      }
  },
  methods: {
  },
  computed: {
  },
  mounted() {  
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>