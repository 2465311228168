<template>
  <div>      
    <ul class="header-status is-call">
        <li class="is-header">Call</li>
        <li>
          <label>고객대기</label>
          <span class="is-value is-imp is-txt-red">{{ callStatus.CUST_WAIT || 0 }}</span>
        </li>
        <li>
          <label>직원대기</label>
          <span class="is-value is-imp is-txt-blue">{{ callStatus.CNCL_WAIT || 0 }}</span>
        </li>
        <li>
          <label>인입</label>
          <span class="is-value">{{ callStatus.INCOMING || 0 }}</span>
        </li>
        <li>
          <label>응답</label>
          <span class="is-value">{{ callStatus.RESPONSE || 0 }}</span>
        </li>
        <li>
          <label>포기</label>
          <span class="is-value">{{ callStatus.GIVEUP || 0 }}</span>
        </li>
      </ul>
      <ul class="header-status is-chat">
        <li class="is-header">Chat</li>
        <li>
          <label>고객대기</label>
          <span class="is-value is-imp is-txt-red">{{ chatStatus.CUST_WAIT || 0 }}</span>
        </li>
        <li>
          <label>인입</label>
          <span class="is-value">{{ chatStatus.INCOMING || 0 }}</span>
        </li>
        <li>
          <label>응답</label>
          <span class="is-value">{{ chatStatus.RESPONSE || 0 }}</span>
        </li>
        <li>
          <label>포기</label>
          <span class="is-value">{{ chatStatus.GIVEUP || 0 }}</span>
        </li>
      </ul>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import api from "@/store/apiUtil";

import { eventBus }  from "@/store/eventBus.js";

export default {
  mixins: [mixin],
  components: {},
  data: () => ({
    callStatus: {},
    chatStatus: {}
  }),
  computed: {},
  methods: {
    init: function() {

      const w = new Worker('/worker.js');


      // const w1 = new Worker('/worker.js');

      w.postMessage("나 통계이야!!!");

      w.addEventListener('message', function(e){
        eventBus.busFnWorker();  
      }, false);


      // w1.postMessage("나 통계이야!!!");

      // w1.addEventListener('message', function(e){
      //   eventBus.busFnWorker();  
      // }, false);

      // this.getStatus();
      // setInterval(() => {
      //   this.getStatus();
      // }, 3000); // 3초 주기
    },
    getStatus: function() {
      let requestData = {
        headers: {
          SERVICE: "cti",
          TYPE: "BIZ_SERVICE",
        },
        sendData: {},
      };

      // header 세팅
      requestData.headers["URL"] = "/api/status-board/status/list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      let USER_ATTR_A = this.$store.getters['userStore/GE_USER_ROLE'].USER_ATTR_A;
      requestData.sendData["USER_ATTR_A"] = USER_ATTR_A;

      this.common_postCall(requestData, false).then((response) => {
        if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
          // this.common_alert("시스템에 오류가 발생하였습니다.", "error");
          return false;
        }

        let header = response.HEADER;
        let data = response.DATA;
        
        this.callStatus = data?.[0]?.CALL_STATUS?.[USER_ATTR_A] ?? { CUST_WAIT: 0, CNCL_WAIT: 0, INCOMING: 0, RESPONSE: 0, GIVEUP: 0 };
        this.chatStatus = data?.[0]?.CHAT_STATUS ?? { CUST_WAIT: 0, INCOMING: 0, RESPONSE: 0, GIVEUP: 0 };

        eventBus.$emit('boardNotReadySet', data?.[0]?.CALL_STATUS?.[USER_ATTR_A] ?? { NOTREADY: 0, NOTREADY_LONG: 0, NOTREADY_SHORT: 0});
      });
    }
  },
  mounted() {
    this.init();
  },
  created() {
    // eventBus.$on('busFnWorker', () => {
    //   this.getStatus();
    // });

  }
};
</script>

<style></style>
