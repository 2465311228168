<template>
  <v-app-bar height="60px" class="jh-layout-header">
    <div class="is-left">
      <v-btn class="jh-btn-menu" @click.stop="$emit('drawer')"></v-btn>      
      <!-- <span class="is-logo" title="이베스트투자증권"></span> -->
      <!-- <MiniBoard></MiniBoard> -->
      <div class="jh-cti-group">
      <span class="is-kakao" title="오픈채팅방"></span>
      <!-- <v-btn class="jh-btn-cti" @click="fnHoldCall" ><span class="is-kakao" title="카카오톡 상담하기"> </span></v-btn> -->
              <v-btn class="jh-btn is-logout" :href="`https://open.kakao.com/o/grKvMFlf/`" target="_blank" style="margin-right:30px">              
                <span>오픈채팅방</span>
              </v-btn>

              <v-btn class="jh-btn is-logout"  @click="logout()">              
                <i class="jh-icon-header is-user"></i>
                <span class="is-name">{{ user_name }}</span>
                <i class="jh-icon-header is-logout"></i>
              </v-btn>              
        <!-- <i class="jh-icon-cti is-ready"></i> -->
        <!-- <img :src="src/assets/img/kakao-opentalk.png"> -->
      <!-- <v-btn class="jh-btn-cti" id="btn_fnss_Ready" :class="[ctiReady ? 'is-active' : '']" @click="ctiStt('1')" :disabled="ctiReady">
        <i class="jh-icon-cti is-ready"></i><span>대기</span></v-btn> -->
        <!-- <i class="jh-icon-cti is-ready"></i><span>대기</span> -->
      <!-- <v-btn class="jh-btn-cti" id="btn_fnss_Ready" v-if="btnReady" @click="fnFnssReady" :disabled="ctiReady"><i class="jh-icon-cti is-ready"></i><span>대기</span></v-btn>
      <v-btn class="jh-btn-cti is-ready is-active"  v-if="!btnReady" id="btn_fnss_NReady" @click="fnNonFnssReady" :disabled="nonReady"><i class="jh-icon-cti is-ready"></i><span>대기해제</span></v-btn>
      <v-btn class="jh-btn-cti" id="btn_MakeCall" :class="[ctiCall ? 'is-active' : '']" @click="callLayer" :disabled="ctiCall"><i class="jh-icon-cti is-call"></i><span>걸기</span></v-btn>
      <v-btn class="jh-btn-cti" id="btn_Trans" :class="[ctiTransfer ? 'is-active' : '']" @click="fnTransfer" :disabled="ctiTransfer"><i class="jh-icon-cti is-transfer"></i><span>호전환</span></v-btn>
      <v-btn class="jh-btn-cti" id="btn_DropCall" :class="[ctiHangUp ? 'is-active' : '']" @click="fnDropCall" :disabled="ctiHangUp"><i class="jh-icon-cti is-hangup"></i><span>끊기</span></v-btn>
      <v-btn class="jh-btn-cti" id="btn_Hold" :class="[ctiHold ? 'is-active' : '']" @click="fnHoldCall" :disabled="ctiHold"><i class="jh-icon-cti is-hold"></i><span>{{ctiHoldBtnText}}</span></v-btn>
      <v-btn class="jh-btn-cti" id="btn_ARS" :class="[ctiArs ? 'is-active' : '']" @click="fnArs" :disabled="ctiArs"><i class="jh-icon-cti is-ars"></i><span>ARS</span></v-btn>
      <v-btn class="jh-btn-cti" id="btn_Absent" :class="[ctiStepOutMenu ? 'is-active' : '']" @click="fnChgStateClick" :disabled="ctiStepOut"><i class="jh-icon-cti is-stepout"></i><span>상태변경</span></v-btn> -->
      <!-- <div class="is-state" :class="[ctiChat ? 'is-chat' : '']">{{this.ctiSttNm}}</div> -->
      <!-- <div class="is-state" :class="this.ctiSttCss">{{this.ctiSttNm}}</div> -->
      
        <!--
          로그인 is-bg-org
          통화중 is-bg-red
          채팅중 is-chat
          후처리중 is-bg-gray
          업무확인중 is-bg-org
          면담/회의 중 is-bg-purple
          점심시간 is-bg-pink
          코칭/교육 중 is-bg-blueGray
          이석중(5분 ↓) is-bg-light-yellow
          이석중(5분 ↑) is-bg-yellow
          업무 TEST 중 is-bg-green
        -->
        <!-- <div class="is-timer" data-step="1">{{this.TM_SS_FRM}}</div> -->
        <!-- 전화걸기  dropdown 메뉴 -->
        <!-- <transition name="slide-down">					
          <div class="jh-drop-layer" style="top: 30px;" v-show="MakeCallLayer" v-click-outside="outsideClick2">
            <div class="jh-drop-layer-header">
              <h2>전화걸기</h2>
              <div class="is-right">
                <v-btn class="jh-btn is-icon" @click="MakeCallLayer = !MakeCallLayer"><i class="jh-icon-close"></i></v-btn>
              </div>
            </div>
            <div class="jh-drop-layer-body is-bg-light-blue">
              <table>
                <colgroup>
                  <col width="75px">
                  <col>
                </colgroup>
                <tr>
                  <th><input type="radio" class="jh-form" name="rdoCallNum" id="rdo1"  value="1" v-model="selCall" ><label for="rdo1">자택전화</label></th>
                  <td><input type="text" class="jh-form is-md is-bg-white" :value="mixin_newPhoneFormat(HOME_TNO)"  @click="selCall=1" @keyup.enter="fnMakeCall(HOME_TNO)" disabled></td>
                </tr>
                <tr>
                  <th><input type="radio" class="jh-form" name="rdoCallNum" id="rdo2" value="2" v-model="selCall" ><label for="rdo2">직장전화</label></th>
                  <td><input type="text" class="jh-form is-md is-bg-white" :value="mixin_newPhoneFormat(OFFICE_TNO)" @click="selCall=2"  @keyup.enter="fnMakeCall(OFFICE_TNO)" disabled></td>
                </tr>
                <tr>
                  <th><input type="radio" class="jh-form" name="rdoCallNum" id="rdo3" value="3" v-model="selCall" ><label for="rdo3">휴대전화</label></th>
                  <td><input type="text" class="jh-form is-md is-bg-white" :value="mixin_newPhoneFormat(MOV_TNO)" @click="selCall=3" @keyup.enter="fnMakeCall(MOV_TNO)" disabled></td>
                </tr>
                <tr>
                  <th><input type="radio" class="jh-form" name="rdoCallNum" id="rdo4" value="4" v-model="selCall"><label for="rdo4">직접입력</label></th>
                  <td><input type="text" class="jh-form is-md is-bg-white" v-model="DIRECT_TNO" @click="selCall=4" @keyup="keyUpTelInp(DIRECT_TNO)" @keyup.enter="fnMakeCall(DIRECT_TNO)"></td>
                </tr>
              </table>
            </div>
            <div class="jh-drop-layer-footer">
              <v-btn class="jh-btn is-md is-light" @click="MakeCallLayer = !MakeCallLayer">취소</v-btn>
              <v-btn class="jh-btn is-md is-del" @click="fnMakeCall('')">전화걸기</v-btn>
            </div>
          </div>
        </transition> -->
        <!-- 상태변경  dropdown 메뉴 -->
        <!-- <transition name="slide-down">					
          <div class="jh-cti-layer" v-show="ctiStepOutMenu" v-click-outside="outsideClick">
            <v-btn class="jh-btn is-trns" :class="{'is-active' : activeIndex === index }" v-for="(item, index) in stepOutMenu" @click.stop="setActiveStepOutMenu(index)"><i class="jh-icon-check-md"></i><span>{{item.text}}</span></v-btn>
          </div>
        </transition> -->
         <!-- <i class="jh-icon-kakao"></i> -->

<!-- 
        <v-tooltip content-class="jh-tooltip" bottom nudge-top="5">
            <template >
              <v-btn class="jh-btn is-logout" v-bind="attrs" v-on="on" @click="logout()">              
                <i class="jh-icon-header is-user"></i>
                <span class="is-name">{{ user_name }}</span>
                <i class="jh-icon-header is-logout"></i>
              </v-btn>
            </template>
            <span>로그아웃</span>
        </v-tooltip> -->
      </div>
      <!-- <div class="stepout-state">
        <label>이석현황</label>
        <ul>
          <li class="is-imp"><label>총이석</label><span class="is-value">{{NOTREADY}}</span></li>
          <li><label>장(5분<i class="jh-icon-header is-arrow-up"></i>)</label><span class="is-value">{{NOTREADY_LONG}}</span></li>
          <li><label>단(5분<i class="jh-icon-header is-arrow-down"></i>)</label><span class="is-value">{{NOTREADY_SHORT}}</span></li>
        </ul>
      </div> -->
      <!-- <div class="is-btn-group">
        <v-btn class="jh-btn is-light" @click="openCallRecordPopup"><i class="jh-icon-header is-record"></i><span>녹취</span></v-btn>
        <v-btn class="jh-btn is-light" :disabled="!REMOTE_ID && user_id != '7007'" @click="openRemotePopup"><i class="jh-icon-header is-remote"></i><span>원격</span></v-btn>
        <v-btn class="jh-btn is-light" @click="openSTTPopup"><i class="jh-icon-header is-stt"></i><span>STT</span></v-btn>
      </div> -->

      <!-- <div>
        <i class="jh-icon-kakao"></i>

      </div> -->

    </div>
    <!-- <div class="is-user-group">
      <span v-if=false> -->
        <!--v-btn class="jh-btn is-light" @click="fnCallList">상태</v-btn-->
        <!-- <div>
        <v-btn class="jh-btn is-sm is-light" @click="fnTransfer">호전환</v-btn> -->
          <!--v-btn class="jh-btn is-light" @click="fnChgServer">서버변경</v-btn-->
          <!--v-btn class="jh-btn is-light" @click="this.finesseHandler1.finesseHandler.getUserState">USER</v-btn-->
          <!--btn class="jh-btn is-light" @click="fnTrnsCsltStt('5000')">호상태</v-btn-->
          <!--v-btn class="jh-btn is-light" @click="fnSetCall">콜변</v-btn-->
          <!-- <v-btn class="jh-btn is-sm is-green" @click="sendToChild(1)" style="width: 30px;">일</v-btn>
          <v-btn class="jh-btn is-light" @click="finesseUserSignOut('9991','0911')">사2</v-btn>
          <v-btn class="jh-btn is-sm is-light" @click="valueTest">vTest</v-btn> 
        </div>
      </span> -->

      <!-- <v-tooltip content-class="jh-tooltip" bottom nudge-top="5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="jh-btn is-logout" v-bind="attrs" v-on="on" @click="logout()">              
              <i class="jh-icon-header is-user"></i>
              <span class="is-name">{{ user_name }}</span>
              <i class="jh-icon-header is-logout"></i>
            </v-btn>
          </template>
          <span>로그아웃</span>
      </v-tooltip> -->
      <!-- <v-btn class="jh-btn is-cti" :class="[CTI_ON ? 'is-active' : '']" v-text="CTI_ON ? 'CTI OFF' : 'CTI ON'" :disabled="isLoading" :loading="isLoading" @click="toggleCTI(false)"></v-btn> -->

      <!-- <WinPopup ref="winPopup" @onClose="val=>evtCloseWinPopup(val)" @onRecvEvtFromWinPop="val=>onRecvTopWinPop(val)">
      </WinPopup>
    </div> -->
    <!-- <div class="d-flex">
      <div class="align-center d-flex">
        <span class="layout-top-avatar" @click="showDialog('TopAreaP01')">
          <div class="layout-top-avatar--icon">
            <v-icon class="svg-profile" v-bind:class="{
                f01: isf01,
                f02: isf02,
                f03: isf03,
                f04: isf04,
                m01: ism01,
                m02: ism02,
                m03: ism03,
                m04: ism04,
              }" />
          </div>
          <span class="ml-1 mr-2"></span>
        </span>
        <v-btn width="14px" height="14px" elevation="0" class="btn-logoff" @click="logout()">
          <v-icon class="svg-logoff"></v-icon>
        </v-btn>
      </div>
      <v-btn elevation="0" class="btn-cti-on">cti On</v-btn>
    </div> -->
    <!-- 
      <v-btn color="success" rounded small @click="checkUserStore('user_role')">
      userStore.user_role 확인
      </v-btn>
    <v-btn
      color="success ml-3"
      rounded
      small
      @click="checkUserStore('user_menu')"
    >
      userStore.user_menu 확인
    </v-btn> 
    <div class="layout-top--msg-wrap">
      <span class="layout-top--msg">
        {{ topMsg }}
      </span>
      <span class="layout-top--msg layout-top--msg-repeat">
        {{ topMsg }}
      </span>
    </div>
    -->
    <!-- 상담사 모달 -->
    <!-- <v-dialog max-width="796" persistent content-class="square-modal modal-profile" v-model="dialogTopAreaP01">
      <dialog-TopAreaP01 @hide="hideDialog('TopAreaP01')" @submit="submitDialog('TopAreaP01')"
        @modalPass="passDialog('TopAreaP02')" :param="userParam" :agentInfo="agentInfo" @input="agentInfo = $event"
        @registAgent="[hideDialog('TopAreaP01'), setAgent()]">
      </dialog-TopAreaP01>
    </v-dialog> -->
    
    <!-- 비밀번호 변경 모달 -->
    <v-dialog
      max-width="400"
      persistent
      content-class="jh-dialog-wrap"
      v-model="dialogTopAreaP02"
    >
      <div class="jh-dialog-header">
          <h1>비밀번호 변경</h1>
          <div class="is-right">
              <v-btn class="jh-btn is-icon" title="닫기" @click="closePassowrdDialog"><i class="jh-icon-close-lg is-white"></i></v-btn>
          </div>
      </div>
      <div class="jh-dialog-body">
        <dl class="jh-list-form">
            <dt><label class="">아이디</label></dt>
            <dd>
                <v-text-field class="jh-form is-lg" placeholder="아이템명" v-model="USER_ID" maxLength="18" disabled></v-text-field>                
            </dd>
            <dt><label class="is-required">기존 비밀번호</label></dt>
            <dd>
              <v-text-field type="password" class="jh-form is-lg" placeholder="기존 비밀번호" v-model="PASSWORD_OLD" maxLength="18"></v-text-field>  
            </dd>
            <dt><label class="is-required">새 비밀번호</label></dt>
            <dd>
              <v-text-field type="password" class="jh-form is-lg" placeholder="새 비밀번호" v-model="PASSWORD_NEW" maxLength="18"></v-text-field>  
            </dd>
            <dt><label class="is-required">새 비밀번호 재확인</label></dt>
            <dd>
              <v-text-field type="password" class="jh-form is-lg" placeholder="새 비밀번호 재확인" v-model="PASSWORD_NEW2" maxLength="18"></v-text-field>  
            </dd>
        </dl>
        <div class="jh-info-txt is-blue is-mt-10">특수문자는 포함 8자리 이상</div>
        <div class="jh-info-txt is-blue">특수문자는 ‘ !, @, #, -$, & ’만 사용 가능</div>
      </div>
      <div class="jh-dialog-footer">
        <v-btn class="jh-btn is-lg" @click="closePassowrdDialog">취소</v-btn>
        <v-btn class="jh-btn is-lg is-main" @click="checkPassword">비밀번호 변경</v-btn>
      </div>
    </v-dialog>
    <!--// 비밀번호 변경 모달 -->

    <!-- 상태변경 모달 -->
    <!-- <v-dialog max-width="420" v-model="dialogStatusUser" persistent @keydown.space="endDialog" @keydown.enter="endDialog">
      <div class="jh-alert">
        <div class="jh-alert-body">
          <div class="jh-alert-badge" :class="this.ctiSttCss">{{ctiMsg}}입니다.</div>
        </div>
        <div class="jh-alert-btn">
          <v-btn class="jh-btn is-lg is-fill" @click="endDialog">착석</v-btn>
        </div>
      </div>
    </v-dialog> -->

    <v-dialog max-width="420" v-if="dialogP100201" v-model="dialogP100201" persistent @keydown.enter="fnAnswerCall" @keydown.space="fnAnswerCall">
      <dialog-P100201 ref="recPop" :receive="receive" :rcvPopMode="rcvPopMode" @input="rcvPopMode = $event" @hide="fnAnswerCall()"></dialog-P100201>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import DialogP100201 from "@/views/page/P100201.vue";
// import DialogTopAreaP01 from "@/views/page/TopAreaP01.vue";
import WinPop from "@/views/popup/WinPop.vue";
import MiniBoard  from "@/views/page/H810241.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import api from "@/store/apiUtil";
import { apiCall } from "@/store/callUtil.js";
import { eventBus }  from "@/store/eventBus.js";
import Vue from "vue";
import $ from "@/cti/jquery-3.2.1.js";
import $1 from "@/cti/jquery-3.2.1_min.js";
import $2 from "@/cti/jquery.cookie.js";
// import * as finesseCore from "@/cti/finesse_core_v2.0.js";
// import * as finesseParser from "@/cti/finesse_parser_v2.0.js";
// import * as finesseHandler from "@/cti/finesse_handler_v2.0.js";
import axios from "axios";

// Vue.use(finesseCore);
// Vue.use(finesseHandler);
// Vue.use(finesseParser);

// import { jabberwerx  }  from "@/utils/jabberwerx.js"
const sha256 = require("sha256");
const crypto = require("crypto");

const FINESSE_PUB_URL  = process.env.VUE_APP_FINESSE_PUB_URL;
const FINESSE_SUB_URL  = process.env.VUE_APP_FINESSE_SUB_URL;

// const cWorker = new Worker('/callWorker.js');

// const RDWP_SERVER_URL1 = 'https://172.17.10.160';
// const RDWP_SERVER_URL2 = 'https://172.17.10.161';
// const RDWP_SERVER_URL3 = 'https://172.17.11.160';
// const RDWP_SERVER_URL4 = 'https://172.17.11.161';

// const RDWP_UDT_PORT    = '18080';
// const RDWP_PLAY_PORT   = '18088';

const RDWP_SERVER_URL1 = process.env.VUE_APP_RDWP_SERVER_URL1;
const RDWP_SERVER_URL2 = process.env.VUE_APP_RDWP_SERVER_URL2;
const RDWP_SERVER_URL3 = process.env.VUE_APP_RDWP_SERVER_URL3;
const RDWP_SERVER_URL4 = process.env.VUE_APP_RDWP_SERVER_URL4;

const RDWP_UDT_PORT    = process.env.VUE_APP_RDWP_UDT_PORT;
const RDWP_PLAY_PORT   = process.env.VUE_APP_RDWP_PLAY_PORT;

export default {
  name: "ParentVue",
  components: {
    // DialogTopAreaP01,
    MiniBoard,
    DialogP100201,
    'WinPopup' : WinPop
  },
  computed: {
    ...mapGetters({
      user_name: "userStore/GE_USER_NAME",
      user_id: "userStore/GE_USER_ID",
      INLNE_NO: "userStore/GE_USER_INLNE_NO",
      geCustInfo: 'callStore/GE_CUST_INFO',
      geCtiInfo: 'callStore/GE_CTI_INFO',
      geUserRole: 'userStore/GE_USER_ROLE',
      geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
      geVMSPlayYn: 'callStore/GE_PLAY_YN',
      gePinTypeNo: 'callStore/GE_PIN_TYPE_NO',
      geCallBdttm: 'callStore/GE_CALL_BDTTM',
      geCallStt: 'callStore/GE_CALL_STATE',
      geCnslSaveCnt: 'callStore/GE_CNSL_SAVE_CNT',
      geSintongClear: 'callStore/GE_SINTONG_CLEAR',
      //topMsg: "userStore/GE_USER_TOPMSG",
      REMOTE_ID: 'userStore/GE_USER_REMOTE_ID',      
      geCustInfoPrev: 'callStore/GE_CNSL_CUST_PREV',    
      geCnslCustInout: 'callStore/GE_CNSL_CUST_INOUT',             
    }),
  },
  mixins: [mixin],
  data: () => ({
    // finesseHandler1 : finesseHandler,
    ENCRYPT_KEY: "",
    PASSWORD_NEW: "",
    PASSWORD_NEW2: "",
    PASSWORD_OLD: "",
    // dialogTopAreaP01: false, //상담사 모달
    dialogTopAreaP02: false, //비밀번호 변경 모달 

    isLoading: false,
    CLOG_FLAG: true,

    asp_cust_key: '001',
    USER_ID: '', //로그인한 사용자 ID
    USER_NM: '', //로그인한 사용자 이름
    rcvPopMode: '',

    NOTREADY: "0",
    NOTREADY_LONG: "0",
    NOTREADY_SHORT: "0",

    params:[],
    agentInfo: {
      AGENT_NM:'',
      INNER_IP:'',
      //AGENT_NM:this.$store.getters["userStore/GE_USER_ROLE"].userName,
      //INNER_IP:this.$store.getters["userStore/GE_USER_ROLE"].inner_ip,
      AGENT_PW:'',
      MSG:'',
    },

    receive:{},           //받기 이벤트
    makeCall:{},
    transfer:{},

    rdwtInfo:{},

    tmpInfo:{
      index: 0,
      CALL_ID: '',
      RDWT_ID: '',
      FROM_TNO: '',
      STT_TIME: '',
      END_TIME: '',
      CALL_TYPE: ''
    },

    param:{
      id: "TOP"
    },

    cti_info:{
      CTI_MODE: false,  // CTI모드(on, off)
      USER_NM: '',
      PASSWORD: '',
      CALL_TYPE: '',
      IPCC_NO: '',
      LOGINFLAG: 0,
      REASON_CD: '',
      EXTENSION: ''
    },
    
    ctiInOut : '',        //CTI IN/OUT
    ctiAgentId:'',        //CTI AGENT_ID
    ctiStation:'',        //CTI_STATION 
    ctiIp:'',             //CTI IP
    ctiPort:'',           //CTI PORT

    callSttTime:'',
    callEndTime:'',
    ctiChkStt:'',
    ctiChkReasonCd:'',

    ESTABLISHED: true,
    btnReady: true,   //대기&대기해제 버튼 visible

    //CTI Active
    ctiReady: false,
    ctiCall: true,
    nonReady: true,
    ctiTransfer: false,
    ctiHangUp: false,
    ctiHold: false,
    ctiArs: false,
    ctiStepOut: false,
    ctiStepOutMenu: false,
    ctiChat: false,
    MakeCallLayer: false,

    MakeCallCheck1: '',

    HOME_TNO: '',
    OFFICE_TNO: '',
    MOV_TNO: '',
    DIRECT_TNO: '',
    selCall: '3',
    REGIST_ADDR_CD: '',

    RDWT_ID: '',          //녹취키 YYMMDDHHmmss+callID+station(내선번호)
    NOW_CALL_TYPE: '',    //현재 CALL_TYPE
    CURR_PINPAD_TYPE: '', //현재 PINPAD_TYPE
    CURR_CALL_ID: '', 
    TRNS_CALL_ID: '',
    CURR_CONF_YN: "N",    //3자통화여부
    TRANS_YN: false,
    CALLYN: "N",
    trnsInf: '',
    TRNS_IPCC_NO: "",

    ctiStt: '',
	  ctiSttNm: '',
    ctiSttCss: '',
    ctiHoldBtnText: '보류',
    callTime: 0,
    inboundNum: "",
    outboundNum: "",
    ctiParams: [
      {"name" : "callVariable1","value": ""}
     ,{"name" : "callVariable2","value": ""}
     ,{"name" : "callVariable3","value": ""}
     ,{"name" : "callVariable4","value": ""}
     ,{"name" : "callVariable5","value": ""}
     ,{"name" : "callVariable6","value": ""}
     ,{"name" : "callVariable9","value": ""}  //호전환번호
     ,{"name" : "user.QSTIME"     ,"value": ""}
     ,{"name" : "user.HOSTERRCODE","value": ""}
     ,{"name" : "user.REAGTID"    ,"value": ""}
     ,{"name" : "user.ENDTYPE"    ,"value": ""}
     ,{"name" : "user.PWDTYPE"    ,"value": ""}
     ,{"name" : "user.PWDRESULT"  ,"value": ""}
     ,{"name" : "user.ACCTNUM"    ,"value": ""}
     ,{"name" : "user.ACCTNAME"   ,"value": ""}
     ,{"name" : "user.ACCTPWD"    ,"value": ""}
     ,{"name" : "user.RRNO"        ,"value": ""}
     ,{"name" : "user.MOBILE"     ,"value": ""}
     ,{"name" : "user.MEM"        ,"value": ""}
     ,{"name" : "user.CSTANI"     ,"value": ""}
    ],

    disableYn: [false, false, false, false, false, false, false, false],  // BTN CTR
    cnsltHistCheck: false,
    CALL_START: true,
    ctiMsg: "이석중",
    ctiExpand: false,
    ctiMenuActive: null,
    CTI_TM_SS: 0,
    TM_SS_FRM: "00:00:00",
    ctiRetryCnt: 0,
    CTI_STT: '',
    CONFF_FLAG: false,
    ARS_FROM_NO: '',
    stepOutMenu: [
      { text: "업무확인 중" , value:"27", ctiSttCss:'is-bg-org', popup:false, isActive: true},
      { text: "면담/회의 중", value:"28", ctiSttCss:'is-bg-blue', popup:true, },
      { text: "점심시간"    , value:"29", ctiSttCss:'is-bg-pink',popup:true, },
      { text: "코칭/교육 중",    value:"30", ctiSttCss:'is-bg-indigo', popup:false,},
      { text: "이석중 ( 5분 ↓)", value:"31", ctiSttCss:'is-bg-light-yellow',popup:true, },
      { text: "이석중 ( 5분 ↑)", value:"32", ctiSttCss:'is-bg-yellow',popup:true, },
      { text: "업무 TEST 중"   , value:"33", ctiSttCss:'is-bg-purple',popup:true, },
    ],
    activeIndex: 9,
    REQ_DN_NUM: '',
    dialogStatusUser: false,

    dialogP100201: false,

    isBtnActive: false,
    ctiExpand: false,
    ctiMenuActive: null,
    ctiMenu: [
      {
        className: "cti-btn-expand-icon01",
        text: "회의",
      },
      {
        className: "cti-btn-expand-icon02",
        text: "면담",
      },
      {
        className: "cti-btn-expand-icon03",
        text: "식사",
      },
      {
        className: "cti-btn-expand-icon04",
        text: "휴식",
      },
    ],
    userParam: {
      test: "oK",
    },
    csIcon: "",
    agentInfo: {
      ICON: "",
      MSG: "",
      OPTION: "",
    },
    topMsg: "",
    isf01: false,
    isf02: false,
    isf03: false,
    isf04: false,
    ism01: false,
    ism02: false,
    ism03: false,
    ism04: false,

    CTI_ON: false,

    TO_TNO: '',
  }),
  created() {
    /**
     * 창 닫힘 시 CTI 로그아웃
     */
    // eventBus.$on('busCloseWindowCtiLogout', () => {
    //   this.toggleCTI(true);
    // });

    /**
     * 이석현황등록
     */
    // eventBus.$on('boardNotReadySet', (item) => {
    //   this.boardNotReadySet(item);
    // });

    // cWorker.onmessage = (msg) => {
    //   if(!this.mixin_isEmpty(msg.data.END_TIME)) {
    //     //console.log("WW main", msg.data)
    //     if(this.geCtiInfo.CALL_ID == msg.data.CALL_ID) {
    //       if(!this.mixin_isEmpty(msg.data.STT_TIME) && msg.data.STT_TIME != this.geCtiInfo.STT_TIME) {
    //         this.geCtiInfo.STT_TIME = msg.data.STT_TIME
    //       }

    //       if(!this.mixin_isEmpty(msg.data.END_TIME) && msg.data.END_TIME != this.geCtiInfo.END_TIME) {
    //         this.geCtiInfo.END_TIME = msg.data.END_TIME
    //       }
    //     }
    //   }
    // }

    // cWorker.postMessage({'CALL_ID':'123456'
    //   ,'RDWT_ID':'RR111'
    //   ,'STT_TIME':'20230910'
    //   ,'END_TIME':''
    //   ,'CALL_TYPE':'OUT'
    // })

    /**
     * 전화 받기 이벤트버스
     */
    // eventBus.$on('receiveCall', () => {
    //   alert("EVTBUS.receiveCall");
    //   //this.showDialog('P100201');
    // });

    // eventBus.$on('ctiStt', (state) => {
    //   if(state == "CLEAN") {
    //     this.cleanCallInf("CLEAN");
    //   } else if(state == "CLEAN") {
    //     this.fnCtiStt(state);
    //   } else {
    //     this.fnCtiStt(state);
    //   }
    // });

    // top영역 요청처리
    // eventBus.$on('reqTopPrc', (gbn) => {
    //   //console.log("@@@@@@@TOPEVENT======reqTopPrc======>" + gbn);
    //   if(gbn == "closeTrnsPop") {
    //     console.log("##### TOP 팝업 CLOSE!!!");
    //     this.$refs.winPopup.closeWinPop();
        
    //   }else if(gbn == "setTrnsVal") {
    //     this.setTrnsValue();
    //   }
    //   else {
    //     console.log("**TOP 요청처리 구분값 확인 필요[" + gbn + "]");
    //   }
    // });
    // // 후처리 상태 처리
    // eventBus.$on('stateAcw', (state) => {
    //     this.ESTABLISHED = false;
    // });
    // eventBus.$on('makeCall', (callNum) => {
    //   if(!this.cti_info.CTI_MODE) {
    //     this.common_alert(`CTI 로그아웃 상태입니다.`, 'error');
    //     return false;
    //   }
    //   if(!this.callSttCheck("MAKECALL")) return false;
    //   this.paramKey = callNum;

      
    //   this.cti_info.IVR_IN_NO    = "";
    //   this.cti_info.IVR_IN_PATH  = "";
    //   this.cti_info.IVR_STD_TIME = "";
    //   this.cti_info.ERR_MENT     = "";
    //   this.cti_info.IVR_IN_CD    = "";
    //   this.cti_info.IVR_SERV_NO  = "";
    //   this.cti_info.BEGIN_TM  = "";
    //   this.cti_info.END_TM  = "";
      
    //   this.cti_info.val1     = "";
    //   this.cti_info.val6     = "";
    //   this.cti_info.sCstAni  = "";
    //   this.cti_info.errCd    = "";
    //   this.cti_info.val9     = "";
    //   this.cti_info.ACCT_NO  = "";
    //   this.cti_info.RRNO     = "";
    //   this.$store.commit('callStore/MU_CTI_INFO', this.cti_info);

    //   // this.cti_info.CALL_TYPE = "OUT";
    //   // this.$store.dispatch('callStore/AC_CTI_INFO', this.cti_info); //콜상태값 갱신
    //   this.fnMakeCall(this.paramKey);
    // });
    // // finesse 데이타 처리
    // // eventBus.$on('sendFnssData', (sMsg, data) => {
    // //     if(sMsg == "USTATE") {
    // //       this.ctiChkStt = data.state;
    // //       this.ctiChkReasonCd = data.reasonCode;
    // //       return;
    // //     }

    // //     this.fnFinesseData(sMsg, data);
    // // });

    // eventBus.$on('sendFnssLog', (sMsg, data) => {
    //     //this.appendLog(sMsg, data);
    // });    
    // // finesse 서비스 요청처리
    // eventBus.$on('ctiSendReq', (sType, sVal) => {
    //   console.log("==>ctiSendReq:" + sType + "/" + sVal);

    //   //console.log("@@CTI:"+ this.cti_info.CTI_MODE);
    //   try {
    //     /* 사용자 로그아웃 처리 */
    //     if(sType == "userSignOUt") {

    //       if(this.mixin_isEmpty(sVal)) {
    //         this.common_toast("사용자 ID 정보가 없습니다!!", 'info');
    //         console.log("사용자 ID 정보가 없습니다!!");
    //         return false;
    //       }

    //       this.finesseUserSignOut(sVal);
    //       return true;
    //     }

    //     if(!this.cti_info.CTI_MODE) {
    //       this.common_alert(`CTI OFF 상태입니다.`, 'error');
    //       return false;
    //     }

    //     if(this.ctiStt != "TALKING" && sType != 'userSignOUt') {
    //       this.common_alert(this.ctiSttNm + ` 상태입니다. 통화중에만 \n요청 가능합니다.`, 'error');
    //       return false;
    //     }
    //     /* 전환요청 */
    //     if(sType === 'consultCall') {
    //       if(this.mixin_isEmpty(sVal)) {
    //         this.common_toast("전환 요청 번호가 없습니다!!", 'info');
    //         console.log("전환 요청 번호가 없습니다!!");
    //         //this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
    //         return false;
    //       }
    //       //this.CURR_CONF_YN = "Y";  //자동 conferenceCall 여부
    //       if(sVal == "65090" || sVal == "65091" || sVal == "65092" || sVal == "65093") {
    //         this.PINPAD_YN = "Y";
    //       } else {
    //         this.PINPAD_YN = "N";
    //       }

    //       console.log("%%%%conferenceCall");
    //       this.CONFF_FLAG = true;
    //       this.TRANS_YN = false;
    //       this.NOW_CALL_TYPE = "CONSULT";
    //       this.finesseHandler1.finesseHandler.consultCall(sVal, this.CURR_CALL_ID, this.ctiParams);
    //     } else if(sType === 'consultCall2') {
    //       if(this.mixin_isEmpty(sVal)) {
    //         this.common_toast("전환 요청 번호가 없습니다!!", 'info');
    //         console.log("전환 요청 번호가 없습니다!!");
    //         return false;
    //       }
    //       // this.CURR_CONF_YN = "Y";  //자동 conferenceCall 여부
    //       this.finesseHandler1.finesseHandler.ssTransferCall(sVal, this.CURR_CALL_ID , this.ctiParams);
    //     /* 전환취소 */
    //     } else if(sType === 'reconnect') {
    //       this.finesseHandler1.finesseHandler.reconnect(this.CURR_CALL_ID, this.TRNS_CALL_ID);
    //     /* 호전환 */
    //     } else if(sType === 'transferCall') {
    //       console.log("here<=======transferCall========" + this.CURR_CALL_ID + "/"+ sVal);
    //       this.finesseHandler1.finesseHandler.transferCall(this.CURR_CALL_ID, sVal);
    //     /* 삼자통화 */
    //     } else if(sType === 'conferenceCall') {
    //       this.finesseHandler1.finesseHandler.conferenceCall(this.CURR_CALL_ID);
    //     /* 1step 전환 */
    //     } else if(sType === 'ssTransferCall') {
    //       if(this.mixin_isEmpty(sVal)) {
    //         this.common_toast("전환 요청 번호가 없습니다!!", 'info');
    //         console.log("전환 요청 번호가 없습니다!!");
    //         return false;
    //       }
    //       this.finesseHandler1.finesseHandler.ssTransferCall(sVal, this.CURR_CALL_ID , this.ctiParams);
    //     } else {
    //       this.common_toast("CTI 요청 서비스 코드를 확인하세요!!", 'info');
    //       console.log("CTI 요청 서비스 코드를 확인하세요!!");
    //       return false;
    //     }
    //   }catch(err) {
    //     console.log("#################################################################FINESSE요청처리오류!!");
    //     this.common_alert('핀패드 처리중 오류 발생<br>잠시후에 다시 시도해주세요.', 'error');
    //     console.log(err);
    //   }finally{
    //     console.log("#################################################################FINESSE요청처리완료!!");
    //   }

    // });
  },
  beforeDestroy(){
    // eventBus.$off('ctiStt');
    // eventBus.$off('sendFnssData');
    // eventBus.$off('ctiSendReq');
  },
  
  
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.disconnect());
  },



  mounted() {

    window.addEventListener('beforeunload', function(event) {

      // let arrPop = this.$store.getters["popStore/GE_POP"];
      //   console.log("######### arrPop  ", arrPop);

      // for(var i = 0; i < arrPop.length; i ++){
      //   console.log("######### arrPop[]  ", arrPop[i]);

      //   let obj = arrPop[i];
      //   obj.close();
      // }

      eventBus.busCloseWindowCtiLogout();  
      // axios.post('/api/phone/ars/list');
      let routerPath = window.location.pathname
      // let saqrfwqr = this.$store.getters["commonStore/GE_COMMON_APP_LOADING"];
      // console.log("!saqrfwqr   ", saqrfwqr)

      // let a = this.$store.getters["commonStore/GE_COMMON_TAB"]
      // console.log("!routerPath   ", routerPath)
      // console.log("!routerPath.includes()   ", !routerPath.includes("P"))
      // console.log("this.$store.getters[commonStore/GE_COMMON_POP]   ", a)
      // console.log("result   ", this.$store.getters["commonStore/GE_COMMON_POP"] == routerPath)
      // let popUp = this.getPop()
      // console.log("popUp", popUp)
      this.$store.commit("authStore/MU_CHECK_PWD_UPD", false);
      this.$store.commit("authStore/MU_CHAT_MODE", false);

      if(!routerPath.includes("P") && window.sessionStorage.getItem("jsessionid") && window.sessionStorage.getItem("token")){
      // if(!routerPath.includes("P")){
          // if(this.popUp == routerPath && window.sessionStorage.getItem("jsessionid") && window.sessionStorage.getItem("token")){
        
        api
        .post("api/logout", {
          ACCESS_TOKEN: window.sessionStorage.getItem("token"),
          JSESSIONID: window.sessionStorage.getItem("jsessionid")
        })
        .then((response) => {
          //로그아웃 처리
          commit("MU_AUTH_LOGOUT_RESULT", set_data);
          window.sessionStorage.clear();

          response_data = response;
          console.log("logout.success", response_data);
          console.log("logout.success", success);
          // eventBus.unloadLogout();

        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("logout.error", error);

          set_data.code = -1;
          //로그인에러 처리
          commit("MU_AUTH_LOGIN_RESULT", set_data);
        });
      }
    })



    // this.initMsg();
    // this.initIcon();
    // // this.init();
    // this.setRdwtInfo();
    // this.initCtiInfo();
    //this.setCtiChk();
  },
  methods: {
    // getCtiChk() {
    //   console.log("@@@@ctiInterval:["+ this.ctiInterval+"]");
    //   console.log("@@@@ctiChkStt:["+ this.ctiChkStt+"]");
    //   console.log("@@@@ctiReasonCd:["+ this.ctiChkReasonCd+"]");
    // },
    // setCtiChk() {
    //   this.ctiInterval = setInterval(() => {
    //     this.ctiRetryCnt += 1;
    //     console.log("ctiRetryCnt:["+ this.ctiRetryCnt+"]");
    //     this.finesseHandler1.finesseHandler.UserInfo();
    //   }, 20000); //20초마다
    // },
    // boardNotReadySet(item){
    //   //console.log(`boardNotReadySet call item : ` + JSON.stringify(item))
    //   this.NOTREADY = item.NOTREADY ?? 0
    //   this.NOTREADY_LONG = item.NOTREADY_LONG ?? 0
    //   this.NOTREADY_SHORT = item.NOTREADY_SHORT ?? 0
    // },
    // async downloadLog(){
    //   let date = new Date();

    //   let Y = date.getFullYear();
    //   let M = date.getMonth();
    //   let D = date.getDate();
    //   let H = date.getHours();
    //   let Min = date.getMinutes();
    //   let S = date.getSeconds();

    //   H = H < 10 ? ('0' + H) : H;
    //   Min = Min < 10 ? ('0' + Min) : Min;
    //   S = S < 10 ? ('0' + S) : S;

    //   let filename = "로그파일_" + Y + M + D + "_" + H + ":" + Min + ":" + ":" + S + ".txt"

    //   const a = document.createElement('a');
    //   const type = filename.split(".").pop();
    //   a.href = URL.createObjectURL(new Blob([this.$store.getters["logStore/GE_LOG"]], {type:`test/${type === "txt" ? "plain" : type}` }));
    //   a.download = filename;
    //   a.click();

    // },
    // async appendLog(sFuncNm, objData){

    //   let date = new Date();

    //   let Y = date.getFullYear();
    //   let M = date.getMonth();
    //   let D = date.getDate();
    //   let H = date.getHours();
    //   let Min = date.getMinutes();
    //   let S = date.getSeconds();

    //   H = H < 10 ? ('0' + H) : H;
    //   Min = Min < 10 ? ('0' + Min) : Min;
    //   S = S < 10 ? ('0' + S) : S;

    //   try {
    //     let data = "";
    //     if (typeof objData === "object") {
    //       data = "시간 : " + Y + ' ' + M + ' ' + D + '  ' + H + ':' + Min + ":" + S + "    |     api ["+sFuncNm+"]:"
    //               + JSON.stringify(objData) + "\n";
    //     } else {
    //       data = "시간 : " + Y + ' ' + M + ' ' + D + '  ' + H + ':' + Min + ":" + S + "    |     api ["+sFuncNm+"]:"
    //               + objData + "\n";
    //     }

    //     this.$store.commit("logStore/MU_LOG", data);
    //   } catch(ex) {
    //     //console.log(">>>>apped error??[" + ex + "]");
    //   }
    // },
    // setRdwtInfo() {
    //   this.rdwtInfo.rdwt_udt_url1 = RDWP_SERVER_URL1 + ":" + RDWP_UDT_PORT;
    //   this.rdwtInfo.rdwt_udt_url2 = RDWP_SERVER_URL2 + ":" + RDWP_UDT_PORT;
    //   this.rdwtInfo.rdwt_udt_url3 = RDWP_SERVER_URL3 + ":" + RDWP_UDT_PORT;
    //   this.rdwtInfo.rdwt_udt_url4 = RDWP_SERVER_URL4 + ":" + RDWP_UDT_PORT;

    //   this.rdwtInfo.rdwt_play_url1 = RDWP_SERVER_URL1 + ":" + RDWP_PLAY_PORT;
    //   this.rdwtInfo.rdwt_play_url2 = RDWP_SERVER_URL2 + ":" + RDWP_PLAY_PORT;
    //   this.rdwtInfo.rdwt_play_url3 = RDWP_SERVER_URL3 + ":" + RDWP_PLAY_PORT;
    //   this.rdwtInfo.rdwt_play_url4 = RDWP_SERVER_URL4 + ":" + RDWP_PLAY_PORT;

    //   this.rdwtInfo.rdwt_udt_stt_url = "/callServer/updatecall/open/common";
    //   this.rdwtInfo.rdwt_udt_end_url = "/callServer/updatecall/close/common";
    //   this.rdwtInfo.rdwt_paly_url = "/provider/key/play";

    //   this.$store.commit('callStore/MU_RDWT_INFO', this.rdwtInfo);
    // },
    // initCtiInfo() {
    //   this.cti_info = {};
    //   this.cti_info.USER_NM = this.geUserRole.userName;
    //   this.cti_info.IPCC_NO = this.geUserRole.IPCC_NO;
    //   this.cti_info.CALL_ID = "";
    //   this.cti_info.USER_ID = this.geUserRole.userId;
    //   this.cti_info.PASSWORD = this.geUserRole.userId;
    //   this.cti_info.CTI_MODE = false;
    //   this.cti_info.CALL_TYPE = "";
    //   this.cti_info.RDWT_ID = "";
    //   this.cti_info.TRNS_CON_GUBUN = "";
    //   this.cti_info.TRNS_YN = "";
    //   this.cti_info.TRNS_CSLT_ID = "";
    //   this.cti_info.TRNS_NO = "";

    //   if(this.cti_info.IPCC_NO == "0911") {
    //     this.cti_info.USER_ID = "9991";
    //     this.cti_info.PASSWORD = "9991";
    //   } else if(this.cti_info.IPCC_NO == "0912") {
    //     this.cti_info.USER_ID = "9992";
    //     this.cti_info.PASSWORD = "9992";
    //   } else if(this.cti_info.IPCC_NO == "0913") {
    //     this.cti_info.USER_ID = "9993";
    //     this.cti_info.PASSWORD = "9993";
    //   }
    //   this.$store.commit('callStore/MU_CTI_INFO', this.cti_info); 

    //   //let ctiInfo = this.$store.getters["callStore/GE_CTI_INFO"];

    //   console.log("*CTI_MODE["+ this.geCtiInfo.CTI_MODE+"]" + this.getDateTime());

    //   // CTI 사용 체크 후 로그인
    //   let cookie_CTIChk = this.getCookie("CTI_CHK");
    //   if (cookie_CTIChk == "true") {
    //    this.CTI_ON = true;
    //    this.isLoading = true;
       
    //     //this.finesseLogin();

    //      this.login_toggleCTI(false);

    //   }
    //   this.receive = {};  //전화받기

    //   // if(this.ctiChat) {
    //   //   this.ctiSttNm = "채팅중";
    //   //   this.ctiSttCss = 'is-chat';
    //   // }
    //   if(this.cti_info.CTI_MODE) {this.fnCtiStt('0') }
    //   else { this.fnCtiStt('Z') }
    // },
    // //key값으로 콜변수 가져오기
    // getCallVariable(callInfo, key){
    //   let rtnVal;
    //   let callVariables = callInfo.callVariables;
    //   try {
    //     for(let i=0; i<callVariables.length; i++){
    //       if(callVariables[i].name == key){
    //         rtnVal = callVariables[i].value;
    //         console.log("KEY:[" + key + "]:" + rtnVal);
    //       }
    //     }
    //   } catch(ex) {
    //     console.log(">>>>val error??[" + ex + "]");
    //   }
    //   return rtnVal;
    // },
    // async fnTimeConlog(msg) {
    //   try{
    //     if(this.CLOG_FLAG) {
    //       let svrDateTime = await this.mixin_getSvrDate("YYYYMMDDHH24MISS");

    //       let svrDate = svrDateTime.substr(0, 8);
    //       let svrTime = svrDateTime.substr(8, 16);

    //       console.log("[" + svrDate + " " + svrTime + "] " + msg);
    //     } else {
    //       console.log(msg);
    //     }
    //   } catch (ex) {
    //     console.log("*************************************fnTimeConlog 에러발생****************************************************************");
    //     console.log(msg);
    //   }
    // },
    // async fnFinesseData(sMsg, data) {
    //   this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
    //   let svrDateTime = this.getDateTime();
    //   try {
    //     if(sMsg == "teams") {
    //       this.$refs.winPopup.sendEvtToChild( { msg: 'teams', sData: data} );
    //       return;
    //     } else if(sMsg == "callId") {
    //       console.log(">>>>CALLID CHG>>"+ data);
    //       this.CURR_CALL_ID = data;
    //       return;
    //     } else if(sMsg == "ERRMSG") {
    //       console.log(">>>>CTI ERROR>>"+ data);
    //       this.common_alert(`콜 ` + data, 'info');
    //       return;
    //     }

    //     if (typeof data === "object") {

    //       if(data.DNIS == 65100 || data.DNIS == 65130 || data.DNIS == 65131 || data.DNIS == 65132) {
    //         console.log("&&ARS!!CALLID :" + data.id +" >>>>> state   : "+state);
    //         console.log("&&ARS!!CALLID :" + data.id +" >>>>> frmAddr : "+frmAddr);
    //         return;
    //       }          

    //       console.log("["+svrDateTime+ "]###################################################################################IVRREP STT");
    //       //console.log(JSON.stringify(data));

    //       let callId    = data.id;
    //       let state     = data.state;
    //       let frmAddr   = "";
    //       let dialNum   = "";
    //       let toAddress = "";
    //       let checkPost = "";
    //       let callType  = "";
    //       let participants = data.participants;	//상담원 Action 배열

    //       try {
    //         frmAddr   = data.fromAddress;
    //         toAddress = data.toAddress;
    //         checkPost = data.event;
    //         callType  = data.callType;
    //         dialNum   = data.dialedNumber;
    //         // participants = data.participants;	//상담원 Action 배열
    //       } catch(ex) {
    //         console.log("fnFinesseData ERR::" + ex);
    //       }
       
    //       // 20230425 수화기 들었다 놨을 때 (BREWDA)
    //       if(callType=="AGENT_INSIDE" && frmAddr == this.cti_info.IPCC_NO && state=="INITIATING") {
    //         console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&수화기 끊음!!");
    //         this.CURR_CALL_ID = "";
    //         this.NOW_CALL_TYPE = "";
    //         return;
    //       }
    //       console.log("#######IVR_REP_DATA CALLID :"+ data.id +" >>>>> state : "+state + "<=====================REPDATA=====================>");
    //       // console.log("**CALL CALLTYPE:"+ callType + ", NOW_CALL_TYPE:" + this.NOW_CALL_TYPE  );
    //       // console.log("**CALL FROMADDR:"+ frmAddr  + ", TOADDR:" + toAddress  );
    //       // console.log("**CALL STATE:"+ state       );
    //       console.log("**CALL EVENT:"+ checkPost   );
    //       if(!this.mixin_isEmpty(callId) && this.mixin_isEmpty(this.CURR_CALL_ID)) {
    //         console.log("^^^^^^^^^^^^^^^^^^^^^^^^^CALLIN!!!!state[" + state + "]");
    //         this.CURR_CALL_ID = callId;
    //         this.cti_info.CALL_ID = callId;
    //         this.cti_info.FROM_TNO = data.fromAddress;
    //         await this.fnSttTime();       //시작시간셋팅
    //         await this.crtRdwtId(callId); //녹취아이디 생성

    //         // cWorker.postMessage({index:0
    //         //   ,'CALL_ID':callId
    //         //   ,'RDWT_ID':this.RDWT_ID
    //         //   ,'FROM_TNO':frmAddr
    //         //   ,'STT_TIME':this.cti_info.BEGIN_TM
    //         //   ,'END_TIME':this.cti_info.END_TM
    //         //   ,'CALL_TYPE':this.geCnslCustInout
    //         // })
    //         // //this.appendLog("$$$NEW_CALL_ID", this.CURR_CALL_ID);
    //       }

    //       // } else if(callType=="PREROUTE_ACD_IN") {
    //       //    this.cti_info.CALL_TYPE = "IN";
    //       //  }
 

    //     //  if(callType=="OUT" || (callType=="AGENT_INSIDE" && state == "FAILED")) {
    //     //    this.cti_info.CALL_TYPE = "OUT";
    //     //     if(state=="INITIATED") {
    //     //       this.fnCtiStt("2R");
    //     //     }
    //     //   } else if(callType=="AGENT_INSIDE" || callType=="PREROUTE_ACD_IN") {
    //     //      this.cti_info.CALL_TYPE = "IN";
    //     //   }

    //     // if(this.mixin_isEmpty(this.cti_info.CALL_TYPE)){

    //     //   //나의 내선번와 비교 같으면.
    //     //   if(frmAddr == this.cti_info.IPCC_NO) {
    //     //     this.cti_info.CALL_TYPE = "OUT";
    //     //   } else {
    //     //     this.cti_info.CALL_TYPE = "IN";
    //     //   }
    //     // }
    //     this.cti_info.CALL_TYPE = this.geCnslCustInout

    //     if(!this.mixin_isEmpty(this.CURR_CALL_ID) && this.mixin_isEmpty(this.RDWT_ID)) {
    //       console.log("^^^^^^^^^^^^^^^^^^^^^^^^^RDWTIN!!!!state[" + state + "]");
    //       await this.crtRdwtId(callId); //녹취아이디 생성
    //       // //this.appendLog("$$$NEW_RDWT_ID", this.CURR_CALL_ID);
    //     }

    //     if(!this.mixin_isEmpty(this.CURR_CALL_ID) && this.mixin_isEmpty(this.cti_info.BEGIN_TM)) {
    //       console.log("^^^^^^^^^^^^^^^^^^^^^^^^^STTTIME!!!!state[" + state + "]");
    //       await this.fnSttTime();
    //       // //this.appendLog("$$$NEW_STT_TIME", this.CURR_CALL_ID);
    //     }

    //     if(this.CALLYN == "Y" && state=="ALERTING") {
    //       console.log("이미 전화받기중!!!" + this.CURR_CALL_ID);
    //       if(this.dialogP100201) {
    //           this.hideDialog('P100201');
    //       }
    //       return false;
    //     }


    //     // let iCnt = 1;

    //     // // 전화기로 받기 : DROPPED 제한.
    //     // let p_state_ca  = "";
    //     // for(let item of participants) {
    //     //     // console.log("mediaAddr시발로마:[" + iCnt +"]" + JSON.stringify(item));
    //     //     let callType_na = data.callType;
    //     //     let p_mediaAddress_na = item.mediaAddress;
    //     //     let p_state_na = item.state;
                          
    //     //     //본인에 대한 처리
    //     //     if(p_mediaAddress_na == this.cti_info.IPCC_NO){ 
    //     //         if(p_state_na == "DROPPED"){
    //     //               p_state_ca = p_state_na;
    //     //           break;
    //     //         }else if(p_state_na == "ACTIVE"){
    //     //               p_state_ca = p_state_na;
    //     //           break;
    //     //         }
    //     //     }
    //     // }



    //     //전화기로 받기  
    //     if(state=="ALERTING" &&  this.ctiStt == "TALKING" && checkPost == "PUT") {
    //       console.log("======================####TALKING 처리 되었음!!!" + this.CURR_CALL_ID);
    //       if(this.dialogP100201) {
    //         console.log("======================전화받기 처리 되었음!!!" + this.CURR_CALL_ID);
    //         this.fnAnswerCall("N");  //전화받기
    //         return;
    //       }
    //       return false;
    //     }

    //    console.log("$$$ACTIVE 통화 확인용 시점!!!OUTPIN:[" + this.NOW_CALL_TYPE + "], CALL_TYPE:[" + callType + "], state:[" + state + "]" + this.CONFF_FLAG);
    //       if(this.NOW_CALL_TYPE == "CONFERENCE" && callType!="CONFERENCE" && state=="ACTIVE") {
    //         if(this.CONFF_FLAG) {
    //           console.log("##### 3자 통화 완료!!!OUTPIN:[" + this.PINPAD_YN + "], CALL_TYPE:[" + callType + "] #####");
    //           this.CONFF_FLAG = false;
    //           this.cti_info.END_TM    = "";              
    //           if(this.PINPAD_YN == "Y") {
    //             console.log("-----------------------------------------P1");
    //             this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
    //             eventBus.getIvrRcv('9');
    //           } else {
    //             console.log("-----------------------------------------P11");
    //             //this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
    //             this.$store.dispatch('callStore/AC_PLAY_YN', "N");
    //             eventBus.sendVmsData('N');
    //           }
    //         }
    //       } else {
    //         console.log("-----------------------------------------E22");
    //         let iCnt = 1;

    //         for(let item of participants) {
    //           //console.log("mediaAddr:[" + iCnt +"]" + JSON.stringify(item));
    //           let callType = data.callType;
    //           let p_mediaAddress = item.mediaAddress;
    //           let p_state = item.state;
    //           let p_actions = item.actions;
    //           let p_mediaAddressType = item.mediaAddressType ;

    //           // //this.appendLog("item[" + iCnt + "]", "Call Id : "+data.id+" >>>>> callType : "+data.callType+", state : "+p_state +", p_mediaAddress : "+p_mediaAddress+", action : "+p_actions);
    //           //console.log("item[" + iCnt + "]" +"mediaAddr:" + p_mediaAddress +", P_state : "+p_state+ ",IPCCNO:"+this.cti_info.IPCC_NO);
    //           console.log("@@participants[" + iCnt + "] {mediaAddr:" + p_mediaAddress +", P_state : "+p_state + "}" );
    //           if (iCnt == 2 && (p_mediaAddress == this.TO_TNO)) {
    //             this.$store.dispatch('callStore/AC_TO_TNO', this.TO_TNO);
    //           }
            
    //           //본인에 대한 처리
    //           if(p_mediaAddress == this.cti_info.IPCC_NO){ 
    //             if(state=="ALERTING") {
    //               if(p_state=="ALERTING") {
    //                 console.log("##### 1-1  벨소리 알람중 #####" + this.NOW_CALL_TYPE + "/ID:" + data.id);
    //                 //this.NOW_CALL_TYPE = callType;
    //                 this.CURR_CALL_ID = callId;
    //                 this.cti_info.CALL_ID   = data.id;
    //                 this.cti_info.TRNS_YN = "N";

    //                 if(!this.mixin_isEmpty(data.id)) {
    //                   let val1 = this.getCallVariable(data, "callVariable1");  //인입대표번호
    //                   let val2 = this.getCallVariable(data, "callVariable2");  //DNIS (65000: 1588-2428 대표, 82-2-3276-9800 해외인입콜 국제전화)
    //                   let val3 = this.getCallVariable(data, "callVariable3");  //최초인입번호 1192
    //                   let val4 = this.getCallVariable(data, "callVariable4");  //스킬명 J_ACCOUNT
    //                   let val5 = this.getCallVariable(data, "callVariable5");  //스킬코드 65200
    //                   let val6 = this.getCallVariable(data, "callVariable6");  //인입경로코드 30000
    //                   let val7 = this.getCallVariable(data, "callVariable7");  
    //                   let val8 = this.getCallVariable(data, "callVariable8");  
    //                   let val9 = this.getCallVariable(data, "callVariable9");  //호전환시 입력값 리턴
    //                   let qsTime    = this.getCallVariable(data, "user.QSTIME");
    //                   let errCd     = this.getCallVariable(data, "user.HOSTERRCODE");
    //                   let reAgtId   = this.getCallVariable(data, "user.REAGTID");  // 마지막 통화한 상담원ID
    //                   let jType     = this.getCallVariable(data, "user.JTYPE");

    //                   // 고객인증정보
    //                   let sEndType  = this.getCallVariable(data, "user.ENDTYPE");   //ICM과 IVR간의 인터페이스 변수 MAIN : ARS 첫번째, REDIRECT:IPT 호전환, END : 종료
    //                   let sCompType = this.getCallVariable(data, "user.PWDTYPE");   //인증유형(1:계좌번호, 2:주민번호)
    //                   let sRst      = this.getCallVariable(data, "user.PWDRESULT"); //인증결과(N:미인증  , Y:인증성공)
    //                   let sAcctNo   = this.getCallVariable(data, "user.ACCTNUM");   //고객계좌번호(인증성공시)
    //                   let sAcctNm   = this.getCallVariable(data, "user.ACCTNAME");  //계좌명(인증성공시)
    //                   let sAcctPwd  = this.getCallVariable(data, "user.ACCTPWD");   //계좌비밀번호(인증성공시)
    //                   let sRrn      = this.getCallVariable(data, "user.RRN");       //고객주민번호(인증성공시)
    //                   let sMobileNo = this.getCallVariable(data, "user.MOBILE");    //고객휴대폰번호(인증성공시)
    //                   let sPhnNumYn = this.getCallVariable(data, "user.MEM");       //폰넘버메모리등록여부(Y:등록, N:미등록)
    //                   let sCstAni   = this.getCallVariable(data, "user.CSTANI");    //고객전화번호

    //                   //기존정보 초기화
    //                   this.cti_info.FROM_TNO    = "";
    //                   this.cti_info.IVR_SERV_NO = "";
    //                   this.cti_info.IVR_IN_NO   = "";
    //                   this.cti_info.IVR_IN_PATH = "";
    //                   this.cti_info.IVR_STD_TIME = "";
    //                   this.cti_info.IVR_MENT     = "";

    //                   let trnsIpccNo = "";
    //                   let trnsCsltId = "";
    //                   let trnsYn     = "";
    //                   let trnsConGbn = "";

    //                   this.trnsInf = this.cti_info.IPCC_NO + "," + this.cti_info.CALL_TYPE + "," +  this.geUserRole.userId;

    //                   //호전환 변수 처리 1191,out,9991
    //                   if(checkPost == "POST"){
    //                     if(!this.mixin_isEmpty(val9) && this.mixin_isEmpty(this.ARS_FROM_NO)) {
    //                       this.cti_info.TRNS_GBN = "TT";
    //                       await this.fnSttTime();       //시작시간셋팅
    //                       trnsYn = "Y";
    //                       let splTrnsInf = val9.split(",");
    //                       for(var i in splTrnsInf)  {
    //                         console.log(">>>>>"+ i + "[" + splTrnsInf[i] + "]");
    //                         if(i==0) {
    //                           trnsIpccNo = splTrnsInf[i];
    //                           this.TRNS_IPCC_NO =  splTrnsInf[i];
    //                         }
    //                         else if(i==1) {
    //                           trnsConGbn = splTrnsInf[i];
    //                           this.cti_info.CALL_TYPE = splTrnsInf[i];
    //                         }
    //                         else if(i==2) {
    //                           trnsCsltId = splTrnsInf[i];
    //                         }
    //                       }
    //                     }
    //                   }

    //                   console.log("------------------------------------------------------receive.val1:"+val1);
    //                   if(sEndType == "IPT") {}
    //                   this.ctiParams = [
    //                     {"name" : "callVariable1","value": val1}
    //                     ,{"name" : "callVariable2","value": val2}
    //                     ,{"name" : "callVariable3","value": val3}
    //                     ,{"name" : "callVariable4","value": val4}
    //                     ,{"name" : "callVariable5","value": val5}
    //                     ,{"name" : "callVariable6","value": val6}
    //                     ,{"name" : "callVariable9","value": this.trnsInf}  //호전환번호
    //                     ,{"name" : "user.QSTIME"     ,"value": qsTime}
    //                     ,{"name" : "user.HOSTERRCODE","value": errCd}
    //                     ,{"name" : "user.REAGTID"    ,"value": reAgtId}
    //                     ,{"name" : "user.ENDTYPE"    ,"value": "IPT" }
    //                     ,{"name" : "user.PWDTYPE"    ,"value": sCompType}
    //                     ,{"name" : "user.PWDRESULT"  ,"value": sRst     }
    //                     ,{"name" : "user.ACCTNUM"    ,"value": sAcctNo  }
    //                     ,{"name" : "user.ACCTNAME"   ,"value": sAcctNm  }
    //                     ,{"name" : "user.ACCTPWD"    ,"value": sAcctPwd }
    //                     ,{"name" : "user.RRNO"        ,"value": sRrn     }
    //                     ,{"name" : "user.MOBILE"     ,"value": sMobileNo}
    //                     ,{"name" : "user.MEM"        ,"value": sPhnNumYn}
    //                     ,{"name" : "user.CSTANI"     ,"value": sCstAni  }
    //                   ];
    //                   console.log("------------------------------------------------------receive.val2:"+val2);

    //                   //this.finesseHandler1.finesseHandler.setCallData(this.ctiParams);

    //                   let svrDate = await this.mixin_getSvrDate("YYYYMMDD");
    //                   let svrTime = await this.mixin_getSvrDate("HH24MISS");

    //                   let svrDtm = svrDate+svrTime;
    //                   let diffTime = 0;

    //                   if(this.mixin_isEmpty(qsTime)) qsTime = svrDtm;
                      
    //                   if(!this.mixin_isEmpty(qsTime)&& !this.mixin_isEmpty(svrDtm)) {
    //                     let diffMS = await this.fnDiffTime(qsTime,svrDtm);

    //                     if(diffMS == 0)  diffMS =1;
    //                     let diffSS   = Math.floor(diffMS%60);
    //                     let diffMin  = Math.floor(diffMS/60%60);
    //                     let diffHour = Math.floor(diffMS/60/60%60);

    //                     diffHour = (diffHour<10)?"0"+diffHour:diffHour;
    //                     diffMin  = (diffMin<10)?"0"+diffMin:diffMin;
    //                     diffSS   = (diffSS<10)?"0"+diffSS:diffSS;

    //                     diffTime =  diffHour + ":" + diffMin + ":" + diffSS;
    //                   }

    //                   this.cti_info.CALL_ID   = data.id;
    //                   this.cti_info.FROM_TNO  = sCstAni;
    //                   this.cti_info.IVR_STD_TIME = diffTime;
    //                   this.cti_info.TEL_TIME = 0;
    //                   this.cti_info.SKILL_NAME = "";
    //                   this.cti_info.SKILL_NO = "";

    //                   if(!this.mixin_isEmpty(val1)) this.cti_info.IVR_IN_NO  = val1;
    //                   if(!this.mixin_isEmpty(val4)) this.cti_info.SKILL_NAME = val4;
    //                   if(!this.mixin_isEmpty(val5)) this.cti_info.SKILL_NO   = val5;

    //                   if(this.mixin_isEmpty(sCstAni)) {
    //                     this.cti_info.FROM_TNO  =  frmAddr;
    //                   }


    //                   //if(this.mixin_isEmpty(sMobileNo)) sMobileNo  = sCstAni;

    //                   //this.$store.commit("callStore/MU_CTI_INFO", this.cti_info);
    //                   console.log("------------------------------------------------------receive.errCd:"+errCd);

    //                   this.receive = {};  //전화받기

    //                   this.receive.IVR_IN_NO   = "";
    //                   this.receive.IVR_IN_CD   = "";
    //                   /*전화받기 셋팅*/
    //                   this.receive.IVR_IN_NO   = val1;
    //                   this.receive.IVR_IN_CD   = val6;
    //                   this.receive.ERR_CD      = errCd;
    //                   this.receive.STD_TIME    = this.cti_info.IVR_STD_TIME;
    //                   this.receive.CUST_NM     = "미인증고객";
    //                   if(this.mixin_isEmpty(sCstAni)) {
    //                     this.receive.inboundNum  = frmAddr;
    //                   } else {
    //                     this.receive.inboundNum  = sCstAni;
    //                   }

    //                   this.cti_info.val1     = val1;  // 인입번호
    //                   this.cti_info.val6     = val6;  // 인입경로
    //                   this.cti_info.val9     = val9;  // 인입경로
    //                   this.cti_info.errCd        = errCd;
    //                   this.cti_info.CUST_NM      = "미인증고객";
    //                   this.cti_info.sCstAni      = sCstAni;
    //                   this.cti_info.frmAddr      = frmAddr;
    //                   this.cti_info.IVR_IN_NO    = val1;

    //                   if(!this.mixin_isEmpty(trnsIpccNo)) {
    //                     this.receive.IVR_IN_CD = trnsIpccNo;
    //                     this.cti_info.IVR_IN_CD = trnsIpccNo;
    //                   } else {
    //                     console.log("------------------------------------------------------val6:["+val6+"]");
    //                   }
    //                   this.receive.CALL_TYPE = this.cti_info.CALL_TYPE
    //                   this.receive.COMP_TYPE = sCompType;
    //                   this.receive.COMP_RST  = sRst;
    //                   this.receive.ACCT_NO   = sAcctNo;
    //                   this.receive.ACCT_NM   = sAcctNm;
    //                   this.receive.ACCT_PWD  = sAcctPwd;
    //                   this.receive.RRNO      = sRrn;
    //                   this.receive.MBL_NO    = sCstAni;
    //                   this.receive.PHNNUM_YN = sPhnNumYn;

    //                   //this.$refs.recPop.initData();

    //                   this.rcvPopMode = "init";

    //                   // //this.appendLog("receivePop", JSON.stringify(this.receive));
    //                   this.mvCallMain();
    //                   this.showDialog('P100201');
    //                 }
    //               //통화중
    //               }else if(p_state=="DROPPED"){
    //                 console.log("##### 1-2 벨소리 알람중 고객이 전화를 끊음 #####");
    //                 this.hideDialog('P100201');
    //                 if(this.TRANS_YN && callType!="TRANSFER") {
    //                   this.sendToChild(1);
    //                   this.finesseHandler1.finesseHandler.retrieveCall(this.CURR_CALL_ID);
    //                 } else {
    //                   /**
    //                    * 전화 인입 팝업창 10초 동안 안받은 경우   reasonCdoe 32767
    //                    * 전화 인입 중에 고객이 끊은 경우
    //                    * 고객 전화번호와 콜타입 넣어줌
    //                    **/
    //                   await this.fnEndTime(); // 통화종료시간
    //                   this.common_confirmHistRegist(`통화가 종료되었습니다.`, this.cnsltHistUpdateEndTime, null, this.cnsltHistUpdateEndTime, null, 'info');
    //                   this.finesseHandler1.finesseHandler.userChangeStateReason("NOT_READY", "34");
    //                   //if(this.mixin_isEmpty(this.geCustInfo.CLNT_NO) && this.mixin_isEmpty(this.geCustInfo.SRCH_CL_CID)) {
    //                      let pVal = {
    //                        COMP_TYPE: "E"
    //                        ,ACCT_NO: ""
    //                      }
    //                     eventBus.setIvrInf(pVal);
    //                     this.geCtiInfo.IVR_IN_NO    = "";
    //                     this.geCtiInfo.IVR_IN_PATH  = "";
    //                     this.geCtiInfo.IVR_STD_TIME = "";
    //                     this.geCtiInfo.ERR_MENT     = "";
    //                     this.geCtiInfo.CALL_TYPE    = "IN";                        
    //                     this.geCtiInfo.FROM_TNO     = frmAddr;
    //                     this.$store.dispatch("callStore/AC_CUST_INOUT", "IN");
    //                     this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");
    //                   //}
    //                 }
    //               }else if(p_state=="INITIATED" && callType=="PREROUTE_ACD_IN"){
    //                 console.log("##### 1-2-1 벨소리 알람중 인입 PREROUTE_ACD_IN #####callId==>" + callId);
    //                 this.CURR_CALL_ID = callId;
    //                 //btnOnList.splice(btnOnList.indexOf("DROP"),1);	//전화끊기 비활성
    //                 //btnOnList.push("RECONNECT");						        //전화취소활성
    //               }else if(p_state=="INITIATED" && callType=="AGENT_INSIDE"){
    //                 console.log("##### 1-2-2 벨소리 알람중 인입 AGENT_DEVICE #####callId==>" + callId);
    //                 this.CURR_CALL_ID = callId; 
    //                 this.fnCtiStt("2R");
    //                 await this.fnSttTime();
    //                 this.$store.dispatch("callStore/AC_CUST_INOUT", "OUT");
    //                 this.cti_info.CALL_TYPE = "OUT";
                    
    //               }else if(p_state=="INITIATED" && callType=="CONSULT"){
    //                 console.log("##### 1-3 전환 요청중 #####");
    //                 this.TRNS_CALL_ID = callId;
    //                 //btnOnList.splice(btnOnList.indexOf("DROP"),1);	//전화끊기 비활성
    //                 //btnOnList.push("RECONNECT");						        //전화취소활성
    //               }else if(p_state=="ANSWER"){
    //                 console.log("##### 1-4 벨소리 알람중 전화받기 신호 #####");
    //                 this.hideDialog('P100201');
    //                 this.fnAnswerCall();
    //               }else{
    //                 console.log("##### 1-5 벨소리 알림중 기타 #####callType:" + callType);
    //               }
    //             } // state(통화중)
    //             else if(state=="ACTIVE"){
    //               if(p_state=="ACTIVE"){
    //                 this.NOW_CALL_TYPE = callType;
    //                 this.READY_YN = "N";
    //                 if(this.geCallBdttm == "00000000" || this.geCallBdttm == "") {
    //                   console.log("##### 2 통화중 시작시간 셋팅!! #####:" + callId);
    //                   this.CALL_START = false;
    //                 }
    //                 this.fnChangeState("34");
    //                 //후처리상태 처리
    //                 this.finesseHandler1.finesseHandler.userChangeStateReason("NOT_READY", "34");
    //                 this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N"); //상담저장여부
                    
    //                 //this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");   //핀패드 실행여부
    //                 console.log("##### 2 통화중 #####callType:" +callType );
    //                 this.hideDialog('P100201');
    //                 if(this.ctiStt == "TALKRRR")this.fnCtiStt("2T");

    //                 if(callType=="CONFERENCE"){
    //                   if(participants.length > 2){
    //                     console.log("###################### 2-1 3자통화 완료 ######################");
    //                     //this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "9");
    //                   }else{
    //                     console.log("###################### 2-2 3자통화중 고객이 끊긴 경우 후처리 ######################");
    //                     this.common_alert(`고객 통화가 끊겼습니다!!`, 'info');
    //                     this.finesseHandler1.finesseHandler.dropCall(this.CURR_CALL_ID);
    //                     // if(this.geCustInfo.CLNT_NO == "undefined" || this.geCustInfo.CLNT_NO == null || this.geCustInfo.CLNT_NO == "" || this.geCustInfo.CLNT_NO == "00000000"){
    //                     //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //                     // }else{
    //                       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");
    //                     // }                             
    //                   }
    //                 }else{
    //                   if(participants.length > 2){				
    //                     console.log("###################### 2-3 3자통화중 IVR이 끊긴 경우 후처리 ######################");
    //                     // if(this.geCustInfo.CLNT_NO == "undefined" || this.geCustInfo.CLNT_NO == null || this.geCustInfo.CLNT_NO == "" || this.geCustInfo.CLNT_NO == "00000000"){
    //                     //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //                     // }else{
    //                       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");
    //                     // }                         
    //                     // this.common_alert(`IVR 통화가 끊겼습니다!!`, 'info');
    //                     // this.finesseHandler1.finesseHandler.dropCall(this.CURR_CALL_ID);
    //                   }else{
    //                     console.log("##### 2-4 통화중 #####");

    //                     if(!this.mixin_isEmpty(callId)) {
    //                       this.cti_info.END_DT  = "";
    //                       this.cti_info.END_TM  = "";
    //                     }

    //                     if(callType=="PREROUTE_ACD_IN") {
    //                       console.log("인입통화성공====================================================[" + this.ctiStt + "]");
    //                       //if(!this.mixin_isEmpty(callId) && this.CURR_CALL_ID != callId) {
    //                       if(this.ctiStt == "TALKRRR") {
    //                         this.fnCtiStt("2T");
    //                       }

    //                       //this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N"); //상담저장여부
    //                     } else if(callType=="OUT") {
    //                       console.log("아웃통화성공====================================================[" + this.ctiStt + "]");

    //                       this.trnsInf = this.cti_info.IPCC_NO + "," + this.cti_info.CALL_TYPE + "," +  this.geUserRole.userId;
    //                       this.ctiParams = [
    //                         {"name" : "callVariable1","value": ""}
    //                         ,{"name" : "callVariable2","value": ""}
    //                         ,{"name" : "callVariable3","value": ""}
    //                         ,{"name" : "callVariable4","value": ""}
    //                         ,{"name" : "callVariable5","value": ""}
    //                         ,{"name" : "callVariable6","value": ""}
    //                         ,{"name" : "callVariable9","value": this.trnsInf}
    //                         ,{"name" : "user.QSTIME"     ,"value": ""}
    //                         ,{"name" : "user.HOSTERRCODE","value": ""}
    //                         ,{"name" : "user.REAGTID"    ,"value": ""}
    //                         ,{"name" : "user.ENDTYPE"    ,"value": ""}
    //                         ,{"name" : "user.PWDTYPE"    ,"value": ""}
    //                         ,{"name" : "user.PWDRESULT"  ,"value": ""}
    //                         ,{"name" : "user.ACCTNUM"    ,"value": ""}
    //                         ,{"name" : "user.ACCTNAME"   ,"value": ""}
    //                         ,{"name" : "user.ACCTPWD"    ,"value": ""}
    //                         ,{"name" : "user.RRNO"        ,"value": ""}
    //                         ,{"name" : "user.MOBILE"     ,"value": ""}
    //                         ,{"name" : "user.MEM"        ,"value": ""}
    //                         ,{"name" : "user.CSTANI"     ,"value": this.TO_TNO}
    //                       ];

    //                       //this.finesseHandler1.finesseHandler.setCallData(this.ctiParams);
    //                       if(!this.mixin_isEmpty(callId)) {
    //                         this.CURR_CALL_ID = callId;
    //                         this.cti_info.CALL_ID = callId;
    //                         this.cti_info.FROM_TNO = p_mediaAddress;
    //                         // this.cti_info.CALL_TYPE = "OUT";
    //                       }
    //                       if(this.ctiStt == "TALKRRR") {
    //                         this.fnCtiStt("2T");
    //                       }
    //                       //this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N"); //상담저장여부
    //                       console.log("-----------CURR_CALL_ID:[" + this.CURR_CALL_ID + "]" + this.cti_info.FROM_TNO);
    //                     }

    //                     //this.common_toast(`통화성공.`, 'info');
    //                     // //this.appendLog(callType, "======>CALL_ID:" + callId);
    //                     if(this.CURR_CALL_ID != callId && callType != "CONSULT") {
    //                       console.log("-----------AC_CNSL_SAVE_YN:[" + callId + "]");
    //                       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N"); //상담저장여부
    //                     }

    //                     // //this.appendLog(callType, "======>RDWT_ID:" + this.cti_info.RDWT_ID);
    //                     this.$store.commit("callStore/MU_CTI_INFO", this.cti_info);
    //                   }
    //                 }
                  
    //               }else if(p_state=="WRAP_UP"){
    //                 if(callType=="CONFERENCE"){
    //                   console.log("###################### 2-5 3자통화중 상담원이 끊긴 경우 후처리 ######################");
    //                 }
    //                 console.log("##### 2-6 통화 종류 후처리 #####");
    //                 // if(this.geCustInfo.CLNT_NO == "undefined" || this.geCustInfo.CLNT_NO == null || this.geCustInfo.CLNT_NO == "" || this.geCustInfo.CLNT_NO == "00000000"){
    //                     //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //                     // }else{
    //                       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");
    //                     // }        
    //                 //btnOnList = ["READY","NOT_READY"];
    //               }else if(p_state=="HELD"){
    //                 if(p_actions.indexOf("CONFERENCE")!=-1||p_actions.indexOf("TRANSFER")!=-1) {
    //                   console.log("##### 2-6 3자통화 or 호전환 요청 후 요청받은 대상이 전화 받음 #####");
    //                   this.sendToChild(2);
    //                   //btnOnList.splice(btnOnList.indexOf("RETRIEVE"),1);		//호전환, 3자통화시 보류해제는 제외
    //                 }else{
    //                   console.log("##### 2-7 보류중 #####");
    //                 }
    //               } else if (p_state=="DROPPED") {
    //                 // if(this.geCustInfo.CLNT_NO == "undefined" || this.geCustInfo.CLNT_NO == null || this.geCustInfo.CLNT_NO == "" || this.geCustInfo.CLNT_NO == "00000000"){
    //                     //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //                     // }else{
    //                       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");
    //                     // }                              
    //                 console.log("##### 2-8 통화중 전화끊김  #####"+ p_state + "/" +this.ARS_FROM_NO);
    //                 this.hideDialog('P100201');
    //                 if(!this.mixin_isEmpty(this.ARS_FROM_NO)) {
    //                   this.cti_info.FROM_TNO = this.ARS_FROM_NO;
    //                   this.ARS_FROM_NO ="";
    //                   this.$store.commit('callStore/MU_CTI_INFO', this.cti_info); 
    //                 } else {

    //                   if(this.TRANS_YN && callType!="TRANSFER") {
    //                     this.sendToChild(1);
    //                     this.finesseHandler1.finesseHandler.retrieveCall(this.CURR_CALL_ID);
    //                   } else {
    //                     await this.fnEndTime(); // 통화종료시간

    //                     if(this.ctiStt = "TALKRRR") {
    //                       console.log("##### 2-8 통화중 전화끊김 통화시도중  #####"+ p_state);
    //                       this.fnCtiStt("1");  //후처리중 상태 변경
    //                     }
    //                     // this.cti_info.REASON_CD = "34";
    //                     // this.fnChangeState("34");
    //                   }
    //                 }
    //               }

    //               // 3자통화 CONFERENCE
    //               if(callType=="CONSULT" && frmAddr == this.cti_info.IPCC_NO) {
    //                 // 3자통화 CONFERENCE
    //                 console.log("##### 2-1 통화중 CONSULT #####" + p_state);
    //                 console.log(" -TRANS_YN>>>" + this.TRANS_YN);
    //                 if(!this.TRANS_YN) {
    //                   this.TRANS_YN = false;
    //                   this.finesseHandler1.finesseHandler.conferenceCall(this.CURR_CALL_ID);
    //                 }

    //               } else if (callType=="CONFERENCE") {
    //                 console.log("##### 2-2 통화중 CONFERENCE #####"+ p_state);
    //                 this.cti_info.TRNS_YN = "Y";    // 호전환여부
    //               }
    //             //전화 끊기
    //             }else if(state=="DROPPED"){
    //               if(p_state=="DROPPED"){
    //                   console.log("this.geCustInfo.SRCH_CL_CID   ", this.geCustInfo.CLNT_NO)
    //                   // if(this.geCustInfo.CLNT_NO == "undefined" || this.geCustInfo.CLNT_NO == null || this.geCustInfo.CLNT_NO == "" || this.geCustInfo.CLNT_NO == "00000000"){
    //                     //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //                     // }else{
    //                       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");
    //                     // }                      

    //                 console.log("##### 3-1 전화를 끊음 #####");
    //                 await this.fnEndTime(); // 통화종료시간
    //                 this.hideDialog('P100201');
    //                 //this.fnCtiStt("1");
    //                 //this.fnCtiStt('1');
    //                 //this.common_alert(`전화를 끊음.`, 'info');
    //                 //CTICallCleared(); //콜이  완전히 끊긴 경우 호출 되는 함수
    //                 //getUserState();
    //               }
    //             }else if(state=="FAILED"){
    //               if(p_state=="DROPPED"){
    //                 console.log("##### 3-2 아웃바운드 연결중 고객이 전화를 받지 않는 경우 #####");
    //                 // if(this.geCustInfo.CLNT_NO == "undefined" || this.geCustInfo.CLNT_NO == null || this.geCustInfo.CLNT_NO == "" || this.geCustInfo.CLNT_NO == "00000000"){
    //                     //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //                     // }else{


    //                 if(callType=="CONSULT" && checkPost == "DELETE") {
    //                   this.finesseHandler1.finesseHandler.retrieveCall(this.CURR_CALL_ID);
    //                   this.sendToChild(1);
    //                   return;
    //                 }
    //                 if(this.mixin_isEmpty(this.geCustInfo.CLNT_NO) && this.mixin_isEmpty(this.geCustInfo.SRCH_CL_CID)) {
    //                   let pVal = {
    //                     COMP_TYPE: "E"
    //                     ,ACCT_NO: ""
    //                   }
    //                   eventBus.setIvrInf(pVal);
    //                   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");
    //                 }
    //                 // }                            
    //                 this.hideDialog('P100201');
    //                 await this.fnEndTime(); // 통화종료시간
    //                 // this.fnCtiStt('1');
    //                 // this.cti_info.REASON_CD = "34";
    //                 // this.fnChangeState("34"); //후처리중
    //                 //this.common_alert(`통화가 끊겼습니다!!`, 'info');
    //                 //this.common_alert(`아웃바운드 연결 실패 전화번호를 확인 하세요!!`, 'info');
    //                 // CTICallCleared(); //콜이  완전히 끊긴 경우 호출 되는 함수
    //                 // getUserState();
    //               }
                
    //             }else if(state=="INITIATED"){
    //               if(p_state=="DROPPED"){
    //                 // if(this.geCustInfo.CLNT_NO == "undefined" || this.geCustInfo.CLNT_NO == null || this.geCustInfo.CLNT_NO == "" || this.geCustInfo.CLNT_NO == "00000000"){
    //                     //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //                     // }else{
    //                 this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");
    //                     // }                         
    //                 console.log("##### 3-3 아웃바운드 연결중 상담원이 전화를 받지 않는 경우 #####");
    //                 this.hideDialog('P100201');
    //                 await this.fnEndTime(); // 통화종료시간
    //                 //this.fnCtiStt('1');
    //                 // this.cti_info.REASON_CD = "34";
    //                 this.fnChangeState("34"); //후처리중
    //                 //this.common_alert(`통화를 끊었습니다!!`, 'info');
    //                 //this.common_alert(`아웃바운드 연결 실패 상담원이 전화를 받지 않음!!`, 'info');
    //                 // CTICallCleared(); //콜이  완전히 끊긴 경우 호출 되는 함수
    //                 // getUserState();
    //               }	else if(p_state=="INITIATED" && callType == "AGENT_INSIDE"){
    //                 console.log("##### 3-3-2 아웃바운드 연결중 통화시도중 상황 #####");
    //                 this.fnCtiStt("2R");
    //                 if(this.mixin_isEmpty(this.cti_info.FROM_TNO)) {
    //                   this.cti_info.FROM_TNO = frmAddr;
    //                 }
    //               }
    //             }else{
    //                 console.log("##### 4 기타 #####", this.geCustInfo.CLNT_NO);
    //                 console.log("this.$store.getters[callStore/GE_CNSL_SAVE_YN];    ", this.$store.getters["callStore/GE_CNSL_SAVE_YN"]);
  
    //                 console.log("ELSE STT>>>>>p_mediaAddress"+ p_mediaAddress + ",state:" + p_state);
    //                 if(p_state=="DROPPED"){
    //                   console.log(`통화가 끊겼습니다!!!`);
  
                      
    //                   // if(this.geCustInfo.CLNT_NO == "undefined" || this.geCustInfo.CLNT_NO == null || this.geCustInfo.CLNT_NO == "" || this.geCustInfo.CLNT_NO == "00000000"){
    //                     //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //                     // }else{
    //                       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");
    //                     // }   
    //                 console.log("this.$store.getters[callStore/GE_CNSL_SAVE_YN];    ", this.$store.getters["callStore/GE_CNSL_SAVE_YN"]);
  
    //                 await this.fnEndTime(); // 통화종료시간
    //                 this.hideDialog('P100201');
    //               }
    //             } // state
    //             iCnt++;
    //           }
    //         } // for
    //       }
    //       console.log("["+svrDateTime+ "]###################################################################################IVRREPEND!!!");
    //     } // if 
         
    //   } catch(ex) {
    //     console.log("["+svrDateTime + "]###################################################################################IVRREPERR!!!");
    //     console.log("RECEIVE ERR===>" + ex);
    //     // //this.appendLog("ERR", ex);
    //   }
    // },
    // /*녹취 아이디 생성*/
    // async crtRdwtId(callId) {
    //   let rdwtId = "";
    //   console.log(">>>>>>>>>>>>>>>>>>>>>>>>>CALL_ID:" +callId);

    //   if(callId.length != 8) {
    //     console.log("콜ID 자리수 오류!!-->" + callId.length);
    //     return true;
    //   }

    //   if(this.CURR_CALL_ID == callId && !this.mixin_isEmpty(this.RDWT_ID))  {
    //     console.log("녹취 ID 이미 생성!!!" + this.RDWT_ID);
    //     return true;
    //   }

    //   try {
        
    //     let svrDateTime = await this.mixin_getSvrDate("YYYYMMDDHH24MISS");

    //     let svrDate = svrDateTime.substr(0, 8);
    //     let svrTime = svrDateTime.substr(8, 16);

    //     rdwtId = svrDate + svrTime + callId + this.cti_info.IPCC_NO;
    //     this.RDWT_ID = rdwtId
    //     this.cti_info.RDWT_ID = rdwtId;
    //     this.cti_info.CALL_ID = callId;
    //     //this.appendLog("RDWT_ID", this.RDWT_ID);

    //     if(this.mixin_isEmpty(rdwtId)) {
    //       this.common_toast("녹취키 생성실패!! 확인 하세요.", "info");
    //     } else {
    //       this.$store.commit('callStore/MU_CTI_INFO', this.cti_info); 
    //       this.rdwtOpenCall(rdwtId);
    //     }
    //   } catch(ex) {
    //     console.log("녹취아이디 생성 오류!!-->" + ex);
    //     return false;
    //   }
    //   return true;
    // },
    // async fnDiffTime(sttDtm, endDtm) {
    //   let beginStp = new Date(this.convDateFormat(sttDtm));
    //   let endStp   = new Date(this.convDateFormat(endDtm));

    //   let diffTm = endStp.getTime() - beginStp.getTime();

    //   let diffMS   = diffTm/1000;
    //   let diffSS   = Math.floor(diffMS%60);
    //   let diffMin  = Math.floor(diffMS/60%60);
    //   let diffHour = Math.floor(diffMS/60/60%60);
    //   let diffTime =  diffHour + ":" + diffMin + ":" + diffSS;

    //   console.log("-------------------------------------");
    //   console.log("*sttDtm:"+sttDtm);
    //   console.log("*endDtm:"+endDtm);
    //   console.log("*diffTm:"+diffMS);
    //   //this.appendLog("CALLTIME", diffMS);
    //   console.log("-------------------------------------");
    //   return diffMS;
    // },
    // fnSttTime: async function() {
    //   let svrDateTime = await this.mixin_getSvrDate("YYYYMMDDHH24MISS");

    //   let svrDate = svrDateTime.substr(0, 8);
    //   let svrTime = svrDateTime.substr(8, 16);

    //   // let sttDtm = this.getDateTime();
    //   // this.callSttTime = sttDtm.replace(/[^0-9]/g, "");
    //   this.callSttTime = svrDate+svrTime;

    //   this.$store.dispatch('callStore/AC_CALL_BDTTM', this.callSttTime); //상담시작시간
    //   console.log("$$$CALL_STT_TIME::" + this.callSttTime.substr(0,8) +" "+this.callSttTime.substr(8,14));

    //   this.cti_info.BEGIN_DT  = svrDate;
    //   this.cti_info.BEGIN_TM  = svrTime;
    //   this.cti_info.TEL_TIME  = "0";
    //   this.$store.commit("callStore/MU_CTI_INFO", this.cti_info);
    // },
    // fnEndTime: async function(sFlag) {
    //   let svrDateTime = await this.mixin_getSvrDate("YYYYMMDDHH24MISS");

    //   let svrDate = svrDateTime.substr(0, 8);
    //   let svrTime = svrDateTime.substr(8, 16);

    //   this.callEndTime      = svrDate+svrTime;
    //   this.cti_info.END_DT  = svrDate;
    //   this.cti_info.END_TM  = svrTime;
    //   this.CALL_START = true;

    //   this.geCtiInfo.END_DT  = svrDate;
    //   this.geCtiInfo.END_TM  = svrTime;

    //   // cWorker.postMessage({index:0
    //   //   ,'CALL_ID':this.CURR_CALL_ID
    //   //   ,'RDWT_ID':this.RDWT_ID
    //   //   ,'FROM_TNO':this.cti_info.FROM_TNO
    //   //   ,'STT_TIME':this.cti_info.BEGIN_TM
    //   //   ,'END_TIME':svrTime
    //   //   ,'CALL_TYPE':this.geCnslCustInout
    //   // })

    //   console.log("$$$$END_TIME" + this.callSttTime + " ~ " + this.callEndTime);

    //   if(!this.mixin_isEmpty(this.callSttTime)&& !this.mixin_isEmpty(this.callEndTime)) {
    //     this.cti_info.TEL_TIME   = await this.fnDiffTime(this.callSttTime,this.callEndTime);
    //     this.geCtiInfo.TEL_TIME  = this.cti_info.TEL_TIME;
    //   }

    //   //this.$store.commit("callStore/MU_CTI_INFO", this.cti_info);

    //   // 통화종료시 통화종료시간 수정처리
    //   if(sFlag == "Y") {
    //     //this.fnSaveCnslHist();
    //   }
    // },
    // /* 통화종료시간 수정 */
    // fnSaveCnslHist() {
    //   if(this.mixin_isEmpty(this.cti_info.END_DT) || this.mixin_isEmpty(this.cti_info.END_TM)) {
    //       this.common_alert(`종료일시가 없어서 종료시간 수정 실패하였습니다.`, 'error');
    //       return false;
    //   }

    //   let reqData = {
    //     ASP_CUST_KEY : this.ASP_CUST_KEY,
    //     CNSL_HIST_NO : this.user_id,
    //     CNSL_EOT_DATE : this.cti_info.END_DT,
    //     CNSL_EOT_TIME : this.cti_info.END_TM,
    //     TEL_TIME : this.cti_info.END_TM,
    //     CHNG_ID : this.user_id,
    //   }

    //   // header 세팅
    //   let requestData = {
    //     headers: {
    //         ASYNC: true,
    //         METHOD: "modify",
    //         SERVICE: "phone.main.cnslt-enddttm",
    //         TYPE: "BIZ_SERVICE",
    //         URL: "/api/phone/main/cnslt-enddttm/modify"
    //     },
    //     sendData: reqData
    //   };
    //   this.common_postCall(requestData).then(response => {
    //       this.isLoading = false;
    //       this.dialogHistory=false;
    //       if(response.HEADER.ERROR_FLAG) {
    //         console.log("상담저장 ERR_MSG:["+response.HEADER.ERROR_MSG+"]");
    //         this.common_alert('상담저장 중 오류가 발생했습니다.<br>잠시후에 다시 시도해주세요.', 'error');
    //         return false;
    //       }
    //       this.common_alert("성공적으로 저장했습니다.");
    //       let item = {CLNT_NO : this.srchCustNo};
    //       this.$emit('selCnslHist', item);
    //   })
    //   .catch((err) => {
    //     this.isLoading = false;
    //     this.common_alert('상담저장 중 오류가 발생했습니다.<br>잠시후에 다시 시도해주세요.', 'error');
    //     console.log(err);
    //   });
    // },
    convDateFormat(sDate) {
      let numPttn = /[^0-9]/g;
      sDate = sDate.replace(numPttn, "");
      if (sDate.length == 14) {
        sDate = sDate.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6');
      }
      return sDate;
    },
    // async valueTest() {
    //   let svrDateTime =  await this.mixin_getSvrDate("YYYYMMDDHH24MISS");
    //   console.log("&&&&&& svrDttm:["   + JSON.stringify(svrDateTime) + "]");
    //   console.log("&&&&&& FROMTNO:["   + this.geCtiInfo.FROM_TNO     + "]");
    //   console.log("&&&&&& 인입번호:["   + this.geCtiInfo.IVR_IN_NO    + "]");
    //   console.log("&&&&&& 인입경로:["   + this.geCtiInfo.IVR_SERV_NO  + "]");
    //   console.log("&&&&&& 대기시간:["   + this.geCtiInfo.IVR_STD_TIME + "]");
    //   console.log("&&&&&& 최종멘트:["   + this.geCtiInfo.IVR_MENT     + "]");
    //   console.log("&&&&&& IN_PATH:["   + this.geCtiInfo.IVR_IN_PATH + "]");
    //   console.log("----------------------------------------------------------");
    //   console.log("&&&&&& rcv_IN_CD:["   + this.receive.IVR_IN_CD + "]");
    //   console.log("&&&&&& rcv_IN_NO:["   + this.receive.IVR_IN_NO + "]");
    //   console.log("&&&&&& rev_IN_PATH:[" + this.receive.IVR_IN_PATH + "]");
    //   console.log("&&&&&& rcv_MENT:["    + this.receive.IVR_MENT + "]");
    //   // let svrDate = svrDateTime.substr(0, 8);
    //   // let svrTime = svrDateTime.substr(8, 16);
    //   if(this.ctiInterval > 0) {
    //     clearInterval(this.ctiInterval);
    //     this.ctiInterval = 0;
    //   }
    //   // console.log("&&&&&& svrDate:["   + svrDate + "]");
    //   // console.log("&&&&&& svrTime:["   + svrTime + "]");
    //   // console.log("&&&&&& geCnslCnt:["   + this.geCnslSaveCnt + "]");
    //   // this.$store.dispatch('callStore/AC_CTI_INFO', this.cti_info); //콜상태값 갱신
    //   // this.$store.dispatch('callStore/AC_CALL_STATE', "TALKING"); //콜상태값 갱신
    //   // console.log("&&&&&& STT PARAM:"   + this.ctiParams.length);
    //   // console.log("&&&&&& geCallBdttm:" + this.geCallBdttm);
    //   // console.log("&&&&&& geCallStt:"   + this.geCallStt);
    //   // console.log("&&&&&& RDWT_ID:"     + this.geCtiInfo.RDWT_ID);
    //   // console.log("&&&&&& CALL_TYPE:"   + this.geCtiInfo.CALL_TYPE);
    //   // console.log("&&&&&& FROM_NO:"     + this.geCtiInfo.FROM_TNO);
    //   let cnslSaveCnt = this.$store.getters["callStore/GE_CNSL_SAVE_CNT"];
    //   console.log("&&&&&& cnslSaveCnt:"   + cnslSaveCnt++);
    //   // console.log("&&&&&& geCallBdttm:" + hoNo);
    //   // console.log("&&&&&& STT PARAM:" + JSON.stringify(this.geCustInfo));
    //   // console.log("&&&&&&:CALL_ID:" + this.cti_info.CALL_ID);
    //   // console.log("&&&&&&:GE_CALL_ID:" + this.geCtiInfo.CALL_ID);
    //   // console.log("&&&&&&:PWD_TYPE:" + this.geCustInfo.PWD_TYPE);
    //   // console.log("&&&&&&:ACCT_CONF:" + this.geCustInfo.ACCT_CONF);
    //   // console.log("&&&&&&:SRCH_ACCOUNT:" + this.geCustInfo.SRCH_ACCOUNT);
    //   // console.log("&&&&&&:PRDT_DTL_NM:" + this.geCustInfo.PRDT_DTL_NM);
    //   // console.log("&&&&&&:ACCOUNT_PW:" + this.geCustInfo.ACCOUNT_PW);
    //   // console.log("&&&&&&:CLNT_NO:" + this.geCustInfo.CLNT_NO);
    //   // console.log("&&&&&&:MOVE_TNO:" + this.geCustInfo.MOVE_TNO);
    // },
    // async setTrnsValue(flag) {
    //   this.trnsInf = this.cti_info.IPCC_NO + "," + this.cti_info.CALL_TYPE + "," +  this.geUserRole.userId;
    //   this.ctiParams[6].value  = this.trnsInf;
    //   this.ctiParams[11].value = this.geCustInfo.PWD_TYPE;
    //   this.ctiParams[12].value = this.geCustInfo.ACCT_CONF;
    //   this.ctiParams[13].value = this.geCustInfo.SRCH_ACCOUNT;
    //   this.ctiParams[14].value = this.geCustInfo.PRDT_DTL_NM;
    //   this.ctiParams[15].value = this.geCustInfo.ACCOUNT_PW;
    //   this.ctiParams[16].value = this.geCustInfo.CLNT_NO;

    //   if(this.geCustInfo.ACCT_CONF == "Y")
    //     this.ctiParams[17].value = this.geCustInfo.MOVE_TNO;

    //   //console.log("<------------------------------------------------------------------------------->");
    //   //console.log("&&&&&& END PARAM:", this.ctiParams);
    //   if(flag == "Y") {
    //     this.finesseHandler1.finesseHandler.setCallData(this.ctiParams);
    //   }
    // },
    // /**
    //  * 상담메인탭 이동
    //  * inYn 인바운드 여부
    //  **/
    // mvCallMain(inYn) {
    //   let acTabs = this.$store.getters["commonStore/GE_COMMON_TAB"];
    //         let aleady = false; //기존에 있는 탭인지 여부
    //         for(let i = 0; i < acTabs.length; i++) {
    //             if(acTabs[i].id === "E010101") {
    //                 console.log("tab[" + acTabs[i].id + "]");
    //                 aleady = true;
    //                 break;
    //             }
    //         }
    //         if(aleady) {
    //             if(inYn == "Y") {
    //               eventBus.setCnslMode('init');
    //             }
               
    //             this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
    //                 id: "E010101",
    //                 title: "콜상담 메인",
    //                 link: "/E010101"
    //             });
    //             return;
    //         }

    //         // 콜상담 메인 탭 오픈
    //         this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
    //             id: "E010101",
    //             title: "콜상담 메인",
    //             default: "N",
    //             tabIcon: "",
    //             link: "/E010101"
    //         });
    // },
    keyUpTelInp(val) {
      let numPttn = /[^0-9]/g;
      val = val.replace(numPttn, "");
      if(val.length > 8)  {
        val = this.mixin_newPhoneFormat(val);
      }
      this.DIRECT_TNO = val;
    },
    // fnTestRecv() {
    //   this.receive.IVR_IN_CD   = "65000"; 
    //   this.receive.ERR_CD      = "03142";
    //   this.receive.STD_TIME    = "00:00:18";
    //   this.receive.CUST_NM     = "고도리";
    //   this.receive.CUST_MEMO   = "";
    //   this.receive.inboundNum  = "0313899599";

    //   this.receive.COMP_TYPE   = "";
    //   this.receive.COMP_RST    = "";
    //   this.receive.ACCT_NO     = "";
    //   this.receive.ACCT_NM     = "";
    //   this.receive.ACCT_PWD    = "";
    //   this.receive.RRNO        = "";
    //   this.receive.MBL_NO      = "";
    //   this.receive.PHNNUM_YN   = "";

    //   let Stamp   = new Date();
    //   let Hours   = Stamp.getHours();
    //   let Mins    = Stamp.getMinutes();
    //   if(Hours < 10) Hours = "0" + Hours;
    //   if(Mins < 10)  Mins = "0" + Mins;

    //   this.cti_info.CALL_ID   = this.cti_info.IPCC_NO + Hours + Mins;
    //   this.cti_info.FROM_TNO  = "01155558282";
    //   //this.cti_info.IVR_IN_NO = this.receive.IVR_IN_CD;
    //   this.cti_info.IVR_STD_TIME = "00:00:18";
    //   this.cti_info.TEL_TIME = 12;
    //   this.cti_info.SKILL_NAME = "J_ACCOUNT";
    //   this.cti_info.SKILL_NO = "65200";

    //   this.$store.dispatch('callStore/AC_CTI_INFO', this.cti_info); //콜상태값 갱신

    //   this.rcvPopMode = "init";
    //   this.rcvPopMode = "RRRR";
    //   this.showDialog('P100201');
    // },
    // /**
    //  * 버튼별 예외 처리
    //  * btnStt : 버튼상태값
    //  * DROP : 끊기, HOLD : 보류, TRSF : 호전환, ARS : 호전환
    //  *
    //  **/
    // callSttCheck(btnStt) {
    //   console.log(">>>VMSPlayYn:["+ this.geVMSPlayYn + "]" + ", pinTypeNo:["+ this.gePinTypeNo + "]" + this.ctiStt );
    //   if(this.ctiStt == "TALKING" && (this.geVMSPlayYn == "Y" || this.gePinTypeNo == "1")) {
    //     this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요!!!`, 'info');
    //     return false;
    //   }
    //   if(btnStt == "DROP" || btnStt == "HOLD" || btnStt == "TRSF"|| btnStt == "ARS") {
    //     console.log("==> CURR CALL STATE ::" + btnStt);
    //   }
    //   return true;
    // },
    // fnRdoClick(val) {
    //   this.selCall =  val;
    // },
    // /* finesse 로그인 */
    // finesseLogin() {
    //   this.$store.dispatch('callStore/AC_CNSL_SAVE_FLAG', false);
    //   var cnslSaveFlag = this.$store.getters["callStore/GE_CNSL_SAVE_FLAG"];
    //   console.log("cnslSaveFlag login    ", cnslSaveFlag);

    //   if(this.mixin_isEmpty(FINESSE_PUB_URL)) {
    //     this.common_alert(`FINESSE PUB SERVER URL 주소가 없습니다.` + FINESSE_PUB_URL, 'info');
    //     return false;
    //   }

    //   if(this.mixin_isEmpty(FINESSE_SUB_URL)) {
    //     this.common_alert(`FINESSE SUB SERVER URL 주소가 없습니다.` + FINESSE_SUB_URL, 'info');
    //     return false;
    //   }
    //   this.CTI_ON = true;
		//   this.cti_info.CTI_MODE = true;
    //   ////this.appendLog("finesseLogin", "id:" +this.cti_info.USER_ID + "/pwd:"+this.cti_info.PASSWORD+ "/ipccNo:"+this.cti_info.IPCC_NO);
    //   this.finesseHandler1.finesseHandler.finesseLogin(this.cti_info.USER_ID, this.cti_info.PASSWORD, this.cti_info.IPCC_NO, FINESSE_PUB_URL, FINESSE_SUB_URL);
    //   //this.finesseHandler1.finesseHandler.finesseLogin(this.cti_info.USER_ID, this.cti_info.PASSWORD, this.cti_info.IPCC_NO, "https://eb-finpub.ebipc.com", FINESSE_SUB_URL);
    // },
    // /* finesse 로그아웃 */
    // finesseLogOut() {
    //   //this.appendLog("signOut", "CTI ON Button Clicked!!!");
    //   this.clearCtiInfo();
    //   this.endTimer();    //타이머종료처리
    //   this.finesseHandler1.finesseHandler.signOut();
    // },
    // /* finesse 사용자 로그아웃 */
    // finesseUserSignOut(userId, userIpNo) {
    //   //this.appendLog("signOut", "USER LOGOUT Button Clicked!!!["+ userId +"]");
    //   this.finesseHandler1.finesseHandler.userSignOut(userId, FINESSE_PUB_URL);
    // },
    // /* 대기 */
    // async fnFnssReady() {
    //   if(this.ctiStt == "TALKING") {
    //     this.common_alert(`통화중입니다.`, 'info');
    //     return false;
    //   }
    //   console.log("this.geCustInfo ", this.geCustInfo )
    //   console.log("this.geCustInfoPrev ", this.geCustInfoPrev )
    //   console.log("this.geCnslSaveYn ", this.geCnslSaveYn )
    //   console.log("this.this.CURR_CALL_ID ", this.CURR_CALL_ID )
    //   //후처리중 상담저장이 안된경우
    //   this.cnsltHistCheck = await this.fnCnsltHistCheck("Y");
    //   if(this.ctiStt == "A_SETACW" && !this.mixin_isEmpty(this.CURR_CALL_ID) && this.geCnslSaveYn == "N") {
    //     if(this.geCustInfo.CLNT_NO != this.geCustInfoPrev || this.cnsltHistCheck == false){
    //       this.common_alert(`상담이력을 저장해주세요.`, 'info');
    //       return false;
    //     }else{
    //       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //     }
    //   }
    //   if(!this.callSttCheck("READY")) return false;
    //   this.$store.dispatch('callStore/AC_PLAY_YN', "N");
	  //   this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
  
    //   /* 신통초기화 */
    //   if(!this.geSintongClear) {
    //     this.mixin_sintongReset();
    //   }

    //   this.cti_info.FROM_TNO     = "";
    //   this.cti_info.IVR_SERV_NO  = "";
    //   this.cti_info.IVR_IN_NO    = "";
    //   this.cti_info.IVR_IN_PATH  = "";
    //   this.cti_info.IVR_STD_TIME = "";
    //   this.cti_info.ERR_MENT     = "";
    //   this.cti_info.IVR_MENT     = "";
    //   this.cti_info.END_DT    = "";
    //   this.cti_info.END_TM    = "";
    //   this.cti_info.CALL_TYPE = "";
    //   this.cti_info.REASON_CD = "";
    //   this.cti_info.BEGIN_DT  = "";
    //   this.cti_info.BEGIN_TM  = "";
    //   this.cti_info.val1      = "";  
    //   this.cti_info.val6      = "";  
    //   this.cti_info.val9      = "";
    //   this.cti_info.errCd     = "";
    //   this.cti_info.CUST_NM   = "";
    //   this.cti_info.sCstAni   = "";
    //   this.cti_info.frmAddr   = "";
    //   this.cti_info.IVR_IN_NO = "";
    //   this.$store.dispatch('callStore/AC_TO_TNO', "99999999");
    //   this.$store.commit('callStore/MU_CTI_INFO', this.cti_info); 

    //   this.getCtiChk();
    //   this.mvCallMain("Y");
    //   this.setCtiTimer();
    //   this.READY_YN = "N";
    //   this.cleanCallInf("READY");
    //   this.ARS_FROM_NO = "";
    //   this.finesseHandler1.finesseHandler.userReady("READY", "");
      
    //   this.$store.dispatch('callStore/AC_CNSL_SAVE_FLAG', false);
    // },
    // /* 대기해제 */
    // fnNonFnssReady() {
    //   if(!this.callSttCheck("NONREADY")) return false;
    //   this.getCtiChk();
    //   this.setCtiTimer();
    //   this.cti_info.REASON_CD = "34";
    //   this.fnChangeState("34");
    //   this.READY_YN = "Y";
      
    // },
    // /* 전화걸기 */
    // async callLayer() {
    //   this.cnsltHistCheck = await this.fnCnsltHistCheck("Y");      //후처리중 상담저장이 안된경우

    //   if(this.ctiStt == "A_SETACW" && !this.mixin_isEmpty(this.CURR_CALL_ID) && this.geCnslSaveYn == "N") {
    //     if(this.geCustInfo.CLNT_NO != this.geCustInfoPrev || this.cnsltHistCheck == false){
    //       this.common_alert(`상담이력을 저장해주세요.`, 'info');
    //       return false;
    //     }else{
    //       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //     }
    //   }

    //   if(this.$store.getters['commonStore/GE_COMMON_ACTIVE_TAB'].id != 'E010101') {
    //     this.common_alert(`콜상담메인 화면으로 이동해주세요.`, 'info');
    //     return false;
    //   }

    //   this.$store.dispatch("callStore/AC_CUST_INOUT", "OUT");

    //   this.HOME_TNO   = "";
    //   this.OFFICE_TNO = "";
    //   this.MOV_TNO    = "";
    //   this.DIRECT_TNO = "";

    //   if(!this.mixin_isEmpty(this.geCustInfo) ) {
    //     this.selCall    = "4";  
    //     if(!this.mixin_isEmpty(this.geCustInfo.HOME_TNO) ) {
    //       this.HOME_TNO = this.geCustInfo.HOME_TNO;
    //       this.selCall  = "1";  
    //     }
    //     if(!this.mixin_isEmpty(this.geCustInfo.OFFC_TNO) ) {
    //       this.OFFICE_TNO = this.geCustInfo.OFFC_TNO;
    //       this.selCall    = "2";  
    //     }
    //     if(!this.mixin_isEmpty(this.geCustInfo.MOVE_TNO) ) {
    //       this.MOV_TNO    = this.geCustInfo.MOVE_TNO;
    //       this.selCall    = "3";  
    //     }

    //     if(this.mixin_isEmpty(this.geCustInfo.CLNT_NO) && this.geCustInfo.SRCH_CL_CID != "9999999999999") {
    //       this.common_alert(`고객정보를 조회해주세요.`, 'info');
    //       return false;
    //     }
    //   } else {
    //     this.common_alert(`고객정보를 조회해주세요.`, 'info');
    //     return false;
    //   }

    //   //this.appendLog("fnMakeCall", "Button Clicked!!!");
    //   this.MakeCallLayer = true;
    // },
    // /* 전화걸기 */
    // fnMakeCall(callNum) {
    //   this.$store.dispatch('callStore/AC_CNSL_SAVE_FLAG', false);
      
    //   if(this.mixin_isEmpty(callNum) ) {
    //     if(this.selCall == "1")      callNum = this.HOME_TNO;
    //     else if(this.selCall == "2") callNum = this.OFFICE_TNO;
    //     else if(this.selCall == "3") callNum = this.MOV_TNO;
    //     else if(this.selCall == "4") callNum = this.DIRECT_TNO;
    //   } 

    //   //this.appendLog("fnMakeCall callNum>>>>", callNum);
    //   if(this.mixin_isEmpty(callNum)) {
    //     this.common_alert(`전화번호를 확인해주세요.`, 'error')
    //     return;
    //   }

    //   if(!this.callSttCheck("MAKECALL")) return false;
      
    //   //CTI 정보 초기화
    //   this.cleanCallInf("OUT");

    //   this.cti_info.IVR_IN_NO    = "";
    //   this.cti_info.IVR_IN_PATH  = "";
    //   this.cti_info.IVR_STD_TIME = "";
    //   this.cti_info.ERR_MENT     = "";
    //   this.cti_info.IVR_IN_CD    = "";
    //   this.cti_info.IVR_SERV_NO  = "";
    //   this.cti_info.BEGIN_TM  = "";
    //   this.cti_info.END_TM  = "";
    //   this.cti_info.val1     = "";
    //   this.cti_info.val6     = "";
    //   this.cti_info.sCstAni  = "";
    //   this.cti_info.errCd    = "";
    //   this.cti_info.val9     = "";
    //   this.cti_info.ACCT_NO  = "";
    //   this.cti_info.RRNO     = "";

    //   // if(this.geCtiInfo.ARS_YN == "Y") {
    //   //   this.cti_info.IVR_IN_NO    = this.geCtiInfo.IVR_IN_NO;
    //   //   this.cti_info.IVR_IN_PATH  = this.geCtiInfo.IVR_IN_PATH;
    //   //   this.cti_info.IVR_STD_TIME = this.geCtiInfo.IVR_STD_TIME;
    //   //   this.cti_info.ERR_MENT     = this.geCtiInfo.IVR_MENT;
    //   // }

    //   this.$store.commit('callStore/MU_CTI_INFO', this.cti_info);

    //   callNum = callNum.replaceAll("-", "");
    //   this.TO_TNO = callNum;
    //   this.$store.dispatch('callStore/AC_TO_TNO', this.TO_TNO);
    //   if(callNum.length > 4)  callNum = "9" + callNum;
    //   this.mvCallMain("N");
    //   this.MakeCallLayer = false;
    //   this.setCtiTimer();
    //   this.$store.dispatch('callStore/AC_PLAY_YN', "N");
    //   this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
    //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N"); //상담저장여부
    //   this.TRANS_YN = false;
    //   this.cti_info.CALL_TYPE = "OUT";
    //   eventBus.clrCallInf("MAKECALL");
    //   this.ARS_FROM_NO = "";
    //   this.finesseHandler1.finesseHandler.makeCall(callNum);
    // },
    // cleanCallInf(callTp) {
    //   this.CURR_CALL_ID       = "";
    //   this.RDWT_ID            = "";
    //   this.CALLYN             = "N";
    //   this.cti_info.CALL_ID   = "";
    //   this.cti_info.RDWT_ID   = "";
    //   // this.cti_info.CALL_TYPE = "";
    //   // this.cti_info.REASON_CD = "";
    //   // this.cti_info.BEGIN_DT = "";
    //   // this.cti_info.BEGIN_TM = "";
    //   // this.cti_info.END_DT = "";
    //   // this.cti_info.END_TM = "";

    //   // this.cti_info.FROM_TNO    = "";
    //   // this.cti_info.IVR_SERV_NO = "";
    //   // this.cti_info.IVR_IN_NO   = "";
    //   // this.cti_info.IVR_IN_PATH = "";
    //   // this.cti_info.IVR_STD_TIME = "";
    //   // this.cti_info.IVR_MENT     = "";
      
    //   //호전환
    //   this.cti_info.TRNS_CON_GUBUN = "";
    //   this.cti_info.TRNS_YN = false;
    //   this.cti_info.TRNS_CSLT_ID = "";
    //   this.cti_info.TRNS_NO = "";
    //   this.$store.dispatch('callStore/AC_CTI_INFO', this.cti_info); //콜상태값 갱신
    // },
    // /* 전화받기 */
    // fnAnswerCall(answerFlag) {
    //   this.$store.dispatch("callStore/AC_CUST_INOUT", "IN");      
    //   this.$store.dispatch('callStore/AC_CNSL_SAVE_FLAG', false); 
    //   try{

    //     //상담저장 초기화 : Y- 상담저장 할 있을 것이다.
    //     this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");

    //       // eventBus.setCnslMode('init');
    //   }catch(e){
    //       console.log("=============setCnslMode 초기화 실패:"+e) ;
    //   }finally{
    //       console.log("=============setCnslMode finally 성공") ;
    //   }
     
    //     // this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");

    //   console.log("=============fnAnswerCall 시작") ;

    //   //this.appendLog("fnAnswerCall", "Button Clicked!!!");
    //   this.hideDialog('P100201');

    //   this.cti_info.IVR_SERV_NO  = "";
    //   //this.cti_info.IVR_IN_NO    = "";
    //   this.cti_info.IVR_IN_PATH  = "";
    //   this.cti_info.ERR_MENT     = "";

    //   // console.log("receive CD VAL:[" +this.receive.IVR_IN_CD+ "]");
    //   // console.log("receive NO VAL:[" +this.receive.IVR_IN_NO+ "]");
    //   // console.log("receive PATH VAL:[" +this.receive.IVR_IN_PATH+ "]");

    //   let errMent = this.receive.IVR_MENT;
    //   if(!this.mixin_isEmpty(this.cti_info.errCd)) {
    //     errMent = errMent + "(" +this.cti_info.errCd+ ")";
    //   }


    //   this.cti_info.IVR_SERV_NO  = this.receive.IVR_IN_CD;
    //   this.cti_info.IVR_IN_NO    = this.receive.IVR_IN_NO;
    //   if(this.mixin_isEmpty(this.receive.IVR_IN_PATH)) {
    //     this.cti_info.IVR_IN_PATH  = this.cti_info.FROM_TNO;
    //   } else {
    //     this.cti_info.IVR_IN_PATH  = this.receive.IVR_IN_PATH;
    //   }
    //   this.cti_info.ERR_MENT     = errMent;
    //   this.cti_info.CALL_TYPE = "IN";

    //   // console.log("cti_info CD VAL:[" +this.cti_info.IVR_SERV_NO+ "]");
    //   // console.log("cti_info NO VAL:[" +this.cti_info.IVR_IN_NO+ "]");
    //   // console.log("cti_info PATH VAL:[" +this.cti_info.IVR_IN_PATH+ "]");

    //   if(!this.mixin_isEmpty(this.cti_info.val9)){
    //     // this.cti_info.IVR_IN_NO   = "";
    //     this.cti_info.IVR_IN_PATH = this.cti_info.IVR_IN_CD;

    //     let varSplit = this.cti_info.val9.split(",");
    //     if(varSplit.length > 1){
    //       let  inOutFlag = varSplit[1];
    //       if(!this.mixin_isEmpty(inOutFlag) && inOutFlag == "OUT" ){
    //         this.cti_info.IVR_IN_NO = "";
    //       }                  
    //     }
    //   }
    //   this.$store.commit("callStore/MU_CTI_INFO", this.cti_info);

    //   let pVal = {
    //     ivrInNo: this.receive.IVR_IN_NO
    //     ,ivrInCd: this.receive.IVR_IN_CD
    //     ,ivrInPath: this.receive.IVR_IN_PATH
    //     ,stdTime: this.receive.STD_TIME
    //     ,errMent: this.receive.IVR_MENT
    //     ,COMP_TYPE: this.receive.COMP_TYPE
    //     ,COMP_RST: this.receive.COMP_RST
    //     ,ACCT_NO: this.receive.ACCT_NO
    //     ,ACCT_NM: this.receive.ACCT_NM
    //     ,ACCT_PWD: this.receive.ACCT_PWD
    //     ,RRNO: this.receive.RRNO
    //     ,MBL_NO: this.receive.MBL_NO
    //     ,PHNNUM_YN: this.receive.PHNNUM_YN
    //     ,EVENT: this.receive.CALL_TYPE
    //   }

    //   eventBus.setIvrInf(pVal);

    //   //TOBE 확인 사항
    //   if(this.ctiStt != "READY" && this.NOW_CALL_TYPE != "CONSULT") {
    //     //this.common_alert(this.ctiStt + ` 상태입니다.`, 'error');
    //     //return;
    //   }
    //   this.setCtiTimer();
    //   //this.cleanCallInf("IN");
    //   this.TRANS_YN = false;
    //   this.$store.dispatch('callStore/AC_PLAY_YN', "N");
    //   this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");
    //   this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N"); //상담저장여부
    //   //this.cti_info.CALL_TYPE = "IN";
    //   this.CALLYN = "Y";

    //   console.log("=============answerCall 시작") ;
    //   if(answerFlag != "N"){
    //     this.finesseHandler1.finesseHandler.answerCall(this.CURR_CALL_ID);
    //   }
    // },
    // /* 호전환 */
    // fnTransfer() {
    //   //this.appendLog("fnTransfer", "Button Clicked!!!");
    //   if(!this.callSttCheck("TRSF")) return false;
    //   this.setTrnsValue("Y");
    //   this.openWinPop('P100401', '1000','655');
    // },
    // fnChgServer() {
    //   this.finesseHandler1.finesseHandler.changeFinesseServe(this.CURR_CALL_ID);
    // },
    // /* 전화끊기 */
    // fnDropCall() {
    //   //this.appendLog("fnDropCall", "fnDropCall Button Clicked!!!");
    //   if(!this.callSttCheck("DROP")) return false;

    //   this.setCtiTimer();
    //   this.finesseHandler1.finesseHandler.dropCall(this.CURR_CALL_ID);
    // },
    // /* 콜변수셋팅 */
    // fnSetCall() {
    //   //this.appendLog("fnDropCall", "fnDropCall Button Clicked!!!");
    //   //this.finesseHandler1.finesseHandler.setCallData(this.ctiParams);
    // },
    // /* 전화보류 */
    // fnHoldCall() {
    //   //this.appendLog("fnHoldCall", "fnHoldCall Button Clicked!!!"  + this.ctiHold);
    //   if(!this.callSttCheck("HOLD")) return false;

    //   if(this.ctiStt == "HOLD") {
    //     this.finesseHandler1.finesseHandler.retrieveCall(this.CURR_CALL_ID);
    //   } else {
    //     this.finesseHandler1.finesseHandler.holdCall(this.CURR_CALL_ID);
    //   }
    // },
    // /* 전환 요청 */
    // fnConsultCall(consultNum) {
    //   //this.appendLog("fnConsultCall", "Button Clicked!!! consultNum:[" +consultNum+ "]");
    //   this.setTrnsValue();
      
    //   this.finesseHandler1.finesseHandler.consultCall(consultNum, this.CURR_CALL_ID, this.ctiParams);
    // },
    // /* 전환 취소 */
    // fnReconnect() {
    //   //this.appendLog("fnReconnect", "fnReconnect Button Clicked!!!");
    //   this.finesseHandler1.finesseHandler.reconnect(this.CURR_CALL_ID);
    //   let tmpData = {
    //     id: "fnReconnect"
    //   }
    // },

    // /* 전환 완료 */
    // fnTransferCall() {
    //   //this.appendLog("fnTransferCall", "fnTransferCall Button Clicked!!!");
    //   this.finesseHandler1.finesseHandler.transferCall(this.CURR_CALL_ID);
    //   let tmpData = {
    //     id: "fnTransferCall"
    //   }
    // },
    // /* 1step 전환 완료 */
    // fnSSTransferCall(consultNum) {
    //   //this.appendLog("ssTransferCall", "Button Clicked!!! consultNum:[" +consultNum+ "]");
    //   this.finesseHandler1.finesseHandler.ssTransferCall(consultNum, this.CURR_CALL_ID, this.ctiParams);
    // },
    // /* 회의 완료 */
    // fnConferenceCall() {
    //   //this.appendLog("fnConferenceCall", "Button Clicked!!!");
    //   this.finesseHandler1.finesseHandler.conferenceCall(this.CURR_CALL_ID);
    // },
    // /* call 정보 조회 */
    // fnCallInfo(div) {
    //   //this.appendLog("fnCallInfo", "fnCallInfo Button Clicked!!!");
    //   this.finesseHandler1.finesseHandler.CallInfo(div);
    //   ////this.appendLog("fnCallInfo", this.cti_info);
    // },
    // /* call 리스트 조회 */
    // fnCallList(div) {
    //   //this.appendLog("fnCallList", "fnCallList Button Clicked!!!");
    //   this.finesseHandler1.finesseHandler.CallList(div);
    //   ////this.appendLog("fnCallInfo", this.cti_info);
    // },
    // /* USER 정보 조회 */
    // fnUserInfo(div) {
    //   //this.appendLog("fnUserInfo", "fnUserInfo Button Clicked!!!");
    //   this.finesseHandler1.finesseHandler.UserInfo();
    //   ////this.appendLog("fnCallInfo", this.cti_info);
    // },
    // /* ARS 호출 */
    // fnArs() {
    //   //this.fnCtiStt('6');
    //   // this.$store.dispatch("callStore/AC_CUST_INOUT", this.cti_info.CALL_TYPE);

    //   //this.appendLog("fnArs", "fnArs Button Clicked!!!");
    //   if(!this.callSttCheck("ARS")) return false;
    //   if(!this.cti_info.CTI_MODE) {
    //     this.common_alert(`CTI OFF 상태입니다.`, 'error');
    //     return false;
    //   }

    //   if(this.ctiStt != "TALKING") {
    //     this.common_alert(this.ctiSttNm + ` 상태입니다. 통화중에만 \n요청 가능합니다.`, 'error');
    //     return false;
    //   }

    //   this.ARS_FROM_NO = this.cti_info.FROM_TNO;
    //   console.log(">>>>this.ARS_FROM_NO::" + this.ARS_FROM_NO);
    //   // this.setVar9();
    //   this.finesseHandler1.finesseHandler.ssTransferCall("65100", this.CURR_CALL_ID , this.ctiParams, "ARS");
    //   //_finesse_srch.getState(sagentid, callBackAgentStateHandler, callBackAgentStateErrHandler);
    // },
    // setVar9() {
    //   this.ctiParams[6].value = "";
    //   //this.finesseHandler1.finesseHandler.setCallData(this.ctiParams);
    // },
    // /* 상태변경 */
    // async fnChgStateClick() {
    //   this.cnsltHistCheck = await this.fnCnsltHistCheck("Y");      
    //   if(this.ctiStt == "TALKING") {
    //     this.common_alert(`통화중에는 상태변경을 선택할 수 없습니다.`, 'error');
    //     return false;
    //   } 
    //   //후처리중 상담저장이 안된경우
    //   if(this.ctiStt == "A_SETACW" && !this.mixin_isEmpty(this.CURR_CALL_ID) && this.geCnslSaveYn == "N") {
    //     if(this.geCustInfo.CLNT_NO != this.geCustInfoPrev || this.cnsltHistCheck == false){
    //       this.common_alert(`상담이력을 저장해주세요.`, 'info');
    //       return false;
    //     }else{
    //       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //     }
    //   }
    //   if(!this.callSttCheck("CHGSTT")) return false;

    //   this.cti_info.FROM_TNO     = "";
    //   this.cti_info.IVR_SERV_NO  = "";
    //   this.cti_info.IVR_IN_NO    = "";
    //   this.cti_info.IVR_IN_PATH  = "";
    //   this.cti_info.IVR_STD_TIME = "";
    //   this.cti_info.ERR_MENT     = "";
    //   this.cti_info.IVR_MENT     = "";
    //   this.cti_info.END_DT       = "";
    //   this.cti_info.END_TM       = "";
    //   this.cti_info.CALL_TYPE    = "";
    //   this.cti_info.REASON_CD    = "";
    //   this.cti_info.BEGIN_DT     = "";
    //   this.cti_info.BEGIN_TM     = "";
    //   this.cti_info.val1         = "";  
    //   this.cti_info.val6         = "";  
    //   this.cti_info.val9         = "";
    //   this.cti_info.errCd        = "";
    //   this.cti_info.CUST_NM      = "";
    //   this.cti_info.sCstAni      = "";
    //   this.cti_info.frmAddr      = "";
    //   this.cti_info.IVR_IN_NO    = "";

    //   this.$store.dispatch('callStore/AC_TO_TNO', "99999999");
    //   this.$store.commit('callStore/MU_CTI_INFO', this.cti_info); 

    //   this.ctiStepOutMenu = !this.ctiStepOutMenu;
    // },
    // /* 상태변경 */
    // fnChangeState(reasonCd) {
    //   if(this.mixin_isEmpty(reasonCd)) {
    //     this.common_alert(`이석 사유 코드가 없어서 \n 상태변경 처리 불가`, 'info');
    //     return false;
    //   }
      
    //   this.cti_info.REASON_CD = reasonCd;
    //   if(reasonCd != "27" && reasonCd != "30" && reasonCd != "34" && reasonCd != "35") {
    //     /* 신통초기화 */
    //     if(!this.geSintongClear) {           
    //       this.mixin_sintongReset();
    //     }
    //   }
    //   if(reasonCd == "35") {
    //     this.setCtiTimer();
    //   }
    //   this.finesseHandler1.finesseHandler.userChangeStateReason("NOT_READY", reasonCd);
    // },
    // /* 원격 BTN CLICK!! */
    // fnRemote: function() {
    //   console.log("fnRemote Button Clicked!!!");
    // },
    // /* STT BTN CLICK!! */
    // fnStt: function() {
    //   console.log("STT Button Clicked!!!");
    // },

    // fnTrnsCsltStt(teamCd) {
    //   let userId = "9991";
    //   this.finesseHandler1.finesseHandler.usersSttList(userId, teamCd);
    // },
    // async fnCustDtl1(custNo, acctNo, acntPwd) {
    //   let ivrErrNm = "";
    //   api.post("/api/phone/main/custDtl-info/inqire", //api url입력
    //     {
    //       CUST_NO: custNo,
    //       ACCT_NO: acctNo,
    //       ACNT_PWD: acntPwd
    //     },
    //     {
    //       head: {
    //         SERVICE: "phone.main.custDtl",
    //         METHOD: "inqire",
    //         TYPE: "BIZ_SERVICE",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     if (response.data.HEADER.COUNT == 0) {
    //       let errMsg = response.data.HEADER.ERROR_MSG;
    //           errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"";
    //       console.log("getIvrErrNm:[" + errMsg +"]");
    //       return false;
    //     }
    //     let objCustDtl = response.data;
    //     console.log(">>>>>objCustDtl>>>>>>" + JSON.stringify(objCustDtl));
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
    //   return ivrErrNm;
    // },
    xmlToJson(xml) {
        var obj = {};

        if(xml.nodeType == 1) {
          if(xml.attributes.length > 0) {

          }
        }

    },
    /* 녹취 Start */
    // rdwtOpenCall: async function (rdwtId) {
    //   if(this.mixin_isEmpty(rdwtId)) {
    //     rdwtId = this.cti_info.RDWT_ID;
    //   }

    //   if(this.mixin_isEmpty(rdwtId)) {
    //     this.common_toast("녹취키 생성실패!! 확인 하세요.", "info");
    //     return;
    //   }

    //   /**
    //    * station    상담원 내선번호
    //    * agent_id   상담원ID
    //    * overwrite  0:기존 정보 유지, 1:기존 정보 오버라이트
    //    * opt1       녹취아이디
    //    * opt2       계좌번호
    //    **/

    //   let userId = this.$store.getters['userStore/GE_USER_ID']
    //   let URLData  = this.rdwtInfo.rdwt_udt_url1 + this.rdwtInfo.rdwt_udt_stt_url;
    //   let URLData2 = this.rdwtInfo.rdwt_udt_url2 + this.rdwtInfo.rdwt_udt_stt_url;
    //   let URLData3 = this.rdwtInfo.rdwt_udt_url3 + this.rdwtInfo.rdwt_udt_stt_url;
    //   let URLData4 = this.rdwtInfo.rdwt_udt_url4 + this.rdwtInfo.rdwt_udt_stt_url;

    //   let addURL = "?station=" + this.cti_info.IPCC_NO + "&agent_id=" + this.cti_info.USER_ID + "&overwrite=0" 
    //               + "&opt1=" + this.cti_info.RDWT_ID + "&opt2=" ;
    //   try {
    //     apiCall.ipcc(URLData  + addURL);
    //     apiCall.ipcc(URLData2 + addURL);
    //     apiCall.ipcc(URLData3 + addURL);
    //     apiCall.ipcc(URLData4 + addURL);
    //   } catch(apiEx) {
    //     console.log("녹취중 에러 발생!! - "+apiEx);
    //   }
    // },
    // /* 청취 WebPlay */
    // openRecPlay: async function (rewtId) {
    //   let agentId   = this.cti_info.USER_ID;  // 사용자 사번
    //   let startDate = this.cti_info.USER_ID;  // 콜이 생성 된 날짜
    //   let endDate   = this.cti_info.USER_ID;  // 콜이 생성 된 날짜
    //   let callKey   = this.cti_info.CALL_ID;  // 녹취키
    //   let SaveFlag  = "1";  // 0:파일저장불가, 1:파일저장가능

    //   window.open("http://172.17.10.160:18088/provider/key/play?agentId="+ agentId + "&startDate=" + startDate + "&endDate=" + endDate + "&callKey=" + callKey + "&SaveFlag=" + saveFlg, 'WebPlay', 'width=780,height=360');
    // },


    // async fnCnsltHistCheck(val) {
    //   let updateYN = false;

    //   let requestData1 = {
    //     headers: {
    //       SERVICE: 'phone.main.cnslt-enddttm',
    //       METHOD: 'inquire',
    //       TYPE: "BIZ_SERVICE",
    //     },
    //     sendData: {}
    //   };

    //   requestData1.headers["URL"] = "/api/phone/main/cnslt-enddttm/select";
    //   requestData1.headers["METHOD"] = "select";
    //   requestData1.headers["ASYNC"] = false;
      
    //   requestData1.sendData['CNSL_BEGIN_DATE'] = this.geCtiInfo.END_DT;
    //   requestData1.sendData['ASP_CUST_KEY'] = '001';
    //   requestData1.sendData['CALL_ID'] = this.geCtiInfo.CALL_ID;
    //   requestData1.sendData['END_YN']  = val;
    //   requestData1.sendData['REG_ID']  = this.geUserRole.userId;

    //   const response1 = await this.common_postCall_noPB(requestData1);

    //   if(!this.mixin_isEmpty(response1.DATA) && response1.DATA.length > 0) {
    //     updateYN = true;
    //   }
    //   return updateYN;
    // },
    // clearCtiInfo () {
    //   this.geCtiInfo.CALL_ID   = "";
    //   this.geCtiInfo.RDWT_ID   = "";
    //   this.geCtiInfo.REASON_CD = "";
    //   this.geCtiInfo.CALL_TYPE = "";
    //   this.geCtiInfo.FROM_TNO  = "";
    //   this.CURR_CALL_ID = "";
    //   this.RDWT_ID      = "";
    //   this.geCtiInfo.IVR_IN_NO    = "";
    //   this.geCtiInfo.IVR_IN_PATH  = "";
    //   this.geCtiInfo.IVR_STD_TIME = "";
    //   this.geCtiInfo.ERR_MENT     = "";
    //   this.geCtiInfo.IVR_IN_CD    = "";
    //   this.geCtiInfo.IVR_SERV_NO  = "";
    //   this.geCtiInfo.BEGIN_DT = "";
    //   this.geCtiInfo.END_DT   = "";
    //   this.geCtiInfo.BEGIN_TM = "";
    //   this.geCtiInfo.END_TM   = "";
    //   this.geCtiInfo.val1     = "";
    //   this.geCtiInfo.val6     = "";
    //   this.geCtiInfo.sCstAni  = "";
    //   this.geCtiInfo.errCd    = "";
    //   this.geCtiInfo.val9     = "";
    //   this.geCtiInfo.ACCT_NO  = "";
    //   this.geCtiInfo.RRNO     = "";
    // },
    // //버튼 상태 변경 0:NOT READY, 1:후처리중, 2:걸기, 3:호전환, 4:끊기, 5:보류해제, 6:ARS, 7:상태변경
    // async fnCtiStt(state) {
    //   console.log(">>>>>>>SET_STT>>>>>>>[" + state + "]");
    //   this.isLoading = false;

    //   try {
    //     if(state === 'Z') {
    //       this.$store.dispatch('callStore/AC_CNSL_SAVE_FLAG', false);
    //       this.CTI_ON = false;
		//       this.cti_info.CTI_MODE = false;
    //       this.ctiSttNm  = "CTI OFF";
    //       this.ctiStt    = "LOGOUT";
    //       this.ctiSttCss = "";
    //       this.nonReady  = true;  // 대기해제버튼상태
    //       this.btnReady  = true;
    //       this.CTI_ON    = false;
    //       this.cti_info.CTI_MODE = false;
    //       this.$store.commit('callStore/MU_CTI_INFO', this.cti_info); 
    //       this.disableYn = [true, true, true, true, true, true, true];
    //       //this.common_toast("CTI 로그아웃 처리 되었습니다.", "info");
    //     //} else if(state === '0' && this.ctiStt == "A_SETACW") {  // 후처리상태
    //       //this.common_toast("후처리 상태 입니다.", "info");
    //     } else if(state === '0' ) {    //0:NOT READY
    //       this.NOW_CALL_TYPE = "";
    //       this.CALLYN = "N"
    //       if(this.ctiStt == "TALKING"  && this.CURR_CALL_ID !="") {
    //         console.log(">>>>>>>NOT_READY>>>>>>>111  cnsltHistCheck ", this.cnsltHistCheck);
    //         this.common_confirmHistRegist(`통화가 종료되었습니다.`, this.cnsltHistUpdateEndTime, null, this.cnsltHistUpdateEndTime, null, 'info');
    //         this.cnsltHistCheck = await this.fnCnsltHistCheck("Y");
    //         if(this.geCustInfo.CLNT_NO == this.geCustInfoPrev && this.cnsltHistCheck == true){
    //           this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //         }else{this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");}
    //         eventBus.setCnslMode('clrCusts');
    //         this.ctiSttNm  = "후처리중";
    //         this.ctiStt    = "A_SETACW";
    //         this.ctiSttCss = "is-bg-gray";
    //         //this.fnChangeState("34");
    //         await this.fnEndTime(); // 통화종료시간
    //       } else if(this.cti_info.REASON_CD == "34"  && this.CURR_CALL_ID =="") {
    //         //후처리 상태가 왔는데 콜아이디 없음!!
    //         this.ctiSttNm  = "후처리중";
    //         this.ctiStt    = "A_SETACW";
    //         this.ctiSttCss = "is-bg-gray";
    //         this.nonReady  = true;
    //         this.btnReady  = true;
    //       } else if(this.cti_info.REASON_CD == "34") {
    //         if(this.geCnslSaveCnt > 0) {
    //           console.log(">>>>>>>NOT_READY>>>>>>>222AAA cnsltHistCheck " + this.cnsltHistCheck);
    //           if(this.READY_YN == "N") {
    //             if(this.geCustInfo.CLNT_NO == this.geCustInfoPrev && this.cnsltHistCheck == true){
    //               this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //             }else{this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");}
    //             this.common_confirmHistRegist(`통화가 종료되었습니다.`, this.cnsltHistUpdateEndTime, null, this.cnsltHistUpdateEndTime, null, 'info');           
    //             this.setCtiTimer();
    //           }
    //           this.cnsltHistCheck = await this.fnCnsltHistCheck("Y");
    //           eventBus.setCnslMode('clrCusts');
    //           this.ctiSttNm  = "후처리중";
    //           this.ctiStt    = "A_SETACW";
    //           this.ctiSttCss = "is-bg-gray";
    //           this.nonReady  = true;
    //           this.btnReady  = true;
    //         } else {
    //           this.ctiSttNm = "로그인";
    //           this.cti_info.CTI_MODE = true;
    //           this.CTI_ON    = true;
    //           this.$store.commit('callStore/MU_CTI_INFO', this.cti_info); 
    //           this.ctiStt    = "LOGIN";
    //           this.ctiSttCss = "";
    //           this.hideDialog('P100201');
    //           this.nonReady  = true;
    //           this.btnReady  = true;
    //         }
    //       } else if(this.mixin_isEmpty(this.cti_info.REASON_CD)) {
    //         console.log(">>>>>>>NOT_READY>>>>>>>222BBB");
    //         if(!this.mixin_isEmpty(this.CURR_CALL_ID)) {
    //           this.setCtiTimer();
    //           this.ctiSttNm  = "후처리중";
    //           this.ctiStt    = "A_SETACW";
    //           this.ctiSttCss = "is-bg-gray";
    //           this.nonReady  = true;
    //           this.btnReady  = true;
    //         }else if(this.geCnslSaveCnt > 0) {
    //           this.setCtiTimer();
    //           this.ctiSttNm  = "후처리중";
    //           this.ctiStt    = "A_SETACW";
    //           this.ctiSttCss = "is-bg-gray";
    //           this.nonReady  = true;
    //           this.btnReady  = true;
    //         } else {
    //           this.endTimer();
    //           this.ctiSttNm = "로그인";
    //           this.cti_info.CTI_MODE = true;
    //           this.CTI_ON    = true;
    //           this.$store.commit('callStore/MU_CTI_INFO', this.cti_info); 
    //           this.ctiStt    = "LOGIN";
    //           this.ctiSttCss = "";
    //           this.hideDialog('P100201');
    //           this.nonReady  = true;
    //           this.btnReady  = true;
    //         }
    //         //this.common_toast("CTI 로그인 처리 되었습니다.", "info");
    //       } else if(this.cti_info.REASON_CD == "27" || this.cti_info.REASON_CD == "30") {
    //         console.log(">>>>>>>NOT_READY>>>>>>>333");
    //         //this.ctiSttNm  = "후처리중";
    //         this.ctiStt    =  "A_SETACW";
    //         this.ctiSttCss = "is-bg-gray";
    //         this.ctiSttCss = this.stepOutMenu[this.activeIndex].ctiSttCss;
    //       } else {
    //         console.log(">>>>>>>NOT_READY>>>>>>>ELSE");
    //         if(this.activeIndex < 7) {
    //           this.ctiMsg =  this.stepOutMenu[this.activeIndex].text;
    //           this.ctiSttNm = this.stepOutMenu[this.activeIndex].text;
    //           this.ctiSttCss = this.stepOutMenu[this.activeIndex].ctiSttCss;
    //           this.ctipopup = this.stepOutMenu[this.activeIndex].popup;
    //         }
    //         this.activeIndex = 9;
    //       }
    //       //this.endTimer();
    //       this.disableYn = [false, false, true, true, true, true, false]; // login

    //     } else if(state === '1') {  //1:후처리중
    //       this.setCtiTimer();
    //       this.NOW_CALL_TYPE = "";
    //       this.CALLYN = "N"
    //       this.ctiSttNm  = "후처리중";
    //       this.ctiStt    = "A_SETACW";
    //       this.ctiSttCss = "is-bg-gray";
    //       this.disableYn = [false, false, true, true, true, false, false]; // Ready
    //     } else if(state === '2R') {
    //       this.ctiSttNm  = "통화시도중";
    //       this.ctiStt    = "TALKRRR";
    //       this.ctiSttCss = "is-bg-light-red";
    //       this.ctiHoldBtnText = "보류";
    //       this.btnReady  = true;
    //       this.disableYn = [true, true, true, false, true, true, true]; // btn_MakeCall
    //     } else if(state === '2T') {
    //         this.ctiSttNm = "통화중";
    //         this.ctiStt = "TALKING";
    //         this.ctiSttCss = "is-bg-red";
    //         this.ctiHoldBtnText = "보류";
    //         this.btnReady  = true;
    //         this.disableYn = [false, true, false, false, false, false, true]; // btn_MakeCall
    //     } else if(state === '2') {
    //       if(this.ctiStt != "TALKRRR") {
    //         this.ctiSttNm = "통화중";
    //         this.ctiStt = "TALKING";
    //         this.ctiSttCss = "is-bg-red";
    //         this.ctiHoldBtnText = "보류";
    //         this.btnReady  = true;
    //         this.disableYn = [false, true, false, false, false, false, true]; // btn_MakeCall
    //       }
    //     } else if(state === '3') {
    //       this.ctiStt = "A_TRANSF";
    //       this.disableYn = [true, true, true, false, true, false, false]; // btn_Trans

    //     } else if(state === '4') {
    //       console.log(">>Ready ctiStt:"+ this.ctiStt);
    //       this.NOW_CALL_TYPE = "";

    //       //BREWDA
    //       if(this.ctiStt == "TALKING" && this.CURR_CALL_ID !="") {
    //         if(this.geCustInfo.CLNT_NO == this.geCustInfoPrev && this.cnsltHistCheck == true){
    //           this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //         }else{this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N");}
    //         this.common_confirmHistRegist(`통화가 종료되었습니다.`, this.cnsltHistUpdateEndTime, null, this.cnsltHistUpdateEndTime, null, 'info');
    //         this.setCtiTimer();
    //         eventBus.setCnslMode('clrCusts');
    //         this.cnsltHistCheck = await this.fnCnsltHistCheck("Y");
    //         this.ctiSttNm = "후처리중";
    //         this.ctiStt = "A_SETACW";
    //         this.ctiSttCss = "is-bg-gray";
    //         this.disableYn = [false, false, true, true, true, true, false]; // SETACW
    //         //this.fnChangeState("34");
    //         await this.fnEndTime(); // 통화종료시간
    //       } else {
    //         this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //         this.ctiSttNm  = "대기중";
    //         this.ctiSttCss = "is-bg-green";
    //         this.nonReady  = false;
    //         this.btnReady  = false;
    //         this.ctiStt = "READY";
    //         this.cti_info.REASON_CD = "";
    //         this.hideDialog('P100201');
    //         this.disableYn = [true, true, true, true, true, true, true]; // ready
    //         // this.mixin_sintongReset();
    //       }
    //       // this.endTimer();

    //     } else if(state === '5') {
    //       this.ctiSttNm = "통화보류";
    //       this.ctiSttCss = "is-bg-cyan";
    //       this.ctiStt = "HOLD";
    //       this.ctiHoldBtnText = "보류해제";
    //       this.disableYn = [true, true, true, true, false, false, true];  // btn_Hold
    //     } else if(state === '6') {
    //       this.ctiSttNm = "ARS";
    //       this.ctiStt = "A_ARSCL";
    //       this.disableYn = [true, true, true, true, true, true, false];   // btn_ARS
    //     } else if(state === '7') {
    //       this.ctiStt = "A_SETAUX";
    //       this.disableYn = [true, true, true, true, true, false, true];   // btn_Absent
    //     }
    //     ////this.appendLog("CTI STATE>>>>", this.ctiStt);
    //     this.$store.dispatch('callStore/AC_CALL_STATE', this.ctiStt); //콜상태값 갱신
	  //   }
    //   catch(ex) {
        
		//     console.log("fnCtiStt ERR:"+ ex);
    //   }
    // },
    getDateTime(){
      var str     = '';
      var Stamp   = new Date();
      var yyyy    = Stamp.getYear();
      var mm, dd;
      var Hours, Mins, Sec;
      if (yyyy < 2000) yyyy = 1900 + yyyy;      
      mm = (Stamp.getMonth() + 1);
      if(mm < 10) mm = "0" +mm;
      dd = Stamp.getDate();
      if(dd < 10) dd = "0" +dd;
      str = yyyy +""+ mm +""+ dd;
      //str = yyyy + "-" + mm  + "-" + dd;
      Hours = Stamp.getHours();
      Mins = Stamp.getMinutes();
      Sec = Stamp.getSeconds();
      if (Hours < 10) Hours = "0" + Hours;
      if (Mins < 10) Mins = "0" + Mins;
      if (Sec < 10) Sec = "0" + Sec;
      str += ""+Hours +""+ Mins +""+ Sec;
      return str;
    },
    evtCloseWinPopup() {
      console.log("evtCloseWinPopup ========= ");
    },
    openWinPop(scrId, width, height) {
      let uri = '/'+scrId+'?param1=abc' + '&param2=def';
      this.$refs.winPopup.openWinPop(uri, width, height);
    },
    /* winPop 에서 호출 */
    // onRecvTopWinPop( recvObj ) {
    //   let phnNum = "";
    //   if(this.ctiStt == "TALKING" && (this.geVMSPlayYn == "Y" || this.gePinTypeNo == "1")) {
    //     this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요!!!`, 'info');
    //     return false;
    //   }
    //   else if(recvObj.evt != null) {
    //     console.log("<=====================================================================>" + recvObj.evt + "/" +recvObj.Val1 );
    //     switch(recvObj.evt) {
    //       case "message" :
    //         this.common_alert(recvObj.Val1, 'error');
    //         break;
    //       case "CONSULT" :
    //         console.log("@@@CONSULT=" +phnNum + "/" +this.TRANS_YN);
    //         phnNum = recvObj.Val1;
    //         if(this.mixin_isEmpty(phnNum)) {
    //           this.common_toast("전환 요청 번호가 없습니다!!", 'info');
    //           console.log("전환 요청 번호가 없습니다!!");
    //           this.sendToChild(1); // 3자통화
    //           return false;
    //         }

    //         if(this.ctiStt != "TALKING") {
    //           this.sendToChild(1);
    //           this.common_toast(`통화중에만 처리 가능합니다.`, 'error');
    //           return;
    //         }

    //         this.TRANS_YN = true;
    //         this.cti_info.TRNS_YN  = "Y";
    //         this.cti_info.TRNS_NO  = phnNum;
    //         this.cti_info.TRNS_CSLT_ID  = this.$store.getters["userStore/GE_USER_ID"];
    //         this.setTrnsValue();
    //         //this.CURR_CONF_YN = "N";  //3자통화여부
    //         this.finesseHandler1.finesseHandler.consultCall(phnNum, this.CURR_CALL_ID, this.ctiParams);
    //         break;
    //       case "CONFERENCE" :
    //         console.log("@@@CONFERENCE=" +phnNum);
    //         this.finesseHandler1.finesseHandler.conferenceCall(this.CURR_CALL_ID);
    //         break;
    //       case "TRANS" :
    //         phnNum = recvObj.Val1;
    //         console.log("@@@TRANSFER=" +phnNum);

    //         this.finesseHandler1.finesseHandler.transferCall(this.CURR_CALL_ID, phnNum);
    //         break;
    //       case "SST" :
    //         phnNum = recvObj.Val1;
    //         if(this.mixin_isEmpty(phnNum)) {
    //           this.common_toast("전환 요청 번호가 없습니다!!", 'info');
    //           console.log("전환 요청 번호가 없습니다!!");
    //           this.sendToChild(2); //호전환
    //           return false;
    //         }

    //         if(this.ctiStt != "TALKING") {
    //           this.sendToChild(4);
    //           this.common_toast(`통화중에만 처리 가능합니다.`, 'error');
    //           return;
    //         }
    //         this.setTrnsValue();
    //         this.finesseHandler1.finesseHandler.ssTransferCall(phnNum, this.CURR_CALL_ID, this.ctiParams);
    //         break;
    //       case "RECONN" :
    //         phnNum = recvObj.Val1;
    //         console.log("@@@RECONN=" +phnNum);

    //         this.finesseHandler1.finesseHandler.reconnect(this.CURR_CALL_ID, this.TRNS_CALL_ID);
    //         break;
    //       case "TEAM" :
    //         phnNum = recvObj.Val1;
    //         this.finesseHandler1.finesseHandler.usersSttList(this.cti_info.USER_ID, phnNum);
    //         break;
    //     }
    //   }
    // },
    sendToChild(val) {
      if(val == 1) {
        this.$refs.winPopup.sendEvtToChild( { msg: 'consult'} );
      } else if(val == 2) {
        this.$refs.winPopup.sendEvtToChild( { msg: 'transfer'} );
      } else if(val == 3) {
        this.$refs.winPopup.sendEvtToChild( { msg: 'retrieve'} );
      } else if(val == 4) {
        this.$refs.winPopup.sendEvtToChild( { msg: 'tryConsult'} );
      } 
    },
    closePassowrdDialog() {
      this.PASSWORD_NEW = "";
      this.PASSWORD_NEW2 = "";
      this.PASSWORD_OLD = "";
      this.hideDialog("TopAreaP02");
    },

    initMsg() {
      this.topMsg = this.$store.getters["userStore/GE_USER_TOPMSG"];
    },
    initIcon() {
      this.agentInfo.ICON = this.$store.getters["userStore/GE_USER_ICON"];
      this.setIcon();
    },
    // init(){
    //   const chatWorker = new Worker('/chatWorker.js');

    //   chatWorker.postMessage("나 통계이야!!!");

    //   chatWorker.addEventListener('message', function(e){
    //     eventBus.checkSocket();       
    //   }, false);          
    // },        

    setAgent() {
      if (this.agentInfo.OPTION == "msg") {
        this.setTopmsg();
      } else if (this.agentInfo.OPTION == "icon") {
        this.setIcon();
      }
    },

    setTopmsg() {
      this.topMsg = this.agentInfo.MSG;
    },

    setIcon() {
      //alert(this.agentInfo.ICON);
      this.resetIcon();
      let iconValue = this.agentInfo.ICON;
      if (iconValue == "f01") {
        this.isf01 = true;
      } else if (iconValue == "f02") {
        this.isf02 = true;
      } else if (iconValue == "f03") {
        this.isf03 = true;
      } else if (iconValue == "f04") {
        this.isf04 = true;
      } else if (iconValue == "m01") {
        this.ism01 = true;
      } else if (iconValue == "m02") {
        this.ism02 = true;
      } else if (iconValue == "m03") {
        this.ism03 = true;
      } else if (iconValue == "m04") {
        this.ism04 = true;
      } else {
        this.isf01 = true;
      }
    },

    resetIcon() {
      this.isf01 = "";
      this.isf02 = "";
      this.isf03 = "";
      this.isf04 = "";
      this.ism01 = "";
      this.ism02 = "";
      this.ism03 = "";
      this.ism04 = "";
    },

    // checkTest() {
    //   //alert("11112222");
    //   //$("#csIcon").attr("src", "@/assets/girl1.svg");
    //   this.csIcon.image = "girl1";
    // },
    //비밀번호체크
    checkPassword() {
      var pwNew = this.PASSWORD_NEW;
      var num = pwNew.search(/[0-9]/g);
      var eng = pwNew.search(/[a-z]/gi);
      var spe = pwNew.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

      if (this.PASSWORD_OLD == "" || this.PASSWORD_OLD == "undefined") {
        this.common_alert("비밀번호를 입력해주세요.", "error");
        return;
      } else if (this.PASSWORD_NEW != this.PASSWORD_NEW2) {
        this.common_alert(
          "새 비밀번호와 비밀번호 확인이 일치하지 않습니다.",
          "error"
        );
        return;
      } else if (num < 0 || eng < 0 || spe < 0) {
        this.common_alert(
          "영문,숫자, 특수문자를 혼합하여 입력해주세요.",
          "error"
        );
        return;
      } else if (pwNew.length < 8 || pwNew.length > 20) {
        this.common_alert("8자리 ~ 20자리 이내로 입력해주세요.", "error");
        return;
      } else if (pwNew.search(/\s/) != -1) {
        this.common_alert("비밀번호는 공백 없이 입력해주세요.", "error");
        return;
      } else {
        this.getKey();
      }
    },
    //암호화 키 호출
    getKey() {
      api
        .post("api/login/password-encpt/process", {
          username: this.user_id,
        })
        .then((response) => {
          if (this.mixin_check_repsonse(response) === true) {
            //alert("0000");
            if (response.data.DATA) {
              this.ENCRYPT_KEY = response.data.DATA[0].ENCRYPT_KEY;

              //호출된 암호화 키로 로그인 처리
              //alert(this.user_id);

              this.changePassword();
            } else {
              this.common_toast(
                "비밀번호 변경을 실패하였습니다(암호화키 에러)",
                "error"
              );
              //alert("1111");
            }
          }

          // commit("MU_USER_MENU", response.data);
        })
        .catch((error) => {
          // 에러인 경우 처리
          // console.error("menu 조회 에러", error);
          this.common_toast(
            "비밀번호 변경을 실패하였습니다(암호화키 에러)",
            "error"
          );
        });
    },
    //비밀번호 변경 처리
    changePassword() {
      const password_OLD = this.PASSWORD_OLD;
      const strPassWD_OLD = sha256(password_OLD);
      let encPassWD_OLD = GibberishAES.aesEncrypt(
        strPassWD_OLD,
        this.ENCRYPT_KEY
      ); //gibberish-aes.js

      const password_NEW = this.PASSWORD_NEW;
      const strPassWD_NEW = sha256(password_NEW);
      let encPassWD_NEW = GibberishAES.aesEncrypt(
        strPassWD_NEW,
        this.ENCRYPT_KEY
      ); //gibberish-aes.js
      //alert(this.ENCRYPT_KEY);

      const CHK_OLD_FLAG = "Y";
      const PWD_FAIL_FREQ = "0";
      const BIZ_DIV = "CHANGEPWD";
      const PWD_STATUS = "NORMAL";
      api
        .post(
          "api/login/password/modify",
          {
            CHK_OLD_FLAG: CHK_OLD_FLAG,
            USER_ID: this.user_id,
            PWD: password_OLD,
            PASSWORD_OLD: encPassWD_OLD,
            PASSWORD_NEW: encPassWD_NEW,
            PWD_FAIL_FREQ: PWD_FAIL_FREQ,
            BIZ_DIV: BIZ_DIV,
            PROC_ID: this.user_id,
            PWD_STATUS: PWD_STATUS,
            ENCRYPT_KEY: this.ENCRYPT_KEY,
          },
          {
            head: {
              SERVICE: "login/password",
              METHOD: "/modify",
              TYPE: "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          if (response.data.HEADER.ERROR_FLAG == true) {
            this.common_alert(
              "아이디 또는 비밀번호가 일치하지 않습니다.",
              "error"
            );
            return;
          } else {
            this.common_alert("정상적으로 수정되었습니다.");
          }
          console.log(response.data);
          console.log(response.data.DATA);
        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("비밀번호 변경 에러", error);
        });
    },
    // endDialog() {
    //   //this.endTimer();
    //   this.setCtiTimer();
    //   this.ctiSttNm  = "후처리중";
    //   this.ctiStt    = "A_SETACW";
    //   this.ctiSttCss = "is-bg-gray";
    //   this.fnChangeState("35");
    //   this.ctiStepOutMenu = false;
    //   this.dialogStatusUser=false;
    // },
    showDialog(type) {
      //this.ism03 = true;
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      console.log("완료");
      this[`dialog${type}`] = false;
    },
    passDialog(type) {
      this[`dialog${type}`] = true;
    },
    btnActive(e) {
      const _t = e.currentTarget;
      var togState =
        _t.classList.value.indexOf("v-btn--active") < 0 ? true : false;

      if (togState == false) {
        _t.classList.remove("v-btn--active");
        togState = true;
      } else {
        _t.classList.add("v-btn--active");
        togState = false;
      }
    },
    btnActiveExpand(e) {
      const _t = e.currentTarget;
      var togState = true;

      if (togState == false) {
        _t.classList.remove("v-btn--active");
        togState = true;
      } else {
        _t.classList.add("v-btn--active");
        togState = false;
      }
      this.ctiExpand = !this.ctiExpand;
    },
    // ctiMenuUpdate(i) {
    //   const valClass = this.ctiMenu[i].className;
    //   const valText = this.ctiMenu[i].text;

    //   this.ctiExpand = false;
    //   this.ctiMenuActive = i;

    //   this.$refs.ctiToggleIcon.$el.classList.remove(
    //     "cti-btn-icon08",
    //     "cti-btn-expand-icon01",
    //     "cti-btn-expand-icon02",
    //     "cti-btn-expand-icon03",
    //     "cti-btn-expand-icon04"
    //   );
    //   this.$refs.ctiToggleIcon.$el.classList.add(valClass);
    //   this.$refs.ctiToggleText.textContent = valText;
    // },
    // ctiMenuClass() {
    //   switch (this.ctiMenuActive) {
    //     case 0:
    //       this.ctiMenu[0].className;
    //       break;
    //     case 1:
    //       this.ctiMenu[1].className;
    //       break;
    //     case 2:
    //       this.ctiMenu[2].className;
    //       break;
    //     case 3:
    //       this.ctiMenu[3].className;
    //       break;

    //     default:
    //       "cti-btn-icon08";
    //       break;
    //   }
    // },
    // setCtiTimer() {
    //   console.log("CTI STT!!!");
    //   this.endTimer();
    //   this.CTI_TM_SS = 0;

    //   this.interval = setInterval(() => {
    //     this.CTI_TM_SS += 1;

    //     this.autoTimeFormat(this.CTI_TM_SS);
    //     //console.log("time:["+ this.CTI_TM_SS +"]");
    //   }, 1000); //1초마다
    // },
    // endTimer() {
    //   console.log("CTI END!!!" + this.interval);
    //   if(this.interval > 0) {
    //     clearInterval(this.interval);
		// 		this.callTime = this.interval;
    //     this.interval = 0;
    //   }
    // },
    // autoTimeFormat(ctiTmSS){
    //   let ctiTm = parseInt(ctiTmSS/3600);
    //   let ctiMin = parseInt((ctiTmSS%3600)/60);
    //   let ctiSS = parseInt(ctiTmSS%60);

    //   this.TM_SS_FRM = (ctiTm<10 ? '0'+ctiTm:ctiTm) + ':' + (ctiMin<10 ? '0'+ctiMin:ctiMin) + ':' + (ctiSS<10 ? '0'+ctiSS:ctiSS)
    // },
    logout() {

      

      //Arthur.Kim 2022.05.18
      eventBus.busStompDisconnect();
      
      this.$store
        .dispatch("authStore/AC_AUTH_LOGOUT", { id: this.userId })
        .then((response) => {
          if(this.CTI_ON) {
            this.CTI_ON = false;
            this.cti_info.CTI_MODE = false;
            this.finesseLogOut();
         }

          if (this.mixin_check_repsonse(response) === true) {
            console.log("######### 로그아웃 성공");
          }


          let arrPop = this.$store.getters["popStore/GE_POP"];
            console.log("######### arrPop  ", arrPop);

          for(var i = 0; i < arrPop.length; i ++){
            console.log("######### arrPop[]  ", arrPop[i]);

            let obj = arrPop[i];
            obj.close();
          }


          this.$store.commit("alertStore/INIT");
          this.$store.commit("toastStore/INIT");

          //API가 정상처리 되지 않아도 세션 삭제 후 로그인 페이지로 이동 시킨다
          // window.sessionStorage.clear();
          this.$router.push("/login");
          window.location.reload(true);
        })
        .catch((err) => {
          // window.sessionStorage.clear();
          console.log("######### 로그아웃 에러");
          if(this.CTI_ON) {
            this.CTI_ON = false;
            this.cti_info.CTI_MODE = false;
            this.finesseLogOut();
          }
          this.$router.push("/login");
          window.location.reload(true);
          // this.errorMessage = err.response.data.errormessage;
        });
    },
    checkUserStore(type) {
      if (type === "user_role") {
        console.log(
          "##########################################################"
        );
        console.log(
          "userStore.user_role=",
          this.$store.getters["userStore/GE_USER_ROLE"]
        );
        console.log(
          "##########################################################"
        );
      } else {
        console.log(
          "##########################################################"
        );
        console.log(
          "userStore.user_menu=",
          this.$store.getters["userStore/GE_USER_MENU"]
        );
        console.log(
          "##########################################################"
        );
      }
    },
    
    //이석 상태변경
    // setActiveStepOutMenu(index){
    //   if(this.ctiStt == "TALKING") {
    //     this.common_alert(`통화중 상담 저장이 필요합니다.`, 'info');
    //     return;
    //   }

    //   this.ctiMsg =  this.stepOutMenu[index].text;
    //   this.ctiSttNm = this.stepOutMenu[index].text;
    //   this.ctiSttCss = this.stepOutMenu[index].ctiSttCss;
    //   this.ctipopup = this.stepOutMenu[index].popup;

    //   let reasonCd = this.stepOutMenu[index].value;

    //   this.fnChangeState(reasonCd);
    //   console.log("reasonCd:" + reasonCd);
    //   this.setCtiTimer();

    //   this.activeIndex = index;
    //   //this.ctiStt(1);  // READY 상태변경
    //   this.dialogStatusUser=true;
    //   if(index===0 || index===3){
    //     this.dialogStatusUser=false;
    //   } else {
    //     this.mvCallMain("Y");
    //   }
    //   this.ctiStepOutMenu = false;    
    // },

    outsideClick(e){
      let el = document.querySelector('.jh-quick-layer');
      let target = e.target;
      if( !this.$el.contains(target) ){ 
        this.ctiStepOutMenu = false;
      }
    },
    insideClick(e){
      console.log(">>>>>inside" + e.target);
    }, 
    outsideClick2(e){
      let el = document.querySelector('.jh-quick-layer');
      let target = e.target;
      if( !this.$el.contains(target) ){ 
        this.MakeCallLayer = false;
      }
    },

    // async toggleCTI(flag){
    //   if(this.$store.getters["authStore/GE_CHAT_MODE"] == true) {
    //     console.log("CTI ON을 하시려면 채팅 OFF 해주세요");
    //     this.common_toast("CTI ON을 하시려면 채팅 OFF 해주세요","error"); 
    //     return;
    //   }
    //   if(this.CTI_ON && this.ctiStt === "READY") {
    //     console.log("대기 상태에서는 로그아웃을 할수 없습니다!");
    //     this.common_toast("대기 상태에서는 로그아웃을 할수 없습니다!","error"); 
    //     return;
    //   }

    //   if(!this.callSttCheck("LOG")) return false;
    //   this.cnsltHistCheck = await this.fnCnsltHistCheck("Y");      

    //   //후처리중 상담저장이 안된경우
    //   if(this.ctiStt == "A_SETACW" && !this.mixin_isEmpty(this.CURR_CALL_ID) && this.geCnslSaveYn == "N") {
    //       if(this.geCustInfo.CLNT_NO != this.geCustInfoPrev || this.cnsltHistCheck == false){
    //       this.common_alert(`상담이력을 저장해주세요.`, 'info');
    //       return false;
    //     }else{
    //       this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //     }
    //   }

    //   this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");

    //   console.log("**toggleCTI PIN_TYPE::[" + this.gePinTypeNo + "]");

    //   this.$store.dispatch('callStore/AC_PLAY_YN', "N");
    //   this.CTI_ON = !this.CTI_ON;
    //   this.isLoading = true;
    //   if(this.CTI_ON && flag == false) {
    //     this.mvCallMain("");  //메인탭활성화
    //     this.cti_info.CTI_MODE = true;
    //     this.finesseLogin();
    //   } else {
    //     this.cti_info.CTI_MODE = false;
    //     this.finesseLogOut();
    //   }
    // },

    // async login_toggleCTI(flag){

    //       if(this.$store.getters["authStore/GE_CHAT_MODE"] == true) {
    //         console.log("CTI ON을 하시려면 채팅 OFF 해주세요");
    //         this.common_toast("CTI ON을 하시려면 채팅 OFF 해주세요","error"); 
    //         return;
    //       }
    //       if(this.CTI_ON && this.ctiStt === "READY") {
    //         console.log("대기 상태에서는 로그아웃을 할수 없습니다!");
    //         this.common_toast("대기 상태에서는 로그아웃을 할수 없습니다!","error"); 
    //         return;
    //       }

    //       if(!this.callSttCheck("LOG")) return false;
    //       this.cnsltHistCheck = await this.fnCnsltHistCheck("Y");      

    //       //후처리중 상담저장이 안된경우
    //       if(this.ctiStt == "A_SETACW" && !this.mixin_isEmpty(this.CURR_CALL_ID) && this.geCnslSaveYn == "N") {
    //           if(this.geCustInfo.CLNT_NO != this.geCustInfoPrev || this.cnsltHistCheck == false){
    //           this.common_alert(`상담이력을 저장해주세요.`, 'info');
    //           return false;
    //         }else{
    //           this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "Y");
    //         }
    //       }

      
    //   this.$store.dispatch('callStore/AC_PIN_TYPE_NO', "0");

    //   console.log("**login_toggleCTI PIN_TYPE::[" + this.gePinTypeNo + "]");

    //   this.$store.dispatch('callStore/AC_PLAY_YN', "N");
    //   this.CTI_ON = !this.CTI_ON;
    //   this.isLoading = true;
    //   if(this.CTI_ON && flag == false) {
    //     //this.mvCallMain("");  //메인탭활성화
    //     this.cti_info.CTI_MODE = true;
    //     this.finesseLogin();
    //   } 

    // },


    // 외부 시스템
    // openCallRecordPopup: function() {
    //   var VUE_APP_RDWP_SERVER_URL = process.env.VUE_APP_RDWP_SERVER_URL
    //   var VUE_APP_RDWP_SERVER_PORT = process.env.VUE_APP_RDWP_SERVER_PORT          
    //   // this.$openWindow("https://voicerec.ebestsec.co.kr:18088", "녹취");
    //   this.$openWindow(VUE_APP_RDWP_SERVER_URL + ":" + VUE_APP_RDWP_SERVER_PORT, "녹취");

    // },
    // openRemotePopup: function() {
    //   this.$openWindow(`http://www.hc119.com/auto?admin=on&pinno=68&param1=${this.REMOTE_ID}&param2=1234`, "hc119");
    // },
    // openSTTPopup: function() {

    //   var VUE_APP_STT_SERVER_URL2 = process.env.VUE_APP_STT_SERVER_URL2
    //   var VUE_APP_STT_SERVER_PORT2 = process.env.VUE_APP_STT_SERVER_PORT2

    //   const frm = document.createElement(`form`);
    //   frm.style.cssText = 'visibility:hidden; position:fixed; top: 10px;';
    //   frm.innerHTML = `<input type="text" id="agentid" name="agentid" value="${this.$store.getters['userStore/GE_USER_ID']}">`;
    //   //동적 form은 바디에 추가해야함
    //   document.body.appendChild(frm);
    //   const optionStr	= 'width=450, height=700, scrollbars=no, resizable=no, location=yes';

		// 	window.open(frm, 'sttChatPopup', optionStr);

    //   // frm.setAttribute(`action`, `https://172.17.10.168:28081/popup/real.online.ivr.view.cust.none.php`);
    //   frm.setAttribute(`action`, `https://${VUE_APP_STT_SERVER_URL2}:${VUE_APP_STT_SERVER_PORT2}/popup/real.online.ivr.view.cust.none.php`);
    //   frm.setAttribute(`target`, `sttChatPopup`);
    //   frm.setAttribute(`method`, `post`);
      
    //   frm.submit();
    // },
    async cnsltHistUpdateEndTime() {

      let updateYN = false;

      let requestData1 = {
        headers: {
          SERVICE: 'phone.main.cnslt-enddttm',
          METHOD: 'inquire',
          TYPE: "BIZ_SERVICE",
        },
        sendData: {}
      };

      requestData1.headers["URL"] = "/api/phone/main/cnslt-enddttm/select";
      requestData1.headers["METHOD"] = "select";
      requestData1.headers["ASYNC"] = false;
      
      requestData1.sendData['ASP_CUST_KEY'] = '001';
      requestData1.sendData['CALL_ID'] = this.geCtiInfo.CALL_ID;
      requestData1.sendData['REG_ID'] = this.geUserRole.userId;
      requestData1.sendData['END_YN'] = "Y";

      const response1 = await this.common_postCall(requestData1);

      if(!this.mixin_isEmpty(response1.DATA) && response1.DATA.length > 0) {
        updateYN = true;
      }

      console.log("this.geCnslSaveYn", this.geCnslSaveYn)
      console.log("this.mixin_isEmpty(this.geCtiInfo.CALL_ID)", this.geCtiInfo.CALL_ID)
      console.log("this.mixin_isEmpty(this.geCtiInfo.RDWT_ID)", this.geCtiInfo.RDWT_ID)

      if(updateYN && (!this.mixin_isEmpty(this.geCtiInfo.CALL_ID) || !this.mixin_isEmpty(this.geCtiInfo.RDWT_ID))) {

          let requestData = {
            headers: {
              SERVICE: 'phone.main.cnslt-enddttm',
              METHOD: 'inquire',
              TYPE: "BIZ_SERVICE",
            },
            sendData: {}
          };

          requestData.headers["URL"] = "/api/phone/main/cnslt-enddttm/update";
          requestData.headers["METHOD"] = "update";
          requestData.headers["ASYNC"] = false;
                        
          requestData.sendData['CNSL_EOT_DATE'] = this.geCtiInfo.END_DT;
          requestData.sendData['EOT_TIME'] = this.geCtiInfo.END_TM;
          requestData.sendData['TEL_TIME'] = this.geCtiInfo.TEL_TIME;  //통화시간

          requestData.sendData['ASP_CUST_KEY'] = '001';
          requestData.sendData['CALL_ID'] = this.geCtiInfo.CALL_ID;
          requestData.sendData['RDWT_ID'] = this.geCtiInfo.RDWT_ID;

          const response = await this.common_postCall(requestData);

          console.log(response);
          if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
            console.log('업데이트 성공!');
            eventBus.clrCallInf("schSangdam");
          } else {
            this.common_alert(`종료시간 업데이트 중 에러가 발생했습니다.`, 'error');
          }
      } else if (this.ctiStt == 'LOGOUT') {
        // CTI 뻑났을 때

        let prevDate = await this.mixin_getSvrDate("YYYYMMDD");
        let prevTime = await this.mixin_getSvrDate("HH24MISS");

        requestData.sendData['CNSL_EOT_DATE'] = prevDate;
        requestData.sendData['EOT_TIME'] = prevTime;
        requestData.sendData['TEL_TIME'] = this.geCtiInfo.TEL_TIME ? this.geCtiInfo.TEL_TIME : '0';  //통화시간

        requestData.sendData['ASP_CUST_KEY'] = '001';
        requestData.sendData['CALL_ID'] = this.geCtiInfo.CALL_ID;

        const response = await this.common_postCall(requestData);

        console.log(response);
        if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
          console.log('업데이트 성공!');
          eventBus.clrCallInf("schSangdam");
        } else {
          this.common_alert(`종료시간 업데이트 중 에러가 발생했습니다.`, 'error');
        }
      }
    },
    /* 상담원별 상담 건수 조회 */
    getCnslRegCnt(pVal) {
      let cnslRegCnt = 0;
      api
      .post("/api/phone/main/ivrErrMsg/inqire", //api url입력
      {
        MSG_TP_CODE: '001'
       ,USER_ID: this.user_id
      },
      {
        head: {
          SERVICE: "phone.main.ivrMsg",
          METHOD: "inqire",
          TYPE: "BIZ_SERVICE",
        },
      }
      )
      .then((response) => {
        if (response.data.HEADER.COUNT == 0) {
          let errMsg = response.data.HEADER.ERROR_MSG;
          errMsg = errMsg?errMsg.replaceAll("\\n", "\n"):"";
          console.log("getCnslCnt:[" + errMsg +"]");
          return false;
        }
        cnslRegCnt = response.data.DATA[0].CNSL_REG_CNT;
        this.CNSL_REG_CNT = cnslRegCnt;
      })
      .catch((err) => {
        alert(err);
      });
    },

  },
  watch: {
      disableYn: function(nVal, oVal) {
        this.ctiReady   = nVal[0], //대기
        this.ctiCall    = nVal[1], //걸기
        this.ctiTransfer= nVal[2], //호전환
        this.ctiHangUp  = nVal[3], //끊기
        this.ctiHold    = nVal[4], //보류해제
        this.ctiArs     = nVal[5], //ARS
        this.ctiStepOut = nVal[6]  //상태변경
      },
  },
};
//암호화 모듈
let GibberishAES = (function () {
  var Nr = 14,
    /* Default to 256 Bit Encryption */
    Nk = 8,
    Decrypt = false,
    enc_utf8 = function (s) {
      try {
        return unescape(encodeURIComponent(s));
      } catch (e) {
        throw "Error on UTF-8 encode";
      }
    },
    dec_utf8 = function (s) {
      try {
        return decodeURIComponent(escape(s));
      } catch (e) {
        throw "Bad Key";
      }
    },
    padBlock = function (byteArr) {
      var array = [],
        cpad,
        i;
      if (byteArr.length < 16) {
        cpad = 16 - byteArr.length;
        array = [
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
        ];
      }
      for (i = 0; i < byteArr.length; i++) {
        array[i] = byteArr[i];
      }
      return array;
    },
    block2s = function (block, lastBlock) {
      var string = "",
        padding,
        i;
      if (lastBlock) {
        padding = block[15];
        if (padding > 16) {
          throw "Decryption error: Maybe bad key";
        }
        if (padding == 16) {
          return "";
        }
        for (i = 0; i < 16 - padding; i++) {
          string += String.fromCharCode(block[i]);
        }
      } else {
        for (i = 0; i < 16; i++) {
          string += String.fromCharCode(block[i]);
        }
      }
      return string;
    },
    a2h = function (numArr) {
      var string = "",
        i;
      for (i = 0; i < numArr.length; i++) {
        string += (numArr[i] < 16 ? "0" : "") + numArr[i].toString(16);
      }
      return string;
    },
    h2a = function (s) {
      var ret = [];
      s.replace(/(..)/g, function (s) {
        ret.push(parseInt(s, 16));
      });
      return ret;
    },
    s2a = function (string, binary) {
      var array = [],
        i;

      if (!binary) {
        string = enc_utf8(string);
      }

      for (i = 0; i < string.length; i++) {
        array[i] = string.charCodeAt(i);
      }

      return array;
    },
    size = function (newsize) {
      switch (newsize) {
        case 128:
          Nr = 10;
          Nk = 4;
          break;
        case 192:
          Nr = 12;
          Nk = 6;
          break;
        case 256:
          Nr = 14;
          Nk = 8;
          break;
        default:
          throw "Invalid Key Size Specified:" + newsize;
      }
    },
    randArr = function (num) {
      var result = [],
        i;
      for (i = 0; i < num; i++) {
        result = result.concat(Math.floor(Math.random() * 256));
      }
      return result;
    },
    openSSLKey = function (passwordArr, saltArr) {
      // Number of rounds depends on the size of the AES in use
      // 3 rounds for 256
      //        2 rounds for the key, 1 for the IV
      // 2 rounds for 128
      //        1 round for the key, 1 round for the IV
      // 3 rounds for 192 since it's not evenly divided by 128 bits
      var rounds = Nr >= 12 ? 3 : 2,
        key = [],
        iv = [],
        md5_hash = [],
        result = [],
        data00 = passwordArr.concat(saltArr),
        i;
      md5_hash[0] = GibberishAES.Hash.MD5(data00);
      result = md5_hash[0];
      for (i = 1; i < rounds; i++) {
        md5_hash[i] = GibberishAES.Hash.MD5(md5_hash[i - 1].concat(data00));
        result = result.concat(md5_hash[i]);
      }
      key = result.slice(0, 4 * Nk);
      iv = result.slice(4 * Nk, 4 * Nk + 16);
      return {
        key: key,
        iv: iv,
      };
    },
    rawEncrypt = function (plaintext, key) {
      plaintext = GibberishAES.s2a(plaintext);
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // plaintext, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = Math.ceil(plaintext.length / 16),
        blocks = [],
        i,
        cipherBlocks = [];
      for (i = 0; i < numBlocks; i++) {
        blocks[i] = padBlock(plaintext.slice(i * 16, i * 16 + 16));
      }
      if (plaintext.length % 16 === 0) {
        blocks.push([
          16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16,
        ]);
        // CBC OpenSSL padding scheme
        numBlocks++;
      }
      for (i = 0; i < blocks.length; i++) {
        blocks[i] =
          i === 0
            ? xorBlocks(blocks[i], iv)
            : xorBlocks(blocks[i], cipherBlocks[i - 1]);
        cipherBlocks[i] = encryptBlock(blocks[i], key);
      }
      return GibberishAES.Base64.encode(cipherBlocks);
    },
    rawDecrypt = function (cryptArr, key) {
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      //  cryptArr, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = cryptArr.length / 16,
        cipherBlocks = [],
        i,
        plainBlocks = [],
        string = "";
      for (i = 0; i < numBlocks; i++) {
        cipherBlocks.push(cryptArr.slice(i * 16, (i + 1) * 16));
      }
      for (i = cipherBlocks.length - 1; i >= 0; i--) {
        plainBlocks[i] = decryptBlock(cipherBlocks[i], key);
        plainBlocks[i] =
          i === 0
            ? xorBlocks(plainBlocks[i], iv)
            : xorBlocks(plainBlocks[i], cipherBlocks[i - 1]);
      }
      for (i = 0; i < numBlocks - 1; i++) {
        string += block2s(plainBlocks[i]);
      }
      string += block2s(plainBlocks[i], true);

      return dec_utf8(string);
      //return binary ? string : dec_utf8(string);
    },
    aesEncrypt = function (plaintext, key) {
      var rtnVal = "";
      plaintext = GibberishAES.s2a(plaintext);
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // plaintext, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = Math.ceil(plaintext.length / 16),
        blocks = [],
        i,
        cipherBlocks = [];
      for (i = 0; i < numBlocks; i++) {
        blocks[i] = padBlock(plaintext.slice(i * 16, i * 16 + 16));
      }
      if (plaintext.length % 16 === 0) {
        blocks.push([
          16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16,
        ]);
        // CBC OpenSSL padding scheme
        numBlocks++;
      }
      for (i = 0; i < blocks.length; i++) {
        blocks[i] =
          i === 0
            ? xorBlocks(blocks[i], iv)
            : xorBlocks(blocks[i], cipherBlocks[i - 1]);
        cipherBlocks[i] = encryptBlock(blocks[i], key);
      }
      rtnVal = GibberishAES.Base64.encode(cipherBlocks);

      return rtnVal.toString().replace("\n", "");
    },
    aesDecrypt = function (base64Text, key) {
      var cryptArr = GibberishAES.Base64.decode(base64Text);
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      //  cryptArr, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = cryptArr.length / 16,
        cipherBlocks = [],
        i,
        plainBlocks = [],
        string = "";
      for (i = 0; i < numBlocks; i++) {
        cipherBlocks.push(cryptArr.slice(i * 16, (i + 1) * 16));
      }
      for (i = cipherBlocks.length - 1; i >= 0; i--) {
        plainBlocks[i] = decryptBlock(cipherBlocks[i], key);
        plainBlocks[i] =
          i === 0
            ? xorBlocks(plainBlocks[i], iv)
            : xorBlocks(plainBlocks[i], cipherBlocks[i - 1]);
      }
      for (i = 0; i < numBlocks - 1; i++) {
        string += block2s(plainBlocks[i]);
      }
      string += block2s(plainBlocks[i], true);

      return dec_utf8(string);
      //return binary ? string : dec_utf8(string);
    },
    encryptBlock = function (block, words) {
      Decrypt = false;
      var state = addRoundKey(block, words, 0),
        round;
      for (round = 1; round < Nr + 1; round++) {
        state = subBytes(state);
        state = shiftRows(state);
        if (round < Nr) {
          state = mixColumns(state);
        }
        //last round? don't mixColumns
        state = addRoundKey(state, words, round);
      }

      return state;
    },
    decryptBlock = function (block, words) {
      Decrypt = true;
      var state = addRoundKey(block, words, Nr),
        round;
      for (round = Nr - 1; round > -1; round--) {
        state = shiftRows(state);
        state = subBytes(state);
        state = addRoundKey(state, words, round);
        if (round > 0) {
          state = mixColumns(state);
        }
        //last round? don't mixColumns
      }

      return state;
    },
    subBytes = function (state) {
      var S = Decrypt ? SBoxInv : SBox,
        temp = [],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = S[state[i]];
      }
      return temp;
    },
    shiftRows = function (state) {
      var temp = [],
        shiftBy = Decrypt
          ? [0, 13, 10, 7, 4, 1, 14, 11, 8, 5, 2, 15, 12, 9, 6, 3]
          : [0, 5, 10, 15, 4, 9, 14, 3, 8, 13, 2, 7, 12, 1, 6, 11],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = state[shiftBy[i]];
      }
      return temp;
    },
    mixColumns = function (state) {
      var t = [],
        c;
      if (!Decrypt) {
        for (c = 0; c < 4; c++) {
          t[c * 4] =
            G2X[state[c * 4]] ^
            G3X[state[1 + c * 4]] ^
            state[2 + c * 4] ^
            state[3 + c * 4];
          t[1 + c * 4] =
            state[c * 4] ^
            G2X[state[1 + c * 4]] ^
            G3X[state[2 + c * 4]] ^
            state[3 + c * 4];
          t[2 + c * 4] =
            state[c * 4] ^
            state[1 + c * 4] ^
            G2X[state[2 + c * 4]] ^
            G3X[state[3 + c * 4]];
          t[3 + c * 4] =
            G3X[state[c * 4]] ^
            state[1 + c * 4] ^
            state[2 + c * 4] ^
            G2X[state[3 + c * 4]];
        }
      } else {
        for (c = 0; c < 4; c++) {
          t[c * 4] =
            GEX[state[c * 4]] ^
            GBX[state[1 + c * 4]] ^
            GDX[state[2 + c * 4]] ^
            G9X[state[3 + c * 4]];
          t[1 + c * 4] =
            G9X[state[c * 4]] ^
            GEX[state[1 + c * 4]] ^
            GBX[state[2 + c * 4]] ^
            GDX[state[3 + c * 4]];
          t[2 + c * 4] =
            GDX[state[c * 4]] ^
            G9X[state[1 + c * 4]] ^
            GEX[state[2 + c * 4]] ^
            GBX[state[3 + c * 4]];
          t[3 + c * 4] =
            GBX[state[c * 4]] ^
            GDX[state[1 + c * 4]] ^
            G9X[state[2 + c * 4]] ^
            GEX[state[3 + c * 4]];
        }
      }

      return t;
    },
    addRoundKey = function (state, words, round) {
      var temp = [],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = state[i] ^ words[round][i];
      }
      return temp;
    },
    xorBlocks = function (block1, block2) {
      var temp = [],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = block1[i] ^ block2[i];
      }
      return temp;
    },
    expandKey = function (key) {
      // Expects a 1d number array
      var w = [],
        temp = [],
        i,
        r,
        t,
        flat = [],
        j;

      for (i = 0; i < Nk; i++) {
        r = [key[4 * i], key[4 * i + 1], key[4 * i + 2], key[4 * i + 3]];
        w[i] = r;
      }

      for (i = Nk; i < 4 * (Nr + 1); i++) {
        w[i] = [];
        for (t = 0; t < 4; t++) {
          temp[t] = w[i - 1][t];
        }
        if (i % Nk === 0) {
          temp = subWord(rotWord(temp));
          temp[0] ^= Rcon[i / Nk - 1];
        } else if (Nk > 6 && i % Nk == 4) {
          temp = subWord(temp);
        }
        for (t = 0; t < 4; t++) {
          w[i][t] = w[i - Nk][t] ^ temp[t];
        }
      }
      for (i = 0; i < Nr + 1; i++) {
        flat[i] = [];
        for (j = 0; j < 4; j++) {
          flat[i].push(
            w[i * 4 + j][0],
            w[i * 4 + j][1],
            w[i * 4 + j][2],
            w[i * 4 + j][3]
          );
        }
      }
      return flat;
    },
    subWord = function (w) {
      // apply SBox to 4-byte word w
      for (var i = 0; i < 4; i++) {
        w[i] = SBox[w[i]];
      }
      return w;
    },
    rotWord = function (w) {
      // rotate 4-byte word w left by one byte
      var tmp = w[0],
        i;
      for (i = 0; i < 4; i++) {
        w[i] = w[i + 1];
      }
      w[3] = tmp;
      return w;
    },
    // S-box
    SBox = [
      99, 124, 119, 123, 242, 107, 111, 197, 48, 1, 103, 43, 254, 215, 171, 118,
      202, 130, 201, 125, 250, 89, 71, 240, 173, 212, 162, 175, 156, 164, 114,
      192, 183, 253, 147, 38, 54, 63, 247, 204, 52, 165, 229, 241, 113, 216, 49,
      21, 4, 199, 35, 195, 24, 150, 5, 154, 7, 18, 128, 226, 235, 39, 178, 117,
      9, 131, 44, 26, 27, 110, 90, 160, 82, 59, 214, 179, 41, 227, 47, 132, 83,
      209, 0, 237, 32, 252, 177, 91, 106, 203, 190, 57, 74, 76, 88, 207, 208,
      239, 170, 251, 67, 77, 51, 133, 69, 249, 2, 127, 80, 60, 159, 168, 81,
      163, 64, 143, 146, 157, 56, 245, 188, 182, 218, 33, 16, 255, 243, 210,
      205, 12, 19, 236, 95, 151, 68, 23, 196, 167, 126, 61, 100, 93, 25, 115,
      96, 129, 79, 220, 34, 42, 144, 136, 70, 238, 184, 20, 222, 94, 11, 219,
      224, 50, 58, 10, 73, 6, 36, 92, 194, 211, 172, 98, 145, 149, 228, 121,
      231, 200, 55, 109, 141, 213, 78, 169, 108, 86, 244, 234, 101, 122, 174, 8,
      186, 120, 37, 46, 28, 166, 180, 198, 232, 221, 116, 31, 75, 189, 139, 138,
      112, 62, 181, 102, 72, 3, 246, 14, 97, 53, 87, 185, 134, 193, 29, 158,
      225, 248, 152, 17, 105, 217, 142, 148, 155, 30, 135, 233, 206, 85, 40,
      223, 140, 161, 137, 13, 191, 230, 66, 104, 65, 153, 45, 15, 176, 84, 187,
      22,
    ],
    // Precomputed lookup table for the inverse SBox
    SBoxInv = [
      82, 9, 106, 213, 48, 54, 165, 56, 191, 64, 163, 158, 129, 243, 215, 251,
      124, 227, 57, 130, 155, 47, 255, 135, 52, 142, 67, 68, 196, 222, 233, 203,
      84, 123, 148, 50, 166, 194, 35, 61, 238, 76, 149, 11, 66, 250, 195, 78, 8,
      46, 161, 102, 40, 217, 36, 178, 118, 91, 162, 73, 109, 139, 209, 37, 114,
      248, 246, 100, 134, 104, 152, 22, 212, 164, 92, 204, 93, 101, 182, 146,
      108, 112, 72, 80, 253, 237, 185, 218, 94, 21, 70, 87, 167, 141, 157, 132,
      144, 216, 171, 0, 140, 188, 211, 10, 247, 228, 88, 5, 184, 179, 69, 6,
      208, 44, 30, 143, 202, 63, 15, 2, 193, 175, 189, 3, 1, 19, 138, 107, 58,
      145, 17, 65, 79, 103, 220, 234, 151, 242, 207, 206, 240, 180, 230, 115,
      150, 172, 116, 34, 231, 173, 53, 133, 226, 249, 55, 232, 28, 117, 223,
      110, 71, 241, 26, 113, 29, 41, 197, 137, 111, 183, 98, 14, 170, 24, 190,
      27, 252, 86, 62, 75, 198, 210, 121, 32, 154, 219, 192, 254, 120, 205, 90,
      244, 31, 221, 168, 51, 136, 7, 199, 49, 177, 18, 16, 89, 39, 128, 236, 95,
      96, 81, 127, 169, 25, 181, 74, 13, 45, 229, 122, 159, 147, 201, 156, 239,
      160, 224, 59, 77, 174, 42, 245, 176, 200, 235, 187, 60, 131, 83, 153, 97,
      23, 43, 4, 126, 186, 119, 214, 38, 225, 105, 20, 99, 85, 33, 12, 125,
    ],
    // Rijndael Rcon
    Rcon = [
      1, 2, 4, 8, 16, 32, 64, 128, 27, 54, 108, 216, 171, 77, 154, 47, 94, 188,
      99, 198, 151, 53, 106, 212, 179, 125, 250, 239, 197, 145,
    ],
    G2X = [
      0x00, 0x02, 0x04, 0x06, 0x08, 0x0a, 0x0c, 0x0e, 0x10, 0x12, 0x14, 0x16,
      0x18, 0x1a, 0x1c, 0x1e, 0x20, 0x22, 0x24, 0x26, 0x28, 0x2a, 0x2c, 0x2e,
      0x30, 0x32, 0x34, 0x36, 0x38, 0x3a, 0x3c, 0x3e, 0x40, 0x42, 0x44, 0x46,
      0x48, 0x4a, 0x4c, 0x4e, 0x50, 0x52, 0x54, 0x56, 0x58, 0x5a, 0x5c, 0x5e,
      0x60, 0x62, 0x64, 0x66, 0x68, 0x6a, 0x6c, 0x6e, 0x70, 0x72, 0x74, 0x76,
      0x78, 0x7a, 0x7c, 0x7e, 0x80, 0x82, 0x84, 0x86, 0x88, 0x8a, 0x8c, 0x8e,
      0x90, 0x92, 0x94, 0x96, 0x98, 0x9a, 0x9c, 0x9e, 0xa0, 0xa2, 0xa4, 0xa6,
      0xa8, 0xaa, 0xac, 0xae, 0xb0, 0xb2, 0xb4, 0xb6, 0xb8, 0xba, 0xbc, 0xbe,
      0xc0, 0xc2, 0xc4, 0xc6, 0xc8, 0xca, 0xcc, 0xce, 0xd0, 0xd2, 0xd4, 0xd6,
      0xd8, 0xda, 0xdc, 0xde, 0xe0, 0xe2, 0xe4, 0xe6, 0xe8, 0xea, 0xec, 0xee,
      0xf0, 0xf2, 0xf4, 0xf6, 0xf8, 0xfa, 0xfc, 0xfe, 0x1b, 0x19, 0x1f, 0x1d,
      0x13, 0x11, 0x17, 0x15, 0x0b, 0x09, 0x0f, 0x0d, 0x03, 0x01, 0x07, 0x05,
      0x3b, 0x39, 0x3f, 0x3d, 0x33, 0x31, 0x37, 0x35, 0x2b, 0x29, 0x2f, 0x2d,
      0x23, 0x21, 0x27, 0x25, 0x5b, 0x59, 0x5f, 0x5d, 0x53, 0x51, 0x57, 0x55,
      0x4b, 0x49, 0x4f, 0x4d, 0x43, 0x41, 0x47, 0x45, 0x7b, 0x79, 0x7f, 0x7d,
      0x73, 0x71, 0x77, 0x75, 0x6b, 0x69, 0x6f, 0x6d, 0x63, 0x61, 0x67, 0x65,
      0x9b, 0x99, 0x9f, 0x9d, 0x93, 0x91, 0x97, 0x95, 0x8b, 0x89, 0x8f, 0x8d,
      0x83, 0x81, 0x87, 0x85, 0xbb, 0xb9, 0xbf, 0xbd, 0xb3, 0xb1, 0xb7, 0xb5,
      0xab, 0xa9, 0xaf, 0xad, 0xa3, 0xa1, 0xa7, 0xa5, 0xdb, 0xd9, 0xdf, 0xdd,
      0xd3, 0xd1, 0xd7, 0xd5, 0xcb, 0xc9, 0xcf, 0xcd, 0xc3, 0xc1, 0xc7, 0xc5,
      0xfb, 0xf9, 0xff, 0xfd, 0xf3, 0xf1, 0xf7, 0xf5, 0xeb, 0xe9, 0xef, 0xed,
      0xe3, 0xe1, 0xe7, 0xe5,
    ],
    G3X = [
      0x00, 0x03, 0x06, 0x05, 0x0c, 0x0f, 0x0a, 0x09, 0x18, 0x1b, 0x1e, 0x1d,
      0x14, 0x17, 0x12, 0x11, 0x30, 0x33, 0x36, 0x35, 0x3c, 0x3f, 0x3a, 0x39,
      0x28, 0x2b, 0x2e, 0x2d, 0x24, 0x27, 0x22, 0x21, 0x60, 0x63, 0x66, 0x65,
      0x6c, 0x6f, 0x6a, 0x69, 0x78, 0x7b, 0x7e, 0x7d, 0x74, 0x77, 0x72, 0x71,
      0x50, 0x53, 0x56, 0x55, 0x5c, 0x5f, 0x5a, 0x59, 0x48, 0x4b, 0x4e, 0x4d,
      0x44, 0x47, 0x42, 0x41, 0xc0, 0xc3, 0xc6, 0xc5, 0xcc, 0xcf, 0xca, 0xc9,
      0xd8, 0xdb, 0xde, 0xdd, 0xd4, 0xd7, 0xd2, 0xd1, 0xf0, 0xf3, 0xf6, 0xf5,
      0xfc, 0xff, 0xfa, 0xf9, 0xe8, 0xeb, 0xee, 0xed, 0xe4, 0xe7, 0xe2, 0xe1,
      0xa0, 0xa3, 0xa6, 0xa5, 0xac, 0xaf, 0xaa, 0xa9, 0xb8, 0xbb, 0xbe, 0xbd,
      0xb4, 0xb7, 0xb2, 0xb1, 0x90, 0x93, 0x96, 0x95, 0x9c, 0x9f, 0x9a, 0x99,
      0x88, 0x8b, 0x8e, 0x8d, 0x84, 0x87, 0x82, 0x81, 0x9b, 0x98, 0x9d, 0x9e,
      0x97, 0x94, 0x91, 0x92, 0x83, 0x80, 0x85, 0x86, 0x8f, 0x8c, 0x89, 0x8a,
      0xab, 0xa8, 0xad, 0xae, 0xa7, 0xa4, 0xa1, 0xa2, 0xb3, 0xb0, 0xb5, 0xb6,
      0xbf, 0xbc, 0xb9, 0xba, 0xfb, 0xf8, 0xfd, 0xfe, 0xf7, 0xf4, 0xf1, 0xf2,
      0xe3, 0xe0, 0xe5, 0xe6, 0xef, 0xec, 0xe9, 0xea, 0xcb, 0xc8, 0xcd, 0xce,
      0xc7, 0xc4, 0xc1, 0xc2, 0xd3, 0xd0, 0xd5, 0xd6, 0xdf, 0xdc, 0xd9, 0xda,
      0x5b, 0x58, 0x5d, 0x5e, 0x57, 0x54, 0x51, 0x52, 0x43, 0x40, 0x45, 0x46,
      0x4f, 0x4c, 0x49, 0x4a, 0x6b, 0x68, 0x6d, 0x6e, 0x67, 0x64, 0x61, 0x62,
      0x73, 0x70, 0x75, 0x76, 0x7f, 0x7c, 0x79, 0x7a, 0x3b, 0x38, 0x3d, 0x3e,
      0x37, 0x34, 0x31, 0x32, 0x23, 0x20, 0x25, 0x26, 0x2f, 0x2c, 0x29, 0x2a,
      0x0b, 0x08, 0x0d, 0x0e, 0x07, 0x04, 0x01, 0x02, 0x13, 0x10, 0x15, 0x16,
      0x1f, 0x1c, 0x19, 0x1a,
    ],
    G9X = [
      0x00, 0x09, 0x12, 0x1b, 0x24, 0x2d, 0x36, 0x3f, 0x48, 0x41, 0x5a, 0x53,
      0x6c, 0x65, 0x7e, 0x77, 0x90, 0x99, 0x82, 0x8b, 0xb4, 0xbd, 0xa6, 0xaf,
      0xd8, 0xd1, 0xca, 0xc3, 0xfc, 0xf5, 0xee, 0xe7, 0x3b, 0x32, 0x29, 0x20,
      0x1f, 0x16, 0x0d, 0x04, 0x73, 0x7a, 0x61, 0x68, 0x57, 0x5e, 0x45, 0x4c,
      0xab, 0xa2, 0xb9, 0xb0, 0x8f, 0x86, 0x9d, 0x94, 0xe3, 0xea, 0xf1, 0xf8,
      0xc7, 0xce, 0xd5, 0xdc, 0x76, 0x7f, 0x64, 0x6d, 0x52, 0x5b, 0x40, 0x49,
      0x3e, 0x37, 0x2c, 0x25, 0x1a, 0x13, 0x08, 0x01, 0xe6, 0xef, 0xf4, 0xfd,
      0xc2, 0xcb, 0xd0, 0xd9, 0xae, 0xa7, 0xbc, 0xb5, 0x8a, 0x83, 0x98, 0x91,
      0x4d, 0x44, 0x5f, 0x56, 0x69, 0x60, 0x7b, 0x72, 0x05, 0x0c, 0x17, 0x1e,
      0x21, 0x28, 0x33, 0x3a, 0xdd, 0xd4, 0xcf, 0xc6, 0xf9, 0xf0, 0xeb, 0xe2,
      0x95, 0x9c, 0x87, 0x8e, 0xb1, 0xb8, 0xa3, 0xaa, 0xec, 0xe5, 0xfe, 0xf7,
      0xc8, 0xc1, 0xda, 0xd3, 0xa4, 0xad, 0xb6, 0xbf, 0x80, 0x89, 0x92, 0x9b,
      0x7c, 0x75, 0x6e, 0x67, 0x58, 0x51, 0x4a, 0x43, 0x34, 0x3d, 0x26, 0x2f,
      0x10, 0x19, 0x02, 0x0b, 0xd7, 0xde, 0xc5, 0xcc, 0xf3, 0xfa, 0xe1, 0xe8,
      0x9f, 0x96, 0x8d, 0x84, 0xbb, 0xb2, 0xa9, 0xa0, 0x47, 0x4e, 0x55, 0x5c,
      0x63, 0x6a, 0x71, 0x78, 0x0f, 0x06, 0x1d, 0x14, 0x2b, 0x22, 0x39, 0x30,
      0x9a, 0x93, 0x88, 0x81, 0xbe, 0xb7, 0xac, 0xa5, 0xd2, 0xdb, 0xc0, 0xc9,
      0xf6, 0xff, 0xe4, 0xed, 0x0a, 0x03, 0x18, 0x11, 0x2e, 0x27, 0x3c, 0x35,
      0x42, 0x4b, 0x50, 0x59, 0x66, 0x6f, 0x74, 0x7d, 0xa1, 0xa8, 0xb3, 0xba,
      0x85, 0x8c, 0x97, 0x9e, 0xe9, 0xe0, 0xfb, 0xf2, 0xcd, 0xc4, 0xdf, 0xd6,
      0x31, 0x38, 0x23, 0x2a, 0x15, 0x1c, 0x07, 0x0e, 0x79, 0x70, 0x6b, 0x62,
      0x5d, 0x54, 0x4f, 0x46,
    ],
    GBX = [
      0x00, 0x0b, 0x16, 0x1d, 0x2c, 0x27, 0x3a, 0x31, 0x58, 0x53, 0x4e, 0x45,
      0x74, 0x7f, 0x62, 0x69, 0xb0, 0xbb, 0xa6, 0xad, 0x9c, 0x97, 0x8a, 0x81,
      0xe8, 0xe3, 0xfe, 0xf5, 0xc4, 0xcf, 0xd2, 0xd9, 0x7b, 0x70, 0x6d, 0x66,
      0x57, 0x5c, 0x41, 0x4a, 0x23, 0x28, 0x35, 0x3e, 0x0f, 0x04, 0x19, 0x12,
      0xcb, 0xc0, 0xdd, 0xd6, 0xe7, 0xec, 0xf1, 0xfa, 0x93, 0x98, 0x85, 0x8e,
      0xbf, 0xb4, 0xa9, 0xa2, 0xf6, 0xfd, 0xe0, 0xeb, 0xda, 0xd1, 0xcc, 0xc7,
      0xae, 0xa5, 0xb8, 0xb3, 0x82, 0x89, 0x94, 0x9f, 0x46, 0x4d, 0x50, 0x5b,
      0x6a, 0x61, 0x7c, 0x77, 0x1e, 0x15, 0x08, 0x03, 0x32, 0x39, 0x24, 0x2f,
      0x8d, 0x86, 0x9b, 0x90, 0xa1, 0xaa, 0xb7, 0xbc, 0xd5, 0xde, 0xc3, 0xc8,
      0xf9, 0xf2, 0xef, 0xe4, 0x3d, 0x36, 0x2b, 0x20, 0x11, 0x1a, 0x07, 0x0c,
      0x65, 0x6e, 0x73, 0x78, 0x49, 0x42, 0x5f, 0x54, 0xf7, 0xfc, 0xe1, 0xea,
      0xdb, 0xd0, 0xcd, 0xc6, 0xaf, 0xa4, 0xb9, 0xb2, 0x83, 0x88, 0x95, 0x9e,
      0x47, 0x4c, 0x51, 0x5a, 0x6b, 0x60, 0x7d, 0x76, 0x1f, 0x14, 0x09, 0x02,
      0x33, 0x38, 0x25, 0x2e, 0x8c, 0x87, 0x9a, 0x91, 0xa0, 0xab, 0xb6, 0xbd,
      0xd4, 0xdf, 0xc2, 0xc9, 0xf8, 0xf3, 0xee, 0xe5, 0x3c, 0x37, 0x2a, 0x21,
      0x10, 0x1b, 0x06, 0x0d, 0x64, 0x6f, 0x72, 0x79, 0x48, 0x43, 0x5e, 0x55,
      0x01, 0x0a, 0x17, 0x1c, 0x2d, 0x26, 0x3b, 0x30, 0x59, 0x52, 0x4f, 0x44,
      0x75, 0x7e, 0x63, 0x68, 0xb1, 0xba, 0xa7, 0xac, 0x9d, 0x96, 0x8b, 0x80,
      0xe9, 0xe2, 0xff, 0xf4, 0xc5, 0xce, 0xd3, 0xd8, 0x7a, 0x71, 0x6c, 0x67,
      0x56, 0x5d, 0x40, 0x4b, 0x22, 0x29, 0x34, 0x3f, 0x0e, 0x05, 0x18, 0x13,
      0xca, 0xc1, 0xdc, 0xd7, 0xe6, 0xed, 0xf0, 0xfb, 0x92, 0x99, 0x84, 0x8f,
      0xbe, 0xb5, 0xa8, 0xa3,
    ],
    GDX = [
      0x00, 0x0d, 0x1a, 0x17, 0x34, 0x39, 0x2e, 0x23, 0x68, 0x65, 0x72, 0x7f,
      0x5c, 0x51, 0x46, 0x4b, 0xd0, 0xdd, 0xca, 0xc7, 0xe4, 0xe9, 0xfe, 0xf3,
      0xb8, 0xb5, 0xa2, 0xaf, 0x8c, 0x81, 0x96, 0x9b, 0xbb, 0xb6, 0xa1, 0xac,
      0x8f, 0x82, 0x95, 0x98, 0xd3, 0xde, 0xc9, 0xc4, 0xe7, 0xea, 0xfd, 0xf0,
      0x6b, 0x66, 0x71, 0x7c, 0x5f, 0x52, 0x45, 0x48, 0x03, 0x0e, 0x19, 0x14,
      0x37, 0x3a, 0x2d, 0x20, 0x6d, 0x60, 0x77, 0x7a, 0x59, 0x54, 0x43, 0x4e,
      0x05, 0x08, 0x1f, 0x12, 0x31, 0x3c, 0x2b, 0x26, 0xbd, 0xb0, 0xa7, 0xaa,
      0x89, 0x84, 0x93, 0x9e, 0xd5, 0xd8, 0xcf, 0xc2, 0xe1, 0xec, 0xfb, 0xf6,
      0xd6, 0xdb, 0xcc, 0xc1, 0xe2, 0xef, 0xf8, 0xf5, 0xbe, 0xb3, 0xa4, 0xa9,
      0x8a, 0x87, 0x90, 0x9d, 0x06, 0x0b, 0x1c, 0x11, 0x32, 0x3f, 0x28, 0x25,
      0x6e, 0x63, 0x74, 0x79, 0x5a, 0x57, 0x40, 0x4d, 0xda, 0xd7, 0xc0, 0xcd,
      0xee, 0xe3, 0xf4, 0xf9, 0xb2, 0xbf, 0xa8, 0xa5, 0x86, 0x8b, 0x9c, 0x91,
      0x0a, 0x07, 0x10, 0x1d, 0x3e, 0x33, 0x24, 0x29, 0x62, 0x6f, 0x78, 0x75,
      0x56, 0x5b, 0x4c, 0x41, 0x61, 0x6c, 0x7b, 0x76, 0x55, 0x58, 0x4f, 0x42,
      0x09, 0x04, 0x13, 0x1e, 0x3d, 0x30, 0x27, 0x2a, 0xb1, 0xbc, 0xab, 0xa6,
      0x85, 0x88, 0x9f, 0x92, 0xd9, 0xd4, 0xc3, 0xce, 0xed, 0xe0, 0xf7, 0xfa,
      0xb7, 0xba, 0xad, 0xa0, 0x83, 0x8e, 0x99, 0x94, 0xdf, 0xd2, 0xc5, 0xc8,
      0xeb, 0xe6, 0xf1, 0xfc, 0x67, 0x6a, 0x7d, 0x70, 0x53, 0x5e, 0x49, 0x44,
      0x0f, 0x02, 0x15, 0x18, 0x3b, 0x36, 0x21, 0x2c, 0x0c, 0x01, 0x16, 0x1b,
      0x38, 0x35, 0x22, 0x2f, 0x64, 0x69, 0x7e, 0x73, 0x50, 0x5d, 0x4a, 0x47,
      0xdc, 0xd1, 0xc6, 0xcb, 0xe8, 0xe5, 0xf2, 0xff, 0xb4, 0xb9, 0xae, 0xa3,
      0x80, 0x8d, 0x9a, 0x97,
    ],
    GEX = [
      0x00, 0x0e, 0x1c, 0x12, 0x38, 0x36, 0x24, 0x2a, 0x70, 0x7e, 0x6c, 0x62,
      0x48, 0x46, 0x54, 0x5a, 0xe0, 0xee, 0xfc, 0xf2, 0xd8, 0xd6, 0xc4, 0xca,
      0x90, 0x9e, 0x8c, 0x82, 0xa8, 0xa6, 0xb4, 0xba, 0xdb, 0xd5, 0xc7, 0xc9,
      0xe3, 0xed, 0xff, 0xf1, 0xab, 0xa5, 0xb7, 0xb9, 0x93, 0x9d, 0x8f, 0x81,
      0x3b, 0x35, 0x27, 0x29, 0x03, 0x0d, 0x1f, 0x11, 0x4b, 0x45, 0x57, 0x59,
      0x73, 0x7d, 0x6f, 0x61, 0xad, 0xa3, 0xb1, 0xbf, 0x95, 0x9b, 0x89, 0x87,
      0xdd, 0xd3, 0xc1, 0xcf, 0xe5, 0xeb, 0xf9, 0xf7, 0x4d, 0x43, 0x51, 0x5f,
      0x75, 0x7b, 0x69, 0x67, 0x3d, 0x33, 0x21, 0x2f, 0x05, 0x0b, 0x19, 0x17,
      0x76, 0x78, 0x6a, 0x64, 0x4e, 0x40, 0x52, 0x5c, 0x06, 0x08, 0x1a, 0x14,
      0x3e, 0x30, 0x22, 0x2c, 0x96, 0x98, 0x8a, 0x84, 0xae, 0xa0, 0xb2, 0xbc,
      0xe6, 0xe8, 0xfa, 0xf4, 0xde, 0xd0, 0xc2, 0xcc, 0x41, 0x4f, 0x5d, 0x53,
      0x79, 0x77, 0x65, 0x6b, 0x31, 0x3f, 0x2d, 0x23, 0x09, 0x07, 0x15, 0x1b,
      0xa1, 0xaf, 0xbd, 0xb3, 0x99, 0x97, 0x85, 0x8b, 0xd1, 0xdf, 0xcd, 0xc3,
      0xe9, 0xe7, 0xf5, 0xfb, 0x9a, 0x94, 0x86, 0x88, 0xa2, 0xac, 0xbe, 0xb0,
      0xea, 0xe4, 0xf6, 0xf8, 0xd2, 0xdc, 0xce, 0xc0, 0x7a, 0x74, 0x66, 0x68,
      0x42, 0x4c, 0x5e, 0x50, 0x0a, 0x04, 0x16, 0x18, 0x32, 0x3c, 0x2e, 0x20,
      0xec, 0xe2, 0xf0, 0xfe, 0xd4, 0xda, 0xc8, 0xc6, 0x9c, 0x92, 0x80, 0x8e,
      0xa4, 0xaa, 0xb8, 0xb6, 0x0c, 0x02, 0x10, 0x1e, 0x34, 0x3a, 0x28, 0x26,
      0x7c, 0x72, 0x60, 0x6e, 0x44, 0x4a, 0x58, 0x56, 0x37, 0x39, 0x2b, 0x25,
      0x0f, 0x01, 0x13, 0x1d, 0x47, 0x49, 0x5b, 0x55, 0x7f, 0x71, 0x63, 0x6d,
      0xd7, 0xd9, 0xcb, 0xc5, 0xef, 0xe1, 0xf3, 0xfd, 0xa7, 0xa9, 0xbb, 0xb5,
      0x9f, 0x91, 0x83, 0x8d,
    ],
    enc = function (string, pass, binary) {
      // string, password in plaintext
      var salt = randArr(8),
        pbe = openSSLKey(s2a(pass, binary), salt),
        key = pbe.key,
        iv = pbe.iv,
        cipherBlocks,
        saltBlock = [[83, 97, 108, 116, 101, 100, 95, 95].concat(salt)];
      string = s2a(string, binary);
      cipherBlocks = rawEncrypt(string, key, iv);
      // Spells out 'Salted__'
      cipherBlocks = saltBlock.concat(cipherBlocks);
      return Base64.encode(cipherBlocks);
    },
    dec = function (string, pass, binary) {
      // string, password in plaintext
      var cryptArr = Base64.decode(string),
        salt = cryptArr.slice(8, 16),
        pbe = openSSLKey(s2a(pass, binary), salt),
        key = pbe.key,
        iv = pbe.iv;
      cryptArr = cryptArr.slice(16, cryptArr.length);
      // Take off the Salted__ffeeddcc
      string = rawDecrypt(cryptArr, key, iv, binary);
      return string;
    },
    MD5 = function (numArr) {
      function rotateLeft(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
      }

      function addUnsigned(lX, lY) {
        var lX4, lY4, lX8, lY8, lResult;
        lX8 = lX & 0x80000000;
        lY8 = lY & 0x80000000;
        lX4 = lX & 0x40000000;
        lY4 = lY & 0x40000000;
        lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);
        if (lX4 & lY4) {
          return lResult ^ 0x80000000 ^ lX8 ^ lY8;
        }
        if (lX4 | lY4) {
          if (lResult & 0x40000000) {
            return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
          } else {
            return lResult ^ 0x40000000 ^ lX8 ^ lY8;
          }
        } else {
          return lResult ^ lX8 ^ lY8;
        }
      }

      function f(x, y, z) {
        return (x & y) | (~x & z);
      }
      function g(x, y, z) {
        return (x & z) | (y & ~z);
      }
      function h(x, y, z) {
        return x ^ y ^ z;
      }
      function funcI(x, y, z) {
        return y ^ (x | ~z);
      }

      function ff(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(f(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function gg(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(g(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function hh(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(h(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function ii(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(funcI(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function convertToWordArray(numArr) {
        var lWordCount,
          lMessageLength = numArr.length,
          lNumberOfWords_temp1 = lMessageLength + 8,
          lNumberOfWords_temp2 =
            (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64,
          lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16,
          lWordArray = [],
          lBytePosition = 0,
          lByteCount = 0;
        while (lByteCount < lMessageLength) {
          lWordCount = (lByteCount - (lByteCount % 4)) / 4;
          lBytePosition = (lByteCount % 4) * 8;
          lWordArray[lWordCount] =
            lWordArray[lWordCount] | (numArr[lByteCount] << lBytePosition);
          lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] =
          lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
      }

      function wordToHex(lValue) {
        var lByte,
          lCount,
          wordToHexArr = [];
        for (lCount = 0; lCount <= 3; lCount++) {
          lByte = (lValue >>> (lCount * 8)) & 255;
          wordToHexArr = wordToHexArr.concat(lByte);
        }
        return wordToHexArr;
      }

      /*function utf8Encode(string) {
            string = string.replace(/\r\n/g, "\n");
            var utftext = "",
            n,
            c;

            for (n = 0; n < string.length; n++) {

                c = string.charCodeAt(n);

                if (c < 128) {
                    utftext += String.fromCharCode(c);
                }
                else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }

            }

            return utftext;
        }*/

      var x = [],
        k,
        AA,
        BB,
        CC,
        DD,
        a,
        b,
        c,
        d,
        S11 = 7,
        S12 = 12,
        S13 = 17,
        S14 = 22,
        S21 = 5,
        S22 = 9,
        S23 = 14,
        S24 = 20,
        S31 = 4,
        S32 = 11,
        S33 = 16,
        S34 = 23,
        S41 = 6,
        S42 = 10,
        S43 = 15,
        S44 = 21;

      x = convertToWordArray(numArr);

      a = 0x67452301;
      b = 0xefcdab89;
      c = 0x98badcfe;
      d = 0x10325476;

      for (k = 0; k < x.length; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = ff(a, b, c, d, x[k + 0], S11, 0xd76aa478);
        d = ff(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
        c = ff(c, d, a, b, x[k + 2], S13, 0x242070db);
        b = ff(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
        a = ff(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
        d = ff(d, a, b, c, x[k + 5], S12, 0x4787c62a);
        c = ff(c, d, a, b, x[k + 6], S13, 0xa8304613);
        b = ff(b, c, d, a, x[k + 7], S14, 0xfd469501);
        a = ff(a, b, c, d, x[k + 8], S11, 0x698098d8);
        d = ff(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
        c = ff(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
        b = ff(b, c, d, a, x[k + 11], S14, 0x895cd7be);
        a = ff(a, b, c, d, x[k + 12], S11, 0x6b901122);
        d = ff(d, a, b, c, x[k + 13], S12, 0xfd987193);
        c = ff(c, d, a, b, x[k + 14], S13, 0xa679438e);
        b = ff(b, c, d, a, x[k + 15], S14, 0x49b40821);
        a = gg(a, b, c, d, x[k + 1], S21, 0xf61e2562);
        d = gg(d, a, b, c, x[k + 6], S22, 0xc040b340);
        c = gg(c, d, a, b, x[k + 11], S23, 0x265e5a51);
        b = gg(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
        a = gg(a, b, c, d, x[k + 5], S21, 0xd62f105d);
        d = gg(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = gg(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
        b = gg(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
        a = gg(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
        d = gg(d, a, b, c, x[k + 14], S22, 0xc33707d6);
        c = gg(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
        b = gg(b, c, d, a, x[k + 8], S24, 0x455a14ed);
        a = gg(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
        d = gg(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
        c = gg(c, d, a, b, x[k + 7], S23, 0x676f02d9);
        b = gg(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
        a = hh(a, b, c, d, x[k + 5], S31, 0xfffa3942);
        d = hh(d, a, b, c, x[k + 8], S32, 0x8771f681);
        c = hh(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
        b = hh(b, c, d, a, x[k + 14], S34, 0xfde5380c);
        a = hh(a, b, c, d, x[k + 1], S31, 0xa4beea44);
        d = hh(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
        c = hh(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
        b = hh(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
        a = hh(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
        d = hh(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
        c = hh(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
        b = hh(b, c, d, a, x[k + 6], S34, 0x4881d05);
        a = hh(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
        d = hh(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
        c = hh(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
        b = hh(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
        a = ii(a, b, c, d, x[k + 0], S41, 0xf4292244);
        d = ii(d, a, b, c, x[k + 7], S42, 0x432aff97);
        c = ii(c, d, a, b, x[k + 14], S43, 0xab9423a7);
        b = ii(b, c, d, a, x[k + 5], S44, 0xfc93a039);
        a = ii(a, b, c, d, x[k + 12], S41, 0x655b59c3);
        d = ii(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
        c = ii(c, d, a, b, x[k + 10], S43, 0xffeff47d);
        b = ii(b, c, d, a, x[k + 1], S44, 0x85845dd1);
        a = ii(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
        d = ii(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
        c = ii(c, d, a, b, x[k + 6], S43, 0xa3014314);
        b = ii(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
        a = ii(a, b, c, d, x[k + 4], S41, 0xf7537e82);
        d = ii(d, a, b, c, x[k + 11], S42, 0xbd3af235);
        c = ii(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
        b = ii(b, c, d, a, x[k + 9], S44, 0xeb86d391);
        a = addUnsigned(a, AA);
        b = addUnsigned(b, BB);
        c = addUnsigned(c, CC);
        d = addUnsigned(d, DD);
      }

      return wordToHex(a).concat(wordToHex(b), wordToHex(c), wordToHex(d));
    },
    Base64 = (function () {
      var totalChunks;
      // Takes a Nx16x1 byte array and converts it to Base64
      var _chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
        chars = _chars.split(""),
        encode = function (b, withBreaks) {
          var flatArr = [],
            b64 = "",
            i,
            broken_b64;
          totalChunks = Math.floor((b.length * 16) / 3);
          for (i = 0; i < b.length * 16; i++) {
            flatArr.push(b[Math.floor(i / 16)][i % 16]);
          }
          for (i = 0; i < flatArr.length; i = i + 3) {
            b64 += chars[flatArr[i] >> 2];
            b64 += chars[((flatArr[i] & 3) << 4) | (flatArr[i + 1] >> 4)];
            if (!(flatArr[i + 1] === undefined)) {
              b64 +=
                chars[((flatArr[i + 1] & 15) << 2) | (flatArr[i + 2] >> 6)];
            } else {
              b64 += "=";
            }
            if (!(flatArr[i + 2] === undefined)) {
              b64 += chars[flatArr[i + 2] & 63];
            } else {
              b64 += "=";
            }
          }
          // OpenSSL is super particular about line breaks
          broken_b64 = b64.slice(0, 64) + "\n";
          for (i = 1; i < Math.ceil(b64.length / 64); i++) {
            broken_b64 +=
              b64.slice(i * 64, i * 64 + 64) +
              (Math.ceil(b64.length / 64) == i + 1 ? "" : "\n");
          }
          return broken_b64;
        },
        decode = function (string) {
          string = string.replace(/\n/g, "");
          var flatArr = [],
            c = [],
            b = [],
            i;
          for (i = 0; i < string.length; i = i + 4) {
            c[0] = _chars.indexOf(string.charAt(i));
            c[1] = _chars.indexOf(string.charAt(i + 1));
            c[2] = _chars.indexOf(string.charAt(i + 2));
            c[3] = _chars.indexOf(string.charAt(i + 3));

            b[0] = (c[0] << 2) | (c[1] >> 4);
            b[1] = ((c[1] & 15) << 4) | (c[2] >> 2);
            b[2] = ((c[2] & 3) << 6) | c[3];
            flatArr.push(b[0], b[1], b[2]);
          }
          flatArr = flatArr.slice(0, flatArr.length - (flatArr.length % 16));
          return flatArr;
        };

      //internet explorer
      if (typeof Array.indexOf === "function") {
        _chars = chars;
      }

      /*
        //other way to solve internet explorer problem
        if(!Array.indexOf){
            Array.prototype.indexOf = function(obj){
                for(var i=0; i<this.length; i++){
                    if(this[i]===obj){
                        return i;
                    }
                }
                return -1;
            }
        }
        */

      return {
        encode: encode,
        decode: decode,
      };
    })();

  return {
    size: size,
    h2a: h2a,
    expandKey: expandKey,
    encryptBlock: encryptBlock,
    decryptBlock: decryptBlock,
    Decrypt: Decrypt,
    s2a: s2a,
    rawEncrypt: rawEncrypt,
    aesEncrypt: aesEncrypt,
    aesDecrypt: aesDecrypt,
    dec: dec,
    openSSLKey: openSSLKey,
    a2h: a2h,
    enc: enc,
    Hash: { MD5: MD5 },
    Base64: Base64,
  };
})();
</script>

<style></style>
