import api from "@/store/apiUtil";
import axios from "axios";

const authStore = {
  namespaced: true,
  state: () => ({
    chatMode: false,
    check_pwd_upd: false,
    token: "", //token
    login_result: null,
    login_data: null,
    jsessionid:"",      //Arthur.Kim 2022.04.29 세션ID
    //jsessionid:"",      //Arthur.Kim 2022.04.29 세션ID
  }),
  getters: {
    GE_CHAT_MODE: (state) => state.chatMode,
    GE_CHECK_PWD_UPD: (state) => state.check_pwd_upd,
    GE_AUTH_TOKEN: (state) => state.token,
    GE_AUTH_LOGIN_RESULT: (state) => state.login_result,
    GE_AUTH_LOGIN_DATA: (state) => state.login_data,
    GE_AUTH_JSESSIONID: (state) => state.jsessionid,    //Arthur.Kim 2022.04.29 세션ID
    //GE_AUTH_JSESSIONID: (state) => state.jsessionid,    //Arthur.Kim 2022.04.29 세션ID
  },
  mutations: {
    MU_CHAT_MODE: (state, data) =>{
      state.chatMode = data;
    },    
    MU_AUTH_TOKEN: (state, data) => {
      // console.log("###########################################################");
      // console.log("### [authStore][mutations][MU_AUTH_TOKEN]");
      // console.log("### data=",data);
      // console.log("###########################################################");
      /*
                여기서는 payload를 객체로 받습니다.
                payload를 객체로 받으면, mutation를 조금더 유연하게 사용할 수 있기는 합니다.
            */
      state.token = data.access_token;
    },
    MU_AUTH_LOGIN_RESULT: (state, data) => {
      state.login_result = data.code;
      state.login_data = data.data;
    },
    MU_CHECK_PWD_UPD: (state, data) => {
      state.check_pwd_upd = data;
    },    
    
    MU_AUTH_LOGOUT_RESULT: (state, data) => {
      state.login_result = data.code;
    },
  },
  actions: {
    //로그인
    async AC_AUTH_LOGIN({ commit }, data) {
      //임시 테스트 데이터
      let set_data = {
        code: 0,
        data: data,
      };

      let response_data;

      await api
        .post("api/login", {
          username: data.username,
          password: data.userpassword,
          relogin: data.relogin || undefined // 기존 로그인을 끊고 다시 로그인하는지 여부
        })
        .then((response) => {
          if (response.data.HEADER.ERROR_FLAG === false) {
            set_data.code = 1;
            set_data.data = response.data.DATA[0];
          }

          if(response.data.HEADER.CHECK_PWD_UPD === false){
            commit("MU_CHECK_PWD_UPD", true);
          }
          commit("MU_CHAT_MODE", false);


          response_data = response;

          // 로그아웃 시 세션정보를 삭제하기 위해
          if (typeof response.data.HEADER.JSESSIONID != 'undefined' ) {
            const jsessionid = response.data.HEADER.JSESSIONID; // sessionid을 header에서 받은 경우
          
            if (jsessionid) {
              window.sessionStorage.setItem("jsessionid", jsessionid);
            }
          }

          // 비밀번호 만료이거나 이미 로그인되어 있거나 현재 클라이언트의 IP에 로그인된 아이디가 존재하면 로그인 결과 변경하지 않음
          if (response.error.flag === true && [ "Expired", "LOGGED_IN_ID", "LOGGED_IN_IP" ].includes(response.error.msg)) {
            set_data = {
              code: null,
              data: null,
            };
          }

          //로그인 성공/실패 처리
          commit("MU_AUTH_LOGIN_RESULT", set_data);
        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("login.error", error);

          set_data.code = 0;
          //로그인에러 처리
          commit("MU_AUTH_LOGIN_RESULT", set_data);
        });

      return response_data;
    },
    //로그아웃
    async AC_AUTH_LOGOUT({ commit }, data) {
      let set_data = {
        code: null,
      };

      commit("MU_CHECK_PWD_UPD", false);
      commit("MU_CHAT_MODE", false);

      let response_data;
      
      await api
        .post("api/logout", {
          ACCESS_TOKEN: sessionStorage.getItem("token"),
          JSESSIONID: sessionStorage.getItem("jsessionid")
        })
        .then((response) => {
          //로그아웃 처리
          commit("MU_AUTH_LOGOUT_RESULT", set_data);
          window.sessionStorage.clear();

          response_data = response;

        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("logout.error", error);
          
          set_data.code = -1;
          //로그인에러 처리
          commit("MU_AUTH_LOGIN_RESULT", set_data);
        });

      return response_data;
    },
  },
};

export default authStore;
