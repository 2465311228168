<template>
  <!-- <div class="is-layout-right" v-click-outside="documentClick"> -->
  <div class="is-layout-right">
    <v-navigation-drawer
      absolute
      permanent
      right
      width="60px"
      class="no-border"
    >
      <ul class="jh-quick-list is-color">        
        <li
          v-for="(item, index) in menu01"
          :key="item.index"
          link
          :ripple="false"
          :class="{'is-active' : selectedMenu === item }"
          @click.stop="btnActive($event, item)"
        >
          <p>
            <i :class="[item.class]"></i>     
            <span>{{item.title}}</span>
          </p>     
        </li>
      </ul>
      <div class="jh-quick-divider"></div>
      <ul class="jh-quick-list">        
        <li
          v-for="(item, index) in menu02"
          :key="item.index"
          link
          :ripple="false"
          :class="{'is-active' : selectedMenu === item }"
          @click.stop="btnActive($event, item)"
        >
          <p><i :class="[item.class]"></i></p>          
          <span>{{item.title}}</span>
        </li>
      </ul>
    </v-navigation-drawer>

    <slide1-modal :evt="evt" v-show="slide1" />
    <slide2-modal :evt="evt" v-show="slide2" />
    <slide3-modal :evt="evt" v-show="slide3" />
    <slide4-modal :evt="evt" v-show="slide4" />
    <slide5-modal :evt="evt" v-show="slide5" />
    <slide6-modal :evt="evt" v-show="slide6" />
    <slide7-modal :evt="evt" v-show="slide7" />
    <slide8-modal :evt="evt" v-show="slide8" />
    <slide9-modal :evt="evt" v-show="slide9" />
    <slide10-modal :evt="evt" v-show="slide10" /> 

  </div>
</template>

<script>
import moment from "moment";
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";

import Vue from "vue";
import { mapGetters } from "vuex";

import slide1Modal from "../../views/page/T100601";        // 2차상담
import slide2Modal from "../../views/page/T100701";        // ARS예약
import slide3Modal from "../../views/page/T100801";        // MY예약
import slide4Modal from "../../views/page/T100201";        // 유상청약
import slide5Modal from "../../views/page/T100301";        // 반대의사
import slide6Modal from "../../views/page/T100401";        // 매수청구
import slide7Modal from "../../views/page/T100501";        // TM
import slide8Modal from "../../views/page/T100101";        // 지점정보
import slide9Modal from "../../views/page/T100901";        // 지식함
import slide10Modal from "../../views/page/T101001";       // 메모

export default {
  mixins: [mixin],

  components: {    
    slide1Modal,
    slide2Modal,
    slide3Modal,
    slide4Modal,
    slide5Modal,
    slide6Modal,
    slide7Modal,
    slide8Modal,
    slide9Modal,
    slide10Modal,
  },
  data() {
    return {
      evt: new Vue,
      selectedMenu: '',
      slide1: false,
      slide2: false,
      slide3: false,
      slide4: false,
      slide5: false,
      slide6: false,
      slide7: false,
      slide8: false,
      slide9: false,
      slide10: false,

      menu01: [
        {
          id: "menu0101",
          title: "2차상담",
          class: "jh-icon-quick-cnsl",
          evt: "slide1",
          active: false,
        },
        {
          id: "menu0102",
          title: "MY예약",
          class: "jh-icon-quick-rsv",
          evt: "slide3",
          active: false,
        },
        {
          id: "menu0103",
          title: "ARS예약",
          class: "jh-icon-quick-ars",
          evt: "slide2",
          active: false,
        },
      ],
      menu02: [
        {
          id: "menu0201",
          title: "유상청약",
          class: "jh-icon-quick-subs",
          evt: "slide4",
          active: false,
        },
        {
          id: "menu0202",
          title: "반대의사",
          class: "jh-icon-quick-no",
          evt: "slide5",
          active: false,
        },
        {
          id: "menu0203",
          title: "매수청구",
          class: "jh-icon-quick-buy",
          evt: "slide6",
          active: false,
        },
        {
          id: "menu0204",
          title: "TM",
          class: "jh-icon-quick-tm",
          evt: "slide7",
          active: false,
        },
        {
          id: "menu0205",
          title: "지점정보",
          class: "jh-icon-quick-office",
          evt: "slide8",
          active: false,
        },
        {
          id: "menu0206",
          title: "지식함",
          class: "jh-icon-quick-kms",
          evt: "slide9",
          active: false,
        },
        {
          id: "menu0207",
          title: "메모",
          class: "jh-icon-quick-memo",
          evt: "slide10",
          active: false,
        },
      ],
    };
  },
  methods: {
    btnActive(e, item) {
      // 패널 변경 처리
      this.showSlide(item);
      if (!this.selectedMenu) {
        this.selectedMenu = item;
        if(!document.body.classList.contains("slide-on")) document.body.classList.toggle("slide-on");
      }
      else if (this.selectedMenu === item){
        this.selectedMenu = '';
        if(document.body.classList.contains("slide-on")) document.body.classList.toggle("slide-on");
      }
      else {
        this.selectedMenu = item;
      }

    },

    showSlide(item) {
      if (this.selectedMenu) {
        if (this[`${this.selectedMenu.evt}`]) this.evt.$emit(`close-${this.selectedMenu.evt}`);
        if (this.selectedMenu.evt !== item.evt) this[`${this.selectedMenu.evt}`] = false;
      }
      if (!this[`${item.evt}`]) this.evt.$emit(`open-${item.evt}`);
      this[`${item.evt}`] = !this[`${item.evt}`];
    },
    hideSlideAll(){   
      this.silde1 = false;
      this.slide2 = false;
      this.slide3 = false;
      this.slide4 = false;
      this.slide5 = false;
      this.slide6 = false;
      this.slide7 = false;
      this.slide8 = false;
      this.slide9 = false;
      this.slide10 = false;
    },
    closeModal(modal) {
      // this[`${modal}`] = false;
      console.log({ evt: modal });
      this.showSlide({ evt: modal });
      this.selectedMenu = '';
      if(document.body.classList.contains("slide-on")) document.body.classList.toggle("slide-on");
    },
    documentClick(e){
      let appEl = document.querySelector(".v-application--wrap");
      // let rightAreaEl = document.querySelector(".is-layout-right");
      let target = e.target;
      if(document.body.classList.contains("slide-on") && appEl.contains(target)) {
        this.showSlide(this.selectedMenu);
        this.selectedMenu = '';

        document.body.classList.toggle("slide-on");
      }
    }
  },
  // created: function () {
  //   setTimeout(() => {
  //     document.querySelector('.v-application--wrap').addEventListener('click', this.documentClick);
  //   }, 1000);
  // },
  // destroyed: function () {
  //   setTimeout(() => {
  //     document.querySelector('.v-application--wrap').removeEventListener('click', this.documentClick);
  //   }, 1000);
  // },
};
</script>

<style scoped>
</style>