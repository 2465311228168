<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      파일 업로드
    </h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class>
          <div>
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              v-on:vdropzone-success="uploadSuccess"
            ></vue-dropzone>
          </div>
          <v-btn @click="upload" color="primary ml-2">업로드</v-btn>
          <v-btn @click="cancle" color="btn-default ml-2">취소</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/store/apiUtil";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapGetters } from "vuex";

export default {
  name: "FileUploadPop",
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    fileInfo: {
      type: Object,
      value: {},
    },
  },
  data() {
    return {
      files: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: process.env.VUE_APP_API_URL + "api/file/chat/images/uploads",
        //url: "http://localhost:8443/" + "api/file/chat/images/uploads",
        maxThumbnailFilesize: 1,
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        thumbnailMethod: "crop",
        maxThumbnailFilesize:10000,
        maxFilesize: 1000,
        maxFiles: 1000,
        addRemoveLinks: true,
        clickable: true,
        dictRemoveFile: '삭제',
        dictCancelUpload: "취소",
        dictCancelUpload: "취소",
        //acceptedFiles: "[.png,.jpg,.gif]",
        acceptedFiles: ".png,.jpg,.gif,.pdf,.PNG,.JPG,.GIF",
        parallelUploads: 1,
        uploadMultiple: true,
        filesizeBase: 1000,

        paramName: "userfiles",
        /*
        paramName: function(e){
          return "userfiles"
        },
        */

        headers: { 
          'Authorization': "",
          
        },
        params: {
          'aspNewCustKey': this.fileInfo.ASP_CUST_KEY,
          'aspCustKey': this.fileInfo.ASP_CUST_KEY,
          'procId': this.$store.getters['userStore/GE_USER_ROLE'].userId,
          'acceptedFiles': this.fileInfo.accessFileTY,
          'uploadUri': this.fileInfo.upload, 
          'downloadUri': this.fileInfo.download,
          'fileUploadLib': "dropzone",
          'dir': this.fileInfo.dir,
          //'maxFilesize': 5,
          'tempDir': this.fileInfo.tempdir,
          'targetType': this.fileInfo.target,
        },

      },
    };
  },
  computed: {
    ...mapGetters({
      user_company: "userStore/GE_USER_COMPANY",
    }),
  },
  watch: {},
  created() {
  },
  mounted() {},
  methods: {
    upload(){
      let token = window.sessionStorage.getItem("token");
      this.dropzoneOptions.headers.Authorization = "bearer " + token;

      let get_files = this.$refs.myVueDropzone.getQueuedFiles();
      console.log("get_files" + JSON.stringify(get_files));

      console.log("get_files.length" + get_files.length);

      for (let i = 0;i<get_files.length;i++){
        this.files[i] = get_files[i].upload.filename;
      }

      console.log("file" + this.files);

      this.fileInfo.file = this.files;

      this.$refs.myVueDropzone.processQueue();
      
      this.$emit("selectFile", this.fileInfo);
    },

    cancle(){
      this.fileInfo.file = '';

      this.$emit("selectFile", this.fileInfo);
    },

    uploadSuccess(file, response) {
      this.fileInfo.result = response.data[0];
    }
  },
  watch: {
    fileInfo(){
      this.dropzoneOptions.maxFiles = this.fileInfo.multi;
      this.dropzoneOptions.maxFilesize = this.fileInfo.maxSize;
      var StringAccessFileTY = '';
      for(var i = 0; i < this.fileInfo.length; i++){
        if(i == 0){
          StringAccessFileTY = this.fileInfo.accessFileTY[i];
        } else {
          StringAccessFileTY = StringAccessFileTY + "," + this.fileInfo.accessFileTY[i];
        } 
      }
      this.dropzoneOptions.acceptedFiles = StringAccessFileTY;
      this.dropzoneOptions.uploadUri = this.fileInfo.upload;
      this.dropzoneOptions.downloadUri = this.fileInfo.download;
      this.dropzoneOptions.dir = this.fileInfo.dir;
      this.dropzoneOptions.tempDir = this.fileInfo.tempdir;
      this.dropzoneOptions.targetType = this.fileInfo.target;

      this.dropzoneOptions.params.aspNewCustKey = this.fileInfo.ASP_CUST_KEY;
      this.dropzoneOptions.params.aspCustKey = this.fileInfo.ASP_CUST_KEY;
    },
  },

  activated() {},
  deactivated() {},
  destroyed() {},
};
</script>

<style scoped></style>
