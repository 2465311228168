<template>
  <div class="jh-container is-home">
    <div class="is-home-header">
      <!-- <label class="jh-label">최근 업데이트</label>
      <span class="is-value is-txt-blue">{{ lastUpdateTime }}</span>
      <v-btn class="jh-btn is-icon" :loading="isLoading" @click="setTime();"><i class="jh-icon-refresh"></i></v-btn> -->
    </div>
    <div class="is-home-body">
      <!--왼쪽 컨텐츠-->
      <div>
        <div class="is-left-top">
          <div class="jh-border-box">
            <div class="is-inner is-user-info">
              <p class="is-name"> {{ user_name }}({{ user_id }})</p>
              <p>{{ CEN_TYPE_CD }}</p>
              <p>{{ atrtGroupNm }}</p>
              <p>{{ LastLoginDt }} 로그인</p>
              <v-btn class="jh-btn is-sm is-main" @click="dialogPassword=true">비밀번호변경</v-btn>
            </div>
          </div>
          <div class="jh-border-box">
            <div class="is-inner is-call">
              <ul class="jh-list-status">
                <li class="is-blue">
                  <label>계약건수</label>
                  <em>{{ total_cnt }}</em>
                  <span>건  </span>
                  <button type="button" to="/E999992" @click="activeTab('E999992','계약관리')"><i class="jh-icon-file is-blue"></i></button>

                </li>

                <li class="is-blue" style="width:200px">
                  <label>광고비</label>
                  <em>{{ ad_fee_tot }}</em>
                  <span>원  </span>
                </li>

                <li class="is-red" style="margin-left: 180px;">
                  <label>만료건수</label>
                  <em>{{ end_cnt }}</em>
                  <span>건  </span>
                  <button type="button" to="/E999994" @click="activeTab('E999994','갱신리스트관리')"><i class="jh-icon-file is-blue"></i></button>
                </li>                
              </ul>
            </div>
          </div>
        </div>
        <div class="is-left-body is-board" style="height: 600px; width: 100%;">
        <div class="jh-form-wrap" style="height: 600px;">
          <data-tables
            ref="dataTable"
            id="cnslHistTable"
            dataTableClass="has-control"
            :data-table-options="dataTableOptions"
            :paginationOptions="paginationOptions"
            
          >
          </data-tables>

        </div>
        </div>
        
      </div>
      <div class="jh-schedule-form">

          <div class="jh-card" style="height: 100%;">
            <div class="jh-card-header">
              <h1><i class="jh-icon-home-file"></i>공지사항</h1>
              <div class="is-right">
                <v-btn class="jh-btn is-sm is-trns" @click="setActiveTab2"><span>더보기</span><i class="jh-icon-plus-xs-bold"></i></v-btn>
              </div>
            </div>
            <div class="jh-card-body">
              <p class="jh-no-data" v-if="this.boardList2.length == 0"><span>현재 데이터가 없습니다.</span></p>
              <ul class="jh-list-board" v-if="this.boardList2.length > 0">
                <li v-for="(item, index) in boardList2" :key="index" @click="setAgent(item)">
                  <i class="jh-icon-new" v-if="item.BRD_TIT_NEW == 'Y'"></i>
                                    <p :title="item.title" :style="(item.BRD_ID == '60')? 'font-weight: bold;' : ''">{{ item.title }}</p>

                  <!-- 첨부파일 아이콘 보여주기 -->
                  <i class="jh-icon-clip" v-show="item.file === true"></i>
                  <span class="is-date">{{ item.date }}</span>
                </li>
              </ul>
            </div>
          </div>

      </div>
    </div>

    <!-- 공지사항, 자유게시판 상세 모달 -->
    <v-dialog max-width="800" v-if="dialogE050101P01" v-model="dialogE050101P01">
      <dialog-E050101P01
        headerTitle="상세 보기"
        @hide="hideDialog('E050101P01')"
        :agentInfo="agentInfo"
        @input="agentInfo = $event"
        @registAgent="setAgent()"
        :isinit="isInit"
      >
      </dialog-E050101P01>
    </v-dialog>

    <!-- 비밀번호 변경 모달 -->
    <v-dialog
      max-width="350"
      persistent
      content-class="jh-dialog-wrap"
      v-model="dialogPassword"
    >
      <div class="jh-dialog-header">
        <h1>비밀번호 변경</h1>
        <div class="is-right">
          <v-btn class="jh-btn is-icon" title="닫기" @click="closePassowrdDialog"><i class="jh-icon-close-lg is-white"></i></v-btn>
        </div>
      </div>
      <div class="jh-dialog-body">
        <dl class="jh-list-form">
          <dt><label class="">아이디</label></dt>
          <dd>
            <v-text-field class="jh-form is-lg" placeholder="아이템명" v-model="USER_ID" maxLength="18" disabled></v-text-field>                
          </dd>
          <dt><label class="is-required">기존 비밀번호</label></dt>
          <dd>
            <v-text-field type="password" class="jh-form is-lg" placeholder="기존 비밀번호" v-model="PASSWORD_OLD" maxLength="18"></v-text-field>  
          </dd>
          <dt><label class="is-required">새 비밀번호</label></dt>
          <dd>
            <v-text-field type="password" class="jh-form is-lg" placeholder="새 비밀번호" v-model="PASSWORD_NEW" maxLength="18"></v-text-field>  
          </dd>
          <dt><label class="is-required">새 비밀번호 재확인</label></dt>
          <dd>
            <v-text-field type="password" class="jh-form is-lg" placeholder="새 비밀번호 재확인" v-model="PASSWORD_NEW2" maxLength="18"></v-text-field>  
          </dd>
        </dl>
        
        <div class="jh-info-txt is-blue is-mt-10">영문, 숫자, 특수문자 혼합 8자리 이상</div>
        <div class="jh-info-txt is-blue is-mt-5" >특수문자는 ‘ !, @, #, $, & ’만 사용</div>
      </div>
      <div class="jh-dialog-footer">
        <v-btn class="jh-btn is-md" @click="closePassowrdDialog">취소</v-btn>
        <v-btn class="jh-btn is-md is-main" @click="checkPassword">비밀번호 변경</v-btn>
      </div>
    </v-dialog>
  </div>
</template>
  
<script>
import moment from "moment";
import api from "@/store/apiUtil";
import { mixin } from "@/mixin/mixin.js";
import DialogE050101P01 from "./E050101P01.vue";
import DialogE050101P02 from "./E050101P02.vue";
import { eventBus }  from "@/store/eventBus.js";
import { mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";
import dataTables from "@/components/DataTables";

const sha256 = require("sha256");
const crypto = require("crypto");
const worker = new Worker('/worker.js');

export default {
  name: "MENU_E999999", //name은 'MENU_' + 파일명 조합
  components: {
    DialogE050101P01,
    DialogE050101P02,
    dataTables

  },
  mixins: [mixin],
  data() {
    return {
      CHK_BL: false,
      NUMBER_CUSTOMER_REQUEST : '',
      NUMBER_COMPLETE : '',
      
      dialogE050101P01: false,
      dialogE050101P02: false,
      dialogPassword  : false,
      lastUpdateTime  : "2022-02-22 09:01:00",

      boardList1        : [],
      boardList2        : [],
      myCallList        : [],
      homeStatusList    : [],
      homeChatStatusList: [],


      agentInfo: {
        BRD_NO         : "",
        date           : "",
        title          : "",
        BRD_ID         : "",
        BRD_RMK        : "",
        FILE_GROUP_KEY : "",        
        ASP_NEWCUST_KEY: "001",
      },

      USER_ID       : "",
      ENCRYPT_KEY   : "",
      PASSWORD_OLD  : "",
      PASSWORD_NEW  : "",
      PASSWORD_NEW2 : "",
      userParam     : "",
      change        : "",

      CD_NM         : "",
      dropItemM     : "",

      ob_calls      : "",  
      total_calls   : "",  
      total_calltime: "",
      ib_calls      : "",

      arrayEvents  : [],
      USER_COMPANY : [],

      arrayEventDetail: null,
      
      isInit : false,
      initCNT: 0,

      CHK_CUST: {},

      loading  : false,

      isLoading: false,

      USER_ID : this.$store.getters["userStore/GE_USER_ROLE"].userId,
      HP_NO   : this.$store.getters["userStore/GE_USER_ROLE"].hpNo,
      B_DAY   : this.$store.getters["userStore/GE_USER_ROLE"].bday,
      atrtGroupId: this.$store.getters["userStore/GE_USER_ROLE"].atrtGroupId,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      calendar: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10), 
      nowDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
  
      alertMsg: {
        responseError: "시스템에 오류가 발생하였습니다.",
      },

            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '550px',
                items: undefined,
                itemKey: 'ROWNUM',
                itemsPerPage: 200,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                // showSelect: true,
                singleSelect: true,
                itemClass: "[item.selected && 'selected']",
                load: undefined,
            },      

      paginationOptions: {
          totalVisible: 10
      },      
      gridDataHeaders: [
        { text: "NO", value: "ROWNUM", align: "center", width: "80px", sortable: false, },
        { text: "광고인", value: "USER_NM", align: "center", width: "100px", },
        { text: "피보험자", value: "INSURED_PERSON", align: "center",  width: "100px", },
        { text: "계약자", value: "CONTRACTOR", align: "center", width: "100px", },
        { text: "가입방식",  value: "CONTRACT_TYP", align: "center", width: "100px", },
        { text: "보험사", value: "INSURE_CO", align: "center", width: "100px", },
        { text: "차량번호", value: "CAR_NUM", align: "center", width: "150px", },
        { text: "개시일", value: "START_DTTM", align: "center", width: "150px", },                                //체크
        { text: "만기일", value: "END_DTTM", align: "center", width: "150px", },                                  //체크
        { text: "보험료", value: "INSURE_FEE", align: "center", width: "150px", },
        { text: "현상태", value: "CONTRACT_STATUS", align: "center", width: "60px", },
      ],  

      ad_fee_tot: 0,
      end_cnt: 0,
      total_cnt: "",
   
    };
  },
  methods: {
    activeTab(NODE_NO, NODE_TITLE){
      this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
        id: NODE_NO,
        title: NODE_TITLE,
        default: "N",
        link: "/" + NODE_NO,
      });
    },



        dataTableSearch() {
            this.detailObj = {};
            this.dataTableOptions.load = this.selectContractList;

            if (this.dataTableOptions.page > 1) {
                this.dataTableOptions.page = 1;
            } else {
                this.dataTableInstance.loadData();
            }
        },


        async selectContractList(loadOptions) {
            // this.selectedRow = undefined;
            // if (this.selectedRow.length > 0) {
            //     this.selectedRow.forEach(item => {
            //         item.select(false);
            //     });
            // }

            let requestData = {
                headers: {
                    SERVICE: "easydirect.list",
                    METHOD: "",
                    TYPE: 'BIZ_SERVICE',
                    ROW_CNT: "200",
                    PAGES_CNT: "1",                    
                },
                sendData: {}
            };

            const sortBy = loadOptions?.sortBy?.[0];
            const sortDesc = loadOptions?.sortDesc?.[0] === undefined ? 'desc' : loadOptions?.sortDesc?.[0] ? 'desc' : 'asc';

            requestData.headers["URL"] = "/api/easydirect/list";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;
            // requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            // requestData.headers["PAGES_CNT"] = loadOptions.page; 
            if(this.CHK_BL == true){
              requestData.sendData["USER_NEWLIST"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
            }
            // requestData.sendData["SEARCH_FROM"] = this.dates[0];
            // requestData.sendData["SEARCH_TO"] = this.dates[1];
            // requestData.sendData["CEN_TYPE_CD"] = this.CEN_TYPE_CD;
            // requestData.sendData["COUNSELOR_NEWLIST"] = this.COUNSELOR_NEWLIST;
            // requestData.sendData["USER_NEWLIST"] = this.USER_NEWLIST;
            // requestData.sendData["SEARCH_NEWTY"] = this.SEARCH_NEWTY;
            // requestData.sendData["CONTRACT_NEWTYP"] = this.CONTRACT_NEWTYP;
            // requestData.sendData["INSURE_NEWCO"] = this.INSURE_NEWCO;
            // requestData.sendData["INSURE_NEWTYP"] = this.INSURE_NEWTYP;
            // requestData.sendData["CONTRACT_NEWSTATUS"] = this.CONTRACT_NEWSTATUS;
            // requestData.sendData["MAIN_SEARCH_NEWTY"] = this.MAIN_SEARCH_NEWTY;
            // requestData.sendData["MAIN_SEARCH_KEY"] = this.MAIN_SEARCH_KEY;
            requestData.sendData["SORT_ORDR"] = sortBy ? sortBy : 'ROWNUM';
            requestData.sendData["SORT_DRCT"] = sortDesc;
            requestData.sendData["MAIN"] = "MAIN";

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG ) {
                let header = response.HEADER;
                for(var i = 0; i < response.DATA.length; i ++){
                  if(response.DATA[i].INSURE_CO == "HD"){
                    response.DATA[i].INSURE_CO = "현대"
                  }else if(response.DATA[i].INSURE_CO == "DB"){
                    response.DATA[i].INSURE_CO = "DB"
                  }else if(response.DATA[i].INSURE_CO == "KB"){
                    response.DATA[i].INSURE_CO = "KB"
                  }else if(response.DATA[i].INSURE_CO == "HH"){
                    response.DATA[i].INSURE_CO = "한화"
                  }else if(response.DATA[i].INSURE_CO == "HK"){
                    response.DATA[i].INSURE_CO = "흥국"
                  }else if(response.DATA[i].INSURE_CO == "AXA"){
                    response.DATA[i].INSURE_CO = "AXA"
                  }else if(response.DATA[i].INSURE_CO == "CR"){
                    response.DATA[i].INSURE_CO = "캐롯"
                  }else if(response.DATA[i].INSURE_CO == "HN"){
                    response.DATA[i].INSURE_CO = "하나"
                  }else if(response.DATA[i].INSURE_CO == "MZ"){
                    response.DATA[i].INSURE_CO = "메리츠"
                  }else if(response.DATA[i].INSURE_CO == "SS"){
                    response.DATA[i].INSURE_CO = "삼성"
                  }else if(response.DATA[i].INSURE_CO == "LT"){
                    response.DATA[i].INSURE_CO = "롯데"
                  }else if(response.DATA[i].INSURE_CO == "MG"){
                    response.DATA[i].INSURE_CO = "MG"
                  }


                  if(response.DATA[i].CONTRACT_STATUS == "NORMAL"){
                    response.DATA[i].CONTRACT_STATUS = "정상"
                  }else if(response.DATA[i].CONTRACT_STATUS == "TERMINATION"){
                    response.DATA[i].CONTRACT_STATUS = "해지"
                  }


                  if(response.DATA[i].CONTRACT_TYP == "CM"){
                    response.DATA[i].CONTRACT_TYP = "CM"
                  }else if(response.DATA[i].CONTRACT_TYP == "TM"){
                    response.DATA[i].CONTRACT_TYP = "TM"
                  }else if(response.DATA[i].CONTRACT_TYP == "OFF"){
                    response.DATA[i].CONTRACT_TYP = "오프"
                  }else if(response.DATA[i].CONTRACT_TYP == "PUBLIC"){
                    response.DATA[i].CONTRACT_TYP = "공동"
                  }else if(response.DATA[i].CONTRACT_TYP == "SHORTTERM"){
                    response.DATA[i].CONTRACT_TYP = "단기"
                  }else if(response.DATA[i].CONTRACT_TYP == "DCM"){
                    response.DATA[i].CONTRACT_TYP = "CM타점"
                  }else if(response.DATA[i].CONTRACT_TYP == "DTM"){
                    response.DATA[i].CONTRACT_TYP = "TM타점"
                  }else if(response.DATA[i].CONTRACT_TYP == "TERMINATION"){
                    response.DATA[i].CONTRACT_TYP = "해지"
                  }
                                    
                  if(response.DATA[i].RENEWAL_STATUS == "COMPLETE"){
                    response.DATA[i].RENEWAL_STATUS = "갱신완료"
                  }else if(response.DATA[i].RENEWAL_STATUS == "UNCOMPLETE"){
                    response.DATA[i].RENEWAL_STATUS = "미가입"
                  }                  
                  else if(response.DATA[i].RENEWAL_STATUS == "UNESTIMATE"){
                    response.DATA[i].RENEWAL_STATUS = "미견적"
                  }                  
                  else if(response.DATA[i].RENEWAL_STATUS == "ESTIMATE"){
                    response.DATA[i].RENEWAL_STATUS = "견적"
                  }                                                    
                  
                  
                  response.DATA[i].INSURE_FEE = Number(response.DATA[i].INSURE_FEE).toLocaleString('ko-KR');
                  response.DATA[i].END_DTTM = response.DATA[i].END_DTTM.substr(0, 10)
                  response.DATA[i].START_DTTM = response.DATA[i].START_DTTM.substr(0, 10)                  

                  if(moment(response.DATA[i].END_DTTM).isBefore(moment().format('YYYY-MM-DD HH:mm:SS')) ){
                    this.end_cnt ++; 
                  }
                }

                if(response.HEADER.COUNT != 0){
                  this.ad_fee_tot = Number(response.DATA[0].ad_fee_tot).toLocaleString('ko-KR');
                  this.total_cnt = response.DATA.length
                }

                let data = response.DATA;

                return {
                    data: data,
                    totalCount: header.COUNT
                };
            }
            // if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                // let header = response.HEADER;
                // let data = response.DATA;
                // this.gridDataText = response.DATA
                // // this.dataTableOptions.items = this.gridDataText;
                // console.log(data)

                // data.forEach(item => {
                //     item.ACCOUNT = item.ACCOUNT.indexOf('-') < 0 ? 
                //         this.mixin_accNoFormat(item.ACCOUNT) : this.mixin_accNoFormat(item.ACCOUNT.replace(/\-/g, ''));
                //     item.RESV_TEL_NO = item.RESV_TEL_NO.indexOf('-') < 0 ?
                //         this.mixin_telNumFormat(item.RESV_TEL_NO) : item.RESV_TEL_NO;
                // });

                // return {
                //     data: data,
                //     totalCount: header.TOT_COUNT
                // };
            // }
        },    
    // 비밀번호 변경 팝업 닫기
    closePassowrdDialog() {
      this.PASSWORD_NEW   = "";
      this.PASSWORD_NEW2  = "";
      this.PASSWORD_OLD   = "";
      this.dialogPassword = false;
    },

    // 비밀번호 체크
    checkPassword() {     
      var pwNew = this.PASSWORD_NEW;

      var num = pwNew.search(/[0-9]/g);
      var eng = pwNew.search(/[a-z]/gi);
      // var spe = pwNew.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
            var spe = pwNew.search(/[`!@#$&|₩₩₩'₩";:₩/?]/gi);

      var HPNO = this.HP_NO.split("-");     // 핸드폰번호 ex) 010-1234-5678 -> HPNO[0]: 010 / HPNO[1]: 1234 / HPNO[2]: 5678
      var BDAY = "";                        // 생년월일   ex)        900101 -> 0101
      if(this.B_DAY.length != 6){
        BDAY = this.B_DAY;
      } else {
        BDAY = this.B_DAY.substr(2, 4);
      }

      if (this.PASSWORD_OLD == "" || this.PASSWORD_OLD == "undefined") {
        this.common_toast("비밀번호를 입력해주세요.", "error");
        return;
      } else if (this.PASSWORD_NEW != this.PASSWORD_NEW2) {
        this.common_toast("새 비밀번호와 비밀번호 확인이 일치하지 않습니다.", "error");
        return;
      } else if (pwNew.length < 8) {
        this.common_toast("8자리 이상 입력해주세요.", "error");
        return;
      } else if (pwNew.length > 20) {
        this.common_toast("20자리 이하로 입력해주세요.", "error");
        return;
      } else if (num < 0 || eng < 0 || spe < 0) {
        this.common_toast("영문, 숫자, 특수문자를 혼합하여 입력해주세요.", "error");
        return;
      } else if (pwNew.indexOf(this.USER_ID) > -1) {        
        this.common_toast("비밀번호는 사번을 포함할 수 없습니다.", "error");
        return;
      } else if ((this.HP_NO != null && this.HP_NO != "") && pwNew.indexOf(HPNO[1]) > -1 || pwNew.indexOf(HPNO[2]) > -1) {
        this.common_toast("비밀번호는 핸드폰번호를 포함할 수 없습니다.", "error");
        return;
      } else if ((this.B_DAY != null && this.B_DAY != "") && pwNew.indexOf(BDAY) > -1) {
        this.common_toast("비밀번호는 생년월일을 포함할 수 없습니다.", "error");
        return;
      } else if (pwNew.search(/\s/) != -1) {
        this.common_toast("비밀번호는 공백 없이 입력해주세요.", "error");
        return;
      } else {
        this.pwGetKey();
      }
    },

    // 암호화 키 호출
    pwGetKey() {
      api
      .post("api/login/password-encpt/process", {
        username: this.user_id,
      })
      .then((response) => {
        if (this.mixin_check_repsonse(response) === true) {
          if (response.data.DATA) {
            this.ENCRYPT_KEY = response.data.DATA[0].ENCRYPT_KEY;
            this.changePassword();
          } else {
            this.common_toast("비밀번호 변경을 실패하였습니다(암호화키 에러)", "error");
          }
        }
      })
      .catch((error) => {
        this.common_toast("비밀번호 변경을 실패하였습니다(암호화키 에러)", "error");
      });
    },

    // 비밀번호 변경 처리
    changePassword() {
      // 암호화 처리
      const password_OLD  = this.PASSWORD_OLD;
      const strPassWD_OLD = sha256(password_OLD);
      let encPassWD_OLD   = this.encryptAES_ECB(strPassWD_OLD, this.ENCRYPT_KEY); 

      const password_NEW  = this.PASSWORD_NEW;
      const strPassWD_NEW = sha256(password_NEW);
      let encPassWD_NEW   = this.encryptAES_ECB(strPassWD_NEW, this.ENCRYPT_KEY); 

      const CHK_OLD_FLAG  = "Y";
      const PWD_FAIL_FREQ = "0";
      const BIZ_DIV       = "CHANGEPWD";
      const PWD_STATUS    = "NORMAL";
      api
      .post(
        "api/login/password/modify",
        {
          CHK_OLD_FLAG : CHK_OLD_FLAG,
          USER_ID      : this.user_id,
          PWD          : password_OLD,
          PASSWORD_OLD : encPassWD_OLD,
          PASSWORD_NEW : encPassWD_NEW,
          PWD_FAIL_FREQ: PWD_FAIL_FREQ,
          BIZ_DIV      : BIZ_DIV,
          PROC_ID      : this.user_id,
          PWD_STATUS   : PWD_STATUS,
          ENCRYPT_KEY  : this.ENCRYPT_KEY,
        },
        {
          head: {
            SERVICE: "login/password",
            METHOD : "/modify",
            TYPE   : "BIZ_SERVICE",
          },
        }
      )
      .then((response) => {
        if (response.data.HEADER.ERROR_FLAG == true) {
          this.common_alert(
            "아이디 또는 비밀번호가 일치하지 않습니다.",
            "error"
          );
          return;
        } else {
          this.common_alert("정상적으로 수정되었습니다.");
          this.$store.commit("authStore/MU_CHECK_PWD_UPD", false);

          this.closePassowrdDialog();
        }
      })
      .catch((error) => {
        console.error("비밀번호 변경 에러", error);
      });
    },

    setActiveTab1() {
      this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST = this.CHK_CUST;
      this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.vue = 'M6101';
      let setId = "M6101";
      this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
        id     : "E050203",
        title  : "자유게시판",
        default: "N",
        tabIcon: "",
        link   : "/E050203",
      });
    },

    setActiveTab2() {
      this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST = this.CHK_CUST;
      this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.vue = 'M6102';
      let setId = "M6102";
      this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
        id     : "E050201",
        title  : "공지사항",
        default: "N",
        tabIcon: "",
        link   : "/E050201",
      });
    },

    // 모달 전송
    setAgent(item) {
      api
        .post(
          "/api/board/detail-popup/inqire",
          {
            BRD_ID: item.BRD_ID,
            BRD_NO: item.BRD_NO,
            ASP_NEWCUST_KEY: "001",
          },
          {
            head: {
              SERVICE: "api/board/detail-popup",
              METHOD : "/inqire",
              TYPE   : "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          let INFO = {
            date           : "",
            title          : "",
            BRD_ID         : "",
            BRD_NO         : "",
            BRD_RMK        : "",
            FILE_GROUP_KEY : "",
            ASP_NEWCUST_KEY: "",
          }
          INFO.BRD_RMK = this.restoreXSS(
            this.restoreXSS_CKeditor(response.data.DATA[0]["BRD_RMK"])
          );
          INFO.FILE_GROUP_KEY  = response.data.DATA[0]["FILE_GROUP_KEY"];
          INFO.BRD_NO          = item.BRD_NO;
          INFO.date            = item.date;
          INFO.BRD_ID          = item.BRD_ID;
          INFO.ASP_NEWCUST_KEY = "001";
          INFO.title           = item.title;

          this.agentInfo = INFO;

          this.showDialog('E050101P01');
        });
    },

    // 달력 클릭 체크
    checkDate() {
      this.arrayEvents = [];
      this.date = this.calendar;
      this.calendarClick();
    },

    // 달력 클릭시 스케줄 api 호출
    calendarClick() {
      const USER_ID         = this.USER_ID;
      const USER_DATE       = this.nowDate.replace(/\-/g, "");
      const FIRST_USER_DATE = USER_DATE.substr(0, 6) + "00"; // 월초 날짜 세팅
      const LAST_USER_DATE  = USER_DATE.substr(0, 6) + "99"; // 월말 날짜 세팅
      this.arrayEvents = [];
      api
        .post(
          "api/schedule/list",
          {
            USER_ID        : USER_ID,
            FIRST_USER_DATE: FIRST_USER_DATE,
            LAST_USER_DATE : LAST_USER_DATE,
          },
          {
            head: {
              SERVICE: "api/schedule",
              METHOD : "/list",
              TYPE   : "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          this.initCNT = 1;
          this.$store.getters['userStore/GE_USER_ROLE'].SCHEDULE = 0;
          for (let index = 0; index < response.data.DATA.length; index++) {
            var dataList = {};
            var arrCal   = [];
            var arrColor = [];
            dataList.id  = index;
            dataList.day = response.data.DATA[index]["USER_DATE"].replace(
              /([0-9]{4})([0-9]+)([0-9]{2})/,
              "$1-$2-$3"
            );
            dataList.titleLS = this.restoreXSS(response.data.DATA[index]["TITLE"]).replace(/\r\n/g, "\n");
            dataList.desc = this.restoreXSS(response.data.DATA[index]["CONTENT"]).replace(/\r\n/g, "\n");
            if (response.data.DATA[index]["SELECT_DAY"] == "기념일") {
              dataList.type  = "evt";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.color = "amber";
            } else {
              dataList.type  = "schedule";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.color = "cyan";
            }
            this.arrayEvents.push(dataList);
            this.arrayEventsCalendar(this.arrayEvents[index].day);
          }
        });
    },

    // 초기 스케줄 세팅
    FirstCalendarClick() {
      const USER_ID         = this.USER_ID;
      const USER_DATE       = this.nowDate.replace(/\-/g, "");
      const FIRST_USER_DATE = USER_DATE.substr(0, 6) + "00";
      const LAST_USER_DATE  = USER_DATE.substr(0, 6) + "99";
      this.arrayEvents = [];
      api
        .post(
          "api/schedule/list",
          {
            USER_ID        : USER_ID,
            FIRST_USER_DATE: FIRST_USER_DATE,
            LAST_USER_DATE : LAST_USER_DATE,
          },
          {
            head: {
              SERVICE: "api/schedule",
              METHOD : "/list",
              TYPE   : "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          this.initCNT   = 1;
          for (let index = 0; index < response.data.DATA.length; index++) {
            var dataList = {};
            var arrCal   = [];
            var arrColor = [];
            dataList.id  = index;
            dataList.day = response.data.DATA[index]["USER_DATE"].replace(
              /([0-9]{4})([0-9]+)([0-9]{2})/,
              "$1-$2-$3"
            );
            dataList.titleLS = this.restoreXSS(response.data.DATA[index]["TITLE"]).replace(/\r\n/g, "\n");
            dataList.desc    = this.restoreXSS(response.data.DATA[index]["CONTENT"]).replace(/\r\n/g, "\n");

            if (response.data.DATA[index]["SELECT_DAY"] == "기념일") {
              dataList.type  = "evt";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.color = "amber";
            } else {
              dataList.type  = "schedule";
              dataList.title = response.data.DATA[index]["SELECT_DAY"];
              dataList.color = "cyan";
            }
            this.arrayEvents.push(dataList);
            this.arrayEventsCalendar(this.arrayEvents[index].day);
          }
        });
    },

    FnArrayEventColor(e) {
      const index = this.arrayEvents.findIndex(function (item) {
        return item.day === e;
      });

      if (index < 0) return;

      return this.arrayEventColor[index];
    },

    // 모달 공통
    showDialog(type) {
      if(type == 'E050101P02'){
        eventBus.$emit('E050101P02TimeInit');
      }
      this[`dialog${type}`] = true;
    },

    // 모달 상세
    showDialogDetail(type, item1, item2, item3, item4, item5) {
      this[`dialog${type}`] = true;
      this.agentInfo.BRD_NO = item1;
      this.agentInfo.date   = item2;
      this.agentInfo.BRD_ID = item3;
      this.agentInfo.ASP_NEWCUST_KEY = "001";
      this.agentInfo.title  = item5;

      this.setAgent();
    },

    hideDialog(type) {
      this.isInit = !this.isInit;
      this[`dialog${type}`] = false;
    },

    // 나의 스케줄
    daySplit(e) {
      const day = e.split("-")[2];
      return day;
    },

    calendarLog(e) {
      const index = this.arrayEvents.findIndex(function (item) {
        return item.day === e;
      });

      if (index < 0) return;

      this.arrayEventDetail = this.arrayEvents[index] ?? this.arrayEvents[index];
    },

    // 스케줄 달력에 포인트 찍기
    arrayEventsCalendar(date) {
      let colorArray = [];

      for (let i = 0; i < this.arrayEvents.length; i++) {
        if (this.arrayEvents[i].day === date) {
          if (this.arrayEvents[i].type === "schedule") {
            colorArray.push("#6DC4B1");
          } else if (this.arrayEvents[i].type === "evt") {
            colorArray.push("#F7B611");
          }
        }
      }
      return colorArray;
    },

    getDay(date) {
      const day = date.split("-")[2];
      return Number(day);
    },

    time() {
      var dateLS = new Date();

      var year   = dateLS.getFullYear();
      var month  = dateLS.getMonth() + 1;
      var day    = dateLS.getDate();
      var hour   = dateLS.getHours();
      var minute = dateLS.getMinutes();
      var second = dateLS.getSeconds();

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }
      var fullNowDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      this.nowDate = year + "-" + month + "-" + day;
      this.lastUpdateTime = fullNowDate;
    },

    // 새로고침
    setTime(){
      this.isLoading = true;

      let nowDate         = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString();
      let nowDatArray     = nowDate.split("T");
      let nowTimeArray    = nowDatArray[1].split(".");
      this.lastUpdateTime = nowDatArray[0] + " " + nowTimeArray[0];

      this.getBoardList1();     // 자유게시판 리스트 조회
      this.getBoardList2();     // 공지사항 리스트 조회
      this.calendarClick();     // 스케줄 리스트 조회
      this.getMyCallList();     // MY 예약콜 리스트 조회
      this.getHomeStatus();     // 전화 상담이력 정보 조회
      this.getHomeChatStatus(); // 채팅 상담이력 정보 조회
    },

    checkCALENDERUNTREATED(){
      const calFlag = localStorage['CALENDER_UNTREATED'];
      if(calFlag == 'true'){
        if (this.atrtGroupId != '20220826113539330TWBaSY4N') {
          this.common_confirmAplyWork("캘린더/책자 공통업무 미처리 건이 있습니다.<br>확인 부탁드립니다.", null, null, "noti");
        }
      }
      localStorage['CALENDER_UNTREATED'] = false;
    },

    // 자유게시판 리스트 조회
    getBoardList1() {
      this.boardList1 = [];
      //부서정보 처리
      let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;
      let userAttrA = (chkGroup.indexOf("시스템") == -1)? this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A : '';
      
      api
        .post(
          "/api/board/list",
          {
            BRD_ID: "2", // BRD_ID: 2(자유게시판)
            ASP_NEWCUST_KEY: "001",
            USER_ATTR_A: userAttrA,
          },
          {
            head: {
              SERVICE: "/api/board",
              METHOD : "/list",
              TYPE   : "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var dataLength = "";
          if (response.data.HEADER.ERROR_FLAG == true){
            this.common_alert(this.alertMsg.responseError, "error");
            return false;
          } else if (response.data.DATA.length == 0) {
            return;
          } else if (response.data.DATA.length > 9) {
            dataLength = 10;  // 자유게시판 리스트는 10개만 보여준다.
          } else {
            dataLength = response.data.DATA.length;
          }
          for (let index = 0; index < dataLength; index++) {
            var dataList = {};
            dataList.title  = this.restoreXSS_CKeditor(response.data.DATA[index]["BRD_TIT"]);
            dataList.BRD_NO = response.data.DATA[index]["BRD_NO"];
            dataList.BRD_ID = "2";
            dataList.ASP_NEWCUST_KEY = "001";

            dataList.LVL_NO      = response.data.DATA[index]["LVL_NO"];
            dataList.NBSP        = response.data.DATA[index]["NBSP"];
            dataList.BRD_TIT_NEW = response.data.DATA[index]["BRD_TIT_NEW"];
            if (
              response.data.DATA[index]["FILE_CNT"] == "" ||
              response.data.DATA[index]["FILE_CNT"] == "undefined" ||
              response.data.DATA[index]["FILE_CNT"] == "0"
            ) {
              dataList.file = false;
            } else {
              dataList.file = true;
            }
            dataList.date = response.data.DATA[index]["LAST_BRD_DT"].replace(
              /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
              "$1-$2-$3 $4:$5:$6"
            );
            this.boardList1.push(dataList);
          }
        })
    },
    
    // 공지사항 리스트 조회
    getBoardList2() {
      this.boardList2 = [];
      //부서정보 처리
      let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;
      let userAttrA = (chkGroup.indexOf("시스템") == -1)? this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A : '';
      api
        .post(
          "/api/board/list",
          {
            BRD_ID: "6", // BRD_ID: 6(공지사항)
            NEW_BRD_ID: "60",
            ASP_NEWCUST_KEY: "001",
            USER_ATTR_A: userAttrA,
          },
          {
            head: {
              SERVICE: "/api/board",
              METHOD : "/list",
              TYPE   : "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          if (response.data.HEADER.ERROR_FLAG == true){
            this.common_alert(this.alertMsg.responseError, "error");
            return false;
          }
          let checkObj = {};
          //고정공지
          if(response.data.FIXED_LIST){
              for (let index = 0; index < response.data.FIXED_LIST.length; index++) {
              if(this.boardList2.length > 9) break;
              var dataList = {};
              dataList.title  = this.restoreXSS_CKeditor(response.data.FIXED_LIST[index]["BRD_TIT"]);
              dataList.BRD_NO = response.data.FIXED_LIST[index]["BRD_NO"];
              dataList.BRD_ID = "60";
              dataList.ASP_NEWCUST_KEY = "001";
              dataList.NBSP        = response.data.FIXED_LIST[index]["NBSP"];
              dataList.BRD_TIT_NEW = response.data.FIXED_LIST[index]["BRD_TIT_NEW"];
              if (
                response.data.FIXED_LIST[index]["FILE_CNT"] == "" ||
                response.data.FIXED_LIST[index]["FILE_CNT"] == "undefined" ||
                response.data.FIXED_LIST[index]["FILE_CNT"] == "0"
              ) {
                dataList.file = false;
              } else {
                dataList.file = true;
              }
              dataList.date = response.data.FIXED_LIST[index]["LAST_BRD_DT"].replace(
                /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                "$1-$2-$3 $4:$5:$6"
              );
              checkObj[dataList.BRD_NO] = '1';
              this.boardList2.push(dataList);
            }
          }
          //일반 공지
          for (let index = 0; index < response.data.DATA.length; index++) {
            if(this.boardList2.length > 9) break;
            var dataList = {};
            dataList.title  = this.restoreXSS_CKeditor(response.data.DATA[index]["BRD_TIT"]);
            dataList.BRD_NO = response.data.DATA[index]["BRD_NO"];
            dataList.BRD_ID = "6";
            dataList.ASP_NEWCUST_KEY = "001";
            dataList.NBSP        = response.data.DATA[index]["NBSP"];
            dataList.BRD_TIT_NEW = response.data.DATA[index]["BRD_TIT_NEW"];
            if (
              response.data.DATA[index]["FILE_CNT"] == "" ||
              response.data.DATA[index]["FILE_CNT"] == "undefined" ||
              response.data.DATA[index]["FILE_CNT"] == "0"
            ) {
              dataList.file = false;
            } else {
              dataList.file = true;
            }
            dataList.date = response.data.DATA[index]["LAST_BRD_DT"].replace(
              /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
              "$1-$2-$3 $4:$5:$6"
            );
            if(typeof checkObj[dataList.BRD_NO] == 'undefined'){
              this.boardList2.push(dataList);
            }
          }
        });
    },

    // MY 예약콜 리스트 조회
    getMyCallList(){
      this.myCallList = [];
      api
        .post(
          "/api/phone/mycall/Homelist",
          {
            CSLT_MAN: this.user_id,              // 담당자
            // PROC_STAT_CD: "01",                  // MYCALL 처리결과코드 (01: 미처리)
            // STR_RECP_DT: [moment().format("YYYYMMDD")],       
            // END_RECP_DT: [moment().format("YYYYMMDD")],
          },
          {
            head: {
              SERVICE: "phone.mycall",
              METHOD : "select",
              TYPE   : "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          var dataLength = "";
          if (response.data.HEADER.ERROR_FLAG == true){
            this.common_alert(this.alertMsg.responseError, "error");
            return false;
          } else if (response.data.DATA.length == 0) {
            return;
          } else {
            dataLength = response.data.DATA.length;
          }
          for (let index = 0; index < dataLength; index++) {
            var dataList = {};  

            dataList.status = response.data.DATA[index]["RSLT_CD_STR"];
            dataList.name   = response.data.DATA[index]["CSLT_TY_LCLS"];
            dataList.date   = response.data.DATA[index]["RECP_DT"].replace(/(\d{4})(\d{2})(\d{2})/,"$1-$2-$3");
            
            this.myCallList.push(dataList);
          }
      });
    },

    // 전화 상담이력 정보 조회
    getHomeStatus(){
      api
        .post(
          "/api/homestatus/homestatus",
          {
            USER_ID : this.user_id,                     // 담당자 ID
            REG_DTTM: [moment().format("YYYY-MM-DD")],  // 등록일자 
          },
          {
            head: {
              SERVICE: "api/homestatus",
              METHOD : "homestatus",
              TYPE   : "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          let data = response.data.DATA[0];

          if (response.data.HEADER.ERROR_FLAG == true){
            this.common_alert(this.alertMsg.responseError, "error");
            return false;
          } else if (data.TOTAL_Call_TIME == "") {
            this.total_calls    = data.TOTAL_Calls;                // 총 건수
            this.ib_calls       = data.IB_CallS;                   // IB 건수
            this.ob_calls       = data.OB_CallS;                   // OB 건수 
            this.total_calltime = ["0", "0", "0"];                 // 총 통화시간 (시:분:초)
          } else {
            this.total_calls    = data.TOTAL_Calls;                // 총 건수
            this.ib_calls       = data.IB_CallS;                   // IB 건수
            this.ob_calls       = data.OB_CallS;                   // OB 건수   
            this.total_calltime = data.TOTAL_Call_TIME.split(":"); // 총 통화시간 (시:분:초)
          }
      }).finally(() => {
          this.isLoading = false;
      });
    },

    // 채팅 상담이력 정보 조회
    getHomeChatStatus(){
      this.homeChatStatusList = [];

      api
        .post(
          "/api/homestatus/homechatstatus",
          {
            USER_ID    : this.user_id,                     // 담당자ID
            CHAT_REG_DTTM: [moment().format("YYYYMMDD")],  // 등록일자 
          },
          {
            head: {
              SERVICE: "api/homechatstatus",
              METHOD : "homestatus",
              TYPE   : "BIZ_SERVICE",
            },
          }
        )
        .then((response) => {
          let data = response.data.DATA[0];

          if (response.data.HEADER.ERROR_FLAG == true){
            this.common_alert(this.alertMsg.responseError, "error");
            return false;
          } else {
            this.NUMBER_CUSTOMER_REQUEST = data.NUMBER_CUSTOMER_REQUEST;                // 총 건수
            this.NUMBER_COMPLETE = data.NUMBER_COMPLETE;                  
          }
      });
    },

    updatePickedMonth(value){
      this.nowDate = value + "-01";

      if (this.initCNT != 0){
        this.calendarClick();
      }
    },

    // loader
    FnLoading() {
      this.loading = !this.loading;

      setTimeout(() => (this.loading = false), 1000);
    },


     async checkBoard() {

            let requestData = {
                headers: {
                    // SERVICE: "easydirect.popup.detail",
                    METHOD: "",
                    TYPE: 'BIZ_SERVICE',
                    ROW_CNT: "200",
                    PAGES_CNT: "1",                    
                },
                sendData: {}
            };


                requestData.headers["SERVICE"] = "easydirect.check.board";
                requestData.headers["URL"] = "/api/easydirect/check/board";
                requestData.headers["METHOD"] = "update";
                requestData.headers["ASYNC"] = false;                  


            requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

                  if(response.DATA[0].CNT > 0){ 
                    let iconType = 'done';
                    let msg = "접수하지 않은 계약문의가 존재합니다.";



                    //alert창 띄우기
                    this.common_alert(msg, iconType);
                  }else{
                    return;
                  }
                
                

            }else{
                let msg = response.HEADER.ERROR_MSG;
                let iconType = 'error';

                this.common_alert(msg, iconType);

            }

        }, 


  },

  mounted() {





    let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;   
    if(chkGroup.indexOf("관리자") > -1 || chkGroup.indexOf("매니저") > -1/*chkGroup == "이베스트관리자" || chkGroup == "시스템관리자"*/){
      this.CHK_BL =false;
      
      worker.postMessage("나 통계이야!!!");

      worker.onmessage = (msg) => {
      // console.log("receive::", msg)
        eventBus.busFnWorker(); 
      }

    }else{
      this.CHK_BL = true;
    }        
    this.CHK_CUST = {
      CHK: 1,
      CHK_CD: this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
      CHK_NM: this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD_NM
    },
    // this.getBoardList1();     // 자유게시판 리스트 조회
    this.getBoardList2();     // 공지사항 리스트 조회
    this.dataTableSearch()
    // this.calendarClick();     // 스케줄 리스트 조회
    // this.getMyCallList();     // MY 예약콜 리스트 조회
    // this.getHomeStatus();     // 전화 상담이력 정보 조회
    // this.getHomeChatStatus(); // 채팅 상담이력 정보 조회

    //TST-CAL_000033 캘린더 신청_미처리건 있을 시_ 알럿 : 로그인화면이 아닌 HOME에서 띄우기
    this.checkCALENDERUNTREATED();
    this.dialogPassword = this.$store.getters["authStore/GE_CHECK_PWD_UPD"]
  },

  created() {
    this.time();
    this.dataTableOptions.headers = this.gridDataHeaders;
    // this.dataTableOptions.items = this.gridDataText;
    this.dataTableOptions.items = this.rowNum;
    
    eventBus.$on('busFnWorker', () => {
      // console.log("shit")
      this.checkBoard();
    });       
  },

  computed: {
    // 접속된 사용자ID, 사용자이름, 생년월일, 핸드폰번호, 마지막 로그인 시간, 권한그룹 가져오기.
    ...mapGetters({
      user_name    : "userStore/GE_USER_NAME",
      user_id      : "userStore/GE_USER_ID",
      hpNo         : "userStore/GE_USER_HP_NO",
      bday         : "userStore/GE_USER_BDAY",
      atrtGroupNm  : "userStore/GE_USER_AUTHOR_GROUP_NM",
      ATTR_DIV_NM_A: "userStore/GE_USER_ATTR_DIV_NM_A",
      ATTR_DIV_NM_B: "userStore/GE_USER_ATTR_DIV_NM_B",
      CEN_TYPE_CD: "userStore/GE_USER_CEN_TYPE_CD"
    }),

        rowNum() {
            return this.items?.map(
                (items, index) => ({
                ...items,
                index: index + 1
            }))
        },
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },        
    
    LastLoginDt: function() {
      return this.$store.getters["userStore/GE_USER_ROLE"].lastLoginDt.replaceAll("/", "-");
    },

    getSCHEDULE () {
      return this.$store.getters['userStore/GE_USER_ROLE'].SCHEDULE;
    },
  },

  watch: {
    getSCHEDULE(val, oldVal){
      this.calendarClick();
    }
  },
};


// 암호화 모듈
let GibberishAES = (function () {
  var Nr = 14,
    /* Default to 256 Bit Encryption */
    Nk = 8,
    Decrypt = false,
    enc_utf8 = function (s) {
      try {
        return unescape(encodeURIComponent(s));
      } catch (e) {
        throw "Error on UTF-8 encode";
      }
    },
    dec_utf8 = function (s) {
      try {
        return decodeURIComponent(escape(s));
      } catch (e) {
        throw "Bad Key";
      }
    },
    padBlock = function (byteArr) {
      var array = [],
        cpad,
        i;
      if (byteArr.length < 16) {
        cpad = 16 - byteArr.length;
        array = [
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
          cpad,
        ];
      }
      for (i = 0; i < byteArr.length; i++) {
        array[i] = byteArr[i];
      }
      return array;
    },
    block2s = function (block, lastBlock) {
      var string = "",
        padding,
        i;
      if (lastBlock) {
        padding = block[15];
        if (padding > 16) {
          throw "Decryption error: Maybe bad key";
        }
        if (padding == 16) {
          return "";
        }
        for (i = 0; i < 16 - padding; i++) {
          string += String.fromCharCode(block[i]);
        }
      } else {
        for (i = 0; i < 16; i++) {
          string += String.fromCharCode(block[i]);
        }
      }
      return string;
    },
    a2h = function (numArr) {
      var string = "",
        i;
      for (i = 0; i < numArr.length; i++) {
        string += (numArr[i] < 16 ? "0" : "") + numArr[i].toString(16);
      }
      return string;
    },
    h2a = function (s) {
      var ret = [];
      s.replace(/(..)/g, function (s) {
        ret.push(parseInt(s, 16));
      });
      return ret;
    },
    s2a = function (string, binary) {
      var array = [],
        i;

      if (!binary) {
        string = enc_utf8(string);
      }

      for (i = 0; i < string.length; i++) {
        array[i] = string.charCodeAt(i);
      }

      return array;
    },
    size = function (newsize) {
      switch (newsize) {
        case 128:
          Nr = 10;
          Nk = 4;
          break;
        case 192:
          Nr = 12;
          Nk = 6;
          break;
        case 256:
          Nr = 14;
          Nk = 8;
          break;
        default:
          throw "Invalid Key Size Specified:" + newsize;
      }
    },
    randArr = function (num) {
      var result = [],
        i;
      for (i = 0; i < num; i++) {
        result = result.concat(Math.floor(Math.random() * 256));
      }
      return result;
    },
    openSSLKey = function (passwordArr, saltArr) {
      // Number of rounds depends on the size of the AES in use
      // 3 rounds for 256
      //        2 rounds for the key, 1 for the IV
      // 2 rounds for 128
      //        1 round for the key, 1 round for the IV
      // 3 rounds for 192 since it's not evenly divided by 128 bits
      var rounds = Nr >= 12 ? 3 : 2,
        key = [],
        iv = [],
        md5_hash = [],
        result = [],
        data00 = passwordArr.concat(saltArr),
        i;
      md5_hash[0] = GibberishAES.Hash.MD5(data00);
      result = md5_hash[0];
      for (i = 1; i < rounds; i++) {
        md5_hash[i] = GibberishAES.Hash.MD5(md5_hash[i - 1].concat(data00));
        result = result.concat(md5_hash[i]);
      }
      key = result.slice(0, 4 * Nk);
      iv = result.slice(4 * Nk, 4 * Nk + 16);
      return {
        key: key,
        iv: iv,
      };
    },
    rawEncrypt = function (plaintext, key) {
      plaintext = GibberishAES.s2a(plaintext);
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // plaintext, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = Math.ceil(plaintext.length / 16),
        blocks = [],
        i,
        cipherBlocks = [];
      for (i = 0; i < numBlocks; i++) {
        blocks[i] = padBlock(plaintext.slice(i * 16, i * 16 + 16));
      }
      if (plaintext.length % 16 === 0) {
        blocks.push([
          16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16,
        ]);
        // CBC OpenSSL padding scheme
        numBlocks++;
      }
      for (i = 0; i < blocks.length; i++) {
        blocks[i] =
          i === 0
            ? xorBlocks(blocks[i], iv)
            : xorBlocks(blocks[i], cipherBlocks[i - 1]);
        cipherBlocks[i] = encryptBlock(blocks[i], key);
      }
      return GibberishAES.Base64.encode(cipherBlocks);
    },
    rawDecrypt = function (cryptArr, key) {
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      //  cryptArr, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = cryptArr.length / 16,
        cipherBlocks = [],
        i,
        plainBlocks = [],
        string = "";
      for (i = 0; i < numBlocks; i++) {
        cipherBlocks.push(cryptArr.slice(i * 16, (i + 1) * 16));
      }
      for (i = cipherBlocks.length - 1; i >= 0; i--) {
        plainBlocks[i] = decryptBlock(cipherBlocks[i], key);
        plainBlocks[i] =
          i === 0
            ? xorBlocks(plainBlocks[i], iv)
            : xorBlocks(plainBlocks[i], cipherBlocks[i - 1]);
      }
      for (i = 0; i < numBlocks - 1; i++) {
        string += block2s(plainBlocks[i]);
      }
      string += block2s(plainBlocks[i], true);

      return dec_utf8(string);
      //return binary ? string : dec_utf8(string);
    },
    aesEncrypt = function (plaintext, key) {
      var rtnVal = "";
      plaintext = GibberishAES.s2a(plaintext);
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // plaintext, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = Math.ceil(plaintext.length / 16),
        blocks = [],
        i,
        cipherBlocks = [];
      for (i = 0; i < numBlocks; i++) {
        blocks[i] = padBlock(plaintext.slice(i * 16, i * 16 + 16));
      }
      if (plaintext.length % 16 === 0) {
        blocks.push([
          16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16,
        ]);
        // CBC OpenSSL padding scheme
        numBlocks++;
      }
      for (i = 0; i < blocks.length; i++) {
        blocks[i] =
          i === 0
            ? xorBlocks(blocks[i], iv)
            : xorBlocks(blocks[i], cipherBlocks[i - 1]);
        cipherBlocks[i] = encryptBlock(blocks[i], key);
      }
      rtnVal = GibberishAES.Base64.encode(cipherBlocks);

      return rtnVal.toString().replace("\n", "");
    },
    aesDecrypt = function (base64Text, key) {
      var cryptArr = GibberishAES.Base64.decode(base64Text);
      var iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      //  cryptArr, key and iv as byte arrays
      key = expandKey(GibberishAES.s2a(key));
      var numBlocks = cryptArr.length / 16,
        cipherBlocks = [],
        i,
        plainBlocks = [],
        string = "";
      for (i = 0; i < numBlocks; i++) {
        cipherBlocks.push(cryptArr.slice(i * 16, (i + 1) * 16));
      }
      for (i = cipherBlocks.length - 1; i >= 0; i--) {
        plainBlocks[i] = decryptBlock(cipherBlocks[i], key);
        plainBlocks[i] =
          i === 0
            ? xorBlocks(plainBlocks[i], iv)
            : xorBlocks(plainBlocks[i], cipherBlocks[i - 1]);
      }
      for (i = 0; i < numBlocks - 1; i++) {
        string += block2s(plainBlocks[i]);
      }
      string += block2s(plainBlocks[i], true);

      return dec_utf8(string);
      //return binary ? string : dec_utf8(string);
    },
    encryptBlock = function (block, words) {
      Decrypt = false;
      var state = addRoundKey(block, words, 0),
        round;
      for (round = 1; round < Nr + 1; round++) {
        state = subBytes(state);
        state = shiftRows(state);
        if (round < Nr) {
          state = mixColumns(state);
        }
        //last round? don't mixColumns
        state = addRoundKey(state, words, round);
      }

      return state;
    },
    decryptBlock = function (block, words) {
      Decrypt = true;
      var state = addRoundKey(block, words, Nr),
        round;
      for (round = Nr - 1; round > -1; round--) {
        state = shiftRows(state);
        state = subBytes(state);
        state = addRoundKey(state, words, round);
        if (round > 0) {
          state = mixColumns(state);
        }
        //last round? don't mixColumns
      }

      return state;
    },
    subBytes = function (state) {
      var S = Decrypt ? SBoxInv : SBox,
        temp = [],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = S[state[i]];
      }
      return temp;
    },
    shiftRows = function (state) {
      var temp = [],
        shiftBy = Decrypt
          ? [0, 13, 10, 7, 4, 1, 14, 11, 8, 5, 2, 15, 12, 9, 6, 3]
          : [0, 5, 10, 15, 4, 9, 14, 3, 8, 13, 2, 7, 12, 1, 6, 11],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = state[shiftBy[i]];
      }
      return temp;
    },
    mixColumns = function (state) {
      var t = [],
        c;
      if (!Decrypt) {
        for (c = 0; c < 4; c++) {
          t[c * 4] =
            G2X[state[c * 4]] ^
            G3X[state[1 + c * 4]] ^
            state[2 + c * 4] ^
            state[3 + c * 4];
          t[1 + c * 4] =
            state[c * 4] ^
            G2X[state[1 + c * 4]] ^
            G3X[state[2 + c * 4]] ^
            state[3 + c * 4];
          t[2 + c * 4] =
            state[c * 4] ^
            state[1 + c * 4] ^
            G2X[state[2 + c * 4]] ^
            G3X[state[3 + c * 4]];
          t[3 + c * 4] =
            G3X[state[c * 4]] ^
            state[1 + c * 4] ^
            state[2 + c * 4] ^
            G2X[state[3 + c * 4]];
        }
      } else {
        for (c = 0; c < 4; c++) {
          t[c * 4] =
            GEX[state[c * 4]] ^
            GBX[state[1 + c * 4]] ^
            GDX[state[2 + c * 4]] ^
            G9X[state[3 + c * 4]];
          t[1 + c * 4] =
            G9X[state[c * 4]] ^
            GEX[state[1 + c * 4]] ^
            GBX[state[2 + c * 4]] ^
            GDX[state[3 + c * 4]];
          t[2 + c * 4] =
            GDX[state[c * 4]] ^
            G9X[state[1 + c * 4]] ^
            GEX[state[2 + c * 4]] ^
            GBX[state[3 + c * 4]];
          t[3 + c * 4] =
            GBX[state[c * 4]] ^
            GDX[state[1 + c * 4]] ^
            G9X[state[2 + c * 4]] ^
            GEX[state[3 + c * 4]];
        }
      }

      return t;
    },
    addRoundKey = function (state, words, round) {
      var temp = [],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = state[i] ^ words[round][i];
      }
      return temp;
    },
    xorBlocks = function (block1, block2) {
      var temp = [],
        i;
      for (i = 0; i < 16; i++) {
        temp[i] = block1[i] ^ block2[i];
      }
      return temp;
    },
    expandKey = function (key) {
      // Expects a 1d number array
      var w = [],
        temp = [],
        i,
        r,
        t,
        flat = [],
        j;

      for (i = 0; i < Nk; i++) {
        r = [key[4 * i], key[4 * i + 1], key[4 * i + 2], key[4 * i + 3]];
        w[i] = r;
      }

      for (i = Nk; i < 4 * (Nr + 1); i++) {
        w[i] = [];
        for (t = 0; t < 4; t++) {
          temp[t] = w[i - 1][t];
        }
        if (i % Nk === 0) {
          temp = subWord(rotWord(temp));
          temp[0] ^= Rcon[i / Nk - 1];
        } else if (Nk > 6 && i % Nk == 4) {
          temp = subWord(temp);
        }
        for (t = 0; t < 4; t++) {
          w[i][t] = w[i - Nk][t] ^ temp[t];
        }
      }
      for (i = 0; i < Nr + 1; i++) {
        flat[i] = [];
        for (j = 0; j < 4; j++) {
          flat[i].push(
            w[i * 4 + j][0],
            w[i * 4 + j][1],
            w[i * 4 + j][2],
            w[i * 4 + j][3]
          );
        }
      }
      return flat;
    },
    subWord = function (w) {
      // apply SBox to 4-byte word w
      for (var i = 0; i < 4; i++) {
        w[i] = SBox[w[i]];
      }
      return w;
    },
    rotWord = function (w) {
      // rotate 4-byte word w left by one byte
      var tmp = w[0],
        i;
      for (i = 0; i < 4; i++) {
        w[i] = w[i + 1];
      }
      w[3] = tmp;
      return w;
    },
    // S-box
    SBox = [
      99, 124, 119, 123, 242, 107, 111, 197, 48, 1, 103, 43, 254, 215, 171, 118,
      202, 130, 201, 125, 250, 89, 71, 240, 173, 212, 162, 175, 156, 164, 114,
      192, 183, 253, 147, 38, 54, 63, 247, 204, 52, 165, 229, 241, 113, 216, 49,
      21, 4, 199, 35, 195, 24, 150, 5, 154, 7, 18, 128, 226, 235, 39, 178, 117,
      9, 131, 44, 26, 27, 110, 90, 160, 82, 59, 214, 179, 41, 227, 47, 132, 83,
      209, 0, 237, 32, 252, 177, 91, 106, 203, 190, 57, 74, 76, 88, 207, 208,
      239, 170, 251, 67, 77, 51, 133, 69, 249, 2, 127, 80, 60, 159, 168, 81,
      163, 64, 143, 146, 157, 56, 245, 188, 182, 218, 33, 16, 255, 243, 210,
      205, 12, 19, 236, 95, 151, 68, 23, 196, 167, 126, 61, 100, 93, 25, 115,
      96, 129, 79, 220, 34, 42, 144, 136, 70, 238, 184, 20, 222, 94, 11, 219,
      224, 50, 58, 10, 73, 6, 36, 92, 194, 211, 172, 98, 145, 149, 228, 121,
      231, 200, 55, 109, 141, 213, 78, 169, 108, 86, 244, 234, 101, 122, 174, 8,
      186, 120, 37, 46, 28, 166, 180, 198, 232, 221, 116, 31, 75, 189, 139, 138,
      112, 62, 181, 102, 72, 3, 246, 14, 97, 53, 87, 185, 134, 193, 29, 158,
      225, 248, 152, 17, 105, 217, 142, 148, 155, 30, 135, 233, 206, 85, 40,
      223, 140, 161, 137, 13, 191, 230, 66, 104, 65, 153, 45, 15, 176, 84, 187,
      22,
    ],
    // Precomputed lookup table for the inverse SBox
    SBoxInv = [
      82, 9, 106, 213, 48, 54, 165, 56, 191, 64, 163, 158, 129, 243, 215, 251,
      124, 227, 57, 130, 155, 47, 255, 135, 52, 142, 67, 68, 196, 222, 233, 203,
      84, 123, 148, 50, 166, 194, 35, 61, 238, 76, 149, 11, 66, 250, 195, 78, 8,
      46, 161, 102, 40, 217, 36, 178, 118, 91, 162, 73, 109, 139, 209, 37, 114,
      248, 246, 100, 134, 104, 152, 22, 212, 164, 92, 204, 93, 101, 182, 146,
      108, 112, 72, 80, 253, 237, 185, 218, 94, 21, 70, 87, 167, 141, 157, 132,
      144, 216, 171, 0, 140, 188, 211, 10, 247, 228, 88, 5, 184, 179, 69, 6,
      208, 44, 30, 143, 202, 63, 15, 2, 193, 175, 189, 3, 1, 19, 138, 107, 58,
      145, 17, 65, 79, 103, 220, 234, 151, 242, 207, 206, 240, 180, 230, 115,
      150, 172, 116, 34, 231, 173, 53, 133, 226, 249, 55, 232, 28, 117, 223,
      110, 71, 241, 26, 113, 29, 41, 197, 137, 111, 183, 98, 14, 170, 24, 190,
      27, 252, 86, 62, 75, 198, 210, 121, 32, 154, 219, 192, 254, 120, 205, 90,
      244, 31, 221, 168, 51, 136, 7, 199, 49, 177, 18, 16, 89, 39, 128, 236, 95,
      96, 81, 127, 169, 25, 181, 74, 13, 45, 229, 122, 159, 147, 201, 156, 239,
      160, 224, 59, 77, 174, 42, 245, 176, 200, 235, 187, 60, 131, 83, 153, 97,
      23, 43, 4, 126, 186, 119, 214, 38, 225, 105, 20, 99, 85, 33, 12, 125,
    ],
    // Rijndael Rcon
    Rcon = [
      1, 2, 4, 8, 16, 32, 64, 128, 27, 54, 108, 216, 171, 77, 154, 47, 94, 188,
      99, 198, 151, 53, 106, 212, 179, 125, 250, 239, 197, 145,
    ],
    G2X = [
      0x00, 0x02, 0x04, 0x06, 0x08, 0x0a, 0x0c, 0x0e, 0x10, 0x12, 0x14, 0x16,
      0x18, 0x1a, 0x1c, 0x1e, 0x20, 0x22, 0x24, 0x26, 0x28, 0x2a, 0x2c, 0x2e,
      0x30, 0x32, 0x34, 0x36, 0x38, 0x3a, 0x3c, 0x3e, 0x40, 0x42, 0x44, 0x46,
      0x48, 0x4a, 0x4c, 0x4e, 0x50, 0x52, 0x54, 0x56, 0x58, 0x5a, 0x5c, 0x5e,
      0x60, 0x62, 0x64, 0x66, 0x68, 0x6a, 0x6c, 0x6e, 0x70, 0x72, 0x74, 0x76,
      0x78, 0x7a, 0x7c, 0x7e, 0x80, 0x82, 0x84, 0x86, 0x88, 0x8a, 0x8c, 0x8e,
      0x90, 0x92, 0x94, 0x96, 0x98, 0x9a, 0x9c, 0x9e, 0xa0, 0xa2, 0xa4, 0xa6,
      0xa8, 0xaa, 0xac, 0xae, 0xb0, 0xb2, 0xb4, 0xb6, 0xb8, 0xba, 0xbc, 0xbe,
      0xc0, 0xc2, 0xc4, 0xc6, 0xc8, 0xca, 0xcc, 0xce, 0xd0, 0xd2, 0xd4, 0xd6,
      0xd8, 0xda, 0xdc, 0xde, 0xe0, 0xe2, 0xe4, 0xe6, 0xe8, 0xea, 0xec, 0xee,
      0xf0, 0xf2, 0xf4, 0xf6, 0xf8, 0xfa, 0xfc, 0xfe, 0x1b, 0x19, 0x1f, 0x1d,
      0x13, 0x11, 0x17, 0x15, 0x0b, 0x09, 0x0f, 0x0d, 0x03, 0x01, 0x07, 0x05,
      0x3b, 0x39, 0x3f, 0x3d, 0x33, 0x31, 0x37, 0x35, 0x2b, 0x29, 0x2f, 0x2d,
      0x23, 0x21, 0x27, 0x25, 0x5b, 0x59, 0x5f, 0x5d, 0x53, 0x51, 0x57, 0x55,
      0x4b, 0x49, 0x4f, 0x4d, 0x43, 0x41, 0x47, 0x45, 0x7b, 0x79, 0x7f, 0x7d,
      0x73, 0x71, 0x77, 0x75, 0x6b, 0x69, 0x6f, 0x6d, 0x63, 0x61, 0x67, 0x65,
      0x9b, 0x99, 0x9f, 0x9d, 0x93, 0x91, 0x97, 0x95, 0x8b, 0x89, 0x8f, 0x8d,
      0x83, 0x81, 0x87, 0x85, 0xbb, 0xb9, 0xbf, 0xbd, 0xb3, 0xb1, 0xb7, 0xb5,
      0xab, 0xa9, 0xaf, 0xad, 0xa3, 0xa1, 0xa7, 0xa5, 0xdb, 0xd9, 0xdf, 0xdd,
      0xd3, 0xd1, 0xd7, 0xd5, 0xcb, 0xc9, 0xcf, 0xcd, 0xc3, 0xc1, 0xc7, 0xc5,
      0xfb, 0xf9, 0xff, 0xfd, 0xf3, 0xf1, 0xf7, 0xf5, 0xeb, 0xe9, 0xef, 0xed,
      0xe3, 0xe1, 0xe7, 0xe5,
    ],
    G3X = [
      0x00, 0x03, 0x06, 0x05, 0x0c, 0x0f, 0x0a, 0x09, 0x18, 0x1b, 0x1e, 0x1d,
      0x14, 0x17, 0x12, 0x11, 0x30, 0x33, 0x36, 0x35, 0x3c, 0x3f, 0x3a, 0x39,
      0x28, 0x2b, 0x2e, 0x2d, 0x24, 0x27, 0x22, 0x21, 0x60, 0x63, 0x66, 0x65,
      0x6c, 0x6f, 0x6a, 0x69, 0x78, 0x7b, 0x7e, 0x7d, 0x74, 0x77, 0x72, 0x71,
      0x50, 0x53, 0x56, 0x55, 0x5c, 0x5f, 0x5a, 0x59, 0x48, 0x4b, 0x4e, 0x4d,
      0x44, 0x47, 0x42, 0x41, 0xc0, 0xc3, 0xc6, 0xc5, 0xcc, 0xcf, 0xca, 0xc9,
      0xd8, 0xdb, 0xde, 0xdd, 0xd4, 0xd7, 0xd2, 0xd1, 0xf0, 0xf3, 0xf6, 0xf5,
      0xfc, 0xff, 0xfa, 0xf9, 0xe8, 0xeb, 0xee, 0xed, 0xe4, 0xe7, 0xe2, 0xe1,
      0xa0, 0xa3, 0xa6, 0xa5, 0xac, 0xaf, 0xaa, 0xa9, 0xb8, 0xbb, 0xbe, 0xbd,
      0xb4, 0xb7, 0xb2, 0xb1, 0x90, 0x93, 0x96, 0x95, 0x9c, 0x9f, 0x9a, 0x99,
      0x88, 0x8b, 0x8e, 0x8d, 0x84, 0x87, 0x82, 0x81, 0x9b, 0x98, 0x9d, 0x9e,
      0x97, 0x94, 0x91, 0x92, 0x83, 0x80, 0x85, 0x86, 0x8f, 0x8c, 0x89, 0x8a,
      0xab, 0xa8, 0xad, 0xae, 0xa7, 0xa4, 0xa1, 0xa2, 0xb3, 0xb0, 0xb5, 0xb6,
      0xbf, 0xbc, 0xb9, 0xba, 0xfb, 0xf8, 0xfd, 0xfe, 0xf7, 0xf4, 0xf1, 0xf2,
      0xe3, 0xe0, 0xe5, 0xe6, 0xef, 0xec, 0xe9, 0xea, 0xcb, 0xc8, 0xcd, 0xce,
      0xc7, 0xc4, 0xc1, 0xc2, 0xd3, 0xd0, 0xd5, 0xd6, 0xdf, 0xdc, 0xd9, 0xda,
      0x5b, 0x58, 0x5d, 0x5e, 0x57, 0x54, 0x51, 0x52, 0x43, 0x40, 0x45, 0x46,
      0x4f, 0x4c, 0x49, 0x4a, 0x6b, 0x68, 0x6d, 0x6e, 0x67, 0x64, 0x61, 0x62,
      0x73, 0x70, 0x75, 0x76, 0x7f, 0x7c, 0x79, 0x7a, 0x3b, 0x38, 0x3d, 0x3e,
      0x37, 0x34, 0x31, 0x32, 0x23, 0x20, 0x25, 0x26, 0x2f, 0x2c, 0x29, 0x2a,
      0x0b, 0x08, 0x0d, 0x0e, 0x07, 0x04, 0x01, 0x02, 0x13, 0x10, 0x15, 0x16,
      0x1f, 0x1c, 0x19, 0x1a,
    ],
    G9X = [
      0x00, 0x09, 0x12, 0x1b, 0x24, 0x2d, 0x36, 0x3f, 0x48, 0x41, 0x5a, 0x53,
      0x6c, 0x65, 0x7e, 0x77, 0x90, 0x99, 0x82, 0x8b, 0xb4, 0xbd, 0xa6, 0xaf,
      0xd8, 0xd1, 0xca, 0xc3, 0xfc, 0xf5, 0xee, 0xe7, 0x3b, 0x32, 0x29, 0x20,
      0x1f, 0x16, 0x0d, 0x04, 0x73, 0x7a, 0x61, 0x68, 0x57, 0x5e, 0x45, 0x4c,
      0xab, 0xa2, 0xb9, 0xb0, 0x8f, 0x86, 0x9d, 0x94, 0xe3, 0xea, 0xf1, 0xf8,
      0xc7, 0xce, 0xd5, 0xdc, 0x76, 0x7f, 0x64, 0x6d, 0x52, 0x5b, 0x40, 0x49,
      0x3e, 0x37, 0x2c, 0x25, 0x1a, 0x13, 0x08, 0x01, 0xe6, 0xef, 0xf4, 0xfd,
      0xc2, 0xcb, 0xd0, 0xd9, 0xae, 0xa7, 0xbc, 0xb5, 0x8a, 0x83, 0x98, 0x91,
      0x4d, 0x44, 0x5f, 0x56, 0x69, 0x60, 0x7b, 0x72, 0x05, 0x0c, 0x17, 0x1e,
      0x21, 0x28, 0x33, 0x3a, 0xdd, 0xd4, 0xcf, 0xc6, 0xf9, 0xf0, 0xeb, 0xe2,
      0x95, 0x9c, 0x87, 0x8e, 0xb1, 0xb8, 0xa3, 0xaa, 0xec, 0xe5, 0xfe, 0xf7,
      0xc8, 0xc1, 0xda, 0xd3, 0xa4, 0xad, 0xb6, 0xbf, 0x80, 0x89, 0x92, 0x9b,
      0x7c, 0x75, 0x6e, 0x67, 0x58, 0x51, 0x4a, 0x43, 0x34, 0x3d, 0x26, 0x2f,
      0x10, 0x19, 0x02, 0x0b, 0xd7, 0xde, 0xc5, 0xcc, 0xf3, 0xfa, 0xe1, 0xe8,
      0x9f, 0x96, 0x8d, 0x84, 0xbb, 0xb2, 0xa9, 0xa0, 0x47, 0x4e, 0x55, 0x5c,
      0x63, 0x6a, 0x71, 0x78, 0x0f, 0x06, 0x1d, 0x14, 0x2b, 0x22, 0x39, 0x30,
      0x9a, 0x93, 0x88, 0x81, 0xbe, 0xb7, 0xac, 0xa5, 0xd2, 0xdb, 0xc0, 0xc9,
      0xf6, 0xff, 0xe4, 0xed, 0x0a, 0x03, 0x18, 0x11, 0x2e, 0x27, 0x3c, 0x35,
      0x42, 0x4b, 0x50, 0x59, 0x66, 0x6f, 0x74, 0x7d, 0xa1, 0xa8, 0xb3, 0xba,
      0x85, 0x8c, 0x97, 0x9e, 0xe9, 0xe0, 0xfb, 0xf2, 0xcd, 0xc4, 0xdf, 0xd6,
      0x31, 0x38, 0x23, 0x2a, 0x15, 0x1c, 0x07, 0x0e, 0x79, 0x70, 0x6b, 0x62,
      0x5d, 0x54, 0x4f, 0x46,
    ],
    GBX = [
      0x00, 0x0b, 0x16, 0x1d, 0x2c, 0x27, 0x3a, 0x31, 0x58, 0x53, 0x4e, 0x45,
      0x74, 0x7f, 0x62, 0x69, 0xb0, 0xbb, 0xa6, 0xad, 0x9c, 0x97, 0x8a, 0x81,
      0xe8, 0xe3, 0xfe, 0xf5, 0xc4, 0xcf, 0xd2, 0xd9, 0x7b, 0x70, 0x6d, 0x66,
      0x57, 0x5c, 0x41, 0x4a, 0x23, 0x28, 0x35, 0x3e, 0x0f, 0x04, 0x19, 0x12,
      0xcb, 0xc0, 0xdd, 0xd6, 0xe7, 0xec, 0xf1, 0xfa, 0x93, 0x98, 0x85, 0x8e,
      0xbf, 0xb4, 0xa9, 0xa2, 0xf6, 0xfd, 0xe0, 0xeb, 0xda, 0xd1, 0xcc, 0xc7,
      0xae, 0xa5, 0xb8, 0xb3, 0x82, 0x89, 0x94, 0x9f, 0x46, 0x4d, 0x50, 0x5b,
      0x6a, 0x61, 0x7c, 0x77, 0x1e, 0x15, 0x08, 0x03, 0x32, 0x39, 0x24, 0x2f,
      0x8d, 0x86, 0x9b, 0x90, 0xa1, 0xaa, 0xb7, 0xbc, 0xd5, 0xde, 0xc3, 0xc8,
      0xf9, 0xf2, 0xef, 0xe4, 0x3d, 0x36, 0x2b, 0x20, 0x11, 0x1a, 0x07, 0x0c,
      0x65, 0x6e, 0x73, 0x78, 0x49, 0x42, 0x5f, 0x54, 0xf7, 0xfc, 0xe1, 0xea,
      0xdb, 0xd0, 0xcd, 0xc6, 0xaf, 0xa4, 0xb9, 0xb2, 0x83, 0x88, 0x95, 0x9e,
      0x47, 0x4c, 0x51, 0x5a, 0x6b, 0x60, 0x7d, 0x76, 0x1f, 0x14, 0x09, 0x02,
      0x33, 0x38, 0x25, 0x2e, 0x8c, 0x87, 0x9a, 0x91, 0xa0, 0xab, 0xb6, 0xbd,
      0xd4, 0xdf, 0xc2, 0xc9, 0xf8, 0xf3, 0xee, 0xe5, 0x3c, 0x37, 0x2a, 0x21,
      0x10, 0x1b, 0x06, 0x0d, 0x64, 0x6f, 0x72, 0x79, 0x48, 0x43, 0x5e, 0x55,
      0x01, 0x0a, 0x17, 0x1c, 0x2d, 0x26, 0x3b, 0x30, 0x59, 0x52, 0x4f, 0x44,
      0x75, 0x7e, 0x63, 0x68, 0xb1, 0xba, 0xa7, 0xac, 0x9d, 0x96, 0x8b, 0x80,
      0xe9, 0xe2, 0xff, 0xf4, 0xc5, 0xce, 0xd3, 0xd8, 0x7a, 0x71, 0x6c, 0x67,
      0x56, 0x5d, 0x40, 0x4b, 0x22, 0x29, 0x34, 0x3f, 0x0e, 0x05, 0x18, 0x13,
      0xca, 0xc1, 0xdc, 0xd7, 0xe6, 0xed, 0xf0, 0xfb, 0x92, 0x99, 0x84, 0x8f,
      0xbe, 0xb5, 0xa8, 0xa3,
    ],
    GDX = [
      0x00, 0x0d, 0x1a, 0x17, 0x34, 0x39, 0x2e, 0x23, 0x68, 0x65, 0x72, 0x7f,
      0x5c, 0x51, 0x46, 0x4b, 0xd0, 0xdd, 0xca, 0xc7, 0xe4, 0xe9, 0xfe, 0xf3,
      0xb8, 0xb5, 0xa2, 0xaf, 0x8c, 0x81, 0x96, 0x9b, 0xbb, 0xb6, 0xa1, 0xac,
      0x8f, 0x82, 0x95, 0x98, 0xd3, 0xde, 0xc9, 0xc4, 0xe7, 0xea, 0xfd, 0xf0,
      0x6b, 0x66, 0x71, 0x7c, 0x5f, 0x52, 0x45, 0x48, 0x03, 0x0e, 0x19, 0x14,
      0x37, 0x3a, 0x2d, 0x20, 0x6d, 0x60, 0x77, 0x7a, 0x59, 0x54, 0x43, 0x4e,
      0x05, 0x08, 0x1f, 0x12, 0x31, 0x3c, 0x2b, 0x26, 0xbd, 0xb0, 0xa7, 0xaa,
      0x89, 0x84, 0x93, 0x9e, 0xd5, 0xd8, 0xcf, 0xc2, 0xe1, 0xec, 0xfb, 0xf6,
      0xd6, 0xdb, 0xcc, 0xc1, 0xe2, 0xef, 0xf8, 0xf5, 0xbe, 0xb3, 0xa4, 0xa9,
      0x8a, 0x87, 0x90, 0x9d, 0x06, 0x0b, 0x1c, 0x11, 0x32, 0x3f, 0x28, 0x25,
      0x6e, 0x63, 0x74, 0x79, 0x5a, 0x57, 0x40, 0x4d, 0xda, 0xd7, 0xc0, 0xcd,
      0xee, 0xe3, 0xf4, 0xf9, 0xb2, 0xbf, 0xa8, 0xa5, 0x86, 0x8b, 0x9c, 0x91,
      0x0a, 0x07, 0x10, 0x1d, 0x3e, 0x33, 0x24, 0x29, 0x62, 0x6f, 0x78, 0x75,
      0x56, 0x5b, 0x4c, 0x41, 0x61, 0x6c, 0x7b, 0x76, 0x55, 0x58, 0x4f, 0x42,
      0x09, 0x04, 0x13, 0x1e, 0x3d, 0x30, 0x27, 0x2a, 0xb1, 0xbc, 0xab, 0xa6,
      0x85, 0x88, 0x9f, 0x92, 0xd9, 0xd4, 0xc3, 0xce, 0xed, 0xe0, 0xf7, 0xfa,
      0xb7, 0xba, 0xad, 0xa0, 0x83, 0x8e, 0x99, 0x94, 0xdf, 0xd2, 0xc5, 0xc8,
      0xeb, 0xe6, 0xf1, 0xfc, 0x67, 0x6a, 0x7d, 0x70, 0x53, 0x5e, 0x49, 0x44,
      0x0f, 0x02, 0x15, 0x18, 0x3b, 0x36, 0x21, 0x2c, 0x0c, 0x01, 0x16, 0x1b,
      0x38, 0x35, 0x22, 0x2f, 0x64, 0x69, 0x7e, 0x73, 0x50, 0x5d, 0x4a, 0x47,
      0xdc, 0xd1, 0xc6, 0xcb, 0xe8, 0xe5, 0xf2, 0xff, 0xb4, 0xb9, 0xae, 0xa3,
      0x80, 0x8d, 0x9a, 0x97,
    ],
    GEX = [
      0x00, 0x0e, 0x1c, 0x12, 0x38, 0x36, 0x24, 0x2a, 0x70, 0x7e, 0x6c, 0x62,
      0x48, 0x46, 0x54, 0x5a, 0xe0, 0xee, 0xfc, 0xf2, 0xd8, 0xd6, 0xc4, 0xca,
      0x90, 0x9e, 0x8c, 0x82, 0xa8, 0xa6, 0xb4, 0xba, 0xdb, 0xd5, 0xc7, 0xc9,
      0xe3, 0xed, 0xff, 0xf1, 0xab, 0xa5, 0xb7, 0xb9, 0x93, 0x9d, 0x8f, 0x81,
      0x3b, 0x35, 0x27, 0x29, 0x03, 0x0d, 0x1f, 0x11, 0x4b, 0x45, 0x57, 0x59,
      0x73, 0x7d, 0x6f, 0x61, 0xad, 0xa3, 0xb1, 0xbf, 0x95, 0x9b, 0x89, 0x87,
      0xdd, 0xd3, 0xc1, 0xcf, 0xe5, 0xeb, 0xf9, 0xf7, 0x4d, 0x43, 0x51, 0x5f,
      0x75, 0x7b, 0x69, 0x67, 0x3d, 0x33, 0x21, 0x2f, 0x05, 0x0b, 0x19, 0x17,
      0x76, 0x78, 0x6a, 0x64, 0x4e, 0x40, 0x52, 0x5c, 0x06, 0x08, 0x1a, 0x14,
      0x3e, 0x30, 0x22, 0x2c, 0x96, 0x98, 0x8a, 0x84, 0xae, 0xa0, 0xb2, 0xbc,
      0xe6, 0xe8, 0xfa, 0xf4, 0xde, 0xd0, 0xc2, 0xcc, 0x41, 0x4f, 0x5d, 0x53,
      0x79, 0x77, 0x65, 0x6b, 0x31, 0x3f, 0x2d, 0x23, 0x09, 0x07, 0x15, 0x1b,
      0xa1, 0xaf, 0xbd, 0xb3, 0x99, 0x97, 0x85, 0x8b, 0xd1, 0xdf, 0xcd, 0xc3,
      0xe9, 0xe7, 0xf5, 0xfb, 0x9a, 0x94, 0x86, 0x88, 0xa2, 0xac, 0xbe, 0xb0,
      0xea, 0xe4, 0xf6, 0xf8, 0xd2, 0xdc, 0xce, 0xc0, 0x7a, 0x74, 0x66, 0x68,
      0x42, 0x4c, 0x5e, 0x50, 0x0a, 0x04, 0x16, 0x18, 0x32, 0x3c, 0x2e, 0x20,
      0xec, 0xe2, 0xf0, 0xfe, 0xd4, 0xda, 0xc8, 0xc6, 0x9c, 0x92, 0x80, 0x8e,
      0xa4, 0xaa, 0xb8, 0xb6, 0x0c, 0x02, 0x10, 0x1e, 0x34, 0x3a, 0x28, 0x26,
      0x7c, 0x72, 0x60, 0x6e, 0x44, 0x4a, 0x58, 0x56, 0x37, 0x39, 0x2b, 0x25,
      0x0f, 0x01, 0x13, 0x1d, 0x47, 0x49, 0x5b, 0x55, 0x7f, 0x71, 0x63, 0x6d,
      0xd7, 0xd9, 0xcb, 0xc5, 0xef, 0xe1, 0xf3, 0xfd, 0xa7, 0xa9, 0xbb, 0xb5,
      0x9f, 0x91, 0x83, 0x8d,
    ],
    enc = function (string, pass, binary) {
      // string, password in plaintext
      var salt = randArr(8),
        pbe = openSSLKey(s2a(pass, binary), salt),
        key = pbe.key,
        iv = pbe.iv,
        cipherBlocks,
        saltBlock = [[83, 97, 108, 116, 101, 100, 95, 95].concat(salt)];
      string = s2a(string, binary);
      cipherBlocks = rawEncrypt(string, key, iv);
      // Spells out 'Salted__'
      cipherBlocks = saltBlock.concat(cipherBlocks);
      return Base64.encode(cipherBlocks);
    },
    dec = function (string, pass, binary) {
      // string, password in plaintext
      var cryptArr = Base64.decode(string),
        salt = cryptArr.slice(8, 16),
        pbe = openSSLKey(s2a(pass, binary), salt),
        key = pbe.key,
        iv = pbe.iv;
      cryptArr = cryptArr.slice(16, cryptArr.length);
      // Take off the Salted__ffeeddcc
      string = rawDecrypt(cryptArr, key, iv, binary);
      return string;
    },
    MD5 = function (numArr) {
      function rotateLeft(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
      }

      function addUnsigned(lX, lY) {
        var lX4, lY4, lX8, lY8, lResult;
        lX8 = lX & 0x80000000;
        lY8 = lY & 0x80000000;
        lX4 = lX & 0x40000000;
        lY4 = lY & 0x40000000;
        lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);
        if (lX4 & lY4) {
          return lResult ^ 0x80000000 ^ lX8 ^ lY8;
        }
        if (lX4 | lY4) {
          if (lResult & 0x40000000) {
            return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
          } else {
            return lResult ^ 0x40000000 ^ lX8 ^ lY8;
          }
        } else {
          return lResult ^ lX8 ^ lY8;
        }
      }

      function f(x, y, z) {
        return (x & y) | (~x & z);
      }
      function g(x, y, z) {
        return (x & z) | (y & ~z);
      }
      function h(x, y, z) {
        return x ^ y ^ z;
      }
      function funcI(x, y, z) {
        return y ^ (x | ~z);
      }

      function ff(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(f(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function gg(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(g(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function hh(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(h(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function ii(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(funcI(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
      }

      function convertToWordArray(numArr) {
        var lWordCount,
          lMessageLength = numArr.length,
          lNumberOfWords_temp1 = lMessageLength + 8,
          lNumberOfWords_temp2 =
            (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64,
          lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16,
          lWordArray = [],
          lBytePosition = 0,
          lByteCount = 0;
        while (lByteCount < lMessageLength) {
          lWordCount = (lByteCount - (lByteCount % 4)) / 4;
          lBytePosition = (lByteCount % 4) * 8;
          lWordArray[lWordCount] =
            lWordArray[lWordCount] | (numArr[lByteCount] << lBytePosition);
          lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] =
          lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
      }

      function wordToHex(lValue) {
        var lByte,
          lCount,
          wordToHexArr = [];
        for (lCount = 0; lCount <= 3; lCount++) {
          lByte = (lValue >>> (lCount * 8)) & 255;
          wordToHexArr = wordToHexArr.concat(lByte);
        }
        return wordToHexArr;
      }

      /*function utf8Encode(string) {
            string = string.replace(/\r\n/g, "\n");
            var utftext = "",
            n,
            c;

            for (n = 0; n < string.length; n++) {

                c = string.charCodeAt(n);

                if (c < 128) {
                    utftext += String.fromCharCode(c);
                }
                else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }

            }

            return utftext;
        }*/

      var x = [],
        k,
        AA,
        BB,
        CC,
        DD,
        a,
        b,
        c,
        d,
        S11 = 7,
        S12 = 12,
        S13 = 17,
        S14 = 22,
        S21 = 5,
        S22 = 9,
        S23 = 14,
        S24 = 20,
        S31 = 4,
        S32 = 11,
        S33 = 16,
        S34 = 23,
        S41 = 6,
        S42 = 10,
        S43 = 15,
        S44 = 21;

      x = convertToWordArray(numArr);

      a = 0x67452301;
      b = 0xefcdab89;
      c = 0x98badcfe;
      d = 0x10325476;

      for (k = 0; k < x.length; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = ff(a, b, c, d, x[k + 0], S11, 0xd76aa478);
        d = ff(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
        c = ff(c, d, a, b, x[k + 2], S13, 0x242070db);
        b = ff(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
        a = ff(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
        d = ff(d, a, b, c, x[k + 5], S12, 0x4787c62a);
        c = ff(c, d, a, b, x[k + 6], S13, 0xa8304613);
        b = ff(b, c, d, a, x[k + 7], S14, 0xfd469501);
        a = ff(a, b, c, d, x[k + 8], S11, 0x698098d8);
        d = ff(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
        c = ff(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
        b = ff(b, c, d, a, x[k + 11], S14, 0x895cd7be);
        a = ff(a, b, c, d, x[k + 12], S11, 0x6b901122);
        d = ff(d, a, b, c, x[k + 13], S12, 0xfd987193);
        c = ff(c, d, a, b, x[k + 14], S13, 0xa679438e);
        b = ff(b, c, d, a, x[k + 15], S14, 0x49b40821);
        a = gg(a, b, c, d, x[k + 1], S21, 0xf61e2562);
        d = gg(d, a, b, c, x[k + 6], S22, 0xc040b340);
        c = gg(c, d, a, b, x[k + 11], S23, 0x265e5a51);
        b = gg(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
        a = gg(a, b, c, d, x[k + 5], S21, 0xd62f105d);
        d = gg(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = gg(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
        b = gg(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
        a = gg(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
        d = gg(d, a, b, c, x[k + 14], S22, 0xc33707d6);
        c = gg(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
        b = gg(b, c, d, a, x[k + 8], S24, 0x455a14ed);
        a = gg(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
        d = gg(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
        c = gg(c, d, a, b, x[k + 7], S23, 0x676f02d9);
        b = gg(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
        a = hh(a, b, c, d, x[k + 5], S31, 0xfffa3942);
        d = hh(d, a, b, c, x[k + 8], S32, 0x8771f681);
        c = hh(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
        b = hh(b, c, d, a, x[k + 14], S34, 0xfde5380c);
        a = hh(a, b, c, d, x[k + 1], S31, 0xa4beea44);
        d = hh(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
        c = hh(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
        b = hh(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
        a = hh(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
        d = hh(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
        c = hh(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
        b = hh(b, c, d, a, x[k + 6], S34, 0x4881d05);
        a = hh(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
        d = hh(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
        c = hh(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
        b = hh(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
        a = ii(a, b, c, d, x[k + 0], S41, 0xf4292244);
        d = ii(d, a, b, c, x[k + 7], S42, 0x432aff97);
        c = ii(c, d, a, b, x[k + 14], S43, 0xab9423a7);
        b = ii(b, c, d, a, x[k + 5], S44, 0xfc93a039);
        a = ii(a, b, c, d, x[k + 12], S41, 0x655b59c3);
        d = ii(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
        c = ii(c, d, a, b, x[k + 10], S43, 0xffeff47d);
        b = ii(b, c, d, a, x[k + 1], S44, 0x85845dd1);
        a = ii(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
        d = ii(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
        c = ii(c, d, a, b, x[k + 6], S43, 0xa3014314);
        b = ii(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
        a = ii(a, b, c, d, x[k + 4], S41, 0xf7537e82);
        d = ii(d, a, b, c, x[k + 11], S42, 0xbd3af235);
        c = ii(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
        b = ii(b, c, d, a, x[k + 9], S44, 0xeb86d391);
        a = addUnsigned(a, AA);
        b = addUnsigned(b, BB);
        c = addUnsigned(c, CC);
        d = addUnsigned(d, DD);
      }

      return wordToHex(a).concat(wordToHex(b), wordToHex(c), wordToHex(d));
    },
    Base64 = (function () {
      var totalChunks;
      // Takes a Nx16x1 byte array and converts it to Base64
      var _chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
        chars = _chars.split(""),
        encode = function (b, withBreaks) {
          var flatArr = [],
            b64 = "",
            i,
            broken_b64;
          totalChunks = Math.floor((b.length * 16) / 3);
          for (i = 0; i < b.length * 16; i++) {
            flatArr.push(b[Math.floor(i / 16)][i % 16]);
          }
          for (i = 0; i < flatArr.length; i = i + 3) {
            b64 += chars[flatArr[i] >> 2];
            b64 += chars[((flatArr[i] & 3) << 4) | (flatArr[i + 1] >> 4)];
            if (!(flatArr[i + 1] === undefined)) {
              b64 +=
                chars[((flatArr[i + 1] & 15) << 2) | (flatArr[i + 2] >> 6)];
            } else {
              b64 += "=";
            }
            if (!(flatArr[i + 2] === undefined)) {
              b64 += chars[flatArr[i + 2] & 63];
            } else {
              b64 += "=";
            }
          }
          // OpenSSL is super particular about line breaks
          broken_b64 = b64.slice(0, 64) + "\n";
          for (i = 1; i < Math.ceil(b64.length / 64); i++) {
            broken_b64 +=
              b64.slice(i * 64, i * 64 + 64) +
              (Math.ceil(b64.length / 64) == i + 1 ? "" : "\n");
          }
          return broken_b64;
        },
        decode = function (string) {
          string = string.replace(/\n/g, "");
          var flatArr = [],
            c = [],
            b = [],
            i;
          for (i = 0; i < string.length; i = i + 4) {
            c[0] = _chars.indexOf(string.charAt(i));
            c[1] = _chars.indexOf(string.charAt(i + 1));
            c[2] = _chars.indexOf(string.charAt(i + 2));
            c[3] = _chars.indexOf(string.charAt(i + 3));

            b[0] = (c[0] << 2) | (c[1] >> 4);
            b[1] = ((c[1] & 15) << 4) | (c[2] >> 2);
            b[2] = ((c[2] & 3) << 6) | c[3];
            flatArr.push(b[0], b[1], b[2]);
          }
          flatArr = flatArr.slice(0, flatArr.length - (flatArr.length % 16));
          return flatArr;
        };

      //internet explorer
      if (typeof Array.indexOf === "function") {
        _chars = chars;
      }

      /*
        //other way to solve internet explorer problem
        if(!Array.indexOf){
            Array.prototype.indexOf = function(obj){
                for(var i=0; i<this.length; i++){
                    if(this[i]===obj){
                        return i;
                    }
                }
                return -1;
            }
        }
        */

      return {
        encode: encode,
        decode: decode,
      };
    })();

  return {
    size: size,
    h2a: h2a,
    expandKey: expandKey,
    encryptBlock: encryptBlock,
    decryptBlock: decryptBlock,
    Decrypt: Decrypt,
    s2a: s2a,
    rawEncrypt: rawEncrypt,
    aesEncrypt: aesEncrypt,
    aesDecrypt: aesDecrypt,
    dec: dec,
    openSSLKey: openSSLKey,
    a2h: a2h,
    enc: enc,
    Hash: { MD5: MD5 },
    Base64: Base64,
  };
})();
</script>
  
<style></style>  

  