<template>
  <div class="jh-card">
    <div class="jh-card-body">
      <div class="jh-ui-header">
        <div class="is-left">
          <i class="jh-icon-clock"></i>
          <label class="jh-label is-ml-5">최근 저장일시 : </label>
          <span class="is-bold is-txt-blue" style="font-size: 14px">  {{ UPD_DTTM }} </span>
        </div>
        <div class="is-right">
          <span class="is-bold">최대 2,000자</span>
        </div>
      </div>
      <v-textarea 
        class="jh-form" 
        style="height: calc(100vh - 190px);"
        name="memo_content"
        v-model="memo_content"
        :rules="textareaRules"
        placeholder="메모내용을 입력하세요."
      />
    </div>
    <div class="jh-card-footer">
      <v-btn class="jh-btn is-md is-fill" @click="memoSave">저장</v-btn>
    </div>      
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import api from "@/store/apiUtil.js";
  import { mixin } from "@/mixin/mixin.js";
  

  export default {
    name: "MENU_T101001", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],

    components: {
        
    },

    props: [ "evt" ],
    
    computed: {
      USER_ID: function() {
        return this.$store.getters["userStore/GE_USER_ROLE"].userId;
      }
    },
    data() {
      return {
        slide10     : false,
        memo_content: null,
        ID          : null,
        UPD_DTTM    : null,

        memoList: [],

        alertMsg: {     
          memoCntData  : "내용은 2000자 제한입니다.",
          responseError: "시스템에 오류가 발생하였습니다.",
        },

        textareaRules: [(v) => (v || '').length < 2001 || 'Max 2000 characters']
      }
    },
    mounted() {
      this.getMemoList();
    },
    watch: {
        
    },
    methods: {

      // 메모 저장
      memoSave() {
        const USER_ID = this.USER_ID;
        
        // 메모 내용은 2000자 제한
        if(this.memo_content.length > "2000"){
          this.common_alert(this.alertMsg.memoCntData, "error");
        }
        // 메모 최초 저장시에는 insert ( 최초 저장하면 MEMO_SEQ가 자동생성 되기 때문에 MEMO_SEQ로 최초인지 아닌지 판단. )       
        else if(this.ID == null || this.ID == ""){
          api
          .post(
            "api/memo/insert",
            {
              USER_ID: USER_ID,             
              MEMO_CONTENT: this.restoreXSS_Dec(this.memo_content),
            },
            {
              head: {
                SERVICE: "api/memo",
                METHOD: "/insert",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            console.log(response);
            if(response.data.HEADER.ERROR_FLAG==false) {
              this.common_alert("메모가 저장되었습니다.", "sucess");
              // 저장 후 메모 새로 조회
              this.getMemoList();
            } else {
              this.common_alert(this.alertMsg.responseError, "error");
              return;
            }
          });
        } 
        // 메모 최초 저장후 두번째 저장할때 부터는 update
        else {
          api
          .post(
            "api/memo/update",
            {
              USER_ID: USER_ID,             
              MEMO_CONTENT: this.restoreXSS_Dec(this.memo_content),
              ID: this.ID,
            },
            {
              head: {
                SERVICE: "api/memo",
                METHOD: "/update",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            console.log(response);
            if(response.data.HEADER.ERROR_FLAG==false) {
              this.common_alert("메모가 저장되었습니다.", "sucess");
              // 수정 후 메모 새로 조회
              this.getMemoList();
            } else {
              this.common_alert(this.alertMsg.responseError, "error");
              return;
            }
          });
        }
      },

      // 메모 조회 
      getMemoList(item) {
        const USER_ID = this.USER_ID;
        api
        .post(
          "/api/memo/list",
          {
            USER_ID: USER_ID,
          },
          {
            head: {
              SERVICE: "/api/memo",
              METHOD: "/list",
              TYPE: "BIZ_SERVICE",
            },
          },
        )
        .then((response) => {
          // 메모 조회 결과
          this.memoList = response.data.DATA;
          // this.memo_content = _.unescape(this.memoList.at().MEMO_CONTENT);
          // this.memo_content = this.restoreXSS(this.memoList.at().MEMO_CONTENT);
          // this.MEMO_SEQ = this.memoList[0].MEMO_SEQ;
          // this.CHNG_DTIM = this.memoList.at().CHNG_DTIM.replace(
          // /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,d
          // "$1-$2-$3 $4:$5:$6"
          // );
          //console.log("memoList::",JSON.stringify(this.memoList))
          // for( var i = 0; i < this.memoList.length;i++ ){
          //   console.log(this.memoList);
          //   this.memoList[i]["index"]= i;
          //   //this.memo_content = _.unescape(this.memoList[i]["MEMO_CONTENT"]); // html 변환 위해 _.unescape() 처리 해준다.
          //   //this.memo_content = this.restoreXSS(this.memoList[i]["MEMO_CONTENT"]).replace("\r\n", "\n"); // html 변환 위해 this.restoreXSS() 처리 해준다.
          //   // 서버에서 값이 올때 \r\n으로 옴, 화면에서 저장할때는 \n으로 하기때문에 서버에서 받은 값을 \n으로 바꿔준다
          //   this.memo_content = this.restoreXSS(this.memoList[i]["MEMO_CONTENT"]).replace(/\r\n/g, "\n")  
          //   this.ID           = this.memoList[i]["ID"];
          //   this.UPD_DTTM     = this.memoList[i]["UPD_DTTM"].replace(
          //   /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
          //   "$1-$2-$3 $4:$5:$6"
          //   );

            this.memo_content = this.restoreXSS(this.memoList[0].MEMO_CONTENT).replace(/\r\n/g, "\n");
            this.ID           = this.memoList[0]["ID"];
            this.UPD_DTTM     = this.memoList[0]["UPD_DTTM"].replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,"$1-$2-$3 $4:$5:$6");
          // }   
        });
      },
      
      closeModal() {
        this.$parent.closeModal('slide10');
      },
    },

  }
</script>

<style scoped>
</style>